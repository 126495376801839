import React, { Component } from 'react';
import {observer} from 'mobx-react';
import './form.css';
import _ from 'lodash';
import loader from "../images/loader.svg";

import InputMask from 'react-input-mask';

import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru"; 
import moment from 'moment';
import Parser from 'html-react-parser';
import 'react-datepicker/dist/react-datepicker.css';
import ImageUploader from '../uploadControl/';
import Select from 'react-select';

import { Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';

import { Editor } from '@tinymce/tinymce-react';
import { IconsPreset } from '../dictionary';
import { formatDate } from '../functions';

registerLocale("ru", ru);

export class TextInput extends Component {
	constructor(props) {
		super(props);

		this.state = {
			upLabel: this.props.Focused ? true : !_.isUndefined(this.props.Value) && this.props.Value !== ""
		};

		this.labelState = this.labelState.bind(this);
	}

	onChange (event) {
		if (this.props.onChange) 
			this.props.onChange(this.props.Type === "number" && event.target.value !== "" ? 
				parseFloat(event.target.value) : event.target.value, this.props.Id);
	}

	labelState(event) {
		if (!this.props.Focused) {
			if (event.type === "focus") this.setState({ upLabel: true });
			if (event.type === "blur" && this.input.value === "") this.setState({ upLabel: false });
		}

		if (event.type === "blur" && this.props.onBlur) this.props.onBlur(this.props.Id);
		if (event.type === "focus" && this.props.onFocus) this.props.onFocus(this.props.Id);
	}

	render() {
		var rowClass = "clearfix form-row " + (this.props.RowClass ? this.props.RowClass : ""),
			upLabel = this.props.Focused ? true :
				(!_.isUndefined(this.props.Value) && this.props.Value !== "") || this.state.upLabel,
			title = /</.test(this.props.Title) ? Parser(this.props.Title) : this.props.Title,
			titlePopover = this.props.Title ? this.props.Title.replace(/<(\/?)(\w+)>/g, "") : "";

		return (
			<div className={rowClass}>
				<label className={upLabel ? " focused" : ""} title={titlePopover}>
					{this.props.MarkRequired && <span className="required">*</span>}
					{title}
				</label>
				<div className="form-input">
					{this.props.Icon && <i className={this.props.Icon}></i>}
					{!_.isUndefined(this.props.Mask) && this.props.Mask !== "" && this.props.Type !== "textarea" &&
						<InputMask 
							mask={this.props.Mask}
							onChange={this.onChange.bind(this)}
							name={this.props.Id}
							// defaultValue={this.props.Value}
							value={this.props.Value}
							onFocus={this.labelState}
							onBlur={this.labelState}
							required={this.props.Required}
							disabled={this.props.Disabled}
							readOnly={this.props.ReadOnly}
							autoComplete="off"
							step={this.props.Step}
							ref={(input) => this.input = input}
						/>
					}
					{(_.isUndefined(this.props.Mask) || this.props.Mask === "") && this.props.Type !== "textarea" &&
						<input
							name={this.props.Id}
							className={this.props.ClassName}
							// placeholder={this.props.Placeholder}
							autoComplete="off"
							type={this.props.Type ? this.props.Type : "text"}
							// data-slots="_"
							// defaultValue={this.props.Value}
							value={this.props.Value}
							onFocus={this.labelState}
							onBlur={this.labelState}
							onChange={this.onChange.bind(this)}
							required={this.props.Required}
							disabled={this.props.Disabled}
							readOnly={this.props.ReadOnly}
							step={this.props.Step}
							maxLength={this.props.MaxLength || "auto"}
							minLength={this.props.MinLength || "auto"}
							max={this.props.Max}
							ref={(input) => this.input = input}
						/>
					}
					{this.props.Type === "textarea" &&
						<textarea
							name={this.props.Id}
							className={this.props.ClassName}
							autoComplete="off"
							type={this.props.Type ? this.props.Type : "text"}
							// defaultValue={this.props.Value}
							value={this.props.Value}
							onFocus={this.labelState}
							onBlur={this.labelState}
							onChange={this.onChange.bind(this)}
							required={this.props.Required}
							disabled={this.props.Disabled}
							readOnly={this.props.ReadOnly}
							maxLength={this.props.Max || "auto"}
							minLength={this.props.Min || "auto"}
							ref={(input) => this.input = input}
						></textarea>
					}
				</div>
			</div>
		)
	}
}

export class DateInput extends Component {
	constructor(props) {
		super(props);

		this.state = {
			startDate: this.props.Value === "today" ? new Date() :
				!_.isUndefined(this.props.Value) && this.props.Value !== "" && moment(this.props.Value).isValid() ? 
				new Date(this.props.Value) : null
		};

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(date) { 
		this.setState({ startDate: date }); 
		
		if (this.props.onChange) 
			this.props.onChange(_.isNull(date) ? "" : moment(date).format(this.props.WithTime ? 
				"YYYY-MM-DD HH:mm" : "YYYY-MM-DD"), this.props.Id);
	}

	render() {
		
		var date = this.props.Value === "today" ? moment() :
				!_.isUndefined(this.props.Value) && this.props.Value !== "" && moment(this.props.Value).isValid() ? 
				moment(this.props.Value) : null,
			day = !_.isNull(date) ? date.isoWeekday() - 1 : moment().isoWeekday(),
			shifts = !_.isNull(day) && !_.isEmpty(this.props.WorkDays) ? _.find(this.props.WorkDays, { Day : day })
				: undefined,
			min = _.isUndefined(shifts) ? undefined : { h : shifts.MinTime.split(":")[0], m : shifts.MinTime.split(":")[1] },
			max = _.isUndefined(shifts) ? undefined : { h : shifts.MaxTime.split(":")[0], m : shifts.MaxTime.split(":")[1] },
			isNextDate = _.isUndefined(shifts) ? false : 
				moment(shifts.MaxTime, "HH:mm").isBefore(moment(shifts.MinTime, "HH:mm")) && 
				moment(shifts.MaxTime, "HH:mm").isAfter(moment("00:00", "HH:mm"))
			
		if (!_.isUndefined(shifts) && isNextDate) shifts.MaxTime = "23:59";

		var minDate = _.isUndefined(this.props.MinDate) ? null : this.props.MinDate === "today" ? moment()
				: this.props.MinDate,
			minTime = !this.props.WithTime || _.isUndefined(this.props.MinDate) ? null :
				_.isNull(date) || !date.isAfter(minDate, "day") ? _.clone(minDate) : date.isAfter(minDate, "day") 
				? shifts ? _.clone(date).hour(min.h).minute(min.m) : _.clone(date).startOf("day") : null,
			maxDate = _.isUndefined(this.props.MaxDate) ? null : this.props.MaxDate === "today" ? moment()
				: this.props.MaxDate,
			maxTime = !this.props.WithTime || _.isUndefined(this.props.MinDate) ? null :
				!_.isNull(minTime) ? shifts && !isNextDate ? (minTime.clone()).hour(max.h).minute(max.m)
				: (minTime.clone()).endOf("day") : null,
			selected = this.props.Value === "today" ? new Date() :
				!_.isUndefined(this.props.Value) && this.props.Value !== "" ? new Date(this.props.Value) : null;

			// var inject = moment("2020-11-20 11:12");

		return (
			<div className={"clearfix form-row " + (this.props.RowClass ? this.props.RowClass : "")}>
				<label className="focused" title={this.props.Title}>{this.props.Title}</label>
				<div className="form-input">
					{this.props.WithIcon && <i className="date"></i>}
					<DatePicker
						customInput={<InputMask mask={this.props.WithTime ? "99.99.2099 99:99" : 
							this.props.DropYear ? "99.99.9999" : "99.99.2099" } />}
						dateFormat={this.props.WithTime ? "dd.MM.yyyy HH:mm" : "dd.MM.yyyy"}
						selected={selected}
						
						minDate={!_.isNull(minDate) ? minDate.toDate() : null}
						minTime={!_.isNull(minTime) ? minTime.toDate() : null}
						maxDate={!_.isNull(maxDate) ? maxDate.toDate() : null}
						maxTime={!_.isNull(maxTime) ? maxTime.toDate() : null}

						// injectTimes={[inject.toDate()]}
						
						onChange={this.handleChange.bind(this)}
						disabled={this.props.Disabled}
						locale="ru"
						showTimeSelect={this.props.WithTime}
						calendarClassName={this.props.WithTime ? "withTime" : ""}
						timeFormat="HH:mm"
						timeIntervals={this.props.Step ? this.props.Step : 5}
						timeCaption="Время"
						showMonthDropdown={this.props.DropYear}
      					showYearDropdown={this.props.DropYear}
						isClearable={!_.isUndefined(this.props.IsClearable) ? this.props.IsClearable : true}
					/>
				</div>
			</div>
		)
	}
}

export class SelectInput extends Component {
	onChange (event) {
		var value = event.target.value;

		if (this.props.onChange) 
			this.props.onChange(this.props.Type === "number" && value !== "" ? 
				parseInt(value, 10) : value, this.props.Id);
	}

	render() {
		return (
			<div className={"clearfix form-row " + (this.props.RowClass ? this.props.RowClass : "")}>
				{this.props.Title && this.props.Title !== "" &&
					<label className="focused" title={this.props.Title}>{this.props.Title}</label>
				}
				<div className="form-input">
					<select
						name={this.props.Id}
						value={this.props.Value}
						disabled={this.props.Disabled}
						onChange={this.onChange.bind(this)}
					>
						{_.map(this.props.List, function (item, i) {
							return (<option value={item.Key} key={i}>{item.Value}</option>)
						})}
					</select>
				</div>
			</div>
		)
	}
}

const Handle = Slider.Handle;
const handle = (props) => {
	const { value, dragging, index, ...restProps } = props;
	return (
		<Tooltip
			prefixCls="rc-slider-tooltip"
			overlay={value}
			placement="top"
			key={index}
			arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
		>
			<Handle value={value} {...restProps} />
		</Tooltip>
	);
};
export class SliderControl extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sliderValue: this.props.Value ? this.props.Value : 0
		}

		this.controlChange = this.controlChange.bind(this);
	}

	controlChange(value) { 
		this.setState({ sliderValue: value }); 
		if (this.props.onChange) this.props.onChange(value, this.props.Id);
	}

	render() {
		return (
			<div className={"clearfix form-row " + (this.props.RowClass ? this.props.RowClass : "")}>
				<label className="focused" title={this.props.Title}>{this.props.Title}</label>
				<div className="form-input slider">
					<Slider
						min={this.props.Min ? this.props.Min : 0} 
						max={this.props.Max} 
						step={this.props.Step ? this.props.Step : 1} 
						handle={handle}
						defaultValue={this.props.Value ? this.props.Value : 0}
						onAfterChange={(value) => this.controlChange(value)}
						trackStyle={{ background : this.props.Type === "Min" && this.props.Color ? 
							this.props.Color : "#DDDDDD"}}
						railStyle={{ background : this.props.Type === "Max" && this.props.Color ? 
							this.props.Color : "#DDDDDD"}}
					/>
					<input type="hidden"
						name={this.props.Id}
						data-parse={this.props.Parse ? this.props.Parse : "numberInt"}
						value={this.state.sliderValue ? this.state.sliderValue : 0}
						readOnly
					/>
				</div>
			</div>
		)
	}
}

export class CheckGroup extends Component {
	onChange (event) {
		var value = event.target.value,
			checked = event.target.checked;

		if (this.props.onChange) 
			this.props.onChange(this.props.Type === "checkbox" ? checked 
				: this.props.ParseType === "number" ? parseInt(value, 10) : value, event.target.name)
	}

	render() {
		var view = this;

		return (
			<div className={"clearfix form-row " + (this.props.RowClass ? this.props.RowClass : "")}>
				<label className="focused" title={this.props.Title}>{this.props.Title}</label>
				<div className="form-input">
					{this.props.List.map(function (item) {
						var title = item.Title + (item.Description && item.Description !== ""
							? (" – " + item.Description) : "");

						return(
							<label className="checkGroupLabel" key={item.Id} title={title}>
								<input
									type={view.props.Type}
									name={view.props.Id + (view.props.Type === "checkbox" ? "_" + item.Id : "")}
									value={item.Id}
									checked={_.indexOf(view.props.Value, item.Id) !== -1}
									disabled={item.Disabled ? item.Disabled : view.props.Disabled}
									readOnly={false}
									
									onChange={view.onChange.bind(view)}
								/>
								{item.Title}
							</label>
						)
					}) }
				</div>
			</div>
		)
	}
}
CheckGroup = observer(CheckGroup);
export class PeriodGroup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			Period: this.props.Value ? this.getPeriod(this.props.Value) : { Count: "", Label: "hours" }
		}
	}

	getPeriod(hours) {
		if (hours === "0" || hours === 0) return { Count: "", Label: "hours" }
		else if (hours % 24 === 0) return { Count: hours / 24, Label: "days" }
		else return { Count: hours, Label: "hours" }
	}

	/**
	 * Сбор данных с формы редактирования раздела
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange (value, id) {
		this.setState({
			Period : _.extend(this.state.Period, { [/Count/.test(id) ? "Count" : "Label"] : value })
		});
		
		var hours = 0;
		if (/Count/.test(id)) hours = value * (this.state.Period.Label === "days" ? 24 : 1);
		else if (/Label/.test(id)) hours = this.state.Period.Count * (value === "days" ? 24 : 1);

		this.props.onChange(hours, this.props.Id)
	}

	render() {
		var data = this.state.Period,
			list = [{ Id: "hours", Title: "часов" }, { Id: "days", Title: "дней" } ];

		return (
			<div className={"clearfix form-row " + (this.props.RowClass || "")}>
				<label className="focused" title={this.props.Title}>{this.props.Title}</label>
				<div className="form-input period">
					<TextInput
						Id={this.props.Id + "_Count"}
						Value={data.Count}
						Type="number"
						onChange={this.handleChange.bind(this)}
					/>
					<CheckGroup
						Id={this.props.Id+ "_Label"}
						Type="radio"
						Value={[data.Label]}
						List={list}
						onChange={this.handleChange.bind(this)}
					/>
				</div>
			</div>
		)
	}
}

export class Selectize extends Component {
	constructor(props) {
		super(props);

		var value = this.props.Value,
			list = _.map(this.props.List, function (v) { return { value : v.Key, label : v.Value } }),
			val = _.isArray(value) ? _.filter(list, function (v) { return _.indexOf(value, v.value) !== -1 }) 
				: _.find(list, { value : this.props.Value }),
			newVal = this.props.isCreatable && value !== "" && !_.isUndefined(value) ? 
				{ value : value, label : value } : "";

		this.state = {
			value : _.isUndefined(val) ? newVal : val,
			isRemoved : false
		};

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange (value, { action, removedValue }) { 
		this.setState({ 
			value : this.state.isRemoved ? "" : value,
			isRemoved : false
		});
		
		var val = this.props.Multi ? _.map(value, function(v) { return v.value; }) : value ? value.value : -1;
		if (this.props.onChange) this.props.onChange(val, this.props.Id); 
	}

	handleFocus () {
		if (this.props.onFocus) this.props.onFocus(this.props.Id);
	}

	handleInputChange (value) { 
		if (this.props.onInputChange) this.props.onInputChange(value, this.props.Id); 
	}

	handleMaskChange(event) {
		if (this.props.onInputChange) this.props.onInputChange(event.target.value, this.props.Id); 
	}

	onRemove (event) {
		event.preventDefault();
		if (this.props.onRemove) this.props.onRemove(event.currentTarget.getAttribute("rel"));
	}

	/**
	* Поле ввода с выпадающим списком и возможностью поиска по нему
	*/
	render () {
		var view = this,
			title = this.props.Title,
			rowClass = "clearfix form-row " + (this.props.RowClass ? this.props.RowClass : ""),
			className = "selectize " + (this.props.Multi ? "multi" : "single"),
			list = _.map(this.props.List, function (v) { 
				return { value : v.Key, label : v.Value, hidden : v.Hidden, color : v.Color }; 
			});

		var value = this.props.Value,
			val = _.isArray(value) ? _.filter(list, function (v) { return _.indexOf(value, v.value) !== -1 }) 
				: _.find(list, { value : this.props.Value });

		if (this.props.onInputChange && _.isUndefined(val)) { 
			list = _.concat(list, { value : value, label : value });
			val = { value : value, label : value };
		}

		const Option = props => {
			const { innerProps, innerRef } = props;
			return (
				<div className="css-v73v8k select__option">
					{this.props.Type === "OrderStatus" && <i className="icon status" 
						style={{ backgroundColor : props.data.color }}></i> }
					<span ref={innerRef} {...innerProps}>{props.data.label}</span>
					{this.props.Type === "Departments" && <a href="/" className="remove" rel={props.data.value} 
						onClick={view.onRemove.bind(view)}>Удалить</a> }
					{this.props.Type === "Sections" && props.data.hidden && <i className="icon hidden"></i> }
				</div>
			);
		};

		return (
			<div className={rowClass}>
				{ title && <label className="focused" title={title}>{title}</label> }
				<div className="form-input">
					{this.props.Icon && <i className={this.props.Icon}></i>}
					<Select
						options={list}
						defaultValue={this.state.value}
						// defaultValue={this.props.Value}
						value={val ? val : ""}
						isMulti={this.props.Multi}
						className={className}
						classNamePrefix="select"
						placeholder={this.props.Placeholder ? this.props.Placeholder : "Выберите..."}
						isDisabled={this.props.Disabled}
						isSearchable={!_.isUndefined(this.props.isSearchable) ? this.props.isSearchable : true}
						isClearable={!_.isUndefined(this.props.isClearable) ? this.props.isClearable : true}
						isOptionDisabled={(option) => option.disabled}
						
						onInputChange={this.handleInputChange.bind(this)}
						onChange={this.handleChange.bind(this)}
						onFocus={this.handleFocus.bind(this)}
						components={{Option}}
					/>
				</div>
			</div>
		)
	}
}
Selectize = observer(Selectize);
export class DropButton extends Component {
	constructor(props) {
		super(props);
	
		this.toggle = this.toggle.bind(this);
		this.state = {
		  	dropdownOpen: false
		};

		this.handleClick = this.handleClick.bind(this);
	}
	
	toggle() { this.setState({ dropdownOpen: !this.state.dropdownOpen }); }
	handleClick (event) { 
		event.preventDefault();
		
		if (!_.isUndefined(this.props.onClick) && event.target.value !== "")
			this.props.onClick(event.target.value, this.props.Id);
	}

	render() {
		var iconClass = "icon " + this.props.Icon,
			list = _.map(this.props.List, function (v) { return { Title : v.Title || v, Id : v.Id || v }; });

			// console.log(list)

		return(
			<div className="buttonDropdown clearfix">
				<ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} onClick={this.handleClick}>
					<Button value={list[0].Id}>
						{this.props.Icon && <i className={iconClass}></i>}
						{this.props.Title}
					</Button>
					<DropdownToggle caret />
					<DropdownMenu>
						{list.map(function (v) { 
							return <DropdownItem key={v.Id} value={v.Id}>{v.Title}</DropdownItem>
						})}
					</DropdownMenu>
				</ButtonDropdown>
			</div>
		)
	}
}
export class PeriodTime extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			StartTime : this.props.Value.EndTime !== "" ? this.props.Value.StartTime : "",
			EndTime : this.props.Value.EndTime,
			InTime : this.props.Value.EndTime === "" ? this.props.Value.StartTime : "",
			Type : this.props.Value.StartTime !== "" && this.props.Value.EndTime === "" ? "exact" : "period"
		};
	}

	onChange (event) {
		var name = event.target.name,
			value = event.target.value,
			isDummy = value === "__:__" || value === ""

		this.setState({
			StartTime : name === "InTime" && !isDummy ? "" : name === "StartTime" ? value : this.state.StartTime,
			EndTime : name === "InTime" && !isDummy ? "" : name === "EndTime" ? value : this.state.EndTime,
		});

		if (this.props.onChange) {
			var values = {
				StartTime : name === "StartTime" ? value : this.state.StartTime,
				EndTime : this.state.Type === "exact" ? "" : name === "EndTime" ? value : this.state.EndTime
			}
			this.props.onChange(values, this.props.Id)
		}
	}

	beforeMaskedValueChange = (newState, oldState, userInput) => {
		var { value } = newState,
			selection = newState.selection;

		if (!_.isNull(userInput)) {
			var parseUserInput = parseInt(userInput, 10);

			if (newState.selection.start === 1 && parseUserInput > 2 ) {
				value = "0" + value;
				selection = { start: newState.selection.start + 2, end : newState.selection.end + 2 }
			}

			if (newState.selection.start === 4 && parseUserInput > 5 ) {
				value = value.split(":")[0] + "0" + value.split(":")[1];
				selection = { start: newState.selection.start + 1, end : newState.selection.end + 1 }
			}
		}
	
		return { value, selection };
	}

	/** Изменение типа выбора времени по клику на лейбл */
	changePeriodType (event) {
		event.preventDefault();
		this.setState({ Type : event.currentTarget.getAttribute("rel")});
	}

	/** Изменение типа выбора времени по клику на переключатель */
	changeCheckType () {
		this.setState({ Type : this.state.Type === "exact" ? "period" : "exact"});
	}
	
	render() {
		var inputClass="form-input periodTime" + (this.props.Type === "exact" ? " exact" : ""),
			isExact = this.props.Type === "exact"
			

		return(
			<div className={"clearfix form-row " + (this.props.RowClass ? this.props.RowClass : "")}>
				{this.props.Title && this.props.Title !== "" &&
					<label className="focused" title={this.props.Title}>{this.props.Title}</label>
				}
				<div className={inputClass}>
					{isExact && 
						<div className='type'>
							<a href='/' onClick={this.changePeriodType.bind(this)} rel="period" className={this.state.Type === "period" ? "active" : ""}>Период</a>
							<span className={this.state.Type} onClick={this.changeCheckType.bind(this)}><b></b></span>
							<a href='/' onClick={this.changePeriodType.bind(this)} rel="exact" className={this.state.Type === "exact" ? "active" : ""}>Точно</a>
						</div>
					}
					<div className='set'>
						{this.state.Type === "period" &&
							<>
								<span>C</span>
								<InputMask 
									name="StartTime"
									mask="99:99" 
									value={this.props.Value.StartTime}
									disabled={this.props.Disabled}
									onChange={this.onChange.bind(this)}
									required={this.props.Type === "exact" ? false: this.props.Required}
									beforeMaskedValueChange={this.beforeMaskedValueChange}
								/>
								<span>По</span>
								<InputMask 
									name="EndTime"
									mask="99:99" 
									value={this.props.Value.EndTime}
									disabled={this.props.Disabled}
									onChange={this.onChange.bind(this)}
									required={this.props.Type === "exact" ? false: this.props.Required}
									beforeMaskedValueChange={this.beforeMaskedValueChange}
								/>
							</>
						}
						{this.state.Type === "exact" &&
							<>
								<span>В</span>
								<InputMask 
									name="StartTime"
									mask="99:99" 
									value={this.props.Value.StartTime}
									disabled={this.props.Disabled}
									onChange={this.onChange.bind(this)}
									beforeMaskedValueChange={this.beforeMaskedValueChange}
								/>
							</>
						}
					</div>
				</div>
			</div>
		)
	}
}
PeriodTime = observer(PeriodTime);
export class TextEditor extends Component {
	constructor(props) {
		super(props);
	
		this.state = {
			value : this.props.Value
		};
	}

	onBlur = (e) => {
		this.setState({ value : e.target.getContent() });
		if (this.props.onChange) this.props.onChange(e.target.getContent(), this.props.Id);
	}

	render() {
		var loc = window.location.origin + "/scripts/lang/ru.js";

		return (
			<div className="textEditor">
				<Editor 
					apiKey='qnhqxb965s1ez9zmzgp1ae0k91xzbomgg5g8vnciaqbsie4i' 
					initialValue={this.props.Value}
					onBlur={this.onBlur.bind(this)}
					init={{
						language : 'ru',
						language_url : loc,
						menubar : false,
						plugins : 'autolink directionality  image link media table charmap hr nonbreaking insertdatetime advlist lists imagetools textpattern',
						toolbar : 'formatselect | italic bold underline strikethrough | table| image media | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat',
						
						image_uploadtab: true,
						images_reuse_filename: true,
						image_description: false,
						images_upload_handler: function (blobInfo, success, failure) {
							let fileData = blobInfo.blob();
							let fileName =  blobInfo.filename();

							//TODO: Refactor me - Interview UPload example
							var data = new FormData()
							data.append('Files', fileName);
							data.append(fileName, fileData);
							data.append('Type', 'Content');

							fetch("/api/uploadFile", {
								method: 'POST',
								body: data
							}).then(function(response) { return response.json(); }).then(function (data) {
								let fName = "upload/Content/" + data.FileName[0];
								success(fName);
							});
						},
						automatic_uploads: true,
					}}
				/>
			</div>
		);
	}
}
export class ColorPreset extends Component {
	onChange(event) {
		event.preventDefault();

		var value = event.target.getAttribute("rel");
		if (this.props.onChange) this.props.onChange(value === this.props.Value ? "" : value, this.props.Id);
	}
	render () {
		var view = this,
			colorList = ["#e9e9ef","#c5c5ce","#97f2e1","#7bd9ef","#95b5fc","#c4a8e2","#f99bc1","#ef8b8b",
				"#f2bc8b","#f4e989","#91f99a"];

		return(
			<div className={"clearfix form-row " + (this.props.RowClass ? this.props.RowClass : "")}>
				<label className="focused" title={this.props.Title}>{this.props.Title}</label>
				<div className="form-input colorPreset">
					{_.map(colorList, function (color, i) {
						var style = { background : color };
						
						return(<span className={(view.props.Value === color ? "active" : "") + 
							(view.props.Disabled ? " disabled" : "")} rel={color} key={"c" + i} style={style} 
							onClick={view.onChange.bind(view)}></span>)
					})}
				</div>
			</div>
		)
	}
}
export class IconPreset extends Component {
	onChange(event) {
		event.preventDefault();
		if (this.props.onChange) 
			this.props.onChange(parseInt(event.currentTarget.getAttribute("rel"), 10), this.props.Id);
	}
	render () {
		var view = this,
			list = IconsPreset[this.props.Type];

		return(
			<div className={"clearfix form-row " + (this.props.RowClass ? this.props.RowClass : "")}>
				<label className="focused" title={this.props.Title}>{this.props.Title}</label>
				<div className="form-input iconPreset">
					{_.map(list, function (icon) {
						return(<span className={"icon_" + icon + (view.props.Value === icon ? " active" 
							: "") + (view.props.Disabled ? " disabled" : "")} rel={icon} key={"c" + icon}  
							onClick={view.onChange.bind(view)}><i></i></span>)
					})}
				</div>
			</div>
		)
	}
}
export class FileInput extends Component {
	constructor(props) {
		super(props);

		var f = this.props.File
		this.state = { 
			pictures: _.isUndefined(f) || _.isNull(f) ? "" : _.isString(f) ? f : f.pictures, 
			files: _.isUndefined(f) || _.isNull(f) ? [] : f.files 
		};
	}

	onChange(pictureFiles, pictureDataURLs) { 
		var f = { files : pictureFiles[0], pictures : pictureDataURLs[0] }
		if (this.props.onChange) 
			this.props.onChange(_.isEmpty(pictureFiles) ? -1 : f, this.props.Id);
	}
	
    render() {
		var typeAccept = this.props.Type ? this.props.Type : "image/*",
			ext = this.props.Ext ? this.props.Ext : ['.jpg', '.png', '.jpeg'],
			max = (this.props.MaxSize ? this.props.MaxSize : 1048576),
			extError = "Разрешены файлы только " + ext.join(", "),
			maxError = "Максимальный размер файла " + (max/1024/1024).toFixed(2) + "MB";

		return (
			<div className={"clearfix form-row " + (this.props.RowClass ? this.props.RowClass : "")}>
				<ImageUploader
					withIcon={false}
					withPreview={true}
					name={this.props.Id}
					buttonText={this.props.Title}
					
					onChange={this.onChange.bind(this)}
					
					singleImage={true}
					imgExtension={ext}
					maxFileSize={max}
					withLabel={false}
					fileSizeError={maxError}
					fileTypeError={extError}
					accept={typeAccept}
					defaultImage={this.state.pictures}
				/>
			</div>
        );
    }
}
export class TagsPreset extends Component {
	onChange(event) {
		event.preventDefault();

		if (/disabled/.test(event.currentTarget.className)) return false;

		var value = event.currentTarget.getAttribute("rel");
		if (this.props.onChange) 
			this.props.onChange(!_.isNaN(parseInt(value, 10)) ? parseInt(value, 10) : value, this.props.Id);
	}

	render () {
		var view = this;

		return(
			<div className={"clearfix form-row " + (this.props.RowClass ? this.props.RowClass : "")}>
				{this.props.Title &&
					<label className="focused" title={this.props.Title}>{this.props.Title}</label>
				}
				<div className="form-input tags">
					{_.map(this.props.List, function (item) {
						return(<span className={(_.indexOf(view.props.Value, item.Id) !== -1 ? " active" : "") 
							+ (view.props.Disabled ? " disabled" : "")} rel={item.Id} key={"c" + item.Id}  
							onClick={view.onChange.bind(view)}>{item.Name}</span>)
					})}
				</div>
			</div>
		)
	}
}

export class EditableTagsPreset extends Component {
	onChange(event) {
		var index = parseInt(event.target.name, 10),
			values = this.props.Value;

		values[index] = this.props.Type === "number" ? parseInt(event.target.value,10) : event.target.value;
		if (this.props.onChange) this.props.onChange(values, this.props.Id);
	}

	onAdd(event) {
		event.preventDefault();
		var value = this.props.Value;
		if (this.props.onChange) this.props.onChange(_.concat(value, ""), this.props.Id);
	}

	onRemove(event) {
		event.preventDefault();
		var index = parseInt(event.currentTarget.getAttribute("rel"), 10),
			values = this.props.Value,
			droped = _.pullAt(values, index);
		// console.log(droped);

		if (this.props.onChange) this.props.onChange(values, this.props.Id);
	}

	render () {
		var view = this,
			count = this.props.Value.length;

		return(
			<div className={"clearfix form-row " + (this.props.RowClass ? this.props.RowClass : "")}>
				{this.props.Title &&
					<label className="focused" title={this.props.Title}>{this.props.Title}</label>
				}
				<div className="form-input edittags">
					{_.map(this.props.Value, function (item, i) {
						return(<span key={i}>
							<input
								name={i}
								autoComplete="off"
								type={view.props.Type ? view.props.Type : "text"}
								value={item}
								disabled={view.props.Disabled}
								onChange={view.onChange.bind(view)}
							/>
							<a href="/" className="icon remove" onClick={view.onRemove.bind(view)} rel={i}><i></i></a>	
						</span>)
					})}
					{count < this.props.Limit &&
						<a href="/" className="icon add" onClick={this.onAdd.bind(this)}><i></i></a>
					}
				</div>
			</div>
		)
	}
}
EditableTagsPreset = observer(EditableTagsPreset);
export class ButtonGroup extends Component {
	onChange(event) {
		event.preventDefault();

		if (/disabled/.test(event.currentTarget.className) || /active/.test(event.currentTarget.className)) 
			return false;

		var rel = event.currentTarget.getAttribute("rel"),
			isNumber = !_.isNaN(parseInt(rel,10));

		if (this.props.onChange) this.props.onChange(this.props.Type === "date" ? rel : !isNumber 
			? rel : parseInt(rel, 10), this.props.Id);
	}
	
	render () {
		var view = this,
			title = this.props.Title,
			canIcon = this.props.CanIcon;
		
		return(
			<div className={"clearfix form-row " + (this.props.RowClass ? this.props.RowClass : "")}>
				{title && title !== "" && <label className="focused" title={title}>{title}</label>}
				<div className="form-input buttonGroup">
					{_.map(this.props.List, function (item) {
						var canItemIcon = canIcon && !_.isUndefined(item.Icon);
							
						return(<span className={(view.props.Value === item.Id ? "active " : "")
							+ (view.props.Disabled || item.Disabled ? " disabled " : "")
							+ (canItemIcon ? " icon " + item.Icon : "")} rel={item.Id} 
							key={"c" + item.Id} onClick={view.onChange.bind(view)}>
								{canItemIcon && <i></i>}
								{!canItemIcon && item.Name}
							</span>)
					})}
				</div>
			</div>
		)
	}
}
export class SpinnerGroup extends Component {
	/** Обработка кликов на «+» и «-» */
	handleClick(event) {
		event.preventDefault();

		if (/disabled/.test(event.currentTarget.className)) return false;

		var action = event.currentTarget.getAttribute("rel"),
			result = this.props.Value + (action === "minus" ? -1 : 1);

		if (result < this.props.Min) result = this.props.Min;
		if (result > this.props.Max) result = this.props.Max;

		if (this.props.onChange) this.props.onChange(result, this.props.Id);
	}
	/**
	 * Сбор данных с формы ввода числа
	 * @param {string} value число
	 */
	handleChange (value) { 
		var result = value;
		if (result < this.props.Min && result !== "") result = this.props.Min;
		if (result > this.props.Max && result !== "") result = this.props.Max;
		
		if (this.props.onChange) this.props.onChange(result, this.props.Id); 
	}

	render () {
		return(
			<div className={"clearfix form-row " + (this.props.RowClass ? this.props.RowClass : "")}>
				{this.props.Title && this.props.Title !== "" &&
					<label className="focused" title={this.props.Title}>{this.props.Title}</label>
				}
				<div className="form-input spinner">
					<a href="/" rel="minus" className={this.props.Disabled || this.props.DisabledLess ? "disabled" : ""} 
						onClick={this.handleClick.bind(this)}>-</a>
					<TextInput
						Id="Number"
						Value={this.props.Value}
						Type="number"
						Disabled={this.props.Disabled || this.props.DisabledLess}
						onChange={this.handleChange.bind(this)}
					/>
					<a href="/"rel="plus" className={this.props.Disabled ? "disabled" : ""} 
						onClick={this.handleClick.bind(this)}>+</a>
				</div>
			</div>
		)
	}
}
export class SubmitButton extends Component {
	render() {
		var loading = this.props.Loading,
			disabled = this.props.Disabled;

		return(
			<button className={"blue " + (loading ? "loading" : "")} disabled={loading || disabled}>
				{loading && <img src={loader} alt="loading" />}
				{!loading && this.props.Title}
			</button>
		)
	}
}

export class AutoCompletePhone extends Component {
	constructor (props) {
		super(props)
		this.state = { 
			openMenu: false,
			value: this.props.Value
		}
	}

	handleChange(event) {
		var value = (event.target.value).replace(/\D/gi,"");
		
		this.setState({ value: value });
		
		if (value.length === 11 && this.props.onChange) 
			this.props.onChange(value, this.props.Id)
		if (this.props.onInputChange) this.props.onInputChange(value, this.props.Id);

		this.setState({ openMenu : value.length > 6 });
	}

	handleSelect(event) {
		event.preventDefault();
		var value = event.currentTarget.getAttribute("rel");

		this.setState({ openMenu : false, value : value });
		if (this.props.onChange) this.props.onChange(value, this.props.Id);
	}

	handleBlur(event) { this.setState({ openMenu : false }); }

	render() {
		var view = this;

		return(
			<div className={"clearfix form-row " + (this.props.RowClass ? this.props.RowClass : "")}>
				{this.props.Title && this.props.Title !== "" &&
					<label className="focused" title={this.props.Title}>{this.props.Title}</label>
				}
				<div className="form-input autocomplete">
					<InputMask 
						name={this.props.Id}
						mask={this.props.Mask}
						value={this.props.Value}
						required={this.props.Required}
						disabled={this.props.Disabled}
						readOnly={this.props.ReadOnly}
						autoComplete="off"
						onChange={this.handleChange.bind(this)}
					/>
					{!_.isEmpty(this.props.List) && this.state.openMenu &&
						<div className="menu">
							{_.map(this.props.List, function(item) {
								return (<a href="/" className="menuItem" key={item.Id} rel={item.Id}
									onClick={view.handleSelect.bind(view)}>{item.Name}</a>)
							})}
						</div>
					}
				</div>
			</div>
		)
	}
}
AutoCompletePhone=observer(AutoCompletePhone);

export class DatePreset extends Component {
	constructor(props) {
		super(props);

		this.state = { 
			Preset : "month"
		}
	}
	setPreset (event) {
		event.preventDefault();

		// if (this.props.setPreset) this.props.setPreset({
		// 		DateFrom: event.currentTarget.dataset.datefrom,
		// 		DateTo: event.currentTarget.dataset.dateto
		// 	}, event.currentTarget.dataset.preset);

		this.props.onChange(event.currentTarget.dataset.datefrom, "DateFrom");
		this.props.onChange(event.currentTarget.dataset.dateto, "DateTo");
		this.setState({ Preset : event.currentTarget.dataset.preset });
	}

	render() {
		var preset = this.state.Preset;

		return(
			<div className="dates">
				<div className="datepreset">
					<a className={preset === "today" ? "active" : ""}
						href="/" onClick={this.setPreset.bind(this)} data-preset="today"
						data-datefrom={formatDate("today", "r")}
						data-dateto={formatDate("today", "r")}>Сегодня</a>
					<a className={preset === "yesterday" ? "active" : ""}
						href="/" onClick={this.setPreset.bind(this)} data-preset="yesterday"
						data-datefrom={moment().subtract(1, "d").format("YYYY-MM-DD")}
						data-dateto={moment().subtract(1, "d").format("YYYY-MM-DD")}>Вчера</a>
					<a className={preset === "week" ? "active" : ""}
						href="/" onClick={this.setPreset.bind(this)} data-preset="week"
						data-datefrom={moment().subtract(1, "w").format("YYYY-MM-DD")}
						data-dateto={formatDate("today", "r")}>Неделя</a>
					<a className={preset === "month" ? "active" : ""}
						href="/" onClick={this.setPreset.bind(this)} data-preset="month"
						data-datefrom={moment().subtract(1, "M").format("YYYY-MM-DD")}
						data-dateto={formatDate("today", "r")}>Месяц</a>
				</div>
				<DateInput
					Id="DateFrom"
					Title="Начало отчета"
					Value={this.props.DateFrom}
					IsClearable={!_.isUndefined(this.props.IsClearable) ? this.props.IsClearable : true}
					onChange={this.props.onChange}
				/>
				<DateInput
					Id="DateTo"
					Title="Конец отчета"
					Value={this.props.DateTo}
					IsClearable={!_.isUndefined(this.props.IsClearable) ? this.props.IsClearable : true}
					onChange={this.props.onChange}
				/>
			</div>
		)
	}
}

export class MonthPreset extends Component {
	/** Изменение активного месяца */
	onChange(event) {
		event.preventDefault();

		var date = event.target.getAttribute("href").replace("#", "");
		if (this.props.onChange) this.props.onChange(date, this.props.Id);
	}

	render() {
		var today = moment(this.props.Value),
			prev = moment(this.props.Value).subtract(1, "months"),
			next = moment(this.props.Value).add(1, "months"),
			min = this.props.MinDate ? moment(this.props.MinDate) : "",
			max = this.props.MaxDate ? moment(this.props.MaxDate) : "";

		return(
			<div className={"monthsFilter " + (this.props.RowClass ? this.props.RowClass : "")}>
				{(min === "" || !min.isSame(today, "month")) &&
					<a className="prev" href={"#" + prev.format("YYYY-MM")} 
						onClick={this.onChange.bind(this)}>{prev.format("MMMM YYYY")}</a>
				}
				<span>{today.format("MMMM YYYY")}</span>
				{(max === "" || !max.isSame(today, "month")) &&
					<a className="next" href={"#" + next.format("YYYY-MM")} 
						onClick={this.onChange.bind(this)}>{next.format("MMMM YYYY")}</a>
				}
			</div>
		)
	}
}

export class CheckFilter extends Component {
	/** Обработка клика по фильтру */
	onChange (event) {
		if (event)
			event.preventDefault();
		if (this.props.onChange) this.props.onChange(!this.props.Value, this.props.Id);
	}

	render() {
		return(
			<div className={"clearfix form-row " + (this.props.RowClass ? this.props.RowClass : "")}>
				<div className="form-input">
					<div className={"checkFilter " + (this.props.Value ? "active" : "")}>
						<span onClick={this.onChange.bind(this)}><b></b></span>
						<a href='/' onClick={this.onChange.bind(this)}>{this.props.Title}</a>
					</div>
				</div>
			</div>
		)
	}
}
CheckFilter = observer(CheckFilter);