import { observable, decorate } from 'mobx';
import _ from 'lodash';
import { authUserState } from './common';
import { collectCheckBox } from '../functions';
import { POSTypesTitles } from '../dictionary';


class OrderStatusState {
	Statuses = [];
	IsSendRequest = false;

	StatusItem = undefined;
	ButtonLoading = false;
	ErrorCode = "";
	SuccessSave = false;

	AllTimes = [];
	Times = [];

	IsFlexible = false;
	ActiveTimeType = -1;

	StatusDummy = {
		Id: -1,
		Name: "",
		Color: "",
		POSType: [],
		StatusType: [],
		Times: [],
		Time: "",
		Default: false,
		Weight: 0
	}

	/**
	 * Генерация шаблона для заполнения кастомного времени
	 * @param {*} time 
	 */
	generateTime(time) {
		var data = [],
			types = _.map(POSTypesTitles, "Id");

		_.each(types, function (type) {
			_.each([0, 1, 2, 3, 4, 5, 6], function (day) {
				_.each(_.range(0, 24), function (hour) {
					data = _.concat(data, {
						Type: type,
						Day: day,
						Hour: hour,
						Time: time || 0,
					});
				});
			});
		});

		return data;
	}

	/**
	 * Разбор данных от сервера со списком статусов заказа
	 * @param {object} data ответ от сервера
	 */
	setList(data) {
		this.Statuses = data.Success ? _.map(data.OrderStatuses, function (v) {
			return _.extend(v, {
				CanRemove: !v.Default,
				Time: v.MinTime === 0 ? "" : v.MinTime + (v.MinTime === v.MaxTime ? "" :
					" – " + v.MaxTime) + " мин",
				Active: _.indexOf(v.Merchants, authUserState.Merchant) !== -1,
				PosTypes: _.map(v.POSType, function (type) {
					var pos = _.find(POSTypesTitles, { Id: type });
					return pos ? pos.Name : pos;
				}).join(", ")
			});
		}) : [];
		this.IsSendRequest = true;
	}

	/**
	 * Разбор данных от сервера с выбранным статусом заказа
	 * @param {object} status ответ от сервера
	 */
	setItem(status) {
		var store = this

		this.StatusItem = _.isUndefined(status) ? undefined : status.Id === -1 ? _.clone(this.StatusDummy)
			: _.extend(_.omit(status, ["ErrorCode", "Success"]), {
				StatusType: store.setStatusType(status.StatusType)
			});

		if (!_.isUndefined(status)) {
			var responseTimes = status.Times,
				uniqTime = _.uniq(_.map(responseTimes, "Time")),
				time = uniqTime.length === 1 ? _.first(uniqTime) : 0,
				generateTimes = this.generateTime(time);

			this.AllTimes = _.map(generateTimes, function (v) {
				var t = _.find(responseTimes, {
					Day: v.Day, Hour: v.Hour, Type: v.Type,
					MerchantId: authUserState.Merchant
				});
				return _.extend(v, { Time: t ? t.Time : v.Time });
			});

			this.StatusItem.Time = time === 0 ? "" : time;
			this.IsFlexible = uniqTime.length <= 1 ? false : time === 0;

			this.groupTimes();
		}

		this.ActiveTimeType = _.isUndefined(status) || status.Id === -1 || _.isNull(status.POSType)
			|| _.isEmpty(status.POSType) ? -1 : _.first(status.POSType);

		this.ButtonLoading = false;
		this.ErrorCode = "";
	}

	groupTimes() {
		this.Times = _.map(_.groupBy(this.AllTimes, "Type"),
			function (times, type) {
				return { Type: parseInt(type, 10), Hours: _.groupBy(times, "Hour") }
			});
	}

	setStatusType(statusType) {
		var typeString = _.padStart((statusType).toString(2), 8, "0");

		return _.filter(_.map(_.reverse(typeString.split("")), function (v, i) {
			return v === "0" ? undefined : i
		}), function (v) { return !_.isUndefined(v); });
	}

	/**
	 * Сбор данных с формы редактирования статуса
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectStatus(id, value) {
		if (/POSType/.test(id)) {
			var ptype = parseInt(id.split("_")[1], 10);
			this.StatusItem.POSType = collectCheckBox(ptype, value, this.StatusItem.POSType);

			this.ActiveTimeType = _.isEmpty(this.StatusItem.POSType) ? -1 : _.first(this.StatusItem.POSType);
		} else if (/StatusType/.test(id)) {
			var stype = parseInt(id.split("_")[1], 10);
			this.StatusItem.StatusType = collectCheckBox(stype, value, this.StatusItem.StatusType);

			if (stype === 1 && _.indexOf(this.StatusItem.StatusType, 0) === -1)
				this.StatusItem.StatusType = _.concat(this.StatusItem.StatusType, 0);
		} else this.StatusItem[id] = value;

		if (/Time/.test(id)) {
			_.map(this.AllTimes, function (v) { return _.extend(v, { Time: value }); });
			this.groupTimes();
		}
	}

	/**
	 * Сбор данных с формы редактирования времени
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 * @param {number} day номер дня
	 * @param {number} hour номер часа
	 */
	collectTime(id, value, day, hour) {
		var index = _.clone(this.ActiveTimeType) - 1;
		this.Times[index].Hours[hour][day][id] = value;
	}

	/**
	 * Сбор данных статуса для сохранения
	 * @returns {object} данные для отправки на сервер
	 */
	getSaveData() {
		var data = _.clone(this.StatusItem),
			statusType = [0, 0, 0, 0, 0, 0, 0, 0],
			times = _.flatten(_.map(this.Times, function (v) { return _.flatMap(v.Hours); }));

		times = _.filter(times, function (v) { return _.indexOf(data.POSType, v.Type) !== -1; });

		if (data.Color === "") data = _.omit(data, ["Color"]);
		data.POSType = data.POSType.join(",");

		_.each(data.StatusType, function (v) { statusType[7 - v] = 1; });
		data.StatusType = statusType.join("");

		data = _.omit(data, ["Time", "Times", "Default"]);
		if (!_.isEmpty(times))
			_.each(times, function (time, i) {
				var name = "Times." + i + ".";

				_.each(time, function (value, field) { data[name + field] = value; });
				data[name + "MerchantId"] = authUserState.Merchant;
			});

		return data;
	}

	/**
	 * Проверка корректности заполнения формы
	 * @returns {boolean} флаг корректности данных
	 */
	validateData() {
		var isValid = true;

		if (_.isEmpty(this.StatusItem.POSType)) {
			this.ErrorCode = "POSTYPE_REQUIRED";
			return false;
		}

		return isValid;
	}

	/**
	 * Сбор данных для ихменения порядка статусов
	 * @returns {string} ID статусов через «,» в новом порядке
	 */
	getOrderData() { return { Ids: _.map(_.clone(this.Statuses), "Id").join(",") }; }
}
decorate(OrderStatusState, {
	Statuses: observable,
	IsSendRequest: observable,

	StatusItem: observable,
	ButtonLoading: observable,
	ErrorCode: observable,
	SuccessSave: observable,

	IsFlexible: observable,
	Times: observable,
	ActiveTimeType: observable
});
export const orderStatusState = new OrderStatusState();