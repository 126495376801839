import { observable, decorate, autorun } from 'mobx';
import _ from 'lodash';
import moment from 'moment';
import { checkRights, clearEmptyParams, collectCheckBox, formatDate } from '../functions';
import { authUserState, uistate } from './common';
import { daysShort } from '../dictionary';

class List {
	constructor(list, action) {
		this.Id = list && list.Id ? action === "copy" ? -1 : list.Id : -1;
		this.Active = list && list.Active ? list.Active : true;
		this.Name = list && list.Name ? action === "copy" ? ("Копия " + list.Name) : list.Name : "";
		this.RoleId = list && list.RoleId ? list.RoleId : -1;
		this.UserId = list && list.UserId ? list.UserId : -1;
		this.FilialId = list && list.FilialId ? list.FilialId : -1;

		this.Groups = [];

		if (_.isUndefined(list.Tasks) || _.isEmpty(list.Tasks) || _.isNull(list.Tasks)) {
			this.Required = true;
			this.Groups.push(new Group())
		} else {
			this.Required = _.some(list.Tasks, "Required");
			if (action === "copy") {
				var tasks = _.map(list.Tasks, (v) => { return _.extend(v, { Id : -1}); });
				this.setGroups(tasks);
			} else this.setGroups(list.Tasks);
		}
	}

	/**
	 * Разбор существующих задач
	 * @param {[Task]} tasks массив задач чек листа
	 */
	setGroups(tasks) {
		_.each(_.groupBy(tasks, "Group"), (group, name) => {
			this.Groups.push(new Group({ Group : name, Tasks : group }));
		});
	}

	/** Добавление новой группы задач */
	addGroup() { this.Groups.push(new Group()); }

	/**
	 * Удаление группы задач по индексу
	 * @param {number} index индекс группы
	 */
	deleteGroup(index) { this.Groups = _.filter(this.Groups, (v,i) => { return i !== index; }); }

	/**
	 * Добавление/Копирование новой задачи в групу
	 * @param {number} index индекс группы
	 * @param {Task} task объект задачи для копирования
	 */
	addTask(index, task) { 
		if (!_.isUndefined(task)) {
			var t = _.extend(_.clone(task), {
				Id : -1,
				Name : "Копия " + task.Name
			})
			this.Groups[index].Tasks.push(new Task(t))
		} else this.Groups[index].Tasks.push(new Task()); 
	}

	/**
	 * Удаление задачи из группы по индексу
	 * @param {number} groupIndex индекс группы
	 * @param {number} index индекс задачи
	 */
	deleteTask(groupIndex, index) {
		this.Groups[groupIndex].Tasks = _.filter(this.Groups[groupIndex].Tasks, (v,i) => { return i !== index; });
	}

	/**
	 * Сбор данных с формы редактирования чек листа
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 * @param {number} gIndex индекс группы
	 * @param {number} tIndex индекс задачи
	 */
	collect(id, value, gIndex, tIndex) { 
		if (!_.isUndefined(tIndex)) {
			if (/Individual/.test(id)) this.Groups[gIndex].Tasks[tIndex].Individual = value === "true";
			else if (/Time/.test(id)) {
				this.Groups[gIndex].Tasks[tIndex].StartTime = value.StartTime;
				this.Groups[gIndex].Tasks[tIndex].EndTime = value.EndTime;
			} else if (/PeriodDescriptionW/.test(id)) {
				var day = _.last(id.split("_"));
				this.Groups[gIndex].Tasks[tIndex].PeriodDescriptionW = collectCheckBox(day, value, this.Groups[gIndex].Tasks[tIndex].PeriodDescriptionW);
			} else if (/NeedPhoto/.test(id)) {
				this.Groups[gIndex].Tasks[tIndex].NeedPhoto = value;
				this.Groups[gIndex].Tasks[tIndex].CPhotos = value ? 1 : -1;
			} else this.Groups[gIndex].Tasks[tIndex][id] = value;
		} else if (!_.isUndefined(gIndex)) {
			this.Groups[gIndex][id] = value;
		} else {
			if (/Required/.test(id)) this.Required = value === "true";
			else this[id] = value; 
		}
	}

	/** Проверка корректности заполнения чек листов перед отправкой на сервер
	 * 1. Все названия подразделов должны быть заполнены
	 * 2. Все названия задачи должны быть заполнены
	 * 3. Если чек листы обязателен должна быть выбрана периодичность выполнения задачи - дневной недельный и тд
	 * 4. Если чек листы обязателен должно быть заполнено описание периодичность выполнения задачи
	 * 5. Если чек листы обязателен должна быть указано время выполнения задачи
	 * @returns {{IsValid : boolean, ErrorCode : string}}
	 */
	validate() {
		var isValid = true,
			errorCode = "",
			tasks = this.getFlattenTasks(this.Groups);

		_.each(tasks, (item) => {
			if (item.Name === "") {
				errorCode = "WRONG_PARAMS_NAME";
				isValid = false;
				return false;
			}

			if (item.Type === "w" && _.isEmpty(item.PeriodDescriptionW) && this.Required) {
				errorCode = "ERR_WRONG_PARAMS_PERIOD_DESCRIPTION";
				isValid = false;
				return false;
			}

			if (item.Type === "m" && item.PeriodDescriptionM === "" && this.Required) {
				errorCode = "ERR_WRONG_PARAMS_PERIOD_DESCRIPTION";
				isValid = false;
				return false;
			}
			// TODO в поле PeriodDescriptionM могут быть только цифры пробелы и запятые

			if (this.Required && item.StartTime === "") {
				errorCode = "ERR_WRONG_PARAMS_STARTTIME";
				isValid = false;
				return false;
			}
		});

		return { IsValid : isValid, ErrorCode : errorCode };
	}

	/** Сборка данных чек листа для отправки на сервер */
	getListtoRequest () {
		var data = {
				Id : this.Id,
				Active : this.Active,
				Name : this.Name,
				RoleId : this.RoleId,
				UserId : this.UserId,
				FilialId : this.FilialId
			},
			tasks = this.getFlattenTasks(this.Groups);

		data = _.omitBy(data, (v, i) => { return i !== "Id" && (v === -1 || v === 0); });

		data.Tasks = _.map(tasks, (task) => {
			var periodD = this.Required ? task.Type === "d" ? "" : 
				task.Type === "w" ? "|" + task.PeriodDescriptionW.join(",") :
				task.Type === "m" ? "|" + task.PeriodDescriptionM : "" : "";

			task = _.omit(task, ["PeriodDescriptionM", "PeriodDescriptionW"]);
			
			return _.extend(task, {
				StartDate : this.Required ? task.StartDate : "",
				StartTime : this.Required ? task.StartTime : "",
				EndTime : this.Required ? task.EndTime :  "",
				Push : this.Required ? task.Push : false,
				Required : this.Required,
				Period : this.Required ? task.Period : 1,
				PeriodDescription : task.Type + periodD,
				CPhotos : task.NeedPhoto ? task.CPhotos : -1
			})
		});

		return data;
	}

	/** Сборка задач из групп в плоский массив 
	 * @param {[Group]} groups массив групп задач
	 * @returns {[Task]} массив задач
	 */
	getFlattenTasks (groups) {
		return _.flatten(_.map(groups, (group) => {
			return _.map(group.Tasks, function (task) { return _.extend(task, { Group : group.Group }); });
		}));
	}

}
decorate(List, {
	Id : observable,		// ID листа
	Active : observable,	// Флаг активности листа
	Name : observable,		// Название листа
	RoleId : observable,	// ID роли, к которой привязан чек лист
	UserId : observable,	// ID пользователя, к которому привязан чек лист
	FilialId : observable,	// ID филиала, к которому привязан чек лист
	Required : observable,	// Флан обязательности чек листа

	Groups : observable		// Группы задач
});

class Group {
	constructor(group) {
		this.Group = group && group.Group ? group.Group : "";
		this.GroupPeriodSettings = false;
		this.Tasks = [];

		// TODO определить одинаковые ли настройки времени в группе GroupPeriodSettings

		if(_.isUndefined(group) || _.isUndefined(group.Tasks) || _.isEmpty(group.Tasks) || _.isNull(group.Tasks))
			this.Tasks.push(new Task())
		else _.each(group.Tasks, (task) => { this.Tasks.push(new Task(task)); });
	}
}
decorate(Group, {
	Group : observable,	// Название группы задач
	Tasks : observable,		// Задачи
});

class Task {
	constructor(task) {
		this.Id = task && task.Id ? task.Id : -1;
		this.Name = task && task.Name ? task.Name : "";
		this.Description = task && task.Description ? task.Description : "";
		this.StartDate = task && task.StartDate ? task.StartDate :  moment().format("YYYY-MM-DD");
		this.Individual = task && !_.isUndefined(task.Individual) ? task.Individual : true;
		this.StartTime = task && task.StartTime ? task.StartTime : "";
		this.EndTime = task && task.EndTime ? task.EndTime :  "";
		this.Push = task && !_.isUndefined(task.Push) ? task.Push : true;
		this.CPhotos = task && task.CPhotos ? task.CPhotos : -1;
		this.NeedPhoto = task && task.CPhotos ? task.CPhotos > 0 : false;
		this.ControlRequired = task && task.ControlRequired ? task.ControlRequired : false;
		
		this.Period = task && task.Period ? task.Period : 1;
		this.Type = "d";
		this.PeriodDescriptionW = [];
		this.PeriodDescriptionM = "";

		if (task && task.PeriodDescription) {
			var type = task.PeriodDescription.split("|")[0],
				period = task.PeriodDescription.split("|")[1];

			this.Type = type;
			switch(type) {
				case "w":
					this.PeriodDescriptionW = period ? period.split(",") : [];
					break;
				case "m":
					this.PeriodDescriptionM = period ? period : "";
					break;
				default:
					this.PeriodDescriptionM = "";
					this.PeriodDescriptionW = [];
			}
		} 
	}
}
decorate(Task, {
	Name : observable,					// Название задачи
	Description : observable,			// Описание задачи
	StartDate : observable,				// Дата начала повторения
	Period : observable,				// Частота повторения
	Individual : observable,			// Флаг индивидуальности задачи
	StartTime : observable,				// Время начала выполнения задачи
	EndTime : observable,				// Время окончания выполнения задачи
	Type : observable,					// Тип периодичности - день, неделя, месяц
	PeriodDescriptionM : observable,	// Дни месяца повторения
	PeriodDescriptionW : observable,	// Дни недели повторения
	Push : observable,					// Флаг отправки уведомлений
	CPhotos : observable,				// Флаг отправки фото при выполнении: -1 - не прикладывать, 0 - можно отправить, необязательно, 1 и более - кол-во обязательных фото
	ControlRequired: observable,		// Флаг обязательной проверки задачи
	NeedPhoto : observable				// Флаг отправки фото, если CPhotos > 1
});

class CheckListState {
	RequiredList = [
		{ Id : "true", Title : "Задачи по чек листу создаются при открытии смены пользователем" },
		{ Id : "false", Title : "Задачи по чек листу создаются по требованию" }
	];
	TypeList = [
		{ Key : "d", Value : "Каждый день" },
		{ Key : "w", Value : "Каждую неделю" },
		{ Key : "m", Value : "Каждый месяц" }
	];
	IndividualList = [
		{ Id : "true", Title : "Должен выполнить каждый" },
		{ Id : "false", Title : "Может выполнить любой" }
	];
	DaysList = _.map(daysShort, function (v,i) { return { Id : i.toString(), Title : v }; });

	constructor() {
		this.CanManage = false;

		this.Lists = [];
		this.Templates = [];
		this.List = undefined;

		this.IsSendRequest = false;
		this.TaskCounter = 0;

		this.MessageParams = {
			UserFirstName : "",
			UserEmail : "",
			UserPhone : "",
			MerchantName : "",
			MerchantID : -1
		}
	}

	disposer = autorun(() => {
		// console.log(authUserState)
		if (!_.isUndefined(authUserState) && !_.isUndefined(authUserState.User) && !_.isNull(authUserState.User)) {
			this.CanManage = checkRights("ManageCheckList");
			this.MessageParams.UserFirstName = authUserState.User.FirstName;
		}

		if (!_.isUndefined(authUserState) && !_.isUndefined(authUserState.CurrentMerchant)) {
			this.MessageParams.MerchantName = authUserState.CurrentMerchant.Name;
			this.MessageParams.MerchantID = authUserState.CurrentMerchant.Id;
		} 
	});

	/** Формирование списка пользователей */
	get UsersList () {
		if (_.isUndefined(this.Users) || _.isEmpty(this.Users)) return [];

		return _.map(this.Users, (v) => { return { Id : v.Id, Name : v.FirstName + " " + v.LastName }});
	}

	/** Формирование столбцов таблицы */
	get TableListColumns () {
		var columns = uistate.IsMobile ? { Name: {}, Required : { filterable : false, width : 60 , Header : "Обязат." } } 
			: { Name: {}, Role: {}, Filial: {}, User : {}, Required : { filterable : false, width : 60, Header : "Обязат."  } };

		if (this.CanManage) {
			if (uistate.IsMobile) _.extend(columns, { View : {} });
			else _.extend(columns, { Edit: {}, Copy: {}, Remove: {} });
		}

		return columns;
	}
	get TableTemplateColumns () {
		var columns = { Name: {} };
		if (this.CanManage) _.extend(columns, uistate.IsMobile ? { View: {} } : { Copy : {} });

		return columns;
	}

	/**
	 * Разбор списка чек листов
	 * @param {object} data ответ от сервера
	 */
	setLists (data) {
		this.Lists = data.Success ? data.Checklists : [];
		this.IsSendRequest = true;
	}

	/**
	 * Разбор списка чек листов
	 * @param {object} data ответ от сервера
	 */
	setTemplates (data) {
		this.Templates = data.Success ? data.Checklists : [];
	}

	/**
	 * Разбор выбранного чек-листа
	 * @param {object} data ответ от сервера
	 * @param {string} type тип операции
	 */
	setList(data, type) {
		if (!_.isUndefined(data))
			this.List = new List(data, type);
		else this.List = undefined;

		this.ButtonLoading = false;
		this.SuccessSave = false;
		this.ErrorCode = "";
		this.IsSendRequest = true;
	}

	/** Проверка корректности заполнения чек листов перед отправкой на сервер
	 * @returns {boolean} Флаг валидности заполнения формы
	 */
	validateData() {
		var result = this.List.validate();
		this.ErrorCode = result.ErrorCode;
		this.SuccessSave = false;

		return result.IsValid;
	}
}
decorate(CheckListState, {
	Lists : observable,
	Templates : observable,
	Users: observable,
	Filials : observable,
	Roles: observable,
	
	List: observable,
	
	IsSendRequest: observable,
	ButtonLoading: observable,
	SuccessSave: observable,
	ErrorCode: observable
});
export const checkListState = new CheckListState();


class PhotoExecution {
	constructor(id, cPhoto = 1) {
		this.Id = id;
		this.CPhoto = cPhoto;
		this.Photos = _.fill(Array(cPhoto), null);
		this.Comment = "";
		this.ErrorCode = "";
	}

	/**
	 * Сбор данных с формы выполнения задачи
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 * @param {number} index порядковый индекс фотографии
	 */
	collect (id, value, index) {
		this.ErrorCode = "";
		if (!_.isUndefined(index)) this.Photos[index] = value;
		else this[id] = value;
	}

	/** Проверка количества фото 
	 * @returns {boolean} Количество приложенных фото, соответствует требуемому
	 */
	validate () {
		this.ErrorCode = "";
		var valid = _.reject(this.Photos, (v) => { return _.isNull(v)}).length >= this.CPhoto;
		if (!valid) this.ErrorCode = "COUNT_PHOTOS_REQUIRED";
		return valid;
	}

	/** Сборка фото для отправки на сервер 
	 * @returns {{ Id : number, Photos : [object], Comment : string }} Данные для отправки на сервер
	*/
	getData () {
		var data = {
				Id : this.Id,
				Comment : this.Comment,
			},
			index = 0;

		_.each(this.Photos, (photo) => {
			if (_.isObject(photo)) {
				data["Photo." + index] = photo.files;
				index++;
			}
		});

		return data;
	}
}
decorate(PhotoExecution, {
	Id : observable, 		// ID выполняемой задачи
	CPhoto : observable, 	// Кол-во необходимых фото
	Photos : observable,	// Массив фотографий
	Comment : observable, 	// Комментарий к выполнению задачи
	ErrorCode : observable	// Код ошибки
})

class CheckListTaskState {
	/** Каждый 5 минут обновляем значения текущего времени */
	constructor() {
		this.Filter = {
			ShowGroup : true,
			ShowDoned : true,
			OnlyExpired : false,
			OnlyReturned : false
		}

		this.Tasks = [];
		this.CheckLists = [];
		this.ActivateList = false;
		this.Now = moment();
		this.OpenPhoto = "";

		setInterval(() => this.Now = moment(), 300000);
	}

	/**
	 * Сборка настроек отображения задач
	 * @param {string} id название параметра
	 * @param {boolean} value значение параметра
	 */
	collectFilter(id, value) { this.Filter[id] = value; }

	/**
	 * Подсчет статистики по задач пользователя
	 * @returns {{Total : int, Done : int, Expired : int}} Количество задач в статусе
	 */
	get TaskStat () {
		if (_.isUndefined(this.Tasks) || _.isEmpty(this.Tasks)) return {};

		var now = this.Now,
			expiredTasks = _.filter(this.Tasks, function(v) {
				var start = !_.isNull(v.StartTime) && !_.isUndefined(v.StartTime) && v.StartTime !== "" ? moment(v.StartTime) : "",
					end = !_.isNull(v.EndTime) && !_.isUndefined(v.EndTime) && v.EndTime !== "" ? moment(v.EndTime) : "",
					expired = end !== "" ? now.isAfter(end) : start !== "" ? now.isAfter(start) : false;

				return !v.Done && expired;
			});

		return {
			Total : this.Tasks.length,
			Done : _.filter(this.Tasks, { Done : true}).length,
			DonePercent : _.filter(this.Tasks, { Done : true}).length*100/(this.Tasks.length),
			Expired : expiredTasks.length,
			ExpiredPercent : expiredTasks.length*100/(this.Tasks.length)
		}
	}

	/** Формирование списка задач
	 * @returns {[object]}
	 */
	get TasksList () {
		if (_.isEmpty(this.Tasks)) return [];

		var	limit = 10,
			now = this.Now,
			tasks = _.map(this.Tasks, (item) => {
				var start = !_.isNull(item.StartTime) && !_.isUndefined(item.StartTime) && item.StartTime !== "" ? moment(item.StartTime) : "",
					end = !_.isNull(item.EndTime) && !_.isUndefined(item.EndTime) && item.EndTime !== "" ? moment(item.EndTime) : "",
					time = end !== "" ? (formatDate(item.StartTime, "с HH:mm") + formatDate(item.EndTime, " до HH:mm"))
						: start !== "" ? formatDate(item.StartTime, "в HH:mm") : "любое время",
					expired = !item.Done && (end !== "" ? now.isAfter(end) : start !== "" ? now.isAfter(start) : false),
					disabled = item.StartTime === "" ? false : now.isBefore(start.subtract(limit, "minutes"));

				return _.extend(_.clone(item), {
					ClassName : item.CurrentStatus === 2 ? "oncheck" : item.CurrentStatus === 4 ? "rejected" : item.Done ? "done" : expired ? "expired" : disabled ? "disabled" : "",
					Time : time,
					Expired : expired,
					Button : {
						Title : item.CurrentStatus === 2 ? "Ожидает проверки" : item.Done ? "Выполнено" : expired ? "Просрочено" : "Выполнить",
						Color : item.CurrentStatus === 4 ? "red" : item.Done ? "green" : expired ? "red" : "blue",
						Disabled : item.Done || item.CurrentStatus === 2 ? true : disabled
					},
					Statuses : _.reject(_.clone(item.Statuses), ({ StatusId : 1 })),
					NotReturned : item.CurrentStatus !== 4,
					SortTime : moment
				});
			});

		tasks = this.Filter.OnlyExpired ? _.filter(tasks, { Expired : true }) : 
			this.Filter.OnlyReturned ? _.filter(tasks, { CurrentStatus : 4 }) : 
			this.Filter.ShowDoned ? tasks : _.filter(tasks, (item) => { return item.CurrentStatus === 1 || item.CurrentStatus === 4 });

		return tasks = this.Filter.ShowGroup ? 
			_.sortBy(tasks, ["NotReturned", "ListName", "Group", "StartTime", "EndTime"]) :
			_.sortBy(tasks, ["NotReturned", "StartTime", "EndTime"])
	}

	/** Получение списка дополнительных чек-листов 
	 * @returns {[{ Id : number, Title : string }]} список чек листов для активации
	*/
	get ActivationLists () {
		if (_.isUndefined(this.CheckLists) || _.isEmpty(this.CheckLists)) return [];

		var ids = _.uniq(_.map(this.Tasks, function (v) { return v.ListId })),
			role = authUserState.Role.Name,
			filial = authUserState.Filial.Name,
			list = _.filter(this.CheckLists, function(v) {
				return !v.Required && (v.Role === role || v.Role === "")
					&& (v.Filial === filial || v.Filial === "") && _.indexOf(ids, v.Id) === -1;
			});

		return _.map(list, function(v) { return { Id : v.Id, Title : v.Name }});
	}

	/** Параметры для выполнения задачи с фото 
	 * @param {number} id ID задачи
	 * @param {number} cPhoto кол-во фото
	 */
	setTaskPhoto (id, cPhoto) {
		this.TaskPhoto = _.isUndefined(id) ? undefined : new PhotoExecution(id, cPhoto);
	}

}
decorate(CheckListTaskState, {
	Filter: observable,			// Фильтр задач на выполнение
	Tasks : observable,			// Список задач, ответ от сервера
	CheckLists : observable,	// Список чек листов, ответ от сервера
	ActivateList : observable,	// Флаг отображения списка дополнительных чек листов
	TaskPhoto : observable,		// Параметры для отправки задачи с фото
	OpenPhoto : observable,		// URL фото для открытия в большом окне
	Now : observable
});
export const checkListTaskState = new CheckListTaskState();

class ReviewTask {
	constructor(id) {
		this.Id = id;
		this.Approve = false;
		this.Comment = "";
		this.ErrorCode = "";
	}

	collect(id, value) { this[id] = value; }

	getData() {
		return {
			Id : this.Id,
			Approve : this.Approve,
			Comment : this.Comment
		}
	}
}
decorate(ReviewTask, {
	Comment : observable,	// Причина отклонения задачи
	ErrorCode : observable,	// Код ошибки запроса
})
class CheckListReportState {
	Limit = 100;

	constructor() {
		this.Filter = {
			Id : -1,
			RoleId : -1,
			UserId : -1,
			FilialId : -1,
			DateFrom: formatDate(moment().subtract(1, "M"), "r"),
			DateTo : formatDate("today", "r"),
			Required : false,
			Limit : this.Limit,
			Offset : 0
		}

		this.RateFilter = {
			DateFrom: formatDate(moment().subtract(1, "M"), "r"),
			DateTo : formatDate("today", "r"),
			IsIndividual : false
		}

		this.HasMore = false;

		this.Users = [];
		this.Filials = [];
		this.Roles = [];
		this.CheckLists = [];

		this.UsersRate = [];
		this.ListsRate = [];
		this.TasksRate = [];

		this.Tasks = [];
		this.TasksStat = {};
		this.ControlTasks = [];
		this.Groups = [];
		this.IsSendRequest = false;
		this.OpenPhoto = "";

		this.openRate();
	}

	/**
	 * разбор ответа сервера со списком пользователей
	 * @param {object} data 
	 */
	setUsers (data) {
		this.Users = data.Success ? _.map(data.Users, function(v) {
			return _.extend(v, { Name : v.FirstName + " " + v.LastName });
		}) : [];
	}

	/** Разбор ответа от сервера с группами задач
	 * @param {object} data 
	 */
	setGroups (data) {
		this.Groups = [];
		this.Groups = data.Success && !_.isNull(data.TaskReportGroups) ? 
			_.map(data.TaskReportGroups, function(item) {
				return(_.extend(item, {
					DonePercent : item.DoneTasks/item.TotalTasks,
					ExpiredPercent : item.ExpiredTasks/item.TotalTasks
				}))
			}) : [];
		this.HasMore = data.HasMore;
		this.IsSendRequest = true;
	}

	/** Разбор ответа от сервера со статистикой
	 * @param {*} data 
	 */
	setStat (data) {
		this.TasksStat = data.Success ? _.omit(_.clone(data), ["Success", "ErrorCode"]) : {}
	}

	/** Добавление задач в группу 
	 * @param {object} data ответ от сервера со списком задач в группе
	 * @param {string} group ID группы
	 */
	setTasks (data, group) {
		if (!data.Success || _.isEmpty(data.TaskReportElements)) return false;
		
		var index = _.findIndex(this.Groups, { GroupName : group })
		if (index === -1) return false;

		this.Groups[index] = _.extend(this.Groups[index], {
			Tasks : _.map(data.TaskReportElements, (v) => {
				var scheduleString = v.TimeToDo.split(" "),
					lastStatus = this.getLastExecutionStatus(v.Statuses);

				return _.extend(v, {
					Schedule : !_.isUndefined(scheduleString) && !_.isUndefined(scheduleString[1]) ? scheduleString[1] : "",
					Expired : v.DoneTime !== "" && !v.InTime,
					Photos : lastStatus ? lastStatus.Photos : [],
					Statuses : _.reject(v.Statuses, { StatusId : 1})
				});
			})
		});
	}

	/** Получение последних фото по задаче
	 * @param {object} statuses Набор статусов задачи
	 */
	getLastExecutionStatus(statuses) {
		if (_.isUndefined(statuses) || _.isNull(statuses) || _.isEmpty(statuses)) return [];

		var doneStatus = _.findLast(statuses, { StatusId : 2 });
		return doneStatus;
		// return _.isUndefined(doneStatus) ? [] : doneStatus.Photos
	}

	/**
	 * Сбор данных с формы поиска 
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectFilter (id, value) { this.Filter[id] = value; }

	/** Сбор данных для отправки запроса поиска */
	getData () { return clearEmptyParams(this.Filter); }

	/**
	 * Открытие окна с полным рейтингом
	 * @param {string} type тип рейтинга
	 */
	openRate(type) {
		if (_.isUndefined(type) || type === "") this.FullRate = undefined;
		else if (type === "user") this.FullRate = { Type : type, List : this.UsersRate };
		else if (type === "list") this.FullRate = { Type : type, List : this.ListsRate };
		else if (type === "task") this.FullRate = { Type : type, List : this.TasksRate };
		else this.FullRate = undefined;
	}

	setControlTasks(data) {
		this.ControlTasks = !data.Success || _.isNull(data.Tasks) || _.isEmpty(data.Tasks) ? [] :
			_.map(data.Tasks, (task) => {
				var lastStatus = this.getLastExecutionStatus(task.Statuses);
				return _.extend(task, {
					Photos : lastStatus ? lastStatus.Photos : [],
					Time : lastStatus ? lastStatus.Time : "",
					UserName : lastStatus ? lastStatus.UserName : "",
					RoleName : lastStatus ? lastStatus.RoleName : "",
					Comment : lastStatus ? lastStatus.Comment : "",
					Statuses : _.reject(task.Statuses, { StatusId : 1 })
				});
			})
	}

	setReview (id) {
		this.ReviewTask = !_.isUndefined(id) ? new ReviewTask(id) : undefined;
	}
}
decorate(CheckListReportState, {
	HasMore: observable,		// Флаг наличия еще записей в таблице
	IsSendRequest: observable,	// Флаг отправки запроса
	Filter: observable,			// Объект фильтра отчета
	RateFilter : observable,	// Объект фильтра для рейтинга

	Users : observable,			// Список пользователей
	Filials : observable,		// Список Филиалов
	Roles : observable,			// Список должностей
	CheckLists : observable,	// Список Чек листов

	UsersRate : observable,		// Рейтинг пользователей по выполнению чек листов
	ListsRate : observable, 	// Рейтинг выполняемости чек листов
	TasksRate : observable,		// Рейтинг выполняемости задач

	FullRate : observable,		// Полный рейтинг

	TasksStat : observable,		// Статистика по задачам
	Groups : observable,		// Группы задач
	ControlTasks : observable,	// Список задач на проверку
	OpenPhoto : observable,		// URL увеличенной фотографии
	ReviewTask : observable		// форма отклонения задачи
});
export const checkListReportState = new CheckListReportState();

class TaskListWidgetState {
	/**
	 * Подсчет статистики по задач пользователя
	 * @returns {{Total : int, Done : int, Expired : int}} Количество задач в статусе
	 */
	get TaskStat () {
		if (_.isUndefined(this.Tasks) || _.isEmpty(this.Tasks)) return {};

		var now = moment(),
			expiredTasks = _.filter(this.Tasks, function(v) {
				var start = !_.isNull(v.StartTime) && !_.isUndefined(v.StartTime) && v.StartTime !== "" ? moment(v.StartTime) : "",
					end = !_.isNull(v.EndTime) && !_.isUndefined(v.EndTime) && v.EndTime !== "" ? moment(v.EndTime) : "",
					expired = end !== "" ? now.isAfter(end) : start !== "" ? now.isAfter(start) : false;

				return !v.Done && expired;
			});

		return {
			Total : this.Tasks.length,
			Done : _.filter(this.Tasks, { Done : true}).length,
			DonePercent : _.filter(this.Tasks, { Done : true}).length/(this.Tasks.length),
			Expired : expiredTasks.length,
			ExpiredPercent : expiredTasks.length/(this.Tasks.length)
		}
	}

	/** Формирование списка задач
	 * @returns {[object]}
	 */
	get TasksList () {
		if (_.isEmpty(this.Tasks)) return [];

		var tasks = _.sortBy(_.filter(this.Tasks, { Done : false }), "StartTime"),
			limit = 10,
			now = moment();

		tasks = _.map(tasks, function(item, i) {
			var start = !_.isNull(item.StartTime) && !_.isUndefined(item.StartTime) && item.StartTime !== "" ? moment(item.StartTime) : "",
				end = !_.isNull(item.EndTime) && !_.isUndefined(item.EndTime) && item.EndTime !== "" ? moment(item.EndTime) : "",
				// time = end !== "" ? (formatDate(item.StartTime, "с HH:mm") + formatDate(item.EndTime, " до HH:mm"))
				// 	: start !== "" ? formatDate(item.StartTime, "в HH:mm") : "любое время",
				expired = !item.Done && (end !== "" ? now.isAfter(end) : start !== "" ? now.isAfter(start) : false),
				disabled = item.StartTime === "" ? false : now.isBefore(start.subtract(limit, "minutes"));

			return _.extend(_.clone(item), {
				ClassName : item.Done ? "done" : expired ? "expired" : disabled ? "disabled" : "",
				// Time : time,
				Button : {
					Color : item.Done ? "green" : expired ? "red" : "blue",
					Disabled : item.Done ? true : disabled
				}
			});
		})

		return _.take(tasks, 4);
	}
}
decorate(TaskListWidgetState, {
	Tasks : observable,		// Ответ от сервера со списком задач
});
export const taskListWidgetState = new TaskListWidgetState();

class CheckListWidgetState {
	constructor () {
		this.MessageParams = {
			UserFirstName : "",
			UserEmail : "",
			UserPhone : "",
			MerchantName : "",
			MerchantID : -1
		}
	}

	disposer = autorun(() => {
		if (!_.isUndefined(authUserState) && !_.isUndefined(authUserState.User)) 
			this.MessageParams.UserFirstName = authUserState.User.FirstName;

		if (!_.isUndefined(authUserState) && !_.isUndefined(authUserState.CurrentMerchant)) {
			this.MessageParams.MerchantName = authUserState.CurrentMerchant.Name;
			this.MessageParams.MerchantID = authUserState.CurrentMerchant.Id;
		} 
	});
}
decorate(CheckListWidgetState, {
	MessageParams : observable,		// Ответ от сервера со списком задач
});
export const checkListWidgetState = new CheckListWidgetState();

class CheckListReportWidgetState {
	constructor () {
		this.Filter = {
			DateFrom: formatDate(moment().subtract(1, "M"), "r"),
			DateTo : formatDate("today", "r"),
			Required : false,
		}

		this.RateFilter = {
			DateFrom: formatDate(moment().subtract(1, "M"), "r"),
			DateTo : formatDate("today", "r"),
			IsIndividual : false
		}

		this.Users = [];
		this.Stat = {};
	}

	/** Разбор ответа от сервера со статистикой
	 * @param {*} data 
	 */
	setStat (data) {
		if (!data.Success || data.TotalTasks === 0) this.Stat = {};
		else {  
			var done = (data.DoneTasks/data.TotalTasks)*100/2,
				expired = ((data.ExpiredTasks/data.TotalTasks)*100/2) + done + 0.4;

			this.Stat = {
				Total : data.TotalTasks,
				Done : data.DoneTasks,
				Expired : data.ExpiredTasks,
				Failed : data.FailedTasks,
				Gradient : "conic-gradient(var(--green) 0.00% " + done + "%, var(--white) " + done + "% " + (done + 0.4) + "%, var(--yellow) " + (done + 0.4) + "% " + expired + "%, var(--white) " + expired + "% " + (expired + 0.4) + "%, var(--red) " + (expired + 0.4) + "% )"
			}
		}
	}

	/** Разбор ответа от сервера c рейтингов пользователей
	 * @param {*} data 
	 */
	setUsers (data) {
		var users = data.Success && !_.isUndefined(data.Users) ? data.Users : [];
		this.Users = [];

		if (users.length > 0) this.Users.push(users[0]);
		if (users.length > 1) this.Users.push(_.last(users));
	}

}
decorate(CheckListReportWidgetState, {
	Stat : observable,
	Users : observable
});
export const checkListReportWidgetState = new CheckListReportWidgetState();