import React, { Component } from 'react';
import { observer } from "mobx-react";
import _ from 'lodash';

import loader from "../images/loader.svg";

import { DataTable } from '../tables/Tables';
import { CheckGroup, SubmitButton } from '../forms/FormItems';

import { dashboardsTitles, rightsTitles, errors, buttonTitles, notifications } from '../dictionary';
import { checkedRights, checkedBoards, userState } from '../stores/rolesusers';
import { uistate, confirmState, errorState, pushState, authUserState } from '../stores/common';
import { toCheckList } from '../functions';
import { ArrowIcon } from '../images/icons';

export class TableFilterPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen : false,
			Filter : this.props.Filter
		}
	}

	toggleFilter (event) {
		event.preventDefault();
		this.setState({ isOpen : !this.state.isOpen });
	}

	setFilter (event) {
		event.preventDefault();
		var filter = event.target.getAttribute("rel");
		this.props.setFilter(filter);
	}

    render() {
		var view = this,
			CanOrder = _.isUndefined(this.props.CanOrder) || this.props.CanOrder;

        return(
			<section className="listpage">
				<div className={"head " + (uistate.IsMobile ? "mobile" : "")}>
					<h2>
						{dashboardsTitles[this.props.Type] || this.props.Type}
						{!_.isEmpty(this.props.HeadFilter) && 
							_.map(this.props.HeadFilter, function(value, name) {
								return(<a href="/" className="toggleActive" onClick={view.setFilter.bind(view)} 
									rel={name} key={name}>{buttonTitles[(value ? "not" : "") + name + view.props.Type]}</a>)
							})
						}
					</h2>
					{this.props.CanManage && (_.isUndefined(this.props.HideAddButton || this.props.HideAddButton)) &&
						<button className="add blue" onClick={(e) => { this.props.setId(-1) }}>
							{uistate.IsMobile ? "Добавить" : buttonTitles[this.props.Type] || "Добавить"}</button>
					}
				</div>
				{!_.isEmpty(this.props.TableData) && CanOrder &&
					<DataTable
						Data={this.props.TableData}
						Columns={this.props.TableColumns}
						Type={this.props.Type}
						PageHeight={uistate.WindowDimensions.height - uistate.HeaderHeight - 
							uistate.PaddingHeight - uistate.TitleHeight}
						IsFilterable={this.props.TableFilterable}
						ClassName="listTable"
						Sorted={this.props.Sorted}
						editRow={(id, type) => this.props.setId(id, type)}
						removeRow={(id) => this.props.removeId(id)}
						changeRowState={(id) => this.props.changeState(id)}
						download={(id) => this.props.downloadList(id)}
						getTrProps={this.props.getTrProps}
					/>
				}
				{_.isEmpty(this.props.TableData) && CanOrder &&
					<p>По вашему запросу ничего не найдено</p>
				}
				{!CanOrder &&
					<p>Вам необходимо открыть смену для работы с данным разделом</p>
				}
			</section>
        )
    }
}
TableFilterPage = observer(TableFilterPage);

export class DashboardBlock extends Component {
	render() {
		var dbGroup = _.groupBy(checkedBoards.allBoards, "Type");
		// console.log(dbGroup)

		return(
			<div className={"dashboards " + (this.props.BlockClass || "")}>
				{dbGroup.Menu &&
					<DashboardGroup
						Type="Menu"
						Group={dbGroup.Menu}
						Cols={uistate.IsMobile ? 2 : 3}
						BlockClass={uistate.IsMobile ? "clearfix" : "col col1_1"}
					/>
				}
				{dbGroup.Sidebar && authUserState.HasERP &&
					<DashboardGroup
						Type="Sidebar"
						Group={dbGroup.Sidebar}
						Cols={2}
						BlockClass={uistate.IsMobile ? "clearfix" : "col col2_3"}
					/>
				}
				{dbGroup.Widget && authUserState.HasERP &&
					<DashboardGroup
						Type="Widget"
						Group={dbGroup.Widget}
						Cols={uistate.IsMobile ? 2 : 1}
						BlockClass={uistate.IsMobile ? "clearfix" : "col col1_3"}
					/>
				}
			</div>
		)
	}
}

class DashboardGroup extends Component {
	onChange (value, id) { checkedBoards.collectBoards(id, value, this.props.Type); }

	render() {
		var view = this,
			isWidget = this.props.Type === "Widget",
			groups = isWidget ? this.props.Group : 
				_.map(_.groupBy(this.props.Group, "Group"), function(v, i) { return { Name : i, Group : v } });

		return(
			<div className={"block " + this.props.Type + " " + (this.props.BlockClass || "")}>
				<div className="clearfix head">
					<h4>{dashboardsTitles[this.props.Type] || this.props.Type}</h4>
					{!isWidget &&
						<div className="checkAll">
							<CheckGroup 
								Type="checkbox"
								Id="CheckAll"
								List={[{ Id : "true", Title : "Выбрать все" }]}
								onChange={this.onChange.bind(this)}
								Value={checkedBoards["CheckAll" + this.props.Type] ? ["true"] : []}
							/>
						</div>
					}
				</div>
				{!isWidget && _.chunk(groups, this.props.Cols).map(function (v,i) {
					return(<DashboardRow key={i}
						Type={view.props.Type}
						ColClass={"col col1_" + view.props.Cols}
						Group={v}
						GroupLength={view.props.Group ?  view.props.Group.length : 0}
					/>)
				})}
				{isWidget &&
					<DashboardRow 
						Type={this.props.Type}
						ColClass={"col col1_" + this.props.Cols}
						Group={groups}
						GroupLength={view.props.Group ?  view.props.Group.length : 0}
					/>
				}
			</div>
		)
	}
}
DashboardGroup = observer(DashboardGroup)
class DashboardRow extends Component {
	constructor(props) {
		super(props);

		this.state = { isRowOpen : !authUserState.HasERP };
	}

	onChange (value, id) { checkedBoards.collectBoards(id, value, this.props.Type); }

	toggleRow(event) {
		event.preventDefault();
		this.setState({ isRowOpen : !this.state.isRowOpen });
	}

	render () {
		var view = this,
			isWidget = this.props.Type === "Widget",
			isMenu = this.props.Type === "Menu",
			widgetList = !isWidget ? [] : _.map(this.props.Group, function(v) {
				return { Id : v.Id, Title : dashboardsTitles[v.Group] || v.Group }
			}),
			text = this.state.isRowOpen ? "Свернуть строку" : "Развернуть строку";

		return(
			<div className={"clearfix row" + (this.state.isRowOpen || isWidget ? " open" : " close")}>
				{!isWidget && authUserState.HasERP &&
					<div className="clearfix">
						<a href="/" className="toggleRow" onClick={this.toggleRow.bind(this)}><i></i>{text}</a>
					</div>
				}
				{!isWidget && 
					_.map(this.props.Group, function (item) {
						var list = _.map(item.Group, function(v) {
							var title = "";

							if (!isMenu && item.Group.length === 1) 
								title = dashboardsTitles[item.Name] || item.Name;
							else {
								var groupName = dashboardsTitles[v.SubGroup] || v.SubGroup,
									nameName = dashboardsTitles[v.Name] || v.Name;
								
								title = groupName + (v.Name !== "" ? " – " + nameName : "");
							}

							return { Id : v.Id, Title : title }
						});

						return(<div className={view.props.ColClass} key={item.Name}>
							<CheckGroup
								Id="Dashboards"
								Title={dashboardsTitles[item.Name] || item.Name}
								Type="checkbox"
								List={list}
								Value={checkedBoards.Checked[view.props.Type]}
								onChange={view.onChange.bind(view)}
							/>
						</div>)
					})
				}
				{isWidget &&
					<CheckGroup
						Id="Dashboards"
						Type="checkbox"
						List={widgetList}
						RowClass="nomargin"
						Value={checkedBoards.Checked[this.props.Type]}
						onChange={this.onChange.bind(this)}
					/>
				}
			</div>
		)
	}
}
DashboardRow = observer(DashboardRow)

export class RightsBlock extends Component {
	/**
	 * Сбор данных с формы выбора прав пользователя
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange (value, id) { checkedRights.collectRights (id, value); }

	render() {
		var view = this;

		return(
			<div className={"rights gray " + this.props.BlockClass}>
				<h4>Права пользователя</h4>
				{_.map(_.groupBy(checkedRights.allRights, "Group"), function (list,group) {
					return(<RightsGroupBlock Title={group} List={list} key={group}
						handleChange={view.handleChange.bind(view)} />)
				}) }
			</div>
		)
	}
}
RightsBlock = observer(RightsBlock);

class RightsGroupBlock extends Component {
	constructor(props) {
		super(props);

		this.state = { IsOpen : !authUserState.HasERP };
	}

	/** Показать/скрыть блок с правами */
	handleToggle (event) {
		event.preventDefault();
		this.setState({ IsOpen : !this.state.IsOpen });
	}

	render() {
		var title = _.find(rightsTitles, { Id : this.props.Title }),
			list = _.compact(_.map(this.props.List, function (item) { 
				return _.find(rightsTitles, { Id : item.Name })
			}));

		return(
			<div className="clearfix block">
				<h5>{title ? title.Name : this.props.Title}</h5>
				{authUserState.HasERP && 
					<a href="/" className={"icon toggle " + (this.state.IsOpen ? "open" : "")} 
						onClick={this.handleToggle.bind(this)}><i></i></a>
				}
				{this.state.IsOpen &&
					<CheckGroup
						Id="Rights"
						RowClass="nomargin"
						Type="checkbox"
						List={toCheckList(list)}
						Value={checkedRights.Checked}
						Disabled={userState.User && userState.User.IsAdmin > 0}
						onChange={this.props.handleChange}
					/>
				}
			</div>
		)
	}
}
RightsGroupBlock = observer(RightsGroupBlock);

export class ItemHeader extends Component {
	render() {
		var style = { left : uistate.IsTablet ? 50 : uistate.IsSideBarOpen ? 260 : 50 }
		
		return(
			<div className={"head " + (uistate.IsMobile ? "mobile" : "")} style={style}>
				<h2>{this.props.Title}</h2>
				{this.props.Error !== "" && <p className={this.props.Success ? "success" : "error"}>
					{errors[this.props.Error] || errors.DEFAULT}</p>}
				<div>

					{this.props.CanManage &&
						<button className={this.props.Loading ? "loading" : ""} disabled={this.props.Loading}>
							{this.props.Loading && <img src={loader} alt="loading" /> }
							{!this.props.Loading && "Сохранить" }
						</button>
					}
					{this.props.CanAccept &&
						<a href="/" className={"green button " + (this.props.Loading ? "loading" : "")} 
							disabled={this.props.Loading} onClick={this.props.onAccept}>
							{this.props.Loading && <img src={loader} alt="loading" /> }
							{!this.props.Loading && "Сохранить и подтвердить" }
						</a>
					}
					{this.props.CanSend &&
						<a href="/" className={"green button " + (this.props.Loading ? "loading" : "")} 
							disabled={this.props.Loading} onClick={this.props.onSend}>
							{this.props.Loading && <img src={loader} alt="loading" /> }
							{!this.props.Loading && "Сохранить и отправить" }
						</a>
					}
					{(_.isUndefined(this.props.HideClose) || this.props.HideClose === false) &&
						<a href="/" className="button close" onClick={this.props.handleClose}>Назад</a>
					}
				</div>
				
			</div>
		)
	}
}
ItemHeader = observer(ItemHeader);

export class Confirm extends Component {
	onCancel (event) {
		event.preventDefault();

		if (confirmState.ConfirmCancel) confirmState.ConfirmCancel();
		confirmState.closeConfirm();
	}

	onConfirm (event) {
		event.preventDefault();
		confirmState.submitConfirm();
	}

	render() {
		if (!confirmState.ConfirmOpen) return null;
		else return(
			<div className="back">
				<div className={"confirmBlock clearfix window " + (uistate.IsMobile ? "mobile" : "")}>
					<p>{confirmState.ConfirmText}</p>
					<p>{confirmState.ConfirmComment}</p>
					<form className="buttons clearfix" onSubmit={this.onConfirm.bind(this)}>
						<SubmitButton Loading={confirmState.ButtonLoading} Title="Подтвердить" />
						<a href="/" role="button" onClick={this.onCancel.bind(this)} className="button gray">Отменить</a>
					</form>
				</div>
			</div>
		)
	}
}
Confirm = observer(Confirm);

export class TableReportPage extends Component {
    render() {
		var hasPagination = this.props.HasMore || this.props.Offset > 0,
			hasPaginationNumber = this.props.Total > this.props.Limit;

        return(
			<section className="listpage">
				<DataTable
					Data={this.props.TableData}
					Columns={this.props.TableColumns}
					Type={this.props.Type}
					IsFilterable={this.props.TableFilterable}
					IsSortable={this.props.IsSortable}
					ClassName="reportTable"

					editRow={this.props.editRow}
					removeRow={this.props.removeRow}
					changeRowState={this.props.payRow}
					download={(id) => this.props.downloadList(id)}

					SubComponent={this.props.SubComponent}
					getTrProps={this.props.getTrProps}
					checkRow={this.props.checkRow}
				/>
				{this.props.Pagination && hasPagination && this.props.PaginationType !== "number" &&
					<TablePagination
						Limit={this.props.Limit}
						HasMore={this.props.HasMore}
						Offset={this.props.Offset}
						changePage={this.props.changePage}
					/>
				}
				{this.props.Pagination && hasPaginationNumber && this.props.PaginationType === "number" &&
					<TablePaginationNumber
						Limit={this.props.Limit}
						// HasMore={this.props.HasMore}
						Total={this.props.Total}
						Offset={this.props.Offset}
						changePage={this.props.changePage}
					/>
				}
			</section>
        )
    }
}
TableReportPage = observer(TableReportPage);

export class TablePagination extends Component {
	/** Обработка клика на переключение между страницами таблицы */
	onClick(event) {
		event.preventDefault();
		var rel = event.currentTarget.getAttribute("rel"),
			offset = 0;
		
		if (rel === "prev") offset = this.props.Offset - this.props.Limit;
		else offset = this.props.Offset + this.props.Limit;

		if (this.props.changePage) this.props.changePage(offset);
	}

	render() {
		return(
			<div className="pagination">
				{this.props.Offset > 0 &&
					<a className="prev" onClick={this.onClick.bind(this)} href="/" rel="prev">
						<ArrowIcon />Предыдущие {this.props.Limit}</a>
				}
				{this.props.HasMore &&
					<a className="next" onClick={this.onClick.bind(this)} href="/" rel="next">
						Следующе {this.props.Limit}<ArrowIcon /></a>
				}
			</div>
		)
	}
}

export class TablePaginationNumber extends Component {
	/** Обработка клика на переключение между страницами таблицы */
	handleClick(event) {
		event.preventDefault();
		var offset = parseInt(event.currentTarget.getAttribute("rel"), 10);

		if (this.props.changePage) this.props.changePage(offset);
	}

	render() {
		var view = this,
			totalPages = Math.ceil(this.props.Total/this.props.Limit),
			activePage = this.props.Offset/this.props.Limit + 1,
			maxPages = 9,
			shiftPage = 4,
			startPageNumber = activePage < shiftPage ? 1 : activePage > (totalPages - shiftPage)
				? totalPages - maxPages + 1 : activePage - shiftPage,
			prev = this.props.Offset - this.props.Limit,
			next = this.props.Offset + this.props.Limit,
			lastPage = (totalPages - 1)*this.props.Limit,
			pages = _.range(startPageNumber, startPageNumber + maxPages);

		return(
			<div className="pagination numbers">
				<a href="/" className="icon start" title="К первой странице" rel="0"
					onClick={this.handleClick.bind(this)}><i></i></a>
				{activePage > 1 &&
					<a href="/" className="icon prev" title="Предыдущая страница" rel={prev}
						onClick={this.handleClick.bind(this)}><i></i></a>
				}
				{_.map(pages, function(v) {
					return(<a href="/" className={"icon number " + (v === activePage ? "active" : "")} 
						title={"Страница " + v} rel={(v - 1)*view.props.Limit} key={v}
						onClick={view.handleClick.bind(view)}><i>{v}</i></a>)
				})}
				{activePage < totalPages &&
					<a href="/" className="icon next" title="Следующая страница" rel={next}
						onClick={this.handleClick.bind(this)}><i></i></a>
				}
				<a href="/" className="icon end" title="Последняя страница" rel={lastPage}
					onClick={this.handleClick.bind(this)}><i></i></a>
			</div>
		)
	}
}

export class ErrorMessage extends Component {
	/** Закрытие окна с ошибкой */
	onClick (event) {
		event.preventDefault();
		
		if (event.target.className === "back" || event.target.className === "hide") {
			if (errorState.OnClose) errorState.OnClose();
			errorState.ErrorText = null;
		}
	}

	render() {
		if (_.isNull(errorState.ErrorText) || errorState.ErrorText === "") return null;

		return(
			<div id="errorMessage" className='back' onClick={this.onClick.bind(this)}>
				<div className={"message " + (errorState.ErrorType ? "error" : "success")}>
					<a href="/" className='hide' onClick={this.onClick.bind(this)}>Нажмите, чтобы закрыть окно</a>
					<h4>{errorState.ErrorType ? "Произошла ошибка" : "Успех!"}</h4>
					<p>{errorState.ErrorText}</p>
				</div>
			</div>
		)
	}
}
ErrorMessage = observer(ErrorMessage);

export class PushBlock extends Component {
	render() {
		return(
			<div id="pushMessage">
				{_.map(pushState.Push, function (push, i) {
					return(<PushMessage key={"p" + i} Index={i} Item={push}/>)
				})}
			</div>
		)
	}
}
PushBlock = observer(PushBlock);

export class PushMessage extends Component {
	onClick (event) {
		event.preventDefault();

		var index = this.props.Index;
		pushState.Push = _.filter(pushState.Push, function (v,i) { return i !== index; });
	}

	render() {
		return(
			<div className="message">
				<h5>{this.props.Item.Title}</h5>
				<p>{this.props.Item.Message}</p>
				<a href="/" onClick={this.onClick.bind(this)} className="close"><i></i></a>
			</div>
		)
	}
}
PushMessage = observer(PushMessage);

export class NotificationBlock extends Component {
	render() {
		var view = this,
			groups = _.groupBy(this.props.List, "Group");
		
		return(
			<div className="clearfix block">
				<h4>Уведомления пользователя</h4>
				{_.map(groups, function(group, name) {
					var list = _.map(group, function (v) { return { Id : v.Id, Name : notifications[v.Name] || v.Name } });
					return(<CheckGroup key={name}
						Id="Notifications"
						Title={notifications[name] || name}
						Type="checkbox"
						RowClass={uistate.IsMobile ? "col col50" : "col col30"}
						List={toCheckList(list)}
						Value={view.props.Value}
						onChange={view.props.onChange}
					/>)
				})}
			</div>
		)
	}
}
NotificationBlock = observer(NotificationBlock);