import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import { PartySuggestions, BankSuggestions } from 'react-dadata';
import 'react-dadata/dist/react-dadata.css';

import { TextInput, Selectize, CheckGroup, PeriodTime } from '../forms/FormItems';
import { TableFilterPage, ItemHeader } from './PageTemplates';

import { daysLong, buttonTitles, VATTitles, dadataKey } from '../dictionary';
import { checkRights, toSelectList, getRubs, getRequest, formatDate } from '../functions';
import { uistate, confirmState } from '../stores/common';
import { providerState } from '../stores/providers';

class ProvidersPage extends Component {
	constructor(props) {
		super(props);

		providerState.setDefault();

		this.state = {};

		this.getData();

		var model = this;
		getRequest("Cities", {}, function (data) { model.setData(data, "Cities"); });
		getRequest("Products", { Type : 1, Active : true}, function (data) { model.setData(data, "Products"); });
	}

	/**
	 * Разбор ответа от сервера в состояние компонента
	 * @param {object} data ответ от сервера
	 * @param {string} param искомый параметр
	 */
	setData(data, param) { this.setState({ [param] : data.Success ? data[param] : [] }); }

	/** API запрос на получение списка поставщиков от сервера */
	getData() {
		providerState.IsSendRequest = false;
		getRequest("Providers", providerState.Filter, function(data) {
			providerState.setProviders(data);
		});
	}

	/**
	 * API запрос на получения поставщика по ID
	 * @param {number} id ID поставщика
	 */
	getItem(id) {
		getRequest("Provider", { Id : id }, function (data) {
			providerState.setProvider(data.Success ? data : undefined);
		});
	}

	/**
	 * Уставнока активного поставщика или сброс его
	 * @param {number|undefined} id 
	 */
	setId(id) {
		if(_.isUndefined(id)) {
			providerState.setProvider(undefined);
			this.getData();
		} else {
			if (id === -1) providerState.setProvider({ Id : -1 });
			else this.getItem(id);
		}
	}

	/** 
	 * Переключатель фильтра поставщиков 
	 * @param {string} id Название поля фильтра
	 */
	setFilter (id) {
		providerState.Filter[id] = !providerState.Filter[id];
		this.getData();
	}

	/**
	 * API запрос на удаление/восстановление поставщика
	 * @param {number} id ID поставщика
	 */
	removeId(id) {
		if (_.isUndefined(id)) return false;

		var model = this,
			item = _.find(providerState.Providers, { Id : id }),
			text = <span>Вы действительно хотите {this.state.Active ? "удалить" : "восстановить"} 
						&nbsp;поставщика <b key="b1">«{item ? item.Name : ""}»</b>?</span>;

		confirmState.openConfirm(text, function() {
			getRequest("RemoveProvider", { Id : id }, function (data) { model.getData(); });
		});
	}

	render() {
		var columns = { Name: {}, City: { width: 100, filterable : false }, Description: { filterable : false }, 
				Products: { Header: "Сырье" } };

		if (checkRights("ManageProviders")) _.extend(columns, { Edit: {}, Remove: {} });
		else _.extend(columns, { View : {} });

		return (
			<div className="page" id="ProvidersPage">
				{providerState.IsSendRequest && _.isUndefined(providerState.Provider) &&
					<TableFilterPage
						Type={this.props.Type}
						TableColumns={columns}
						TableData={providerState.Providers}
						CanManage={checkRights("ManageProviders") && providerState.Filter.Active}

						HeadFilter={providerState.Filter}
						TableFilterable={true}

						setId={this.setId.bind(this)}
						removeId={this.removeId.bind(this)}
						setFilter={this.setFilter.bind(this)}

						getTrProps={(state, rowInfo, column) => {
							return { className: rowInfo && !rowInfo.original.Active ? "notactive" : "" };
						}}
					/>
				}
				{!_.isUndefined(providerState.Provider) &&
					<ProviderItem
						Cities={this.state.Cities}
						Products={this.state.Products}

						setId={this.setId.bind(this)}
					/>
				}
			</div>
		)
	}
}
ProvidersPage = observer(ProvidersPage);

class ProviderItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			ShowSuggest : false
		}

		if (providerState.Provider.Id !== -1) this.getHistory();
	}

	/** API запрос на получение истории поставок поставщика */
	getHistory() {
		getRequest("ProviderHistory", { Id : providerState.Provider.Id }, function (data) {
			providerState.History = data.Success ? data.Orders : [];
		});
	}

	/** Закрыть окно активного поставщика */
	handleClose(event) {
		event.preventDefault();
		this.props.setId(undefined);
	}

	/**
	 * Сбор данных с формы редактирования поставщика
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange(value, id) { providerState.collectProvider(id, value); }

	/** API запрос на редактирование поставщика */
	handleSubmit(event) {
		event.preventDefault();
		
		if (!providerState.validateData())
			return false;
		
		providerState.ButtonLoading = true;

		getRequest("SaveProvider", providerState.getSaveData(), function (data) {
			providerState.ErrorCode = data.Success ? "SUCCESS_SAVE" : data.ErrorCode;
			providerState.SuccessSave = data.Success;
			providerState.ButtonLoading = false;
		});
	}

	handleToggle(event) {
		event.preventDefault();
		this.setState({ ShowSuggest : !this.state.ShowSuggest });
	}

	handleSuggest(value) {

	}

	render() {
		var style = { height: uistate.sectionHeight - 52 - 15 },
			canFinance = checkRights("ManageProvidersFinance") || checkRights("ManageProviders");

		return (
			<section className="clearfix item two-cols" id="ProviderItem">
				<form onSubmit={this.handleSubmit.bind(this)} >
					<ItemHeader
						Title={buttonTitles[(this.props.Id === -1 ? "New" : "Edit") + "Provider"]}
						Error={providerState.ErrorCode}
						Loading={providerState.ButtonLoading}
						CanManage={checkRights("ManageProviders")}
						Success={providerState.SuccessSave}
						handleClose={this.handleClose.bind(this)}
					/>
					<div className="clearfix main backblock">
						<TextInput
							Id="Name"
							Title="Название поставщика"
							Required={true}
							Value={providerState.Provider.Name}
							Disabled={!checkRights("ManageProviders")}
							RowClass="col col25"
							onChange={this.handleChange.bind(this)}
						/>
						<Selectize
							Id="CityId"
							Title="Город"
							List={toSelectList(this.props.Cities)}
							Value={providerState.Provider.CityId}
							Required={true}
							Disabled={!checkRights("ManageProviders")}
							RowClass="col col25"
							onChange={this.handleChange.bind(this)}
						/>
						<div className="col col50">
							{!this.state.ShowSuggest &&
								<a href="/" className="button green suggest" 
									onClick={this.handleToggle.bind(this)}>Заполнить автоматически</a>
							}
							{this.state.ShowSuggest &&
								<div className="clearfix dadata">
									<div className="col col90 form-row">
										<label className="focused">ИНН или название поставщика</label>
										<PartySuggestions 
											token={dadataKey.test}
											onChange={providerState.setSuggestProvider.bind(providerState)} 
										/>
									</div>
									<a href="/" className="button icon close" title="Отменить" 
										onClick={this.handleToggle.bind(this)}><i></i></a>
								</div>
							}
						</div>
					</div>
					<div className="clearfix section" style={style}>
						<div className="clearfix">
							<div className="col col65">
								<MainInfo handleChange={this.handleChange.bind(this)} />
								<ProductsBlock Products={this.props.Products} />
								{canFinance && <FinanceBlock handleChange={this.handleChange.bind(this)} /> }
							</div>
							<div className="col col35 gray">
								{canFinance && !_.isEmpty(providerState.History) && <OrderHistoryBlock /> }
								<WorkDaysBlock />
								<ContactsBlock />
							</div>
						</div>
					</div>
				</form>
			</section>
		)
	}
}
ProviderItem = observer(ProviderItem);

class MainInfo extends Component {
	render() {
		return (
			<div className="clearfix main block">
				<TextInput
					Id="Address"
					Title="Юридический адрес"
					Value={providerState.Provider.Address}
					Required={true}
					Disabled={!checkRights("ManageProviders")}
					RowClass="col col100"
					onChange={this.props.handleChange}
				/>
				<TextInput
					Id="FactAddress"
					Title="Фактический адрес"
					Value={providerState.Provider.FactAddress}
					Disabled={!checkRights("ManageProviders")}
					RowClass="col col100"
					onChange={this.props.handleChange}
				/>
				<TextInput
					Id="DefermentPayment"
					Title="Отсрочка платежа"
					Type="number"
					Value={providerState.Provider.DefermentPayment}
					RowClass="col col50"
					Disabled={!checkRights("ManageProviders")}
					onChange={this.props.handleChange}
				/>
				<TextInput
					Id="MinAmount"
					Title="Минимальная сумма заказа"
					Value={providerState.Provider.MinAmount}
					RowClass="col col50"
					Disabled={!checkRights("ManageProviders")}
					onChange={this.props.handleChange}
				/>
				<TextInput
					Id="Description"
					Title="Комментарий"
					Type="textarea"
					RowClass="col col100"
					Disabled={!checkRights("ManageProviders")}
					Value={providerState.Provider.Description}
					onChange={this.props.handleChange}
				/>
			</div>
		)
	}
}
MainInfo = observer(MainInfo);

class FinanceBlock extends Component {
	render () {
		return(
			<div className="clearfix requisites block">
				<h4>Реквизиты поставщика</h4>
				<TextInput
					Id="INN"
					Title="ИНН"
					Mask="999999999999"
					RowClass="col col50"
					Disabled={!checkRights("ManageProviders")}
					Value={providerState.Provider.INN}
					onChange={this.props.handleChange}
				/>
				<TextInput
					Id="KPP"
					Title="КПП"
					Mask="999999999"
					RowClass="col col50"
					Disabled={!checkRights("ManageProviders")}
					Value={providerState.Provider.KPP}
					onChange={this.props.handleChange}
				/>
				
				<div className="col col75 form-row dadata">
					<label className="focused">Заполнить реквизиты банка по ИНН или Названию</label>
					<BankSuggestions 
						token={dadataKey.test}
						onChange={providerState.setSuggestBank.bind(providerState)}
					/>
				</div>
				<TextInput
					Id="Bank"
					Title="Банк"
					RowClass="col col50"
					Disabled={!checkRights("ManageProviders")}
					Value={providerState.Provider.Bank}
					onChange={this.props.handleChange}
				/>
				<TextInput
					Id="BIK"
					Title="БИК"
					Mask="049999999"
					RowClass="col col50"
					Disabled={!checkRights("ManageProviders")}
					Value={providerState.Provider.BIK}
					onChange={this.props.handleChange}
				/>
				<TextInput
					Id="CorrAccount"
					Title="Корр. счет"
					Mask="9999 9999 9999 9999 9999"
					RowClass="col col50"
					Disabled={!checkRights("ManageProviders")}
					Value={providerState.Provider.CorrAccount}
					onChange={this.props.handleChange}
				/>
				<TextInput
					Id="CheckAccount"
					Title="Рассчетный счет"
					Mask="9999 9999 9999 9999 9999"
					RowClass="col col50"
					Disabled={!checkRights("ManageProviders")}
					Value={providerState.Provider.CheckAccount}
					onChange={this.props.handleChange}
				/>
			</div>
		)
	}
}
FinanceBlock = observer(FinanceBlock);

class ContactsBlock extends Component {
	/** Добавление нового контакта */
	handlerAdd (event) { 
		event.preventDefault();

		providerState.Contacts = _.concat(providerState.Contacts, _.clone(providerState.ContactDummy));
	}

	render() {
		return(
			<div className="clearfix contacts block">
				<h4>Контактные данные</h4>
				<div className="clearfix">
					{providerState.Contacts.map(function (v,i) {
						return (<ElemItem Item={v} Index={i} key={providerState.ContactsCounter++} />)
					}) }
				</div>
				{checkRights("ManageProviders") &&
					<a className="button add" onClick={this.handlerAdd.bind(this)} href="/">
						<i></i>{buttonTitles.Contacts}</a>
				}
			</div>
		)
	}
}
ContactsBlock = observer(ContactsBlock)

class ElemItem extends Component {
	/** Удаление контакта */
	handleDelete (event) { 
		event.preventDefault();
		
		var index = this.props.Index;
		providerState.Contacts = _.filter(providerState.Contacts, function (v,i) { return i !== index; });
	}

	/**
	 * Сбор данных с формы редактирования контактов поставщика
	 * @param {string} value 
	 * @param {string} id 
	 */
	onChange (value, id) { providerState.collectContacts(id, value, this.props.Index); }

	render() {
		return(
			<div className="contact clearfix">
				<TextInput
					Id="FIO"
					Title="Имя контактного лица"
					Value={this.props.Item.FIO}
					Required={true}
					Disabled={!checkRights("ManageProviders")}
					onChange={this.onChange.bind(this)}
				/>
				<TextInput
					Id="Phone"
					Title="Телефон"
					Value={this.props.Item.Phone}
					Disabled={!checkRights("ManageProviders")}
					onChange={this.onChange.bind(this)}
					Mask="7 (999) 999-99-99"
				/>
				<TextInput
					Id="Email"
					Title="E-mail"
					Value={this.props.Item.Email}
					Disabled={!checkRights("ManageProviders")}
					onChange={this.onChange.bind(this)}
				/>
				{checkRights("ManageProviders") &&
					<a className="button remove" href="/" role="button" title="Удалить контакт"
						onClick={this.handleDelete.bind(this)}><i></i></a>
				}
			</div>
		)
	}
}
ElemItem = observer(ElemItem)

class ProductsBlock extends Component {
	/** Добавление продукта, поставляемого поставщиком */
	handlerAdd (event) { 
		event.preventDefault();
		providerState.Products = _.concat(providerState.Products, _.clone(providerState.ProductDummy));
	}

	render() {
		var view = this;

		return(
			<div className="clearfix products block">
				<h4>Наличие сырья для заказа</h4>
				<div className="clearfix">
					{providerState.Products.map(function (v,i) {
						return (<ProductItem Item={v} Index={i} key={"pr" + providerState.ProductsCounter++} 
							Products={view.props.Products} />)
					}) }
				</div>
				{checkRights("ManageProviders") &&
					<a className="button add" onClick={this.handlerAdd.bind(this)} href="/">
						<i></i>{buttonTitles.AddProducts}</a>
				}
			</div>
		)
	}
}
ProductsBlock = observer(ProductsBlock)
class ProductItem extends Component {
	/** Удаление продукта, доставляемого поставщиком */
	handleDelete (event) { 
		event.preventDefault();
		
		var index = this.props.Index;
		providerState.Products = _.filter(providerState.Products, function (v,i) { return i !== index; });
	}

	getId (index, id) { return "Products_" + index + "_" + id; }
	/**
	 * Сбор данных с формы редактирования продуктов поставщика
	 * @param {string} value 
	 * @param {string} id 
	 */
	onChange (value, id) { 
		if (/ProductId/.exec(id)) var product = _.find(this.props.Products, { Id : value });
		providerState.collectProducts(id, value, this.props.Index, product); 
	}

	render() {
		var canFinance = checkRights("ManageProvidersFinance") || checkRights("ManageProviders");

		return(
			<div className="product clearfix">
				<Selectize
					Id={this.getId(this.props.Index, "ProductId")}
					Title="Сырье"
					Value={this.props.Item.ProductId}
					List={toSelectList(this.props.Products)}
					Required={true}
					Disabled={!checkRights("ManageProviders")}
					onChange={this.onChange.bind(this)}
					RowClass={canFinance ? "col col40" : "col col55"}
				/>
				<TextInput
					Id={this.getId(this.props.Index, "MinCount")}
					Title="Мин. заказ"
					Value={this.props.Item.MinCount}
					Disabled={!checkRights("ManageProviders")}
					Type="number"
					RowClass="col col15"
					Step="0.001"
					onChange={this.onChange.bind(this)}
				/>
				<TextInput
					Id={this.getId(this.props.Index, "Unit")}
					Title="ЕИ"
					Value={this.props.Item.Unit}
					Disabled={true}
					RowClass="col col10"
				/>
				{canFinance &&
					<TextInput
						Id={this.getId(this.props.Index, "Price")}
						Title="Цена за ед."
						Value={getRubs(this.props.Item.Price)}
						Disabled={true}
						RowClass="col col15"
						/>
					}
				<Selectize
					Id={this.getId(this.props.Index, "VAT")}
					Title="НДС" 
					Value={this.props.Item.VAT}
					List={toSelectList(VATTitles)}
					RowClass="col col20"
					Disabled={!checkRights("ManageProviders")}
					onChange={this.onChange.bind(this)}
				/>
				{checkRights("ManageProviders") &&
					<a className="button remove" href="/" role="button" title="Удалить сырье"
						onClick={this.handleDelete.bind(this)}><i></i></a>
				}
			</div>
		)
	}
}
ProductItem = observer(ProductItem)

class WorkDaysBlock extends Component {
	render() {
		return(
			<div className="clearfix workdays block">
				<h4>Время приема заказов</h4>
				{providerState.WorkHours.map(function (v,i) {
					return(<WorkDayItem key={"d" + i} Index={i} Item={v} />) 
				}) }
			</div>
		)
	}
}
WorkDaysBlock = observer(WorkDaysBlock);
class WorkDayItem extends Component {
	/**
	 * Сбор данных с формы редактирования времени заказа
	 * @param {string} value 
	 * @param {string} id 
	 */
	onChange (value, id) { providerState.collectWorkDays(id, value, this.props.Index) }

	render() {
		return(
			<div className="clearfix day">
				<CheckGroup
					Id={"WorkHours_" + this.props.Index + "_Day"}
					Type="checkbox"
					List={[{ Id : this.props.Index, Title : daysLong[this.props.Index] }]}
					Value={this.props.Item.Day !== -1 && this.props.Item.Day !== "" ? [this.props.Index] : []}
					Disabled={!checkRights("ManageProviders")}
					onChange={this.onChange.bind(this)}
				/>
				<PeriodTime 
					Id={"WorkHours_" + this.props.Index + "_Time"}
					Value={{ 
						StartTime : this.props.Item.StartTime,
						EndTime : this.props.Item.EndTime
					}}
					Required={this.props.Item.Day !== -1 && this.props.Item.Day !== ""}
					Disabled={!checkRights("ManageProviders")}
					onChange={this.onChange.bind(this)}
				/>
			</div>
		)
	}
}
WorkDayItem = observer(WorkDayItem);

class OrderHistoryBlock extends Component {
	/** Показать/скрыть всю историю заказов */
	handleToggle (event) {
		event.preventDefault();
		providerState.AllHistory = !providerState.AllHistory;
	}
	
	render() {
		var statuses = { 1 : "Заказано", 2 : "Отгружено", 3 : "Оплачено" },
			orders = providerState.AllHistory ? providerState.History : _.take(providerState.History, 10),
			link = providerState.AllHistory ? "Свернуть" : "Показать все";

		return(
			<div className="clearfix history block">
				<h4>История закупок</h4>
				{_.map(orders, function (v,i) {
					var statusI = v.Status === 3 ? "success" : v.Status === 2 ? "ready" : "ordered";

					return(<div className="order clearfix" key={i}>
						<span className="col col35"><i className={statusI}></i>{formatDate(v.Date)}</span>
						<span className="col col25">{statuses[v.Status]}</span>
						<span className="col col40">{getRubs(v.Amount, true, true)} руб.</span>
					</div>)
				})}
				<a href="/" className="toggle dotted" onClick={this.handleToggle.bind(this)}>{link}</a>
			</div>
		)
	}
}
OrderHistoryBlock = observer(OrderHistoryBlock)

export default ProvidersPage;