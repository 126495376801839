import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import Parser from 'html-react-parser';

import { TextInput, Selectize, CheckGroup, SliderControl, ButtonGroup } from '../forms/FormItems';

import { TableFilterPage, ItemHeader } from './PageTemplates';

import { buttonTitles, PrintFormatesTitles, TextAligns } from '../dictionary';
import { checkRights, getRequest, toSelectList, toCheckList, formatDate, getRubs, parsePhoneMask, addressToPatternString } from '../functions';
import { confirmState, uistate } from '../stores/common';
import { billTemplateState } from '../stores/bills';

export class BillsPage extends Component {
	constructor(props) {
		super(props);

		billTemplateState.setTemplate(undefined);

		this.state = {};

		this.getData = this.getData.bind(this);
		this.getData();

		getRequest("Entities", {}, function (data) { billTemplateState.Entities = data.Success ? data.Entities : []; });
		getRequest("Brands", {}, function (data) { billTemplateState.Brands = data.Success ? data.Brands : []; });
		getRequest("Filials", {}, function (data) {
			billTemplateState.POS = data.Success ? _.flatten(_.map(data.Filials, "POS")) : [];
		});
	}

	/** API запрос на получение данных от сервера */
	getData() {
		billTemplateState.IsSendRequest = false;
		getRequest("BillTemplates", {}, function (data) { billTemplateState.setTemplates(data); });
	}

    /**
     * API запрос на получение одного шаблона от сервера
     * @param {number} id ID шаблона чека
     */
	getItem(id) {
		getRequest("BillTemplate", { Id: id }, function (data) { billTemplateState.setTemplate(data.Success ? data : undefined); });
	}

    /**
     * Получение или сброс просомтра одного шаблона
     * @param {number} id ID шаблона чека
     */
	setId(id) {
		if (!_.isUndefined(id)) {
			if (id === -1) billTemplateState.setTemplate({ Id: -1 });
			else this.getItem(id);
		} else {
			billTemplateState.setTemplate(undefined);
			this.getData();
		}
	}

    /**
     * API запрос на удаление чека по ID с подтверждением
     * @param {number} id ID шаблона чека
     */
	removeId(id) {
		if (_.isUndefined(id)) return false;

		var model = this,
			item = _.find(billTemplateState.Templates, { Id: id }),
			text = <span>Вы действительно хотите удалить шаблон чека для точки продаж&nbsp;
                <b key="b1">«{item ? item.POS : ""}»</b>?</span>;

		confirmState.openConfirm(text, function () {
			getRequest("RemoveBillTemplate", { Id: id }, function (data) { model.getData(); });
		});
	}

	render() {
		var columns = uistate.IsMobile ? { POS: {}, PrintFormat: {} } : 
			{ Filial: {}, POS: {}, Entity: {}, PrintFormat: {} };

		if (checkRights("ManageBillTemplates")) _.extend(columns, { Edit: {}, Remove: {} })

		return (
			<div className="page" id="BillsPage">
				{billTemplateState.IsSendRequest && _.isUndefined(billTemplateState.Template) &&
					<TableFilterPage
						Type={this.props.Type}
						TableData={billTemplateState.Templates}
						TableColumns={columns}
						CanManage={checkRights("ManageBillTemplates")}

						setId={this.setId.bind(this)}
						removeId={this.removeId.bind(this)}
					/>
				}
				{!_.isUndefined(billTemplateState.Template) && <BillTemplateItem setId={this.setId.bind(this)} />}
			</div>
		)
	}
}
BillsPage = observer(BillsPage);

class BillTemplateItem extends Component {
	/** Закрытие окна редактирования */
	handleClose(event) {
		event.preventDefault();
		this.props.setId(undefined);
	}

	/**
	 * Сбор данных с формы настроек шаблона чека
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange(value, id) { billTemplateState.collectTemplate(id, value); }

	/** API запрос на сохранение данных шаблона */
	handleSubmit(event) {
		event.preventDefault();

		if (!billTemplateState.validateData()) return false;

		billTemplateState.ButtonLoading = true;

		getRequest("SaveBillTemplate", billTemplateState.getSaveData(), function (data) {
			billTemplateState.ErrorCode = data.Success ? "SUCCESS_SAVE" : data.ErrorCode;
			billTemplateState.SuccessSave = data.Success;
			billTemplateState.ButtonLoading = false;

			if (data.Success) billTemplateState.Template.Id = data.Id;
		});
	}

	/**
	 * Смена образцов чеков
	 * @param {string} value вид образца
	 */
	handleChangeTemplate(value) { billTemplateState.BillState = value; }

	render() {
		var billTypes = [{ Id : "tape", Name : "Лента" }, { Id : "paper", Name : "Бумага" }],
			state = billTemplateState.BillState,
			mobileClass = uistate.IsMobile ? "mobile" : "";

		return (
			<section className={"clearfix item two-cols " + mobileClass} id="BillTemplateItem">
				<form onSubmit={this.handleSubmit.bind(this)} >
					<ItemHeader
						Title={buttonTitles[(billTemplateState.Template.Id === -1 ? "New" : "Edit") + "Check"]}
						Error={billTemplateState.ErrorCode}
						Loading={billTemplateState.ButtonLoading}
						CanManage={checkRights("ManageBillTemplates")}
						Success={billTemplateState.SuccessSave}
						handleClose={this.handleClose.bind(this)}
					/>
					<div className="clearfix main backblock">
						<Selectize
							Id="POSId"
							Title="Точка продаж"
							Required={true}
							List={toSelectList(billTemplateState.POS)}
							Value={billTemplateState.Template.POSId}
							RowClass={"col " + (uistate.IsMobile ? "col75" : "col25")}
							onChange={this.handleChange.bind(this)}
						/>
					</div>
					<div className="clearfix section">
						<div className="clearfix">
							<div className={uistate.IsMobile ? "clearfix template" : "col col40"}>
								<FieldSettings handleChange={this.handleChange.bind(this)} />
								<ShowSettings handleChange={this.handleChange.bind(this)} />
								<PrintSettings handleChange={this.handleChange.bind(this)} />
							</div>
							<div className={"gray sample " + (uistate.IsMobile ? "clearfix" : "col col60")}>
								<ButtonGroup
									Id="BillState"
									List={billTypes}
									Value={billTemplateState.BillState}
									RowClass="nomargin"
									onChange={this.handleChangeTemplate.bind(this)}
								/>
								{!_.isUndefined(billTemplateState.generateBill) && state === "tape" &&
									<BillTapeItem Bill={billTemplateState.generateBill} />
								}
								{!_.isUndefined(billTemplateState.generateBill) && state === "paper" &&
									<BillPaperItem Bill={billTemplateState.generateBill} />
								}
							</div>
						</div>
					</div>
				</form>
			</section>
		)
	}
}
BillTemplateItem = observer(BillTemplateItem);

class FieldSettings extends Component {
	render() {
		var requisitesList = [{ Id: "true", Name: "Заполнить реквизиты из настроек" }];

		return (
			<div className="clearfix block">
				<h4>Поля для заполнения</h4>
				<TextInput
					Id="Requisites"
					Title="Реквизиты организации"
					Type="textarea"
					Value={billTemplateState.Template.Requisites}
					onChange={this.props.handleChange}
				/>
				<CheckGroup
					Id="AutoRequisites"
					Type="checkbox"
					RowClass="nomargin"
					List={toCheckList(requisitesList)}
					Disabled={billTemplateState.Template.POSId === -1}
					Value={billTemplateState.Template.AutoRequisites ? ["true"] : []}
					onChange={this.props.handleChange}
				/>
				<FieldBlock />
				<TextInput
					Id="FinalText"
					Title="Текст после состава чека"
					Type="textarea"
					Value={billTemplateState.Template.FinalText}
					onChange={this.props.handleChange}
				/>
				<TextInput
					Id="Manufacturer"
					Title="Название производителя продукции"
					Value={billTemplateState.Template.Manufacturer}
					Required={billTemplateState.Template.ShowComposition}
					onChange={this.props.handleChange}
				/>
				<TextInput
					Id="LegalAddress"
					Title="Адрес производства"
					Value={billTemplateState.Template.LegalAddress}
					onChange={this.props.handleChange}
				/>
			</div>
		)
	}
}
FieldSettings = observer(FieldSettings);

class FieldBlock extends Component {
	/** Добавление произвольного поля */
	handleAdd(event) {
		event.preventDefault();
		billTemplateState.Fields = _.concat(billTemplateState.Fields, _.clone(billTemplateState.FieldDummy));
	}

	render() {
		return (
			<div className="clearfix fields">
				{_.map(billTemplateState.Fields, function (field, i) {
					return (<FieldBlockItem Item={field} key={"f" + billTemplateState.FieldCounter++} Index={i} />)
				})}
				{billTemplateState.Fields.length < 3 &&
					<a href="/" className="button add icon" onClick={this.handleAdd.bind(this)}>
						<i></i>Добавить поле</a>
				}
			</div>
		)
	}
}
FieldBlock = observer(FieldBlock);
class FieldBlockItem extends Component {
	/** Удаление произвольного поля */
	handleDelete(event) {
		event.preventDefault();
		var index = this.props.Index;
		billTemplateState.Fields = _.filter(billTemplateState.Fields, function (v, i) { return i !== index; });
	}

	/**
	 * Сбор данных с формы настроек шаблона чека
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange(value, id) { billTemplateState.collectTemplate(id, value, this.props.Index); }

	render() {
		return (
			<div className="clearfix item">
				<TextInput
					Id="Field"
					Title={"Произвольное поле " + (this.props.Index + 1)}
					Type="textarea"
					RowClass="col col95"
					Value={this.props.Item.Value}
					onChange={this.handleChange.bind(this)}
				/>
				<a href="/" className="button remove icon" title="Удалить поле"
					onClick={this.handleDelete.bind(this)}><i></i></a>
			</div>
		)
	}
}
FieldBlockItem = observer(FieldBlockItem);

class ShowSettings extends Component {
	render() {
		var view = this,
			showSettingsLists = {
				ShowCustomerName: [{ Id: "true", Name: "Печатать имя клиента" }],
				ShowCustomerAddress: [{ Id: "true", Name: "Печатать адрес клиента" }],
				ShowDescription: [{ Id: "true", Name: "Печатать комментарий к заказу" }],
				ShowStaff: [{ Id: "true", Name: "Печатать имя сотрудника" }],
				ShowOrderTime: [{ Id: "true", Name: "Печатать время приема заказа" }],
				ShowPrintTime: [{ Id: "true", Name: "Печатать время печати чека" }],
				ShowComposition: [{ Id: "true", Name: "Печатать потребительскую информацию" }]
			};

		return (
			<div className="clearfix block">
				<h4 className="col col100">Дополнительные настройки шаблона</h4>
				{_.map(showSettingsLists, function (settings, name) {
					return (<CheckGroup
						Id={name}
						key={name}
						Type="checkbox"
						RowClass={"nomargin col " + (uistate.IsMobile ? "col100" : "col50")}
						List={toCheckList(settings)}
						Value={billTemplateState.Template[name] ? ["true"] : []}
						onChange={view.props.handleChange}
					/>)
				})}
			</div>
		)
	}
}
ShowSettings = observer(ShowSettings);
class PrintSettings extends Component {
	render() {
		return (
			<div className="clearfix block">
				<CheckGroup
					Id="PrintFormat"
					Title="Форматы печати"
					Type="checkbox"
					RowClass={"col printFormat " + (uistate.IsMobile ? "col100" : "col40")}
					List={toCheckList(PrintFormatesTitles)}
					Value={billTemplateState.Template.PrintFormat}
					onChange={this.props.handleChange}
				/>
				<SliderControl
					Id="FontSize"
					Title="Размер шрифта (pt)"
					RowClass={"col " + (uistate.IsMobile ? "col100" : "col60")}
					Type="Min"
					Color="#54b6a9"
					Value={billTemplateState.Template.FontSize}
					Min={8}
					Max={18}
					onChange={this.props.handleChange}
				/>
				<ButtonGroup
					Id="TextAlign"
					Type="number"
					Title="Выравнивание текстовых полей"
					RowClass={"col " + (uistate.IsMobile ? "col100" : "col60")}
					List={TextAligns.Titles}
					Value={billTemplateState.Template.TextAlign}
					onChange={this.props.handleChange}
				/>
				<SliderControl
					Id="FontSizeComposition"
					Title="Размер шрифта для потербительской информации (pt)"
					RowClass={"col " + (uistate.IsMobile ? "col100" : "col60")}
					Type="Min"
					Color="#54b6a9"
					Value={billTemplateState.Template.FontSizeComposition}
					Min={8}
					Max={16}
					onChange={this.props.handleChange}
				/>
			</div>
		)
	}
}
PrintSettings = observer(PrintSettings);

export class BillTapeItem extends Component {
	render() {
		var style = { fontSize: this.props.Bill.FontSize },
			align = { textAlign : TextAligns.Actions[this.props.Bill.TextAlign] };

		if (this.props.Width) style = _.extend(style, { width : this.props.Width });

		return (
			<div className="clearfix billItem" id="BillTapeItem" style={style}>
				<BillHeadTape Info={this.props.Bill} Style={align} />
				<BillProducts Info={this.props.Bill} Type="tape" />
				<BillComments Info={this.props.Bill} Style={align} />
				<BillCustomer Info={this.props.Bill} />
				{this.props.Bill.Manufacturer !== "" &&
					<BillComposition Info={this.props.Bill} Type="tape" />
				}
			</div>
		)
	}
}
BillTapeItem = observer(BillTapeItem)

export class BillPaperItem extends Component {
	render() {
		var style = { 
				fontSize: this.props.Bill.FontSize,
				height: this.props.Height
			},
			align = { textAlign : TextAligns.Actions[this.props.Bill.TextAlign] };

		return (
			<div className="clearfix billItem" id="BillPaperItem" style={style}>
				<BillHeadPaper Info={this.props.Bill} />
				<BillInfo Info={this.props.Bill} />
				<BillProducts Info={this.props.Bill} Type="paper" />
				<BillComments Info={this.props.Bill} Style={align} />
				{this.props.Bill.Manufacturer !== "" &&
					<BillComposition Info={this.props.Bill} Type="tape" />
				}
			</div>
		)
	}
}
BillPaperItem = observer(BillPaperItem);

class BillHeadTape extends Component {
	render() {
		var view = this,
			info = this.props.Info;

		return (
			<div className="clearfix head">
				<div className="brand clearfix">
					{info.BrandBigLogo !== "" && <img src={info.BrandBigLogo} alt={info.Brand} />}
					<p>{info.Brand}</p>
					<p style={this.props.Style}>{Parser(info.Requisites.replace(/(\n)|(\r\n)/gi, "<br />"))}</p>
				</div>
				<div className="clearfix">
					{info.Date !== "" && <p>{formatDate(info.Date, "timedate")}</p>}
					{info.User !== "" && <p>{info.User}</p>}
					<p className="orderNumber">## {info.OrderNumber}</p>
					{info.TableNumber !== 0 && <p>Столик №{info.TableNumber}</p>}
					{info.OrderTime !== "" && <p>Заказ принят {formatDate(info.OrderTime, "timedate")}</p>}
				</div>
				{!_.isEmpty(info.Fields) &&
					<div className="fields clearfix">
						{_.map(info.Fields, function (field, i) { 
							if (_.isUndefined(field)) return null;

							return (<p key={i} style={view.props.Style}>
								{Parser(field.replace(/(\n)|(\r\n)/gi, "<br />"))}</p>); 
						})}
					</div>
				}
			</div>
		)
	}
}

class BillHeadPaper extends Component {
	render() {
		return (
			<div className="clearfix head">
				<div className="brand col col1_3">
					{this.props.Info.BrandBigLogo !== "" &&
						<img src={this.props.Info.BrandBigLogo} alt={this.props.Info.Brand} />
					}
				</div>
				<div className="orderNumber col col1_3">
					<h4>Товарный чек №{this.props.Info.OrderNumber}</h4>
				</div>
				<div className="order col col1_3">
					{this.props.Info.OrderTime !== "" &&
						<p>Заказ принят {formatDate(this.props.Info.OrderTime, "HH:mm DD.MM.YYYY")}</p>
					}
					{this.props.Info.User !== "" &&
						<p>{this.props.Info.User}</p>
					}
					{this.props.Info.Date !== "" &&
						<p>{formatDate(this.props.Info.Date, "HH:mm DD.MM.YYYY")}</p>
					}
				</div>
			</div>
		)
	}
}

class BillInfo extends Component {
	render() {
		var info = this.props.Info,
			customer = this.props.Info.Customer,
			pattern = "{%%ss%, }%%SS%, дом %%HH% {%%HB%}{, кв %%FF%}{, подъезд %%ee%}{, этаж %%ff%}{, домофон %%ii%}",
			address = !_.isEmpty(customer.CustomerAddress) ? customer.CustomerAddress : customer.Address;

		return(
			<div className="clearfix info">
				<p className="clearfix item">
					<b className="col col25">Организация</b>
					<span className="col col75">{info.Brand}, {info.Requisites}</span>
				</p>
				{_.map(info.Fields, function(value, i) {
					return(<p className="clearfix item" key={i}>
						<b className="col col25"></b>
						<span className="col col75">{Parser(value.replace(/(\n)|(\r\n)/gi, "<br />"))}</span>
					</p>)
				})}
				{customer.Name !== "" &&
					<p className="clearfix item">
						<b className="col col25">Клиент</b>
						<span className="col col75">{customer.Name}, {parsePhoneMask(customer.Login, "Россия")}</span>
					</p>
				}
				{info.PersonsNumber !== 0 &&
					<p className="clearfix item">
						<b className="col col25">Кол-во персон</b>
						<span className="col col75">{info.PersonsNumber}</span>
					</p>
				}
				{customer.Address !== "" && address.Street !== "" &&
					<p className="clearfix item">
						<b className="col col25">Адрес</b>
						<span className="col col75">{addressToPatternString(address, pattern)}</span>
					</p>
				}
				{info.TableNumber !== 0 &&
					<p className="clearfix item">
						<b className="col col25">Столик</b>
						<span className="col col75">{info.TableNumber}</span>
					</p>
				}
				{info.DescriptionAdmin !== "" && 
					<p className="clearfix item">
						<b className="col col25">Примечание</b>
						<span className="col col75">{info.DescriptionAdmin}</span>
					</p>
				}
			</div>
		)
	}
}

class BillProducts extends Component {
	render() {
		var type = this.props.Type,
			info = this.props.Info;

		return (
			<div className="clearfix products">
				<div className="titles clearfix tr">
					{type === "paper" && <span className="td number">№</span>}
					<span className="name td">Название</span>
					{type === "paper" && <span className="td price">Цена</span>}
					<span className="count td">{type === "paper" ? "Кол-во" : "Кол."}</span>
					<span className="amount td">Сумма</span>
				</div>
				{_.map(this.props.Info.Products, function (product, i) {
					return(<div className="clearfix trGroup" key={i}>
						<BillProductItem Product={product} Type={type} Index={i + 1} IsModificator={false}/>
						{!_.isNull(product.Modifications) && _.map(product.Modifications, function (mod, k) {
							return(<BillProductItem Product={mod} Type={type} key={k} IsModificator={true}/>)
						})}
					</div>
					)
					// return (<div className="clearfix product tr" key={i}>
					// 	{type === "paper" && <span className="td number">{i + 1}</span>}
					// 	<span className="td product">{product.Product}</span>
					// 	{type === "paper" && 
					// 		<span className="td price">{getRubs(product.Price, true, true)}</span>
					// 	}
					// 	<span className="td count">{product.Count}</span>
					// 	<span className="td amount">
					// 		{getRubs(product.Price * product.Count, true, true)}
					// 	</span>
					// </div>)
				})}
				<div className="clearfix amounts trGroup">
					<div className="clearfix tr">
						{type === "paper" && <span className="td number">&nbsp;</span>}
						<span className="td name">ИТОГО</span>
						{type === "paper" &&  <span className="td price">&nbsp;</span>}
						<span className="td count">&nbsp;</span>
						<span className="td amount">{getRubs(info.Amount, true, true)}</span>
					</div>
					<div className="clearfix tr">
						{type === "paper" && <span className="td number">&nbsp;</span>}
						<span className="td name">Скидка</span>
						{type === "paper" &&  <span className="td price">&nbsp;</span>}
						<span className="td count">&nbsp;</span>
						<span className="td amount">{getRubs(info.Discount, true, true)}</span>
					</div>
				</div>
				<div className="clearfix summary tr">
					{type === "paper" && <span className="td number">&nbsp;</span>}
					<span className="td name">К ОПЛАТЕ</span>
					{type === "paper" &&  <span className="td price">&nbsp;</span>}
					<span className="td count">&nbsp;</span>
					<span className="td amount">{getRubs(info.TotalAmount, true, true)}</span>
				</div>
			</div>
		)
	}
}
class BillProductItem extends Component {
	render() {
		var product = this.props.Product,
			name = this.props.IsModificator ? " + " + product.ProductName : product.Product;

		return(
			<div className="clearfix product tr">
				{this.props.Type === "paper" && 
					<span className="td number">{this.props.IsModificator ? " " : this.props.Index}</span>
				}
				<span className="td product">{name}</span>
				{this.props.Type === "paper" && 
					<span className="td price">{getRubs(product.Price, true, true)}</span>
				}
				<span className="td count">{product.Count}</span>
				<span className="td amount">
					{getRubs(product.Price * product.Count, true, true)}
				</span>
			</div>
		)
	}
}


class BillComments extends Component {
	render() { 
		return (<div className="clearfix comments" style={this.props.Style}>
			<p>{Parser(this.props.Info.FinalText.replace(/(\n)|(\r\n)/gi, "<br />"))}</p>
		</div>) 
	}
}

class BillCustomer extends Component {
	render() {
		var customer = this.props.Info.Customer,
			address = !_.isEmpty(customer.CustomerAddress) ? customer.CustomerAddress : customer.Address;

		return (
			<div className="clearfix customer">
				{this.props.Info.PersonsNumber !== 0 &&
					<p className="clearfix">
						<span className="col col45">Кол-во персон</span>
						<b className="col col55">{this.props.Info.PersonsNumber}</b>
					</p>
				}
				{customer.Name !== "" &&
					<p className="clearfix">
						<span className="col col45">Клиент</span><b className="col col55">{customer.Name}</b>
					</p>
				}
				{customer.Login !== "" &&
					<p className="clearfix">
						<span className="col col45">Телефон</span><b className="col col55">{parsePhoneMask(customer.Login, "Россия")}</b>
					</p>
				}
				{address !== "" && (address.Street !== "" || (!_.isUndefined(address.Settlement) && address.Settlement !== "")) &&
					<div className="clearfix address">
						{address.Settlement !== "" &&
							<p className="clearfix">
								<span className="col col45">Деревня/СНТ</span><b className="col col55">{address.Settlement}</b>
							</p>
						}
						<p className="clearfix">
							<span className="col col45">Улица</span><b className="col col55">{address.Street}</b>
						</p>
						<p className="clearfix">
							<span className="col col45">Дом</span><b className="col col55">{address.House}</b>
						</p>
						{address.HouseBuilding !== "" &&
							<p className="clearfix">
								<span className="col col45">Строение</span><b className="col col55">{address.HouseBuilding}</b>
							</p>
						}
						{address.Flat !== "" &&
							<p className="clearfix">
								<span className="col col45">Квартира</span><b className="col col55">{address.Flat}</b>
							</p>
						}
						{address.Intercom !== "" &&
							<p className="clearfix">
								<span className="col col45">Домофон</span><b className="col col55">{address.Intercom}</b>
							</p>
						}
						{address.Entrance !== "" && address.Entrance !== "0" &&
							<p className="clearfix">
								<span className="col col45">Подъезд</span><b className="col col55">{address.Entrance}</b>
							</p>
						}
						{address.Floor !== "" && address.Floor !== 0 &&
							<p className="clearfix">
								<span className="col col45">Этаж</span><b className="col col55">{address.Floor}</b>
							</p>
						}
					</div>
				}
				{this.props.Info.DescriptionAdmin !== "" && <p className="description">{this.props.Info.DescriptionAdmin}</p>}
			</div>
		)
	}
}

class BillComposition extends Component {
	render() {
		var info = this.props.Info,
			style = { fontSize: info.FontSizeComposition };
			
		return(
			<div className="clearfix composition" style={style}>
				<h5>Потребительская информация</h5>
				{_.map(info.Products, function(item, i) {
					return(<BillCompositionTapeItem Item={item} key={i} />)
				})}
				<p>Производитель: {info.Manufacturer}</p>
				<p>{info.LegalAddress}</p>
				{info.ProductionDate !== "" &&
					<p>Дата и время производства {formatDate(info.ProductionDate, "timedate")}</p>
				}
			</div>
		)
	}
}

class BillCompositionTapeItem extends Component {
	render() {
		var item = this.props.Item,
			hasExp = item.ExpDate !== 0 || item.ExpDateRoom !== 0 || item.ExpDateFreeze !== 0;

		if (item.Composition === "") return null;
			
		return(
			<div className="clearfix item">
				<p><b>{item.Product}</b></p>
				<p>Состав: {item.Composition}</p>
				<p>Выход: {item.Weight} г, Белки {item.Proteins.toFixed(1)} г, Жиры {item.Fats.toFixed(1)} г, 
				Углеводы {item.Carbohydrate.toFixed(1)} г, Калорийность (кКал/кДж)&nbsp;
				{item.Calories.toFixed(1)}/{item.CaloriesJoule.toFixed(1)}</p>
				{hasExp &&
					<p>Сроки и условия хранения:&nbsp; 
						{item.ExpDateRoom !== 0 && <span>При +20º – {item.ExpDateRoom} часа.</span>}&nbsp;
						{item.ExpDate !== 0 && <span>При +2º - +4º — {item.ExpDate} часа.</span>}&nbsp;
						{item.ExpDateFreeze !== 0 && <span>При -16º — {item.ExpDateFreeze} часа.</span>}
					</p>
				}
			</div>
		)
	}
}