import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import './table.css';
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";

import { daysShort, CancelReasonsTitles, CassaTransactionTypes } from './../dictionary';
import { getRubs, parsePhoneMask, formatDate } from '../functions';

const dataColumns = {
	Id: {
		Header: "ID",
		accessor: "Id",
		width: 40,
		show: true,
		setvisible: false,
	},
	Name: {
		Header: "Название",
		accessor: "Name",
		show: true,
		setvisible: false,
	},
	Login: {
		Header: "Логин",
		accessor: "Login",
		show: true,
		setvisible: false,
	},
	Email: {
		Header: "Email",
		accessor: "Email",
		show: true,
		setvisible: false,
	},
	Role: {
		Header: "Должность",
		accessor: "Role",
		show: true,
		setvisible: false,
	},
	Roles: {
		Header: "Должности",
		accessor: "Roles",
		show: true,
		setvisible: false,
	},
	Merchants: {
		Header: "Предприятия",
		accessor: "Merchants",
		show: true,
		setvisible: false,
	},
	Filials: {
		Header: "Филиалы",
		accessor: "Filials",
		show: true,
		setvisible: false,
	},
	Filial: {
		Header: "Филиал",
		accessor: "Filial",
		show: true,
		setvisible: false,
	},
	POS: {
		Header: "Точка продаж",
		accessor: "POS",
		show: true,
		setvisible: false,
	},
	Entity: {
		Header: "Юр. лицо",
		accessor: "Entity",
		show: true,
		setvisible: false,
	},
	PrintFormat: {
		Header: "Форматы печати",
		accessor: "PrintFormat",
		show: true,
		setvisible: false,
	},
	Department: {
		Header: "Отдел",
		accessor: "Department",
		show: true,
		setvisible: false,
	},
	CreateDate: {
		Header: "Дата создания",
		accessor: "CreateDate",
		width: 120,
		show: true,
		setvisible: false,
	},
	StartDate: {
		Header: "Дата начала",
		accessor: "StartDate",
		width: 90,
		show: true,
		setvisible: false,
	},
	SalaryDate: {
		Header: "Дата ЗП",
		accessor: "SalaryDate",
		width: 90,
		show: true,
		setvisible: false,
	},
	EndDate: {
		Header: "Дата окончания",
		accessor: "EndDate",
		width: 90,
		show: true,
		setvisible: false,
	},
	Promocode: {
		Header: "Промокод",
		accessor: "Promocode",
		// width: 90,
		show: true,
		setvisible: false,
	},
	Customer: {
		Header: "Пользователь",
		accessor: "Customer",
		width: 150,
		show: true,
		setvisible: false,
	},
	ActionType: {
		Header: "Тип",
		accessor: "ActionType",
		width: 150,
		show: true,
		setvisible: false,
	},
	MaxCount: {
		Header: "Макс.",
		accessor: "MaxCount",
		width: 60,
		show: true,
		setvisible: false,
	},
	BirthDate: {
		Header: "День рождения",
		accessor: "BirthDate",
		width: 120,
		show: true,
		setvisible: false,
	},
	City: {
		Header: "Город",
		accessor: "City",
		show: true,
		setvisible: false,
	},
	Address: {
		Header: "Адрес",
		accessor: "Address",
		show: true,
		setvisible: false,
	},
	Phone: {
		Header: "Номер телефона",
		accessor: "Phone",
		width: 140,
		show: true,
		setvisible: false,
	},
	// Franchise: {
	// 	Header: "Франшиза",
	// 	accessor: "Franchise",
	// 	width: 80,
	// 	show: true,
	// 	setvisible: false,
	// },
	WorkDays: {
		Header: "Рабочие дни",
		accessor: "WorkDays",
		show: true,
		setvisible: false,
	},
	POSTypes: {
		Header: "Типы точек",
		accessor: "POSTypes",
		show: true,
		setvisible: false,
	},
	ListName: {
		Header: "Чек-лист",
		accessor: "ListName",
		show: true,
		setvisible: false,
	},
	UserName: {
		Header: "Пользователь",
		accessor: "UserName",
		show: true,
		setvisible: false,
	},
	User: {
		Header: "Пользователь",
		accessor: "User",
		show: true,
		setvisible: false,
	},
	DoneTime: {
		Header: "Выполнено",
		accessor: "DoneTime",
		width: 130,
		show: true,
		setvisible: false,
	},
	Required: {
		Header: "Обязательный",
		accessor: "Required",
		show: true,
		setvisible: false,
	},
	TimeToDo: {
		Header: "Расписание",
		accessor: "TimeToDo",
		width: 100,
		show: true,
		setvisible: false,
	},
	ShiftOpen: {
		Header: "Открытие смены",
		accessor: "ShiftOpen",
		width: 150,
		show: true,
		setvisible: false,
	},
	ShiftClose: {
		Header: "Закрытие смены",
		accessor: "ShiftClose",
		width: 150,
		show: true,
		setvisible: false,
	},
	Date: {
		Header: "Дата отправки",
		accessor: "Date",
		width: 150,
		show: true,
		setvisible: false,
	},
	MinProfit: {
		Header: "Мин. наценка",
		accessor: "MinProfit",
		width: 80,
		show: true,
		setvisible: false,
	},

	ShiftDate: {
		Header: "Смена",
		accessor: "ShiftDate",
		width: 85,
		show: true,
		setvisible: false,
	},
	Type: {
		Header: "Тип",
		accessor: "Type",
		// width: 150,
		show: true,
		setvisible: false,
	},
	SectionId: {
		Header: "Раздел",
		accessor: "SectionId",
		// width: 150,
		show: true,
		setvisible: false,
	},
	InventoryNumber: {
		Header: "Инвентарный номер",
		accessor: "InventoryNumber",
		// width: 150,
		show: true,
		setvisible: false,
	},
	Products: {
		Header: "Продукты",
		accessor: "Products",
		show: true,
		setvisible: false,
	},
	Description: {
		Header: "Описание",
		accessor: "Description",
		show: true,
		setvisible: false,
	},
	UnitNet: {
		Header: "ЕИ",
		accessor: "UnitNet",
		width: 45,
		show: true,
		setvisible: false,
	},
	UnitGross: {
		Header: "ЕИ брутто",
		accessor: "UnitGross",
		width: 45,
		show: true,
		setvisible: false,
	},
	Price: {
		Header: "Цена",
		accessor: "Price",
		width: 100,
		show: true,
		setvisible: false,
		filterable : false
	},
	Cost: {
		Header: "Себестоимость",
		accessor: "Cost",
		width: 100,
		show: true,
		setvisible: false,
		filterable : false
	},
	Margin: {
		Header: "Маржинальность",
		accessor: "Margin",
		show: true,
		setvisible: false,
	},
	Markup: {
		Header: "Наценка",
		accessor: "Markup",
		show: true,
		setvisible: false,
	},
	Purchase: {
		Header: "Закупка",
		accessor: "Purchase",
		show: true,
		setvisible: false,
	},
	TransferDst: {
		Header: "Перемещение «+»",
		accessor: "TransferDst",
		show: true,
		setvisible: false,
	},
	Income: {
		Header: "Приход",
		accessor: "Income",
		show: true,
		setvisible: false,
	},
	Outcome: {
		Header: "Расход",
		accessor: "Outcome",
		show: true,
		setvisible: false,
	},
	TransferSrc: {
		Header: "Перемещение «-»",
		accessor: "TransferSrc",
		show: true,
		setvisible: false,
	},
	WriteOff: {
		Header: "Списание",
		accessor: "WriteOff",
		show: true,
		setvisible: false,
	},
	Release: {
		Header: "Выпуск",
		accessor: "Release",
		show: true,
		setvisible: false,
	},
	Сonsumption: {
		Header: "Продажа",
		accessor: "Сonsumption",
		show: true,
		setvisible: false,
	},
	Invoice: {
		Header: "Накладная",
		accessor: "Invoice",
		show: true,
		setvisible: false,
	},
	Stock: {
		Header: "Склад",
		accessor: "Stock",
		show: true,
		setvisible: false,
	},
	Status: {
		Header: "Статус",
		accessor: "Status",
		show: true,
		setvisible: false,
	},
	Provider: {
		Header: "Поставщик",
		accessor: "Provider",
		show: true,
		setvisible: false,
	},
	InvoiceNumber: {
		Header: "Номер",
		accessor: "InvoiceNumber",
		show: true,
		setvisible: false,
	},
	Time: {
		Header: "Время",
		accessor: "Time",
		show: true,
		setvisible: false,
	},
	Tips: {
		Header: "Чаевые",
		accessor: "Tips",
		show: true,
		setvisible: false,
		sortable: false,
		filterable : false, 
		width: 50
	},
	Amount: {
		Header: "Сумма",
		accessor: "Amount",
		width: 65,
		show: true,
		setvisible: false,
		filterable : false
	},
	Accrual: {
		Header: "Начисления",
		accessor: "Accrual",
		show: true,
		setvisible: false,
		filterable : false
	},
	Payout: {
		Header: "Выплаты",
		accessor: "Payout",
		show: true,
		setvisible: false,
		filterable : false
	},
	Avg: {
		Header: "Средний чек",
		accessor: "Avg",
		show: true,
		setvisible: false,
		filterable : false
	},
	AmountPos: {
		Header: "Сумма «+»",
		accessor: "AmountPos",
		width: 65,
		show: true,
		setvisible: false,
		filterable : false
	},
	AmountNeg: {
		Header: "Сумма «-»",
		accessor: "AmountNeg",
		width: 65,
		show: true,
		setvisible: false,
		filterable : false
	},
	StartAmount: {
		Header: "Нач. Остаток",
		accessor: "StartAmount",
		// width: 80,
		show: true,
		setvisible: false,
		filterable : false
	},
	Proceeds: {
		Header: "Выручка",
		accessor: "Proceeds",
		// width: 80,
		show: true,
		setvisible: false,
		filterable : false
	},
	CashIn: {
		Header: "Внесение",
		accessor: "CashIn",
		// width: 65,
		show: true,
		setvisible: false,
		filterable : false
	},
	CashOut: {
		Header: "Изъятие",
		accessor: "CashOut",
		// width: 65,
		show: true,
		setvisible: false,
		filterable : false
	},
	EndAmount: {
		Header: "Остаток",
		accessor: "EndAmount",
		// width: 80,
		show: true,
		setvisible: false,
		filterable : false
	},
	CreateAmount: {
		Header: "Принято на сумму",
		accessor: "CreateAmount",
		show: true,
		setvisible: false,
		filterable : false
	},
	CreateCount: {
		Header: "Принято кол-во",
		accessor: "CreateCount",
		show: true,
		setvisible: false,
		filterable : false
	},
	CreateAvg: {
		Header: "Принято ср. чек",
		accessor: "CreateAvg",
		show: true,
		setvisible: false,
		filterable : false
	},
	ProcessAmount: {
		Header: "Обработано на сумму",
		accessor: "ProcessAmount",
		show: true,
		setvisible: false,
		filterable : false
	}, 
	ProcessCount: {
		Header: "Обработано кол-во",
		accessor: "ProcessCount",
		show: true,
		setvisible: false,
		filterable : false
	}, 
	ProcessAvg: {
		Header: "Обработано ср. чек",
		accessor: "ProcessAvg",
		show: true,
		setvisible: false,
		filterable : false
	},

	DeadlinePayment: {
		Header: "Срок оплаты",
		accessor: "DeadlinePayment",
		show: true,
		setvisible: false,
	},
	TransferNumberName: {
		Header: "№",
		width: 100,
		accessor: "TransferNumberName",
		show: true,
		setvisible: false,
	},
	FromStock: {
		Header: "Откуда",
		accessor: "FromStock",
		show: true,
		setvisible: false,
	},
	ToStock: {
		Header: "Куда",
		accessor: "ToStock",
		show: true,
		setvisible: false,
	},
	FromUser: {
		Header: "Отправлял",
		accessor: "FromUser",
		show: true,
		setvisible: false,
	},
	ToUser: {
		Header: "Принимал",
		accessor: "ToUser",
		show: true,
		setvisible: false,
	},
	FromDate: {
		Header: "Отправлено",
		accessor: "FromDate",
		show: true,
		setvisible: false,
	},
	ToDate: {
		Header: "Принято",
		accessor: "ToDate",
		show: true,
		setvisible: false,
	},
	CourierName: {
		Header: "Имя курьера",
		accessor: "CourierName",
		show: true,
		setvisible: false,
	},
	Inventory: {
		Header: "Инвентарь",
		accessor: "Inventory",
		show: true,
		setvisible: false,
	},
	Count: {
		Header: "Кол-во",
		accessor: "Count",
		show: true,
		setvisible: false,
	},
	CountPos: {
		Header: "Кол-во «+»",
		accessor: "CountPos",
		show: true,
		setvisible: false,
	},
	CountNeg: {
		Header: "Кол-во «-»",
		accessor: "CountNeg",
		show: true,
		setvisible: false,
	},
	VendorCode: {
		Header: "Артикул",
		accessor: "VendorCode",
		show: true,
		setvisible: false,
	},
	OrderCount: {
		Header: "Заказы",
		accessor: "OrderCount",
		show: true,
		setvisible: false,
	},
	CancelReason: {
		Header: "Причина списания",
		accessor: "CancelReason",
		show: true,
		setvisible: false,
	},
	Group: {
		Header: "Группа",
		accessor: "Group",
		show: true,
		setvisible: false,
	},
	Fiscal: {
		Header: "Фиск.",
		accessor: "Fiscal",
		width: 60,
		show: true,
		setvisible: false,
	},
	OrdersAmount: {
		Header: "Сумма",
		accessor: "OrdersAmount",
		show: true,
		setvisible: false,
	},
	LastOrder: {
		Header: "Последний",
		accessor: "LastOrder",
		show: true,
		setvisible: false,
	},
	Tag: {
		Header: "Тип",
		accessor: "Tag",
		show: true,
		setvisible: false,
	},
	ToggleShift : {
		Header: "Курьерская смена",
		accessor: "ShiftFilialId",
		show: true,
		setvisible: false,
		filterable: false,
	},
	Color: {
		Header: "",
		width: 35,
		show: true,
		setvisible: false,
		sortable: false,
		filterable: false,
	},
	Icon: {
		Header: "",
		width: 42,
		show: true,
		setvisible: false,
		sortable: false,
		filterable: false,
	},
	WorkShops: {
		Header: "Цеха",
		accessor: "WorkShops",
		show: true,
		setvisible: false,
		sortable: false,
		filterable: false,
	},
	AcceptUser: {},
	AcceptDate: {},
	PaymentType: {
		Header: "Тип",
		width: 42,
		show: true,
		setvisible: false,
		sortable: false,
		filterable: false,
	},
	Edit: {
		Header: "",
		width: 42,
		show: true,
		setvisible: false,
		sortable: false,
		filterable: false,
	},
	Copy: {
		Header: "",
		width: 42,
		show: true,
		setvisible: false,
		sortable: false,
		filterable: false,
	},
	Remove: {
		Header: "",
		width: 42,
		show: true,
		setvisible: false,
		sortable: false,
		filterable: false,
	},
	View: {
		Header: "",
		width: 42,
		show: true,
		setvisible: false,
		sortable: false,
		filterable: false,
	},
	Check: {
		Header: "",
		width: 42,
		show: true,
		setvisible: false,
		sortable: false,
		filterable: false,
	},
	Pay: {
		Header: "",
		width: 42,
		show: true,
		setvisible: false,
		sortable: false,
		filterable: false,
	},
	Process: {
		Header: "",
		width: 42,
		show: true,
		setvisible: false,
		sortable: false,
		filterable: false,
	},
	Send: {
		Header: "",
		width: 42,
		show: true,
		setvisible: false,
		sortable: false,
		filterable: false,
	},
	Take: {
		Header: "",
		width: 42,
		show: true,
		setvisible: false,
		sortable: false,
		filterable: false,
	},
	DownLoad: {
		Header: "",
		width: 42,
		show: true,
		setvisible: false,
		sortable: false,
		filterable: false,
	},
	BlackList: {
		Header: "ЧС",
		width: 42,
		show: true,
		setvisible: false,
		sortable: false,
		filterable: false,
	},
	CommonPhone: {
		Header: "",
		width: 42,
		show: true,
		setvisible: false,
		sortable: false,
		filterable: false,
	},
}

export class DataTable extends Component {
	constructor(props) {
		super(props);

		var columns = _.map(this.props.Columns, function (v, i) {
			return dataColumns[i] ? _.extend(_.clone(dataColumns[i]), v, { keyI: i }) :
				_.extend(v, { keyI: i });
		}),
			columnState = _.fromPairs(_.compact(_.map(columns, function (v) {
				return v.setvisible ? [v.keyI, v.show] : "";
			})));

		this.state = {
			Columns: columns,
			ColumnState: columnState
		}

		this.changeColumnState = this.changeColumnState.bind(this);
	}

	parseBool(data) { return data ? "Да" : ""; }
	getDays(data) { return _.map(data, function (k) { return daysShort[k] }).join(", ") }

	changeColumnState(event) {
		var id = event.target.value;

		this.setState({ ColumnState: _.extend(this.state.ColumnState, { [id]: !this.state.ColumnState[id] }) });
	}

	render() {
		var count = _.isNull(this.props.Data) || _.isUndefined(this.props.Data) ? 1 : this.props.Data.length,
			state = this.state.ColumnState,
			parseColumns = {
				Edit: {
					Cell: row => _.isUndefined(row.original.CanEdit) || row.original.CanEdit ? 
						<button className="icon edit" title="Редактировать"
						onClick={e => { this.props.editRow(row.original.Id, "original") }}><i></i></button> : ""
				},
				Process: {
					Cell: row => !row.original.CanProcess ? "" :
						<button className="icon process" title="Обработать"
						onClick={e => { this.props.editRow(row.original.Id, "process") }}><i></i></button>
				},
				Take: {
					Cell: row => !row.original.CanTake ? "" :
						<button className="icon take" title="Принять на склад"
						onClick={e => { this.props.editRow(row.original.Id, "Take") }}><i></i></button>
				},
				View: {
					Cell: row => _.isUndefined(row.original.CanView) || row.original.CanView ? 
						<button className="icon view" title="Просмотреть"
							onClick={e => { this.props.editRow(row.original.Id, "view") }}><i></i></button> : ""
				},
				Send: {
					Cell: row => (!row.original.CanSend ? "" :
						<button className="icon send" title="Пометить как отправленное"
							onClick={e => { this.props.changeRowState(row.original.Id) }}><i></i></button>)
				},
				DownLoad: {
					Cell: row => (!row.original.CanSend ? "" :
						<button className="icon download" title="Скачать PDF"
							onClick={e => { this.props.download(row.original.Id) }}><i></i></button>)
				},
				Pay: {
					Cell: row => (!row.original.CanPay ? "" :
						<button className="icon pay" title="Пометить как оплаченную"
							onClick={e => { this.props.changeRowState(row.original.Id) }}><i></i></button>)
				},
				Copy: {
					Cell: row => (<button className="icon copy" title="Копировать"
						onClick={e => { this.props.editRow(row.original.Id, "copy") }}><i></i></button>)
				},
				Check: {
					Cell: row => (row.original.IsAlerted ? <a className={"icon check " + (row.original.IsChecked 
						? "active" : "")} title="Отметить как обработанный" 
						onClick={e => { this.props.checkRow(row.original.Id, row.original.IsChecked) }}><i></i></a>
						: "")
				},
				Remove: {
					Cell: row => _.isUndefined(row.original.CanRemove) || row.original.CanRemove ? 
						<button className={(_.isUndefined(row.original.Active) || row.original.Active 
							? "remove" : "activate") + " icon"} title={_.isUndefined(row.original.Active) 
							|| row.original.Active ? "Удалить" : "Восстановить"} 
							onClick={e => { this.props.removeRow(row.original.Id) }}><i></i></button> : ""
				},
				Color: {
					Cell: row => (row.original.Color !== "" ? <i className="color" 
						style={{ background : row.original.Color}}></i> : "")
				},
				Icon: {
					Cell: row => (row.original.Icon !== "" ? <i className={"icon icon_" + row.original.Icon} 
						></i> : "")
				},
				BlackList: {
					Cell: row => (row.original.BlackList ? <i className="icon blacklist" 
						title={"В черном списке с " + formatDate(row.original.BlackListDate) + ". ПРИЧИНА: " 
						+ row.original.BlackListDescription}></i> : "")
				},
				CommonPhone: {
					Cell: row => (row.original.CommonPhone ? <i className="icon common" 
						title={"Общий номер телефона для разных клиентов"}></i> : "")
				},
				Name: {
					Cell: row => (<span title={row.original.Name}>{row.original.Name}</span>)
				},
				Description: {
					Cell: row => (<span title={row.original.Description}>{row.original.Description}</span>)
				},
				ListName: {
					Cell: row => (<span title={row.original.ListName}>{row.original.ListName}</span>)
				},
				CreateDate: { Cell: row => (formatDate(row.original.CreateDate)) },
				// Franchise: { Cell: row => (this.parseBool(row.original.Franchise)) },
				WorkDays: { Cell: row => (this.getDays(row.original.WorkDays)) },
				Products: { Cell: row => (row.original.Products.join(", ")) },
				Phone: { Cell: row => (parsePhoneMask(row.original.Phone, row.original.Country)) },
				Login: { Cell: row => (parsePhoneMask(row.original.Login, "Россия")) },
				Role: { Cell: row => row.original.Role === "" ? "Все" : row.original.Role },
				Filial: { Cell: row => row.original.Filial === "" ? "Все" : row.original.Filial },
				User: { Cell: row => row.original.User === "" ? "Все" : row.original.User },
				Required: { Cell: row => row.original.Required ? "Да" : "" },
				DoneTime: { Cell: row => (formatDate(row.original.DoneTime, "DD.MM.YYYY HH:mm")) },
				ShiftOpen: { Cell: row => (formatDate(row.original.ShiftOpen, "DD.MM.YYYY HH:mm")) },
				ShiftClose: { Cell: row => (formatDate(row.original.ShiftClose, "DD.MM.YYYY HH:mm")) },
				Date: { Cell: row => formatDate(row.original.Date, "DD.MM.YYYY HH:mm") },
				BirthDate: { Cell: row => formatDate(row.original.BirthDate) },
				ShiftDate: { Cell: row => formatDate(row.original.ShiftDate) },
				StartDate: { Cell: row => formatDate(row.original.StartDate) },
				SalaryDate: { Cell: row => formatDate(row.original.SalaryDate) },
				EndDate: { Cell: row => formatDate(row.original.EndDate) },
				TimeToDo: { Cell: row => row.original.TimeToDo !== "" ? row.original.TimeToDo.split(" ")[1] : ""},
				SectionId : { Cell: row => row.original.SectionName },
				Type : { Cell: row => this.props.Type === "CancelReasons" ? CancelReasonsTitles[row.original.Type]
					: row.original.Type },
				Group : { Cell: row => this.props.Type === "CassaTransactionTag" ? CassaTransactionTypes[row.original.Group]
					: row.original.Group },
				Amount : { Cell: row => <span>{getRubs(row.original.Amount, true, true)}</span> },
				Accrual : { Cell: row => <span>{getRubs(row.original.Accrual, true, true)}</span> },
				Payout : { Cell: row => <span>{getRubs(row.original.Payout, true, true)}</span> },
				AmountPos : { Cell: row => <span className="plus">{getRubs(row.original.AmountPos, true, true)}</span> },
				AmountNeg : { Cell: row => <span className="minus">{getRubs(row.original.AmountNeg, true, true)}</span> },
				StartAmount : { Cell: row => <span>{getRubs(row.original.StartAmount, true, true)}</span> },
				Proceeds : { Cell: row => <span className="plus">{getRubs(row.original.Proceeds, true, true)}</span> },
				CashIn : { Cell: row => <span className="minus">{getRubs(row.original.CashIn, true, true)}</span> },
				CashOut : { Cell: row => <span>{getRubs(row.original.CashOut, true, true)}</span> },
				EndAmount : { Cell: row => <span className="plus">{getRubs(row.original.EndAmount, true, true)}</span> },
				
				Promocode : { Cell: row => <span className="upper" title={row.original.Promocode}>{row.original.Promocode}</span>},
				OrdersAmount : { Cell: row => <span>{getRubs(row.original.OrdersAmount, true, true)}</span> },
				LastOrder : { Cell: row => (formatDate(row.original.LastOrder)) },
				Fiscal : { Cell: row => (row.original.Fiscal ? "да" : "") },
				CreateAmount : { Cell: row => <span>{getRubs(row.original.CreateAmount, true, true)}</span> },
				CreateAvg : { Cell: row => <span>{getRubs(row.original.CreateAvg, true, true)}</span> },
				ProcessAmount : { Cell: row => <span>{getRubs(row.original.ProcessAmount, true, true)}</span> },
				ProcessAvg : { Cell: row => <span>{getRubs(row.original.ProcessAvg, true, true)}</span> },
				Avg : { Cell: row => <span>{getRubs(row.original.Avg, true, true)}</span> },
			},
			columns = _.map(this.state.Columns, function (v) {
				var c = !_.isUndefined(parseColumns[v.keyI]) ? _.extend(parseColumns[v.keyI], v) : v;
				return _.extend(c, { show: _.isUndefined(state[v.keyI]) ? true : state[v.keyI] });
			}),
			manageColumns = _.map(state, function (v, i) {
				var col = _.find(columns, { keyI: i });
				return { Name: _.isUndefined(col) ? i : col.Header, Id: i, Active: v };
			});

		return (
			<div className="table clearfix">
				{!_.isEmpty(manageColumns) &&
					<ManageColumns
						Columns={manageColumns}
						changeColumnState={(event) => this.changeColumnState(event)}
					/>
				}
				<ReactTable
					data={!_.isNull(this.props.Data) || _.isUndefined(this.props.Data) ? this.props.Data : []}
					noDataText="Нет данных"
					showPagination={false}
					columns={columns}
					filterable={this.props.IsFilterable}
					sortable={!_.isUndefined(this.props.IsSortable) ? this.props.IsSortable : true}
					defaultFilterMethod={(filter, row) => _.lowerCase(String(row[filter.id])).indexOf(_.lowerCase(filter.value)) !== -1}
					defaultSorted={this.props.Sorted}
					defaultPageSize={count}
					pageSize={count}
					// style={{ height: height }}
					className={this.props.ClassName}
					resizable={false}
					SubComponent={this.props.SubComponent}
					getTrProps={this.props.getTrProps}
				/>
			</div>
		)
	}
}
DataTable = observer(DataTable);

class ManageColumns extends Component {
	render() {
		var view = this;

		return (
			<div className="clearfix columns">
				{this.props.Columns.map(function (item, i) {
					return (<button className={item.Active ? "active" : ""} key={i} value={item.Id}
						onClick={view.props.changeColumnState}>{item.Name}</button>)
				})}
			</div>
		)
	}
}