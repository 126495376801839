import { observable, decorate } from 'mobx';
import _ from 'lodash';

class ReleaseState {
    Release = [];
    WidgetRelease = [];
    IsSendRequest = false;
    ShowAll = false;
	
	setRelease (data) {
        this.ReleaseAll = data.Success && !_.isNull(data.Semimanufactures) ? data.Semimanufactures : [];
        this.groupTasks();
		this.IsSendRequest = true;
    }

    groupTasks () {
        var store = this,
            release = _.filter(this.ReleaseAll, function (v) { 
                return store.ShowAll ? true : v.DoneTime === ""
            });
        this.Release = _.map(_.groupBy(release, "StockId"), function(stock) {
            return { StockId : stock[0].StockId, StockName : stock[0].StockName, Products : stock }
        });
    }
    
    collectProduct(id, value, index, stock) { this.Release[stock].Products[index][id] = value; }

    getRelease (id) {
        var tasks = _.flatMap(_.map(_.clone(this.Release), "Products")),
            task = _.find(tasks, { TaskId : id });

        return task ? { Id : task.Id, Count : task.ReleaseCount } : {};
    }

    setWidgetRelease (data) {
        var release = !data.Success ? [] : _.filter(data.Semimanufactures, function (v) {
            return v.DoneTime === ""; });

        this.WidgetRelease = _.take(release, 5);
        this.IsSendRequest = true;
    }
}

decorate(ReleaseState, {
	Release: observable,
    IsSendRequest: observable,
    ShowAll: observable,

    WidgetRelease: observable
});
export const releaseState = new ReleaseState();