/**
 * /pages/OrdersStatuses.js – Работа со статусами заказа
 * 
 * Список статусов доступен всем пользователям
 * Добавление и удаление статусов может производить только админ ресторана (User.IsAdmin)
 * Редактирование статусов, добавление статуса в предприятие может производить пользователь с правом «ManageOrderStatuses»
 * 
 * Список статусов существует один на весь холдинг предприятий.
 * 
 * При загрузке страницы выполняется запрос «OrderStatuses» — получение все статусов
 * При входе в режим редактирования выполняется запрос на получение статуса «OrderStatus» по ID
 * Для редактирования статуса вызывается метод «SaveOrderStatus» с набором параметров
 * Для активирования статуса в предприятии необходимо вызвать «ChangeStatusVisible» с ID статуса
 * Для изменения порядка выполнения статусов заказа необходимо вызвать метод «ChangeStatusOrder»
 * Для удаление статуса из холдинга вызывается метод «RemoveOrderStatus» с ID статуса
 */

import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import { checkRights, getRequest, toCheckList, setZero } from '../functions';
import { dashboardsTitles, buttonTitles, errors, daysLong, OrderStatusTypes, POSTypesTitles } from '../dictionary';

import { TextInput, ColorPreset, CheckGroup, ButtonGroup, SliderControl } from '../forms/FormItems';

import { uistate, confirmState, authUserState } from '../stores/common';
import { orderStatusState } from '../stores/orderStatuses';
import { ItemHeader } from './PageTemplates';

import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

const DragHandle = SortableHandle(() => <i className="SortHandle"></i>);

export class OrderStatuses extends Component {
	constructor(props) {
		super(props);

		orderStatusState.setItem(undefined);

		this.state = {}

		this.getData = this.getData.bind(this);
		this.getData();
	}

	/** API запрос на получение списка статусов заказа */
	getData() {
		orderStatusState.IsSendRequest = false;
		getRequest("OrderStatuses", {}, function (data) { orderStatusState.setList(data); });
	}

	/** 
	 * API запрос на получение статуса заказа
	 * @param {number} id ID статуса заказа
	 */
	getItem(id) {
		getRequest("OrderStatus", { Id: id }, function (data) {
			orderStatusState.setItem(data.Success ? data : undefined);
		});
	}

	/** 
	 * Открытие/закрытие выбранного статуса заказа 
	 * @param {number} id ID статуса заказа
	 */
	setId(id) {
		if (!_.isUndefined(id)) {
			if (id !== -1) this.getItem(id);
			else orderStatusState.setItem({ Id: -1 });
		} else {
			orderStatusState.setItem(undefined);
			this.getData();
		}
	}

	/**
	 * API запрос на удаление статуса заказа
	 * @param {number} id ID статуса заказа
	 */
	removeId(id) {
		if (_.isUndefined(id)) return false;

		var model = this,
			item = _.find(orderStatusState.Statuses, { Id: id }),
			text = <span>Вы действительно хотите удалить статус
						&nbsp;<b key="b1">«{item ? item.Name : ""}»</b>?</span>;

		confirmState.openConfirm(text, function () {
			getRequest("RemoveOrderStatus", { Id: id }, function () { model.getData(); });
		});
	}

	/**
	 * API запрос на активацию статуса заказа в рамках прдеприятия
	 * @param {number} id ID статуса заказа
	 */
	activateStatus(id) {
		if (_.isUndefined(id)) return false;

		var model = this,
			item = _.find(orderStatusState.Statuses, { Id: id }),
			merchant = _.find(authUserState.Merchants, { Id: authUserState.Merchant }),
			text = <span>Вы действительно хотите {item.Active ? "отключить" : "подключить"} статус
						&nbsp;<b key="b1">«{item ? item.Name : ""}»</b> для предприятия
						&nbsp;<b key="b2">«{merchant ? merchant.Name : ""}»</b>?</span>;

		confirmState.openConfirm(text, function () {
			getRequest("ChangeStatusVisible", { StatusId: id, MerchantId: authUserState.Merchant },
				function () { model.getData(); });
		});
	}

	render() {
		return (
			<div className={"page " + this.props.Type} id="OrderStatusPage">
				{_.isUndefined(orderStatusState.StatusItem) &&
					<div className="clearfix">
						<div className="head">
							<h2>{dashboardsTitles[this.props.Type] || this.props.Type}</h2>
							{_.isUndefined(orderStatusState.DirectoryItem) && authUserState.User.IsAdmin &&
								<button className="add" onClick={() => this.setId(-1)}>
									{buttonTitles[this.props.Type] || "Добавить"}</button>
							}
						</div>
						{orderStatusState.IsSendRequest && _.isEmpty(orderStatusState.Statuses) &&
							<p>{errors.EMPTY_RESPONSE}</p>
						}
						{orderStatusState.IsSendRequest && !_.isEmpty(orderStatusState.Statuses) &&
							<StatusTable setId={this.setId.bind(this)} removeId={this.removeId.bind(this)}
								getData={this.getData.bind(this)} activateStatus={this.activateStatus.bind(this)} />
						}
					</div>
				}
				{!_.isUndefined(orderStatusState.StatusItem) &&
					<StatusItem editId={this.setId.bind(this)} />
				}
			</div>
		)
	}
}
OrderStatuses = observer(OrderStatuses);

class StatusTable extends Component {
	onSortEnd = ({ oldIndex, newIndex }) => {
		orderStatusState.Statuses = arrayMove(orderStatusState.Statuses, oldIndex, newIndex);
		this.changeOrder();
	};

	changeOrder() {
		var model = this;
		getRequest("ChangeStatusOrder", orderStatusState.getOrderData(), function () { model.props.getData(); })
	}

	render() {
		var view = this;

		var SortableItem = SortableElement(({ value, sortIndex }) => {
			return (
				<StatusTableItem Item={value} Index={sortIndex} setId={view.props.setId}
					removeId={view.props.removeId} activateStatus={this.props.activateStatus} />
			)
		}),
			SortableList = SortableContainer(({ items }) => {
				return (
					<div className="SortableList">{items.map((value, index) => (
						<SortableItem key={"ag" + index} index={index} value={value} sortIndex={index} />
					))}</div>
				);
			});

		return (
			<div className="table">
				<div className="titles clearfix">
					<span className="col col30">Название</span>
					<span className="col col5">&nbsp;</span>
					<span className="col col30">Тип точки продаж</span>
					<span className="col col15">Среднее время</span>
					<span className="col col5">Вес</span>
				</div>
				<div className="clearfix tableBody" >
					<SortableList
						items={orderStatusState.Statuses}
						onSortEnd={this.onSortEnd}
						useDragHandle
						helperClass="SortableHelper"
						axis="xy"
						lockToContainerEdges={true}
						disableAutoscroll={true}
					/>
				</div>
			</div>
		)
	}
}
class StatusTableItem extends Component {
	render() {
		var item = this.props.Item,
			color = { background: item.Color },
			active = this.props.Item.Active;

		return (
			<div className={"clearfix item orderSortItem " + (!active ? "notactive" : "")}>
				{authUserState.User.IsAdmin && !this.props.ForMenu && <DragHandle />}

				<span className="col col30">{item.Name}</span>
				<span className="col col5"><i className="icon color" style={color}></i></span>
				<span className="col col30">{item.PosTypes}</span>
				<span className="col col15">{item.Time}</span>
				<span className="col col5">{item.Weight}</span>
				{checkRights("ManageOrderStatuses") &&
					<span className="col edit"><button className={(!active ? "de" : "") + "activation icon "}
						title={active ? "Отключить" : "Подключить"}
						onClick={() => this.props.activateStatus(item.Id)}><i></i></button></span>
				}
				{checkRights("ManageOrderStatuses") &&
					<span className="col edit"><button className="icon edit" title="Редактировать"
						onClick={() => this.props.setId(item.Id)}><i></i></button></span>
				}
				{item.CanRemove && authUserState.User.IsAdmin &&
					<span className="col edit"><button className="remove icon" title="Удалить"
						onClick={() => this.props.removeId(item.Id)}><i></i></button></span>
				}
			</div>
		)
	}
}

class StatusItem extends Component {
	/** API отправка запроса на редактирование статуса заказа */
	handleSubmit(event) {
		event.preventDefault();

		if (!orderStatusState.validateData()) return false;

		orderStatusState.ButtonLoading = true;

		getRequest("SaveOrderStatus", orderStatusState.getSaveData(), function (data) {
			orderStatusState.SuccessSave = data.Success;
			orderStatusState.ErrorCode = data.Success ? "SUCCESS_SAVE" : data.ErrorCode;
			orderStatusState.ButtonLoading = false;

			orderStatusState.StatusItem.Id = data.Success ? data.Id : orderStatusState.StatusItem.Id;
		});
	}

	/**
	 * Сбор данных с формы редактирования статуса заказа
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange(value, id) { orderStatusState.collectStatus(id, value); }

	/** Закрытие окна редактирования статуса */
	handleClose(event) {
		event.preventDefault();
		this.props.editId(undefined);
	}

	/** Переключение типанастройки времени в статусе заказа — гибкое/обычное */
	changeFlexType(event) {
		event.preventDefault();
		orderStatusState.IsFlexible = !orderStatusState.IsFlexible;
	}

	render() {
		var style = { height: uistate.sectionHeight - 5 },
			title = (orderStatusState.StatusItem.Id === -1 ? "New" : "Edit") + "OrderStatus",
			flexTitle = orderStatusState.IsFlexible ? "Выставить одинаковое время" : "Настроить гибко",
			isDelivery = _.indexOf(orderStatusState.StatusItem.StatusType, 6) !== -1,
			deliveryText = "Если вы работаете с зонами доставки, в данном разделе необходимо указывать " +
				"минимальное время доставки для самой ближней зоны. Дальшейшие настройки по времени доставки " +
				"будут проводится в разделе «Зоны доставки»";

		return (
			<section className="clearfix item two-cols" id="StatusItem">
				<form onSubmit={this.handleSubmit.bind(this)}>
					<ItemHeader
						Title={buttonTitles[title]}
						Error={orderStatusState.ErrorCode}
						Loading={orderStatusState.ButtonLoading}
						CanManage={checkRights("ManageOrderStatuses")}
						Success={orderStatusState.SuccessSave}
						handleClose={this.handleClose.bind(this)}
					/>
					<div className="clearfix section" style={style}>
						<div className="clearfix">
							<div className="col col75 main">
								<div className="clearfix block">
									<TextInput
										Id="Name"
										Title="Название"
										Value={orderStatusState.StatusItem.Name}
										RowClass="col col50"
										Required={true}
										Disabled={orderStatusState.StatusItem.Default}
										onChange={this.handleChange.bind(this)}
									/>
									<ColorPreset
										Id="Color"
										Title="Цвет статуса"
										Value={orderStatusState.StatusItem.Color}
										Disabled={!authUserState.User.IsAdmin}
										RowClass="col col50"
										onChange={this.handleChange.bind(this)}
									/>
								</div>
								<div className="clearfix block">
									<div className="col col70">
										<TextInput
											Id="Time"
											Title="Среднее время нахождения в статусе (мин)"
											Value={orderStatusState.StatusItem.Time}
											RowClass="col col60"
											Type="number"
											Disabled={orderStatusState.IsFlexible}
											onChange={this.handleChange.bind(this)}
										/>
										<a href="/" onClick={this.changeFlexType.bind(this)}
											className="flexible dotted">{flexTitle}</a>
									</div>
									{isDelivery &&
										<p className="comment col col30">{deliveryText}</p>
									}
								</div>
								{orderStatusState.IsFlexible && <StatusTimes />}
							</div>
							<div className="col col25 gray">
								<div className="clearfix block">
									<CheckGroup
										Id="POSType"
										Title="Доступен для типа точки продаж"
										Type="checkbox"
										List={toCheckList(POSTypesTitles)}
										Value={orderStatusState.StatusItem.POSType}
										Disabled={!authUserState.User.IsAdmin}
										onChange={this.handleChange.bind(this)}
									/>

								</div>
								<div className="clearfix block">
									<SliderControl
										Id="Weight"
										Title="Вес при сортировке заказов"
										Type="Min"
										Color="#54b6a9"
										Disabled={!authUserState.User.IsAdmin}
										Value={orderStatusState.StatusItem.Weight}
										Min={0}
										Max={100}
										Step={10}
										onChange={this.handleChange.bind(this)}
									/>
								</div>
								<div className="clearfix block">
									<h4>Системные настройки</h4>
									<p className="comment">
										Данные настройки отвечают за автоматические
										действия системы при смене статуса. Для подробной информации
										наведите на название настройки
									</p>
									<CheckGroup
										Id="StatusType"
										Type="checkbox"
										List={toCheckList(OrderStatusTypes)}
										Value={orderStatusState.StatusItem.StatusType}
										Disabled={!authUserState.User.IsAdmin}
										onChange={this.handleChange.bind(this)}
									/>
								</div>
							</div>
						</div>
					</div>
				</form>
			</section>
		)
	}
}
StatusItem = observer(StatusItem);

class StatusTimes extends Component {
	/**
	 * Изменение типа подачи заказа (зал/доставка/самовывоз)
	 * @param {number} value значение параметра
	 */
	handleChange(value) { orderStatusState.ActiveTimeType = value; }

	render() {
		var types = _.filter(POSTypesTitles, function (v) {
			return _.indexOf(orderStatusState.StatusItem.POSType, v.Id) !== -1;
		}),
			times = _.find(orderStatusState.Times, { Type: orderStatusState.ActiveTimeType });

		return (
			<div className="clearfix block flextime">
				<h4>Гибкая настройка эталонного времени</h4>
				<ButtonGroup
					Id="OrderTypeId"
					List={types}
					Type="number"
					Value={orderStatusState.ActiveTimeType}
					onChange={this.handleChange.bind(this)}
				/>
				<div className="clearfix titles">
					<span className="col col15">Время</span>
					{_.map(daysLong, function (v, i) {
						return (<span className="col time" key={i} title={v}>{v}</span>)
					})}
				</div>
				{orderStatusState.ActiveTimeType !== -1 && times &&
					<div className="clearfix">
						{_.map(times.Hours, function (times, hour) {
							return (<StatusTimeItem key={hour} Items={times} Hour={parseInt(hour, 10)} />)
						})}
					</div>
				}
			</div>
		)
	}
}
StatusTimes = observer(StatusTimes);
class StatusTimeItem extends Component {
	/**
	 * Форматирование подписей к временным диапазонам
	 * @param {number} hour час
	 */
	formatTime(hour) { return setZero(hour) + ":00 – " + setZero(hour + 1) + ":00"; }

	render() {
		var view = this;
		return (
			<div className="clearfix item">
				<span className="col col15">{this.formatTime(this.props.Hour)}</span>
				{_.map(this.props.Items, function (v, i) {
					return (<StatusTimeHourItem Item={v} Hour={view.props.Hour} key={i} Day={i} />)
				})}
			</div>
		)
	}
}
StatusTimeItem = observer(StatusTimeItem);
class StatusTimeHourItem extends Component {
	/**
	 * Сбор данных с формы эталонного времени
	 * @param {number} value значени параметра
	 * @param {string} id название параметра
	 */
	handleChange(value, id) { orderStatusState.collectTime(id, value, this.props.Day, this.props.Hour); }

	render() {
		return (
			<TextInput
				Id="Time"
				RowClass="col time nomargin"
				Required={true}
				Type="number"
				Value={this.props.Item.Time}
				onChange={this.handleChange.bind(this)}
			/>
		)
	}
}
StatusTimeHourItem = observer(StatusTimeHourItem);