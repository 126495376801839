import React, { Component } from 'react';
import { observer } from "mobx-react";
import pageLoader from '../images/loader_big.svg';
import _ from 'lodash';
import moment from 'moment';

import { DateInput, Selectize, CheckGroup, TextInput, FileInput, DropButton, ButtonGroup } from '../forms/FormItems';
import { DataTable } from '../tables/Tables';

import { getRequest, toSelectList, toCheckList, getRubs, checkRights, getUpload, getFile, setUnit, formatDate } from '../functions';
import { dashboardsTitles, errors } from '../dictionary';
import { authUserState, uistate, confirmState } from '../stores/common';
import { statState, importState, purchaseSummaryState } from '../stores/statistics';
import { ItemHeader } from './PageTemplates';

export class CostStat extends Component {
	constructor(props) {
		super(props);
		this.state = {}

		statState.setFilter(this.props.Type);

		this.getData();

		var model = this;
		getRequest("Sections", {}, function (data) { model.setData(data, "Sections"); });
	}

	setData(data, param) { this.setState({ [param]: data.Success ? data[param] : [] }); }

	getData() { 
		if (statState.Filter.OutForm === "json") {
			statState.IsSendRequest = false;
			getRequest("StatCost", statState.getFilter(), function (data) { statState.setStat(data); }); 
		} else if (statState.Filter.OutForm === "file")
			getFile("StatCost", statState.getFilter(), "File.csv"); 
	}

	handleSubmit(event) {
		if (event) {
			event.preventDefault();
			statState.Filter.OutForm = "json";
		}
		
		if (!statState.validateFilter()) return false;

		this.getData();
	}

	render() {
		var columns = { Name : {}, Cost: { width : statState.Filter.Type === 2 ? 300 : 100, 
			Cell : row => row.original.Product === "" ? "" : 
			row.original.Cost !== "" ? getRubs(row.original.Cost, true, true) : ""} };

		if (statState.Filter.Type === 3) columns = _.extend(columns, { 
			Price: { Cell : row => row.original.Product === "" ? "" : 
				row.original.Price !== "" ? getRubs(row.original.Price, true, true) : ""},
			MinProfit : { Cell : row => row.original.MinProfit + "%" },
			Margin: { Cell : row => row.original.Product !== "" ? (row.original.Margin*100).toFixed(2) + "%" 
				: <span className="block">
					<span>{(row.original.Margin ? row.original.Margin*100 : 0).toFixed(2) + "%"}</span>
					<span>{(row.original.AVGWMargin ? row.original.AVGWMargin*100 : 0).toFixed(2) + "%"}</span>
				</span>},
			Markup: { Cell : row => row.original.Product !== "" ? (row.original.Markup*100).toFixed(2) + "%" 
				: <span className="block">
					<span>{(row.original.Markup ? row.original.Markup*100 : 0).toFixed(2) + "%"}</span>
					<span>{(row.original.AVGWMarkup ? row.original.AVGWMarkup*100 : 0).toFixed(2) + "%"}</span>
				</span>},
			Count: {} });

		return (
			<div className="clearfix page" id="StatPage">
				<HeadBlock Type={this.props.Type} />
				<StatForm
					Type={this.props.Type}
					Sections={_.filter(this.state.Sections, { System: false })}
					Cities={authUserState.Cities}

					onSubmit={this.handleSubmit.bind(this)}
				/>
				<StatTable TableColumns={columns} Type={this.props.Type} />
			</div>
		)
	}
}
CostStat = observer(CostStat);

export class RawStat extends Component {
	constructor(props) {
		super(props);
		this.state = {}

		statState.setFilter(this.props.Type);

		this.getData();

		var model = this;
		getRequest("Products", { Active: true }, function (data) { model.setData(data, "Products"); });
		getRequest("Sections", {}, function (data) { model.setData(data, "Sections"); });
		getRequest("Filials", {}, function (data) { model.setData(data, "Filials"); });
		getRequest("Stocks", {}, function (data) { model.setData(data, "Stocks"); });
	}

	setData(data, param) { this.setState({ [param]: data.Success ? data[param] : [] }); }

	getData() { 
		if (statState.Filter.OutForm === "json") {
			statState.IsSendRequest = false;
			getRequest("StatRawMovement", statState.getFilter(), function (data) { statState.setStat(data); }); 
		} else if (statState.Filter.OutForm === "file")
			getFile("StatRawMovement", statState.getFilter(), "File.csv"); 
	}

	handleSubmit(event) {
		if (event) {
			event.preventDefault();
			statState.Filter.OutForm = "json";
		}

		this.getData();
	}

	render() {
		var columns = { Name: {}};

		if (statState.Filter.Type === 1) columns = _.extend(columns, {
				Purchase : { Cell : row => <span className="block plus">
					<span>{setUnit(row.original.Purchase, row.original.Unit)}</span>
					<span>{getRubs(row.original.PurchaseAmount, true, true)}</span>
				</span> }
			});

		if (statState.Filter.Type === 2) columns = _.extend(columns, {
				Release : { Cell : row => <span className="block plus">
					<span>{setUnit(row.original.Release, row.original.Unit)}</span>
					<span>{getRubs(row.original.ReleaseAmount, true, true)}</span>
				</span> },
			});

		if (statState.Filter.StockIds.length === 1) columns = _.extend(columns, {
			TransferDst : { Cell : row => <span className="block plus">
				<span>{setUnit(row.original.TransferDst, row.original.Unit)}</span>
				<span>{getRubs(row.original.TransferDstAmount, true, true)}</span>
			</span>},
			Income : { Cell : row => <span className="block plus"><b>
				<span>{setUnit(row.original.TransferDst + row.original.Purchase + row.original.Release, 
					row.original.Unit)}</span>
				<span>{getRubs(row.original.TransferDstAmount + row.original.PurchaseAmount + row.original.ReleaseAmount, true, true)}</span>
			</b></span>}
		});

		columns = _.extend(columns, {
			WriteOff : { Cell : row => <span className="block minus">
				<span>{setUnit(row.original.WriteOff, row.original.Unit)}</span>
				<span>{getRubs(row.original.WriteOffAmount, true, true)}</span>
			</span> }
		});

		if (statState.Filter.StockIds.length === 1) columns = _.extend(columns, {
			TransferSrc : { Cell : row => <span className="block minus">
				<span>{setUnit(row.original.TransferSrc, row.original.Unit)}</span>
				<span>{getRubs(row.original.TransferSrcAmount, true, true)}</span>
			</span> },
		});

		columns = _.extend(columns, {
			Сonsumption : { Cell : row => <span className="block minus">
				<span>{setUnit(row.original.Consumption, row.original.Unit)}</span>
				<span>{getRubs(row.original.ConsumptionAmount, true, true)}</span>
			</span> },
			Outcome : { Cell : row => <span className="block minus"><b>
				<span>{setUnit(row.original.WriteOff + row.original.Consumption + 
					(statState.Filter.StockIds.length === 1 ? row.original.TransferSrc : 0), row.original.Unit)}</span>
				<span>{getRubs(row.original.WriteOffAmount + row.original.ConsumptionAmount + 
					(statState.Filter.StockIds.length === 1 ? row.original.TransferSrcAmount : 0), true, true)}</span>
			</b></span>}
		});

		return (
			<div className="clearfix page" id="StatPage">
				<HeadBlock Type={this.props.Type} />
				<StatForm
					Type={this.props.Type}
					Sections={_.filter(this.state.Sections, function (v) { return (v.Type === 1 || v.Type === 2) && !v.System })}
					Products={_.filter(this.state.Products, function (v) { return v.Type === 1 || v.Type === 2 })}
					Filials={this.state.Filials}
					Stocks={this.state.Stocks}

					onSubmit={this.handleSubmit.bind(this)}
				/>
				<StatTable TableColumns={columns} Type={this.props.Type} />
			</div>
		)
	}
}
RawStat = observer(RawStat);

export class PurchaseStat extends Component {
	constructor(props) {
		super(props);
		this.state = {}

		statState.setFilter(this.props.Type);

		this.getData();

		var model = this;
		getRequest("Products", { Active: true, Type: 1 }, function (data) { model.setData(data, "Products"); });
		getRequest("Sections", { Type: 1 }, function (data) { model.setData(data, "Sections"); });
		getRequest("Filials", {}, function (data) { model.setData(data, "Filials"); });
		getRequest("Stocks", {}, function (data) { model.setData(data, "Stocks"); });
		getRequest("Providers", { Active: true }, function (data) { model.setData(data, "Providers"); });
	}

	setData(data, param) { this.setState({ [param]: data.Success ? data[param] : [] }); }

	getData() { 
		if (statState.Filter.OutForm === "json") {
			statState.IsSendRequest = false;
			getRequest("StatPurchase", statState.getFilter(), function (data) { statState.setStat(data); }); 
		} else if (statState.Filter.OutForm === "file")
			getFile("StatPurchase", statState.getFilter(), "File.csv"); 
	}

	handleSubmit(event) {
		if (event) {
			event.preventDefault();
			statState.Filter.OutForm = "json";
		}
		
		this.getData();
	}

	render() {
		var types = { 1 : "№", 2 : "ТН", 3 : "СФ" },
			columns = { Name: {}, Provider: {}, 
				Count: { Cell : row => setUnit(row.original.Count, row.original.Unit) }, 
				Amount: { width: 100,  Cell : row => row.original.Product !== "" ? getRubs(row.original.Amount, true, true)
					: <span className="block">
						<span>{getRubs(row.original.Amount, true, true)}</span>
						{row.original.Section !== "" &&
							<span>{(row.original.Share ? row.original.Share*100 : 0) + "%"}</span>
						}
					</span>}
			};
				
		if (!statState.Filter.Summary) columns = _.extend(columns, {
				Invoice: { Cell: row => row.original.Product !== "" 
						? types[row.original.InvoiceType] + " " + row.original.InvoiceNumber : ""}, 
				Date : { Cell: row => row.original.Product !== "" ? formatDate(row.original.Date) : "", Header : "Дата накладной" }
			});

		return (
			<div className="clearfix page" id="StatPage">
				<HeadBlock Type={this.props.Type} />
				<StatForm
					Type={this.props.Type}
					Sections={_.filter(this.state.Sections, { System: false })}
					Products={this.state.Products}
					Filials={this.state.Filials}
					Stocks={this.state.Stocks}
					Providers={this.state.Providers}

					onSubmit={this.handleSubmit.bind(this)}
				/>
				<StatTable TableColumns={columns} Type={this.props.Type} />
			</div>
		)
	}
}
PurchaseStat = observer(PurchaseStat);

export class SalesStat extends Component {
	constructor(props) {
		super(props);
		this.state = {}

		statState.setFilter(this.props.Type);
		importState.setImport(undefined);

		this.getData();

		var model = this;
		getRequest("Products", { Active: true, Type: 3 }, function (data) { model.setData(data, "Products"); });
		getRequest("Sections", { Type: 3 }, function (data) { model.setData(data, "Sections"); });
		getRequest("Filials", {}, function (data) { model.setData(data, "Filials"); });
		getRequest("Stocks", {}, function (data) { model.setData(data, "Stocks"); });
	}

	setData(data, param) { this.setState({ [param]: data.Success ? data[param] : [] }); }

	getData() { 
		if (statState.Filter.OutForm === "json") {
			statState.IsSendRequest = false;
			getRequest("StatOrders", statState.getFilter(), function (data) { statState.setStat(data); }); 
		} else if (statState.Filter.OutForm === "file")
			getFile("StatOrders", statState.getFilter(), "File.csv"); 
	}

	handleSubmit(event) {
		if (event) {
			event.preventDefault();
			statState.Filter.OutForm = "json";
		}
		
		this.getData();
	}

	handleImport(event) {
		event.preventDefault();
		importState.setImport({ Id : -1 });
	}

	render() {
		var columns = { Name: {}, Count: {}, Amount: {} };

		return (
			<div className="clearfix page" id="StatPage">
				{_.isUndefined(importState.Import) &&
					<HeadBlock Type={this.props.Type} CanImport={true} handleImport={this.handleImport.bind(this)} />
				}
				{_.isUndefined(importState.Import) &&
					<StatForm
						Type={this.props.Type}
						Sections={_.filter(this.state.Sections, { System: false })}
						Products={this.state.Products}
						Filials={this.state.Filials}
						Stocks={this.state.Stocks}
						
						onSubmit={this.handleSubmit.bind(this)}
					/>
				}
				{_.isUndefined(importState.Import) &&
					<StatTable TableColumns={columns} Type={this.props.Type} />
				}
				{!_.isUndefined(importState.Import) &&
					<ImportBlock Filials={this.state.Filials} Products={this.state.Products} />
				}
			</div>
		)
	}
}
SalesStat = observer(SalesStat);

export class RevisionStat extends Component {
	constructor(props) {
		super(props);
		this.state = {}

		statState.setFilter(this.props.Type);

		this.getData();

		var model = this;
		getRequest("Products", { Active: true }, function (data) { model.setData(data, "Products"); });
		getRequest("Sections", {}, function (data) { model.setData(data, "Sections"); });
		getRequest("Filials", {}, function (data) { model.setData(data, "Filials"); });
		getRequest("Stocks", {}, function (data) { model.setData(data, "Stocks"); });
	}

	setData(data, param) { this.setState({ [param]: data.Success ? data[param] : [] }); }

	getData() { 
		if (statState.Filter.OutForm === "json") {
			statState.IsSendRequest = false;
			getRequest("StatRevisions", statState.getFilter(), function (data) { statState.setStat(data); }); 
		} else if (statState.Filter.OutForm === "file")
			getFile("StatRevisions", statState.getFilter(), "File.csv"); 
	}

	handleSubmit(event) {
		if (event) {
			event.preventDefault();
			statState.Filter.OutForm = "json";
		}
		this.getData();
	}

	render() {
		var columns = { Name: {}, 
			CountPos: { Cell : row => setUnit(row.original.CountPos, row.original.Unit) }, 
			AmountPos: { width: 200 }, 
			CountNeg: { Cell : row => setUnit(row.original.CountNeg, row.original.Unit) }, 
			AmountNeg: { width: 200 } 
		};

		return (
			<div className="clearfix page" id="StatPage">
				<HeadBlock Type={this.props.Type} />
				<StatForm
					Type={this.props.Type}
					Sections={_.filter(this.state.Sections, function (v) { return (v.Type === 1 || v.Type === 2) && !v.System })}
					Products={_.filter(this.state.Products, function (v) { return v.Type === 1 || v.Type === 2 })}
					Filials={this.state.Filials}
					Stocks={this.state.Stocks}

					onSubmit={this.handleSubmit.bind(this)}
				/>
				<StatTable TableColumns={columns} Type={this.props.Type} />
			</div>
		)
	}
}
RevisionStat = observer(RevisionStat);

export class WriteOffStat extends Component {
	constructor(props) {
		super(props);
		this.state = {}

		statState.setFilter(this.props.Type);

		this.getData();

		var model = this;
		getRequest("Products", { Active: true }, function (data) { model.setData(data, "Products"); });
		getRequest("Sections", {}, function (data) { model.setData(data, "Sections"); });
		getRequest("Filials", {}, function (data) { model.setData(data, "Filials"); });
		getRequest("Stocks", {}, function (data) { model.setData(data, "Stocks"); });
		getRequest("CancelReasons", {}, function (data) { model.setData(data, "CancelReasons"); });
	}

	setData(data, param) { this.setState({ [param]: data.Success ? data[param] : [] }); }

	getData() { 
		if (statState.Filter.OutForm === "json") {
			statState.IsSendRequest = false;
			// statState.setStat({ Success : true, Products : writeoffstat});
			getRequest("WriteOffStat", statState.getFilter(), function (data) { statState.setStat(data); }); 
		} else if (statState.Filter.OutForm === "file")
			getFile("WriteOffStat", statState.getFilter(), "File.csv"); 
	}

	handleSubmit(event) {
		if (event) {
			event.preventDefault();
			statState.Filter.OutForm = "json";
		}
		this.getData();
	}

	render() {
		var columns = { Name: {}, CancelReason : {}, 
			Count: { Cell : row => setUnit(row.original.Count, row.original.Unit) }, 
			Amount: { width: 150 }
		};

		if (!statState.Filter.Summary) columns = _.extend(columns, { 
			Date : { Header : "Дата списания", Cell : row => formatDate(row.original.Date) } , 
			Description : {} });

		return (
			<div className="clearfix page" id="StatPage">
				<HeadBlock Type={this.props.Type} />
				<StatForm
					Type={this.props.Type}
					Sections={_.filter(this.state.Sections, function (v) { return (v.Type === 1 || v.Type === 2) && !v.System })}
					Products={_.filter(this.state.Products, function (v) { return v.Type === 1 || v.Type === 2 })}
					Filials={this.state.Filials}
					Stocks={this.state.Stocks}
					CancelReasons={this.state.CancelReasons}

					onSubmit={this.handleSubmit.bind(this)}
				/>
				<StatTable TableColumns={columns} Type={this.props.Type} />
			</div>
		)
	}
}
WriteOffStat = observer(WriteOffStat);

class HeadBlock extends Component {
	toggleFilter (event) {
		event.preventDefault();
		statState.FilterOpen = !statState.FilterOpen;
	}

	render() {
		return(
			<div className="head clearfix">
				<h2>{dashboardsTitles[this.props.Type]}</h2>
				<a href="/" className={"toggleFilter " + (statState.FilterOpen ? "active" : "")} 
					title="Фильтр поиска" onClick={this.toggleFilter.bind(this)}><i></i></a>
				{this.props.CanImport && checkRights("ReportStockProcess") &&
					<a href="/" className="button" onClick={this.props.handleImport}>Загрузить продажи</a>
				}
			</div>
		)
	}
}
HeadBlock = observer(HeadBlock)

class StatForm extends Component {
	handleChange(value, id) { statState.collectFilter(id, value); }

	handleClick(value, id) {
		statState.collectFilter(id, value);
		if (this.props.onSubmit) this.props.onSubmit();
	}

	setPreset(event) {
		event.preventDefault();

		statState.Filter = _.extend(statState.Filter, {
			DateFrom : event.currentTarget.dataset.datefrom,
			DateTo : event.currentTarget.dataset.dateto
		});

		statState.DatePreset = event.currentTarget.dataset.preset;
	}

	render() {
		var dropList = [{ Id : "json", Title : "Найти" }, { Id : "file", Title : "Скачать" }],
			isTypeFilter = _.indexOf(["CostPrice", "RawMovement", "RevisionsStat"], this.props.Type) !== -1,
			sectionsList = isTypeFilter ? _.filter(this.props.Sections, { Type : statState.Filter.Type }) 
				: this.props.Sections,
			productsList = !_.isEmpty(statState.Filter.SectionIds) ? _.filter(this.props.Products, function (v) { 
					return _.indexOf(statState.Filter.SectionIds, v.SectionId) !== -1;
				}) : isTypeFilter ? _.filter(this.props.Products, { Type : statState.Filter.Type }) : this.props.Products,
			stocksList = _.isEmpty(statState.Filter.FilialIds) ? this.props.Stocks :
				_.filter(this.props.Stocks, function (v) { 
					return _.indexOf(statState.Filter.FilialIds, v.FilialId) !== -1;
				}),
			types = this.props.Type === "CostPrice" ? [{ Key : 2, Value : "Полуфабрикаты"}, { Key : 3, Value : "Продукция"}]
				: _.indexOf(["RawMovement", "RevisionsStat"], this.props.Type) !== -1 
				? [{ Key : 1, Value : "Сырье"}, { Key : 2, Value : "Полуфабрикаты"}]
				: [{ Key : 1, Value : "Сырье"}, { Key : 2, Value : "Полуфабрикаты"}, { Key : 3, Value : "Продукция"}]
			
		return (
			<form className={"clearfix " + this.props.Type} onSubmit={this.props.onSubmit}>
				<div className="col col80 filter">
					<div className="dates clearfix">
						<div className="col col60 preset">
							<a className={"col col25 " + (statState.DatePreset === "today" ? "active" : "")} 
								href="/" onClick={this.setPreset.bind(this)} data-preset="today"
								data-datefrom={moment().format("YYYY-MM-DD")}
								data-dateto={moment().format("YYYY-MM-DD")}>Сегодня</a>
							<a className={"col col25 " + (statState.DatePreset === "yesterday" ? "active" : "")} 
								href="/" onClick={this.setPreset.bind(this)} data-preset="yesterday"
								data-datefrom={moment().subtract(1,"d").format("YYYY-MM-DD")}
								data-dateto={moment().subtract(1,"d").format("YYYY-MM-DD")}>Вчера</a>
							<a className={"col col25 " + (statState.DatePreset === "week" ? "active" : "")} 
								href="/" onClick={this.setPreset.bind(this)} data-preset="week"
								data-datefrom={moment().subtract(1,"w").format("YYYY-MM-DD")}
								data-dateto={moment().format("YYYY-MM-DD")}>Неделя</a>
							<a className={"col col25 " + (statState.DatePreset === "month" ? "active" : "")} 
								href="/" onClick={this.setPreset.bind(this)} data-preset="month"
								data-datefrom={moment().subtract(1,"M").format("YYYY-MM-DD")}
								data-dateto={moment().format("YYYY-MM-DD")}>Месяц</a>
						</div>
						<DateInput
							Id="DateFrom"
							Title="Начало отчета"
							Value={statState.Filter.DateFrom}
							RowClass="col col20"
							onChange={this.handleChange.bind(this)}
						/>
						<DateInput
							Id="DateTo"
							Title="Конец отчета"
							Value={statState.Filter.DateTo}
							RowClass="col col20"
							onChange={this.handleChange.bind(this)}
						/>
					</div>
					{statState.FilterOpen &&
						<div className="clearfix">
							{this.props.Type === "CostPrice" &&
								<Selectize
									Id="Type"
									Title="Тип продукта"
									Value={statState.Filter.Type}
									List={types}
									RowClass="col col25"
									isClearable={false}
									onChange={this.handleChange.bind(this)}
								/>
							}
							<Selectize
								Id="SectionIds"
								Title="Разделы"
								Value={statState.Filter.SectionIds}
								List={toSelectList(sectionsList)}
								RowClass="col col25"
								Multi={true}
								onChange={this.handleChange.bind(this)}
							/>
							{this.props.Type !== "CostPrice" &&
								<Selectize
									Id="ProductIds"
									Title="Продукты"
									Value={statState.Filter.ProductIds}
									List={toSelectList(productsList)}
									RowClass="col col25"
									Multi={true}
									onChange={this.handleChange.bind(this)}
								/>
							}
							{this.props.Type !== "CostPrice" &&
								<Selectize
									Id="FilialIds"
									Title="Филиалы"
									Value={statState.Filter.FilialIds}
									List={toSelectList(this.props.Filials)}
									RowClass="col col25"
									Multi={true}
									onChange={this.handleChange.bind(this)}
								/>
							}
							{this.props.Type !== "CostPrice" &&
								<Selectize
									Id="StockIds"
									Title="Склады"
									Value={statState.Filter.StockIds}
									List={toSelectList(stocksList)}
									RowClass="col col25"
									Multi={true}
									onChange={this.handleChange.bind(this)}
								/>
							}
							{this.props.Type === "CostPrice" &&
								<Selectize
									Id="CityId"
									Title="Город"
									Value={statState.Filter.CityId}
									List={toSelectList(this.props.Cities)}
									RowClass="col col25"
									isClearable={false}
									onChange={this.handleChange.bind(this)}
								/>
							}
							{this.props.Type === "PurchaseHistory" &&
								<Selectize
									Id="ProviderIds"
									Title="Поставщики"
									Value={statState.Filter.ProviderIds}
									List={toSelectList(this.props.Providers)}
									RowClass="col col25"
									Multi={true}
									onChange={this.handleChange.bind(this)}
								/>
							}
							{this.props.Type === "ProductsStat" &&
								<CheckGroup
									Id="OnlySales"
									Type="checkbox"
									Value={statState.Filter.OnlySales ? ["true"] : []}
									List={toCheckList([{ Id : "true", Name : "Только проданные"}])}
									RowClass="col col25 nomargin"
									onChange={this.handleChange.bind(this)}
								/>
							}
							{_.indexOf(["RawMovement", "RevisionsStat", "WriteoffStat"], this.props.Type) !== -1 &&
								<Selectize
									Id="Type"
									Title="Тип продукта"
									Value={statState.Filter.Type}
									List={types}
									RowClass="col col25"
									isClearable={false}
									onChange={this.handleChange.bind(this)}
								/>
							}
							{this.props.Type === "WriteoffStat" &&
								<Selectize
									Id="CancelReasonIds"
									Title="Причины списания"
									Value={statState.Filter.CancelReasonIds}
									List={toSelectList(this.props.CancelReasons)}
									RowClass="col col25"
									Multi={true}
									onChange={this.handleChange.bind(this)}
								/>
							}
							{_.indexOf(["PurchaseHistory", "WriteoffStat"], this.props.Type) !== -1 &&
								<CheckGroup
									Id="Summary"
									Type="checkbox"
									Value={statState.Filter.Summary ? ["true"] : []}
									List={toCheckList([{ Id : "true", Name : "Суммарно по продуктам"}])}
									RowClass="col col25"
									onChange={this.handleChange.bind(this)}
								/>
							}
						</div>
					}
				</div>
				<div className="col col20 buttons">
					<DropButton
						Id="OutForm"
						List={dropList}
						Title="Найти"
						onClick={this.handleClick.bind(this)}
					/>
				</div>
			</form>
		)
	}
}
StatForm = observer(StatForm);

class StatTable extends Component {
	render() {
		return (
			<div className="clearfix table">
				{!statState.IsSendRequest &&
					<div className="loader"><img src={pageLoader} alt="logo" /></div>
				}
				{statState.IsSendRequest && !_.isEmpty(statState.Stats) &&
					<DataTable
						Data={statState.Stats}
						Columns={this.props.TableColumns}
						Type={this.props.Type}

						PageHeight={statState.tableHeight}
						IsSortable={false}

						SubComponent={this.props.Type !== "CostPrice" ? undefined : row =>  
							(row.original.Product === "" ? null : <TableSubComponent Info={row.original.Composition} />)  }

						getTrProps={(state, rowInfo, column) => {
							return { className: rowInfo && rowInfo.original.Product === "" ? "section" : 
								this.props.Type === "CostPrice" && rowInfo.original.MinProfit > rowInfo.original.Markup*100
								? "alert" : "" };
						}}
					/>
				}
				{statState.IsSendRequest && _.isEmpty(statState.Stats) && 
					<p>По вашему запросу ничего не найдено</p>
				}
			</div>
		)
	}
}
StatTable = observer(StatTable);

class TableSubComponent extends Component {
	render() {
		return(
			<div className="clearfix subRowTable">
				{_.isNull(this.props.Info) && <p>нет данных по составу</p>}
				{!_.isNull(this.props.Info) && _.map(this.props.Info, function (item, i) {
					return(<div className="clearfix row" key={"r" + i}>
						<span className="col col25">{item.Product}</span>
						<span className="col col15">{item.Count} {item.Unit}</span>
						<span className="col col15">{getRubs(item.UnitCost, true, true)} руб./{item.Unit}</span>
						<span className="col col15">{getRubs(item.CountCost, true, true)} руб.</span>
					</div>)
				})}
			</div>
		)
	}
}

class ImportBlock extends Component {
	constructor(props) {
		super(props);
		this.state = {};

		importState.setErrors({ Success : false })
		this.getErrors();
	}

	getErrors () {
		getRequest("ImportOrdersFailed", {}, function(data) { importState.setErrors(data); });
	}

	removeErrors(ids, callback) {
		var model = this;
		getRequest("DeleteOrdersFailed", { Ids : ids }, function(data) { 
			if (callback) callback();
			else model.getErrors();
		});
	}

	handleSubmit(event) {
		event.preventDefault();

		var model = this,
			request = importState.ImportType === "file" ? "ImportFile" : "ImportOrders",
			result = importState.getSaveData();

		importState.ButtonLoading = true;


		this.removeErrors(result.Ids, function() {
			getUpload(request, result.Data, function (data) {
				importState.SuccessSave = data.Success;
				importState.ErrorCode = data.Success ? "SUCCESS_UPLOAD" : data.ErrorCode;
				importState.ButtonLoading = false;

				if (data.Success) model.getErrors();
			});
		});
	}

	handleClose(event) {
		event.preventDefault();
		importState.setImport(undefined);
	}

	handleChange(value, id) {
		if (/Type/.test(id)) importState.ImportType = value;
		else importState.collectImport(id, value);
	}

	render() {
		var view = this,
			style = { height : uistate.sectionHeight - 5 },
			types = [{ Id : "file", Name : "Через файл" }, { Id : "manual", Name : "Ручная обработка" }]

		return(
			<section className="clearfix item  two-cols"id="ImportItem">
				<form onSubmit={this.handleSubmit.bind(this)}>
					<ItemHeader
						Title="Загрузка продаж"
						Error={importState.ErrorCode}
						Loading={importState.ButtonLoading}
						CanManage={true}
						Success={importState.SuccessSave}
						handleClose={this.handleClose.bind(this)}
					/>
					<div className="clearfix section" style={style}>
						<div className="col col70 main">
							<div className="clearfix block">
								<Selectize
									Id="FilialId"
									Title="Филиал"
									List={toSelectList(this.props.Filials)}
									RowClass="col col30"
									Value={importState.Import.FilialId}
									onChange={this.handleChange.bind(this)}
								/>
								<DateInput
									Id="Date"
									Title="Дата"
									RowClass="col col30"
									Value={importState.Import.Date}
									onChange={this.handleChange.bind(this)}
								/>
								<Selectize
									Id="Type"
									Title="Тип загрузки"
									List={toSelectList(types)}
									RowClass="col col30"
									Value={importState.ImportType}
									onChange={this.handleChange.bind(this)}
								/>
							</div>
							<div className="clearfix block errors">
								<h5>Имеются ошибки загрузки в следующих филиалах:</h5>
								{_.map(importState.ErrorFilials, function (v,i) {
									return(<p key={"e" + i}>{_.find(view.props.Filials, { Id : v.FilialId}).Name}
										&nbsp;за&nbsp;{moment(v.Date).format("DD.MM.YYYY")}</p>)
								})}
							</div>
							{importState.ImportType === "file" &&
								<div className="clearfix block">
									<FileInput
										Id="File"
										Type="*"
										Ext={["all"]}
										Title="Выбрать файл"
										Value={importState.Import.File}
										onChange={this.handleChange.bind(this)}
									/>
								</div>
							}
							{importState.ImportType === "manual" &&
								<ImportProductsBlock Products={this.props.Products} 
									removeErrors={this.removeErrors.bind(this)} />
							}
						</div>
						<div className="col col30">
							<p className="comment">В данном разделе происходит загрузка продаж&nbsp;
							из ФронтПада в нашу систему.</p>
							<p className="comment">Во ФронтПаде необходимо скачать файл с продажами за&nbsp; 
							<b>одни</b> сутки. В нашей системе необходимо выбрать день, за который были&nbsp;
							совершены продажи и филиал к которому они относятся. На один филиал можно загрузить&nbsp;
							несколько файлов из ФронтПада. Файлы загружаются <b>по одному</b>.</p>
							<p className="comment">Для обработки ошибок загрузки файла выберите тип <b>«Ручная обработка»</b></p>
							<p className="comment">Если ошибка <b>«Продукт не найден»</b> это значит, что артикул из ФП не&nbsp;
							найден в нашей базе — необходимо сопоставить данной записи нужный продукт из списка.</p>
							<p className="comment">Если ошибка <b>«Недостаточно сырья»</b> это значит, возникли проблемы с&nbsp;
							выпуском нужной продукции из-за недостатка сырья на складе. Для выпуска необходимо вручную&nbsp; 
							подтвердить данную запись.</p>
						</div>
					</div>
				</form>
			</section>
		)
	}
}
ImportBlock = observer(ImportBlock);
class ImportProductsBlock extends Component {
	handleAdd(event) {
		event.preventDefault();
		importState.Products = _.concat(importState.Products, _.clone(importState.ProductDummy));
	}

	render() {
		var view = this;
		return(
			<div className="clearfix block products">
				<div className="clearfix titles">
					<span className="col col30">Продукт в системе</span>
					<span className="col col25">Артикул внеш.</span>
					<span className="col col10">Кол-во</span>
					<span className="col col35">Ошибка загрузки</span>
				</div>
				{_.map(importState.Products, function (product, i) {
					return(<ImportProductItem key={"pr" + i} Index={i} Item={product} 
						Products={view.props.Products} removeErrors={view.props.removeErrors} />)
				})}
				<a href="/" className="button icon add" onClick={this.handleAdd.bind(this)}><i></i>Добавить</a>
			</div>
		)
	}
}
ImportProductsBlock = observer(ImportProductsBlock);
class ImportProductItem extends Component {
	handleChange(value, id) {
		importState.collectProducts(id, value, this.props.Index);
	}

	handleDelete(event) {
		event.preventDefault();

		if (this.props.Item.Id !== "") {
			var model = this,
				text = <span>Вы действительно хотите исключить продукт&nbsp;
				<b key="b1">«{this.props.Item.VendorCode}»</b> из обработки?</span>;

			confirmState.openConfirm(text, function() { model.props.removeErrors([model.props.Item.Id]); });
		} else {
			var index = this.props.Index;
			importState.Products = _.filter(importState.Products, function(v, i) { return i !== index; }); 
		}
	}

	render() {
		return(
			<div className="clearfix item">
				<Selectize
					Id="ProductId"
					Title=""
					List={toSelectList(this.props.Products)}
					Value={this.props.Item.ProductId}
					RowClass="col col30"
					onChange={this.handleChange.bind(this)}
				/>
				<span className="col col25">{this.props.Item.VendorCode}</span>
				<TextInput
					Id="Count"
					Title=""
					Type="number"
					Value={this.props.Item.Count}
					RowClass="col col10"
					onChange={this.handleChange.bind(this)}
				/>
				{this.props.Item.Code !== "" &&
					<span className="col col35">{errors[this.props.Item.Code] || errors.DEFAULT}</span>
				}
				<a href="/" className="button icon remove" onClick={this.handleDelete.bind(this)}><i></i></a>
			</div>
		)
	}
}
ImportProductItem = observer(ImportProductItem);

const summary = [	
	{ Section : "Бакалея", Date : "2020-06-01", Amount : 3625400 },
	{ Section : "Гастрономия", Date : "2020-06-01", Amount : 2891700 },
	{ Section : "Заморозка", Date : "2020-06-01", Amount : 372600 },
	{ Section : "Канцелярия", Date : "2020-06-01", Amount : 715300 },
	{ Section : "Кофе Чай", Date : "2020-06-01", Amount : 362600 },
	{ Section : "Морепродукты", Date : "2020-06-01", Amount : 21761900 },
	{ Section : "Мясо", Date : "2020-06-01", Amount : 5537200 },
	{ Section : "Напитки", Date : "2020-06-01", Amount : 2635200 },
	{ Section : "Овощи Фрукты Зелень", Date : "2020-06-01", Amount : 4527700 },
	{ Section : "Пиво", Date : "2020-06-01", Amount : 26362800 },
	{ Section : "Розница", Date : "2020-06-01", Amount : 376100 },


	{ Section : "Бакалея", Date : "2020-05-01", Amount : 3274600 },
	{ Section : "Гастрономия", Date : "2020-05-01", Amount : 7219100 },
	{ Section : "Заморозка", Date : "2020-05-01", Amount : 947200 },
	{ Section : "Канцелярия", Date : "2020-05-01", Amount : 370000 },
	{ Section : "Кофе Чай", Date : "2020-05-01", Amount : 4625700 },
	{ Section : "Морепродукты", Date : "2020-05-01", Amount : 35141200 },
	{ Section : "Мясо", Date : "2020-05-01", Amount : 4625200 },
	{ Section : "Напитки", Date : "2020-05-01", Amount : 2524300 },
	{ Section : "Овощи Фрукты Зелень", Date : "2020-05-01", Amount : 6329900 },
	{ Section : "Пиво", Date : "2020-05-01", Amount : 473600 },
	{ Section : "Розница", Date : "2020-05-01", Amount : 362500 },
	
	{ Section : "Бакалея", Date : "2020-04-01", Amount : 2376200 },
	{ Section : "Гастрономия", Date : "2020-04-01", Amount : 21753500 },
	{ Section : "Заморозка", Date : "2020-04-01", Amount : 4721600 },
	{ Section : "Канцелярия", Date : "2020-04-01", Amount : 573500 },
	{ Section : "Кофе Чай", Date : "2020-04-01", Amount : 4826300 },
	{ Section : "Морепродукты", Date : "2020-04-01", Amount : 17268800 },
	{ Section : "Мясо", Date : "2020-04-01", Amount : 5735200 },
	{ Section : "Напитки", Date : "2020-04-01", Amount : 5358800 },
	{ Section : "Овощи Фрукты Зелень", Date : "2020-04-01", Amount : 7538800 },
	{ Section : "Пиво", Date : "2020-04-01", Amount : 6467800 },
	{ Section : "Розница", Date : "2020-04-01", Amount : 525600 },
]
export class PurchaseSummary extends Component {
	constructor(props) {
		super(props);

		purchaseSummaryState.setFilter();

		this.state = {};


		this.getData = this.getData.bind(this);
		this.getData();

		var model = this;
		getRequest("Filials", {}, function (data) { model.setData(data, "Filials"); });
	}

	setData(data, param) { this.setState({ [param]: data.Success ? data[param] : [] }); }

	getData() {
		purchaseSummaryState.IsSendRequest = false;
		purchaseSummaryState.setStat({ Success : true, Products : summary });

		// getRequest("PurchaseSummary", purchaseSummaryState.getFilterData(), function (data) {
		// 	purchaseSummaryState.setStat(data);
		// });
	}

	handleChange(value, id) { 
		purchaseSummaryState.collectFilter(id, value); 
		this.getData();
	}

	render() {
		var date = purchaseSummaryState.Filter.DateTo,
			prev = moment(date).subtract(6, "months"),
			next = moment(date).add(6, "months"),
			dateList = [
				{ Id : prev.format("YYYY-MM"), Name : prev.format("MMMM YYYY") },
				{ Id : date, Name : moment(date).format("MMMM YYYY") },
				{ Id : next.format("YYYY-MM"), Name : next.format("MMMM YYYY") },
			],
			months = _.map(_.rangeRight(6), function (v) {
				return _.clone(moment(date)).subtract(v, "months").format("YYYY-MM");
			}),
			columns = { Name : {}};

		_.each(months, function(month) {
			columns = _.extend(columns, { [month] : { 
				Header : formatDate(month, "MMMM YYYY"), 
				accessor : month,
				Cell : row => <span className="block">
					<span>{getRubs(row.original["Amount_" + month], true, true)}</span>
					{row.original.Name !== "ИТОГО" &&
						<span>{(row.original["Share_" + month]).toFixed(1) + "%"}</span>
					}
				</span>
			}})
		});

		return(
			<div className="page" id="StatPage">
				<div className="head clearfix"><h2>{dashboardsTitles[this.props.Type] || this.props.Type}</h2></div>
				<form className={"clearfix filter " + this.props.Type}>
					<ButtonGroup
						Id="DateTo"
						List={dateList}
						Value={purchaseSummaryState.Filter.DateTo}
						RowClass="col col40"
						onChange={this.handleChange.bind(this)}

					/>
					<Selectize
						Id="FilialId"
						Title="Филиал"
						Value={purchaseSummaryState.Filter.FilialId}
						List={toSelectList(this.state.Filials)}
						RowClass="col col20"
						onChange={this.handleChange.bind(this)}
					/>
				</form>
				<PurchaseTable TableColumns={columns} Type={this.props.Type} />
			</div>
		)
	}
}
PurchaseSummary = observer(PurchaseSummary);

class PurchaseTable extends Component {
	render() {
		return (
			<div className="clearfix table">
				{!purchaseSummaryState.IsSendRequest &&
					<div className="loader"><img src={pageLoader} alt="loader" /></div>
				}
				{purchaseSummaryState.IsSendRequest && !_.isEmpty(purchaseSummaryState.Stats) &&
					<DataTable
						Data={purchaseSummaryState.Stats}
						Columns={this.props.TableColumns}
						Type={this.props.Type}

						PageHeight={purchaseSummaryState.tableHeight}
						IsSortable={false}

						getTrProps={(state, rowInfo, column) => {
							return { className: rowInfo && rowInfo.original.Name === "ИТОГО" ? "section" : ""};
						}}
					/>
				}
				{purchaseSummaryState.IsSendRequest && _.isEmpty(purchaseSummaryState.Stats) && 
					<p>По вашему запросу ничего не найдено</p>
				}
			</div>
		)
	}
}
PurchaseTable = observer(PurchaseTable);