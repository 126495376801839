import { observable, decorate, computed } from 'mobx';
import _ from 'lodash';
import { checkRights, clearEmptyParams, collectCheckBox } from '../functions';
import { authUserState } from './common';

class CatalogState {
	SectionDummy = {
		Id : -1,
		Name : "",
		ParentId : -1,
		Description : "",
		Photo : "",
		BrandId : -1,
		Active : true,
		SubSections : []
	}
	ProductDummy = {
		Id : -1,
		SectionId : -1,
		Name : "",
		Description : "",
		ShortDescription : "",
		PhotoPreview : "",
		Photo : "",
		TTKProductId : -1,
		Cities : [],
	}
	OptionGroupDummy = {
		Name : "",
		RequiredType : false,
		CountType : -1,
		DefaultIndex : -1
	}
	OptionDummy = {
		Name : "",
		ByDefault : false,
		TTKProductId : -1,
		Cities : []
	}

	/**
	 * Установка дефолтного состояния страницы
	 */
	setDefault() {
		this.Sections = [];
		this.Brands = [];
		this.CurrentSection = -1;
		this.Products = [];
		this.TTKProducts = [];
		
		this.CanManage = checkRights("ManageProductCatalog");
		this.IsSendRequest = false;

		this.setSection(undefined);
		this.setProduct(undefined);
	}

	/** ---------- SECTIONS BLOCK ---------- */
	/**
	 * Разбор данных от сервера с разделами продуктов
	 * @param {[object]} sections ответ от сервера
	 */
	setSections(sections) {
		this.Sections = _.map(sections, function(v) {
			return _.extend(v, { SubSections : _.isNull(v.SubSections) ? [] : v.SubSections });
		});

		var brands = _.sortBy(_.uniq(_.map(sections, "BrandId"))),
			brandId = _.head(brands),
			brandSections = _.filter(this.Sections, { BrandId : brandId });
		
		this.CurrentSection = !_.isEmpty(this.Sections) ? _.head(brandSections).Id : -1;
	}

	/** Группировка разделов по брендам */
	get BrandSections () {
		if (_.isUndefined(this.Sections)) return [];

		return _.sortBy(_.map(_.groupBy(this.Sections, "BrandId"), function(items, brand) {
			return { BrandId : parseInt(brand), BrandName : items[0].BrandName, Sections : items }
		}), "BrandId");
	}

	/**
	 * Выбор активного раздела для редактирования
	 * @param {object} section информация о разделе
	 */
	setSection(section) {
		this.Section = _.isUndefined(section) ? section : section.Id === -1 ? section : section;

		this.ErrorCode = "";
		this.ButtonLoading = false;
		this.SuccessSave = false;
	}

	/** Получение списка брендов, по которым еще не созданы каталоги
	 * @returns {[object]} список брендов { Id, Name }
	 */
	get brandList() {
		if (_.isUndefined(this.Brands) || _.isEmpty(this.Brands)) return [];
		if (_.isUndefined(this.Sections) || _.isEmpty(this.Sections)) return this.Brands;

		var sectionBrands = _.map(this.Sections, "BrandId"),
			brands = _.reject(this.Brands, function(v) { return _.indexOf(sectionBrands, v.Id) !== -1; });

		if (!_.isUndefined(this.Section)) {
			var currentBrand = _.find(this.Brands, { Id : this.Section.BrandId });
			brands = _.concat(brands, currentBrand);
		}

		return brands;
	}

	/** Получение активного бренда по активному разделу
	 * @returns {number} ID бренда
	 */
	get CurrentBrand () {
		if (_.isUndefined(this.Brands) || _.isEmpty(this.Brands)) return -1;

		var section = _.find(this.Sections, { Id : this.CurrentSection });
		if (section) return section.BrandId;
		else {
			var brandId = -1,
				currentSection = this.CurrentSection;

			_.each(this.Sections, function(section) {
				if (_.find(section.SubSections, { Id : currentSection })) {
					brandId = section.BrandId;
					return false;
				}
			})
			return brandId;
		}
	}

	/**
	 * Сбор данных с формы редактирования раздела
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectSection(id, value) {
		if (/Active/.test(id)) this.Section.Active = value;
		else if (/Name/.test(id)) this.Section.Name = _.upperFirst(value);
		else this.Section[id] = value;
	}

	/** Сбор данных раздела продукта для отправки на сервер */
	getSectionData() {
		var data = _.clone(this.Section);
		data.Photo = _.isObject(data.Photo) ? data.Photo.files : data.Photo;
		return data;
	}

	/** Проверка корректности заполнения информации о разделе */
	validSectionData() {
		if (this.Section.BrandId === -1) {
			this.ErrorCode = "ORDER_BRAND_REQUIRED";
			return false;
		}

		return true;
	}

	/**
	 * Разбор списка карточек товара от сервера
	 * @param {[object]} products ответ от сервера с карточками
	 */
	setProducts(products) {
		this.Products = products;
		this.IsSendRequest = true;
	}

	/** Получение карточек товара активной категории и подстановка цен города */
	get SectionProducts() { 
		var products = _.filter(this.Products, { SectionId: this.CurrentSection }); 

		return _.map(products, function(item) {
			var price = _.find(item.Prices, { CityId : authUserState.City });
			
			return _.extend(_.clone(item), {
				Active : price ? price.Active : false,
				Price : price ? price.Price : "",
			});
		});
	}

	/** Сбор порядка Карточек продуктов */
	getProductsOrder () { return { ProductsIds : _.map(this.SectionProducts, "Id").join(",") } }

	/** ---------- CATALOG PRODUCT PAGE ---------- */
	/**
	 * Разбор ответа от сервера с данными товара
	 * @param {object} product данные по продукции или ПФ
	 * @param {string} type тип операции edit|copy
	 */
	setProduct(product, type) {
		this.Product = _.isUndefined(product) ? product : product.Id === -1
			? _.extend(_.clone(this.ProductDummy), { SectionId: this.CurrentSection }) 
			: _.omit(_.extend(_.clone(product), {
				Id: type === "copy" ? -1 : product.Id,
				Name: product.Name + (type === "copy" ? " — копия" : ""),
				Cities : _.isNull(product.Cities) ? [] : product.Cities,
				Photo : "",
				PhotoPreview : ""
			}), ["Success", "ErrorCode", "OptionsGroup"]);

		this.OptionsGroup = _.isUndefined(product) || _.isNull(product.OptionsGroup) ? [] : 
			_.map(product.OptionsGroup, function(v) {
				var defaultIndex = _.findIndex(v.Options, { ByDefault : true });

				return _.extend(v, {
					DefaultIndex : defaultIndex,
					Options : _.map(v.Options, function(o) {
						return _.extend(o, { Cities : _.isNull(o.Cities) ? [] : o.Cities });
					})
				});
			});

		this.ButtonLoading = false;
		this.ErrorCode = "";
		this.SuccessSave = false;
	}

	get TTKProductList () {
		if (_.isUndefined(this.Product)) return [];

		var brandId = this.CurrentBrand,
			sections = _.filter(this.TTKSections, function(v) {
				return !v.IsHidden && !v.System && _.indexOf(v.Brands, brandId) !== -1;
			}),
			sectionIds = _.map(sections, "Id");

		return _.filter(this.TTKProducts, function(v) { return _.indexOf(sectionIds, v.SectionId) !== -1; });
	}

	/** Получение списка родительских и дочерних разделов в рамках одного бренда */
	get sectionProductList () {
		if (_.isUndefined(this.Product)) return [];
		
		var sections = _.map(_.filter(this.Sections, { BrandId : this.CurrentBrand }), function (v) {
			return [v, _.map(v.SubSections, function(i) { return _.extend(_.clone(i), { Name : "-- " + i.Name }); })]
		});
		sections = _.compact(_.flattenDeep(sections));
		
		return _.map(sections, function (v) { return { Key: v.Id, Value: v.Name, Hidden: v.IsHidden }; });
	}

	/**
	 * Сбор данных с формы товара
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectProduct(id, value) {
		if (/Cities/.test(id)) {
			var cityId = parseInt(id.split("_")[1]);
			this.Product.Cities = collectCheckBox(cityId, value, this.Product.Cities);
		} else this.Product[id] = value;

		if (/TTKProductId/.test(id)) {
			var product = _.find(this.TTKProducts, { Id : value });
			this.Product.ShortDescription = product ? product.Description : this.Product.ShortDescription;
			this.Product.Description = product ? product.Description : this.Product.Description;
		}
	}

	/**
	 * Сбор данных с формы товара
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectOptions(id, value, groupIndex, optionIndex) {
		if (_.isUndefined(optionIndex)) {
			if (/RequiredType/.test(id)) this.OptionsGroup[groupIndex].RequiredType = value;
			else if (/CountType/.test(id)) this.OptionsGroup[groupIndex].CountType = value;
			else this.OptionsGroup[groupIndex][id] = value;
		} else {
			if (/Cities/.test(id)) {
				var cityId = parseInt(id.split("_")[1]);
				this.OptionsGroup[groupIndex].Options[optionIndex].Cities = 
					collectCheckBox(cityId, value, this.OptionsGroup[groupIndex].Options[optionIndex].Cities);
			} else if (/ByDefault/.test(id)) this.OptionsGroup[groupIndex].DefaultIndex = optionIndex;
			else this.OptionsGroup[groupIndex].Options[optionIndex][id] = value;
		}

		if (/TTKProductId/.test(id) || /ByDefault/.test(id)) {
			if (this.Product.TTKProductId === -1 && this.OptionsGroup[groupIndex].DefaultIndex === optionIndex) {
				var ttkId = this.OptionsGroup[groupIndex].Options[optionIndex].TTKProductId,
					product = _.find(this.TTKProducts, { Id : ttkId });
				
				this.Product.ShortDescription = product ? product.Description : this.Product.ShortDescription;
				this.Product.Description = product ? product.Description : this.Product.Description;
			}
		}
	}
	
	/** Сбор данных о продукции для отправки на сервер
	 * @return {object}
	 */
	getProductData() {
		var data = _.clone(this.Product);

		data = clearEmptyParams(data, [false, "", -1, 0]);

		if (_.isUndefined(data.TTKProductId)) data.TTKProductId = -1;
		
		data.Cities = data.TTKProductId === -1 ? "" : data.Cities.join(",");
		data.Photo = _.isObject(data.Photo) ? data.Photo.files : _.isUndefined(data.Photo) ? "" : data.Photo;
		data.PhotoPreview = _.isObject(data.PhotoPreview) ? data.PhotoPreview.files : 
			_.isUndefined(data.PhotoPreview) ? "" : data.PhotoPreview;

		if (!_.isEmpty(this.OptionsGroup)) _.each(this.OptionsGroup, function(group, i) {
			var name = "OptionsGroup." + i + ".";

			data[name + "Name"] = group.Name;
			data[name + "RequiredType"] = group.RequiredType;
			data[name + "CountType"] = group.CountType;
			
			_.each(group.Options, function(option, k) {
				var optionName = name + "Options." + k + ".";

				data[optionName + "Name"] = option.Name;
				data[optionName + "TTKProductId"] = option.TTKProductId;
				data[optionName + "CitiesIds"] = option.Cities.join(",");
				data[optionName + "ByDefault"] = group.DefaultIndex === k;
			});
		});

		return data;
	}

	/** Валидирование данных карточки товара
	 * 1. Раздел обязательный
	 * 2. Если нет опциий привязка ТТК обязательна
	 * 3. Если есть опции, у каждой опции должен быть привязан ТТК
	 * 4. Если есть опции, у каждой группы должен быть выбран способ выбора опции
	 * 5. Если есть группа - должна быть хотя бы одна опциия
	 * 6. Все привязанные ТТК должны быть уникальны
	 * @return {boolean} флаг корректности заполнения данных
	 */
	validateProductData() {
		var store = this,
			isValid = true,
			ttkIds = this.Product.TTKProductId !== -1 ? [this.Product.TTKProductId] : [];

		this.ErrorCode = "";
		this.SuccessSave = false;

		if (this.Product.SectionId === -1 || this.Product.SectionId === "") {
			this.ErrorCode = "SECTION_REQUIRED";
			return false;
		}

		if (_.isEmpty(this.OptionsGroup) && this.Product.TTKProductId === -1) {
			this.ErrorCode = "TTK_PRODUCT_REQUIRED";
			return false;
		}

		if (!_.isEmpty(this.OptionsGroup)) _.each(this.OptionsGroup, function(group) {
			if (group.CountType === -1) {
				store.ErrorCode = "COUNTTYPE_REQUIRED";
				isValid = false;
				return false;
			}

			if (_.isEmpty(group.Options)) {
				store.ErrorCode = "CATALOG_OPTION_REQUIRED";
				isValid = false;
				return false;
			}

			var TTKs = _.map(group.Options, "TTKProductId");
			ttkIds = _.concat(ttkIds, TTKs);

			if (_.indexOf(TTKs, -1) !== -1) {
				store.ErrorCode = "TTK_PRODUCT_OPTION_REQUIRED";
				isValid = false;
				return false;
			}
		});

		if (ttkIds.length !== _.uniq(ttkIds).length) {
			this.ErrorCode = "TTK_PRODUCT_UNIQ";
			return false;
		}

		return isValid;
	}
}
decorate(CatalogState, {
	Brands: observable,
	TTKProducts: observable,
	TTKSections: observable,

	CurrentSection: observable,
	IsSendRequest: observable,
	
	Sections: observable,
	Products: observable,

	Section: observable,

	Product: observable,
	OptionsGroup: observable,


	ButtonLoading: observable,
	ErrorCode: observable,
	SuccessSave: observable,
	CanManage: observable,

	SectionProducts: computed
});
export const catalogState = new CatalogState();

