import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

import { createBrowserHistory } from 'history';
import { Provider } from 'mobx-react';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';

import App from './App';

const browserHistory = createBrowserHistory();
const routingStore = new RouterStore();

const stores = { routing: routingStore };

const history = syncHistoryWithStore(browserHistory, routingStore);

if ("serviceWorker" in navigator) {
	navigator.serviceWorker
		.register("./firebase-messaging-sw.js")
		.then(function (registration) { console.log("Registration successful, scope is:", registration.scope); })
		.catch(function (err) { console.log("Service worker registration failed, error:", err); });
}

ReactDOM.render(<Provider {...stores}><Router history={history}><App history={history} /></Router></Provider>, document.getElementById('root'));

//serviceWorker.unregister(); - вот эта штука мне мешает. Мне нужен зарегистрированный воркер.