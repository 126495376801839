import React from 'react';
import PropTypes from 'prop-types';
import './index.css';
import FlipMove from 'react-flip-move';
import UploadIcon from './UploadIcon.svg';

const styles = {
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	flexWrap: "wrap",
	width: "100%"
};

class ReactImageUploadComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pictures: props.defaultImage ? [props.defaultImage] : [],
			files: [],
			notAcceptedFileType: [],
			notAcceptedFileSize: []
		};
		this.inputElement = '';
		this.onDropFile = this.onDropFile.bind(this);
		this.onUploadClick = this.onUploadClick.bind(this);
		this.triggerFileUpload = this.triggerFileUpload.bind(this);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.files !== this.state.files) {
			this.props.onChange(this.state.files, this.state.pictures);
		}
	}

	/*
	 Load image at the beggining if defaultImage prop exists
	 */
	componentWillReceiveProps(nextProps) {
		if (nextProps.defaultImage) {
			this.setState({ pictures: [nextProps.defaultImage] });
		}
	}

	/*
	   Check file extension (onDropFile)
	   */
	hasExtension(fileName) {
		const pattern = '(' + this.props.imgExtension.join('|').replace(/\./g, '\\.') + ')$';
		return this.props.imgExtension[0] === "all" ? true : new RegExp(pattern, 'i').test(fileName);
	}

	/*
	 Handle file validation
	 */
	onDropFile(e) {
		const files = e.target.files;
		const allFilePromises = [];

		

		// this.setState

		// Iterate over all uploaded files
		for (let i = 0; i < files.length; i++) {
			let f = files[i];
			// Check for file extension

			console.log(f.name, !this.hasExtension(f.name))
			if (!this.hasExtension(f.name)) {
				// const newArray = this.state.notAcceptedFileType.slice();
				const newArray = [];
				newArray.push(f.name);
				this.setState({ notAcceptedFileType: newArray });
				continue;
			} else this.setState({ notAcceptedFileType: [] });
			// Check for file size
			if (f.size > this.props.maxFileSize) {
				// const newArray = this.state.notAcceptedFileSize.slice();
				const newArray = [];
				newArray.push(f.name);
				this.setState({ notAcceptedFileSize: newArray });
				continue;
			} else this.setState({ notAcceptedFileSize: [] });

			allFilePromises.push(this.readFile(f));
		}

		Promise.all(allFilePromises).then(newFilesData => {
			const files = [];
			const dataURLs = [];

			newFilesData.forEach(newFileData => {
				dataURLs.push(newFileData.dataURL);
				files.push(newFileData.file);
				
			});

			this.setState({ pictures: dataURLs, files: files }, () => {
				this.props.onChange(this.state.files, this.state.pictures);
			});
		});
	}

	onUploadClick(e) {
		// Fixes https://github.com/JakeHartnell/react-images-upload/issues/55
		e.target.value = null;
	}

	/*
	   Read a file and return a promise that when resolved gives the file itself and the data URL
	 */
	readFile(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			// Read the image via FileReader API and save image result in state.
			reader.onload = function (e) {
				// Add the file name to the data URL
				let dataURL = e.target.result;
				dataURL = dataURL.replace(";base64", `;name=${file.name};base64`);
				resolve({ file, dataURL });
			};

			reader.readAsDataURL(file);
		});
	}

	/*
	 Remove the image from state
	 */
	removeImage(picture) {
		const removeIndex = this.state.pictures.findIndex(e => e === picture);
		const filteredPictures = this.state.pictures.filter((e, index) => index !== removeIndex);
		const filteredFiles = this.state.files.filter((e, index) => index !== removeIndex);

		this.inputElementRemoved.value = "true"

		this.setState({ pictures: filteredPictures, files: filteredFiles }, () => {
			this.props.onChange(this.state.files, this.state.pictures);
		});
	}

	/*
	 Check if any errors && render
	 */
	renderErrors() {
		let notAccepted = '';
		if (this.state.notAcceptedFileType.length > 0) {
			notAccepted = this.state.notAcceptedFileType.map((error, index) => {
				return (
					<div className={'errorMessage ' + this.props.errorClass} key={index} style={this.props.errorStyle}>
						{error} {this.props.fileTypeError}
					</div>
				)
			});
		}
		if (this.state.notAcceptedFileSize.length > 0) {
			notAccepted = this.state.notAcceptedFileSize.map((error, index) => {
				return (
					<div className={'errorMessage ' + this.props.errorClass} key={index} style={this.props.errorStyle}>
						* {error} {this.props.fileSizeError}
					</div>
				)
			});
		}
		return notAccepted;
	}

	/*
	 Render the upload icon
	 */
	renderIcon() {
		if (this.props.withIcon) {
			return <img src={UploadIcon} className="uploadIcon" alt="Upload Icon" />;
		}
	}

	/*
	 Render label
	 */
	renderLabel() {
		if (this.props.withLabel) {
			return <p className={this.props.labelClass} style={this.props.labelStyles}>{this.props.label}</p>
		}
	}

	/*
	 Render preview images
	 */
	renderPreview() {
		return (
			<div className="uploadPicturesWrapper">
				<FlipMove enterAnimation="fade" leaveAnimation="fade" style={styles}>
					{this.renderPreviewPictures()}
				</FlipMove>
			</div>
		);
	}

	renderPreviewPictures() {
		var type = /image/.test(this.props.accept) ? "image" : /video/.test(this.props.accept) ? "video" : "file",
			files = this.state.files,
			defaultImg = this.props.defaultImage;
		
		return this.state.pictures.map((picture, index) => {
			return (
				<div key={index} className="uploadPictureContainer">
					<div className="deleteImage" onClick={() => this.removeImage(picture)} title="Удалить файл">X</div>
					{ type === "image" &&
						<img src={picture} className="uploadPicture" alt="Предпросмотр" />
					}
					{ type === "video" &&
						<video><source src={picture}  type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' /></video>
					}
					{ type === "file" && <span>{files[index] ? files[index].name : defaultImg}</span> }
				</div>
			);
		});
	}

	/*
	 On button click, trigger input file to open
	 */
	triggerFileUpload() {
		this.inputElement.click();
	}

	render() {
		var remName = this.props.name + "_removed",
			type = /image/.test(this.props.accept) || /video/.test(this.props.accept) ? " image" : " file";

		return (
			<div className={"fileUploader " + this.props.className} style={this.props.style}>
				<div className={"fileContainer" + type}>
					<div className="buttonContainer">
						<input
							type="text"
							ref={input => this.inputElementRemoved = input}
							name={remName}
							defaultValue="false"
						/>
						{this.renderIcon()}
						{this.renderLabel()}
						<button
							type={this.props.buttonType}
							className={"chooseFileButton " + this.props.buttonClassName}
							style={this.props.buttonStyles}
							onClick={this.triggerFileUpload}
						>
							<i></i><span>{this.props.buttonText}</span>
						</button>
						<input
							type="file"
							ref={input => this.inputElement = input}
							name={this.props.name}
							multiple={!this.props.singleImage}
							onChange={this.onDropFile}
							onClick={this.onUploadClick}
							accept={this.props.accept}
						/>
						<div className="errorsContainer">{this.renderErrors()}</div>
					</div>
					{this.props.withPreview ? this.renderPreview() : null}
				</div>
			</div>
		)
	}
}

ReactImageUploadComponent.defaultProps = {
	className: '',
	buttonClassName: "",
	buttonStyles: {},
	withPreview: false,
	accept: "image/*",
	name: "",
	withIcon: true,
	buttonText: "Choose images",
	buttonType: "button",
	withLabel: true,
	label: "Max file size: 5mb, accepted: jpg|gif|png",
	labelStyles: {},
	labelClass: "",
	imgExtension: ['.jpg', '.jpeg', '.gif', '.png'],
	maxFileSize: 5242880,
	fileSizeError: " file size is too big",
	fileTypeError: " is not a supported file extension",
	errorClass: "",
	style: {},
	errorStyle: {},
	singleImage: false,
	onChange: () => { },
	defaultImage: ""
};

ReactImageUploadComponent.propTypes = {
	style: PropTypes.object,
	className: PropTypes.string,
	onChange: PropTypes.func,
	onDelete: PropTypes.func,
	buttonClassName: PropTypes.string,
	buttonStyles: PropTypes.object,
	buttonType: PropTypes.string,
	withPreview: PropTypes.bool,
	accept: PropTypes.string,
	name: PropTypes.string,
	withIcon: PropTypes.bool,
	buttonText: PropTypes.string,
	withLabel: PropTypes.bool,
	label: PropTypes.string,
	labelStyles: PropTypes.object,
	labelClass: PropTypes.string,
	imgExtension: PropTypes.array,
	maxFileSize: PropTypes.number,
	fileSizeError: PropTypes.string,
	fileTypeError: PropTypes.string,
	errorClass: PropTypes.string,
	errorStyle: PropTypes.object,
	singleImage: PropTypes.bool,
	defaultImage: PropTypes.string
};

export default ReactImageUploadComponent;
