import { observable, decorate, computed } from 'mobx';
import _ from 'lodash';
import { checkRights, collectCheckBox, getRubs } from '../functions';
import { authUserState } from './common';

class ProductState {
	PageType = 1;
	Sections = [];
	TrashSection = undefined;
	CurrentSection = -1;
	IsActive = true;
	Section = undefined;
	IsSendRequest = false;

	EditSection = undefined;
	SectionDummy = {
		Id: -1,
		Name: "",
		Type: -1,
		IsHidden: false,
		MenuGroup: 0,
		Color: "",
		Brands: [],
		System : false
	}

	ButtonLoading = false;
	ErrorCode = "";
	SuccessSave = true;

	RawDummy = {
		Id: -1,
		Name: "",
		Active: true,
		SectionId: -1,
		Description: "",
		ExpDate: "",
		ExpDateRoom: "",
		ExpDateFreeze: "",
		Calories: "",
		Proteins: "",
		Fats: "",
		Carbohydrates: "",
		UnitIdNet: -1,
		UnitIdGross: -1,
		VendorCode: "",
		AutoPrice: true,
		Price: ""
	}
	RawProviders = [];
	RawProducts = [];
	RawCosts = [];

	Product = undefined;
	ProductDummy = {
		Id: -1,
		Type: 2,
		Name: "",
		Active: true,
		SectionId: -1,
		Description: "",
		ExpDate: "",
		Calories: 0,
		Proteins: 0,
		Fats: 0,
		Carbohydrates: 0,
		CookingTime : 0,
		UnitIdNet: -1,
		VendorCode: "",
		Weight: "",
		WeightReady: "", 
		TechConditions: "",
		Count: 1,
		VAT: -1,
		Color: "",
		MinProfit: "",
		NoStat: false,
		Modificators: 0,
		IsCombo: false,
		Tags: [],
		MainPhotoURL: "",
		Video: [],
		Recomendation: "",
		WorkShops : [],
		POSTypes : [],
		RequiredComposition: false,
		BillComposition : ""
	}
	ProductComposition = {};
	ProductCompositionDummy = {
		Id: -1,
		SourceId: -1,
		Count: 0,
		Unit: "",
		SourceTrash: 0,
		SourceUseful: 0
	};
	ProductTechnique = [];
	ProductTechniqueDummy = {
		Id : -1,
		Name: "", 
		Description: "", 
		Photo: null
	};
	ProductPrices = [];
	Video = [];
	VideoDummy = { Id : -1, File : null, FileURL : "" }

	TechCounter = 0;
	ProdCounter = 0;
	VCounter = 0;

	/**
	 * Установка дефолтного состояния страницы
	 * @param {number} type тип страницы
	 */
	setDefault (type) {
		this.PageType = type;

		this.setProduct(undefined);
		this.setRaw(undefined);
		this.AllSections = [];
	}

	setAllProducts (products, type) {
		this.AllProducts = type === "active" ? products : _.concat(this.AllProducts, products);
		this.IsSendRequest = true;
	}

	get SectionProducts () { return _.filter(this.AllProducts, { SectionId : this.CurrentSection }); }

	/** ---------- SECTIONS BLOCK ---------- */
	/**
	 * Разбор данных от сервера с разделами продуктов
	 * @param {[object]} sections ответ от сервера
	 */
	setSections(sections) {
		this.Sections = _.filter(sections, { System : false });
		this.TrashSection = _.find(sections, { System : true });

		this.CurrentSection = !_.isEmpty(this.Sections) ? _.head(this.Sections).Id : -1;
	}

	/**
	 * Выбор активного раздела для редактирования
	 * @param {object} section информация о разделе
	 */
	setSection(section) {
		this.Section = _.isUndefined(section) ? section : section.Id === -1
			? _.extend(_.clone(this.SectionDummy), { Type: this.PageType })
			: _.extend(section, { Type: this.PageType, Brands : _.isNull(section.Brands) ? [] : section.Brands });

		this.EditSection = _.isUndefined(this.Section) ? undefined : this.Section.Id;
		this.ErrorCode = "";
		this.ButtonLoading = false;
		this.SuccessSave = false;
	}
	
	/**
	 * Сбор данных с формы редактирования раздела
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectSection(id, value) {
		if (/IsHidden/.test(id)) this.Section.IsHidden = value;
		else if (/Brands/.test(id)) {
			var item = id.split("_")[1] ? parseInt(id.split("_")[1], 10) : "";
			if (value && item !== "") this.Section.Brands = _.concat(this.Section.Brands, item);
			else if (!value && item !== "") this.Section.Brands = _.without(this.Section.Brands, item);
		} else if (/Name/.test(id)) this.Section.Name = [_.toUpper(_.head(value)), _.tail(value).join("")].join("");
		else this.Section[id] = value;
	}

	/** Сбор данных раздела продукта для отправки на сервер */
	getSectionData() {
		var data = _.clone(this.Section);

		if (this.PageType === 1) data = _.omit(data, ["MenuGroup", "IsHidden", "Color", "Brands"]);
		if (data.Color === "") data = _.omit(data, "Color");
		if (this.PageType === 3) data.Brands = data.Brands.join(",");
		else data = _.omit(data, "Brands");

		return data;
	}

	/** Проверка корректности заполнения информации о разделе */
	validSectionData() {
		if (_.indexOf([1,2], this.Section.MenuGroup) === -1 && this.PageType !== 1) {
			this.ErrorCode = "EMPTY_MENU_GROUP";
			return false;
		}

		return true;
	}

	/** Сбор информации для изменения порядка разделов продуктов */
	getSectionsOrder() { 
		var ids = _.map(this.Sections, "Id");

		if (!_.isUndefined(this.TrashSection)) ids = _.concat(ids, this.TrashSection.Id);
		return { SectionIds : ids.join(",") };
	}

	/** ---------- RAW PAGE ---------- */
	/**
	 * Разбор активного сырья
	 * @param {object} raw информация о сырье от сервера
	 * @param {string} type тип операции — edit, copy
	 */
	setRaw(raw, type) {
		this.Product = _.isUndefined(raw) ? raw : raw.Id === -1
			? _.extend(_.clone(this.RawDummy), { SectionId: this.CurrentSection })
			: _.omit(_.extend(raw, { 
				Id : type === "copy" ? -1 : raw.Id,
				Name : raw.Name + (type === "copy" ? " — копия" : "")
			}), ["Success", "ErrorCode"]);

		if (_.isUndefined(raw) || raw.Id === -1 || type === "copy") {
			this.RawProducts = [];
			this.RawProviders = [];
		}

		this.RawCosts = _.isUndefined(raw) ? [] : _.map(authUserState.Cities, function (city) {
			var price = _.find(raw.Costs, { CityId : city.Id });
			return {
				CityId : city.Id,
				Name : city.Name,
				Cost : getRubs(price ? price.Cost : 0, true),
				ManualPrice : price ? price.ManualPrice : true
			}
		})

		this.ButtonLoading = false;
		this.ErrorCode = "";
		this.SuccessSave = false;
	}
	/**
	 * Разбор данных о поставщиках активного сырья
	 * @param {[object]} providers список поставщиков
	 */
	setRawProviders(providers) { this.RawProviders = providers; }
	/**
	 * Разбор данных о продуктах, куда входит активное сырье
	 * @param {[object]} products  список продуктов
	 */
	setRawProducts(products) {
		if (_.isUndefined(products)) this.RawProducts = [];
		else this.RawProducts = _.groupBy(_.sortBy(products, "Type"), "Type");
	}
	/**
	 * Сбор данных с формы редактирования сырья
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectRaw(id, value) {
		this.Product[id] = value;

		if (/UnitIdNet/.test(id)) {
			var unit = _.find(authUserState.Units, { Id : parseInt(value, 10) })
			if (unit && !_.isNull(unit.InGramm) && unit.InGramm !== 0) this.Product.Weight = unit.InGramm;
			else this.Product.Weight = "";
		}
	}
	/**
	 * Сбор данных с формы редактирования стоимости сырья
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 * @param {number} index порядковый номер
	 */
	collectRawCost(id, value, index) { this.RawCosts[index][id] = value; }
	/** Сбор данных для отправки на сервер
	 * @return {object} данные о сырье
	 */
	getRawData() {
		var data = _.clone(this.Product),
			index = 0;

		_.each(data, function (v, i) {
			if (v === "" || _.isNull(v) || _.isNaN(v)) data = _.omit(data, i);
		});

		if (data.ExpDate === 0 || data.ExpDate === "0" || data.ExpDate === "" || _.isNaN(data.ExpDate)) 
			data = _.omit(data, "ExpDate");

		data = _.omit(data, ["Price", "Costs"]);

		if (!_.isEmpty(this.RawCosts)) _.each(this.RawCosts, function (v) {
			var name = "Prices." + index + ".";
			if (v.ManualPrice && parseInt(v.Cost, 10) !== 0) {
				data[name + "CityId"] = v.CityId;
				data[name + "Price"] = (parseFloat(v.Cost, 10)*100).toFixed(0);
				index++;
			}
		});

		return data;
	}
	/** Проверка корректности заполненности данных о сырье
	 * @returns {boolean} флаг корректности данных
	 */
	validateRawData() {
		var isValid = true;

		if (this.Product.UnitIdGross === -1 || this.Product.UnitIdGross === "" || 
				this.Product.UnitIdNet === -1 || this.Product.UnitIdNet === "") {
			isValid = false;
			this.ErrorCode = "UNIT_REQUIRED";
		}

		if (this.Product.SectionId === -1 || this.Product.SectionId === "") {
			isValid = false;
			this.ErrorCode = "SECTION_REQUIRED";
		}

		return isValid;
	}
	
	/** ---------- SEMI/GOODS PAGE ---------- */
	/**
	 * Разбор ответа от сервера с данными товара
	 * @param {object} product данные по продукции или ПФ
	 * @param {string} type тип операции edit|copy
	 */
	setProduct(product, type) {
		var store = this;

		this.Product = _.isUndefined(product) ? product : product.Id === -1
			? _.extend(_.clone(this.ProductDummy), { 
				SectionId: this.CurrentSection,
				Weight : this.PageType === 2 ? 1 : "",
				UnitIdNet : this.PageType === 2 ? 1 : -1
			}) : _.omit(_.extend(_.clone(product), { 
					Id : type === "copy" ? -1 : product.Id,
					Name : product.Name + (type === "copy" ? " — копия" : ""),
					Weight : this.PageType === 2 && _.indexOf([1,3], product.UnitIdNet) !== -1 
						? product.Weight/1000 : product.Weight,
					RequiredComposition : false
				}), ["Success", "ErrorCode", "Composition", "Technique", "Prices"]);

		if (!_.isUndefined(product)) this.Product.MainPhoto = "";

		this.ProductComposition = {};
		if (!_.isUndefined(product)) {
			var productGroup = product.Composition ? _.groupBy(product.Composition, "Type") : [],
				types = this.PageType === 2 ? [1,2] : [1,2,3];
			
			_.each(types, function (i) { store.ProductComposition[i] = _.isUndefined(productGroup[i]) ? [] 
				: _.map(productGroup[i], function (v) {
					var unit = _.find(authUserState.Units, { Id : v.UnitId });

					return _.extend(v, {
						Id : type === "copy" ? -1 : v.Id,
						Unit : unit ? unit.Name : "",
						SourceTrash : v.SourceTrash ? (v.SourceTrash).toFixed(3) : 0,
						SourceUseful : (v.SourceCount - (v.SourceTrash ? v.SourceTrash : 0)).toFixed(3)
					});
				}); 
			});
		}

		this.ProductTechnique = _.isUndefined(product) || product.Id === -1 || _.isNull(product.Technique)
			? [] : _.map(product.Technique, function (v) { 
				return _.extend(v, { Id : type === "copy" ? -1 : v.Id })
			});

		this.ProductPrices = _.isUndefined(product) ? [] : _.map(authUserState.Cities, function (city) {
			var price = _.find(product.Prices, { CityId : city.Id });
			return { CityId : city.Id, Name : city.Name, Price : price ? getRubs(price.Price) : "", 
				Cost : price ? price.Cost : "", Margin : price ? price.Margin : 0, Markup : price ? price.Markup : 0 };
		});

		this.Video = _.isUndefined(product) ? [] : !_.isNull(product.Video) 
			? _.map(product.Video, function (v) { 
				return _.extend(v, { FileURL : v.File, File : null, Id : type === "copy" ? -1 : v.Id }) 
			}) : checkRights("ManageProducts") && (product.Id === -1 || _.isNull(product.Video)) 
			? [_.clone(this.VideoDummy)] : [];

		this.ButtonLoading = false;
		this.ErrorCode = "";
		this.TechCounter = 0;
		this.ProdCounter = 0;
		this.VCounter = 0;
		this.SuccessSave = false;
	}
	/**
	 * Сбор данных с формы товара
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectProduct (id, value) {
		if (/IsCombo/.test(id)) this.Product.IsCombo = value;
		else if (/RequiredComposition/.test(id)) this.Product.RequiredComposition = value;
		else if (/NoStat/.test(id)) this.Product.NoStat = value;
		else if (/Modificators/.test(id)) {
			var mod = id.split("_")[1] ? parseInt(id.split("_")[1], 10) : undefined;
			this.Product.Modificators = value ? mod : 0;
		} else if (/POSTypes/.test(id)) {
			var index = id.split("_")[1] ? parseInt(id.split("_")[1], 10) : undefined;
			this.Product.POSTypes = collectCheckBox(index, value, this.Product.POSTypes);
		}
		else this.Product[id] = value;
	}
	/**
	 * Сбор данных с формы тех карты
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 * @param {number} index порядковый номер
	 * @param {number} type тип продукции
	 * @param {object} product объект выбранного продукта
	 */
	collectComposition (id, value, index, type, product) {
		this.ProductComposition[type][index][id] = value;
 
		if (id === "SourceId" && !_.isUndefined(product))
			this.ProductComposition[type][index].Unit = product.UnitNet;

		var prod = this.ProductComposition[type][index];
		this.ProductComposition[type][index].SourceCount = (parseFloat(prod.SourceTrash) + parseFloat(prod.SourceUseful)).toFixed(3);
	}
	/**
	 * Сбор данных с формы способа приготовления
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 * @param {number} index порядковый номер
	 */
	collectTechnique (id, value, index) { this.ProductTechnique[index][id] = value; }
	/**
	 * Сбор данных с формы цен продукции
	 * @param {string} value значение параметра
	 * @param {number} cityId ID города
	 */
	collectPrices (value, cityId) { 
		var index = _.findIndex(this.ProductPrices, { CityId : cityId });
		if (index !== -1) this.ProductPrices[index].Price = value; 
	}
	/**
	 * Сбор данных с формы способа приготовления
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 * @param {number} index порядковый номер
	 */
	collectVideo (id, value, index) { if (/File/.test(id)) this.Video[index].File = value; }

	/** Сбор данных о продукции для отправки на сервер
	 * @return {object}
	 */
	getProductData() {
		var data = _.clone(this.Product);

		data = _.omit(data, ["Calories", "Carbohydrates", "Fats", "Proteins", "MainPhotoURL", "Video", "SourceWeight"]);
		
		if (this.PageType === 2) {
			data = _.omit(data, ["Color", "MinProfit", "NoStat", "Modificators", "Tags", "VAT", "Prices", "IsCombo", "POSTypes"]);
			data.Weight = parseFloat(data.Weight)*(_.indexOf([1,3], data.UnitIdNet) !== -1 ? 1000 : 1);
			data.WeightReady = data.Weight;
		} else {
			_.each(this.ProductPrices, function (item, i) {
				var name = "Prices." + i + ".";
				if (item.Price !== "") _.each(item, function(value, field) { 
					if (field === "Price" || field === "CityId")
						data[name + field] = field === "Price" ? value*100 : value; 
				});
			});
		}

		// TODO когда будут подключаться модификаторы убрать эту строку
		// data = _.omit(data, ["Modificators"])

		if (this.PageType === 3 && !_.isEmpty(data.WorkShops)) data.WorkShops = data.WorkShops.join(",");
		if (this.PageType === 3) data.POSTypes = data.POSTypes.join(",");

		_.each(data, function (v,i) { 
			if (v === "" || v === 0 || _.isNull(v) || _.isNaN(v)) 
				data = _.omit(data, [i]); 
		});

		_.each(this.ProductTechnique, function (item, i) {
			var name = "Technique." + i + ".";
			if (item.Name !== "") _.each(item, function(value, field) { 
				if (field === "Photo") 
					data[name + field] = _.isObject(value) ? value.files : value === -1 ? value : null;
				else if (field === "PhotoURL")
					data[name + "Photo"] = _.isUndefined(data[name + "Photo"]) ? null : data[name + "Photo"];
				else data[name + field] = value; 
			});
			data[name + "Order"] = i
		});

		_.each(_.flatMap(this.ProductComposition), function (item, i) {
			var name = "Composition." + i + ".";
			_.each(item, function(value, field) { 
				if (field !== "Type" && field !== "Unit" && field !== "SourceUseful") 
					data[name + field] = field === "SourceTrash" ? parseFloat(value) : value; 
			});
		});

		data.MainPhoto = _.isObject(data.MainPhoto) ? data.MainPhoto.files : data.MainPhoto;

		_.each(this.Video, function (item, i) {
			var name = "Video." + i + ".";
			_.each(item, function(value, field) { 
				if (field === "File")
					data[name + field] = _.isObject(value) ? value.files : value === -1 ? value : null;
				else if (field !== "FileURL")  data[name + field] = value;
			});
		});

		if (data.ExpDate === 0 || data.ExpDate === "0" || data.ExpDate === "" || _.isNaN(data.ExpDate)) 
			data = _.omit(data, "ExpDate");

		if (data.VAT === -1 || data.VAT === "" || _.isNaN(data.VAT)) 
			data = _.omit(data, "VAT");
		
		return data;
	}

	/** Валидирование данных о продукции
	 * @return {boolean} флаг корректности заполнения данных
	 */
	validateProductData() {
		var store = this,
			isValid = true;

		this.ErrorCode = "";
		
		if (this.Product.SectionId === -1 || this.Product.SectionId === "") {
			this.ErrorCode = "SECTION_REQUIRED";
			return false;
		}

		if (this.Product.UnitIdNet === -1 || this.Product.UnitIdNet === "") {
			this.ErrorCode = "UNIT_REQUIRED";
			return false;
		}

		if (this.Product.Weight === "" || parseInt(this.Product.Weight, 10) < 1) {
			this.ErrorCode = "WEIGHT_REQUIRED";
			return false;
		}

		if (!_.isEmpty(this.ProductComposition)) _.each(_.flatMap(this.ProductComposition), function (v) {
			if (v.SourceId === "" || v.SourceId === -1) {
				store.ErrorCode = "PRODUCT_REQUIRED";
				isValid = false;
				return false;
			}
		});

		return isValid;
	}
}
decorate(ProductState, {
	PageType: observable,
	CurrentSection: observable,
	Sections: observable,
	TrashSection: observable,
	IsSendRequest: observable,
	AllProducts: observable,

	Section: observable,
	EditSection: observable,

	Product: observable,

	RawProviders: observable,
	RawProducts: observable,
	RawCosts: observable,

	ProductComposition: observable,
	ProductTechnique: observable,
	ProductPrices: observable,
	Video: observable,

	ButtonLoading: observable,
	ErrorCode: observable,
	SuccessSave: observable,

	SectionProducts: computed
});
export const productState = new ProductState();

