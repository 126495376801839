/**
 * /pages/Roles.js – Работа со настройками должностей
 * /stores/rolesusers.js – бизнес логика
 * 
 * Для получения списка должностей необходимо сделать запрос «Roles»
 * Для просмотра/редактирования/удаления должностей и отделов необходимо право «ManageRole»
 * 
 * Для изменения настроек должности необходимо отправить запрос «RoleSave»
 * Чтобы удалить должность необходимо отправить запрос «RoleRemove»
 * 
 * Для получения дополнительных данных необходимо отправить запросы
 * 		«Rights» для получения списка прав
 * 		«Dashboards» для получения списка рабочих столов
 * 		«NotificationTypes» для получения списка уведомлений
 * 
 * Для работы с разделами необходимо вызвать следующие методы
 * 		«Departments» для получения списка отделов
 * 		«DepartmentSave» для получения добавления отдела
 * 		«DepartmentRemove» для получения списка уведомлений
 */

import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import { TextInput, Selectize, SubmitButton } from '../forms/FormItems';

import { TableFilterPage, ItemHeader, DashboardBlock, RightsBlock, NotificationBlock } from './PageTemplates';

import { buttonTitles } from '../dictionary';
import { roleState, checkedRights, checkedBoards } from '../stores/rolesusers';
import { getErrorText, getRequest, toSelectList } from '../functions';
import { uistate, confirmState } from '../stores/common';

class RolesPage extends Component {
	constructor(props) {
		super(props);
		
		roleState.setDefault();
		
		this.state = {};

		this.getData();
		this.getDepartments();
		
		getRequest("Rights", {}, (data) => { 
			this.setData(data, "Rights"); 
			checkedRights.allRights = data.Rights;
		});
		getRequest("Dashboards", {}, (data) => { 
			this.setData(data, "Dashboards"); 
			checkedBoards.allBoards = data.Dashboards;
		});
		
		getRequest("NotificationTypes", {}, function (data) { 
			roleState.Notifications = data.Success && !_.isNull(data.Notifications) ? data.Notifications : [];
		});
	}

	/**
	 * Разбор ответа от сервера и сотановка данных модели
	 * @param {object} data ответ от сервера
	 * @param {string} param название параметра
	 */
	setData(data, param) { this.setState({ [param] : data.Success ? data[param] : [] }); }

	/** API запрос «Roles» на получение списка должностей */
	getData() {
		roleState.IsSendRequest = false;
		getRequest("Roles", {}, function (data) { roleState.setRoles(data); });
	}

	/**
	 * API запрос «Role» на получение должности по ID
	 * @param {number} id ID должности
	 */
	getItem(id) {
		getRequest("Role", { Id : id }, function (data) { roleState.setRole(data.Success ? data : undefined); });
	}
	/**
	 * Выбор/сброс активной должности
	 * @param {number} id ID должности или undefined (сброс)
	 */
	setId(id) {
		if (!_.isUndefined(id)) {
			if (id === -1) roleState.setRole({ Id : -1 });
			else this.getItem(id);
		} else {
			roleState.setRole(undefined);
			this.getData();
		}
	}

	/**
	 * API запрос «RoleRemove» на удаление должности по ID
	 * @param {number} id ID должности
	 */
	removeId(id) { 
		if (_.isUndefined(id)) return false;

		var model = this,
			item = _.find(roleState.Roles, { Id : id }),
			text = <span>Вы действительно хотите удалить должность <b key="b1">«{item ? item.Name : ""}»</b>?</span>;

		confirmState.openConfirm(text, function(){
			getRequest("RoleRemove", { Id : id }, function (data) { model.getData(); });
		});
	}

	/** API запрос «Departments» на получение списка отделов */
	getDepartments() {
		getRequest("Departments", {}, (data) => { 
			roleState.Departments = data.Success && !_.isNull(data.Departments) ? data.Departments : [];
		});
	}

	render() {
		var columns = { Name: {}, Department: {} };

		if (roleState.CanManage) _.extend(columns, { Edit: {}, Remove: {} })

		return (
			<div className="page" id="RolesPage">
				{roleState.IsSendRequest && _.isUndefined(roleState.Role) &&
					<TableFilterPage 
						Type={this.props.Type}
						TableData={roleState.Roles}
						TableColumns={columns}
						CanManage={roleState.CanManage}

						setId={this.setId.bind(this)}
						removeId={this.removeId.bind(this)}
					/>
				}
				{!_.isUndefined(roleState.Role) && 
					<RoleItem
						Dashboards={this.state.Dashboards}
						Rights={this.state.Rights}
						setId={this.setId.bind(this)}
						getDepartments={this.getDepartments.bind(this)}
					/>
				}
				{!_.isUndefined(roleState.Department) &&
					<DepartmentBlock getDepartments={this.getDepartments.bind(this)} />
				}
			</div>
		)
	}
}
RolesPage = observer(RolesPage);

class RoleItem extends Component {
	/** Закрытие окна редактирования */
	handleClose (event) { 
		event.preventDefault(); 
		this.props.setId(undefined); 
	}

	/** Показать/скрыть форму создания отдела */
	handleToggle (event) {
		event.preventDefault();
		roleState.setDepartment({ Id : -1 });
	}

	/** API запрос «RoleSave» на сохранение данных должности */
	handleSubmit (event) {
		event.preventDefault();

		roleState.ButtonLoading = true;

		getRequest("RoleSave", roleState.getSaveData(), (data) => {
			roleState.ErrorCode = data.Success ? "SUCCESS_SAVE" : data.ErrorCode;
			roleState.SuccessSave = data.Success;
			roleState.Role.Id = data.Success ? data.Id : -1;
			roleState.ButtonLoading = false;
		});
	}

	/** API запрос на удаление отдела */
	removeId(id) { 
		if (_.isUndefined(id)) return false;

		var model = this,
			depId = parseInt(id,10),
			item = _.find(roleState.Departments, { Id : depId }),
			text = <span>Вы действительно хотите удалить отдел <b key="b1">«{item ? item.Name : ""}»</b>?</span>;

		confirmState.openConfirm(text, function() {
			getRequest("DepartmentRemove", { Id : depId }, (data) => {
				roleState.ErrorCode = data.ErrorCode;
				model.props.getDepartments();
			});
		});
	}

	render() {
		var item = roleState.Role,
			style = { minHeight : uistate.sectionHeight - 67 };

		return(
			<section className="clearfix item two-cols" id="RoleItem">
				<form onSubmit={this.handleSubmit.bind(this)} >
					<ItemHeader 
						Title={buttonTitles[(roleState.Role.Id === -1 ? "New" : "Edit") + "Role"]}
						Error={roleState.ErrorCode}
						Loading={roleState.ButtonLoading}
						CanManage={roleState.CanManage}
						Success={roleState.SuccessSave}
						handleClose={this.handleClose.bind(this)}
					/>
					<div className="clearfix section" style={style}>
						<div className="clearfix">
							<div className={uistate.IsMobile ? "col col100" : "col col70"}>
								<div className='mainInfo clearfix block'>
									<TextInput 
										Id="Name"
										Title="Должность"
										Required={true}
										Value={item.Name}
										Disabled={item.IsDefault}
										RowClass={uistate.IsMobile ? "" : "col col35"}
										onChange={(value,id) => roleState.collectRole(id, value)}
									/>
									<Selectize 
										Id="DepartmentId"
										Title="Отдел"
										Type="Departments"
										List={toSelectList(roleState.Departments)}
										Value={item.DepartmentId}
										Disabled={item.IsDefault}
										Required={true}
										RowClass={uistate.IsMobile ? "" : "col col40"}
										onRemove={this.removeId.bind(this)}
										onChange={(value,id) => roleState.collectRole(id, value)}
									/>
									<a href="/" className="button" 
										onClick={this.handleToggle.bind(this)}>Создать отдел</a>
								</div>
								<NotificationBlock 
									Value={roleState.Role.Notifications} 
									List={roleState.NotificationsList}
									onChange={(value,id) => roleState.collectRole(id, value)}
								/>
								<DashboardBlock BlockClass="clearfix block" />
							</div>
							<RightsBlock BlockClass={uistate.IsMobile ? "col col100" : "col col30"} />
						</div>
					</div>
				</form>
			</section>
		)
	}
}
RoleItem = observer(RoleItem);

class DepartmentBlock extends Component {
	/** API «DepartmentSave» запрос на сохранение данных отдела */
	handleSave (event) {
		event.preventDefault();

		getRequest("DepartmentSave", roleState.Department, (data) => {
			if (data.Success) {
				roleState.setDepartment();
				this.props.getDepartments();
				roleState.Role.DepartmentId = data.Id;
			} else roleState.ErrorCode = data.ErrorCode;
		});
	}

	/** Закрытие окна создания отдела */
	handleClose(event) {
		event.preventDefault();
		roleState.setDepartment();
	}

	render() {
		return(
			<div className="back">
				<div className={"confirmBlock clearfix window department " + (uistate.IsMobile ? "mobile" : "")}>
					<h5>Добавление нового отдела</h5>
					<form className="buttons clearfix" onSubmit={this.handleSave.bind(this)}>
						<TextInput
							Id="Name"
							Title="Название отдела"
							Required={true}
							Value={roleState.Department.Name}
							onChange={(value,id) => roleState.collectDepartment(id, value)}
						/>
						{roleState.ErrorCode !== "" &&
							<p className='error'>{getErrorText(roleState.ErrorCode)}</p>
						}
						<SubmitButton Loading={roleState.ButtonLoading} Title="Сохранить" />
						<a href="/" role="button" onClick={this.handleClose.bind(this)} className="button gray">Отменить</a>
					</form>
				</div>
			</div>
		)
	}
}
DepartmentBlock = observer(DepartmentBlock);

export default RolesPage;