/**
 * /pages/Filials.js – Работа со настройками филиалов предприятия
 * /stores/filial.js – бизнес логика
 * 
 * Список филиалов доступен всем пользователям
 * Редактирование, добавление и удаление филиала может производить пользователь с правом «ManageFilials»
 * 
 * При загрузке страницы выполняется запрос «Filials» — получение филиалов активного предприятия
 * Также для справочников вызываются следующие методы один раз при загрузке страницы
 *  - «Cities» — список городов
 *  - «GetTZInfo» — список часовых поясов
 * Если предприятие — не колл-центр, загружаются справочники
 *  - «Aggregators» — список доступных агрегаторов по предприятию
 *  - «Brands» — список доступных брендов по предприятию
 *  - «Entities» — список доступных юр лиц по предприятию
 *  - «PaymentMethods» — список доступных способов оплаты по предприятию
 * 
 * Для удаления филиала из предприятия вызывается метод «FilialRemove» с ID филиала
 * 
 * Для получения филиала на редактирования выполняется запрос «Filial» по ID филиала
 * Каждый раз при открытии филиала на редактирование вызывается метод «FiscalCassaList» для получения подключенных фискальных касс
 * Для сохранения изменений по филиалу используется метод «FilialSave» с параметрами
 */

import React, { Component } from 'react';
import { observer } from "mobx-react";
import _ from 'lodash';

import { TextInput, CheckGroup, PeriodTime, Selectize } from '../forms/FormItems';

import { TableFilterPage, ItemHeader } from './PageTemplates';

import { daysLong, buttonTitles, daysShort, POSTypesTitles, CassaTypes, daysWorkLong, timeZonesTitles } from './../dictionary';

import { filialState, stockState } from '../stores/filials';
import { uistate, confirmState, authUserState } from '../stores/common';
import { checkRights, toSelectList, getRequest, toCheckList } from '../functions';

export class FilialsPage extends Component {
	constructor(props) {
		super(props);

		filialState.setDefault();

		this.state = {};

		this.getData();

		getRequest("Cities", {}, (data) => { filialState.Cities = data.Success ? data.Cities : []; });

		if (!authUserState.isCallCentre && authUserState.HasERP) {
			getRequest("Brands", {}, (data) => { filialState.Brands = data.Success ? data.Brands : []; });
			getRequest("Entities", {}, (data) => { filialState.Entities = data.Success ? data.Entities : []; });
			getRequest("Aggregators", {}, (data) => { filialState.Aggregators = data.Success ? data.Aggregators : []; });
			getRequest("PaymentMethods", {}, (data) => { filialState.PaymentMethods = data.Success ? data.PaymentMethods : []; });
		}
	}

	/** API запрос «Filials» на получение списка филиалов */
	getData() {
		filialState.IsSendRequest = false;
		getRequest("Filials", {}, (data) => { filialState.setFilials(data); });
	}

	/**
	 * API запрос «Filial» на получение одного филиала по ID
	 * @param {number} id ID филиала
	 */
	getItem(id) {
		getRequest("Filial", { Id : id }, (data) => {
			filialState.setFilial(data.Success ? data : undefined);
		});
	}

	/**
	 * Выбор/сброс актинвого филиала
	 * @param {number} id ID филиала
	 */
	setId(id) { 
		if (!_.isUndefined(id)){ 
			if (id !== -1) this.getItem(id);
			else filialState.setFilial({ Id : -1 });
		} else {
			filialState.setFilial(undefined);
			this.getData();
		}
	}

	/**
	 * API запрос «FilialRemove» на удаление филиала по ID
	 * @param {number} id ID филиала
	 */
	removeId(id) { 
		if (_.isUndefined(id)) return false;

		var model = this,
			item = _.find(filialState.Filials, { Id : id }),
			text = <span>Вы действительно хотите удалить филиал <b key="b1">«{item ? item.Name : ""}»</b>?</span>;

		confirmState.openConfirm(text, function() {
			getRequest("FilialRemove", { Id : id }, function () { model.getData(); });
		});
	}

	render() {
		var columns = { Name: {}, City: { width: 100 }};

		if (!uistate.IsMobile) columns = _.extend(columns, { Address: {}, Phone: { width: 120 } })
		if (authUserState.HasERP) columns = _.extend(columns, { POSTypes : { filterable : false } })
		if (!uistate.IsMobile) columns = _.extend(columns, { WorkDays: { filterable : false } })
		if (filialState.CanManage) _.extend(columns, { Edit: {}, Remove: {} });

		return (
			<div className="page" id="FilialsPage">
				{_.isUndefined(filialState.Filial) && filialState.IsSendRequest &&
					<TableFilterPage 
						Type={this.props.Type}
						CanManage={filialState.CanManage}

						TableData={filialState.Filials}
						TableColumns={columns}
						TableFilterable={true}

						setId={this.setId.bind(this)}
						removeId={this.removeId.bind(this)}
					/>
				}
				{!_.isUndefined(filialState.Filial) && 
					<FilialItem setId={this.setId.bind(this)} />
				}
			</div>
		)
	}
}
FilialsPage = observer(FilialsPage);

class FilialItem extends Component {
	constructor(props) {
		super(props);

		if (authUserState.HasERP)
			this.getCassaList();
	}

	/** API запрос «FiscalCassaList» на получение списка непривязанных касс */
	getCassaList () {
		getRequest("FiscalCassaList", {}, (data) => {
			filialState.CassaList = data.Success ? _.map(data.FiscalCassaList, function(v) { 
				return { Id : v.Name, Name : v.Name }; }) : [];
		});
	}

	/** API запрос «FilialSave» на редактирование филиала */
	handleSubmit (event) {
		event.preventDefault();

		if (!filialState.validateData())
			return false;

		filialState.ButtonLoading = true;
		// var model = this;

		getRequest("FilialSave", filialState.getSaveData(), (data) => {
			filialState.ErrorCode = data.Success ? "SUCCESS_SAVE" : data.ErrorCode;
			filialState.SuccessSave = data.Success;
			filialState.ButtonLoading = false;

			if (data.Success) {
				filialState.Filial.Id = data.Id;

				if (authUserState.HasERP) this.getCassaList();
			}
		});
	}

	/** Закрытие окна редактирование филиала */
	handleClose (event) {
		event.preventDefault();
		this.props.setId(undefined);
	}

	render() {
		var city = _.find(filialState.Cities, { Id : filialState.Filial.CityId }),
			timezone = !_.isUndefined(city) ? timeZonesTitles[city.GMT] : "",
			timezoneTitle = timezone === "" ? "" : ", часовой пояс: " + timezone;

		// console.log(tz)

		return (
			<section className="clearfix item two-cols" id="FilialItem">
				<form onSubmit={this.handleSubmit.bind(this)} >
					<ItemHeader 
						Title={buttonTitles[(filialState.Filial.Id === -1 ? "New" : "Edit") + "Filial"]}
						Error={filialState.ErrorCode}
						Loading={filialState.ButtonLoading}
						CanManage={filialState.CanManage}
						Success={filialState.SuccessSave}
						handleClose={this.handleClose.bind(this)}
					/>
					<div className="clearfix main backblock">
						<TextInput
							Id="Name"
							Title={"Филиал " + (filialState.Filial.Id !== -1 ? "ID: " + filialState.Filial.Id : "")}
							Value={filialState.Filial.Name}
							Required={true}
							RowClass={uistate.IsMobile ? "" : "col col25"}
							onChange={(value,id) => filialState.collectFilial(id, value)}
						/>
						<Selectize 
							Id="CityId"
							Title={"Город" + timezoneTitle}
							List={toSelectList(filialState.Cities)}
							Value={filialState.Filial.CityId}
							Required={true}
							RowClass={uistate.IsMobile ? "" : "col col25"}
							onChange={(value,id) => filialState.collectFilial(id, value)}
						/>
					</div>
					<div className="clearfix section">
						<div className="mainInfo">
							{/* <div className={uistate.IsMobile ? "col col100" : "col col55"}> */}
							<div className="">
								<div className="clearfix block">
									<TextInput
										Id="Address"
										Title="Адрес"
										Value={filialState.Filial.Address}
										// Required={true}
										RowClass="col col70"
										onChange={(value,id) => filialState.collectFilial(id, value)}
									/>
									<TextInput
										Id="Phone"
										Title="Телефон"
										Value={filialState.Filial.Phone}
										Mask="7 (999) 999-99-99"
										RowClass="col col70"
										onChange={(value,id) => filialState.collectFilial(id, value)}
									/>
								</div>
								{!authUserState.isCallCentre && authUserState.HasERP && <StoreBlock />}
							</div>
							<WorkDaysBlock />
						</div>
						{!authUserState.isCallCentre && authUserState.HasERP && <PosBlock />}
					</div>
				</form>
			</section>
		)
	}
}
FilialItem = observer(FilialItem);

class WorkDaysBlock extends Component {
	/** Установка пресетов рабочих дней */
	setWorkDays(event) {
		event.preventDefault()
		var rel = event.currentTarget.getAttribute("rel");

		if (rel === "all")
			_.each(daysLong, function(v,i) { filialState.collectWorkDays("Day", true, i); });
		
		if (rel === "work") {
			_.each(daysLong, function(v,i) { filialState.collectWorkDays("Day", false, i); });
			_.each(daysWorkLong, function(v,i) { filialState.collectWorkDays("Day", true, i); });
		}
	}

	render() {
		return(
			<div className='gray workdays'>
				<h4>Время работы филиала</h4>
				<p className='comment'>Для работы со сменами необходимо настроить часы работы филиала. Если у персонала есть задачи, после официального закрытия филиала настройте поле «Сдвиг смены»</p>
				<TextInput
					Id="ShiftHour"
					Type="number"
					Title="Сдвиг смены, часы"
					Value={filialState.Filial.ShiftHour}
					RowClass="shift"
					onChange={(value,id) => filialState.collectFilial(id, value)}
				/>
				<div className='head'>
					<h5>Время работы</h5>
					<a href='/' rel="all" className='dotted' onClick={this.setWorkDays.bind(this)}>Ежедневно</a>
					<a href='/' rel="work" className='dotted' onClick={this.setWorkDays.bind(this)}>ПН-ПТ</a>
				</div>
				{filialState.WorkDays.map(function (v,i) {
					return(<WorkDayItem key={"d" + i} Index={i} Item={v} />) 
				}) }
			</div>
		)
	}
}
WorkDaysBlock = observer(WorkDaysBlock)

class WorkDayItem extends Component {
	/** Установка пресетов часов работы */
	setWorkHours (event) {
		event.preventDefault();
		var time = {
				StartTime : filialState.WorkDays[0].StartTime,
				EndTime :  filialState.WorkDays[0].EndTime
			},
			days = _.map(_.filter(filialState.WorkDays, (v) => { return v.Day !== -1; }), "Day");

		_.each(days, function(v) { filialState.collectWorkDays("Time", time, v); });
	}

	render() {
		var item = this.props.Item;

		return(
			<div className="day">
				<CheckGroup
					Id="Day"
					Type="checkbox"
					List={[{ Id : this.props.Index, Title : daysLong[this.props.Index] }]}
					Value={item.Day !== -1 && item.Day !== "" ? [this.props.Index] : []}
					onChange={(value, id) => filialState.collectWorkDays(id, value, this.props.Index)}
				/>
				<PeriodTime 
					Id="Time"
					Index={this.props.Index}
					Value={{ StartTime : item.StartTime, EndTime : item.EndTime }}
					Required={item.Day !== -1 && item.Day !== ""}
					onChange={(value, id) => filialState.collectWorkDays(id, value, this.props.Index)}
				/>
				{this.props.Index === 0 &&
					<a href='/' className='dotted' onClick={this.setWorkHours.bind(this)}>Применить ко всем</a>
				}
			</div>
		)
	}
}
WorkDayItem = observer(WorkDayItem);

class StoreBlock extends Component {
	/** Добавление склада */
	handlerAdd (event) { 
		event.preventDefault();
		filialState.Stock = _.concat(filialState.Stock, _.clone(filialState.StockDummy));
	}

	render() {
		return(
			<div className="clearfix block">
				<h4>{buttonTitles.Stores}</h4>
				<div className="clearfix">
					{_.map(filialState.Stock, function (v,i) {
						return (<StoreItem Item={v} Index={i} key={filialState.PSCounter++} />)
					}) }
				</div>
				<a className="button add" onClick={this.handlerAdd.bind(this)} href="/">
					<i></i>{buttonTitles.AddStore}</a>
			</div>
		)
	}
}
StoreBlock = observer(StoreBlock);

class StoreItem extends Component {
	/** Удаление склада */
	handleDelete (event) { 
		event.preventDefault();
		
		var index = this.props.Index;
		filialState.Stock = _.filter(filialState.Stock, function (v,i) { return i !== index; });
	}

	/**
	 * Сбор данных с формы редактирования склада
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange (value, id) { 
		if (/Menu/.test(id)) filialState.collectMenu(id, value, this.props.Item.Id);
		else filialState.collectStock(id, value, this.props.Index); 
	}

	render() {
		var stockList = [{ Id : "Kitchen", Title : "Кухня"}, { Id : "Bar", Title : "Бар"}],
			menu = [];

		if (filialState.Filial.Kitchen === this.props.Item.Id) menu = _.concat(menu, "Kitchen");
		if (filialState.Filial.Bar === this.props.Item.Id) menu = _.concat(menu, "Bar");

		return(
			<div className="pos col col50">
				<TextInput
					Id="Name"
					Title="Название"
					Value={this.props.Item.Name}
					Required={true}
					onChange={this.handleChange.bind(this)}
				/>
				<CheckGroup
					Id="Menu"
					Type="checkbox"
					RowClass="nomargin"
					List={stockList}
					Value={menu}
					onChange={this.handleChange.bind(this)}
				/>
				<a className="button remove" href="/" role="button" title={buttonTitles.RemoveStore}
					onClick={this.handleDelete.bind(this)}><i></i></a>
			</div>
		)
	}
}
StoreItem = observer(StoreItem);

class PosBlock extends Component {
	/** Добавление точки продаж */
	handlerAdd (event) { 
		event.preventDefault();
		filialState.POS = _.concat(filialState.POS, _.extend(_.clone(filialState.POSDummy), {
			Types : _.map(POSTypesTitles, function (type) {
				return _.extend(_.clone(filialState.POSTypeDummy), { Type : type.Id });
			})
		}));
	}

	render() {
		return(
			<div className="clearfix poses block">
				<h4>{buttonTitles.POSes}</h4>
				<div className="clearfix">
					{_.map(filialState.POS, function (v,i) {
						return (<PosItem Item={v} Index={i} key={filialState.PSCounter++} />)
					}) }
				</div>
				<a className="button add" onClick={this.handlerAdd.bind(this)} href="/">
					<i></i>{buttonTitles.AddPOS}</a>
			</div>
		)
	}
}
PosBlock = observer(PosBlock)

class PosItem extends Component {
	/** Удаление точки продаж */
	handleDelete (event) { 
		event.preventDefault();
		
		var index = this.props.Index;
		filialState.POS = _.filter(filialState.POS, function (v,i) { return i !== index; });
	}

	/**
	 * Сбор данных с формы редактирования точки продаж
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	onChange (value, id) { filialState.collectPOS(id, value, this.props.Index); }

	render() {
		var view = this,
			cassaName = this.props.Item.FiscalCassaName,
			cassaList = cassaName === "" ? filialState.CassaList :
				_.concat(_.clone(filialState.CassaList), { Id : cassaName, Name : cassaName });

		return(
			<div className={"clearfix pos " + (uistate.IsMobile ? "mobile" : "")}>
				<div className={uistate.IsMobile ? "col col100" : "col col40"}>
					<div className="clearfix block">
						<TextInput
							Id="Name"
							Title={"Название " + (this.props.Item.Id !== -1 ? "ID: " + this.props.Item.Id : "")}
							Value={this.props.Item.Name}
							Required={true}
							onChange={this.onChange.bind(this)}
						/>
						<Selectize
							Id="EntityId"
							Title="Юр. лицо"
							List={toSelectList(filialState.Entities)}
							Value={this.props.Item.EntityId}
							isClearable={false}
							onChange={this.onChange.bind(this)}
						/>
						<CheckGroup
							Id="Main"
							Type="checkbox"
							List={[{ Id : "true", Title : "Основная" }]}
							Value={this.props.Index === filialState.Filial.MainPOS ? ["true"] : []}
							RowClass="nomargin"
							onChange={this.onChange.bind(this)}
						/>
					</div>
					<div className="clearfix block">
						<CheckGroup
							Id="HasCassa"
							Type="checkbox"
							List={[{ Id : "true", Title : "Отдельная касса" }]}
							Value={this.props.Item.HasCassa ? ["true"] : []}
							RowClass="col col50 nomargin"
							onChange={this.onChange.bind(this)}
						/>
						{this.props.Item.HasCassa &&
							<CheckGroup
								Id="Fiscal"
								Type="checkbox"
								List={[{ Id : "true", Title : "Фискальная касса" }]}
								Value={this.props.Item.Fiscal ? ["true"] : []}
								RowClass="col col50 nomargin"
								onChange={this.onChange.bind(this)}
							/>
						}
						{this.props.Item.HasCassa && this.props.Item.Fiscal &&
							<Selectize
								Id="CassaType"
								Title="Тип кассы"
								List={toSelectList(CassaTypes)}
								Value={this.props.Item.CassaType}
								RowClass="col col50"
								onChange={this.onChange.bind(this)}
							/>
						}
						{this.props.Item.HasCassa && this.props.Item.Fiscal &&
							<Selectize
								Id="FiscalCassaName"
								Title="Ключ фискальной кассы"
								List={toSelectList(cassaList)}
								Value={this.props.Item.FiscalCassaName}
								RowClass="col col50"
								onChange={this.onChange.bind(this)}
							/>
						}
					</div>
					<div className="clearfix block">
						<CheckGroup
							Id="Brands"
							Title="Бренды"
							Type="checkbox"
							List={toCheckList(filialState.Brands)}
							Value={this.props.Item.Brands}
							RowClass="col col1_3"
							onChange={this.onChange.bind(this)}
						/>
						<CheckGroup
							Id="Aggregators"
							Title="Агрегаторы"
							Type="checkbox"
							List={toCheckList(filialState.Aggregators)}
							Value={this.props.Item.Aggregators}
							RowClass="col col1_3"
							onChange={this.onChange.bind(this)}
						/>
						<CheckGroup
							Id="PaymentMethods"
							Title="Способы оплаты"
							Type="checkbox"
							List={toCheckList(filialState.PaymentMethods)}
							Value={this.props.Item.PaymentMethods}
							RowClass="col col1_3"
							onChange={this.onChange.bind(this)}
						/>
					</div>
				</div>
				<div className={uistate.IsMobile ? "col col100" : "col col60"}>
					{_.map(this.props.Item.Types, function (v, i) {
						return(<PosTypeItem key={i} Item={v} Index={view.props.Index} />)
					})}
				</div>
				<a className="button remove" href="/" role="button" title={buttonTitles.RemovePOS}
					onClick={this.handleDelete.bind(this)}><i></i></a>
			</div>
		)
	}
}
PosItem = observer(PosItem);

class PosTypeItem extends Component {
	/**
	 * Сбор данных с формы редактирования типа точки продаж
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	onChange (value, id) { filialState.collectPOS(id, value, this.props.Index, this.props.Item.Type); }

	render() {
		var type = _.find(POSTypesTitles, { Id : this.props.Item.Type });

		return(
			<div className="col col1_4">
				<CheckGroup
					Id="PT_Type"
					Type="checkbox"
					List={toCheckList([type])}
					Value={this.props.Item.Active ? [type.Id] : []}
					onChange={this.onChange.bind(this)}
				/>
				<TextInput
					Id="PT_FirstTime"
					Title="Со скольких можно оформлять текущий заказ"
					Type="number"
					Value={this.props.Item.FirstTime}
					Disabled={!this.props.Item.Active}
					Required={this.props.Item.Active}
					onChange={this.onChange.bind(this)}
				/>
				<TextInput
					Id="PT_FirstToTime"
					Title="Ко скольким можно начать оформлять заказ ко времени"
					Type="number"
					Value={this.props.Item.FirstToTime}
					Disabled={!this.props.Item.Active}
					Required={this.props.Item.Active}
					onChange={this.onChange.bind(this)}
				/>
				<TextInput
					Id="PT_LastTime"
					Title="До скольких можно оформлять текущий заказ"
					Type="number"
					Value={this.props.Item.LastTime}
					Disabled={!this.props.Item.Active}
					Required={this.props.Item.Active}
					onChange={this.onChange.bind(this)}
				/>
				<TextInput
					Id="PT_LastToTime"
					Title="До скольких можно забрать/доставить заказ ко времени"
					Type="number"
					Value={this.props.Item.LastToTime}
					Disabled={!this.props.Item.Active}
					Required={this.props.Item.Active}
					onChange={this.onChange.bind(this)}
				/>
			</div>
		)
	}
}
PosTypeItem = observer(PosTypeItem);

export class StocksPage extends Component {
	constructor(props) {
		super(props);

		stockState.setStock(undefined);

		this.state = {};

		this.getData = this.getData.bind(this);
		this.getData();
		
		var model = this;
		getRequest("Products", { Active : true }, function (data) {
			var products = data.Success ? _.filter(data.Products, function (v) { return v.Type !== 3; }) : [];
			model.setState({ 
				Products : products,
				Sections : _.uniqBy(_.map(products, function(v) { 
					return { Id : v.SectionId, Name : v.Section, Type : v.Type }}), "Id")
			});
		});
	}

	/** API запрос на получение списка всех складов предприятия */
	getData() {
		stockState.IsSendRequest = false;
		getRequest("Stocks", {}, function(data) { stockState.setStocks(data); });
	}

	/**
	 * API запрос на получение одного склада по ID
	 * @param {number} id ID склада
	 */
	getItem(id) {
		getRequest("Stock", { Id : id }, function(data) { stockState.setStock(data.Success ? data : undefined); });
	}

	/**
	 * Выбор/сброс активного склада
	 * @param {number} id ID склада или undefined (сброс)
	 */
	setId(id) { 
		if (!_.isUndefined(id) && id !== -1) this.getItem(id);
		else {
			stockState.setStock(undefined);
			this.getData();
		}
	}

	render() {
		var columns = { Name: {}, Filial: {}, City: {} };
		if (checkRights("ManageStocks")) _.extend(columns, { Edit: {} });

		return (
			<div className="page" id="StocksPage">
				{_.isUndefined(stockState.Stock) && stockState.IsSendRequest &&
					<TableFilterPage 
						Type={this.props.Type}
						CanManage={checkRights("ManageStocks")}
						HideAddButton={true}

						TableData={stockState.Stocks}
						TableColumns={columns}
						TableFilterable={true}

						setId={this.setId.bind(this)}
					/>
				}
				{!_.isUndefined(stockState.Stock) && 
					<StockItem 
						Sections={this.state.Sections}
						Products={this.state.Products}
						setId={this.setId.bind(this)}  
					/>
				}
			</div>
		)
	}
}
StocksPage = observer(StocksPage);

class StockItem extends Component {
	/** API запрос на редактирование настроек склада */
	handleSubmit (event) {
		event.preventDefault();
		
		stockState.ButtonLoading = true;

		getRequest("SaveStock", stockState.getSaveData(), function (data) {
			stockState.ErrorCode = data.Success ? "SUCCESS_SAVE" : data.ErrorCode;
			stockState.SuccessSave = data.Success;
			stockState.ButtonLoading = false;
		});
	}

	/** Закрытие окна редактирования */
	handleClose (event) {
		event.preventDefault();
		this.props.setId(undefined);
	}

	render() {
		return (
			<section className="clearfix item" id="StockItem">
				<form onSubmit={this.handleSubmit.bind(this)} >
					<ItemHeader 
						Title={buttonTitles["EditStock"]}
						Error={stockState.ErrorCode}
						Loading={stockState.ButtonLoading}
						CanManage={checkRights("ManageStocks")}
						Success={stockState.SuccessSave}
						handleClose={this.handleClose.bind(this)}
					/>
					<div className="clearfix main backblock">
						<div className="col col25">
							<TextInput
								Id="Name"
								Title="Склад"
								Value={stockState.Stock.Name}
								Disabled={true}
							/>
						</div>
						<div className="col col25">
							<TextInput
								Id="Filial"
								Title="Филиал"
								Value={stockState.Stock.Filial}
								Disabled={true}
							/>
						</div>
						<div className="col col25">
							<TextInput
								Id="City"
								Title="Город"
								Value={stockState.Stock.City}
								Disabled={true}
							/>
						</div>
					</div>
					<div className="clearfix section" >
						<StockProductsBlock 
							Type="Products"
							key="Products"
							Products={this.props.Products} 
							Sections={this.props.Sections} 
						/>
						<StockProductsBlock 
							Type="Semimanufactures"
							key="Semimanufactures"
							Products={_.filter(this.props.Products, { Type : 2 })} 
							Sections={_.filter(this.props.Sections, { Type : 2 })} 
						/>
					</div>
				</form>
			</section>
		)
	}
}
StockItem = observer(StockItem);

class StockProductsBlock extends Component {
	constructor(props) {
		super(props);

		this.state = { isOpen : true };
	}

	/** Показать/скрыть блок настроек */
	handleToggle (event) {
		event.preventDefault();
		this.setState({ isOpen : !this.state.isOpen });
	}

	/** Добавление раздела ПФ для заготовок */
	handleAdd (event) {
		event.preventDefault();
		stockState.Semimanufactures = _.concat(stockState.Semimanufactures, {
			SectionId : -1, Group : [_.clone(stockState.SemiDummy)] });
	}

	render() {
		var view = this;

		if (this.props.Type === "Products")
			return(
				<div className="clearfix block products">
					<h4>Конфигурация склада
						<a href="/" className={"toggle " + (this.state.isOpen ? "open" : "close")}
							onClick={this.handleToggle.bind(this)}>
								<i></i>{this.state.isOpen ? "Свернуть" : "Развернуть"}</a>
					</h4>
					{this.state.isOpen && _.map(stockState.Products, function (products, type) {
						return(<StockProductType
							key={"t" + type}
							ProductType={type}
							Sections={_.filter(view.props.Sections, { Type : parseInt(type, 10) })}
							Products={_.filter(view.props.Products, { Type : parseInt(type, 10) })}
							ProductsGroup={products}
						/>)
					})}
				</div>
			)
		else 
			return(
				<div className="clearfix block semimanufactures">
					<h4>Ежедневные заготовки
						<a href="/" className={"toggle " + (this.state.isOpen ? "open" : "close")}
							onClick={this.handleToggle.bind(this)}>
								<i></i>{this.state.isOpen ? "Свернуть" : "Развернуть"}</a>
					</h4>
					{this.state.isOpen &&
						<div className="clearfix">
							{_.map(stockState.Semimanufactures, function (group,i) {
								return(<StockProductsGroup key={"sg" + i}
									Type={view.props.Type}
									SectionId={group.SectionId}
									Sections={view.props.Sections}
									Products={view.props.Products}
									Index={i}
									Group={group.Group}
								/>)
							})}
							{checkRights("ManageStocks") &&
								<a href="/" className="icon add button" onClick={this.handleAdd.bind(this)}>
									<i></i>Добавить раздел</a>
							}
						</div>
					}
				</div>
			)
	}
}
StockProductsBlock = observer(StockProductsBlock);

class StockProductType extends Component {
	/** Добавление раздела продуктов (сырье или ПФ) для настроек конфигурации склада */
	handleAdd (event) {
		event.preventDefault();
		stockState.Products[this.props.ProductType] = _.concat(stockState.Products[this.props.ProductType], 
			_.extend(_.clone(stockState.SectionDummy), { Group : [_.clone(stockState.ProductDummy)]}));
	}

	render() {
		var view = this;

		return(
			<div className="clearfix block">
				<h4>{this.props.ProductType === "1" ? "Сырье" : "Полуфабрикаты"}</h4>
				{_.map(this.props.ProductsGroup, function (group,i) {
					return(<StockProductsGroup key={"pg" + i}
						Type="Products"
						SectionId={group.SectionId}
						ProductType={view.props.ProductType}
						Sections={view.props.Sections}
						Products={view.props.Products}
						Index={i}
						Group={group.Group}
					/>)
				})}
				{checkRights("ManageStocks") &&
					<a href="/" className="icon add button" onClick={this.handleAdd.bind(this)}>
						<i></i>Добавить раздел</a>
				}
			</div>
		)
	}
}
StockProductType = observer(StockProductType);

class StockProductsGroup extends Component {
	/** Удаление блока раздела продуктов */
	handleDelete (event) {
		event.preventDefault();

		var index = this.props.Index;
		if (this.props.Type === "Products")
			stockState.Products[this.props.ProductType] = _.filter(stockState.Products[this.props.ProductType], 
				function (v,i) { return i !== index; });
		else
			stockState.Semimanufactures = _.filter(stockState.Semimanufactures, function (v,i) { 
				return i !== index; });
	}

	/**
	 * Сбор данных с формы редактирования склада
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange (value, id) {
		if (this.props.Type === "Products")
			stockState.collectProducts(id, value, undefined, this.props.Index, this.props.ProductType);
		else
			stockState.collectSemi(id, value, undefined, this.props.Index);
	}

	/** Добавление продукта в список */
	handleAdd (event) {
		event.preventDefault();
		
		var index = this.props.Index;
		if (this.props.Type === "Products")
			stockState.Products[this.props.ProductType][index].Group = 
				_.concat(stockState.Products[this.props.ProductType][index].Group, _.clone(stockState.ProductDummy));
		else
			stockState.Semimanufactures[index].Group = _.concat(stockState.Semimanufactures[index].Group, 
				_.clone(stockState.SemiDummy))
	}

	render() {
		var view = this,
			products = _.filter(this.props.Products, { SectionId : this.props.SectionId });

		return(
			<div className="clearfix group">
				<div className="clearfix title">
					<Selectize
						Id="SectionId"
						Title="Раздел"
						List={toSelectList(this.props.Sections)}
						Value={this.props.SectionId}
						RowClass="col col40"
						onChange={this.handleChange.bind(this)}
					/>
					{checkRights("CanManage") &&
						<a href="/" className="icon remove button" onClick={this.handleDelete.bind(this)}>
							<i></i>Удалить раздел</a>
					}
				</div>
				<div className="clearfix">
					{_.map(this.props.Group, function (item, i) {
						if (view.props.Type === "Products")
							return(<StockConfigItem key={"pgs" + i} Item={item} Index={i}
								GroupIndex={view.props.Index} Products={products} 
								ProductType={view.props.ProductType}/>)
						else
							return(<SemimanufacturesItem key={"sgs" + stockState.sCounter++} Item={item} Index={i}
								GroupIndex={view.props.Index} Products={products}/>)
					})}
					{checkRights("ManageStocks") &&
						<a href="/" className="icon add button" onClick={this.handleAdd.bind(this)}>
							<i></i>{"Добавить " + (this.props.ProductType === "1" ? "сырье" : "полуфабрикат")}</a>
					}
				</div>
			</div>
		)
	}
}
StockProductsGroup = observer(StockProductsGroup);

class StockConfigItem extends Component {
	/**
	 * Сбор данных с формы редактирования конфигурации склада
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange (value, id) { 
		var unit = "";
		if (/ProductId/.test(id)) {
			var product = _.find(this.props.Products, { Id : value });
			unit = product ? product.UnitNet : "";
		}

		stockState.collectProducts(id, value, this.props.Index, this.props.GroupIndex, 
			this.props.ProductType, unit); 
	}

	/** Удаление продукта из списка */
	handleDelete (event) {
		event.preventDefault();

		var index = this.props.Index,
			group = this.props.GroupIndex
		stockState.Products[this.props.ProductType][group].Group = 
			_.filter(stockState.Products[this.props.ProductType][group].Group, 
				function (v,i) { return i !== index; });
	}

	render() {
		return(
			<div className="clearfix item">
				<Selectize
					Id="ProductId"
					Title={this.props.ProductType === "1" ? "Сырье" : "Полуфабрикат"}
					Value={this.props.Item.ProductId}
					List={toSelectList(this.props.Products)}
					Disabled={!checkRights("ManageStocks")}
					RowClass="col col40"
					onChange={this.handleChange.bind(this)}
				/>
				<TextInput
					Id="UnitName"
					Title="ЕИ"
					Value={this.props.Item.UnitName}
					RowClass="col col5"
					Disabled={true}
				/>
				<TextInput
					Id="Count"
					Value={this.props.Item.Count}
					Type="number"
					Step="0.01"
					Title="Мин. кол-во"
					RowClass="col col10"
					Disabled={!checkRights("ManageStocks")}
					Required={true}
					onChange={this.handleChange.bind(this)}
				/>
				<CheckGroup
					Id="Revision"
					Type="checkbox"
					List={[{ Id : "true", Title : "Участвует в ревизии"}]}
					Value={this.props.Item.Revision ? ["true"] : []}
					RowClass="col col15"
					Disabled={!checkRights("ManageStocks")}
					onChange={this.handleChange.bind(this)}
				/>
				{this.props.ProductType === "2" &&
					<CheckGroup
						Id="NotRelease"
						Type="checkbox"
						List={[{ Id : "true", Title : "Не производится на складе"}]}
						Value={this.props.Item.NotRelease ? ["true"] : []}
						RowClass="col col25"
						Disabled={!checkRights("ManageStocks")}
						onChange={this.handleChange.bind(this)}
					/>
				}
				{checkRights("ManageStocks") &&
					<a href="/" className="button icon remove" onClick={this.handleDelete.bind(this)}><i></i></a>
				}
			</div>
		)
	}
}
StockConfigItem = observer(StockConfigItem);

class SemimanufacturesItem extends Component {
	/**
	 * Сбор данных с формы редактирования заготовок склада
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange (value, id) { 
		var product = {}
		if (/ProductId/.test(id)) product = _.find(this.props.Products, { Id : value });

		stockState.collectSemi(id, value, this.props.Index, this.props.GroupIndex, product); 
	}

	/** Удаление продукта из списка */
	handleDelete (event) {
		event.preventDefault();
		var index = this.props.Index,
			group = this.props.GroupIndex;

		stockState.Semimanufactures[group].Group = _.filter(stockState.Semimanufactures[group].Group, 
			function (v,i) { return i !== index; });
	}

	render() {
		var days = _.map(daysShort, function (v, i) { return { Id : i, Name : v }});
		
		return(
			<div className="clearfix item">
				<Selectize
					Id="ProductId"
					Title="Полуфабрикат"
					List={toSelectList(this.props.Products)}
					Value={this.props.Item.ProductId}
					RowClass="col col25"
					onChange={this.handleChange.bind(this)}
				/>
				<TextInput
					Id="Count"
					Title="Кол-во"
					Value={this.props.Item.Count}
					RowClass="col col10"
					Required={true}
					onChange={this.handleChange.bind(this)}
				/>
				<TextInput
					Id="UnitName"
					Title="ЕИ"
					Value={this.props.Item.UnitName}
					RowClass="col col5"
					Disabled={true}
				/>
				<PeriodTime
					Id="Time"
					Title="Время выполнения"
					RowClass="col col25"
					Value={{ StartTime : this.props.Item.StartTime, EndTime : this.props.Item.EndTime }}
					onChange={this.handleChange.bind(this)}
				/>
				<CheckGroup
					Id="Day"
					Title=""
					Type="checkbox"
					RowClass="col col35 days"
					List={toCheckList(days)}
					Value={this.props.Item.Day}
					onChange={this.handleChange.bind(this)}
				/>
				{checkRights("ManageStocks") && <a href="/" className="icon remove button" 
					onClick={this.handleDelete.bind(this)}><i></i></a>
				}
			</div>
		)
	}
}
SemimanufacturesItem = observer(SemimanufacturesItem);