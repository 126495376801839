import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import '../styles/products.css';

import { TextInput, Selectize, CheckGroup, ColorPreset, DropButton, FileInput, PeriodGroup } from '../forms/FormItems';
import { ItemHeader } from './PageTemplates';
import { DataTable } from '../tables/Tables';

import { buttonTitles, dashboardsTitles, VATTitles, errors, MenuTitles, POSTypesTitles } from '../dictionary';
import { checkRights, toSelectList, getRubs, getRequest, toCheckList, setUnit, formatDate, 
	getUpload } from '../functions';

import { uistate, confirmState, authUserState } from '../stores/common';
import { productState } from '../stores/products';
import { menuState } from '../stores/productionMenu';

import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';

const DragHandle = SortableHandle(() => <i className="SortHandle"></i>);

export class Raw extends Component {
	render() { return (<ProductPage Type={this.props.Type} ProductType={1} />) }
}
export class SemiManufactures extends Component {
	render() { return (<ProductPage Type={this.props.Type} ProductType={2} />) }
}
export class Products extends Component {
	render() { return (<ProductPage Type={this.props.Type} ProductType={3} />) }
}
class ProductPage extends Component {
	constructor(props) {
		super(props);

		productState.setDefault(this.props.ProductType);

		this.state = {};

		this.getSections();
		this.getData();
		
		var model = this;
		getRequest("Brands", {}, function (data) { model.setModel(data, "Brands"); });
		getRequest("Tags", {}, function (data) { model.setModel(data, "Tags"); });
		getRequest("WorkShops", {}, function (data) { model.setModel(data, "WorkShops"); });
	}

	/**
	 * Разбор данных в модель
	 * @param {object} data ответ от сервера
	 * @param {string} param название параметра
	 */
	setModel (data, param) { this.setState({ [param] : data.Success ? data[param] : [] }); }

	/** API запрос на получение списка разделов от сервера */
	getSections() {
		productState.setSection(undefined); 
		getRequest("Sections", { Type : productState.PageType }, function (data) {
			productState.setSections(data.Success && !_.isNull(data.Sections) ? data.Sections : []);
		});
	}

	/** API запрос «Products» на получение списка продуктов от сервера */
	getData() {
		var model = this;

		productState.IsSendRequest = false;
		getRequest("Products", { Active : true }, function(data) {
			productState.setAllProducts(data.Success ? data.Products : [], "active");
			model.getRemovedProducts();
		});
	}

	/** API запрос «Products» на получение удаленных списка продуктов от сервера */
	getRemovedProducts() {
		getRequest("Products", { Active : false }, function(data) {
			productState.setAllProducts(data.Success ? data.Products : [], "removed");
		});
	}

	/** API запрос на получение продукта от сервера 
	 * @param {number} id ID продукта 
	 * @param {string} operationType тип операции — создание или копирование
	 */
	getItem(id, operationType) {
		var model = this;
		getRequest(this.props.Type === "Raw" ? "Raw" : "Product", { Id : id }, function (data) {
			if (model.props.Type === "Raw") productState.setRaw(data.Success ? data : undefined, operationType);
			else productState.setProduct(data.Success ? data : undefined, operationType);
		});
	}

	/** Выбор/сброс активного продукта
	 * @param {number} id ID продукта 
	 * @param {number} type тип продукта
	 */
	setId(id, type) {
		if (_.isUndefined(id)) {
			productState.setRaw(undefined);
			productState.setProduct(undefined);
			
			this.getData();
		} else if (id !== -1) this.getItem(id, type);
		else if (id === -1) {
			if (this.props.Type === "Raw") productState.setRaw({ Id : -1 });
			else productState.setProduct({ Id : -1 });
		}
	}

	/** API запрос на удаление продукта от сервера 
	 * @param {number} id ID продукта 
	 */
	removeId(id) {
		if (_.isUndefined(id)) return false;

		var model = this,
			item = _.find(productState.SectionProducts, { Id: id }),
			text = <span>Вы действительно хотите {productState.IsActive ? "удалить" : "восстановить"}
				&nbsp;продукт <b key="b1">«{item ? item.Name : ""}»</b>?</span>;

		confirmState.openConfirm(text, function() {
			getRequest("RemoveProduct", { Id : id }, function() { model.getData(); });
		});
	}

	render() {
		var showAdd = !_.isEmpty(_.filter(productState.Sections, { System : false })) && productState.IsActive 
			&& _.isUndefined(productState.EditSection);

		return (
			<div className="page" id="ProductPage">
				{_.isUndefined(productState.Product) && 
					<div className="head clearfix">
						<h2>{dashboardsTitles[this.props.Type] || this.props.Type}</h2>
						{checkRights("ManageProducts") && showAdd && 
							<button className="add" onClick={(e) => { this.setId(-1) }}>
								{buttonTitles[this.props.Type] || "Добавить"}</button>
						}
					</div>
				}
				{_.isUndefined(productState.Product) && 
					<ProductTable
						Products={productState.SectionProducts}
						Type={this.props.Type}
						Brands={this.state.Brands}
						WorkShops={this.state.WorkShops}

						setId={this.setId.bind(this)}
						removeId={this.removeId.bind(this)}
						getData={this.getData.bind(this)}
						getSections={this.getSections.bind(this)}
					/>
				}
				{!_.isUndefined(productState.Product) && this.props.Type === "Raw" &&
					<RawItem setId={this.setId.bind(this)} />
				}
				{!_.isUndefined(productState.Product) && this.props.Type !== "Raw" &&
					<ProductItem
						Type={this.props.Type}
						Tags={this.state.Tags}
						WorkShops={this.state.WorkShops}

						setId={this.setId.bind(this)}
						getItem={this.getItem.bind(this)}
					/>
				}
			</div>
		)
	}
}
ProductPage = observer(ProductPage);

class ProductTable extends Component {
	render() {
		// TODO Перенести разбор данных для талицы на сторону store
		var isRaw = productState.PageType === 1,
			isGood = productState.PageType === 3,
			workshops = this.props.WorkShops,
			columns = { Name : {}, Description : {}, UnitNet : { filterable : false } };

		if (isGood) 
			columns = {
				Name : {}, Color : {}, VendorCode : { width: 80 }, 
				WorkShops : { width: 70,
					Cell : function(row) {
						if (_.isNull(row.original.WorkShops)) return "";
						return _.map(row.original.WorkShops, function (v) {
							var ws = _.find(workshops, { Id : v });

							return ws ? <i className={"icon ws ws_" + ws.Icon} title={v.Name}></i> : "";
						})
					}
				},
				Description : {}, UnitNet : { filterable : false },
				POSTypes : {
					Header: "ТП", width : 80, filterable : false, 
					Cell : row => _.isNull(row.original.POSTypes) ? "" :
						_.map(row.original.POSTypes, function(v) { 
							var type = _.find(POSTypesTitles, { Id : v });
							return type ? type.ShortName : v; 
						}).join("/")
				}
			}
			
		if (isRaw) _.extend(columns, { 
			UnitNet : { Header : "ЕИ нетто", filterable : false }, UnitGross : {filterable : false } });

		if (checkRights("ManageProductsFinance")) {
			columns = _.extend(columns, { Cost : { Header : "Себес", width: 75,
				Cell : row => _.find(row.original.Prices, { CityId : authUserState.City }) ? 
					getRubs(_.find(row.original.Prices, { CityId : authUserState.City }).Cost, true, true) : ""
			}});
			if (isGood) _.extend(columns, { 
				Price : {
					width: 75,
					Cell : row => _.find(row.original.Prices, { CityId : authUserState.City }) ? 
						getRubs(_.find(row.original.Prices, { CityId : authUserState.City }).Price, true, true) : ""
				} });
		}
		
		if (checkRights("ManageProducts")) _.extend(columns, { Edit : {} });
		else _.extend(columns, { View : {} });

		if (productState.IsActive && checkRights("ManageProducts")) 
			_.extend(columns, { Copy : {}, Remove : {} });

		return (
			<div className="clearfix section">
				<SectionMenu getData={this.props.getData} getSections={this.props.getSections}
					Brands={this.props.Brands} Items={productState.Sections} />
				<div className="col col80 products">
					{!_.isEmpty(this.props.Products) && productState.IsSendRequest &&
						<DataTable
							Data={this.props.Products}
							Columns={columns}
							Type={this.props.Type}
							// PageHeight={tableHeight}
							IsFilterable={true}
							Sorted={[{ id: "Name", desc: false }]}
							
							editRow={(id, type) => this.props.setId(id, type)}
							removeRow={(id) => this.props.removeId(id)}

							getTrProps={(state, rowInfo, column) => {
								return { className: !productState.IsActive ? "notactive" : "" };
							}}
						/>
					}
					{_.isEmpty(this.props.Products) && productState.IsSendRequest &&
						<h4>{errors.EMPTY_RESPONSE}</h4>
					}
				</div>
			</div>
		)
	}
}
ProductTable = observer(ProductTable);

export class SectionMenu extends Component {
	/** Выбор активного раздела */
	changeSection(event) {
		event.preventDefault();

		if (/active/.test(event.currentTarget.className)) return false;
		else if (/removed/.test(event.currentTarget.className)) {
			productState.CurrentSection = parseInt(event.currentTarget.getAttribute("rel"), 10);
			productState.IsActive = false;
		} else {
			var id = parseInt(event.currentTarget.getAttribute("rel"), 10);
			
			if (this.props.ForMenu) {
				if (this.props.IsProduct) {
					// menuState.CurrentProduct = id;
					if (this.props.getData) this.props.getData(id);
				} else {
					menuState.CurrentSection = id;
					menuState.setProduct(undefined);
				}
			} else {
				productState.CurrentSection = id;
				productState.IsActive = true;
			}
		}
	}

	/** Добавление нового раздела */
	addSection (event) {
		event.preventDefault();
		productState.Sections = _.concat(productState.Sections, _.clone(productState.SectionDummy));
		productState.setSection(_.last(productState.Sections));
	}

	/** Изменение порядка разделов */
	onSortEnd = ({oldIndex, newIndex}) => {
		arrayMove.mutate(productState.Sections, oldIndex, newIndex);
		this.changeOrder();
	};

	/** API запрос на изменение порядка разделов */
	changeOrder() {
		var model = this;
		getRequest("ChangeSectionsOrder", productState.getSectionsOrder(), function() { model.props.getSections(); });
	}

	render() {
		var view = this,
			style = { height : this.props.ForMenu ? uistate.sectionHeight : "100%" };

		var SortableItem = SortableElement(({value, sortIndex}) => {
			return(
				<SectionMenuItem 
					Item={value} 
					Index={sortIndex}
					ForMenu={view.props.ForMenu} 
					IsProduct={view.props.IsProduct}
					Brands={view.props.Brands}
					getSections={view.props.getSections}
					changeSection={view.changeSection.bind(view)} 
				/>
			)
		}),
		SortableList = SortableContainer(({items}) => {
			return (
				<div className="SortableList">{items.map((value, index) => (
					<SortableItem key={"ag" + index} index={index} value={value} sortIndex={index}/>
				))}</div>
			);
		});

		return(
			<div className={"col col20 productsNav " + (this.props.IsProduct ? "prod" : "sec")}>
				<div className="clearfix container" style={style}>
					<SortableList 
						items={this.props.Items} 
						onSortEnd={this.onSortEnd} 
						useDragHandle   
						helperClass="SortableHelper"
						axis="xy"
						lockToContainerEdges={true}
						disableAutoscroll={true}
					/>
					{checkRights("ManageProducts") && _.isUndefined(productState.EditSection) && 
							!this.props.ForMenu && 
						<a href="/" className="add icon" onClick={this.addSection.bind(this)}><i></i> Добавить</a>
					}
				</div>
				{!this.props.ForMenu && !_.isUndefined(productState.TrashSection) &&
					<a className={"removed " + (!productState.IsActive ? "active" : "")} href="/" 
						onClick={this.changeSection.bind(view)} rel={productState.TrashSection.Id}><i></i>Корзина</a>
				}
			</div>
		)
	}
}
SectionMenu = observer(SectionMenu);

class SectionMenuItem extends Component {
	/** Включить/отключить режим редактирования выбранного раздела */
	changeEditMode() { productState.setSection(_.find(productState.Sections, { Id : this.props.Item.Id })); }
	
	/** ЗАкрыть режим редактирования раздела */
	closeEdit(event) { 
		event.preventDefault();
		productState.setSection(undefined); 
		productState.Sections = _.filter(productState.Sections, function (v) { return v.Id !== -1; });
	}

	/**
	 * Сбор данных с формы редактирования раздела
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange (value, id) { productState.collectSection(id, value); }

	/** API запрос «SaveSections» на изменение данных раздела */
	handleSubmit (event) {
		event.preventDefault();

		if (!productState.validSectionData()) return false;
		
		var model = this;
		getRequest("SaveSections", productState.getSectionData(), function(data) {
			if (data.Success) model.props.getSections();
			else productState.ErrorCode = data.ErrorCode;
		});
	}

	/** API запрос «RemoveSection» на удаление выбранного раздела */
	removeId() {
		if (_.isUndefined(this.props.Item.Id)) return false;

		var model = this,
			item = _.find(productState.Sections, { Id: this.props.Item.Id }),
			text = <span>Вы действительно хотите удалить&nbsp;категорию <b key="b1">«{item ? item.Name : ""}»
				</b>?</span>;

		confirmState.openConfirm(text, function() {
			getRequest("RemoveSection", { Id : model.props.Item.Id }, function () { model.props.getSections(); });
		});
	}

	render() {
		var current = this.props.IsProduct && menuState.Product ? menuState.Product.Id : this.props.ForMenu 
			? menuState.CurrentSection : productState.CurrentSection;

		if (productState.EditSection !== this.props.Item.Id || this.props.ForMenu)
			return(
				<a className={"icon sectionItem " + (this.props.Item.Type ? "type_" + this.props.Item.Type : "") 
					+ (current === this.props.Item.Id ? " active" : "")} 
					href="/" onClick={this.props.changeSection} rel={this.props.Item.Id}>
						{checkRights("ManageProducts") && !this.props.ForMenu && <DragHandle />}
						<span>{this.props.Item.Name}</span>
						{this.props.Item.IsHidden && !this.props.ForMenu && <i className="hidden"></i> }
						{checkRights("ManageProducts") && !this.props.ForMenu &&
							<i className="edit" onClick={this.changeEditMode.bind(this)}></i>
						}
						{checkRights("ManageProducts") && !this.props.ForMenu &&
							<i className="remove" onClick={this.removeId.bind(this)}></i>
						}
				</a>
			)
		else 
			return(
				<form className="section" onSubmit={this.handleSubmit.bind(this)}>
					<TextInput
						Id="Name"
						Title="Категория"
						Value={productState.Section ? productState.Section.Name : ""}
						Required={true}
						onChange={this.handleChange.bind(this)}
					/>
					{productState.PageType !== 1 &&
						<Selectize
							Id="MenuGroup"
							Title="Тип меню"
							List={toSelectList(MenuTitles)}
							Value={productState.Section ? productState.Section.MenuGroup : ""}
							Required={true}
							onChange={this.handleChange.bind(this)}
						/>
					}
					{productState.PageType !== 1 &&
						<CheckGroup
							Id="IsHidden"
							Type="checkbox"
							RowClass="nomargin"
							List={[{ Id : "true", Title : "Скрытая категория" }]}
							Value={productState.Section && productState.Section.IsHidden ? ["true"] : []}
							onChange={this.handleChange.bind(this)}
						/>
					}
					{productState.PageType === 3 &&
						<ColorPreset
							Id="Color"
							Title="Цвет категории"
							Value={productState.Section ? productState.Section.Color : ""}
							onChange={this.handleChange.bind(this)}
						/>
					}
					{productState.PageType === 3 &&
						<CheckGroup
							Id="Brands"
							Title="Бренды"
							Type="checkbox"
							List={toCheckList(this.props.Brands)}
							Value={productState.Section ? productState.Section.Brands : []}
							onChange={this.handleChange.bind(this)}
						/>

					}
					{productState.ErrorCode !== "" &&
						<p className="error">{errors[productState.ErrorCode] || errors.DEFAULT}</p>
					}
					<div className="clearfix">
						<button>Сохранить</button>
						<a href="/" className="button close icon" onClick={this.closeEdit.bind(this)}><i></i></a>
					</div>
				</form>
			)
	}
}
SectionMenuItem = observer(SectionMenuItem);

class RawItem extends Component {
	constructor(props) {
		super(props);

		if (productState.Product.Id !== -1) {
			this.getProviders();
			this.getProducts();
		}
	}

	/** API запрос на получение списка поставщиков этого сырья */
	getProviders () {
		getRequest("ProductProviders", { Id : productState.Product.Id }, function (data) {
			productState.setRawProviders(data.Success ? data.Providers : []);
		});
	}

	/** API запрос на получение списка ПФ и товаров, куда входит это сырье */
	getProducts () {
		getRequest("RawProducts", { Id : productState.Product.Id }, function (data) {
			productState.setRawProducts(data.Success ? data.Products : []);
		});
	}

	/** Закрытие выбранного продукта */
	handleClose(event) {
		event.preventDefault();
		this.props.setId(undefined);
	}

	/**
	 * Сбор данных с формы редактирования сырья
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	handleChange(value, id) { productState.collectRaw(id, value); }

	/** API запрос на сохранение сырья */
	handleSubmit(event) {
		event.preventDefault();
		if (!productState.validateRawData()) return false;

		productState.ButtonLoading = true;

		getRequest("SaveRaw", productState.getRawData(), function(data) {
			productState.ErrorCode = data.Success ? "SUCCESS_SAVE" : data.ErrorCode;
			productState.SuccessSave = data.Success;
			productState.ButtonLoading = false;

			if (data.Success) productState.Product.Id = data.Id;
		});
	}

	render() {
		var style = { left: uistate.IsSideBarOpen ? 260 : 50 },
			showWeight = _.indexOf([1,2,3,4], productState.Product.UnitIdNet) === -1,
			sections = productState.Product.Active ? _.filter(productState.Sections, function (v) {
				return !v.System; }) : productState.Sections;

		return (
			<section className="clearfix item two-cols" id="RawItem">
				<form onSubmit={this.handleSubmit.bind(this)} >
					<ItemHeader
						Title={buttonTitles[productState.Product.Id === -1 ? "NewRaw" : "EditRaw"]}
						Error={productState.ErrorCode}
						CanManage={checkRights("ManageProducts")}
						Success={productState.SuccessSave}
						handleClose={this.handleClose.bind(this)}
						Loading={productState.ButtonLoading}
					/>
					<div className="clearfix main backblock fixed" style={style}>
						<TextInput
							Id="Name"
							Title="Название сырья"
							Required={true}
							Value={productState.Product.Name}
							Disabled={!checkRights("ManageProducts")}
							RowClass="col col25"
							onChange={this.handleChange.bind(this)}
						/>
						<Selectize
							Id="SectionId"
							Title="Категория"
							List={toSelectList(sections)}
							Value={productState.Product.SectionId}
							Required={true}
							RowClass="col col25"
							Disabled={!checkRights("ManageProducts")}
							onChange={this.handleChange.bind(this)}
						/>
					</div>
					<div className="clearfix section" >
						<div className="clearfix">
							<div className="col col60">
								<div className="clearfix block">
									<Selectize
										Id="UnitIdNet"
										Title="Ед. измерения нетто"
										List={toSelectList(authUserState.Units)}
										Value={productState.Product.UnitIdNet}
										Required={true}
										RowClass={showWeight ? "col col1_4" : "col col1_3"}
										Disabled={!checkRights("ManageProducts")}
										onChange={this.handleChange.bind(this)}
									/>
									{showWeight &&
										<TextInput
											Id="Weight"	
											Title="Вес (г)"
											Value={productState.Product.Weight}
											Type="number"
											RowClass={showWeight ? "col col1_4" : "col col1_3"}
											Disabled={!checkRights("ManageProducts")}
											onChange={this.handleChange.bind(this)}
										/>
									}
									<Selectize
										Id="UnitIdGross"
										Title="Ед. измерения брутто"
										List={toSelectList(authUserState.Units)}
										Value={productState.Product.UnitIdGross}
										Required={true}
										RowClass={showWeight ? "col col1_4" : "col col1_3"}
										Disabled={!checkRights("ManageProducts")}
										onChange={this.handleChange.bind(this)}
									/>
									<TextInput
										Id="VendorCode"	
										Title="Артикул"
										RowClass="col col100"
										Value={productState.Product.VendorCode}
										Disabled={!checkRights("ManageProducts")}
										onChange={this.handleChange.bind(this)}
									/>
									<TextInput
										Id="Description"	
										Title="Описание"
										Type="textarea"
										RowClass="col col100"
										Value={productState.Product.Description}
										Disabled={!checkRights("ManageProducts")}
										onChange={this.handleChange.bind(this)}
									/>
								</div>
								<ExpDateBlock
									handleChange={this.handleChange.bind(this)}
								/>
								<CpfcRawBlock
									handleChange={this.handleChange.bind(this)}
								/>	
							</div>
							<div className="col col40 gray">
								{!_.isEmpty(productState.RawCosts) && 
									(checkRights("ManageProductsFinance") || checkRights("ManageProducts")) && 
									<RawPriceBlock />
								}
								{!_.isEmpty(productState.RawProducts) && <RawProducts /> }
							</div>
						</div>
					</div>
				</form>
			</section>
		)
	}
}
RawItem = observer(RawItem);

class ExpDateBlock extends Component {
	render() {
		var isRaw = productState.PageType === 1;

		return(
			<div className="clearfix block expdate">
				<h4>Условия хранения</h4>
				<PeriodGroup
					Id="ExpDateRoom"
					Title="Срок хранения при температуре +20º"
					Value={productState.Product.ExpDateRoom}
					Disabled={!checkRights("ManageProducts")}
					RowClass={"col " + (isRaw ? "col60" : "col100")}
					onChange={this.props.handleChange}
				/>
				<PeriodGroup
					Id="ExpDate"
					Title="Срок хранения при температуре +2º - +4º)"
					Value={productState.Product.ExpDate}
					Disabled={!checkRights("ManageProducts")}
					RowClass={"col " + (isRaw ? "col60" : "col100")}
					onChange={this.props.handleChange}
				/>
				<PeriodGroup
					Id="ExpDateFreeze"
					Title="Срок хранения при температуре -16º"
					Value={productState.Product.ExpDateFreeze}
					Disabled={!checkRights("ManageProducts")}
					RowClass={"col " + (isRaw ? "col60" : "col100")}
					onChange={this.props.handleChange}
				/>
			</div>
		)
	}
}
ExpDateBlock = observer(ExpDateBlock);

class CpfcRawBlock extends Component {
	render() {
		return(
			<div className="clearfix block">
				<h4>КБЖУ</h4>
				<TextInput
					Id="Calories"	
					Title="Калорийность (на 100 г)"
					Value={productState.Product.Calories}
					RowClass="col col25"
					Type="number"
					Step="0.1"
					Disabled={!checkRights("ManageProducts")}
					onChange={this.props.handleChange}
				/>
				<TextInput
					Id="Proteins"	
					Title="Белки (на 100 г)"
					Value={productState.Product.Proteins}
					RowClass="col col25"
					Type="number"
					Step="0.1"
					Disabled={!checkRights("ManageProducts")}
					onChange={this.props.handleChange}
				/>
				<TextInput
					Id="Fats"	
					Title="Жиры (на 100 г)"
					Value={productState.Product.Fats}
					RowClass="col col25"
					Type="number"
					Step="0.1"
					Disabled={!checkRights("ManageProducts")}
					onChange={this.props.handleChange}
				/>
				<TextInput
					Id="Carbohydrates"	
					Title="Углеводы (на 100 г)"
					Type="number"
					Step="0.1"
					Value={productState.Product.Carbohydrates}
					RowClass="col col25"
					Disabled={!checkRights("ManageProducts")}
					onChange={this.props.handleChange}
				/>
			</div>
		)
	}
}
CpfcRawBlock = observer(CpfcRawBlock);

class RawPriceBlock extends Component {
	render() {
		return(
			<div className="clearfix block costs">
				<h4>Средняя цена по городу</h4>
				{_.map(productState.RawCosts, function (item, i) {
					return(<RawPriceItem key={i} Item={item} Index={i} />)
				})}
			</div>
		)
	}
}
RawPriceBlock = observer(RawPriceBlock);

class RawPriceItem extends Component {
	constructor(props) {
		super(props);

		this.state = { OpenProvider : false };
	}

	/** Развернуть/Свернуть блок со списком поставок сырья */
	handleClick (event) {
		event.preventDefault();
		this.setState({ OpenProvider : !this.state.OpenProvider });
	}

	/**
	 * Сбор данных с формы стоимости сырья
	 * @param {string} value 
	 * @param {string} id 
	 */
	handleChange(value, id) { productState.collectRawCost(id, value, this.props.Index); }

	render() {
		return(
			<div className="clearfix item">
				<div className="clearfix">
					<span className="col col50">{this.props.Item.Name}</span>
					{!this.props.Item.ManualPrice &&
						<span className="col col50">{this.props.Item.Cost} руб.</span>
					}
					{this.props.Item.ManualPrice &&
						<TextInput
							Id="Cost"
							Value={this.props.Item.Cost}
							Type="number"
							Step="0.01"
							RowClass="col col50"
							onChange={this.handleChange.bind(this)}
						/>
					}
					{!this.props.Item.ManualPrice &&
						<a href="/" className="provider" title="Показать поставщиков"
							onClick={this.handleClick.bind(this)}><i></i></a>
					}
				</div>
				{this.state.OpenProvider &&
					<ProvidersBlock 
						Providers={_.filter(productState.RawProviders, { CityId : this.props.Item.CityId })} 
					/>
				}
			</div>
		)
	}
}
RawPriceItem = observer(RawPriceItem);

class ProvidersBlock extends Component {
	render() {
		return(
			<div className="block providers clearfix">
				<div className="clearfix titles">
					<span className="col col45">Поставщик</span>
					<span className="col col30">Цена</span>
					<span className="col col25">Дата поставки</span>
				</div>
				{_.map(this.props.Providers, function(v, i) {
					var city = _.find(authUserState.Cities, { Id : v.CityId }),
						vat = _.find(VATTitles, { Id : v.VAT });

					return(<div className="clearfix item" key={"sm" + i}>
						<span className="col col45" title={v.Name}>{v.Name} ({city ? city.Name : ""})</span>
						<span className="col col30">{getRubs(v.Price, true, true)} ({vat ? vat.Name : ""})</span>
						<span className="col col25">{formatDate(v.Date)}</span>
					</div>)
				})}
			</div>
		)
	}
}

class RawProducts extends Component {
	constructor(props) {
		super(props);

		this.state = {
			OpenSemimanufactures : true,
			OpenProducts : true
		};
	}

	/** Развернуть/Свернуть блок с продуктами, куда выходит выбранное сырье */
	handleClick (event) {
		event.preventDefault();

		var type = "Open" + event.currentTarget.getAttribute("rel")
		this.setState({ [type] : !this.state[type] });
	}

	render() {
		var unit = _.find(authUserState.Units, { Id: productState.Product.UnitIdNet });

		return(
			<div className="composition clearfix block">
				{!_.isUndefined(productState.RawProducts[2]) &&
					<div className="block ">
						<h4>
							Используется в полуфабрикатах
							<a className={"toggle " + (this.state.OpenSemimanufactures ? "open" : "close")} 
								href="/" onClick={this.handleClick.bind(this)} rel="Semimanufactures"><i></i></a>
						</h4>
						{this.state.OpenSemimanufactures && 
							<div className="clearfix titles">
								<h5 className="col col75">Название</h5>
								<h5 className="col col25">Кол-во</h5>
							</div>
						}
						{this.state.OpenSemimanufactures && _.map(productState.RawProducts[2], function(v, i) {
							return(<div className="clearfix item" key={"sm" + i}>
								<span className="col col75">{v.Name}</span>
								<span className="col col25">{setUnit(v.Count, unit ? unit.Name : "")}</span>
							</div>)
						})}
					</div>
				}
				{!_.isUndefined(productState.RawProducts[3]) &&
					<div className="block">
						<h4>
							Используется в продукции
							<a className={"toggle " + (this.state.OpenProducts ? "open" : "close")} 
								href="/" onClick={this.handleClick.bind(this)} rel="Products"><i></i></a>
						</h4>
						{this.state.OpenProducts && 
							<div className="clearfix titles">
								<h5 className="col col75">Название</h5>
								<h5 className="col col25">Кол-во</h5>
							</div>
						}
						{this.state.OpenProducts && _.map(productState.RawProducts[3], function(v, i) {
							return(<div className="clearfix item" key={"pr" + i}>
								<span className="col col75">{v.Name}</span>
								<span className="col col25">{setUnit(v.Count, unit ? unit.Name : "")}</span>
							</div>)
						})}
					</div>
				}
			</div>
		)
	}
}

class ProductItem extends Component {
	constructor(props) {
		super(props);

		this.state = {};

		this.getRawProducts = this.getRawProducts.bind(this);

		if (productState.Product.Id !== -1) this.getRawProducts();
	}

	/** API запрос на список товаров, куда входит данный товар */
	getRawProducts () {
		getRequest("RawProducts", { Id : productState.Product.Id }, function (data) {
			productState.setRawProducts(data.Success ? data.Products : []);
		});
	}

	/** Закрытие окна выбранного продукта */
	handleClose(event) {
		event.preventDefault();
		this.props.setId(undefined);
	}

	/**
	 * Сбор данных с формы редактирования продукта
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange(value, id) { productState.collectProduct(id, value); }

	/** API запрос «SaveProduct» на редактирование продукта */
	handleSubmit(event) {
		event.preventDefault();
		if (!productState.validateProductData()) return false;

		var model = this;

		productState.ButtonLoading = true;

		getUpload("SaveProduct", productState.getProductData(), function(data) {
			productState.ErrorCode = data.Success ? "SUCCESS_SAVE" : data.ErrorCode;
			productState.SuccessSave = data.Success;
			productState.ButtonLoading = false;

			if (data.Success) setTimeout(function() { model.props.getItem(data.Id); }, 200);
		});
	}

	render() {
		var style = { left: uistate.IsSideBarOpen ? 260 : 50 },
			sections = productState.Product.Active ? _.filter(productState.Sections, function (v) {
				return !v.System; }) : productState.Sections,
			sectionsList = _.map(sections, function (v) {
				return { Key : v.Id, Value : v.Name, Hidden : v.IsHidden }; });

		return (
			<section className="clearfix item two-cols" id="ProductItem">
				<form onSubmit={this.handleSubmit.bind(this)} >
					<ItemHeader
						Title={buttonTitles[(this.props.Id === -1 ? "New" : "Edit") + this.props.Type]}
						Error={productState.ErrorCode}
						Loading={productState.ButtonLoading}
						CanManage={checkRights("ManageProducts")}
						Success={productState.SuccessSave}
						handleClose={this.handleClose.bind(this)}
					/>
					<div className="clearfix main backblock fixed" style={style}>
						<TextInput
							Id="Name"
							Title="Название"
							Required={true}
							Disabled={!checkRights("ManageProducts")}
							Value={productState.Product.Name}
							RowClass="col col25"
							onChange={this.handleChange.bind(this)}
						/>
						<Selectize
							Id="SectionId"
							Title="Категория"
							Type="Sections"
							List={sectionsList}
							Value={productState.Product.SectionId}
							Required={true}
							RowClass="col col25"
							Disabled={!checkRights("ManageProducts")}
							onChange={this.handleChange.bind(this)}
						/>
					</div>
					<div className="clearfix section">
						<div className="clearfix">
							<div className="col col60">
								{((!checkRights("ManageProducts") && !_.isEmpty(productState.ProductComposition)) 
										|| checkRights("ManageProducts")) && 
									<CompositionProductBlock />
								}
								<ProductMain 
									Tags={this.props.Tags} 
									WorkShops={this.props.WorkShops} 
									handleChange={this.handleChange.bind(this)}
								/>
								<ProductDescription
									handleChange={this.handleChange.bind(this)}
								/>
								{((!checkRights("ManageProducts") && !_.isEmpty(productState.ProductTechnique))
									|| checkRights("ManageProducts")) && <CookingTech />
								}
								{((!checkRights("ManageProducts") && !_.isEmpty(productState.Video))
									|| checkRights("ManageProducts")) && <VideoBlock />
								}
							</div>
							<div className="col col40 gray">
								<CPFCBlock/>
								{!_.isEmpty(productState.RawProducts) && <RawProducts /> }
								{(checkRights("ManageProductsFinance") || checkRights("ManageProducts")) && 
									<FinanceProductBlock 
										handleChange={this.handleChange.bind(this)}
									/>
								}
							</div>
						</div>
					</div>
				</form>
			</section>
		)
	}
}
ProductItem = observer(ProductItem);

class ProductMain extends Component {
	render() {
		var isSemi = productState.PageType === 2,
			isGood = productState.PageType === 3;

		return(
			<div className="block clearfix">
				<div className="col col60">
					<div className="clearfix block">
						<TextInput
							Id="Weight"	
							Title={"Вес блюда " + (isSemi ? "(кг)" : "(г)")}
							Value={productState.Product.Weight}
							Type="number"
							Required={true}
							Step={isSemi ? "0.001" : "1"}
							Disabled={!checkRights("ManageProducts")}
							RowClass={"col " + (isSemi ? "col1_3" : "col1_2")}
							onChange={this.props.handleChange}
						/>
						{isGood &&
							<TextInput
								Id="WeightReady"	
								Title="Вес подачи (г)"
								Value={productState.Product.WeightReady}
								Type="number"
								Required={true}
								Disabled={!checkRights("ManageProducts")}
								RowClass="col col50"
								onChange={this.props.handleChange}
							/>
						}
						<Selectize
							Id="UnitIdNet"
							Title="Ед. измерения нетто"
							List={toSelectList(authUserState.Units)}
							Value={productState.Product.UnitIdNet}
							Required={true}
							Disabled={!checkRights("ManageProducts")}
							RowClass={"col " + (isSemi ? "col1_3" : "col1_2")}
							onChange={this.props.handleChange}
						/>
						<TextInput
							Id="Count"	
							Title="Количество"
							Value={productState.Product.Count}
							Type="number"
							Disabled={!checkRights("ManageProducts")}
							RowClass={"col " + (isSemi ? "col1_3" : "col1_2")}
							onChange={this.props.handleChange}
						/>
						{isGood &&
							<TextInput
								Id="CookingTime"	
								Title="Время приготовления (мин)"
								Value={productState.Product.CookingTime}
								Type="number"
								Disabled={!checkRights("ManageProducts")}
								RowClass="col col100"
								onChange={this.props.handleChange}
							/>
						}
						<TextInput
							Id="TechConditions"	
							Title="Технические условия"
							Value={productState.Product.TechConditions}
							RowClass="col col100"
							Disabled={!checkRights("ManageProducts")}
							onChange={this.props.handleChange}
						/>
						<TextInput
							Id="VendorCode"	
							Title="Артикул"
							RowClass="col col100"
							Value={productState.Product.VendorCode}
							Disabled={!checkRights("ManageProducts")}
							onChange={this.props.handleChange}
						/>
						{isGood &&
							<ColorPreset
								Id="Color"
								Title="Цвет карточки продукта"
								Value={productState.Product.Color}
								RowClass="col col100"
								Disabled={!checkRights("ManageProducts")}
								onChange={this.props.handleChange}
							/>
						}
					</div>
					<ExpDateBlock
						handleChange={this.props.handleChange}
					/>
				</div>
				{isGood &&
					<GoodsMain
						WorkShops={this.props.WorkShops}
						Tags={this.props.Tags}
						handleChange={this.props.handleChange}
					/>
				}
			</div>
		)
	}
}
ProductMain = observer(ProductMain);

class GoodsMain extends Component {
	render () {
		var product = productState.Product,
			modificatorsList = [
				{ Id : 1, Title: "Можно модифицировать" },
				{ Id : 2, Title: "Является модификатором" }
			]

		return(
			<div className="col col40">
				<CheckGroup
					Id="IsCombo"
					Type="checkbox"
					List={[{ Id : "true", Title : "Является комбо-продуктом"}]}
					Value={product.IsCombo ? ["true"] : []}
					Disabled={!checkRights("ManageProducts")}
					RowClass="nomargin"
					onChange={this.props.handleChange}
				/>
				<CheckGroup
					Id="NoStat"
					Type="checkbox"
					List={[{ Id : "true", Title : "Не отображать в статистике"}]}
					Value={product.NoStat ? ["true"] : []}
					Disabled={!checkRights("ManageProducts")}
					RowClass="nomargin"
					onChange={this.props.handleChange}
				/>
				<CheckGroup
					Id="Modificators"
					Title="Настройки для модификаторов"
					Type="checkbox"
					List={modificatorsList}
					Value={[product.Modificators]}
					Disabled={!checkRights("ManageProducts")}
					onChange={this.props.handleChange}
				/>
				<Selectize
					Id="Tags"
					Title="Подсказки к продуктам"
					Multi={true}
					List={toSelectList(this.props.Tags)}
					Value={product.Tags}
					Disabled={!checkRights("ManageProducts")}
					onChange={this.props.handleChange}
				/>
				<Selectize
					Id="WorkShops"
					Title="Цех приготовления"
					List={toSelectList(this.props.WorkShops)}
					Value={product.WorkShops}
					Disabled={!checkRights("ManageProducts")}
					Multi={true}
					onChange={this.props.handleChange}
				/>
				<CheckGroup
					Id="POSTypes"
					Title="Доступно для заказа"
					Type="checkbox"
					List={toCheckList(POSTypesTitles)}
					Value={product.POSTypes}
					Disabled={!checkRights("ManageProducts")}
					onChange={this.props.handleChange}
				/>
			</div>
		)
	}
}
GoodsMain = observer(GoodsMain);

class ProductDescription extends Component {
	render() {
		var product = productState.Product,
			isGood = productState.PageType === 3;

		return(
			<div className="clearfix block description">
				<TextInput
					Id="Description"	
					Title="Описание"
					Type="textarea"
					RowClass="col col60"
					Value={product.Description}
					Disabled={!checkRights("ManageProducts")}
					onChange={this.props.handleChange}
				/>
				<FileInput 
					Id="MainPhoto"
					Title="Основное фото"
					MaxSize={2097152}
					RowClass="col col40 nomargin"
					File={product.MainPhotoURL}
					onChange={this.props.handleChange}
				/>
				{isGood &&
					<TextInput
						Id="BillComposition"	
						Title="Состав блюда для чека"
						Type="textarea"
						RowClass="col col60"
						Value={product.BillComposition}
						Disabled={!checkRights("ManageProducts")}
						onChange={this.props.handleChange}
					/>
				}
				{/* {isGood &&
					<CheckGroup
						Id="POSTypes"
						Title="Доступно для заказа"
						Type="checkbox"
						List={toCheckList(POSTypesTitles)}
						RowClass="col col40"
						Value={productState.Product.POSTypes}
						Disabled={!checkRights("ManageProducts")}
						onChange={this.props.handleChange}
					/>
				} */}
				{isGood &&
					<TextInput
						Id="Recomendation"	
						Title="Рекомендации к заказу"
						Type="textarea"
						RowClass="col col60"
						Value={productState.Product.Recomendation}
						Disabled={!checkRights("ManageProducts")}
						onChange={this.props.handleChange}
					/>
				}
			</div>
		)
	}
}
ProductDescription = observer(ProductDescription);

class CookingTech extends Component {
	/** Добавление новго этапа приготовления */
	handleAdd(event) {
		event.preventDefault();

		productState.ProductTechnique = _.concat(productState.ProductTechnique, 
			_.clone(productState.ProductTechniqueDummy));
	}

	render () {
		return(
			<div className="clearfix block technique">
				<h4>Техника приготовления</h4>
				{_.map(productState.ProductTechnique, function (item, i) {
					return(<CookingTechItem key={"cook" + productState.TechCounter++ } Index={i} Item={item} />)
				})}
				{checkRights("ManageProducts") &&
					<a href="/" className="button add icon" onClick={this.handleAdd.bind(this)}>
						<i></i>Добавить этап приготовления</a>
				}
			</div>
		)
	}
}
CookingTech = observer(CookingTech);
class CookingTechItem extends Component {
	/** Удаление этапа приготовления */
	handleDelete (event) { 
		event.preventDefault();
		
		var index = this.props.Index;
		productState.ProductTechnique = _.filter(productState.ProductTechnique, function (v,i) { 
			return i !== index; });
	}
	
	/**
	 * Сбор данных с формы техник приготовления
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange(value, id) { productState.collectTechnique(id, value, this.props.Index); }

	render() {
		return(
			<div className="clearfix tech row">
				<div className="col col60">
					<TextInput
						Id="Name"	
						Title="Заголовок этапа"
						Value={this.props.Item.Name}
						Required={true}
						Disabled={!checkRights("ManageProducts")}
						onChange={this.handleChange.bind(this)}
					/>
					<TextInput
						Id="Description"	
						Title="Описание"
						Type="textarea"
						Value={this.props.Item.Description}
						Required={true}
						Disabled={!checkRights("ManageProducts")}
						onChange={this.handleChange.bind(this)}
					/>
				</div>
				<div className="col col40">
					<FileInput 
						Id="Photo"
						Title="Фото этапа"
						File={this.props.Item.PhotoURL}
						onChange={this.handleChange.bind(this)}
					/>
				</div>
				{checkRights("ManageProducts") &&
					<a className="button remove" href="/" role="button" title="Удалить этап"
						onClick={this.handleDelete.bind(this)}><i></i></a>
				}
			</div>
		)
	}
}
CookingTechItem = observer(CookingTechItem);

class CPFCBlock extends Component {
	render () {
		return(
			<div className="clearfix block CPFC">
				<h4>КБЖУ</h4>
				<p className="comment">Информация обновится после сохранения продукта</p>
				<div className="clearfix row">
					<span className="col col50 title">Вес сырых продуктов</span>
					<span className="col col25">{productState.Product.SourceWeight} г</span>
				</div>
				<div className="clearfix row titles">
					<span className="col col50"></span>
					<span className="col col25">на 100 г</span>
					<span className="col col25">на блюдо</span>
				</div>
				<div className="clearfix row">
					<span className="col col50 title">Калорийность</span>
					<span className="col col25">{(productState.Product.Calories).toFixed(1)}</span>
					<span className="col col25">
						{(productState.Product.Calories*productState.Product.WeightReady/100).toFixed(1)}
					</span>
				</div>
				<div className="clearfix row">
					<span className="col col50 title">Белки</span>
					<span className="col col25">{(productState.Product.Proteins).toFixed(1)}</span>
					<span className="col col25">
						{(productState.Product.Proteins*productState.Product.WeightReady/100).toFixed(1)}
					</span>
				</div>
				<div className="clearfix row">
					<span className="col col50 title">Жиры</span>
					<span className="col col25">{(productState.Product.Fats).toFixed(1)}</span>
					<span className="col col25">
						{(productState.Product.Fats*productState.Product.WeightReady/100).toFixed(1)}
					</span>
				</div>
				<div className="clearfix row">
					<span className="col col50 title">Углеводы</span>
					<span className="col col25">{(productState.Product.Carbohydrates).toFixed(1)}</span>
					<span className="col col25">
						{(productState.Product.Carbohydrates*productState.Product.WeightReady/100).toFixed(1)}
					</span>
				</div>
			</div>
		)
	}
}
CPFCBlock = observer(CPFCBlock);

class CompositionProductBlock extends Component {
	handleAdd(value) {
		var type = parseInt(value, 10);

		productState.ProductComposition[type] = _.concat(productState.ProductComposition[type], 
			_.extend(_.clone(productState.ProductCompositionDummy), { Type : type }));
	}

	render () {
		var buttonList = [{ Id : 1, Title : "Сырье" }, { Id : 2, Title : "Полуфабрикат" }];

		if (productState.PageType === 3 ) buttonList = _.concat(buttonList, { Id : 3, Title : "Продукцию" });

		return(
			<div className="block composition clearfix">
				<h4>Состав</h4>
				{_.map(productState.ProductComposition, function (group, i) {
					return(<div className="group clearfix" key={"gr" + i}>
						{!_.isEmpty(group) && _.map(group, function(item,k) {
							return(<CompositionItem 
								key={"p" + productState.ProdCounter++} 
								Item={item} 
								Index={k} 
								Type={i} 
								ProductsList={_.filter(productState.AllProducts, { Type : parseInt(i, 10),
									Active : true })}
							/>)
						}) }
					</div>)
				})}
				{checkRights("ManageProducts") &&
					<DropButton Title="Добавить" List={buttonList} onClick={this.handleAdd.bind(this)} />
				}
			</div>
		)
	}
}
CompositionProductBlock = observer(CompositionProductBlock);
class CompositionItem extends Component {
	handleDelete (event) { 
		event.preventDefault();
		
		var index = this.props.Index,
			type = this.props.Type;

		productState.ProductComposition[type] = _.filter(productState.ProductComposition[type], function (v,i) { 
			return i !== index; });
	}

	onChange (value, id) { 
		if (id === "SourceId") var product = _.find(this.props.ProductsList, { Id : parseInt(value, 10) });
		productState.collectComposition(id, value, this.props.Index, this.props.Type, product); 
	}

	render() {
		var titles = { 1 : "Сырье", 2 : "Полуфабрикаты", 3 : "Продукция" };

		return(
			<div className="product clearfix">
				<Selectize
					Id="SourceId"
					Title={titles[this.props.Type]}
					Value={this.props.Item.SourceId}
					List={toSelectList(this.props.ProductsList)}
					Required={true}
					Disabled={!checkRights("ManageProducts")}
					RowClass="col col65"
					onChange={this.onChange.bind(this)}
				/>
				<TextInput
					Id="SourceUseful"
					Title="В блюдо"
					Value={this.props.Item.SourceUseful}
					Type="number"
					Step="0.001"
					RowClass="col col15"
					Required={true}
					Disabled={!checkRights("ManageProducts")}
					onChange={this.onChange.bind(this)}
				/>
				{productState.PageType === 2 &&
					<TextInput
						Id="SourceTrash"
						Title="Обрез"
						Value={this.props.Item.SourceTrash || 0}
						Type="number"
						Step="0.001"
						RowClass="col col15"
						Disabled={!checkRights("ManageProducts")}
						onChange={this.onChange.bind(this)}
					/>
				}
				<span className="col col5">{this.props.Item.Unit}</span>
				{checkRights("ManageProducts") &&
					<a className="button remove" href="/" role="button" title="Удалить продукт"
						onClick={this.handleDelete.bind(this)}><i></i></a>
				}
			</div>
		)
	}
}
CompositionItem = observer(CompositionItem)

class FinanceProductBlock extends Component {
	render() {
		var isGood = productState.PageType === 3;

		return(
			<div className="block finance">
				<h4>Цена и себестоимость</h4>
				<p className="comment">Информация обновится после сохранения продукта</p>
				{isGood &&
					<div className="clearfix">
						<TextInput
							Id="MinProfit"	
							Title="Минимальная наценка"
							Type="number"
							RowClass="col col50"
							Value={productState.Product.MinProfit}
							onChange={this.props.handleChange}
						/>
						<Selectize
							Id="VAT"
							Title="НДС"
							List={toSelectList(VATTitles)}
							RowClass="col col50"
							Value={productState.Product.VAT}
							onChange={this.props.handleChange}
						/>
					</div>
				}
				<div className="clearfix prices">
					<div className="titles clearfix row">
						<span className="col col25 title">Город</span>
						<span className={isGood ? "col col20 title" : "col col75"}>Себес., руб.</span>
						{isGood &&
							<span className="col col15 title">Наценка</span>
						}
						{isGood &&
							<span className="col col15 title">Маржа</span>
						}
						{isGood &&
							<span className="col col25 title">Цена</span>
						}
					</div>
					{_.map(productState.ProductPrices, function (v,i) {
						return(<FinanceProductBlockItem Item={v} key={"city" + i}/>)
					})}
				</div>
			</div>
		)
	}
}
FinanceProductBlock = observer(FinanceProductBlock);
class FinanceProductBlockItem extends Component {
	/**
	 * Сбор данных с формы изменения цен
	 * @param {string} value значение цены
	 */
	handleChange(value, id) { productState.collectPrices(value, this.props.Item.CityId); }

	render() {
		return(
			<div className="clearfix city row">
				<span className="name col col25" title={this.props.Item.Name}>{this.props.Item.Name}</span>
				<span className="col col20">{getRubs(this.props.Item.Cost, true, true)}</span>
				{productState.PageType === 3 && 
					<span className="col col15">{(this.props.Item.Markup ? (this.props.Item.Markup*100).toFixed(1) : 0) + "%"}</span>
				}
				{productState.PageType === 3 && 
					<span className="col col15">{(this.props.Item.Margin ? (this.props.Item.Margin*100).toFixed(1) : 0) + "%"}</span>
				}
				{productState.PageType === 3 && 
					<TextInput
						Id="Price"
						Type="number"
						Step="0.01"
						RowClass={"col col25 " + (this.props.Item.Markup*100 < productState.Product.MinProfit ? "alert" : "")}
						Value={this.props.Item.Price}
						onChange={this.handleChange.bind(this)}
					/>
				}
			</div>
		)
	}
}
FinanceProductBlockItem = observer(FinanceProductBlockItem);

class VideoBlock extends Component {
	/** Добавление видео файла */
	handleAdd(event) {
		event.preventDefault();
		productState.Video = _.concat(productState.Video, _.clone(productState.VideoDummy));
	}

	render() {
		return(
			<div className="clearfix block video">
				<h4>Видео материалы</h4>
				<div className="clearfix">
					{_.map(productState.Video, function (video, i) {
						return(<VideoItem Item={video} key={"v" + productState.VCounter++} Index={i} />)
					})}
				</div>
				{checkRights("ManageProducts") &&
					<a href="/" className="button icon add" onClick={this.handleAdd.bind(this)}>
						<i></i>Добавить видео</a>
				}
			</div>
		)
	}
}
VideoBlock = observer(VideoBlock);

class VideoItem extends Component {
	/** Удаление видео файла */
	handleDelete (event) { 
		event.preventDefault();
		
		var index = this.props.Index;
		productState.Video = _.filter(productState.Video, function (v,i) { return i !== index; });
	}

	/**
	 * Сбор данных с формы добавления видео
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange (value, id) { productState.collectVideo(id, value, this.props.Index); }

	render() {
		return(
			<div className="item col col50">
				<FileInput 
					Id="File"
					Title="Видео"
					File={this.props.Item.FileURL}
					Type="video/*"
					Ext={[".mov", ".avi"]}
					MaxSize={5242880}
					onChange={this.handleChange.bind(this)}
				/>
				{checkRights("ManageProducts") &&
					<a className="button remove" href="/" role="button" title="Удалить этап"
						onClick={this.handleDelete.bind(this)}><i></i></a>
				}
			</div>
		)
	}
}