import { observable, decorate } from 'mobx';
import _ from 'lodash';

class DirectoryState {
	DirectoryItem = undefined;
	DirectoryType = "";
	ButtonLoading = false;
	ErrorCode = "";
	Directory = [];
	IsSendRequest = false;
	Active = true;

	/**
	 * РАзбор ответа от сервера со списком элементов
	 * @param {object} data ответ от сервера {Success, Error, [List]}
	 */
	setList(data) {
		var type = this.DirectoryType;
		
		this.Directory = data.Success && !_.isNull(data[type]) ? _.map(data[type], function (v) {
				return v.Price ? _.extend(v, { Price: (v.Price / 100).toFixed(2) }) : v 
			}) :  [];
		this.IsSendRequest = true;
	}

	/**
	 * Разбор данных элемента списка по полям
	 * @param {object} item элемент списка
	 * @param {[object]} fields список полей
	 */
	setItem(item, fields) {
		var dummy = { Id: -1 };
		_.each(fields, function (v) { dummy[v.Id] = "" });

		this.DirectoryItem = _.isUndefined(item) ? undefined : _.extend(_.clone(dummy), item);

		this.ButtonLoading = false;
		this.ErrorCode = "";
	}

	/**
	 * Сбор данных с формы редактирования элемента списка
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectItem(id, value) { this.DirectoryItem[id] = value; }

	/**
	 * Сбор данных элемента списка для отправки на сервер для сохранения
	 * @returns {object} данные
	 */
	getData() {
		var data = _.clone(this.DirectoryItem);

		if (data.Price) data.Price = data.Price * 100;

		if (this.DirectoryType === "Inventories") data = _.extend(data, { Active: true });

		return data;
	}
}
decorate(DirectoryState, {
	Directory: observable,
	IsSendRequest: observable,
	Active: observable,

	DirectoryItem: observable,
	ButtonLoading: observable,
	ErrorCode: observable,
});
export const directoryState = new DirectoryState();