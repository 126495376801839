import { observable, decorate, computed } from 'mobx';
import _ from 'lodash';

import { authUserState } from './common';
import { formatDate, checkRights, clearEmptyParams } from '../functions';

class CassaState {
	IsSendRequest = false;
	CurrentPOSId = -1;
	ShiftDate = "";
	IsOpen = false;
	Filter = {
		POSId: -1,
		StartShiftDate: formatDate("today", "r"),
		EndShiftDate: formatDate("today", "r"),
		TagId: -1,
		Group: -1,
		ShowOrders : false,
	};
	SendZReport = false;
	ErrorCode = "";
	ButtonLoading = false;
	ErrorMessage = "";
	XReportLoading = false;

	Transactions = [];
	Dates = [];
	Stat = {};

	TransactionDummy = {
		POSId: -1,
		Amount: "",
		Group: -1,
		TagId: -1,
		Description: "",
		Fiscal: false
	}
	CouriersCash = [];
	CouriersAmount = 0;

	/** Формирование списка точек продаж и касс, доступных пользователю
	 * @returns {[object]} список точек продаж
	 */
	get posList() {
		var cassaFilials = _.filter(authUserState.Filials, function (v) {
			return checkRights("CanViewAllCassa") ? v.MerchantId === authUserState.Merchant :
				authUserState.Shift.Active ? v.Id === authUserState.Shift.FilialId : false;
		});
		return _.filter(_.flatten(_.map(cassaFilials, "POS")), { HasCassa: true });
	}

	/** Проверка фискальности кассы
	 * @returns {boolean} Касса фискальная
	 */
	get isFiscal() {
		return _.isEmpty(this.posList) || this.CurrentPOSId === -1 ? false :
			_.find(this.posList, { Id: this.CurrentPOSId }) ?
				_.find(this.posList, { Id: this.CurrentPOSId }).Fiscal : false;
	}


	/** Установка дефолтных значений фильтра */
	setDefault() {
		this.Filter = {
			POSId: this.posList.length === 0 ? -1 : _.head(this.posList).Id,
			StartShiftDate: authUserState.Shift.Date,
			EndShiftDate: authUserState.Shift.Date,
			TagId: -1,
			Group: -1,
			ShowOrders : false
		};

		this.Dates = [];
		this.Transactions = [];
		this.ConfimOpen = false;
		this.TransactionInfo = undefined;
		
		this.CouriersCash = [];
		this.CouriersAmount = 0;
		this.CassaAmount = 0;

		this.setCassaTransaction(undefined)
	}
	/**
	 * Сбор данных с формы фильтра кассовых операций
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectFilter(id, value) { 
		if (/ShowOrders/.test(id)) this.Filter.ShowOrders = value;
		else this.Filter[id] = value; 
	}
	/** Сбор данных фильтра для отправки на сервер 
	 * @return {object} данные для запроса поиска кассовых операций
	*/
	getFilterData() { 
		return clearEmptyParams(this.Filter, ["", -1]);
		// return _.omitBy(_.clone(this.Filter), function (v) { return v === -1 || v === ""; }); 
	}

	/** Разбор данных кассы */
	setCassa(data) {
		this.Dates = data.Success && !_.isNull(data.Dates) ? data.Dates : [];
		this.CurrentPOSId = data.POSId;
		this.ShiftDate = data.Success ? data.CassaShiftDate : "";
		this.IsOpen = data.Success ? data.IsOpen : false;
		this.IsSendRequest = true;

		this.Stat = {};
		this.Transactions = [];
		this.CassaMulty = !(this.Dates.length === 1 && this.Dates[0].ShiftDate === authUserState.Shift.Date);

		if (!this.CassaMulty) {
			var cassa = this.Dates[0];

			this.Transactions = _.map(cassa.Transactions, function (item) {
				return _.extend(item, {
					CanRemove: !item.Fiscal && data.IsOpen && item.Group < 3
				});
			});
			this.Stat = _.omit(_.clone(cassa), "Transactions");
		}

		if (!_.isEmpty(this.Dates) && this.CassaMulty) {
			this.Dates = _.map(this.Dates, function(date) {
				var data = date;

				_.each(date.Orders, function (method){
					var nonPayment = method.Amount - method.Payment;

					data = _.extend(data, {
						["Order_" + method.PaymentMethodId  + "_Amount"] : method.Amount,
						["Order_" + method.PaymentMethodId  + "_NonPayment"] : nonPayment
					});
				});

				return data;
			});
		}
	}

	get CassaBalance () {
		return this.CassaAmount;
	}

	/**
	 * Создание кассовой операции
	 * @param {number} group вид операции
	 */
	setCassaTransaction(group) {
		this.CassaTransaction = _.isUndefined(group) ? undefined :
			_.extend(_.clone(this.TransactionDummy), { Group: group, POSId: this.CurrentPOSId });
		this.ErrorCode = "";
	}
	/**
	 * Сбор данных с формы кассовой операции
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectCassaTransaction(id, value) {
		if (/Fiscal/.test(id)) this.CassaTransaction.Fiscal = value;
		else this.CassaTransaction[id] = value;
	}
	/** Сбор данных операции для отправки на сервер 
	 * @return {object} данные кассовой операции
	*/
	getTransactionData() {
		var data = _.omitBy(_.clone(this.CassaTransaction), function (v) { return v === -1 || v === ""; });
		data.Amount = data.Amount === "" ? 0 : (data.Amount * 100).toFixed(0);

		return data;
	}

	/** Сбор данных кассовой смены для отправки на сервер 
	 * @return {object} данные кассовой смены
	*/
	getCassaShift() {
		return {
			POSId : this.CurrentPOSId,
			Active : !this.IsOpen,
			ZReport : !this.IsOpen || !this.isFiscal ? false : this.SendZReport
		}
	}
}
decorate(CassaState, {
	IsSendRequest: observable,
	Filter: observable,
	ConfimOpen: observable,
	ErrorCode: observable,
	ButtonLoading: observable,
	ErrorMessage: observable,
	XReportLoading: observable,
	
	CassaAmount : observable,
	CouriersCash : observable,
	CouriersAmount : observable,
	Dates: observable,
	Transactions: observable,
	Stat: observable,
	TransactionInfo: observable,

	CurrentPOSId: observable,
	ShiftDate: observable,
	IsOpen: observable,
	CassaMulty: observable,

	CassaTransaction: observable,
	SendZReport: observable,

	posList: computed,
	isFiscal: computed,
	CassaBalance: computed
});
export const cassaState = new CassaState();