import React, { Component } from 'react';
import { observer } from 'mobx-react';

import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/ru';

import { toSelectList, checkRights, getRubs, getRequest, getFile, formatDate, setUnit, toCheckList } from '../functions';
import { buttonTitles, daysLong, daysShortSun, VATTitles, ProductTypeTitles } from '../dictionary';

import { TableFilterPage, ItemHeader } from './PageTemplates';
import { CheckGroup, Selectize, TextInput } from '../forms/FormItems';

import { uistate, authUserState, confirmState } from '../stores/common';
import { requestState, shopListState } from '../stores/requests';

export class RequestsPage extends Component {
	constructor(props) {
		super(props);

		requestState.setRequest(undefined);

		this.state = {};

		this.getData();

		getRequest("Providers", { Active: true }, function (data) {
			requestState.Providers = data.Success ? data.Providers : [];
		});
	}

	/** API запрос «Requests» на получение заявок */
	getData() {
		requestState.IsSendRequest = false;
		getRequest("Requests", {}, function (data) { requestState.setRequests(data); });
	}

	/**
	 * Выбор нужной заявки
	 * @param {number} id ID заявки
	 * @param {string} type тип просмотра — new, view, process
	 */
	setId(id, type) {
		if (!_.isUndefined(id)) {
			if (id !== -1) {
				requestState.Operation = type;
				requestState.setRequest(_.find(requestState.Requests, { Id: id }));
			} else {
				requestState.Operation = "new";
				requestState.setRequest({ Id: -1 });
			}
		} else {
			requestState.setRequest(undefined);
			this.getData();
		}
	}

	/**
	 * API запрос «RemoveRequest» на удаление заявки
	 * @param {number} id ID заявки
	 */
	removeId(id) {
		if (_.isUndefined(id)) return false;

		var model = this,
			item = _.find(requestState.Requests, { Id: id }),
			text = <span>Вы действительно хотите удалить заявку от&nbsp;
				<b key="b1">«{item ? formatDate(item.Date) : ""}»</b>?</span>;

		confirmState.openConfirm(text, function () {
			getRequest("RemoveRequest", { Id: id }, function () { model.getData(); });
		});
	}

	render() {
		var status = { 1: "Новая", 2: "Обработана" },
			columns = {
				Date: { filterable: false, Header: "Дата создания" }, User: {}, Stock: {},
				Status: { filterable: false, Cell: row => status[row.original.Status] },
				Type: { filterable: false, Cell: row => _.find(ProductTypeTitles, { Id : row.original.Type }).Name },
				View: {}
			};

		if (checkRights("ManagePurchase")) _.extend(columns, { Process: {} });

		_.extend(columns, { Remove: {} });

		return (
			<div className="page" id="RequestsPage">
				{requestState.IsSendRequest && _.isUndefined(requestState.Request) &&
					<TableFilterPage
						Type="Order"
						CanManage={authUserState.Shift.Active}
						CanOrder={checkRights("ManagePurchase") || authUserState.Shift.Active}

						TableData={requestState.Requests}
						TableColumns={columns}
						TableFilterable={true}

						setId={this.setId.bind(this)}
						removeId={this.removeId.bind(this)}

						getTrProps={(state, rowInfo, column) => {
							return { className: rowInfo && rowInfo.original.Status !== 1 ? "notactive" : "" };
						}}
					/>
				}
				{!_.isUndefined(requestState.Request) &&
					<RequestItem setId={this.setId.bind(this)} />
				}
			</div>
		)
	}
}
RequestsPage = observer(RequestsPage);

class RequestItem extends Component {
	constructor(props) {
		super(props);

		this.state = {};

		if (requestState.Request.Id !== -1) {
			this.getStock(requestState.Request.StockId);
			this.getActual(requestState.Request.StockId);
		}
	}

	/** API запрос «Stock» на получение настроек склада */
	getStock(id) {
		getRequest("Stock", { Id: id, OnlyProducts: true }, function (data) {
			requestState.StockProducts = data.Success ? data.Products : [];
		});
	}

	/** API запрос «StockActual» на получение остатков склада */
	getActual(id) {
		getRequest("StockActual", { Id: id }, function (data) {
			requestState.StockActualProducts = data.Success ? data.Products : [];
		});
	}

	/** Закрытие окна с заявкой */
	handleClose(event) {
		event.preventDefault();
		this.props.setId(undefined);
	}

	/** API запрос «NewRequest»/«ProcessRequest» на создание/обработку */
	handleSubmit(event) {
		event.preventDefault();

		var model = this,
			request = requestState.Operation === "process" ? "ProcessRequest" : "NewRequest",
			result = requestState.Operation === "process" ? requestState.getProcessData()
				: requestState.getSaveData();

		if (requestState.Operation === "process" && !requestState.validateProcess())
			return false;

		requestState.ButtonLoading = true;

		getRequest(request, result, function (data) {
			if (data.Success) model.props.setId(undefined);
			else requestState.ErrorCode = data.ErrorCode;

			requestState.ButtonLoading = false;
		});
	}

	/**
	 * Сбор данных с формы заявки
	 * @param {number} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange(value, id) {
		requestState.collectRequest(id, value);
		requestState.ShowTemplate = true;

		if (/StockId/.test(id)) {
			this.getStock(value);
			this.getActual(value);
		}
	}

	render() {
		var request = requestState.Request,
			title = requestState.Operation === "process" ? "EditRequest"
				: request.Id === -1 ? "NewRequest" : "ViewRequest",
			templateTitles = [{ Id: 1, Name: "По всему складу" }, { Id: 2, Name: "Выборочно" }],
			hasData = request.StockId > 0 && request.TemplateType > 0;
		
		return (
			<section className={"clearfix item " + (uistate.IsTablet ? "tablet" : "")} id="RequestItem">
				<form onSubmit={this.handleSubmit.bind(this)}>
					<ItemHeader
						Title={buttonTitles[title]}
						Error={requestState.ErrorCode}
						Loading={requestState.ButtonLoading}
						CanManage={requestState.Operation !== "view" && !requestState.ShowTemplate}
						handleClose={this.handleClose.bind(this)}
					/>
					<div className="clearfix main backblock">
						<Selectize
							Id="StockId"
							Title="Склад"
							List={toSelectList(authUserState.Stocks)}
							Value={request.StockId}
							Disabled={request.Id !== -1}
							isSearchable={false}
							RowClass="col col25"
							onChange={this.handleChange.bind(this)}
						/>
						<Selectize
							Id="TemplateType"
							Title="Тип заявки"
							List={toSelectList(templateTitles)}
							Value={request.TemplateType}
							Disabled={request.Id !== -1}
							isSearchable={false}
							RowClass="col col25"
							onChange={this.handleChange.bind(this)}
						/>
						{request.StockId !== -1 && request.TemplateType !== -1 &&
							<TextInput
								Id="Filter"
								Title="Поиск по продуктам"
								Value={requestState.Filter}
								RowClass="col col25 search"
								onChange={requestState.collectFilter.bind(requestState)}
							/>
						}
					</div>
					{requestState.ShowTemplate && hasData &&
						<TemplateProductsBlock />
					}
					{!_.isEmpty(requestState.grouppedProducts) && !requestState.ShowTemplate &&
						<RequestProductsBlock Products={requestState.grouppedProducts} />
					}
				</form>
			</section>
		)
	}
}
RequestItem = observer(RequestItem);

class TemplateProductsBlock extends Component {
	/**
	 * Сбор данных с формы набора продуктов для выборочной ревизии
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange(value, id) { requestState.collectTemplateProducts(id, value); }

	/** Начать ревизию по выбранному шаблону */
	hanldeStart(event) {
		event.preventDefault();
		requestState.startRequest();
	}

	render () {
		var view = this,
			style = { minHeight: uistate.sectionHeight - 67 };
		
		return(
			<div className="clearfix templateProducts section" style={style}>
				{requestState.Request.TemplateType === 2 && _.map(requestState.TemplateProducts, function (item, i) {
					return(<div className="block type clearfix" key={i}>
						<h4>{_.find(ProductTypeTitles, { Id : item.Type }).Name}</h4>
						{_.map(item.Sections, function (section, k) {
							var ids = _.map(section.Products, "Id"),
								all = _.isEqual(_.intersection(ids, requestState.CheckedTemplateProducts), ids);

							return(<div className="col col30" key={k}>
								<CheckGroup
									Id={"TemplateProducts_All_" + section.SectionId}
									List={[{ Id : "true", Title : section.SectionName }]}
									RowClass="checkAll"
									Type="checkbox"
									Value={ all ? ["true"] : []}
									onChange={view.handleChange.bind(view)}
								/>
								<CheckGroup
									Id={"TemplateProducts"}
									List={toCheckList(section.Products)}
									Type="checkbox"
									RowClass="nomargin product"
									Value={requestState.CheckedTemplateProducts}
									onChange={view.handleChange.bind(view)}
								/>
							</div>)
						})}
					</div>)
				})}
				<a href="/" className="button blue" onClick={this.hanldeStart.bind(this)}>Начать</a>
			</div>
		)
	}
}
TemplateProductsBlock = observer(TemplateProductsBlock);

class RequestProductsBlock extends Component {
	render() {
		var view = this,
			style = { minHeight: uistate.sectionHeight - 67 };

		return (
			<div className="clearfix section" style={style}>
				{_.map(this.props.Products, function (type, i) {
					return (<RequestProductType Item={type} Index={i} key={"t" + i}
						Providers={view.props.Providers} />)
				})}
			</div>
		)
	}
}
RequestProductsBlock = observer(RequestProductsBlock);

class RequestProductType extends Component {
	render() {
		var view = this;
		
		return (
			<div className="clearfix block">
				{_.find(ProductTypeTitles, { Id : this.props.Item.Type }) &&
					<h4>{_.find(ProductTypeTitles, { Id : this.props.Item.Type }).Name}</h4>
				}
				<div className="clearfix">
					{_.map(this.props.Item.Sections, function (section, i) {
						return (<RequestProductGroup
							Item={section}
							Index={i}
							key={"s" + i}
							SectionName={section.SectionName}
							Products={view.props.Products}
							Type={view.props.Index}
							Providers={view.props.Providers}
						/>)
					})}
				</div>
			</div>
		)
	}
}
RequestProductType = observer(RequestProductType);

class RequestProductGroup extends Component {
	render() {
		var view = this;

		return (
			<div className="clearfix">
				<h5>{this.props.SectionName}</h5>
				<div className="clearfix titles">
					<span className="col col20">Название</span>
					<span className="col col15">Мин. кол-во</span>
					<span className="col col15" title="Остаток в программе">Остаток в программе</span>
					<span className="col col15" title="Фактически на складе">Факт. на складе</span>
					{requestState.Operation === "process" && <span className="col col15">Заказ</span>}
					{requestState.Operation === "process" && <span className="col col20">Поставщик</span>}
				</div>
				<div className="clearfix">
					{_.map(this.props.Item.Products, function (product, i) {
						return (<RequestProductItem key={i}
							Item={product} Index={i} Type={view.props.Type}
							Section={view.props.Index} Providers={view.props.Providers} />)
					})}
				</div>
			</div>
		)
	}
}
RequestProductGroup = observer(RequestProductGroup);

class RequestProductItem extends Component {
	/**
	 * Сбор данных с формы редактирования продуктов заявки
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange(value, id) { requestState.collectProduct(id, value, this.props.Item.ProductId); }

	/** Удаление строки с продуктом */
	handleDelete(event) {
		event.preventDefault();
		requestState.ProductsAll = _.reject(requestState.ProductsAll, { ProductId : this.props.Item.ProductId });
	}

	render() {
		var view = this,
			item = this.props.Item,
			providers = _.filter(requestState.Providers, function (v) {
				return _.indexOf(v.Products, view.props.Item.Product) !== -1;
			});

		return (
			<div className="clearfix item">
				<span className="col col20">{this.props.Item.Product}</span>
				<span className="col col15">{setUnit(item.MinCount, item.UnitName)}</span>
				<span className="col col15">
					{setUnit(item.Actual, item.UnitName)}
					<i className="date">{formatDate(item.LastUpdate, ", DD.MM.YYYY")}</i>
				</span>
				{requestState.Operation === "new" &&
					<TextInput
						Id="Count"
						Value={item.Count}
						Type="number"
						Step="0.001"
						RowClass="col col15 nomargin"
						onChange={this.handleChange.bind(this)}
					/>
				}
				{requestState.Operation !== "new" &&
					<span className="col col15">{setUnit(item.Count, item.UnitName)}</span>
				}
				{requestState.Operation === "process" &&
					<TextInput
						Id="Order"
						Value={this.props.Item.Order}
						Type="number"
						Step="0.001"
						RowClass="col col15 nomargin"
						Required={true}
						onChange={this.handleChange.bind(this)}
					/>
				}
				{requestState.Operation === "process" &&
					<Selectize
						Id="ProviderId"
						Value={this.props.Item.ProviderId}
						List={toSelectList(providers)}
						RowClass="col col20 nomargin"
						onChange={this.handleChange.bind(this)}
					/>
				}
				{requestState.Operation === "process" &&
					<a href="/" className="icon remove" title="Удалить из заказа"
						onClick={this.handleDelete.bind(this)}><i></i></a>
				}
			</div>
		)
	}
}
RequestProductItem = observer(RequestProductItem);

export class ShopListPage extends Component {
	constructor(props) {
		super(props);

		shopListState.setList(undefined);

		this.state = {};

		this.getData();

		var model = this;
		getRequest("Products", { Active: true, Type: 1 }, function (data) {
			model.setState({ Products: data.Success ? data.Products : [] });
		});
	}

	getData() {
		shopListState.IsSendRequest = false;
		getRequest("ShopLists", {}, function (data) { shopListState.setLists(data); });
	}

	getItem(id) {
		getRequest("ShopList", { Id: id }, function (data) { shopListState.setList(data.Success ? data : undefined); });
	}

	setId(id) {
		if (!_.isUndefined(id)) {
			if (id !== -1) this.getItem(id);
		} else {
			shopListState.setList(undefined);
			this.getData();
		}
	}

	removeId(id) {
		if (_.isUndefined(id)) return false;

		var model = this,
			deleteItem = _.isEmpty(shopListState.Lists) ? "" : _.find(shopListState.Lists, { Id: id }),
			text = <span>Вы действительно хотите удалить закупку для <b key="b2">{deleteItem.Provider}</b>
				&nbsp;от <b key="b1">«{moment(deleteItem.Date).format("DD.MM.YYYY")}»</b>?</span>;

		confirmState.openConfirm(text, function () {
			getRequest("RemoveShopList", { Id: id }, function (data) { model.getData(); });
		});
	}

	changeState(id) {
		if (_.isUndefined(id)) return false;

		var model = this,
			item = _.isEmpty(shopListState.Lists) ? "" : _.find(shopListState.Lists, { Id: id }),
			text = <span>Вы действительно хотите отправить закупку для <b key="b2">{item.Provider}</b>
				&nbsp;от <b key="b1">«{moment(item.Date).format("DD.MM.YYYY")}»</b>?</span>;

		confirmState.openConfirm(text, function () {
			getRequest("SendShopList", { Id: id }, function (data) { model.getData(); });
		});
	}

	downloadList(id) { getFile("ShopListPDF", { Id: id }, "File.pdf"); }

	render() {
		var status = { 1: "Новый", 2: "Отправлен", 3: "Доставлен" },
			columns = {
				Date: {
					filterable: false, Header: "Дата создания",
					Cell: row => moment(row.original.Date).format("DD.MM.YYYY")
				},
				User: {}, Stock: {}, Provider: {}, Amount: {},
				Status: { filterable: false, Cell: row => status[row.original.Status] },
			};

		if (checkRights("ManagePurchase"))
			_.extend(columns, { View: {}, Edit: {}, Send: {}, DownLoad: {}, Remove: {} });

		return (
			<div className="page" id="ShopListsPage">
				{shopListState.IsSendRequest && _.isUndefined(shopListState.ShopList) &&
					<TableFilterPage
						Type="Purchase"
						CanManage={false}

						TableData={shopListState.Lists}
						TableColumns={columns}
						TableFilterable={true}

						setId={this.setId.bind(this)}
						removeId={this.removeId.bind(this)}
						changeState={this.changeState.bind(this)}
						downloadList={this.downloadList.bind(this)}

						getTrProps={(state, rowInfo, column) => {
							return { className: rowInfo && rowInfo.original.Status !== 1 ? "notactive" : "" };
						}}
					/>
				}
				{!_.isUndefined(shopListState.ShopList) &&
					<ShopListItem
						Products={this.state.Products}
						getItem={this.getItem.bind(this)}
						setId={this.setId.bind(this)}
					/>
				}
			</div>
		)
	}
}
ShopListPage = observer(ShopListPage);

class ShopListItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			// isSendRequest: false
		};

		getRequest("Provider", { Id: shopListState.ShopList.ProviderId }, function (data) {
			shopListState.setProvider(data.Success ? data : {});
		});
	}

	handleClose(event) {
		event.preventDefault();
		this.props.setId(undefined);
	}

	handleSubmit(event) {
		event.preventDefault();

		shopListState.ButtonLoading = true;
		getRequest("SaveShopList", shopListState.getSaveData(), function (data) {
			shopListState.ErrorCode = data.Success ? "SUCCESS_SAVE" : data.ErrorCode;
			shopListState.SuccessSave = data.Success;
			shopListState.ButtonLoading = false;
		});
	}

	render() {
		var style = { height: uistate.sectionHeight - 15 },
			provProd = _.isUndefined(shopListState.Provider) ? [] :
				_.map(shopListState.Provider.Products, function (v) { return v.ProductId; }),
			products = _.filter(this.props.Products, function (v) {
				return _.indexOf(provProd, v.Id) !== -1
			});

		return (
			<section className="clearfix item two-cols" id="ShopListItem">
				<form onSubmit={this.handleSubmit.bind(this)} >
					<ItemHeader
						Title={buttonTitles["EditShopList"]}
						Error={shopListState.ErrorCode}
						Loading={shopListState.ButtonLoading}
						CanManage={checkRights("ManagePurchase") && shopListState.ShopList.Status === 1}
						Success={shopListState.SuccessSave}
						handleClose={this.handleClose.bind(this)}
					/>
					<div className="clearfix section" style={style}>
						<div className="clearfix">
							<div className="col col70">
								<MainInfoList />
								{!_.isUndefined(shopListState.Provider) &&
									<ProductsBlock Products={products} />
								}
							</div>
							<div className="col col30 gray">
								{!_.isUndefined(shopListState.Provider) && <ProviderInfo />}
							</div>
						</div>
					</div>
				</form>
			</section>
		)
	}
}
ShopListItem = observer(ShopListItem);

class MainInfoList extends Component {
	render() {
		var status = { 1: "Новый", 2: "Отправлен поставщику", 3: "Доставлен" },
			amount = !_.isUndefined(shopListState.Provider) &&
				shopListState.ShopList.Amount < shopListState.Provider.MinAmount ? "min" : "";

		return (
			<div className="clearfix block">
				<div className="col col60">
					<div className="clearfix item">
						<span className="col col40 title">Склад</span>
						<span className="col col60">{shopListState.ShopList.Stock}</span>
					</div>
					<div className="clearfix item">
						<span className="col col40 title">Поставщик</span>
						<span className="col col60">{shopListState.ShopList.Provider}</span>
					</div>
					<div className="clearfix item">
						<span className="col col40 title">Сформировано</span>
						<span className="col col60">
							{shopListState.ShopList.User}, {moment(shopListState.ShopList.Date).format("DD.MM.YYYY")}
						</span>
					</div>
					<div className="clearfix item">
						<span className="col col40 title">Статус</span>
						<span className="col col60">{status[shopListState.ShopList.Status]}</span>
					</div>
				</div>
				<div className="col col40 amount">
					<div className="clearfix item">
						<span>Примерная сумма заказа</span>
						<span className={amount}>{getRubs(shopListState.ShopList.Amount)} руб.</span>
					</div>
					<div className="clearfix item">
						<span>Минимальная сумма заказа</span>
						<span>{getRubs(shopListState.ShopList.MinAmount)} руб.</span>
					</div>
				</div>
			</div>
		)
	}
}
MainInfoList = observer(MainInfoList);

class ProviderInfo extends Component {
	render() {
		var today = moment(),
			date = daysShortSun[today.format("d")],
			dayNumber = parseInt(today.format("d"), 10) === 0 ? 6 : parseInt(today.format("d"), 10) - 1;

		return (
			<div className="clearfix">
				{!_.isNull(shopListState.Provider.Contacts) &&
					<div className="clearfix block contacts">
						<h4>Контакты поставщика</h4>
						{_.map(shopListState.Provider.Contacts, function (contact, i) {
							return (<div className="clearfix item" key={"c" + i}>
								<h5>{contact.FIO}</h5>
								{!_.isEmpty(contact.Phone) && <p>{contact.Phone}</p>}
								{!_.isEmpty(contact.Email) && <p>{contact.Email}</p>}
							</div>)
						})}
					</div>
				}
				{!_.isNull(shopListState.Provider.WorkHours) &&
					<div className="clearfix block workdays">
						<h4>Время приема заказов</h4>
						<p className="comment">Сегодня: {date}, {today.format("DD.MM")}</p>
						{_.map(_.sortBy(shopListState.Provider.WorkHours, "Day"), function (day, i) {
							var active = dayNumber === day.Day ? "active" : "";

							return (<div className={"clearfix item " + active} key={"c" + i}>
								<span className="col col40">{daysLong[day.Day]}</span>
								<span className="col col60">с {day.StartTime} до {day.EndTime}</span>
							</div>)
						})}
					</div>
				}
			</div>
		)
	}
}
ProviderInfo = observer(ProviderInfo);

class ProductsBlock extends Component {
	handleAdd(event) {
		event.preventDefault();

		shopListState.Products = _.concat(shopListState.Products, _.clone(shopListState.ProductDummy));
	}

	render() {
		var view = this;

		return (
			<div className="clearfix block products">
				<h4>Заказ</h4>
				<div className="clearfix titles">
					<span className="col col35">Сырье</span>
					<span className="col col15">Кол-во</span>
					<span className="col col10">ЕИ</span>
					<span className="col col20">Цена (за ед.)</span>
					<span className="col col10">Мин.</span>
					<span className="col col10">НДС</span>
				</div>
				{_.map(shopListState.Products, function (product, i) {
					return (<ProductItem key={"pr" + shopListState.pCounter++} Item={product} Index={i}
						Products={view.props.Products} />)
				})}
				<a href="/" className="button icon add" onClick={this.handleAdd.bind(this)}>
					<i></i>Добавить сырье</a>
			</div>
		)
	}
}
ProductsBlock = observer(ProductsBlock);

class ProductItem extends Component {
	handleChange(value, id) { shopListState.collectProducts(id, value, this.props.Index); }

	handleDelete(event) {
		event.preventDefault();

		var index = this.props.Index;
		shopListState.Products = _.filter(shopListState.Products, function (v, i) { return i !== index; });
	}

	render() {
		var VAT = _.find(VATTitles, { Id: this.props.Item.VAT });

		return (
			<div className="clearfix item">
				<Selectize
					Id="ProductId"
					List={toSelectList(this.props.Products)}
					Value={this.props.Item.ProductId}
					RowClass="col col35"
					onChange={this.handleChange.bind(this)}
					Disabled={shopListState.ShopList.Status === 2}
				/>
				<TextInput
					Id="Count"
					Type="number"
					Step="0.001"
					Value={this.props.Item.Count}
					RowClass={"col col15 " + (this.props.Item.Count < this.props.Item.MinCount ? "min" : "")}
					onChange={this.handleChange.bind(this)}
					Disabled={shopListState.ShopList.Status === 2}
				/>
				<TextInput
					Id="UnitName"
					Value={this.props.Item.UnitName}
					RowClass="col col10"
					Disabled={true}
				/>
				<TextInput
					Id="Price"
					Value={getRubs(this.props.Item.Price, false, true)}
					RowClass="col col20"
					Disabled={true}
				/>
				<TextInput
					Id="MinCount"
					Value={this.props.Item.MinCount}
					RowClass="col col10"
					Disabled={true}
				/>
				<TextInput
					Id="VAT"
					Value={VAT ? VAT.Name : ""}
					RowClass="col col10"
					Disabled={true}
				/>
				<a href="/" className="button icon remove" onClick={this.handleDelete.bind(this)}><i></i></a>
			</div>
		)
	}
}
ProductItem = observer(ProductItem);