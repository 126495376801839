import { observable, decorate } from 'mobx';
import _, { isUndefined } from 'lodash';
import moment from 'moment';

import { authUserState } from './common';
import { clearEmptyParams, formatDate, getRubs } from '../functions';

class PayoutState {
	Staff = [];
	ButtonLoading = false;
	IsSendRequest = false;
	ErrorCode = "";

	Filter = {}
	StatFilter = {};
    PageType = "";
    
	User = undefined;
	UserShift = [];
	UserOrders = [];

	/**
	 * Установка дефолтных значений фильтра и страницы
     * @param {string} type – тип страницы
	 */
	setDefault(type) {
		this.Filter = {
			Date: formatDate("today", "YYYY-MM"),
			FilialId: _.head(authUserState.Filials).Id,
			IsCourier: type === "PayoutCourier"
        }
        
		this.PageType = type;
		this.setUser(undefined);

		this.Staff = [];
		this.ErrorCode = "";
	}

	/**
	 * Сбор данных с формы фильтра
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectFilter(id, value) { this.Filter[id] = value; }

	/** Проверка корректности данных запроса из формы
	 * @returns {boolean} флаг корректности данных
	 */
	validateFilter() {
		var isValid = true;

		if (this.Filter.Date === "") {
			this.ErrorCode = "DATE_REQUIRED";
			return false;
        }
        
        if (this.Filter.FilialId === "") {
			this.ErrorCode = "FILIAL_REQUIRED";
			return false;
		}

		return isValid;
	}

	/**Сбор данных фильтра для отправки запроса */
	getFilter() { return clearEmptyParams(_.clone(this.Filter), ["", -1]); }

	/**
	 * Разбор ответа от сервера со статистикой по сотрудникам
	 * @param {object} data 
	 */
	setList(data) {
		var roles = _.groupBy(data, "Role");
		this.Staff = _.flatten(_.map(roles, function(items, role) {
			return _.concat([{
				Name : role,
				Role : role,
				Accrual: _.sumBy(items, "Accrual"), 
				Payout: _.sumBy(items, "Payout"), 
				CanView : false
			}], _.sortBy(items, "Name"))
		}))

		this.IsSendRequest = true;
		this.ErrorCode = "";
		this.ButtonLoading = false;
	}

	/**
	 * Разбор данных пользователя
	 * @param {object} user ответ от сервера с выплатами пользователя
	 */
	setUser(user) {
		this.User = _.isUndefined(user) ? user : user;

		this.Accruals = _.isUndefined(user) ? [] : user.Accruals;
		this.Payouts = _.isUndefined(user) ? [] : user.Payouts;

		this.StatFilter = _.isUndefined(user) ? {} : {
			UserId : user.Id,
			FilialId : this.Filter.FilialId,
			DateFrom : _.maxBy(user.Accruals, "Date").Date,
			DateTo : formatDate("today", "r"),
			IsCourier : user.IsCourier,
			Details : true
		}
	}

	/**
	 * Сбор данных с формы фильтра по статистике
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectStatFilter(id, value) { this.StatFilter[id] = value; }

	/** Получение данных фильтра для отправки запроса */
	getStatFilter (type) {
		var data = _.clone(this.StatFilter);

		if (type === "ShiftReport") data = _.omit(data, "Details");
		if (type !== "ShiftReport") data = _.omit(data, "IsCourier");

		return data;
	}

	/**
	 * Разбор полученных данных для отображение блока со статистикой
	 * @return {[object]} Массив с данными о смене и основными показателями пользователя
	 */
	get userStat () {
		if (_.isUndefined(this.User)) return [];
		// TODO расчет сделан только под стату курьеров
		
		var store = this,
			shiftDates = _.map(this.UserShift, "ShiftDate"),
			deliveryDates = _.map(this.UserOrders, "Date"),
			dates = _.sortBy(_.union(shiftDates, deliveryDates));

		return _.map(dates, function(date) {
			var shift = _.find(store.UserShift, { ShiftDate : date }),
				isDates = shift && shift.ShiftOpen !== "" && shift.ShiftClose !== "",
				start = shift && shift.ShiftOpen !== "" ? moment(shift.ShiftOpen) : "",
				end = shift && shift.ShiftClose !== "" ? moment(shift.ShiftClose) : "",
				order = _.find(store.UserOrders, { Date : date });

			return {
				ShiftDate : date,
				Orders : order ? order.Count : 0,
				Time : isDates ? end.diff(start, "minutes") : 0
			}
		})
	}

	/**
	 * Разбор нового/существующего начисления
	 * @param {object} accrual информация о существующем начислении
	 */
	setAccrual(accrual) {
		this.Accrual = isUndefined(accrual) ? undefined : {
			Id : accrual.Id,
			UserId : this.User.Id,
			FilialId : this.Filter.FilialId,
			SalaryDate : accrual.Id === -1 ? formatDate("today", "r") : accrual.SalaryDate,
			PaymentType : accrual.Id === -1 ? 1 : accrual.PaymentType,
			Amount : accrual.Id === -1 ? "" : getRubs(accrual.Amount),
			Description : accrual.Id === -1 ? "" : accrual.Description,
			Date : accrual.Id === -1 ? formatDate("today", "r") : accrual.Date
		};

		this.ErrorCode = "";
	}

	/**
	 * Сбор данных с формы начисления
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectAccrual (id, value) { this.Accrual[id] = value; }

	/** Обработка данных для создания начисления */
	getAccrual () {
		var data = _.clone(this.Accrual);
		data.Amount = data.Amount*100;

		return data;
	}

	/**
	 * Обработка данных для открытия выплат по начислениям
	 * @param {boolean} active открыть/закрыть окно
	 */
	setPayments(active) {
		var payments = active ? _.map(this.Accruals, function (item) {
				return ({
					Id : item.Id,
					Name : "Начисление от " + formatDate(item.Date) + " на сумму " + getRubs(item.Amount) 
						+ " за " + formatDate(item.SalaryDate),
					PaymentType : item.PaymentType
				})
			}) : [];

		this.Payments = _.groupBy(payments, "PaymentType");
		this.Payout = active ? {
			Ids : [],
			FilialId : this.Filter.FilialId,
			POSId : this.getDefaultPOS(this.Filter.FilialId)
		} : undefined;
		this.ErrorCode = "";
	}

	/**
	 * Получение точки продаж для выплат по филиалу
	 * @param {number} filialId ID филилала
	 */
	getDefaultPOS (filialId) {
		var filial = _.find(authUserState.Filials, { Id : filialId }),
			poses = filial ? filial.POS : [],
			payoutPos = _.find(poses, { Payout : true });

		return _.isEmpty(poses) ? -1 : payoutPos ? payoutPos.Id : _.head(poses).Id;
	}

	/**
	 * Сбор данных с формы выплат
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectPayout(id, value) {
		if (/Ids/.test(id)) {
			var item = parseInt(id.split("_")[1], 10);

			if (item && value) this.Payout.Ids = _.concat(this.Payout.Ids, item);
			else if (item) this.Payout.Ids = _.without(this.Payout.Ids, item);
		} else this.Payout[id] = value;

		if (/FilialId/.test(id)) this.Payout.POSId = this.getDefaultPOS(value);
	}

	/** Сборка данных для отправки запроса на выплаты */
	getPayout() {
		var data = _.clone(this.Payout);
		data.Ids = data.Ids.join(",");

		//TODO Убрать при полной интеграции
		var filial = _.find(authUserState.Filials, { Id : data.FilialId }),
			poses = filial ? filial.POS : [],
			pos = _.find(poses, { Id : data.POSId });
		data.POS = pos ? pos.Name : "";

		return data;
	}


	collectSalaryCalc (id, value) {
		return 0;
	}

	get salaryCalc () {
		return 0;
	}
}
decorate(PayoutState, {
	Staff: observable,
	Filter: observable,
	PageType: observable,

	IsSendRequest: observable,
	ButtonLoading: observable,
	ErrorCode: observable,

	User: observable,
	Accrual: observable,
	Accruals: observable,
	Payouts: observable,
	Payments: observable,
	Payout : observable,

	StatFilter: observable
});
export const payoutState = new PayoutState();

