import { observable, decorate } from 'mobx';
import _ from 'lodash';
import { authUserState } from './common';
import { formatDate, parsePhoneMask } from '../functions';
import moment from 'moment';

class AccountState {
	// TipsURL = "http://localhost:3001/tips"
	TipsURL = "https://lk.ectrum.ru/tips/"

	Motivations = [
		{ Id : 1, Title : "Поздравляем! Вы - лучший из лучших! Продолжайте в том же духе и достигайте новых вершин!", Rel : "best" },
		{ Id : 2, Title : "Вы - настоящий чемпион! Ваш труд и усердие не остались незамеченными. Продолжайте вперед!", Rel : "best" },
		{ Id : 3, Title : "Отличная работа! Вы заслужили звание лидера. Будьте вдохновением для всех вокруг!", Rel : "best" },
		{ Id : 4, Title : "Вы отлично справляетесь! Не останавливайтесь на достигнутом, еще многое впереди!", Rel : "first" },
		{ Id : 5, Title : "Вы входите в 30% лучших сотрудников! Продолжайте двигаться вперед и улучшать свои результаты!", Rel : "first" },
		{ Id : 6, Title : "Хорошая работа! Ваше старание и упорство приносят результаты. Продолжайте развиваться и расти!", Rel : "first" },
		{ Id : 7, Title : "Не расстраивайтесь! У вас есть возможность улучшить свои результаты. Не сдавайтесь, а продолжайте работать над собой!", Rel : "last" },
		{ Id : 8, Title : "Неудачи - это временные трудности. Поверьте в себя, учтите опыт и двигайтесь дальше к своим целям!", Rel : "last" },
		{ Id : 9, Title : "Важно помнить, что даже неудачи могут быть полезными уроками. Примите их как шанс для роста и развития!", Rel : "last" },
		{ Id : 10, Title : "У вас хороший результат! Продолжайте улучшать свои навыки и стремиться к лучшему!", Rel : "middle" },
		{ Id : 11, Title : "Вы на верном пути! Сохраняйте мотивацию и нацеленность на достижение своих целей. У вас все получится!", Rel : "middle" },
		{ Id : 12, Title : "Отличная работа! Ваше усердие и настойчивость помогут вам преодолеть любые препятствия. Продолжайте двигаться вперед!", Rel : "middle" },
	]

	/** Установка дефолтного состояния страницы */
	setDefault() {
		var user = authUserState.User;

		this.Account = {
			Name: [user.LastName, user.FirstName, user.Patronymic].join(" "),
			PhotoURL: user.PhotoURL,
			Photo : null,
			Merchants: _.map(authUserState.Merchants, (v) => {
				return {
					Name : v.Name,
					Roles : _.map(_.filter(authUserState.User.Roles, { MerchantId : v.Id }), "Name").join(", "),
					Filials : _.map(_.filter(authUserState.User.Filials, { MerchantId : v.Id }), "Name").join(", ")
				}
			})
		};

		this.RateFilter = {
			DateFrom: formatDate(moment().subtract(1, "M"), "r"),
			DateTo : formatDate("today", "r"),
			IsIndividual : true
		}

		this.Dates = { 
			DateFrom : formatDate(moment().subtract(30, "days"), "r"), 
			DateTo : formatDate(moment().add(1, "days"), "r") 
		};

		this.Recipient = undefined;
		this.Pass = undefined;
		this.Limits = undefined;
		this.ConfirmURL = undefined;
		this.PayOutData = undefined;

		this.ButtonLoading = false;
		this.ErrorCode = "";
		this.SuccessSave = false;

		this.ChangePhoto = false;
	}

	/**
	 * Разбор данных от сервера с профилем пользователя
	 * @param {object} user
	 */
	setAccount(data) {
		if (data.Success) {
			this.Account.Id = data.Id;
			this.Account.Phone = parsePhoneMask(data.Login);
			this.Password = data.Password;
			
			this.Recipient = !data.TipsRecipient ? undefined :
				_.omit(_.clone(data), ["Success", "ErrorCode", "Phone", "Password", "Transactions"]);

			this.Transactions = data.TipsRecipient && !_.isNull(data.Transactions)
				? data.Transactions : [];
		}
		this.ChangePassword = undefined;

		this.ButtonLoading = false;
		this.ErrorCode = "";
		this.SuccessSave = false;
	}

	get UserRating () {
		if (_.isUndefined(this.UsersRate) || _.isEmpty(this.UsersRate.Users) || _.isUndefined(this.Account.Id)) return [];
		
		var rate = [],
			currentUser = _.find(this.UsersRate.Users, { UserId : authUserState.User.Id });

		if (_.isUndefined(currentUser)) return [];
		
		if (currentUser.Rate === 1) {
			rate.first = {
				FIO : this.getFio(currentUser),
				Photo : currentUser.PhotoURL,
			}
			if (this.UsersRate.TotalRate > 1)
				rate.last = { Rate : this.UsersRate.TotalRate }
		} else if (currentUser.Rate === this.UsersRate.TotalRate) {
			rate = {
				first : {
					FIO : this.getFio(this.UsersRate.Users[0]),
					Photo : this.UsersRate.Users[0].PhotoURL
				},
				last : {
					Rate : currentUser.Rate,
					FIO : this.getFio(currentUser),
					Photo : currentUser.PhotoURL
				}
			}
		} else {
			rate = {
				first : {
					FIO : this.getFio(this.UsersRate.Users[0]),
					Photo : this.UsersRate.Users[0].PhotoURL
				},
				last : {
					Rate : this.UsersRate.TotalRate
				},
				middle : {
					Rate : currentUser.Rate,
					FIO : this.getFio(currentUser),
					Photo : currentUser.PhotoURL,
					Position : (1 - currentUser.Rate/this.UsersRate.TotalRate)*100 + "%"
				}
			}
		}

		return rate;
	}

	/** Получение текущего рейтинга пользователя */
	get CurrentUserRating () {
		if (_.isUndefined(this.UsersRate) || _.isEmpty(this.UsersRate.Users) || _.isUndefined(this.Account.Id)) return undefined;
		
		var currentUser = _.find(this.UsersRate.Users, { UserId : authUserState.User.Id });

		if (_.isUndefined(currentUser)) return undefined;

		if (currentUser.Rate === 1) return "best";
		else if (currentUser.Rate <= this.UsersRate.TotalRate*0.3) return "first";
		else if (currentUser.Rate >= this.UsersRate.TotalRate*0.7) return "last";
		else return "middle";
	}

	/** Получение мотивационной фразы для чек листа */
	get MotivationTitle () {
		if (_.isUndefined(this.CurrentUserRating) || this.CurrentUserRating === "") return "";
		var prase = _.filter(this.Motivations, { Rel : this.CurrentUserRating })

		return _.sample(prase).Title;
	}

	/** Формирование списка проблемных задач
	 * возвращает 3 задачи, худшие по соотношению поставлено/выполнено */
	get TaskRating () {
		if (_.isEmpty(this.TasksRate) || _.isUndefined(this.Account.Id)) return [];

		var tasks = _.filter(this.TasksRate, (v) => { return v.Total > v.Done; });

		return _.map(_.takeRight(tasks, 3), (v) => {
			return _.extend(v, {
				Failed : v.Total - v.Done - v.Expired,
				TotalDone : v.Done + v.Expired
			});
		})
	}

	/** Функция, возвращающая инициалы пользователя
	 * @param {{ FirstName: string, LastName : string }} объект пользователя
	 * @returns {string} инициалы
	*/
	getFio (user) {
		if (_.isUndefined(user) || _.isUndefined(user.FirstName) || user.FirstName === "") return "";
		return user.FirstName[0] + (user.LastName !== "" ? user.LastName[0] : "")
	}

	/** Формирование 6-значного кода пользователя */
	get UserCode () {
		return !_.isUndefined(this.Recipient) ? _.padStart(this.Recipient.UserCode, 6, "0") : "";
	}

	/** Формирование ссылки на оплату */
	get PaymentLink () {
		return _.isUndefined(this.Recipient) || this.UserCode === "" ? ""
			: this.TipsURL + "?UserCode=" + this.UserCode;
	}

	/** Флаг, что можно привязывать номер телефона */
	get CanConfirmPhone () { 
		return !_.isUndefined(this.Recipient) && this.Recipient.TipsState < 2;
	}
	/** Флаг, что можно подтверждать личность по паспорту */
	get CanConfirmPassport () {
		return !_.isUndefined(this.Recipient) && this.Recipient.TipsState === 1 && this.Recipient.TipsActive === 1;
	}
	/** Флаг, что можно выводить деньги на карту */
	get CanPayOut () {
		return !_.isUndefined(this.Recipient) && this.Recipient.TipsState === 1 && _.indexOf([2,5], this.Recipient.TipsActive) !== -1;
	}
	/** Флаг, что можно получать данные карты */
	get CanGetCard () {
		return !_.isUndefined(this.Recipient) && this.Recipient.TipsState === 1 && this.Recipient.TipsActive >= 1;
	}

	/** Флаг, что остаток приближается к лимиту */
	get IsLimit () {
		return _.isUndefined(this.Limits) || _.isUndefined(this.Recipient) ? false :
			this.Recipient.Balance/this.Limits.MaxBalance > 0.9
	}

	/**
	 * Сбор данных с формы редактирования пользователя
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectUser(value, id) { this.Account[id] = value; }

	/**
	 * Сбор данных с формы редактирования получателя
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectRecipient (value, id) { this.Recipient[id] = value; }

	/**
	 * Сбор данных пользователя для отправки на сервер
	 * @returns {object}
	 */
	getSaveData() {
		var account = _.clone(this.Account),
			recipient = _.clone(this.Recipient);

		return {
			Id : account.Id,
			Photo : _.isObject(account.Photo) ? account.Photo.files : account.Photo === -1 ? -1 : null,
			FIO : authUserState.HasTips && !_.isUndefined(recipient) ? recipient.FIO : "",
			SavingUpFor : authUserState.HasTips && !_.isUndefined(recipient) ? recipient.SavingUpFor : "",
		}
	}

	/** ------ БЛОК ВЫВОДА СРЕДСТВ ------ */
	/** Проверка введенных данных
	 * @return {object} данные корректны
	 */
	validateAmount() {
		if (_.isUndefined(this.PayOutData)) return true;
		this.PayOutData.ErrorCode = "";

		var amount = this.PayOutData.Amount * 100;
		if (amount < 10000 || amount > 1500000) {
			this.PayOutData.ErrorCode = "WRONG_PAYOUT_AMOUNT";
			return false;
		}

		return true;
	}

	/** ------ БЛОК СМЕНЫ ПАРОЛЯ ------ */
	/**
	 * Установка данных для смены пароля
	 * @param {boolean} open открыть/закрыть окно смены пароля
	 */
	setChangePassword (open) {
		this.ChangePassword = open ? {
			// OldPassword : this.Password,
			OldPassword : "",
			NewPassword : "",
			RepeatNewPassword : ""
		} : undefined;

		this.ErrorCode = "";
		this.ButtonLoading = "";
	}

	/**
	 * Сбор данных с формы смены пароля
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectPass(value, id) { this.ChangePassword[id] = value; }

	/**
	 * Проверка корректности смены пароля
	 * 1. Повтор нового пароля должен быть корректным
	 * 2. Длина пароля не может быть меньше 8 символов
	 * 3. Пароль может содержать только символы A-Za-z0-9()*!$@?%
	 * @returns {boolean} флаг корректности заполнения
	 */
	validatePass() {
		this.ErrorCode = "";

		if (this.ChangePassword.NewPassword !== this.ChangePassword.RepeatNewPassword) {
			this.ErrorCode = "WRONG_NEW_PASSWORD";
			return false;
		}

		if (this.ChangePassword.NewPassword.length < 8) {
			this.ErrorCode = "WRONG_PASS_FORMAT";
			return false;
		}

		if (!/^[A-Za-z0-9()*!$@?%\_]*$/.test(this.ChangePassword.NewPassword)) {
			this.ErrorCode = "WRONG_PASS_FORMAT";
			return false;
		}

		return true;
	}

	/**
	 * Сбор данных пользователя для отправки на сервер
	 * @returns {object}
	 */
	getPassData() {
		return _.omit(_.clone(this.ChangePassword), "RepeatNewPassword")
	}
}
decorate(AccountState, {
	Account: observable, 		// Информация о данных пользователя
	Recipient: observable, 		// Информация о получателе чаевых
	Password: observable, 		// Текущий пароль пользователя
	Transactions: observable, 	// Массив транзакций пользователя по чаевым
	Limits : observable, 		// Информация о лимитах виртуальной карты получателя чаевых
	ChangePassword: observable, // Блок с изменением пароля
	Dates : observable,			// Даты для фильтра транзакций
	ChangePhoto : observable,	// Флаг открытия окна смены фото

	ConfirmURL: observable, 	// Ссылка для подтверждения номера телефона
	CardURL: observable, 		// Ссылка для просмотра полной информации по карте
	PayOutData: observable, 	// Данные для вывода денег

	UsersRate : observable,		// Рейтинг пользователей по выполнению чек листов
	TasksRate : observable,		// Рейтинг задач

	ButtonLoading: observable, 	// Состояние лоадера на кнопке
	ErrorCode: observable, 		// Код ошибки
	SuccessSave: observable 	// Флаг успешного сохранения информации пользователя
});
export const accountState = new AccountState();