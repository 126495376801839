import { observable, decorate, computed } from 'mobx';
import _ from 'lodash';
import moment from 'moment';

import { uistate, authUserState } from './common';

class StatState {
	Stats = [];
	ButtonLoading = false;
	IsSendRequest = false;
	ErrorCode = "";
	Filter = {
		DateFrom: moment().format("YYYY-MM-DD"),
		DateTo: moment().format("YYYY-MM-DD"),
		StockIds: [],
		FilialIds: [],
		SectionIds: [],
		ProductIds: [],
		ProviderIds: [],
		CityId: "",
		Summary: false,
		OnlySales: false,
		ShowComposition: false,
		Type: "",
		OutForm: "json",
	}
	DatePreset = "today";
	PageType = "";
	FilterOpen = false;

	/**
	 * Установка дефолтного состояния страниц статистики, в зависиомсти от типа страницы
	 * @param {string} type тип страницы
	 */
	setFilter(type) {
		this.Filter = {
			DateFrom: moment().format("YYYY-MM-DD"),
			DateTo: moment().format("YYYY-MM-DD"),
			StockIds: [],
			FilialIds: [],
			SectionIds: [],
			ProductIds: [],
			ProviderIds: [],
			CityId: type === "CostPrice" ? authUserState.City : "",
			Summary: type === "PurchaseHistory" || type === "WriteoffStat" ? false : "",
			OnlySales: type === "ProductsStat" ? false : "",
			ShowComposition: type === "CostPrice" ? true : "",
			Type: type === "CostPrice" ? 3 : type === "RawMovement" || type === "RevisionsStat" || type === "WriteoffStat" ? 1 : "",
			OutForm: "json",
		}
		this.DatePreset = "today";
		this.PageType = type;
		this.Stats = [];
		this.ErrorCode = "";
		this.FilterOpen = false;
	}

	/**
	 * Сбор данных с формы фильтра
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectFilter(id, value) {
		if (/Summary/.test(id)) this.Filter.Summary = value;
		else if (/OnlySales/.test(id)) this.Filter.OnlySales = value;
		else this.Filter[id] = value;
	}

	/**
	 * Валидация параметров в фильтре
	 * @return {boolean} флаг корректности данных
	 */
	validateFilter() {
		var isValid = true;

		if (this.Filter.DateFrom === "" || this.Filter.DateTo === "") {
			this.ErrorCode = "STAT_DATE_REQUIRED";
			return false;
		}

		if (this.PageType === "CostPrice" && (this.Filter.CityId === "" || this.Filter.CityId === -1)) {
			this.ErrorCode = "STAT_CITY_REQUIRED";
			return false;
		}

		return isValid;
	}

	/** 
	 * Обработка параметров фильтра для отправки на сервер
	 * @returns {object} данные фильтра
	 */
	getFilter() {
		return _.omitBy(_.clone(this.Filter), function (v,i) { 
			return v === "" || (_.isArray(v) && _.isEmpty(v)); });
	}

	/**
	 * Разбор ответа от сервера с данными статистики
	 * @param {object} data ответ от сервера
	 */
	setStat(data) {
		this.Stats = data.Success ? _.map(data.Products, function(v) {
			return _.extend(v, { Name : v.Section === "" && v.Product === "" ? "ИТОГО" : v.Product === "" 
				? v.Section : v.Product });
		}) : [];
		this.IsSendRequest = true;
		this.ErrorCode = "";
		this.ButtonLoading = false;
	}

	// TODO – выпилить - не используется
	get tableHeight () {
		var filterCloseHeight = 52 - 5,
			filterOpenHeight = {
				ProductsStat : 142 - 5,
				CostPrice : 106 - 5,
				RawMovement : 160 - 5,
				PurchaseHistory : 160 - 5,
				RevisionsStat : 160 - 5
			}
		return uistate.WindowDimensions.height - uistate.HeaderHeight - uistate.PaddingHeight 
			- uistate.TitleHeight - (this.FilterOpen ? filterOpenHeight[this.PageType] : filterCloseHeight);
	}
}
decorate(StatState, {
	Stats: observable,
	Filter: observable,
	DatePreset: observable,
	PageType: observable,
	IsSendRequest: observable,
	ButtonLoading: observable,
	ErrorCode: observable,
	FilterOpen: observable,

	tableHeight: computed
});
export const statState = new StatState();

class ImportState {
	WrongProducts = [];
	Import = undefined;
	ImportType = "file";
	ErrorCode = "";
	ButtonLoading = false;
	SuccessSave = false;
	ErrorProducts = [];
	ErrorFilials = [];

	Products = [];

	ProductDummy = {
		Id : "",
		ProductId : -1,
		Count : "",
		ErrorCode : "",
		VendorCode : ""
	}

	setImport(importB) {
		this.Import = _.isUndefined(importB) ? undefined 
			: { Date : moment().format("YYYY-MM-DD"), FilialId : -1, File :  null };

		this.Products = []

		this.ErrorCode = "";
		this.ButtonLoading = false;
		this.SuccessSave = false;
		this.ImportType = "file";
	}

	setErrors(data) {
		this.ErrorProducts = data.Success && !_.isNull(data.WrongProducts) 
			? _.map(data.WrongProducts, function(v) { return v }) : [];
		this.ErrorFilials = [];
		this.Products = [];

		if (data.Success && !_.isNull(data.WrongProducts)) this.filterErrors();
	}

	filterErrors() {
		var store = this;
		
		this.ErrorFilials = _.uniqWith(_.map(_.clone(this.ErrorProducts), function (v) { 
			return { FilialId : v.FilialId, Date : v.Date}}), _.isEqual);
		this.Products = _.filter(this.ErrorProducts, function (v) { 
			return v.FilialId === store.Import.FilialId && v.Date === store.Import.Date });
	}

	collectImport(id, value) { 
		this.Import[id] = value; 

		this.filterErrors();
	}

	collectProducts(id, value, index) {
		this.Products[index][id] = value;
	}

	getSaveData() {
		var data = _.clone(this.Import),
			ids = [],
			index = 0;

		if (this.ImportType === "manual") {
			data = _.omit(data, ["File"]);
			_.each(this.Products, function (product) {
				var name = "Products." + index + ".";

				if (product.ProductId !== -1 && product.ProductId !== "") {
					data[name + "Id"] = product.ProductId;
					data[name + "Count"] = product.Count;

					if (product.Id !== "") ids = _.concat(ids, product.Id);
					
					index++;
				}
			});
		} else data.File = data.File.files;

		return { Data : data, Ids : ids };
	}
}
decorate(ImportState, {
	WrongProducts: observable,
	Import: observable,
	ImportType: observable,
	ErrorCode: observable,
	SuccessSave: observable,
	ButtonLoading: observable,
	Products: observable,

	ErrorProducts: observable,
	ErrorFilials: observable

});
export const importState = new ImportState();

class PurchaseSummaryState {
	Filter = {
		DateTo : moment().format("YYYY-MM"),
		FilialId : -1,
		Period : 6
	}
	Stat = [];
	IsSendRequest = false;

	setFilter() { this.Filter = { DateTo : moment().format("YYYY-MM"), FilialId : -1, Period : 6 } }
	collectFilter(id, value) { this.Filter[id] = value; }
	getFilterData() { return _.clone(this.Filter); }

	setStat(data) {
		var date = this.Filter.Date,
			monthRange = _.map(_.rangeRight(6), function (v) {
				return _.clone(moment(date)).subtract(v, "months").format("YYYY-MM");
			}),
			sections = data.Success ? _.groupBy(data.Products, "Section") : [],
			summary = { Name : "ИТОГО" },
			stat = [];

		if (data.Success) {
			_.each(monthRange, function(v) {
				var amount = _.sum(_.map(_.filter(data.Products, { Date : v + "-01" }), "Amount"))
				summary = _.extend(summary, {["Amount_" + v] : amount });
			});

			stat = _.map(sections, function(section, name) {
				var s = { Name : name }
				
				_.each(monthRange, function(v) {
					var product = _.find(data.Products, { Section : name, Date : v + "-01" }),
						month = summary["Amount_" + v];

					s["Amount_" + v] = product ? product.Amount : 0
					s["Share_" + v] = product && month !== 0 ? product.Amount*100/month : 0
				});

				return s;
			});
		}

		this.Stats = !data.Success ? [] : _.concat(stat, summary)
		this.IsSendRequest = true;
	}

	get tableHeight () {
		var filterCloseHeight = 52 - 5;
			
		return uistate.WindowDimensions.height - uistate.HeaderHeight - uistate.PaddingHeight 
			- uistate.TitleHeight - filterCloseHeight;
	}
}
decorate(PurchaseSummaryState, {
	Filter : observable,
	Stats: observable,
	IsSendRequest: observable,

	tableHeight: computed
});
export const purchaseSummaryState = new PurchaseSummaryState();