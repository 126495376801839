/**
 * /pages/Order_process.js – Работа с производством заказов поварами
 * /stores/orders.js – Стор для реализации бизнес логики
 * 
 * Список заказов на приготовление доступен если
 * - у пользователя есть право производить заказы «CanReleaseOrder»
 * - у пользователя открыта смена в филиале, на который оформлен заказ
 * - заказ находится в статусе, у которого есть свойство «Производство заказа» (В производстве) или в статусе перед ним (Подтвержден)
 * 
 * При загрузке страницы выполняется запрос «CookingOrders» — получение заказов на производство
 * - Получить заказы можно только на «сегодня» или на «завтра» (параметр Date)
 * 
 * Также для справочников вызываются следующие методы один раз при загрузке страницы
 *  - «WorkShops» — список цехов для фильтра и для отображения в карточке товара
 *  - «OrderStatuses» — список статусов заказа для смены статусов и отображения эталонного времени
 *  - «Brands» — список брендов для отображения логотипа на карточке заказа
 * 
 * Производство товаров в заказе:
 *  - Производство заказ происходит в два этапа — старт производства товара и заверение
 *  - Старт производства выполняется с помощью запроса «StartReleaseProduct» — в параметрах передается список продуктов и флаг старта
 *  -- При старте производства первого продукта заказ меняет статус на бВ производстве (по свойству)
 *  — Отмена старта производства также выполняется запросом «StartReleaseProduct» с флагом отмена старта
 *  -- Отменить старт начала выпуска продукта может только тот же пользователь, что начал выполнение
 *  - Завершение приготовления/отмена выпуска продукта выполняется запросом «ReleaseProduct»
 *  -- Параметры — список продуктов, номер заказа и флаг выпустить или отменить выпуск
 * 
 * Смена статуса заказа на «Приготовлен» выполняется командой «ChangeOrderStatus»
 */

import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import '../styles/order.css';

import moment from 'moment';
import 'moment/locale/ru';
import Parser from 'html-react-parser';

import { checkRights, toSelectList, getRequest, formatDate, setLabel, getHourTime } from '../functions';
import { dashboardsTitles, PersonLabels, errors } from '../dictionary';

import { Selectize, ButtonGroup } from '../forms/FormItems';

import { confirmState, authUserState, errorState, uistate } from '../stores/common';

import { processOrderState } from '../stores/orders';
import { MenuItem, MenuItemPhoto } from './ProductionMenu';

export class OrdersCookPage extends Component {
	constructor(props) {
		super(props);

		processOrderState.setDefault();
		this.getData();

		this.state = {}

		getRequest("WorkShops", {}, function (data) { 
			processOrderState.WorkShops = data.Success ? data.WorkShops : [];
		});
		getRequest("Brands", {}, function (data) { processOrderState.Brands = data.Success ? data.Brands : []; });
		getRequest("OrderStatuses", {}, function (data) {
			processOrderState.OrderStatuses = data.Success ? data.OrderStatuses : [];
		});

		var model = this;
		if (_.isUndefined(processOrderState.OrderInterval))
			processOrderState.OrderInterval = setInterval(function () {
				model.getData();
			}, 6000);
	}

	/** API запрос на получение списка заказов для повара */
	getData() {
		getRequest("CookingOrders", processOrderState.getFilterData(), function (data) {
			processOrderState.setOrders(data);
		});
	}

	/**
	 * Сбор данных с формы поиска
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleFilterChange (value, id) {
		if (/WorkShopId/.test(id)) processOrderState.WorkShopId = value === "" ? -1 : value;
		else {
			processOrderState.collectFilter(id, value);
			this.getData();
		}
	}

	/** 
	 * API запрос «CookingTechnique» на получение техники приготовления продукта
	 * @param {number} id ID продукта
	 */
	getTechnique(id) {
		getRequest("CookingTechnique", { Id : id }, function(data) {
			processOrderState.setMenuCard(data.Success ? data : undefined);
		});
	}

	render() {
		var canRelease = checkRights("CanReleaseOrder") && authUserState.Shift.Active;

		return (
			<div className="page" id="OrdersCookPage">
				<section className="">
					<div className="head clearfix cook">
						<h2>{dashboardsTitles[this.props.Type] || this.props.Type}</h2>
						{canRelease && <OrderFilter handleChange={this.handleFilterChange.bind(this)} />}
					</div>
					{!canRelease && <p className="noSearch">{errors.SHIFT_CLOSE}</p>}
					{canRelease && processOrderState.IsSendRequest && _.isEmpty(processOrderState.filteredOrders) && 
						<p className="noSearch">{errors.EMPTY_RESPONSE}</p>
					}
					{canRelease && !_.isEmpty(processOrderState.filteredOrders) && 
						<OrderCookTable 
							getData={this.getData.bind(this)} 
							getTechnique={this.getTechnique.bind(this)}
						/>
					}
					{!_.isUndefined(processOrderState.MenuCard) &&
						<OrderCookMenu
							getTechnique={this.getTechnique.bind(this)}
						/>
					}
					{!_.isUndefined(processOrderState.ProductPhoto) &&
						<MenuItemPhoto
							Photo={processOrderState.ProductPhoto}
							handleOpenPhoto={processOrderState.setProductPhoto.bind(processOrderState)}
						/>
					}
				</section>
			</div>
		)
	}
}
OrdersCookPage = observer(OrdersCookPage);

class OrderFilter extends Component {
	render() {
		var dateList = [
				{ Id: formatDate(authUserState.Shift.Date, "r"), Name: "Сегодня" },
				{ Id: formatDate(moment(authUserState.Shift.Date).add(1, "d"), "r"), Name: "Завтра" }
			];

		return (
			<form className="clearfix filter">
				<ButtonGroup
					Id="ShiftDate"
					List={dateList}
					Value={processOrderState.Filter.ShiftDate}
					RowClass="col"
					Type="date"
					onChange={this.props.handleChange}
				/>
				<Selectize
					Id="WorkShopId"
					Placeholder="Цех приготовления"
					List={toSelectList(processOrderState.WorkShops)}
					Value={processOrderState.WorkShopId}
					RowClass="col col1_3"
					isSearchable={false}
					onChange={this.props.handleChange}
				/>
			</form>
		)
	}
}
OrderFilter = observer(OrderFilter);

class OrderCookTable extends Component {
	render() {
		var view = this,
			columns = uistate.IsTablet ? 2 : 3,
			groups = _.groupBy(processOrderState.filteredOrders, function (item) { 
				return item.Index % columns; });

		return (
			<div className={"clearfix list " + (uistate.IsTablet ? "tablet" : "")}>
				{_.map(groups, function (orders, group) {
					return (<div className={"col column col1_" + columns} key={group}>
						{_.map(orders, function (item, i) {
							var active = item.Id === processOrderState.OrderId;

							if (active) return (<OrderCookTableItemActive Item={item} key={i}
								getData={view.props.getData}
								getTechnique={view.props.getTechnique}
							/>)
							else return (<OrderCookTableItem Item={item} key={i} />)
						})}
					</div>)
				})}
			</div>
		)
	}
}
OrderCookTable = observer(OrderCookTable);

class OrderCookTableItem extends Component {
	/** Выбор активного заказа */
	setOrder(event) {
		event.preventDefault();
		processOrderState.OrderId = this.props.Item.Id;
	}

	render() {
		var item = this.props.Item,
			style = { borderColor : this.props.Item.StatusColor }

		return (
			<div className={"item"} onClick={this.setOrder.bind(this)}>
				<OrderCookItemHeader Item={this.props.Item} />
				<div className="body clearfix" style={style}>
					<div className={"products clearfix"}>
						{_.map(item.Products, function (product, i) {
							return(<OrderCookItemProduct key={i} Item={product} IsActive={false} />)
						})}
					</div>
					<OrderCookItemComment Item={this.props.Item} />
				</div>
			</div>
		)
	}
}
OrderCookTableItem = observer(OrderCookTableItem);

class OrderCookTableItemActive extends Component {
	/**
	 * Обработка клика выпуска проудкта
	 * @param {number} id ID выпускаемого продукта
	 * @param {boolean} active выпуск/отмена выпуска
	 */
	handleRelease(id, active) {
		if (active) this.releaseProduct([id], active);
		else {
			var model = this,
				product = _.find(this.props.Item.Products, { ProductId: id }),
				text = <span>Вы действительно хотите отменить выпуск
				&nbsp;<b key="b1">«{product ? product.ProductName : ""}»</b>
				&nbsp;в заказе <b key="b2">№{this.props.Item.OrderNumber}</b>?</span>

			confirmState.openConfirm(text, function () { model.releaseProduct([id], active) });
		}
	}

	/**
	 * Обработка клика начала выпуска продукта
	 * @param {number} id ID выпускаемого продукта
	 * @param {boolean} active выпуск/отмена старта выпуска
	 */
	handleStart(id, active) {
		if (active) this.startReleaseProduct([id], active);
		else {
			var model = this,
				product = _.find(this.props.Item.Products, { ProductId: id }),
				text = <span>Вы действительно хотите отменить начало производства
				&nbsp;<b key="b1">«{product ? product.ProductName : ""}»</b>
				&nbsp;в заказе <b key="b2">№{this.props.Item.OrderNumber}</b>?</span>

			confirmState.openConfirm(text, function () { model.startReleaseProduct([id], active) });
		}
	}

	/** Смена статуса заказа */
	handleStatus() { this.changeStatus(processOrderState.EndReleaseStatusId); }

	/**
	 * API запрос «StartReleaseProduct» на старт выпуска/отмену старта продуктов
	 * @param {[number]} ids список ID продуктов
	 * @param {boolean} active начать выпуск/отменить начало выпуска
	 */
	startReleaseProduct(ids, active) {
		var model = this;

		getRequest("StartReleaseProduct", processOrderState.getReleaseProduct(ids, active, true), function (data) {
			if (data.Success) {
				if (active && processOrderState.canProcess) 
					model.changeStatus(processOrderState.StartReleaseStatusId);
				else model.props.getData();
			} else errorState.setError(data.ErrorCode);
		});
	}

	/**
	 * API запрос на «ReleaseProduct» выпуск продуктов/отмену выпуска
	 * @param {[number]} ids список ID продуктов
	 * @param {boolean} active выпустить/развыпустить
	 */
	releaseProduct(ids, active) {
		var model = this;

		getRequest("ReleaseProduct", processOrderState.getReleaseProduct(ids, active), function (data) {
			if (data.Success) model.props.getData();
			else errorState.setError(data.ErrorCode);
		});
	}

	/**
	 * API запрос «ChangeOrderStatus» на смену статуса заказа
	 * @param {number} id 
	 */
	changeStatus(id) {
		var model = this;

		getRequest("ChangeOrderStatus", { Id: this.props.Item.Id, StatusId: id }, function (data) { 
			if (data.Success) model.props.getData(); 
			else errorState.setError(data.ErrorCode);
		});
	}

	render() {
		var view = this,
			item = this.props.Item,
			style = { borderColor : this.props.Item.StatusColor }

		return (
			<div className="item active">
				<OrderCookItemHeader Item={this.props.Item} IsActive={true} />
				<div className="body clearfix" style={style}>
					<div className={"products clearfix"}>
						{_.map(this.props.Item.Products, function (product, i) {
							return(<OrderCookItemProduct key={i}
								Item={product}
								IsActive={true}

								handleStart={view.handleStart.bind(view)}
								handleRelease={view.handleRelease.bind(view)}
								getTechnique={view.props.getTechnique}
							/>)
						})}
					</div>
					<OrderCookItemComment Item={this.props.Item} />
				</div>
				{item.StatusId !== processOrderState.EndReleaseStatusId &&
					<button className="button" onClick={this.handleStatus.bind(this)}>Выполнить</button>
				}
			</div>
		)
	}
}
OrderCookTableItemActive = observer(OrderCookTableItemActive);

class OrderCookItemHeader extends Component {
	/** Закрыть активный заказ */
	closeOrder() { if (this.props.IsActive) processOrderState.OrderId = -1; }

	render() {
		var item = this.props.Item,
			times = processOrderState.getOrderTimes(item.CreationTime, item.MustCompletedTime, item.ReferenceTimes),
			orderTypesIcons = { 1 : "restaurant", 2 : "pickup", 3 : "delivery", 4 : "home" };

		return(
			<div className={"head clearfix " + times.Light} onClick={this.closeOrder.bind(this)}>
				<div className="col col80">
					<div className="col col65 orderInfo">
						<div className="clearfix">
							<b className="orderNumber">№{item.OrderNumber}</b>
							{item.MustCompletedTime !== "" &&
								<span className="tag">{Parser(formatDate(times.MustCook, "С кухни <b>HH:mm</b>"))}</span>
							}
						</div>
						<div className="clearfix">
							<span className={"icon orderType " + orderTypesIcons[item.OrderTypeId]}><i></i></span>
							<span className="tag">{setLabel(item.PersonsNumber, PersonLabels)}</span>
						</div>
					</div>
					<div className="col col35 time">{getHourTime(times.Left)}</div>
				</div>
				<div className="col col20 reference">
					<h5>Эталон</h5>
					<span className="tag">{times.Reference} м</span>
				</div>
			</div>
	)
	}
}
OrderCookItemHeader = observer(OrderCookItemHeader)

class OrderCookItemComment extends Component {
	render () {
		var item = this.props.Item,
			brand = _.find(processOrderState.Brands, { Id : item.BrandId }),
			hasDescription = item.DescriptionCook !== "" || item.DescriptionAdmin !== "";

		return(
			<div className="clearfix decription">
				{hasDescription &&
					<div className="col col70">
						{item.DescriptionCook !== "" && <h5>Повару</h5>}
						{item.DescriptionCook !== "" && <p>{item.DescriptionCook}</p>}
						{item.DescriptionAdmin !== "" && <h5>Администратору</h5>}
						{item.DescriptionAdmin !== "" && <p>{item.DescriptionAdmin}</p>}
					</div>
				}
				<div className="col col30 brand">
					<h5>Упаковка</h5>
					{brand && brand.SmallLogo !== "" && <img src={brand.SmallLogo} alt={brand.Name} />}
					{brand && brand.SmallLogo === "" && <span>{brand.Name}</span>}
				</div>
			</div>
		)
	}
}

class OrderCookItemProduct extends Component {
	handleTech (event) {
		event.preventDefault();
		if (this.props.IsActive) this.props.getTechnique(this.props.Item.ProductId);
	}
	
	render() {
		var product = this.props.Item,
			moreThanOne = product.Count > 1 ? "more" : "",
			startUser = product.StartUserId === authUserState.User.Id,
			isReleased = product.Released === product.Count,
			isStarted = product.Released !== product.Count && product.Started === product.Count,
			readyClass = isReleased ? "ready" : isStarted ? "inProcess" : "",
			canCancelStart = product.Released !== product.Count && product.Started === product.Count && startUser,
			modificationsList = _.isNull(product.Modifications) ? [] :
				_.map(product.Modifications, "ProductName");

		return(

			<div className="clearfix product rowGroup">
				<div className={"clearfix row " + readyClass}>
					<div className={"td count " + moreThanOne}>{product.Count}</div>
					<div className="td name">
						{!this.props.IsActive && product.ProductName}
						{this.props.IsActive && 
							<a href="/"  onClick={this.handleTech.bind(this)}>{product.ProductName}</a>
						}
						{!_.isEmpty(modificationsList) && !this.props.IsActive &&
							<p className="mods">+ {modificationsList.join(", ")}</p>
						}
					</div>
					<div className="td workshops">
						{!_.isNull(product.WorkShops) && _.map(product.WorkShops, function (v) {
							var workShop = _.find(processOrderState.WorkShops, { Id: v });
							return (<i key={v} className={"icon icon_" + (workShop ? workShop.Icon : "")}></i>)
						})}
					</div>
					{this.props.IsActive &&
						<div className="buttons td">
							{isReleased && <span>Готово</span>}
							{!isStarted && !isReleased && <button className="button blue" onClick={() =>
								this.props.handleStart(product.ProductId, true)}>Начать</button>
							}
							{isStarted && <button className={"button green"} onClick={() =>
								this.props.handleRelease(product.ProductId, true)}>Выпуск</button>
							}
						</div>
					}
					{this.props.IsActive &&
						<div className="cancel td">
							{canCancelStart && <button className="button gray close" onClick={() =>
								this.props.handleStart(product.ProductId, false)}><i></i></button>
							}
							{isReleased && <button className="button gray close" onClick={() =>
								this.props.handleRelease(product.ProductId, false)}><i></i></button>
							}
						</div>
					}
				</div>
				{!_.isNull(product.Modifications) && this.props.IsActive &&
					<div className={"clearfix row mods " + readyClass}>
						<div className="td count">&nbsp;</div>
						<div className="td name">
							<h5>Доп. ингредиенты</h5>
							{_.map(product.Modifications, function (mod, i) {
								return(<p key={i}>+&nbsp;{mod.ProductName}</p>);
							})}
						</div>
						<div className="td workshops">
							{_.map(product.Modifications, function (mod, i) { return(<p key={i}>{mod.Count}</p>); })}
						</div>
						<div className="buttons td">&nbsp;</div>
						<div className="cancel td">&nbsp;</div>
					</div>
				}
			</div>
		)
	}
}

class OrderCookMenu extends Component {
	/** Заркытие окна с меню по кнопке «закрыть */
	handleClose (event) {
		event.preventDefault()
		processOrderState.setMenuCard(undefined);
	}

	/** Закрытие окна с меню по клику вне блока */
	handleBackClose(event){
		if (event.target.getAttribute("rel") === "back") processOrderState.setMenuCard(undefined);
	}

	/** Открытие большой фотографии продукта/приготовления */
	handleOpenPhoto(url) { processOrderState.setProductPhoto(url); }

	/** Переход по кросслинкам между продуктами */
	handleProductCheck(event) {
		event.preventDefault();
		var params = event.currentTarget.getAttribute("rel").split("_");
		this.props.getTechnique(parseInt(params[2], 10));
	}

	/** Переход по кросслинкам между продуктами */
	handleCrumbCheck(event) {
		event.preventDefault();
		var params = event.currentTarget.getAttribute("rel");
		this.props.getTechnique(parseInt(params, 10));
	}

	/**
	 * Изменение кол-ва готового ПФ
	 * @param {number} value значение параметра
	 */
	handleSemiChange(value) { processOrderState.ProductCount = value; }

	render() {
		var view = this,
			style = uistate.windowLargeDimensions;

		return(
			<div className="back" rel="back" onClick={this.handleBackClose.bind(this)} style={{ display: "block" }}>
				<div className={"window large " + (uistate.IsTablet ? "tablet" : "")} style={style}>
					<div className="clearfix breadcrumbs">
						{_.map(processOrderState.MenuCrumbs, function(item, i) {
							if (i < processOrderState.MenuCrumbs.length - 1)
								return(<a href="/" key={i} rel={item.Id}
									onClick={view.handleCrumbCheck.bind(view)}>{item.Name}</a>)
							else return(<span key={i}>{item.Name}</span>)
						})}
					</div>
					<a href="/" className="icon close" onClick={this.handleClose.bind(this)}><i></i></a>
					<MenuItem  
						BlockClass="clearfix"
						Product={processOrderState.MenuCard}
						Composition={processOrderState.menuComposition}
						Type={processOrderState.MenuCard.Type}
						Count={processOrderState.ProductCount}
						handleChange={this.handleSemiChange.bind(this)}
						handleCheck={this.handleProductCheck.bind(this)}
						handleOpenPhoto={this.handleOpenPhoto.bind(this)}
					/>
				</div>
			</div>
		)
	}
}
OrderCookMenu = observer(OrderCookMenu);