/**
 * /pages/Users.js – Работа со настройками пользователей — курьеры и обычные пользователи
 * /stores/rolesusers.js – бизнес логика
 * 
 * Для получения списка пользователей необходимо сделать запрос «Users»
 * Для получения списка курьеров необходимо сделать запрос «Couriers»
 * 
 * Для просмотра/редактирования/удаления пользователей необходимо право «ManageUsers»
 * Для просмотра/редактирования/удаления курьеров необходимо право «ManageCouriers»
 * 
 * Для того, чтобы видеть какие пользователя, являются получаетелями чаевых, назначать получателей чаевых
 * 		необходимо право «ManageRecipient»
 * 
 * Для изменения настроек пользователя/курьера необходимо сделать запрос «UserSave»
 * Чтобы удалить пользователя/курьера необходимо отправить запрос «UserDelete»
 * 
 * Для получения дополнительных данных необходимо отправить запросы
 * 		«Rights» для получения списка прав
 * 		«Dashboards» для получения списка рабочих столов
 * 		«NotificationTypes» для получения списка уведомлений
 * 
 * Для работы со сменами курьеров необходимо право «CanCourierShift» и открытая смена пользователя 
 * 		в том же филиале
 * Для открытия/закрытия смены курьера неоходимо отправить запрос «ToggleCourierShift»
 * 
 * На странице блоки настроек зависят от подключенных модулей
 */

import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import { TextInput, CheckGroup, DateInput } from '../forms/FormItems';
import { TableFilterPage, DashboardBlock, ItemHeader, RightsBlock, NotificationBlock } from './PageTemplates';

import { buttonTitles, errors, TipsStateTitles } from '../dictionary';
import { toCheckList, getRequest } from './../functions';
import { userState, checkedBoards, checkedRights } from '../stores/rolesusers';
import { uistate, confirmState, authUserState, errorState } from '../stores/common';

export class UsersPage extends Component {
	/** API «Users» запрос на получение списка пользователей */
	getData () {
		userState.IsSendRequest = false;
		getRequest("Users", userState.getFilter(), function(data) { userState.setUsers(data); });
	}

	render() { return (<UsersCommonPage Type={this.props.Type} getData={this.getData.bind(this)} />) }
}

export class CouriersPage extends Component {
	/** API «Couriers» запрос на получение списка курьеров */
	getData () {
		userState.IsSendRequest = false;
		getRequest("Couriers", userState.getFilter(), function(data) { userState.setUsers(data); });
	}

	render() { return (<UsersCommonPage Type={this.props.Type} getData={this.getData.bind(this)}  />) }
}

class UsersCommonPage extends Component {
	constructor(props) {
		super(props);

		userState.setDefault(this.props.Type);

		this.state = {};

		if (this.props.getData) this.props.getData();

		getRequest("Filials", {}, (data) => { userState.allFilials = data.Success ? data.Filials : []; });
		getRequest("GroupMerchants", {}, (data) => { userState.allMerchants = data.Success ? data.Merchants : []; });
		getRequest("Roles", { AllMerchants : true }, (data) => { userState.allRoles = data.Success ? data.Roles : []; });
		
		if (this.props.Type === "Users") {
			getRequest("Rights", {}, (data) => { checkedRights.allRights = data.Rights; });
			getRequest("Dashboards", {}, (data) => { checkedBoards.allBoards = data.Dashboards; });
			getRequest("NotificationTypes", {}, (data) => { 
				userState.AllNotifications = data.Success && !_.isNull(data.Notifications) ? data.Notifications : [];
			});
		}
	}

	/** API запрос «User» на получение конкретного пользвователя по ID
	 * @param {number} id ID пользователя
	 */
	getItem(id) {
		getRequest("User", { Id : id }, function(data) { userState.setUser(data.Success ? data : undefined); });
	}

	/** Получение конкретного пользователя
	 * @param {number} id ID пользователя
	 */
	setId(id) { 
		if (!_.isUndefined(id)){ 
			if (id !== -1) this.getItem(id);
			else {
				if (userState.UserLimit === -1 || userState.UserLimit > userState.ActiveUsers)
					userState.setUser({ Id : -1 }, this.props.Type === "Couriers");
				else errorState.setError("MERCHANT_USERLIMIT")
			}
		} else {
			userState.setUser(undefined);
			this.props.getData();
		}
	}

	/** API запрос «UserDelete» на удаление пользователя
	 * @param {number} id ID пользователя
	 */
	removeId(id) { 
		if (_.isUndefined(id)) return false;

		if (userState.UserLimit > 0 && userState.UserLimit <= userState.ActiveUsers) {
			errorState.setError("MERCHANT_USERLIMIT")
			return false;
		}

		var model = this,
			item = _.find(userState.Users, { Id : id }),
			text = <span>Вы действительно хотите {userState.Filter.Active ? "удалить" : "восстановить"} 
				&nbsp;пользователя <b key="b1">«{item ? item.Name : ""}»</b>?</span>;

		confirmState.openConfirm(text, function(){
			getRequest("UserDelete", { Id : id }, function () { model.props.getData(); });
		});
	}

	/** 
	 * Переключатель фильтра пользователей 
	 * @param {string} id Название поля фильтра
	 */
	setFilter (id) {
		userState.Filter[id] = !userState.Filter[id];
		if (this.props.getData) this.props.getData();
	}

	/**
	 * Открытие окна смены курьера
	 * @param {object} courier информация о курьере
	 */
	toggleCourierShift (courier) {
		userState.setCourierShift({ CourierId : courier.Id, CourierName : courier.Name, Active : courier.ShiftDate === "" });
	}

	render() {
		var columns = uistate.isDevice ? {} : { CreateDate: { filterable : false, width: 90 } },
			isCourier = userState.CourierInterface,
			shiftOpen = authUserState.Shift.Active,
			sameMerchant = shiftOpen ? _.find(authUserState.Filials, { Id : authUserState.Shift.FilialId })
				: false,
			canOpen = shiftOpen && userState.CanOpenShift && userState.Filter.Active && sameMerchant;

		columns = _.extend(columns, { Name: { Header: "Имя" }, Login: { width: 120 } });

		if (!uistate.IsMobile && !isCourier) columns = _.extend(columns, { Merchants : {}, Roles: {}, Filials: {} });
		if (!uistate.IsMobile && isCourier) 
			columns = _.extend(columns, { Merchants : {}, Filials: {}, ToggleShift : {
				Cell : row => !canOpen ? "" : row.original.ShiftDate === "" 
					? <button className="button shift open green" onClick={e => {this.toggleCourierShift(row.original)}}>
						Открыть смену</button>
					: row.original.ShiftFilialId === authUserState.Shift.FilialId
					? <button className={"button shift close " + (row.original.CloseAfterResolve ? "yellow" : "red")} 
						onClick={e => {this.toggleCourierShift(row.original)}}>Закрыть смену</button> : "",
				sortMethod: (a, b) => {
					const aSort = a === authUserState.Shift.FilialId ? 3 : a === -1 ? 2 : 1;
					const bSort = b === authUserState.Shift.FilialId ? 3 : b === -1 ? 2 : 1;
					
					if (a === b) return 0;
					return aSort > bSort ? 1 : -1;
				}
			} });
		
		if (authUserState.HasERP && userState.CanRecipient) _.extend(columns, {
			Tips : { Cell : row => row.original.TipsRecipient ? "да" : "" }
		})
		if (userState.CanManage) _.extend(columns, { Edit: {}, Remove: {} });

		console.log(userState.UserLimit)
		console.log(userState.ActiveUsers);

		return (
			<div className="page" id="UsersPage">
				{_.isUndefined(userState.User) && userState.IsSendRequest &&
					<TableFilterPage 
						Type={this.props.Type}
						CanManage={userState.CanManage && userState.Filter.Active}
						HeadFilter={userState.Filter}
						
						TableData={userState.Users}
						TableColumns={columns}
						TableFilterable={true}
						Sorted={[{ id: "ToggleShift", desc: false }]}

						setFilter={this.setFilter.bind(this)}
						setId={this.setId.bind(this)}
						removeId={this.removeId.bind(this)}
						getTrProps={(state, rowInfo, column) => {
							return { className: rowInfo && !rowInfo.original.Active ? "notactive" : "" };
						}}
					/>
				}
				{!_.isUndefined(userState.User) && 
					<UserItem
						Type={this.props.Type}
						setId={this.setId.bind(this)}
					/>
				}
				{!_.isUndefined(userState.CourierShift) && userState.CanOpenShift &&
					<CourierShift getData={this.props.getData} />
				}
			</div>
		)
	}
}
UsersCommonPage = observer(UsersCommonPage);

class CourierShift extends Component {
	/** Закрыть окно смены курьера */
	onCancel (event) {
		event.preventDefault();
		userState.setCourierShift(undefined);
	}

	/** API запрос «ToggleCourierShift» на открытие/закрытие курьерской смены */
	onConfirm (event) {
		event.preventDefault();
		var model = this;
		
		getRequest("ToggleCourierShift", userState.getCourierShift(), function(data) {
			if (data.Success) {
				model.props.getData();
			} else errorState.setError(data.ErrorCode);
			userState.setCourierShift(undefined);
		});
	}

	/**
	 * Сбор данных с формы открытия/закрытия курьерской смены
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange(value, id) { userState.collectCourierShift(id, value); }

	render() {
		var params = userState.CourierShift,
			text = <span>Вы действительно хотите {params.Active ? "открыть" : "закрыть"} смену 
					&nbsp;курьера <b key="b1">«{params.CourierName}»</b> в филиале&nbsp;
					<b key="b2">«{params.FilialName}»</b>?</span>,
			autoList = [{ Id : "true", Name : "Закрыть смену после выполнения всех заказов"}];

		return(
			<div className="back">
				<form className="confirmBlock clearfix courierShift window">
					<p>{text}</p>
					{!params.Active &&
						<CheckGroup
							Id="CloseAfterResolve"
							Type="checkbox"
							Value={userState.CourierShift.CloseAfterResolve ? ["true"] : []}
							List={toCheckList(autoList)}
							onChange={this.handleChange.bind(this)}
						/>
					}
					{!userState.CourierShift.CloseAfterResolve &&
						<DateInput
							Id="Date"
							Title={"Дата и время " + (params.Active ? "открытия" : "закрытия") + " смены"}
							Value={userState.CourierShift.Date}
							WithTime={true}
							onChange={this.handleChange.bind(this)}
						/>
					}
					{userState.ErrorCodeShift !== "" &&
						<p className="error">{errors[userState.ErrorCodeShift] || errors.DEFAULT}</p>
					}
					<div className="buttons clearfix">
						<a href="/" role="button" onClick={this.onConfirm.bind(this)} className="button confirm">Подтвердить</a>
						<a href="/" role="button" onClick={this.onCancel.bind(this)} className="button gray">Отменить</a>
					</div>
				</form>
			</div>
		)
	}
}
CourierShift = observer(CourierShift);

class UserItem extends Component {
	/** API запрос «UserSave» на сохранение данных пользователя */
	handleSubmit (event) {
		event.preventDefault();

		if (!userState.validateData()) return false;

		userState.ButtonLoading = true;

		getRequest("UserSave", userState.getSaveData(), (data) => {
			userState.ErrorCode = data.Success ? userState.User.Id === -1 ? "SUCCESS_NEW_USER_SAVE" : "SUCCESS_SAVE" : data.ErrorCode;
			userState.SuccessSave = data.Success;
			userState.ButtonLoading = false;

			userState.User.Id = data.Success ? data.Id : userState.User.Id;
		});
	}

	/** Закрытие окна редактирования */
	handleClose (event) {
		event.preventDefault();
		this.props.setId(undefined);
	}

	/**
	 * Сбор данных с формы
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange(value, id) { userState.collectUser(id, value); }

	render() {
		var title = buttonTitles[(userState.User.Id === -1 ? "New" : "Edit") + this.props.Type],
			isCourier = userState.CourierInterface;
		
		return (
			<section className="clearfix item  two-cols flex-page" id="UserItem">
				<form onSubmit={this.handleSubmit.bind(this)}>
					<ItemHeader 
						Title={title}
						Error={userState.ErrorCode}
						Loading={userState.ButtonLoading}
						CanManage={userState.CanManage}
						Success={userState.SuccessSave}
						handleClose={this.handleClose.bind(this)}
					/>
					<div className="clearfix section">
						<div className="clearfix">
							<div className={uistate.IsMobile || isCourier ? "col col100" : "col col70"}>
								<MainInfo />
								<FilialsInfo />
								{!isCourier && !_.isEmpty(userState.NotificationsList) &&
									<NotificationBlock 
										Value={userState.User.Notifications} 
										List={userState.NotificationsList}
										onChange={this.handleChange.bind(this)}
									/>
								}
								{!isCourier && <DashboardBlock />}
							</div>
							{!isCourier &&
								<RightsBlock BlockClass={uistate.IsMobile ? "col col100" : "col col30"} />
							}
						</div>
					</div>
				</form>
			</section>
		)
	}
}
UserItem = observer(UserItem);

class MainInfo extends Component {
	/** API метод «GenerateUserPassword» для сброса пароля пользователю */
	handleResetPassword (event) {
		event.preventDefault();

		if (userState.User.Email === "") {
			errorState.setError("EMPTY_RESET_EMAIL");
			confirmState.closeConfirm();
			return false;
		}

		var text = <span>Вы действительно хотите сбросить пароль пользователю 
				&nbsp;<b key="b1">«{userState.User ? userState.User.FirstName : ""}»</b>? В результате пользователю на почту будет отправлено письмо с новыми аторизационными данными</span>;

		confirmState.openConfirm(text, function(){
			getRequest("GenerateUserPassword", { UserId : userState.User.Id }, (data) => { 
				userState.ErrorCode = data.Success ? "SUCCESS_RESET" : data.ErrorCode;
				userState.SuccessSave = data.Success;
			});
		});
	}
	render () {
		var isCourier = userState.CourierInterface,
			tipsList = [{ Id : "true", Name : "Является получателем чаевых" }],
			innList = [{ Id : "true", Name : "Отображать в кассе" }],
			isRecipient = userState.User.TipsRecipient && userState.User.TipsState > -1;

			console.log(userState.User.TipsState)

		return(
			<div className="clearfix block">
				<div className={"col main " + (uistate.IsMobile ? "col100" : "col60")}>
					{userState.User.Id !== -1 &&
						<div className='reset'>
							<a href="/" onClick={this.handleResetPassword.bind(this)} className='dotted'>Сбросить пароль</a>
						</div>
					}
					<div>
						<TextInput
							Id="Login"
							Title="Логин"
							Value={userState.User.Login}
							Required={true}
							Disabled={userState.User.Id > 0}
							Mask="7 (999) 999-99-99"
							onChange={(value,id) => userState.collectUser(id, value)}
						/>
						<TextInput
							Id="Email"
							Title="E-mail"
							Value={userState.User.Email}
							Required={true}
							onChange={(value,id) => userState.collectUser(id, value)}
						/>
					</div>
					<div>
						<TextInput
							Id="FirstName"
							Title="Имя"
							Value={userState.User.FirstName}
							Required={true}
							onChange={(value,id) => userState.collectUser(id, value)}
						/>
						<TextInput
							Id="LastName"
							Title="Фамилия"
							Value={userState.User.LastName}
							Required={userState.User.TipsRecipient}
							onChange={(value,id) => userState.collectUser(id, value)}
						/>
						{userState.User.TipsRecipient &&
							<TextInput
								Id="Patronymic"
								Title="Отчество"
								Value={userState.User.Patronymic}
								Required={true}
								onChange={(value,id) => userState.collectUser(id, value)}
							/>
						}
					</div>
					{authUserState.HasTips && userState.CanRecipient &&
						<div className='tips'>
							<CheckGroup
								Id="TipsRecipient"
								Type="checkbox"
								RowClass="nomargin"
								List={toCheckList(tipsList)}
								Disabled={isRecipient}
								Value={userState.User.TipsRecipient ? ["true"] : []}
								onChange={(value,id) => userState.collectUser(id, value)}
							/>
							{isRecipient &&
								<p className="comment">{TipsStateTitles[userState.User.TipsState]}</p>
							}
						</div>
					}
					{!isCourier &&
						<div>
							<TextInput
								Id="IPList"
								Title="IP адрес (для ограничения доступа к сервису по IP)"
								Value={userState.User.IPList}
								onChange={(value,id) => userState.collectUser(id, value)}
							/>
						</div>
					}
					{authUserState.HasERP &&
						<div>
							<TextInput
								Id="INN"
								Title="ИНН кассира (должны быть для работы с кассами)"
								Mask="999999999999"
								Value={userState.User.INN}
								Disabled={isCourier && !userState.CanManageINN}
								onChange={(value,id) => userState.collectUser(id, value)}
							/>
							{isCourier && userState.CanManageINN &&
								<CheckGroup
								Id="ShowINNFiscal"
								Type="checkbox"
								List={toCheckList(innList)}
								Disabled={!userState.canShowInn}
								Value={userState.User.ShowINNFiscal ? ["true"] : []}
								onChange={(value,id) => userState.collectUser(id, value)}
								/>
							}
						</div>
					}
				</div>
				<div className={"col roles " + (uistate.IsMobile ? "col100" : "col40")}>
					{userState.merchantList.length > 1 &&
						<CheckGroup
							Id="MerchantIds"
							Title="Предприятия"
							Type="checkbox"
							List={toCheckList(userState.merchantList)}
							Value={userState.MerchantIds}
							onChange={(value,id) => userState.collectMerchants(id, value)}
						/>
					}
					{!isCourier &&
						<CheckGroup
						Id="Roles"
						Title="Должности"
						Type="checkbox"
						List={toCheckList(userState.roleList)}
						Value={userState.Roles}
						onChange={(value,id) => userState.collectRoles(id, value)}
						/>
					}
					{!isCourier &&
						<p className='comment'>При назначении должности все ее настройки автоматически применяются к пользователю. Вы можете дать пользователю индивидуальные права доступа в блоке ниже.</p>
					}
				</div>
			</div>
		)
	}
}
MainInfo = observer(MainInfo);

class FilialsInfo extends Component {
	render () {
		return(
			<div className="filials clearfix block">
				<div className="clearfix head"><h4>Доступ к филиалам</h4></div>
				{_.map(_.groupBy(userState.allFilials, "City"), function(items, city) {
					return(<FilialsCities key={city} City={city} Filials={items} />)
				})}
			</div>
		)
	}
}
FilialsInfo = observer(FilialsInfo);

class FilialsCities extends Component {
	/**
	 * Сбор данных с формы
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	onChange (value, id) { userState.collectFilialsPOS(id, value, this.props.City); }

	render() {
		var view = this,
			isCourier = userState.CourierInterface,
			filialList = _.map(this.props.Filials, function (v) { return { Id: v.Id, Title: v.Name, POS : v.POS }; })

		return(
			<div className={isCourier ? " col col50 city" : "clearfix city block"}>
				{!isCourier &&
					<div className="clearfix head">
						<h4>{this.props.City}</h4>
						<div className="checkAll">
							<CheckGroup 
								Type="checkbox"
								Id="CheckAll"
								List={[{ Id : "true", Title : "Выбрать все" }]}
								Value={userState.CheckAllFilials[this.props.City] ? ["true"] : []}
								onChange={this.onChange.bind(this)}
							/>
						</div>
					</div>
				}
				{isCourier && 
					<CheckGroup
						Id="Filials"
						Type="checkbox"
						Title={"Филиалы " + this.props.City}
						List={filialList}
						Value={userState.Filials}
						onChange={this.onChange.bind(view)}
					/>
				}
				{!isCourier && filialList.map(function (v) {
					return (
						<div className={"filial col " + (uistate.IsMobile ? "col1_2" : "col1_3")} key={v.Id}>
							<CheckGroup
								Id="Filials"
								Type="checkbox"
								Title="Филиал"
								List={[v]}
								Value={userState.Filials}
								onChange={view.onChange.bind(view)}
							/>
							{!_.isEmpty(v.POS) && authUserState.HasERP && <h5>Точки продаж</h5>}
							{!_.isEmpty(v.POS) && authUserState.HasERP && _.map(v.POS, function (pos, i) {
								return(<div className="pos" key={i}>
									<CheckGroup
										Id="POS"
										Type="checkbox"
										List={toCheckList([{ Id : pos.Id, Name : pos.Name }])}
										Value={userState.POS}
										RowClass="nomargin"
										Disabled={_.indexOf(userState.Filials, v.Id) === -1}
										onChange={view.onChange.bind(view)}
									/>
									{pos.HasCassa && authUserState.HasERP &&
										<CheckGroup
											Id="Cassa"
											Type="checkbox"
											List={toCheckList([{ Id : pos.Id, Name : "Касса" }])}
											Value={userState.Cassa}
											RowClass="cassa nomargin"
											Disabled={_.indexOf(userState.POS, pos.Id) === -1}
											onChange={view.onChange.bind(view)}
										/>
									}
								</div>)
							})}
						</div>
					)
				})}
			</div>
		)
	}
}
FilialsCities = observer(FilialsCities);