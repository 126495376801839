import { observable, decorate } from 'mobx';
import _ from 'lodash';
import moment from 'moment';

class InventorizationState {
    Inventorizations = [];
    IsSendRequest = false;
    Filter = {
        ShiftDate : "",
        FilialId : "",
        InventoryId : "",
        Limit : 100,
        Offset : 0
    };
    Limit = 100;

    Inventorization = undefined;
    ButtonLoading = false;
    ErrorCode = "";
    Inventory = [];
    ICounter = 0;

    InventorizationDummy = {
        Id : -1,
        Date : moment().format("YYYY-MM-DD"),
        FilialId : -1
    }
    InventoryDummy = {
        InventoryId : -1,
        Count : "",
        Description : "",
        Action : -1
    }

    setFilter() {
        this.Filter = {
            ShiftDate : "",
            FilialId : "",
            InventoryId : "",
            Limit : 100,
            Offset : 0
        }
    }
    collectFilter (id, value) { this.Filter[id] = value; }
    getFilter () { return _.omitBy(_.clone(this.Filter), function (v) { return v === ""; }); }

    setInventorizations(data) {
        this.Inventorizations = data.Success ? data.Inventorizations : [];
        this.IsSendRequest = true;
    }

    setInventorization(inventorization) {
        this.Inventorization = _.isUndefined(inventorization) ? undefined : _.clone(this.InventorizationDummy);

        this.Inventory = [_.clone(this.InventoryDummy)];
        this.ICounter = 0;
        this.ButtonLoading = false;
        this.ErrorCode = "";
    }

    collectInventorization (id, value) { this.Inventorization[id] = value; }
    collectInventory (id, value, index, item) { 
        console.log(id, value)
        this.Inventory[index][id] = value; 
        if (!_.isUndefined(item)) this.Inventory[index].InventoryNumber = item.InventoryNumber;
    }

    getSaveData() {
        var data = _.clone(this.Inventorization);

        _.each(this.Inventory, function(inventory, i) {
            var name = "Inventorizations." + i + "."
            
            data[name + "InventoryId"] = inventory.InventoryId;
            data[name + "Count"] = inventory.Action === 1 ? inventory.Count : -inventory.Count;
            data[name + "Description"] = inventory.Description;
        });

        return data;
    }

    validationData() {
        var store = this,
            isValid = true;

        if (this.Inventorization.FilialId === "" || this.Inventorization.FilialId === -1) {
            this.ErrorCode = "FILIAL_REQUIRED";
            return false;
        }

        if (_.isEmpty(this.Inventory)) {
            this.ErrorCode = "INVENTORY_REQUIRED";
            return false;
        }

        _.each(this.Inventory, function(inventory) {
            if (inventory.InventoryId === "" || inventory.InventoryId === -1) {
                store.ErrorCode = "INVENTORY_REQUIRED";
                isValid = false;
                return false;
            }

            if (inventory.Action === "" || inventory.Action === -1) {
                store.ErrorCode = "INVENTORY_ACTION_REQUIRED";
                isValid = false;
                return false;
            }
        });

        return isValid;
    }
}

decorate(InventorizationState, {
    Inventorizations: observable,
    IsSendRequest: observable,
    Filter: observable,
    HasMore: observable,

    Inventorization: observable,
    Inventory: observable,

    ButtonLoading: observable,
    ErrorCode: observable
});
export const inventorizationState = new InventorizationState();