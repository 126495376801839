import { observable, decorate, computed } from 'mobx';
import _ from 'lodash';
import moment from 'moment';
import { isTablet } from 'react-device-detect';

import { uistate } from './common';
import { checkRights, formatDate, getRubs } from '../functions';
import { VATTitles } from '../dictionary';

class InvoiceState {
	Today = moment().hour(0).minute(0).second(0);
	Limit = 100;

	InvoiceDummy = {
		Id : -1,
		InvoiceType : -1,
		InvoiceNumber : "",
		Date : "",
		// Date : moment().format("YYYY-MM-DD"),
		StockId : -1,
		ProviderId : -1,
		Amount : "",
		VATAmount : "",
		PaymentType : "",
		Description : "",
		AcceptUser : "",
		AcceptDate : "",
		Status : 1
	}
	ProductDummy = {
		Id : -1,
		ProductId : -1,
		Count : "",
		Price : "",
		CountGross : "",
		VAT : -1,
		VATAmount : "",
		Amount : ""
	}
	ProviderProducts = [];
	ShopListId = "";

	pCounter = 0;

	vatMulti = { 1 : 0, 2 : 1/11, 3 : 1/6 };
	invoiceTypes = { 1 : "№", 2 : "ТН", 3 : "СФ" };
	invoiceStatuses = [{ Id : 1, Name: "Оприходована" }, { Id : 2, Name: "Подтверждена" }, { Id : 3, Name: "Оплачена" }];
	paymentTypes = [{ Id : 2, Name : "По счету" }, { Id : 3, Name : "Бизнес-карта" }, { Id : 1, Name : "Наличные" } ];

	/** Установка дефолтного состояния страницы */
	setDefault() {
		this.CanEdit = checkRights("ManageInvoices");
		this.CanAccept = checkRights("AcceptInvoices");
		this.CanManage = this.CanEdit || this.CanAccept;
		this.CanPayment = checkRights("PaymentInvoices")

		this.Stocks = [];
		this.Providers = [];
		this.ShopLists = [];

		this.Filter = {
			StockId : "",
			ProviderId : "",
			Date : "",
			Status : "",
			Limit : this.Limit,
			Offset : 0
		}

		this.Invoices = [];
		this.IsSendRequest = false;
		this.HasMore = false;

		this.setInvoice(undefined);
	}

	/**
	 * Сбор данных с формы поиска накладных
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	collectFilter (value, id) { this.Filter[id] = value; }

	/** Сбор данных с формы фильтра */
	getFilterData () { return _.omitBy(_.clone(this.Filter), function (v) { return v === "" || v === false }); }

	/**
	 * Разбор данных с накладными
	 * @param {object} data ответ от сервера
	 */
	setInvoices(data) {
		var types = this.invoiceTypes,
			statuses = this.invoiceStatuses,
			today = this.Today;

		this.Invoices = data.Success ? _.map(data.Invoices, function(v) {
			var deadline = moment(v.DeadlinePayment),
				hours = deadline.diff(today, "hours"),
				days = Math.ceil(hours/24),
				color = v.Status === 1 ? "" : v.Status === 3 ? "payment" : days > 3 ? "notPayment" : "alert";
				
			return _.extend(v, { 
				InvoiceNumber : types[v.InvoiceType] + " " + v.InvoiceNumber,
				StatusName : _.find(statuses, { Id : v.Status }) ? _.find(statuses, { Id : v.Status }).Name : "",
				StatusColor : color,
				CanRemove : v.Status === 1, 
				CanPay : v.Status === 2, 
				CanEdit : v.Status === 1, 
				CanView : v.Status !== 1 })
		}) : [];

		this.HasMore = data.HasMore;
		this.IsSendRequest = true;
	}

	get ShopsLists () {
		if (_.isEmpty(this.ShopLists)) return [];

		return _.map(this.ShopLists, function (v) {
			return {
				Id : v.Id,
				Name : v.Provider + " — " + v.Stock + " — от " + formatDate(v.Date)
			}
		});
	}

	/**
	 * Разбор данных с накладной
	 * @param {object} invoice ответ от сервера
	 */
	setInvoice(invoice) {
		this.Invoice = _.isUndefined(invoice) ? undefined : invoice.Id === -1 ? _.clone(this.InvoiceDummy)
			: _.extend(_.omit(_.clone(invoice), ["Success", "ErrorCode, Products"]), {
				Amount : getRubs(invoice.Amount),
				VATAmount : getRubs(invoice.VATAmount)
			});

		this.Products = _.isUndefined(invoice) || _.isNull(invoice.Products) ? [] : invoice.Id === -1
			? [_.clone(this.ProductDummy)] 
			: _.map(invoice.Products, function (v,i) {
				return _.extend(v, { 
					Price : getRubs(v.Price, invoice.Status !== 1, invoice.Status !== 1),
					VATTitle : _.find(VATTitles, { Id : v.VAT }) ? _.find(VATTitles, { Id : v.VAT }).Name : "",
					VATAmount : getRubs(v.VATAmount, invoice.Status !== 1, invoice.Status !== 1),
					Amount : getRubs(v.Amount, invoice.Status !== 1, invoice.Status !== 1)
				});
			});

		this.ErrorCode = "";
		this.ButtonLoading = false;
		this.SuccessSave = false;
	}

	get CanManageInvoice () {
		return _.isUndefined(this.Invoice) || !this.CanManage ? false : this.Invoice.Status === 1;
	}
	get CanAcceptInvoice () {
		return _.isUndefined(this.Invoice) || !this.CanAccept ? false : this.Invoice.Status === 1 && this.Invoice.Id !== -1;
	}

	/**
	 * Разбор данных из списка покупок
	 * @param {object} shopList список покупок
	 */
	setShopList (shopList) {
		this.Invoice = _.extend(this.Invoice, {
			StockId : shopList.StockId,
			ProviderId : shopList.ProviderId
		});

		this.Products = _.isNull(shopList.Products) ? this.Products
			: _.map(shopList.Products, function (v,i) {
				return _.extend(v, { 
					Price : getRubs(v.Price), 
					VATAmount : getRubs(v.VATAmount) 
				});
			});
	}

	setProvider (provider) {
		var store = this;

		this.ProviderProducts = provider.Products;

		this.Products = _.map(this.Products, function (v) {
			var product = _.find(provider.Products, { ProductId : v.ProductId });
			return _.extend(v, {
				Price : v.Price !== "" ? v.Price : product ? getRubs(product.Price) : "",
				VAT : v.VAT !== -1 ? v.VAT : product ? product.VAT : -1,
				Amount : v.Amount !== "" ? v.Amount : product ? getRubs(product.Price*v.Count) : "",
				VATAmount : v.VATAmount !== "" ? v.VATAmount : product ? getRubs(product.Price*v.Count*store.vatMulti[product.VAT]) : "",
			});
		});

		if (!_.isUndefined(this.Invoice))
			this.setSummary();
	}

	collectInvoice(id, value) { this.Invoice[id] = value; }

	collectProducts(id, value, index, product) {
		this.Products[index][id] = value;

		if (!_.isEmpty(product)) this.Products[index] = _.extend(this.Products[index], {
			UnitNet : product.UnitNet,
			UnitGross : product.UnitGross,
			VAT : product.VAT,
			Price : getRubs(product.Price)
		});

		var store = this,
			prod = this.Products[index];

		if (/Count/.test(id) || /Price/.test(id)) 
			this.Products[index] = _.extend(this.Products[index], {
				Amount : prod.Price !== "" && prod.Count !== "" 
					? (parseFloat(prod.Price)*parseFloat(prod.Count)).toFixed(2) : 0,
				VATAmount : prod.VAT !== -1 && prod.VAT !== "" 
					? (parseFloat(prod.Price)*parseFloat(prod.Count)*store.vatMulti[prod.VAT]).toFixed(2) : 0
			});

		if ("VAT" === id || "Amount" === id) 
			this.Products[index] = _.extend(this.Products[index], {
				Price : prod.Amount !== "" && prod.Count !== ""
					? (parseFloat(prod.Amount)/parseFloat(prod.Count)).toFixed(2) : 0,
				VATAmount : prod.VAT !== -1 && prod.VAT !== "" 
					? (parseFloat(prod.Amount)*store.vatMulti[prod.VAT]).toFixed(2) : 0
			});   
		
		if (!/CountGross/.test(id))
			this.setSummary();
	}

	setSummary () {
		this.Invoice.Amount = _.sumBy(this.Products, function(v) { 
			return _.isNaN(parseFloat(v.Amount)) ? 0 : parseFloat(v.Amount); }).toFixed(2);
		this.Invoice.VATAmount = _.sumBy(this.Products, function(v) { 
			return _.isNaN(parseFloat(v.VATAmount)) ? 0 : parseFloat(v.VATAmount); }).toFixed(2);
	}

	getSaveData () {
		var data = _.clone(this.Invoice),
			index = 0;

		_.each(this.Products, function (product, i) {
			var name = "Products." + index + ".";

			if (product.ProductId !== "" && product.ProductId !== -1) {
				data[name + "ProductId"] = product.ProductId;
				data[name + "Count"] = product.Count;
				data[name + "CountGross"] = product.CountGross;
				data[name + "Price"] = (product.Price*100).toFixed(0);
				data[name + "Amount"] = (product.Amount*100).toFixed(0);
				data[name + "VATAmount"] = (product.VATAmount*100).toFixed(0);
				data[name + "VAT"] = product.VAT;
				index++;
			}
		});

		data = _.extend(data, {
			Amount : (data.Amount*100).toFixed(0),
			VATAmount : (data.VATAmount*100).toFixed(0)
		});

		if (this.ShopListId !== "") data.ShopListId = this.ShopListId;

		return data;
	}

	validateData() {
		var store = this,
			isValid = true;

		if (this.Invoice.InvoiceNumber === "" || this.Invoice.InvoiceType === "" 
				|| this.Invoice.InvoiceType === -1) {
			this.ErrorCode ="INVOICE_NUMBER_REQUIRED";
			return false;
		}

		if (this.Invoice.Date === "") {
			this.ErrorCode ="INVOICE_DATE_REQUIRED";
			return false;
		}

		if (this.Invoice.StockId === "" || this.Invoice.StockId === -1) {
			this.ErrorCode ="STOCK_REQUIRED";
			return false;
		}

		if (this.Invoice.ProviderId === "" || this.Invoice.ProviderId === -1) {
			this.ErrorCode ="PROVIDER_REQUIRED";
			return false;
		}

		if (this.Invoice.PaymentType === "" || this.Invoice.PaymentType === -1) {
			this.ErrorCode ="PAYMENTTYPE_REQUIRED";
			return false;
		}

		var products = _.filter(this.Products, function (v) { return v.ProductId !== -1 && v.ProductId !== ""; });

		if (_.isEmpty(products)) {
			this.ErrorCode ="INVOICE_PRODUCT_REQUIRED";
			return false;
		}

		_.each(products, function(v) {
			if (v.Count === "" || v.CountGross === "") {
				store.ErrorCode ="COUNT_REQUIRED";
				isValid = false;
				return false;
			}

			if (v.Price === "" || v.VAT === "" || v.VAT === -1 || v.VATAmount === "") {
				store.ErrorCode ="P_AMOUNT_REQUIRED";
				isValid = false;
				return false;
			}
		});

		if (this.Invoice.Amount === "" || this.Invoice.VATAmount === "") {
			this.ErrorCode ="AMOUNT_REQUIRED";
			return false;
		}

		return isValid;
	}
}
decorate(InvoiceState, {
	Stocks : observable,		// Список складов
	Providers: observable,		// Список поставщиков
	ShopLists : observable,		// список листов покупок

	Invoice: observable,
	Products: observable,
	ProviderProducts: observable,
	ShopListId: observable,
	InvoiceConfirm: observable,
	InvoiceConfirmLoading: observable,

	Invoices: observable,
	IsSendRequest: observable,
	
	Filter: observable,
	HasMore: observable,
	
	ButtonLoading: observable,
	ErrorCode: observable,
	SuccessSave: observable
});
export const invoiceState = new InvoiceState();

class PaymentsState {
	CurrentMonth = moment().format("YYYY-MM");
	dayCounter = 0;
	CurrentProvider = {};
	Dates = {};

	setDefault () {
		this.CurrentMonth = moment().format("YYYY-MM");
		this.Dates = {};
		this.Weeks = {};
		this.dayCounter = 0;
		this.setProvider(undefined);
	}

	setMonth (dates) {
		var store = this,
			today = moment().hour(0).minute(0).second(0),
			invoiceTypes = { 1 : "№", 2 : "ТН", 3 : "СФ" },
			stubCount = moment(this.CurrentMonth + "-01").isoWeekday(),
			stub = _.map(_.range(1, stubCount), function (v) {
				return {
					Disabled : true,
					Date : moment(store.CurrentMonth + "-01").subtract(stubCount-v, "days").format("YYYY-MM-DD"),
					Providers : [],
					Today : false
				}
			}),
			days = _.concat(stub, _.map(_.clone(dates), function(v) {
				return _.extend(v, { 
					Disabled : false, 
					Today : today.format("YYYY-MM-DD") === v.Date,
					Providers : _.isNull(v.Invoices) ? [] : _.map(_.groupBy(v.Invoices, "Provider"), function (items, p) {
						var deadline = _.min(_.map(items, "DeadlinePayment")),
							hours = moment(deadline).diff(today, "hours"),
							delta = Math.ceil(hours/24);
						
							return {
							Provider : p,
							Date 	 : v.Date,
							Total 	 : _.sumBy(items, "Amount"),
							Status 	 : _.min(_.map(items, "Status")),
							PaymentType : _.sortBy(_.uniq((_.map(items, "PaymentType")))).join("_"),
							Alert : today.isAfter(deadline) || delta < 4,
							DeadlinePayment : items[0].DeadlinePayment,
							Invoices : _.map(items, function (invoice) {
								var deadLine = moment(invoice.DeadlinePayment),
									hours = deadLine.diff(today, "hours"),
									delta = Math.ceil(hours/24);

								return _.extend(invoice, {
									Name : invoiceTypes[invoice.InvoiceType] + " " + invoice.InvoiceNumber,
									Alert : today.isAfter(deadLine) || delta < 4,
								});
							})
						}
					})
				});
			})),
			weeks = _.groupBy(_.clone(dates), function (v) { return moment(v.Date).isoWeek(); });

		weeks = _.sortBy(weeks, function (v) { return (v[0].Date).split("-")[0]+(v[0].Date).split("-")[1]+(v[0].Date).split("-")[2]; });

		this.Dates = _.groupBy(_.clone(days), function (v) { return moment(v.Date).isoWeekday(); });
		this.Weeks = _.map(weeks, function (days, i) {
			var invoices = _.map(days, function (v) { return _.isNull(v.Invoices) ? [] : v.Invoices; })
			return {
				Title : moment(_.head(days).Date).format("DD.MM") + "–" + moment(_.last(days).Date).format("DD.MM"),
				Amounts : _.map(_.groupBy(_.flatten(invoices), "PaymentType"), function (v, type) {
					var deadline = _.min(_.map(v, "DeadlinePayment")),
						delta = moment(deadline).diff(today, "days");
					
					return {
						Total : _.isEmpty(invoices) ? 0 : _.sumBy(_.filter(v, { Status : 2 }), "Amount"),
						Payment : _.isEmpty(invoices) ? 0 : _.sumBy(_.filter(v, { Status : 3 }), "Amount"),
						Alert 	: today.isAfter(deadline) || delta < 4,
						Status 	: _.min(_.map(invoices, "Status")),
						Type	: parseInt(type, 10)
					}
				})
			}
		});
		
		this.dayCounter = 0;
	}

	setProvider(day, date, provider) {
		this.CurrentProvider = date && provider ? _.find(_.find(this.Dates[day], { Date : date }).Providers, 
			{ Provider : provider }) : {};
	}

	get dayWidth () {
		var sectionWidth = uistate.WindowDimensions.width - (uistate.IsSideBarOpen && !isTablet ? 260 : 50) - 40;
		return Math.floor((sectionWidth - 25)/this.Dates[1].length - 5);
	}
}
decorate(PaymentsState, {
	CurrentMonth: observable,
	Dates: observable,
	Weeks: observable,
	CurrentProvider: observable,

	dayWidth: computed,
});
export const paymentsState = new PaymentsState();