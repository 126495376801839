import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import { checkRights, toSelectList, getRequest, toCheckList, formatDate } from '../functions';
import { dashboardsTitles, buttonTitles, daysLong, ConditionGroupTypes, ActionGroupTypes } from '../dictionary';

import { TextInput, Selectize, CheckGroup, DateInput, ButtonGroup } from '../forms/FormItems';

import { uistate, confirmState } from '../stores/common';
import { ItemHeader, TableFilterPage } from './PageTemplates';

import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { bonusState, promocodeState } from '../stores/bonusRules';

const DragHandle = SortableHandle(() => <i className="SortHandle"></i>);

export class BonusPage extends Component {
	constructor(props) {
		super(props);

		bonusState.setItem(undefined);

		this.state = {}

		this.getData();

		getRequest("Products", { Type : 3, Active : true }, function(data) { bonusState.setProducts(data); });
	}

	getData() {
		bonusState.IsSendRequest = false;
		getRequest("BonusRules", { Active : bonusState.Active }, function (data) { bonusState.setList(data); });
	}

	setId(id) {
		if (_.isUndefined(id)) {
			bonusState.setItem(undefined);
			this.getData();
		} else if (id === -1) bonusState.setItem({ Id: -1 });
		else this.getItem(id);
	}

	getItem(id) {
		getRequest("BonusRule", { Id: id }, function (data) { bonusState.setItem(data.Success ? data : undefined); });
	}

	removeRow(id) {
		if (_.isUndefined(id)) return false;

		var model = this,
			item = _.find(bonusState.Rules, { Id: id }),
			text = <span>Вы действительно хотите {item.Active ? "деактивировать" : "активировать"} правило
						&nbsp;<b key="b1">«{item ? item.Description : ""}»</b>?</span>;

		confirmState.openConfirm(text, function () {
			getRequest("ChangeStateBonusRule", { Id: id }, function () { model.getData(); });
		});
	}

	setActive(event) {
		event.preventDefault();
		bonusState.Active = bonusState.Active === 1 ? -1 : 1;
		this.getData();
	}

	render() {
		return (
			<div className={"page " + this.props.Type} id="BonusRulePage">
				{_.isUndefined(bonusState.Rule) &&
					<div className="clearfix">
						<div className="head">
							<h2>
								{dashboardsTitles[this.props.Type] || this.props.Type}
								<a href="/" className="toggleActive" onClick={this.setActive.bind(this)}>
									{buttonTitles[(bonusState.Active === 1 ? "Not" : "") + "ActiveBonusRules"]}</a>
							</h2>
							{checkRights("ManageBonusRules") && bonusState.Active === 1 &&
								<button className="add" onClick={() => this.setId(-1)}>
									{buttonTitles[this.props.Type] || "Добавить"}</button>
							}
						</div>
						{bonusState.IsSendRequest && _.isEmpty(bonusState.Rules) &&
							<p>По вашему запросу ничего не найдено</p>
						}
						{bonusState.IsSendRequest && !_.isEmpty(bonusState.Rules) &&
							<BonusTable editId={this.setId.bind(this)} removeRow={this.removeRow.bind(this)}
								getData={this.getData.bind(this)} />
						}
					</div>
				}
				{!_.isUndefined(bonusState.Rule) &&
					<RuleItem editId={this.setId.bind(this)} />
				}
			</div>
		)
	}
}
BonusPage = observer(BonusPage);

class BonusTable extends Component {
	onSortEnd = ({ oldIndex, newIndex }) => {
		bonusState.Rules = arrayMove(bonusState.Rules, oldIndex, newIndex);
		this.changeOrder();
	};

	changeOrder() {
		var model = this;
		getRequest("ChangeOrderBonusRules", bonusState.getOrderData(), function () { model.props.getData(); })
	}

	render() {
		var view = this,
			height = uistate.WindowDimensions.height - uistate.HeaderHeight - uistate.PaddingHeight
				- uistate.TitleHeight - 30,
			style = { height: height };

		var SortableItem = SortableElement(({ value, sortIndex }) => {
			return (
				<TableItem Item={value} Index={sortIndex} editId={view.props.editId} removeRow={view.props.removeRow}/>
			)
		}),
			SortableList = SortableContainer(({ items }) => {
				return (
					<div className="SortableList">{items.map((value, index) => (
						<SortableItem key={"ag" + index} index={index} value={value} sortIndex={index} />
					))}</div>
				);
			});

		return (
			<div className="table">
				<div className="titles clearfix">
					<span className="col col10" title="Дата начала действия">Дата начала</span>
					<span className="col col10" title="Дата окончания действия">Дата окончания</span>
					<span className="col col45" title="Описание">Описание</span>
					<span className="col col10" title="Пользователь">Пользователь</span>
					<span className="col col10" title="Макс. кол-во применений">Макс.</span>
				</div>
				<div className="clearfix tableBody" style={style}>
					<SortableList
						items={bonusState.Rules}
						onSortEnd={this.onSortEnd}
						useDragHandle
						helperClass="SortableHelper"
						axis="xy"
						lockToContainerEdges={true}
						disableAutoscroll={true}
					/>
				</div>
			</div>
		)
	}
}
BonusTable = observer(BonusTable)

class TableItem extends Component {
	handleEdit() { this.props.editId(this.props.Item.Id); }

	handleRemove() { this.props.removeRow(this.props.Item.Id); }

	render() {
		var rule = this.props.Item,
			active = this.props.Item.Active;

		return (
			<div className={"clearfix item orderSortItem " + (!active ? "notactive" : "")}>
				{checkRights("ManageBonusRules") && bonusState.Active === 1 && <DragHandle />}

				<span className="col col10">{formatDate(rule.StartDate)}</span>
				<span className="col col10">{formatDate(rule.EndDate)}</span>
				<span className="col col45" title={rule.Description}>{rule.Description}</span>
				<span className="col col10">{rule.Customer}</span>
				<span className="col col10">{rule.MaxCount === 0 ? "" : rule.MaxCount}</span>

				{checkRights("ManageBonusRules") &&
					<span className="col edit"><button className="icon edit" title="Редактировать"
						onClick={this.handleEdit.bind(this)}><i></i></button></span>
				}
				<span className="col edit">
					{checkRights("ManageBonusRules") && rule.Active &&
						<button className="remove icon" title="Удалить" onClick={this.handleRemove.bind(this)}><i></i></button>
					}
				</span>
				<span className="col edit">
					{checkRights("ManageBonusRules") && !rule.Active &&
						<button className="activate icon" title="Восстановить" onClick={this.handleRemove.bind(this)}><i></i></button>
					}
				</span>
			</div>
		)
	}
}

class RuleItem extends Component {
	handleSubmit(event) {
		event.preventDefault();
		
		if (!bonusState.validateData()) return false;

		bonusState.ButtonLoading = true;

		getRequest("SaveBonusRule", bonusState.getSaveData(), function (data) {
			bonusState.SuccessSave = data.Success;
			bonusState.ErrorCode = data.Success ? "SUCCESS_SAVE" : data.ErrorCode;
			bonusState.ButtonLoading = false;

			bonusState.Rule.Id = data.Success ? data.Id : bonusState.Rule.Id;
		});
	}

	handleChange(value, id) { bonusState.collectItem(id, value); }

	handleClose(event) {
		event.preventDefault();
		this.props.editId(undefined);
	}

	render() {
		var style = { height: uistate.sectionHeight - 5 },
			title = (bonusState.Rule.Id === -1 ? "New" : "Edit") + "BonusRule",
			isScore = _.indexOf(_.map(bonusState.Actions, "GroupType"), 1) !== -1;

		return (
			<section className="clearfix item two-cols" id="RuleItem">
				<form onSubmit={this.handleSubmit.bind(this)}>
					<ItemHeader
						Title={buttonTitles[title]}
						Error={bonusState.ErrorCode}
						Loading={bonusState.ButtonLoading}
						CanManage={checkRights("ManageBonusRule")}
						Success={bonusState.SuccessSave}
						handleClose={this.handleClose.bind(this)}
					/>
					<div className="clearfix section" style={style}>
						<div className="clearfix">
							<div className="col col70">
								<TextInput
									Id="Description"
									Type="textarea"
									Title="Описание правила"
									RowClass="block"
									Value={bonusState.Rule.Description}
									onChange={this.handleChange.bind(this)}
								/>
								{!_.isEmpty(bonusState.Conditions) && <ConditionBlock Conditions={bonusState.Conditions} />}
								{!_.isEmpty(bonusState.Actions) && <ActionBlock Actions={bonusState.Actions} 
									Type="Rule" UseProducts={bonusState.UseProducts} Products={bonusState.SystemProducts} />}
							</div>
							<CommonSettings Item={bonusState.Rule} Type="Rule" IsScore={isScore} />
						</div>
					</div>
				</form>
			</section>
		)
	}
}
RuleItem = observer(RuleItem);

class CommonSettings extends Component {
	handleChange(value, id) { 
		if (this.props.Type === "Rule") bonusState.collectItem(id, value); 
		else if (this.props.Type === "Promocode") promocodeState.collectItem(id, value); 
	}
	render () {
		return(
			<div className="col col30 gray">
				<DateInput
					Id="StartDate"
					WithIcon={true}
					Title="Дата начала действия правила"
					RowClass="date"
					// Value={bonusState.Rule.StartDate}
					Value={this.props.Item.StartDate}
					onChange={this.handleChange.bind(this)}
				/>
				<DateInput
					Id="EndDate"
					WithIcon={true}
					Title="Дата окончания действия правила"
					// Value={bonusState.Rule.EndDate}
					Value={this.props.Item.EndDate}
					RowClass="date"
					onChange={this.handleChange.bind(this)}
				/>
				{this.props.IsScore &&
					<TextInput
						Id="BonusDuration"
						Title="Срок действия бонусов"
						Type="number"
						// Value={bonusState.Rule.BonusDuration}
						Value={this.props.Item.BonusDuration}
						onChange={this.handleChange.bind(this)}
					/>
				}
				<TextInput
					Id="MaxCount"
					Title="Макс. кол-во использований"
					Type="number"
					// Value={bonusState.Rule.MaxCount}
					Value={this.props.Item.MaxCount}
					onChange={this.handleChange.bind(this)}
				/>
				{checkRights("ManageCustomerBonusRule") &&
					<Selectize
						Id="Customer"
						List={[]}
						Disabled={true}
						Title="Индивидуальное правило на пользователя"
						// Value={bonusState.Rule.Customer}
						Value={this.props.Item.Customer}
						onChange={this.handleChange.bind(this)}
					/>
				}
				{this.props.Type === "Rule" && 
					<CheckGroup
						Id="IsFinal"
						RowClass="nomargin"
						Type="checkbox"
						List={[{ Id : "true", Title : "Принудительно закончить обработку при срабатывании правила" }]}
						// Value={bonusState.Rule.IsFinal ? ["true"] : ""}
						Value={this.props.Item.IsFinal ? ["true"] : ""}
						onChange={this.handleChange.bind(this)}
					/>
				}
			</div>
		)
	}
}
CommonSettings = observer(CommonSettings);

class ConditionBlock extends Component {
	render() {
		return(
			<div className="clearfix block conditions">
				<h4>Условие</h4>
				{_.map(this.props.Conditions, function (item, i) {
					return(<ConditionItem Item={item} Index={i} key={i} />)
				})}
			</div>
		)
	}
}
ConditionBlock = observer(ConditionBlock);

class ConditionItem extends Component {
	handleChange(value, id) { bonusState.collectCondition(id, value, this.props.Index); }

	render() {
		var item = this.props.Item,
			ordersTypes = [{ Id : 1, Name : "Сумма всех заказов" }, { Id : 7, Name : "Количество всех заказов" }],
			countOperands = [
					{ Id : "=",  Name : "Равно" }, { Id : "!=", Name : "Не равно" }, { Id : ">",  Name : "Больше" },
					{ Id : ">=", Name : "Больше или равно" }, { Id : "<",  Name : "Меньше" }, { Id : "<=", Name : "Меньше или равно" },
				],
			listOperands = [
				{ Id : "inc", Name : "Включая одно" },
				{ Id : "incall", Name : "Включая все" },
				{ Id : "exc", Name : "Исключая" }
			],
			dateTypes = [
				{ Id : "date", Name : "Точная дата" }, { Id : "w", Name : "Дни недели" }, { Id : "m", Name : "Числа месяца" },
			],
			weekDays = _.map(daysLong, function (v, i) { return { Id : i, Name : v }; });

		return(
			<div className="clearfix item">
				<div className="col col75">
					<Selectize
						Id="GroupType"
						Title="Что проверять?"
						List={toSelectList(ConditionGroupTypes)}
						Value={item.GroupType}
						RowClass="col col100"
						onChange={this.handleChange.bind(this)}
					/>
					{item.GroupType === 1 &&
						<ButtonGroup 
							Id="Type"
							List={ordersTypes}
							Value={item.Type}
							Type="number"
							RowClass="col col100"
							onChange={this.handleChange.bind(this)}
						/>
					}
					{_.indexOf([1,2], item.GroupType) !== -1 &&
						<Selectize
							Id="Operand"
							Title="Сравнение"
							List={toSelectList(countOperands)}
							RowClass="col col50"
							Value={item.Operand}
							onChange={this.handleChange.bind(this)}
						/>
					}
					{_.indexOf([1,2], item.GroupType) !== -1 &&
						<TextInput
							Id={item.Type === 7 ? "Count" : "Amount"}
							Title={item.Type === 7 ? "Количество" : "Сумма"}
							Type="number"
							RowClass="col col50"
							Step={item.Type === 7 ? "1" : "0.01"}
							Value={item.Type === 7 ? item.Count : item.Amount}
							onChange={this.handleChange.bind(this)}
						/>
					}
					{item.GroupType === 3 &&
						<ButtonGroup
							Id="Operand"
							List={listOperands}
							Value={item.Operand}
							RowClass="col col100"
							onChange={this.handleChange.bind(this)}
						/>
					}
					{item.GroupType === 3 && !bonusState.UseProducts &&
						<TextInput
							Id="VendorCodes"
							Title="Артикулы товаров через «,»"
							Value={item.VendorCodes}
							Type="textarea"
							RowClass="col col100"
							onChange={this.handleChange.bind(this)}
						/>
					}
					{item.GroupType === 3 && bonusState.UseProducts &&
						<Selectize
							Id="ProductIds"
							Title="Товары из списка"
							Value={item.ProductIds}
							List={toSelectList(bonusState.SystemProducts)}
							Multi={true}
							RowClass="col col100"
							onChange={this.handleChange.bind(this)}
						/>
					}
					{item.GroupType === 4 &&
						<ButtonGroup
							Id="DateType"
							List={dateTypes}
							Value={item.DateType}
							RowClass="col col100"
							onChange={this.handleChange.bind(this)}
						/>
					}
					{item.GroupType === 4 && item.DateType === "date" &&
						<DateInput
							Id="Date"
							Title="Дата"
							WithIcon={true}
							Value={item.Date}
							RowClass="date col col50"
							onChange={this.handleChange.bind(this)}
						/>
					}
					{item.GroupType === 4 && item.DateType === "w" &&
						<CheckGroup
							Id="PeriodWeek"
							Type="checkbox"
							Title="Дни недели"
							List={toCheckList(weekDays)}
							Value={item.PeriodWeek}
							RowClass="col col100 week"
							onChange={this.handleChange.bind(this)}
						/>
					}
					{item.GroupType === 4 && item.DateType === "m" &&
						<TextInput
							Id="PeriodMonth"
							Title="Числа месяца через «,»"
							Value={item.PeriodMonth}
							RowClass="col col100"
							onChange={this.handleChange.bind(this)}
						/>
					}
				</div>
				{_.find(ConditionGroupTypes, { Id : item.GroupType }) &&
					<div className="col col25 comment">
						{_.find(ConditionGroupTypes, { Id : item.GroupType }).Description}
					</div>
				}
			</div>
		)
	}
}
ConditionItem = observer(ConditionItem);
class ActionBlock extends Component {
	render() {
		var view = this;

		return(
			<div className="clearfix block actions">
				<h4>Действие</h4>
				{_.map(this.props.Actions, function (item, i) {
					return(<ActionItem Item={item} Index={i} key={i} Type={view.props.Type} 
						UseProducts={view.props.UseProducts} Products={view.props.Products} />)
				})}
			</div>
		)
	}
}
ActionBlock = observer(ActionBlock);

class ActionItem extends Component {
	handleChange(value, id) { 
		if (this.props.Type === "Rule") bonusState.collectAction(id, value, this.props.Index); 
		else if (this.props.Type === "Promocode") promocodeState.collectAction(id, value, this.props.Index); 
	}

	handleAdd(event) {
		event.preventDefault();

		if (this.props.Type === "Rule")
			bonusState.Actions[this.props.Index].Products = _.concat(bonusState.Actions[this.props.Index].Products,
				_.clone(bonusState.ProductDummy));
		else if (this.props.Type === "Promocode")
			promocodeState.Actions[this.props.Index].Products = _.concat(promocodeState.Actions[this.props.Index].Products,
				_.clone(promocodeState.ProductDummy));
	}

	render() {
		var view = this,
			item = this.props.Item,
			groups = this.props.Type === "Rule" ? ActionGroupTypes : _.filter(ActionGroupTypes, function(v) {
				return v.Id !== 4;
			}),
			countTypes = [
				{ Id : item.GroupType === 1 ? 1 : 3, Name : "Процент от суммы заказа" },
				{ Id : item.GroupType === 1 ? 2 : 4, Name : item.GroupType === 1 ? "Фикс. кол-во" : "Фикс. сумма" }
			],
			excludeTypes = [
				{ Id : 6, Name : "На товар из списка не начисляются баллы/скидка" },
				{ Id : 7, Name : "Товар из списка не учитывается при расчете суммы для подарков" },
			];

		return(
			<div className="clearfix item">
				<div className="col col75">
					<Selectize
						Id="GroupType"
						Title="Результат проверки"
						List={toSelectList(groups)}
						Value={item.GroupType}
						RowClass="col col100"
						onChange={this.handleChange.bind(this)}
					/>
					{_.indexOf([1,2], item.GroupType) !== -1 &&
						<TextInput
							Id="Amount"
							Title={item.GroupType === 1 ? "Количество баллов" : "Скидка"}
							Type="number"
							Value={item.Amount}
							RowClass="col col20"
							onChange={this.handleChange.bind(this)}
						/>
					}
					{_.indexOf([1,2], item.GroupType) !== -1 &&
						<ButtonGroup
							Id="ActionType"
							List={countTypes}
							Value={item.ActionType}
							Type="number"
							RowClass="col col80"
							onChange={this.handleChange.bind(this)}
						/>
					}
					{item.GroupType === 4 &&
						<CheckGroup
							Id="ActionType"
							Type="radio"
							List={toCheckList(excludeTypes)}
							Value={[item.ActionType]}
							RowClass="col col100"
							onChange={this.handleChange.bind(this)}
						/>
					}
					{item.GroupType === 3 &&
						<div className="clearfix products col col100">
							{_.map(this.props.Item.Products, function (product, i) {
								return(<ActionProductItem Item={product} key={i} Index={i} Type={view.props.Type}
									ActionIndex={view.props.Index} Products={view.props.Products}
									UseProducts={view.props.UseProducts}/>)
							})}
							<a href="/" className="button add" onClick={this.handleAdd.bind(this)}>Добавить товар</a>
						</div>
					}
				</div>
				{_.find(ActionGroupTypes, { Id : item.GroupType }) &&
					<div className="col col25 comment">
						{_.find(ActionGroupTypes, { Id : item.GroupType }).Description}
					</div>
				}
			</div>
		)
	}
}
ActionItem = observer(ActionItem);

class ActionProductItem extends Component {
	handleChange(value, id) {
		if (this.props.Type === "Rule") bonusState.collectAction(id, value, this.props.ActionIndex, this.props.Index);
		else if (this.props.Type === "Promocode") 
			promocodeState.collectAction(id, value, this.props.ActionIndex, this.props.Index);
	}

	handleDelete(event) {
		event.preventDefault();

		var index = this.props.Index,
			action = this.props.ActionIndex;

		if (this.props.Type === "Rule") bonusState.Actions[action].Products = 
			_.filter(bonusState.Actions[action].Products, function (v,i) { return i !== index; });
		else if (this.props.Type === "Promocode") promocodeState.Actions[action].Products = 
			_.filter(promocodeState.Actions[action].Products, function (v,i) { return i !== index; });
	}

	render() {
		return(
			<div className="clearfix product col col100">
				{!this.props.UseProducts &&
					<TextInput
						Id="VendorCode"
						Title="Артикул товара"
						RowClass="col col70"
						Value={this.props.Item.VendorCode}
						onChange={this.handleChange.bind(this)}
					/>
				}
				{this.props.UseProducts &&
					<Selectize
						Id="ProductId"
						Title="Товар"
						List={toSelectList(this.props.Products)}
						RowClass="col col70"
						Value={this.props.Item.ProductId}
						onChange={this.handleChange.bind(this)}
					/>
				}
				<TextInput
					Id="Amount"
					Title="Новая цена товара"
					RowClass="col col30"
					Type="number"
					Step="0.01"
					Value={this.props.Item.Amount}
					onChange={this.handleChange.bind(this)}
				/>
				<a href="/" className="button icon remove" onClick={this.handleDelete.bind(this)}><i></i></a>
			</div>
		)
	}
}
ActionProductItem = observer(ActionProductItem);

export class PromocodePage extends Component {
	constructor(props) {
		super(props);

		promocodeState.setItem(undefined);

		this.state = {}

		this.getData();

		getRequest("Products", { Type : 3, Active : true }, function(data) { promocodeState.setProducts(data); });
	}

	getData() {
		bonusState.IsSendRequest = false;
		getRequest("Promocodes", { Active : promocodeState.Active }, function (data) { promocodeState.setList(data); });
	}

	setId(id) {
		if (_.isUndefined(id)) {
			promocodeState.setItem(undefined);
			this.getData();
		} else if (id === -1) promocodeState.setItem({ Id: -1 });
		else this.getItem(id);
	}

	getItem(id) {
		getRequest("Promocode", { Id: id }, function (data) { promocodeState.setItem(data.Success ? data : undefined); });
	}

	removeRow(id) {
		if (_.isUndefined(id)) return false;

		var model = this,
			item = _.find(promocodeState.Promocodes, { Id: id }),
			text = <span>Вы действительно хотите {item.Active ? "деактивировать" : "активировать"} промокод
						&nbsp;<b key="b1">«{item ? item.Promocode : ""}»</b>?</span>;

		confirmState.openConfirm(text, function () {
			getRequest("ChangeStatePromocode", { Id: id }, function () { model.getData(); });
		});
	}

	setActive(event) {
		event.preventDefault();
		promocodeState.Active = promocodeState.Active === 1 ? -1 : 1;
		this.getData();
	}

	render() {
		var columns = { StartDate : {}, EndDate : {}, Promocode : {}, ActionType : {}, Customer : {}, MaxCount : {}};

		if (checkRights("ManageBonusPromocodes")) columns = _.extend(columns, { Edit : {}, Remove : {} });
		
		return (
			<div className={"page " + this.props.Type} id="PromocodePage">
				{_.isUndefined(promocodeState.Promocode) && promocodeState.IsSendRequest &&
					<TableFilterPage 
						Type="BonusPromocodes"
						CanManage={checkRights("ManageBonusPromocodes") && promocodeState.Active === 1}
						
						ActiveFilter={true}
						ShowActive={promocodeState.Active === 1}
						
						TableData={promocodeState.Promocodes}
						TableColumns={columns}

						setActive={this.setActive.bind(this)}
						setId={this.setId.bind(this)}
						removeId={this.removeRow.bind(this)}
						getTrProps={(state, rowInfo, column) => {
							return { className: rowInfo && !rowInfo.original.Active ? "notactive" : "" };
						}}
					/>
				}
				{!_.isUndefined(promocodeState.Promocode) && <PromocodeItem editId={this.setId.bind(this)} />}
			</div>
		)
	}
}
PromocodePage = observer(PromocodePage);


class PromocodeItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			Generate : false
		}
	}

	handleSubmit(event) {
		event.preventDefault();
		console.log(promocodeState.getSaveData());
		if (!promocodeState.validateData()) return false;

		promocodeState.ButtonLoading = true;

		getRequest("SavePromocode", promocodeState.getSaveData(), function (data) {
			promocodeState.SuccessSave = data.Success;
			promocodeState.ErrorCode = data.Success ? "SUCCESS_SAVE" : data.ErrorCode;
			promocodeState.ButtonLoading = false;

			promocodeState.Promocode.Id = data.Success ? data.Id : promocodeState.Promocode.Id;
		});
	}

	handleChange(value, id) { promocodeState.collectItem(id, value); }

	handleClose(event) {
		event.preventDefault();
		this.props.editId(undefined);
	}

	handlePromocode(value, id) { promocodeState.PromocodeChars = value; }

	toggleGenerate(event) {
		event.preventDefault();
		this.setState({ Generate : !this.state.Generate });
	}

	generatePromocode(event) {
		event.preventDefault();
		getRequest("GeneratePromocode", { Length : promocodeState.PromocodeChars }, function (data) {
			promocodeState.ErrorCode = data.Success ? "" : data.ErrorCode;
			promocodeState.Promocode.Promocode = data.Success ? data.Promocode : promocodeState.Promocode.Promocode;
		});
	}

	render() {
		var style = { height: uistate.sectionHeight - 5 },
			title = (promocodeState.Promocode.Id === -1 ? "New" : "Edit") + "Promocode",
			isScore = _.indexOf(_.map(promocodeState.Actions, "GroupType"), 1) !== -1;

			//TODO generate promocode

		return (
			<section className="clearfix item two-cols" id="PromocodeItem">
				<form onSubmit={this.handleSubmit.bind(this)}>
					<ItemHeader
						Title={buttonTitles[title]}
						Error={promocodeState.ErrorCode}
						Loading={promocodeState.ButtonLoading}
						CanManage={checkRights("ManageBonusPromocodes")}
						Success={promocodeState.SuccessSave}
						handleClose={this.handleClose.bind(this)}
					/>
					<div className="clearfix section" style={style}>
						<div className="clearfix">
							<div className="col col70">
								<div className="block clearfix">
									<TextInput
										Id="Promocode"
										Required={true}
										Title="Промокод"
										RowClass="col col70 promocode"
										MinLength={4}
										Value={promocodeState.Promocode.Promocode}
										onChange={this.handleChange.bind(this)}
									/>
									<div className="col col30 generatePromo">
										<a href="/" className="dotted" onClick={this.toggleGenerate.bind(this)}>
											Сгенерировать промокод</a>
										{this.state.Generate &&
											<div className="clearfix">
												<TextInput
													Id="PromocodeChars"
													Type="Number"
													Title="Длина промокода"
													RowClass="col col50"
													Value={promocodeState.PromocodeChars}
													onChange={this.handlePromocode.bind(this)}
												/>
												<a href="/" className="button" onClick={this.generatePromocode.bind(this)}>
													Создать</a>
											</div>
										}
									</div>
								</div>
								{!_.isEmpty(promocodeState.Actions) && 
									<ActionBlock Actions={promocodeState.Actions} Type="Promocode" 
										UseProducts={promocodeState.UseProducts} Products={promocodeState.SystemProducts}  />
								}
							</div>
							<CommonSettings Item={promocodeState.Promocode} Type="Promocode" IsScore={isScore} />
						</div>
					</div>
				</form>
			</section>
		)
	}
}
PromocodeItem = observer(PromocodeItem);