import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import moment from 'moment';
import Parser from 'html-react-parser';

import loader from "../images/loader.svg";

import { toSelectList, toCheckList, getRequest, getHourTime, formatDate } from '../functions';
import { buttonTitles, errors } from '../dictionary';

import { TextInput, CheckGroup, Selectize, DateInput, DatePreset } from '../forms/FormItems';
import { TableReportPage } from './PageTemplates';

import { authUserState, uistate, confirmState } from '../stores/common';
import { userReport, shiftTransferState, shiftReportState } from '../stores/userreports';

export class UserReport extends Component {
	constructor(props) {
		super(props);

		userReport.setDefault();

		this.state = {
			IsSentReport : false,
			SendRequest : false,
			GroupReport : false,
			IsAutoSend : false
		}

		this.getTemplate = this.getTemplate.bind(this);
		this.getPreviousReports = this.getPreviousReports.bind(this);
		this.saveReport = this.saveReport.bind(this);

		if (authUserState.Shift.Active) {
			this.getTemplate();
			this.getPreviousReports();
		} else userReport.setReport(undefined);
	}

	getTemplate() {
		var model = this;
		getRequest("ReportTemplate", {}, function (data) {
			model.setState({ 
				Template: data.Success ? data.Fields : [],
				GroupReport : data.Success ? data.GroupReport : false
			});
			if (data.Success) model.getReport();
		});
	}

	getReport () {
		var model = this;

		getRequest("LastReport", {}, function (data) {
			var currentReport = _.find(data.Reports, { UserId : authUserState.User.Id }),
				fields = _.isUndefined(currentReport) ? {} : currentReport.Fields,
				IsSent = !_.isUndefined(currentReport) ? currentReport.IsSent : false;

			userReport.setReport(model.state.Template, fields, model.state.TemplateId, 
				currentReport ? currentReport.Id : -1, IsSent);
			model.setState({ 
				SendRequest : true,
				IsSentReport : IsSent,
				GroupReports : _.filter(data.Reports, function(v) { 
					return v.UserId !== authUserState.User.Id; })
			});
		});
	}

	getPreviousReports () {
		var model = this;
		getRequest("PreviousShiftReports", {}, function (data) { model.setState({ LastReports : data.Reports }); });
	}

	handleSubmit (event) {
		event.preventDefault();
		this.saveReport(false);

		userReport.ButtonSave = true;
	}

	onClickSend (event) {
		event.preventDefault();

		if (!userReport.validateReport()) userReport.ErrorCode = "EMPTY_REQUIRED";
		else {
			this.setState({ IsAutoSend : true });
			var text = <span>Вы действительно хотите отправить отчет о смене? Дальнейшие изменения будут невозможны!</span>;

			confirmState.openConfirm(text, this.saveReport);
		}
	}

	saveReport (afterSend) {
		var model = this;

		afterSend = this.state.IsAutoSend;

		if (afterSend) userReport.ButtonSend = true;

		getRequest("SaveReport", userReport.getSaveData(), function (data) {
			if (data.Success) {
				if (afterSend) model.sendReport(data.Id);
				else model.getReport();

				model.setState({ IsAutoSend : false });
			} 
			userReport.ErrorCode = data.ErrorCode;
			userReport.ButtonSave = false;
		});
	}

	sendReport (id) {
		var model = this;
		getRequest("SendReport", { Id : id }, function (data) {
			if (data.Success) model.getReport();

			userReport.ErrorCode = data.ErrorCode;
			userReport.ButtonSend = false;
			model.setState({ isConfirmState : false });
		});
	}

	/** Изменение активной вкалдки страницы */
	changeButton(event) {
		event.preventDefault();
		userReport.ActiveState = event.currentTarget.getAttribute("rel");
	}

	render() {
		var sectionStyle = { height: uistate.sectionHeight - 5, minHeight : 200 },
			isReport = this.state.SendRequest ? !_.isEmpty(userReport.Report) : false;

		return (
			<div className={"page " + (uistate.IsMobile ? "mobile" : "")} id="UserReport">
				<div className="head clearfix">
					<h2>Отчеты о смене</h2>
					{isReport && !this.state.IsSentReport &&
						<div className="buttons">
							<button className={"green " + (userReport.ButtonSend ? "loading" : "")} 
								disabled={userReport.ButtonSend} onClick={this.onClickSend.bind(this)}>
									{userReport.ButtonSend && <img src={loader} alt="loading" /> }
									{!userReport.ButtonSend && "Сохранить и отправить" }
							</button>
							<button className={"blue" + (userReport.ButtonSave ? "loading" : "")} 
								disabled={userReport.ButtonSave} onClick={this.handleSubmit.bind(this)}>
									{userReport.ButtonSave && <img src={loader} alt="loading" /> }
									{!userReport.ButtonSave && "Сохранить" }
							</button>
						</div>	
					}
					{userReport.ErrorCode !== "" &&
						<p className="error">{errors[userReport.ErrorCode] || errors.DEFAULT}</p>
					}
				</div>
				<section className="clearfix" style={sectionStyle}>
					{uistate.IsMobile &&
						<div className="clearfix tabs">
							<a className={userReport.ActiveState === "form" ? "activeLink" : ""}
								rel="form" href="/" onClick={this.changeButton.bind(this)}>Отчет за сегодня</a>
							<a className={userReport.ActiveState === "report" ? "activeLink" : ""}
								rel="report" href="/" onClick={this.changeButton.bind(this)}>Предыдущий отчет</a>
						</div>
					}
					<div className="clearfix">
						{(!uistate.IsMobile || (uistate.IsMobile && userReport.ActiveState === "form")) &&
						<div className={"form " + (uistate.IsMobile ? "clearfix" : "col col60")}>
							{!authUserState.Shift.Active &&
								<h4>Откройте смену для просмотра и написания отчетов</h4>
							}
							{authUserState.Shift.Active && !isReport &&
								<h4>Для вашей должности нет бланков отчета о смене</h4>
							}
							{authUserState.Shift.Active && isReport &&
								<form onSubmit={this.handleSubmit.bind(this)}>
									{_.map(userReport.Groups, function (group) {
										return(<GroupTemplate key={group} Name={group}
											Fields={_.pickBy(userReport.Report, { Group : group })} />)
									})}
								</form>
							}
						</div>
						}
						{(!uistate.IsMobile || (uistate.IsMobile && userReport.ActiveState === "report")) &&
							<div className={"reports " + (uistate.IsMobile ? "clearfix" : "col col40")}>
								{authUserState.Shift.Active && isReport && this.state.GroupReport &&
									<PrevReport Reports={this.state.GroupReports} Type="Current" Group={true} />
								}
								{authUserState.Shift.Active && isReport &&
									<PrevReport Reports={this.state.LastReports} Type="Last" Group={this.state.GroupReport} />
								}
							</div>
						}
					</div>
				</section>
			</div>
		)
	}
}
UserReport = observer(UserReport);

class GroupTemplate extends Component {
	onChange (value, id) { userReport.collectReport (id, value); }

	render() {
		var view = this,
			fields = _.sortBy(this.props.Fields, "Id");

		return(
			<div className="clearfix group">
				<h4>{this.props.Name}</h4>
				{_.map(fields, function(item) {
					return(<TextInput
						key={"f" + item.Id}
						Id={"Field_" + item.Id}
						Title={item.Name}
						Type={item.Type === "Text" ? "textarea" : "number"}
						// RowClass={item.Type}
						RowClass={item.Type === "Text" ? "col col100" : "col col50"}
						Value={item.Value}
						Focused={true}
						Disabled={item.Disabled}
						MarkRequired={item.Required}
						onChange={view.onChange.bind(view)}
					/>)
				}) }
			</div>
		)
	}
}
GroupTemplate = observer(GroupTemplate);

class PrevReport extends Component {
	render() {
		var view = this,
			title = this.props.Type === "Last" ? "Отчеты о предыдущей смене" : "Текущие отчеты",
			isGroup = this.props.Group,
			fields = _.filter(this.props.Reports, function (v) { return !_.isNull(v.Fields); });

		if (isGroup)
			fields = _.map(fields, function(v) { 
				return _.map(v.Fields, function (i) { return _.extend(i, { User : v.User, Date : v.Date }); });
			});
		else fields = _.map(fields, function (v) { return v.Fields });

		fields = _.groupBy(_.flatten(fields), "Name");

		return(
			<div className="clearfix">
				<h4>{title}</h4>
				{!isGroup && _.map(this.props.Reports, function (v,i) { 
					return(<p className="clearfix comment" key={"u" + i}> 
							<span className="name col col60">{v.User}</span>
					 		<span className="date col col40">{moment(v.Date).format("DD.MM.YYYY HH:mm")}</span>
				 		</p>)
				})}
				<div className="clearfix">
					{!_.isEmpty(fields) && _.map(fields, function (item, name) {
						return(<PrevReportItem Fields={item} Name={name} key={"f" + name} 
							Group={isGroup} Type={view.props.Type}/>)
					})}
					{_.isEmpty(fields) &&
						<div className="clearfix field"><p>Пользователь не оставил отчета о смене</p></div>
					}
				</div>
			</div>
		)
	}
}
PrevReport = observer(PrevReport);

class PrevReportItem extends Component {
	render() {
		if (!_.some(this.props.Fields, 'Text')) return null;
		
		var view = this;

		return(
			<div className="clearfix field">
				<h6>{Parser(this.props.Name)}</h6>
				{_.map(this.props.Fields, function (field, i) {
					var date = moment(field.Date).format("DD.MM.YYYY HH:mm");

					if (field.Text !== "") return(<div className="clearfix" key={"fe" + i}>
							{view.props.Group && <p className="user clearfix">
								<span className="name col col60">{field.User}</span>
								{view.props.Type === "Last" && <span className="date col col40">{date}</span>}
							</p>}
							<p className="clearfix">{field.Text === "-1" ? "" : Parser(field.Text)}</p>
						</div>)
				}) }
			</div>
		)
	}
}

export class ShiftTransferReport extends Component {
	render() { return(<ShiftTransferCommonReport GroupType={1} Type="ShiftTransferReport" />) }
}
export class ShiftSecretReport extends Component {
	render() { return(<ShiftTransferCommonReport GroupType={2} Type="ShiftSecretReport" />) }
}

class ShiftTransferCommonReport extends Component {
	constructor(props) {
		super(props);

		shiftTransferState.setDefault(this.props.GroupType);

		this.state = {
			IsOpen : false,
		}

		this.getData = this.getData.bind(this);

		this.getData();

		var model = this;
		getRequest("Filials", {}, function (data) { model.setModel(data, "Filials"); });
		getRequest("Roles", {}, function (data) { model.setModel(data, "Roles"); });
		getRequest("Users", { Active : true }, function (data) { shiftTransferState.setUsers(data); });
	}

	/**
	 * Добавление данных в состояние компонента
	 * @param {object} data ответ от сервера
	 * @param {string} param название параметра
	 */
	setModel(data, param) { this.setState({ [param] : data.Success ? data[param] : [] }); }

	/** API запрос на получение данных от сервера */
	getData () {
		shiftTransferState.IsSendRequest = false;
		getRequest("AllReports", shiftTransferState.getFilter(), function(data) {
			shiftTransferState.setReports(data);
		});
	}
	
	/** Показать/скрыть фильтр по отчетам */
	toggleFilter (event) {
		event.preventDefault();
		this.setState({ IsOpen : !this.state.IsOpen });
	}

	/** 
	 * Изменение активной страницы
	 * @param {number} сдвиг на кол-во записей в ответе
	 */
	changePage (offset) { 
		shiftTransferState.Filter.Offset = offset;
		this.getData();
	}

	/**
	 * API запрос на обработку критического отчета
	 * @param {number} id 
	 * @param {boolean} isChecked 
	 */
	checkReport (id, isChecked) {
		if (isChecked) return false;

		var model = this,
			text = <span>Вы действительно хотите отметить этот отчет как обработанный?</span>;

		confirmState.openConfirm(text, function() {
			getRequest("MarkReport", { Id : id }, function () { model.getData(); });
		});
	}
	
	/**
	 * Сбор данных с формы поиска отчетов
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange (value, id) { shiftTransferState.collectFilter(id, value); }

	/** Сабмит формы поиска отчетов */
	handleSubmit (event) {
		event.preventDefault();
		shiftTransferState.Filter.Offset = 0;
		this.getData();
	}

	/**
	 * Подсветка строк в таблице в зависимости от данных
	 * @param {object} info 
	 */
	getRowClass (info) {
		if (_.isUndefined(info)) return "";
		var rowClass = "";

		if (info.IsAutoSend) rowClass += " notSend";
		if (info.IsAlerted && this.props.Type === "ShiftSecretReport" && !info.IsChecked) rowClass += " alert";

		return rowClass;
	}

	render() {
		var columns = { Role : {}, Filial : {}, User : {}, Date : {} },
			title = this.props.GroupType === 1 ? "Отчет о передачах смены" : "Отчеты о прошедших сменах";

		if (uistate.IsMobile) columns = {
			User : { 
				Cell: row => (<span>{row.original.User}
					<span className="comment">{row.original.Filial}, {!shiftReportState.IsCourier && row.original.Role}</span> 
				</span>)},
			Date : {}
		}

		if (this.props.GroupType === 2 ) columns = _.extend(columns, { Check : {} });

		return(
			<div className={"page " + (this.state.isOpen ? "open" : "close") + (uistate.IsMobile ? " mobile" : "")} id="ShiftTransferReport">
				<div className="head">
					<h2>{title}</h2>
					<a href="/" className={"toggleFilter " + (this.state.isOpen ? "active" : "")} 
						title="Фильтр поиска" onClick={this.toggleFilter.bind(this)}><i></i></a>
				</div>
				{this.state.IsOpen && 
					<ShiftTransferFilter
						GroupType={this.props.GroupType}
						Users={this.state.Users}
						Filials={this.state.Filials}
						Roles={_.filter(this.state.Roles, { IsCourier : false })}

						handleSubmit={this.handleSubmit.bind(this)}
						handleChange={this.handleChange.bind(this)}
					/>
				}
				{!_.isEmpty(shiftTransferState.Reports) && 
					<TableReportPage
						TableData={shiftTransferState.Reports}
						Type={this.props.Type}
						TableColumns={columns}

						SubComponent={row => {return (<ShiftSubComponent Info={row.original.Fields} />); }}
						
						FilterHeight={this.state.isOpen ? 125 : 0}
						Pagination={true}
						
						Limit={shiftTransferState.Limit}
						HasMore={shiftTransferState.HasMore}
						Offset={shiftTransferState.Filter.Offset}

						getTrProps={(state, rowInfo, column) => {
							return { className: this.getRowClass(rowInfo.original)  };
						}}
						changePage={this.changePage.bind(this)}
						checkRow={this.checkReport.bind(this)}
					/>
				}
				{shiftTransferState.IsSendRequest && _.isEmpty(shiftTransferState.Reports) &&
					<p>{errors.EMPTY_RESPONSE}</p>
				}
			</div>
		)
	}
}
ShiftTransferCommonReport = observer(ShiftTransferCommonReport);

class ShiftTransferFilter extends Component {
	render() {
		return(
			<form onSubmit={this.props.handleSubmit} className="filter clearfix">
				<div className={uistate.IsMobile ? "clearfix" : "col col80"}>
					<Selectize 
						Id="UserId"
						Title="Пользователь"
						List={toSelectList(shiftTransferState.Users)}
						Value={shiftTransferState.Filter.UserId}
						RowClass={uistate.IsMobile ? "col col50" : "col col25"}
						onChange={this.props.handleChange}
					/>
					<Selectize 
						Id="RoleId"
						Title="Должность"
						List={toSelectList(this.props.Roles)}
						Value={shiftTransferState.Filter.RoleId}
						RowClass={uistate.IsMobile ? "col col50" : "col col25"}
						onChange={this.props.handleChange}
					/>
					<Selectize 
						Id="FilialId"
						Title="Филиал"
						List={toSelectList(this.props.Filials)}
						Value={shiftTransferState.Filter.FilialId}
						RowClass={uistate.IsMobile ? "col col50" : "col col25"}
						onChange={this.props.handleChange}
					/>
					<DateInput 
						Id="Date"
						Title="Дата открытия смены"
						Value={shiftTransferState.Filter.Date}
						RowClass={uistate.IsMobile ? "col col50" : "col col25"}
						onChange={this.props.handleChange}
					/>
					{this.props.GroupType === 2 &&
						<CheckGroup 
							Id="IsAlerted"
							Type="checkbox"
							List={toCheckList([{ Id : "true", Name : "Только критические отчеты" }])}
							Value={shiftTransferState.Filter.IsAlerted ? ["true"] : []}
							RowClass={"nomargin " + (uistate.IsMobile ? "col col100" : "col col50")}
							onChange={this.props.handleChange}
						/>
					}
					<CheckGroup 
						Id="IsAutoSend"
						Type="checkbox"
						List={toCheckList([{ Id : "true", Name : "Только неотправленные отчеты" }])}
						Value={shiftTransferState.Filter.IsAutoSend ? ["true"] : []}
						RowClass={"nomargin " + (uistate.IsMobile ? "col col100" : "col col50")}
						onChange={this.props.handleChange}
					/>
				</div>
				<div className={"buttons " + (uistate.IsMobile ? "clearfix" : "col col20")}><button>Найти</button></div>
			</form>
		)
	}
}
ShiftTransferFilter = observer(ShiftTransferFilter);

class ShiftSubComponent extends Component {
	render() {
		return(
			<div className="clearfix subRowTable">
				{_.isNull(this.props.Info) && <p>Пользователь не заполнил отчет о смене</p>}
				{!_.isNull(this.props.Info) && _.map(_.sortBy(this.props.Info, "Name"), function (item, i) {
					return(<div className="clearfix row" key={"r" + i}>
						<h6 className={"col " + (uistate.IsMobile ? "col50" : "col35")}>{Parser(item.Name)}</h6>
						<p className={"col text " + (uistate.IsMobile ? "col50" : "col40")}>
							{item.Text === "-1" ? "" : Parser(item.Text.replace(/(\n)|(\r\n)/gi, "<br />"))}
						</p> 
						{item.Group && 
							<p className="col col25">{item.User}</p> 
						}
					</div>)
				})}
			</div>
		)
	}
}

export class ShiftReport extends Component {
	render() { return(<ShiftCommonReport Type={this.props.Type} />) }
}
export class ShiftReportCourier extends Component {
	render() { return(<ShiftCommonReport Type={this.props.Type} />) }
}
class ShiftCommonReport extends Component {
	constructor(props) {
		super(props);

		shiftReportState.setDefault(this.props.Type);

		this.state = { IsOpen : false }

		this.getData();

		var model = this;
		getRequest("Filials", {}, function(data) { model.setModel(data, "Filials"); });
		getRequest("Roles", {}, function(data) { model.setModel(data, "Roles"); });

		getRequest(!shiftReportState.IsCourier ? "Users" : "Couriers", { Active : true }, function(data) {
			shiftReportState.setUsers(data);
		});
	}

	/**
	 * Функция, обновляющее состояние модели
	 * @param {object} data ответ от сервера со списком
	 * @param {string} param название параметра
	 */
	setModel (data, param) { this.setState({ [param] : data.Success ? data[param] : [] }); }

	/** API запрос «ShiftReport» на получение списка смен */
	getData () {
		shiftReportState.IsSendRequest = false;
		getRequest("ShiftReport", shiftReportState.getFilter(), function(data) {
			shiftReportState.setShifts(data);
		});
	}

	/** Открытие/закрытие фильтра смен */
	toggleFilter (event) {
		event.preventDefault();
		this.setState({ IsOpen : !this.state.IsOpen });
	}

	/**
	 * Постраничная навигация
	 * @param {number} offset сдвиг для постраничной навигации
	 */
	changePage (offset) { 
		shiftReportState.Filter.Offset = offset;
		this.getData();
	}

	render() {
		var columns = { User : {}, Filial : {} } ;

		if (!shiftReportState.IsCourier) columns = _.extend(columns, { Role : {} });

		columns = _.extend(columns, { ShiftOpen : {}, ShiftClose : {} });

		if (shiftReportState.IsCourier) columns = _.extend(columns, { Time : {
			Header : "Время на смене", Cell : row => (getHourTime(row.original.Time))
		} });

		if (uistate.IsMobile) columns = {
			User : { 
				Cell: row => (<span>{row.original.User}
					<span className="comment">{row.original.Filial}, {!shiftReportState.IsCourier && row.original.Role}</span> 
				</span>)},
			ShiftOpen : {
				Header : "Смена",
				Cell: row => (<span>{formatDate(row.original.ShiftOpen, row.original.ShiftClose === "" ? "HH:mm DD.MM" : "time")} – {formatDate(row.original.ShiftClose, "HH:mm DD.MM")}
					{shiftReportState.IsCourier && <span className="comment">На смене {getHourTime(row.original.Time)}</span>}
				</span>),
			}
		}

		return(
			<div className={"page " + (uistate.IsMobile ? "mobile" : "")} id="ShiftReport">
				<div className="head">
					<h2>{buttonTitles[this.props.Type]}</h2>
					<a href="/" className={"toggleFilter " + (this.state.IsOpen ? "active" : "")} 
						title="Фильтр поиска" onClick={this.toggleFilter.bind(this)}><i></i></a>
				</div>
				{this.state.IsOpen && 
					<ShiftReportFilter
						Type={this.props.Type}
						Roles={_.filter(this.state.Roles, { IsCourier : false })}
						Filials={this.state.Filials}

						getData={this.getData.bind(this)}
					/>
				}
				{!_.isEmpty(shiftReportState.List) && 
					<TableReportPage
						TableData={shiftReportState.List}
						Type="ShiftReport"
						TableColumns={columns}
						FilterHeight={this.state.isOpen ? 125 : 0}
						Pagination={true}
						Limit={shiftReportState.Limit}
						HasMore={shiftReportState.HasMore}
						Offset={shiftReportState.Filter.Offset}
						
						changePage={this.changePage.bind(this)}
					/>
				}
				{shiftReportState.IsSendRequest && _.isEmpty(shiftReportState.List) &&
					<p>По вашему запросу ничего не найдено</p>
				}
			</div>
		)
	}
}
ShiftCommonReport = observer(ShiftCommonReport);

class ShiftReportFilter extends Component {
	/** Нажатие на кнопку «найти» */
	handleSubmit (event) {
		event.preventDefault();
		shiftReportState.Filter.Offset = 0;
		this.props.getData();
	}

	render() {
		return(
			<div className="filter clearfix">
				<form onSubmit={this.handleSubmit.bind(this)}>
					<div className={uistate.IsMobile ? "clearfix" : "col col80"}>
						<DatePreset
							DateFrom={shiftReportState.Filter.DateFrom}
							DateTo={shiftReportState.Filter.DateTo}
							onChange={(value,id) => shiftReportState.collectFilter(id,value)}
						/>
						<div className="clearfix">
							<Selectize 
								Id="User"
								Title="Пользователь"
								List={toSelectList(shiftReportState.Users)}
								Value={shiftReportState.Filter.User}
								RowClass={uistate.IsMobile ? "col col50" : "col col25"}
								onChange={(value,id) => shiftReportState.collectFilter(id,value)}
							/>
							{this.props.Type !== "ShiftReportCourier" &&
								<Selectize 
									Id="Role"
									Title="Должность"
									List={toSelectList(this.props.Roles)}
									Value={shiftReportState.Filter.Role}
									RowClass={uistate.IsMobile ? "col col50" : "col col25"}
									onChange={(value,id) => shiftReportState.collectFilter(id,value)}
								/>
							}
							<Selectize 
								Id="Filial"
								Title="Филиал"
								List={toSelectList(this.props.Filials)}
								RowClass={uistate.IsMobile ? "col col50" : "col col25"}
								Value={shiftReportState.Filter.Filial}
								onChange={(value,id) => shiftReportState.collectFilter(id,value)}
							/>
						</div>
					</div>
					<div className={"buttons blue " + (uistate.IsMobile ? "clearfix" : "col col20")}><button>Найти</button></div>
				</form>
			</div>
		)
	}
}
ShiftReportFilter = observer(ShiftReportFilter);