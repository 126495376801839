import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import moment from 'moment';

import { toSelectList, checkRights, getRequest, getRubs } from '../functions';
import { buttonTitles, dashboardsTitles } from '../dictionary';

import { ItemHeader, TableReportPage } from './PageTemplates';
import { Selectize, TextInput, DateInput } from '../forms/FormItems';

import { uistate, confirmState } from '../stores/common';
import { inventorizationState } from '../stores/inventorization';

export class InventorizationPage extends Component {
	constructor(props) {
		super(props);

		inventorizationState.setInventorization(undefined);
		inventorizationState.setFilter();

		this.state = {
			isOpen : false
		};

		this.getData = this.getData.bind(this);
		this.getData();

		var model = this;
		getRequest("Filials", {}, function (data) { model.setData(data, "Filials"); });
		getRequest("Inventories", { Active : true }, function (data) { model.setData(data, "Inventories"); });
	}

	setData (data, param) { this.setState({ [param] : data.Success ? data[param] : [] }); }

	getData() {
		inventorizationState.IsSendRequest = false;
		getRequest("Inventorizations", inventorizationState.getFilter(), function (data) {
			inventorizationState.setInventorizations(data);
		});
	}

	setId(id) {
        inventorizationState.setInventorization(_.isUndefined(id) ? id : { Id : -1 });
		if (_.isUndefined(id)) this.getData();
	}

	toggleFilter (event) {
		event.preventDefault();
		this.setState({ isOpen : !this.state.isOpen });
	}

	handleChange(value, id) { inventorizationState.collectFilter(id, value); }

	handleSubmit(event) {
		event.preventDefault();
		inventorizationState.Filter.Offset = 0;
		this.getData();
	}

	changePage (offset) { 
		inventorizationState.Filter.Offset = offset;
		this.getData();
	}

	render() {
		var columns = {
                Date : { Header : "Дата инвентаризации", Cell : row => moment(row.original.Date).format("DD.MM.YYYY")},
                Filial : {}, Inventory : {}, 
                Count : { Cell : row => <span className={row.original.Count > 0 ? "plus" : "minus"}>
                    {row.original.Count}</span>}, 
                Amount : { Cell : row => <span className={row.original.Amount > 0 ? "plus" : "minus"}>
                    {getRubs(row.original.Amount)}</span>}, 
                Description : { Cell : row => <span title={row.original.Description}>{row.original.Description}</span>}
			};

		return (
			<div className="page" id="InventorizationPage">
				{_.isUndefined(inventorizationState.Inventorization) &&
					<div className="head">
						<h2>{dashboardsTitles[this.props.Type] || this.props.Type}</h2>
						<a href="/" className={"toggleFilter " + (this.state.isOpen ? "active" : "")} 
							title="Фильтр поиска" onClick={this.toggleFilter.bind(this)}><i></i></a>
						{checkRights("ManageInventoryProcess") &&
							<button className="add" onClick={(e) => { this.setId(-1) }}>
								{buttonTitles[this.props.Type] || "Добавить"}</button>
						}
					</div>
				}
				{this.state.isOpen && _.isUndefined(inventorizationState.Inventorization) &&
					<form onSubmit={this.handleSubmit.bind(this)} className="filters clearfix">
						<div className="col col80">
							<Selectize 
								Id="FilialId"
								Title="Филиал"
								List={toSelectList(this.state.Filials)}
								Value={inventorizationState.Filter.FilialId}
								onChange={this.handleChange.bind(this)}
							/>
							<Selectize 
								Id="InventoryId"
								Title="Инвентарь"
								List={toSelectList(this.state.Inventories)}
								Value={inventorizationState.Filter.InventoryId}
								onChange={this.handleChange.bind(this)}
							/>
							<DateInput 
								Id="ShiftDate"
								Title="Дата инвентаризации"
								Value={inventorizationState.Filter.ShiftDate}
								onChange={this.handleChange.bind(this)}
							/>
						</div>
						<div className="buttons class col col20"><button>Найти</button></div>
					</form>
				}
				{!_.isEmpty(inventorizationState.Inventorizations) && _.isUndefined(inventorizationState.Inventorization) &&
					<TableReportPage
						Type="Inventorization"
						TableData={inventorizationState.Inventorizations}
						TableColumns={columns}
						
						FilterHeight={this.state.isOpen ? 125 : 0}
						
						Pagination={true}
						Limit={inventorizationState.Limit}
						HasMore={inventorizationState.HasMore}
						Offset={inventorizationState.Filter.Offset}
						
						changePage={this.changePage.bind(this)}
					/>
				}
                {inventorizationState.IsSendRequest && _.isEmpty(inventorizationState.Inventorizations) && _.isUndefined(inventorizationState.Inventorization) &&
					<p>По вашему запросу ничего не найдено</p>
				}
				{!_.isUndefined(inventorizationState.Inventorization) &&
					<InventorizationItem
						Filials={this.state.Filials}
						Inventories={this.state.Inventories}

						setId={this.setId.bind(this)}
					/>
				}
			</div>
		)
	}
}
InventorizationPage = observer(InventorizationPage);

class InventorizationItem extends Component {
	handleClose(event) {
		event.preventDefault();
		this.props.setId(undefined);
	}

	handleSubmit(event) {
        event.preventDefault();
        
        if (!inventorizationState.validationData()) return false;

		var model = this,
			text = <span>Вы действительно хотите сохранить инвентаризацию? Изменения будут недоступны.</span>;

		confirmState.openConfirm(text, function() { model.saveInventarization(); });		
	}

	saveInventarization () {
        var model = this;

		inventorizationState.ButtonLoading = true;

		getRequest("NewInventorization", inventorizationState.getSaveData(), function (data) {
			inventorizationState.ButtonLoading = false;
            inventorizationState.ErrorCode = data.ErrorCode;
            
            if (data.Success) model.props.setId(undefined);
		});
	}
	
	handleChange (value, id) { inventorizationState.collectInventorization(id, value); }

	render() {
		var style = { height : uistate.sectionHeight - 67 };

		return (
			<section className="clearfix item" id="InventarizationItem">
				<form onSubmit={this.handleSubmit.bind(this)}>
					<ItemHeader
						Title={buttonTitles.NewInventorization}
						Error={inventorizationState.ErrorCode}
						Loading={inventorizationState.ButtonLoading}
						CanManage={checkRights("ManageInventoryProcess")}
						handleClose={this.handleClose.bind(this)}
					/>
                    <div className="clearfix main backblock">
                        <Selectize 
                            Id="FilialId"
                            Title="Филиал"
                            List={toSelectList(this.props.Filials)}
                            Value={inventorizationState.Inventorization.FilialId}
                            RowClass="col col25"
                            onChange={this.handleChange.bind(this)}
                        />
                        <DateInput
                            Id="Date"
                            Title="Дата"
                            Value={inventorizationState.Inventorization.Date}
                            RowClass="col col25"
                            onChange={this.handleChange.bind(this)}
                        />
                    </div>
					<div className="clearfix section" style={style}>
						<InventoryBlock Inventories={this.props.Inventories} />
					</div>
				</form>
			</section>
		)
	}
}
InventorizationItem = observer(InventorizationItem);

class InventoryBlock extends Component {
	handleAdd(event) {
		event.preventDefault();

        inventorizationState.Inventory = _.concat(inventorizationState.Inventory, 
            _.clone(inventorizationState.InventoryDummy));
	}

	render() {
		var view = this;

		return(
			<div className="clearfix inventory block">
				<h4>Список инвентаря</h4>
				<div className="clearfix">
					<div className="clearfix titles">
						<span className="col col30">Инвентарь</span>
						<span className="col col15">Инвентарный номер</span>
						<span className="col col20">Действие</span>
						<span className="col col10">Кол-во</span>
						<span className="col col25">Комментарий</span>
					</div>
					{_.map(inventorizationState.Inventory, function (item, i) {
						return(<InventoryItem key={"p" + inventorizationState.ICounter++} Index={i}
							Item={item} Inventories={view.props.Inventories} />)
					})}
                    <a href="/" className="icon button add" onClick={this.handleAdd.bind(this)}>
                        <i></i>Добавить</a>
				</div>
			</div>
		)
	}
}
InventoryBlock = observer(InventoryBlock);

class InventoryItem extends Component {
	handleDelete(event) {
		event.preventDefault();

		var index = this.props.Index;
		inventorizationState.Inventory = _.filter(inventorizationState.Inventory, function (v,i) { 
            return i !== index; });
	}

	handleChange (value, id) { 
		var inventory = undefined;

		if (/InventoryId/.test(id)) inventory = _.find(this.props.Inventories, { Id : value });
		inventorizationState.collectInventory(id, value, this.props.Index, inventory); 
	}

	render() {
		var actions = [{ Id : 1, Name : "Пополнить" }, { Id : 2, Name : "Списать" }]
		return(
			<div className="clearfix item">
				<Selectize 
					Id="InventoryId"
					List={toSelectList(this.props.Inventories)}
					Value={this.props.Item.InventoryId}
					RowClass="col col30"
					onChange={this.handleChange.bind(this)}
				/>
				<span className="col col15" title={this.props.Item.InventoryNumber}>{this.props.Item.InventoryNumber}</span>
				<Selectize 
					Id="Action"
					List={toSelectList(actions)}
					Value={this.props.Item.Action}
					RowClass="col col20"
					onChange={this.handleChange.bind(this)}
				/>
				<TextInput 
					Id="Count"
					Value={this.props.Item.Count}
					RowClass="col col10"
                    Required={true}
                    onChange={this.handleChange.bind(this)}
				/>
				<TextInput 
					Id="Description"
					Value={this.props.Item.Description}
					RowClass="col col25"
                    onChange={this.handleChange.bind(this)}
				/>
                <a href="/" className="button icon remove" onClick={this.handleDelete.bind(this)}>
                    <i></i></a>
			</div>
		)
	}
}
InventoryItem = observer(InventoryItem);