import { observable, decorate, computed } from 'mobx';
import _ from 'lodash';

import { authUserState } from './common';
import { checkRights, clearEmptyParams, formatDate } from '../functions';

class OrderStatState {
	Stats = [];
	ButtonLoading = false;
	IsSendRequest = false;
	ErrorCode = "";
	StatType = "delivery";

	Filter = {
		DateFrom: formatDate("today", "r"),
		DateTo: formatDate("today", "r"),
		FilialId: -1,
		POSId: -1,
		BrandId: -1,
		HomeOrder: false,
		Period: "day"
	}
	DatePreset = "today";
	PageType = "";
	FilterOpen = false;

	/**
	 * Формирование списка точек продаж в зависимости от филиала в фильтре
	 * @returns {[object]} список точек продаж {Id, Name}
	 */
	get posList () {
		var filials = this.Filter.FilialId === -1 ? authUserState.Filials :
			_.filter(authUserState.Filials, { Id : this.Filter.FilialId});
		return _.flatten(_.map(filials, "POS"));
	}

	/**
	 * Формирование списка достуных отчетов в зависимости от прав пользователя
	 * @returns {[object]} список отчетов {Id, Name}
	 */
	get emploeesList () {
		var list = [];

		if (checkRights("ReportOrderTaking")) 
			list = _.concat(list, { Id : "taking", Name : "Прием заказов" });
		if (checkRights("ReportOrderDelivery")) 
			list = _.concat(list, { Id : "delivery", Name : "Доставка заказов" });

		return list;
	}

	/**
	 * Установка дефолтных значений фильтра и страницы
	 */
	setDefault() {
		this.Filter = {
			DateFrom: formatDate("today", "r"),
			DateTo: formatDate("today", "r"),
			FilialId: -1,
			POSId: -1,
			BrandId: -1,
			HomeOrder: false,
			Period: "day"
		}

		this.DatePreset = "today";
		this.Stats = [];
		this.ErrorCode = "";
		this.FilterOpen = false;
		this.StatType = "taking";
	}

	/**
	 * Сбор данных с формы фильтра
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectFilter(id, value) { 
		if (/HomeOrder/.test(id)) this.Filter.HomeOrder = value === 2;
		else this.Filter[id] = value; 
	}

	/** Проверка корректности данных запроса из формы
	 * @returns {boolean} флаг корректности данных
	 */
	validateFilter() {
		var isValid = true;

		if (this.Filter.DateFrom === "" || this.Filter.DateTo === "") {
			this.ErrorCode = "STAT_DATE_REQUIRED";
			return false;
		}

		return isValid;
	}

	/** Сбор данных фильтра для отправки запроса
	 * @return {object} данные фильтра
	 */
	getFilter(type) {
		var data = clearEmptyParams(this.Filter, ["", -1]);

		if (type === "Revenue") {
			data.StartDate = data.DateFrom;
			data.EndDate = data.DateTo;
			data.IsHome = data.HomeOrder;
			data = _.omit(data, ["DateFrom", "DateTo", "HomeOrder"]);
		}

		return data;
	}

	/**
	 * Разбор ответа от сервера со статистикой по сотрудникам
	 * @param {object} data 
	 */
	setStat(data) {
		this.Stats = data;
		this.IsSendRequest = true;
		this.ErrorCode = "";
		this.ButtonLoading = false;
	}
}
decorate(OrderStatState, {
	Stats: observable,
	Filter: observable,
	DatePreset: observable,
	StatType: observable,

	PageType: observable,
	IsSendRequest: observable,
	ButtonLoading: observable,
	ErrorCode: observable,

	posList: computed,
});
export const orderStatState = new OrderStatState();

