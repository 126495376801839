import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import Parser from 'html-react-parser';

import '../styles/products.css';

import { TextInput } from '../forms/FormItems';

import { dashboardsTitles } from '../dictionary';
import { getRequest, setUnit } from '../functions';

import { uistate } from '../stores/common';
import { menuState } from '../stores/productionMenu';
import { SectionMenu } from './Products';


export class KitchenMenu extends Component {
	render() { return (<MenuPage Type={this.props.Type} MenuGroup={1} />) }
}
export class BarMenu extends Component {
	render() { return (<MenuPage Type={this.props.Type} MenuGroup={2} />) }
}
class MenuPage extends Component {
	constructor(props) {
		super(props);

		menuState.setDefault(this.props.MenuGroup);

		// this.getSections();
		// this.getProducts();
		getRequest("Sections", { MenuGroup : menuState.MenuGroup }, function (data) {
			menuState.setSections(data.Success ? data.Sections : []);
		});
		getRequest("Products", { Active : true }, function(data) {
			menuState.setProducts(data.Success ? data.Products : []);
		});
	}

	/** API запрос «Sections» на получение всех разделов */
	getSections() {
		
	}
	/** API запрос «Products» на получение всех продуктов */
	getProducts() {
		
	}
	/** 
	 * API запрос «CookingTechnique» на получение техники приготовления продукта
	 * @param {number} id ID продукта
	 */
	getTechnique(id) {
		getRequest("CookingTechnique", { Id : id }, function(data) {
			menuState.setProduct(data.Success ? data : undefined);
		});
	}

	/**
	 * Сбор данных с формы поиска по продуктам
	 * @param {string} value значение параметра
	 */
	handleSearch(value) { menuState.Filter = value; }

	/**
	 * Изменение кол-ва готового ПФ
	 * @param {number} value значение параметра
	 */
	handleSemiChange(value) { menuState.ProductCount = value; }

	/** Переход по кросслинкам между продуктами */
	handleProductCheck(event) {
		event.preventDefault();

		var params = event.currentTarget.getAttribute("rel").split("_");

		menuState.setProduct(undefined);
		menuState.CurrentSection = parseInt(params[1], 0);
		this.getTechnique(parseInt(params[2], 10));
	}

	/** Открытие большой фотографии продукта/приготовления */
	handleOpenPhoto(url) { menuState.setProductPhoto(url); }

	render() {
		return(
			<div className={"page " + (uistate.IsTablet ? "tablet" : "")} id="MenuPage">
				<div className="head clearfix">
					<h2>{dashboardsTitles[this.props.Type] || this.props.Type}</h2>
					<form className="col col20 searchProducts">
						<TextInput
							Id="Filter"
							Title="Поиск по продуктам"
							Value={menuState.Filter}
							RowClass="nomargin"
							onChange={this.handleSearch.bind(this)}
						/>
					</form>
				</div>
				<SectionMenu ForMenu={true} Items={menuState.Sections} />
				<SectionMenu ForMenu={true} getData={this.getTechnique.bind(this)} Items={menuState.Products} IsProduct={true} />
				{!_.isUndefined(menuState.Product) && 
					<MenuItem 
						BlockClass="col col60"
						Type={menuState.CurrentType}
						Product={menuState.Product}
						Composition={menuState.composition}
						Count={menuState.ProductCount}
						handleChange={this.handleSemiChange.bind(this)}
						handleCheck={this.handleProductCheck.bind(this)}
						handleOpenPhoto={this.handleOpenPhoto.bind(this)}
					/>
				}
				{!_.isUndefined(menuState.ProductPhoto) &&
					<MenuItemPhoto 
						Photo={menuState.ProductPhoto}  
						handleOpenPhoto={this.handleOpenPhoto.bind(this)}
					/>

				}
			</div>
		)
	}
}
MenuPage = observer(MenuPage);

export class MenuItem extends Component {
	/** Открытие большого изображения */
	openPhoto (event) {
		event.preventDefault();
		this.props.handleOpenPhoto(this.props.Product.MainPhotoURL)
	}

	render() {
		var product = this.props.Product,
			image = product.MainPhotoURL,
			style = { height : uistate.sectionHeight },
			isProduct = this.props.Type === 3,
			hasTechnique = !_.isNull(product.Video) || !_.isNull(product.Technique),
			hasPhoto = image && image !== "",
			stylePhoto = hasPhoto ? { backgroundImage: "url(" + image + ")" } : {};

		return(
			<div className={"MenuProductItem " + this.props.BlockClass}>
				<div className="container clearfix" style={style}>
					<div className="clearfix main">
						<div className="col col35">
							<div className="photo" style={stylePhoto}>
								{hasPhoto && <a href="/" className="icon zoom"
									onClick={this.openPhoto.bind(this)}><i></i></a>
								}
							</div>
							<div className="clearfix weight">
								<h5>Вес готового продукта</h5>
								<p>{product.Weight} грамм</p>
							</div>
							{isProduct &&
								<div className="clearfix weight">
									<h5>Вес подачи</h5>
									<p>{product.WeightReady} грамм</p>
								</div>
							}
						</div>
						<div className="col col65">
							<form className="clearfix">
								<h4 className={"col " + (isProduct ? "col100" : "col70")}>{product.Name}</h4>
								{!isProduct &&
									<TextInput
										Id="ProductCount"
										Title="Вес готового ПФ"
										Type="number"
										Step="0.1"
										RowClass="col col30"
										Value={this.props.Count}
										onChange={this.props.handleChange}
									/>
								}
							</form>
							{!_.isEmpty(this.props.Composition) && 
								<MenuComposition 
									Type={this.props.Type}
									Composition={this.props.Composition}
									handleCheck={this.props.handleCheck}
								/>
							}
						</div>
					</div>
					{hasTechnique &&
						<MenuTechCard
							Product={this.props.Product}
							handleOpenPhoto={this.props.handleOpenPhoto}
						/>
					}
				</div>
			</div>
		)
	}
}
MenuItem = observer(MenuItem);

class MenuComposition extends Component {
	render() {
		var view = this,
			isSemi = this.props.Type === 2;

		return (
			<div className="composition">
				<h5>Состав</h5>
				<div className="clearfix row titles">
					<span className="col col70">Продукт</span>
					{isSemi && <span className="col col15">Всего</span>}
					<span className="col col15">В блюдо</span>
				</div>
				{_.map(this.props.Composition, function(item, i) {
					var rel = item.Type + "_" + item.SectionId + "_" + item.ProductId;

					return(<div className="clearfix row" key={"com" + i}>
						{item.Type !== 1 &&
							<a href="/" rel={rel} title={item.Name} className="col col70 dotted"
								onClick={view.props.handleCheck}>{item.Name}, {item.Unit}</a>
						}
						{item.Type === 1 &&
							<span title={item.Name} className="col col70">{item.Name}, {item.Unit}</span>
						}
						{isSemi && <span className="col col15 count">{setUnit(item.Count, "")}</span>}
						<span className="col col15 count">{setUnit(item.Count - item.SourceTrash, "")}</span>
					</div>)
				})}
			</div>
		)
	}
}

class MenuTechCard extends Component {
	/** Открытие большого изображения */
	openPhoto (event) {
		event.preventDefault();
		this.props.handleOpenPhoto(event.currentTarget.getAttribute("rel"));
	}

	render() {
		var view = this;

		return(
			<div className="clearfix tech">
				{_.map(this.props.Product.Technique, function (item,i) {
					var img = item.PhotoURL,
						hasPhoto = img && img !== "",
						photostyle = hasPhoto ? { backgroundImage: "url(" + img + ")" } : {};
					
					return(<div className="clearfix row" key={"th" + i}>
						<div className={"col text col65"}>
							<h5>{item.Name}</h5>
							<p>{Parser(item.Description.replace(/(\n)|(\r\n)/gi, "<br />"))}</p>
						</div>
						{hasPhoto &&
							<div className="col col35 photo" style={photostyle}>
								<a href="/" className="icon zoom" rel={img}
									onClick={view.openPhoto.bind(view)}><i></i></a>
							</div>
						}
					</div>)
				})}
				{!_.isNull(this.props.Product.Video) &&
					<div className="video">
						<video controls="controls">
						{_.map(this.props.Product.Video, function (video, i) {
							return(<source src={video.File} key={"v" + i} 
								type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />)
						})}
						</video>
					</div>
				}
			</div>
		)
	}
}

export class MenuItemPhoto extends Component {
	constructor(props) {
		super(props);

		var model = this;
		this.state = { WindowHeight : 0 }

		setTimeout(function() { model.setState({ WindowHeight : model.element.offsetHeight}); }, 50);
	}

	/** Закрытие окна с фото по клику вне блока */
	handleBackClose(event){
		if (event.target.getAttribute("rel") === "back") this.props.handleOpenPhoto(undefined);
	}

	/** Закрытие окна с фото по кнопке закрыть */
	handleClose(event){
		event.preventDefault();
		this.props.handleOpenPhoto(undefined);
	}

	render() {
		var photoWidth = this.state.WindowHeight*255/190,
			photoStyle = { 
				backgroundImage: "url(" + this.props.Photo + ")",
				width: uistate.IsMobile ? "100%" : photoWidth,
			},
			windowStyle = {
				width : uistate.IsMobile ? "94%" : photoWidth,
				left : "calc((100% - " + photoWidth + "px)/2"
			};

		return(
			<div className="back" rel="back" onClick={this.handleBackClose.bind(this)}>
				<div className={"window large menuPhoto " + (uistate.IsTablet ? "tablet" : "")} style={windowStyle} 
						ref={(element) => this.element = element}>
					<a href="/" className="icon close" onClick={this.handleClose.bind(this)}><i></i></a>
					<div className="photo" style={photoStyle}></div>
				</div>
			</div>
		)
	}
}
MenuItemPhoto = observer(MenuItemPhoto);