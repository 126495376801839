import { observable, decorate } from 'mobx';
import _ from 'lodash';
import { checkRights, formatDate, clearEmptyParams, collectCheckBox, collectArrayCheckBox } from '../functions';

class AuditState {
	Limit = 100;

	AuditDummy = {
		Id: -1,
		Type: -1,
		User: "",
		StockId: -1,
		Stock: "",
		Date: "",
		Status: -1,
		AcceptUser: "",
		AcceptDate: "",
		RawIds: ""
	}
	ProductDummy = {
		ProductId: -1,
		Product: "",
		SectionId: -1,
		Section: "",
		Type: -1,
		Count: "",
		MinCount: "",
		Actual: "",
		LastUpdate: "",
		UnitName: "",
		Cost: ""
	}

	/** Установка дефолтного состояния страницы */
	setDefault() {
		this.CanEdit = checkRights("ManageRevision");
		this.CanAccept = checkRights("AcceptRevision");
		this.CanManage = this.CanEdit || this.CanAccept;

		this.Filter = {
			Status: "",
			StockId: -1,
			ShiftDate: "",
			UserId: -1,
			Limit: this.Limit,
			Offset: 0
		}

		this.HasMore = false;
		this.IsSendRequest = false;

		this.Audits = [];
		this.setAudit(undefined);

		this.FilterMask = "";
	}

	/** ----- СПИСОК РЕВИЗИЙ ----- */
	/** Формирование списка пользователей */
	get UsersList () {
		if (_.isUndefined(this.Users) || _.isEmpty(this.Users) || _.isNull(this.Users)) return [];
		return _.map(this.Users, function(v) { return { Id : v.Id, Name : v.FirstName + " " + v.LastName }; });
	}

	/**
	 * Сбор данных с формы поиска ревизий
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	collectFilter(value, id) { this.Filter[id] = value; }

	/** Сбор данных с формы поиска для запроса к серверу */
	getFilterData() { return clearEmptyParams(_.clone(this.Filter)); }

	/**
	 * Обработка ответа от сервера со списком ревизий
	 * @param {[object]]} response ответ от сервера с массивом ревизий
	 */
	setAudits(response) {
		var store = this;

		this.Audits = !response.Success ? [] : _.map(response.Revisions, function (v) {
			return _.extend(v, {
				CanEdit: v.Status !== 2 && store.CanManage,
				CanView: v.Status === 2 && store.CanManage,
				CanRemove: v.Status !== 2 && store.CanAccept,
				CanSend: v.Status === 2 && store.CanManage,
				AmountNeg: -v.AmountNeg
			})
		});
		this.HasMore = response.HasMore;
		this.IsSendRequest = true;
	}

	/** ----- ПРОВЕДЕНИЕ РЕВИЗИИ ----- */
	/** Обработка ответа от сервера с ревизией
	 * @param {object} ответ от сервера с ревизией
	 */
	setAudit(audit) {
		this.StartRevision = false;

		this.Audit = _.isUndefined(audit) ? undefined : audit.Id === -1 ? _.clone(this.AuditDummy)
			: _.extend(_.omit(_.clone(audit), ["Success", "ErrorCode, Products"]), { Type: audit.TemplateId });

		this.Products = _.isUndefined(audit) || audit.Id === -1 ? [] : audit.Products;
		if (!_.isUndefined(audit) && audit.Id !== -1) 
			this.Products = _.concat(this.Products, _.map(audit.RawIds, function(v) { return { ProductId : v }; }));

		this.CheckedTemplateProducts = [];
		this.StartRevision = !_.isUndefined(audit) && audit.Id !== -1;

		this.ErrorCode = "";
		this.ButtonLoading = false;
		this.SuccessSave = false;
		this.FilterMask = "";
	}

	/** Флаг что подтверждена */
	get IsAccepted () { return _.isUndefined(this.Audit) ? false : this.Audit.Status === 2; }
	/** Флаг что ревизию можно акцептовать */
	get UserCanAccept () {
		return _.isUndefined(this.Audit) ? false : this.Audit.Status === 1 && this.CanAccept;
	}
	/** Флаг что ревизию можно редактировать */
	get UserCanSave () {
		return _.isUndefined(this.Audit) ? false : this.CanManage && this.StartRevision && !this.IsAccepted;
	}
	
	/**
	 * Сбор данных с формы ревизии
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectAudit(id, value) { this.Audit[id] = value; }

	/**
	 * Фильтрация продуктов по названию
	 * @param {string} value строка поиска
	 */
	collectProductsFilter(value) { this.FilterMask = value; }

	/** Подготовка списка продуктов для выборочной ревизии */
	get TemplateStockProducts () {
		if (_.isUndefined(this.Audit) || _.isUndefined(this.StockProducts) || _.isEmpty(this.StockProducts) || this.Audit.Type !== 2)
			return [];

		var mask = _.toLower(this.FilterMask),
			products = mask === "" ? _.clone(this.StockProducts) : _.filter(this.StockProducts, function(v) {
				return _.toLower(v.Product).indexOf(mask) !== -1;
			});

		return _.map(_.groupBy(products, "Type"), function (sections, type) {
			return {
				Type : type,
				Sections : _.map(_.groupBy(sections, "SectionId"), function(items, section) {
					return {
						Id : section,
						Name : items[0].Section,
						Products : _.map(items, function(v) { return { Id : v.ProductId, Name : v.Product } })
					}
				})
			}
		});
	}

	/**
	 * Сбор данных с формы шаблона ревизии
	 * @param {value} value значение параметра
	 * @param {string} id название параметра
	 */
	collectTemplateProducts(value, id) {
		if (/All/.test(id)) {
			var sectionId = id.split("_").length >= 3 ? parseInt(id.split("_")[2], 10) : "";

			if (sectionId !== "") {
				var products = _.map(_.filter(this.StockProducts, { SectionId: sectionId }), "ProductId");
				this.CheckedTemplateProducts = collectArrayCheckBox(products, value, this.CheckedTemplateProducts);
			}
		} else {
			var productId = id.split("_").length === 2 ? parseInt(id.split("_")[1], 10) : "";
			this.CheckedTemplateProducts = collectCheckBox(productId, value, this.CheckedTemplateProducts);
		}
	}

	/** Проверка возможность начала ревизии
	 * @returns {boolean} данные для начала заполнены корректно
	 */
	validateStartRevision () {
		if (this.Audit.StockId === -1 || this.Audit.StockId === "") {
			this.ErrorCode = "STOCK_REQUIRED";
			return false;
		}

		if (this.Audit.Type === -1 || this.Audit.Type === "") {
			this.ErrorCode = "AUDIT_TYPE_REQUIRED";
			return false;
		}

		if (this.Audit.Type === 2 && _.isEmpty(this.CheckedTemplateProducts)) {
			this.ErrorCode = "AUDIT_PRODUCTS_REQUIRED";
			return false;
		}

		return true;
	}

	/** Объединение данных по продуктам из ревизии, склада и остатков */
	get RequestsProducts () {
		if (_.isUndefined(this.Audit) || _.isEmpty(this.Products)) return [];

		var search = _.toLower(this.FilterMask),
			products = search === "" ? this.Products : _.filter(this.Products, function(v) {
				return _.toLower(v.Product).indexOf(search) !== -1;
			}),
			stockProducts = this.StockProducts,
			actualProducts = this.ActualProducts;

		products = _.uniqBy(products, "ProductId");

		return _.map(products, function(item) {
			var stock = _.find(stockProducts, { ProductId : item.ProductId }),
				actual = _.find(actualProducts, { ProductId : item.ProductId });

			return _.extend(_.clone(item), {
				Product : stock ? stock.Product : item.Product || item.Name,
				UnitName : stock ? stock.UnitName : item.UnitName,
				SectionId : stock ? stock.SectionId : item.SectionId,
				Section : stock ? stock.Section : item.Section || item.SectionName,
				Type : stock ? stock.Type : item.Type,
				MinCount : stock ? stock.Count : item.MinCount,
				Actual: actual ? actual.Count : item.Actual,
				LastUpdate: actual ? actual.LastUpdate : item.LastUpdate,
				Count: _.isUndefined(item.Count) ? "" : item.Count,
				Cost: _.isUndefined(item.Cost) ? 0 : item.Cost,
			});
		});
	}

	/** Группировка продуктов по типу и разделу */
	get TemplateProducts () {
		return _.isUndefined(this.RequestsProducts) ? [] : 
			_.map(_.groupBy(this.RequestsProducts, "Type"), function(list, type) {
				var sections = _.groupBy(list, "SectionId");
				return {
					Type: parseInt(type, 10),
					Sections: _.map(sections, function (products, section) {
						return {
							SectionId: parseInt(section),
							SectionName: products[0].Section,
							Products: products
						}
					})
				};
			});
	}

	/**
	 * Сбор данных с формы продуктов ревизии
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 * @param {number} productId ID продукта
	 */
	collectProducts(id, value, productId) {
		var product = _.find(this.Products, { ProductId: productId })
		product[id] = value;
	}

	/** Сбор данных для отправки на сервер сохранения ревизии */
	getSaveData() {
		var data = { Id: this.Audit.Id, StockId: this.Audit.StockId, TemplateId: this.Audit.Type },
			products = _.filter(this.Products, function (v) { return !_.isUndefined(v.Count) && v.Count !== ""; });

		if (this.Audit.Type === 2) 
			data.RawIds = this.Audit.Id === -1 ? this.CheckedTemplateProducts.join(",") : this.Audit.RawIds.join(",");

		if (this.Audit.Date !== "") data.Date = formatDate(this.Audit.Date, "r");

		_.each(products, function (v, i) {
			var name = "Products." + i + ".";

			data[name + "ProductId"] = v.ProductId;
			data[name + "Count"] = v.Count;
		});

		return data;
	}

	/** Валидация введенных данных
	 * @returns {boolean} Рекизия корректна
	 */
	validateData() {
		var products = _.filter(this.Products, function (v) { return v.Count !== ""; });

		if (this.Audit.StockId === "" || this.Audit.StockId === -1) {
			this.ErrorCode = "STOCK_REQUIRED";
			return false;
		}
		if (_.isEmpty(products)) {
			this.ErrorCode = "REVISION_PRODUCTS_REQUIRED";
			return false;
		}

		return true;
	}
}
decorate(AuditState, {
	Audits: observable,			// Список ревизий
	Users: observable,			// Список пользователей
	Stocks: observable,			// Список складов
	IsSendRequest: observable,	// Флаг отправки запроса
	Filter: observable,			// Фильтр ревизий
	HasMore: observable,		// Флаг что есть еще результаты

	Audit: observable,			// Данные о текущей ревизии
	ActualProducts: observable, // Данные об актуальных остатках склада
	StockProducts: observable,	// Данные о настройках склада
	Products: observable,		// Список продуктов в ревизии
	CheckedTemplateProducts: observable,	// Выбранные продукты для ревизии
	
	FilterMask: observable,		// Фильтр для поиска по названию
	StartRevision: observable,	// Флаг существующей ревизии
	ButtonLoading: observable,	// Флаг лоадера кнопки
	ErrorCode: observable,		// Код ошибки
	SuccessSave: observable		// Флаг успешного сохранения
});
export const auditState = new AuditState();

class ActualStockState {
	ViewMode = "All";
	Actual = {};

	ProductDummy = {
		ProductId: -1,
		Product: "",
		SectionId: -1,
		Section: "",
		Type: -1,
		MinCount: "",
		Actual: "",
		LastUpdate: "",
		VirtualCount: "",
		UnitName: "",
		VirtualAmount: "",
		Amount: ""
	}

	/** Установка дефолтного состояния страницы */
	setDefault() {
		this.ActualProducts = [];
		this.StockProducts = [];
		this.Stocks = [];
		
		this.Filter = {
			StockId : -1,
			ProductType : 1
		}
		this.NameFilter = "";

		this.IsSendRequest = false;
	}

	/** Добавдение информации о продуктах */
	get Products () {
		if (_.isUndefined(this.ActualProducts) || _.isEmpty(this.ActualProducts)) return [];

		var mask = _.toLower(this.NameFilter),
			type = this.Filter.ProductType,
			stockProducts = this.StockProducts,
			products = _.filter(this.ActualProducts, function(item) {
				if (item.Type !== type) return false;
				return mask === "" ? true : _.toLower(item.Product).indexOf(mask) !== -1;
			});

		return _.map(products, function(item) {
			var stock = _.find(stockProducts, { ProductId : item.ProductId });

			return _.extend(_.clone(item), {
				MinCount: stock ? stock.Count : "",
				Actual: item.Count,
			});
		});
	}

	/** Группировка продуктов по типу и разделу */
	get TemplateProducts () {
		return _.isUndefined(this.Products) ? [] : 
			_.map(_.groupBy(this.Products, "Type"), function(list, type) {
				var sections = _.groupBy(list, "SectionId");
				return {
					Type: parseInt(type, 10),
					Sections: _.map(sections, function (products, section) {
						var amounts = _.groupBy(_.compact(_.map(products, "Amount")), function (v) { return v > 0 }),
							vAmounts = _.groupBy(_.compact(_.map(products, "VirtualAmount")), function (v) { return v > 0 });

						return {
							SectionId: parseInt(section),
							SectionName: products[0].Section,
							Products: products,
							AmountPos: _.sum(amounts.true),
							AmountNeg: _.sum(amounts.false),
							VirtualAmountPos: _.sumBy(vAmounts.true),
							VirtualAmountNeg: _.sumBy(vAmounts.false),
						}
					})
				};
			});
	}
}
decorate(ActualStockState, {
	Filter: observable,			// Фильтр для отображения
	NameFilter: observable,		// Фильтр для поиска по имени
	IsSendRequest: observable,	// Флаг получения ответа от сервера

	Stocks: observable,		// Список доступных складов

	StockProducts : observable,		// Данные о настройках склада
	ActualProducts : observable,	// Данные об актуальных остатках 
	Amounts : observable			// Данные о стоимости товара на складе
})
export const actualStockState = new ActualStockState();