/**
 * /pages/TipsPrint.js – Работа со печатными материалами для чаевых
 * /stores/tipsPrint.js – бизнес логика
 * 
 * Отображаются существующие варианты печатных макетов
 * 
 * Для работы со страницей необходимо быть либо получателем чаевых, либо обладаться правом «ManageRecipient»
 * Для того, чтобы скачать макет необходимо чтобы были выбраны бренд и получатель. Необходимо отправить запрос «GetTipsMaterials»
 */

import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import TipsBCard from '../images/TipsBCard.png';
import TipsTableTent from '../images/TipsTableTent.png';
import TipsStiker from '../images/TipsStiker.png';
import TipsTiangle from '../images/TipsTiangle.png';
import TipsQR from '../images/TipsQR.png';

import { Selectize } from '../forms/FormItems';

import { dashboardsTitles } from '../dictionary';
import { getFile, getRequest, toSelectList } from './../functions';

import { uistate, authUserState } from '../stores/common';
import { tipsPrintState } from '../stores/tipsPrint';

export class TipsPrintPage extends Component {
	constructor(props) {
		super(props);

		tipsPrintState.setDefault();

		if (tipsPrintState.CanManage)
			getRequest("Users", { Active : true, TipsRecipient : true }, function(data) {
				tipsPrintState.Users = data.Success ? data.Users : [];
			});
		else if (authUserState.TipsRecipient) {
			var user = authUserState.User;
			tipsPrintState.Users = [{ Id : user.Id, FirstName : user.FirstName, LastName : user.LastName }]
		}
		getRequest("Brands", {}, function(data) {
			tipsPrintState.Brands = data.Success ? data.Brands : [];
			if (data.Success && data.Brands.length === 1) tipsPrintState.Filter.BrandId = data.Brands[0].Id;
		});
	}

	render() {
		return (
			<div className={"page " + (uistate.IsMobile ? "mobile" : "")} id="TipsPrintPage">
				<div className="head clearfix">
					<h2>{dashboardsTitles[this.props.Type] || this.props.Type}</h2>
				</div>
				{tipsPrintState.CanView &&
					<TipsPrintFilter />
				}
				{tipsPrintState.CanView && 
					<div className="clearfix">
						{_.map(tipsPrintState.DocumentTypes, function(item, i) {
							return(<PrintItem Item={item} key={i} />)
						})}
					</div>
				}
			</div>
		)
	}
}
TipsPrintPage = observer(TipsPrintPage);

class TipsPrintFilter extends Component {
	/** API запрос «GetTipsMaterials» для получения ссылки на платежную страницу */
	handleLink (event) {
		event.preventDefault();
		
		getFile("GetTipsMaterials", tipsPrintState.getLinkData(), function(data) {
			console.log(data)
			// navigator.clipboard.writeText(data.Link);
		});
	}

	render() {
		return(
			<div className="filter form clearfix">
				<Selectize
					Id="BrandId"
					Title="Бренд"
					// List={toSelectList(authUserState.TipsBrands)}
					List={toSelectList(tipsPrintState.Brands)}
					Value={tipsPrintState.Filter.BrandId}
					RowClass="col col25"
					onChange={tipsPrintState.collectFilter.bind(tipsPrintState)}
				/>
				<Selectize
					Id="UserId"
					Title="Получатель"
					Disabled={!tipsPrintState.CanManage}
					List={toSelectList(tipsPrintState.UserList)}
					Value={tipsPrintState.Filter.UserId}
					RowClass="col col25"
					onChange={tipsPrintState.collectFilter.bind(tipsPrintState)}
				/>
				{/* {tipsPrintState.CanDownload &&
					<a href="/" onClick={this.handleLink.bind(this)}>Скопировать ссылку на страницу</a>
				} */}
			</div>
		)
	}
}
TipsPrintFilter = observer(TipsPrintFilter);

class PrintItem extends Component {
	/** API запрос «GetTipsMaterials» на Скачивание файла */
	handleDownLoad(event) {
		event.preventDefault();
		var name = "EctrumTips_" + this.props.Item.Name + ".pdf"
		getFile("GetTipsMaterials", tipsPrintState.getDownloadData(this.props.Item.Id), name)
	}

	render() {
		var item = this.props.Item;

		return (
			<div className="col col1_3">
				<div className="clearfix item">
					<h4>{item.Name}</h4>
					<div className="clearfix">
						<div className="col col60">
							{item.Image === "TipsBCard" && <img src={TipsBCard} alt="Макет" /> }
							{item.Image === "TipsTableTent" && <img src={TipsTableTent} alt="Макет" /> }
							{item.Image === "TipsStiker" && <img src={TipsStiker} alt="Макет" />}
							{item.Image === "TipsTiangle" && <img src={TipsTiangle} alt="Макет" /> }
							{item.Image === "TipsQR" && <img src={TipsQR} alt="Макет" /> }
						</div>
						<div className="col col40">
							<p>{item.Description}</p>
							<p>Размер {item.Size}</p>
							{tipsPrintState.CanDownload &&
								<a href="/" className="button" onClick={this.handleDownLoad.bind(this)}>Скачать PDF</a>
							}
						</div>
					</div>
				</div>
			</div>
		)
	}
}
PrintItem = observer(PrintItem);