import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import { toSelectList, getRequest, getRubs, getFile, setUnit, formatDate, toCheckList } from '../functions';
import { buttonTitles, dashboardsTitles, errors } from '../dictionary';

import { ItemHeader, TableReportPage } from './PageTemplates';
import { Selectize, TextInput, CheckGroup, DateInput } from '../forms/FormItems';

import { uistate, authUserState, confirmState } from '../stores/common';
import { auditState, actualStockState } from '../stores/audits';

export class AuditPage extends Component {
	constructor(props) {
		super(props);

		auditState.setDefault();

		this.state = { isOpen : false };

		this.getData();

		getRequest("Stocks", {}, function (data) { 
			auditState.Stocks = data.Success && !_.isNull(data.Stocks) ? data.Stocks : [];
		});
		getRequest("Users", { Active : true }, function (data) { 
			auditState.Users = data.Success && !_.isNull(data.Users) ? data.Users : [];
		});
	}

	/** API запрос «Revisions» на получение списка ревизий */
	getData() {
		auditState.IsSendRequest = false;
		getRequest("Revisions", auditState.getFilterData(), function(data) { auditState.setAudits(data); });
	}

	/** API запрос «Revisions» на получение выбранной ревизии
	 * @param {number} id ID ревизии
	*/
	getItem(id) {
		getRequest("Revision", { Id : id }, function (data) {
			auditState.setAudit(data.Success ? data : undefined);
		});
	}

	setId(id, type) {
		if (!_.isUndefined(id)) {
			if (id !== -1) {
				this.getItem(id);
				auditState.OperationType = _.capitalize(type);
			} else {
				auditState.OperationType = "New";
				auditState.setAudit({ Id: -1 });
			}
		} else {
			auditState.setAudit(undefined);
			auditState.OperationType = "";
			this.getData();
		}
	}

	/** Открыть/закрыть фильтр */
	toggleFilter (event) {
		event.preventDefault();
		this.setState({ isOpen : !this.state.isOpen });
	}

	/**
	 * Изменить страницу результатов
	 * @param {number} offset Сдвиг по результатам
	 */
	changePage (offset) { 
		auditState.Filter.Offset = offset;
		this.getData();
	}

	/** API запрос «RemoveRevision» на удаление ревизии
	 * @param {number} id 
	 */
	removeId(id) {
		if (_.isUndefined(id)) return false;

		var model = this,
			item = _.find(auditState.Audits, { Id : id }),
			text = <span>Вы действительно хотите удалить ревизию от&nbsp; 
				<b key="b1">«{item ? formatDate(item.Date) : ""}»</b>?</span>;

		confirmState.openConfirm(text, function() {
			getRequest("RemoveRevision", { Id : id }, function () { model.getData(); });
		});
	}

	/** API запрос «RevisionPDF» на скачивание ревизии в PDF
	 * @param {number} id 
	 */
	downloadList(id) { 
		var item = _.find(auditState.Audits, { Id : id }),
			name = (item ? "Ревизия_" + item.Stock + "_" + formatDate(item.ShiftDate) : "Ревизия") + ".pdf";

		getFile("RevisionPDF", { Id : id }, name); 
	}

	render() {
		var	statuses = [{ Id : 1, Name : "Новая" }, { Id : 2, Name : "Подтверждена" }],
			columns = {
				ShiftDate : {}, Stock: {}, User: {},
				Status: { Cell: row => _.find(statuses, { Id : row.original.Status }).Name }
			};

		if (auditState.CanAccept) _.extend(columns, { AmountPos: {}, AmountNeg : {} });
		if (auditState.CanManage) _.extend(columns, { Edit: {}, DownLoad : {}, View: {}, Remove : {} });

		return (
			<div className={"page " + (uistate.IsTablet ? "tablet" : "")} id="AuditPage">
				{_.isUndefined(auditState.Audit) &&
					<div className="head">
						<h2>{dashboardsTitles[this.props.Type] || this.props.Type}</h2>
						<a href="/" className={"toggleFilter " + (this.state.isOpen ? "active" : "")} 
							title="Фильтр поиска" onClick={this.toggleFilter.bind(this)}><i></i></a>
						{auditState.CanManage && authUserState.Shift.Active &&
							<button className="add" onClick={(e) => { this.setId(-1) }}>
								{buttonTitles[this.props.Type] || "Добавить"}</button>
						}
					</div>
				}
				{this.state.isOpen && _.isUndefined(auditState.Audit) &&
					<AuditFilter getData={this.getData.bind(this)} />
				}
				{auditState.IsSendRequest && _.isUndefined(auditState.Audit) && !_.isEmpty(auditState.Audits) &&
					<TableReportPage
						Type="Audits"
						TableData={auditState.Audits}
						TableColumns={columns}

						FilterHeight={this.state.isOpen ? 125 : 0}
						Pagination={true}
						Limit={auditState.Limit}
						HasMore={auditState.HasMore}
						Offset={auditState.Filter.Offset}
						
						changePage={this.changePage.bind(this)}
						editRow={this.setId.bind(this)}
						removeRow={this.removeId.bind(this)}
						downloadList={this.downloadList.bind(this)}

						getTrProps={(state, rowInfo, column) => {
							return { className: rowInfo && rowInfo.original.Status === 2 ? "notactive" : "" };
						}}
					/>
				}
				{auditState.IsSendRequest && _.isUndefined(auditState.Audit) && _.isEmpty(auditState.Audits) &&
					<p>{errors.EMPTY_RESPONSE}</p>
				}
				{!_.isUndefined(auditState.Audit) &&
					<AuditItem
						setId={this.setId.bind(this)}
						getItem={this.getItem.bind(this)}
					/>
				}
			</div>
		)
	}
}
AuditPage = observer(AuditPage);

class AuditFilter extends Component {
	/** Обработка сабмита формы поиска */
	handleSubmit(event) {
		event.preventDefault();
		auditState.Filter.Offset = 0;
		this.props.getData();
	}

	render() {
		var	statuses = [{ Id : 1, Name : "Новая" }, { Id : 2, Name : "Подтверждена" }];

		return (
			<form onSubmit={this.handleSubmit.bind(this)} className="filter clearfix">
				<div className="col col80">
					<Selectize 
						Id="UserId"
						Title="Ревизор"
						List={toSelectList(auditState.UsersList)}
						Value={auditState.Filter.UserId}
						RowClass="col col25"
						onChange={auditState.collectFilter.bind(auditState)}
					/>
					<Selectize 
						Id="StockId"
						Title="Склад"
						List={toSelectList(auditState.Stocks)}
						Value={auditState.Filter.StockId}
						isSearchable={false}
						RowClass="col col25"
						onChange={auditState.collectFilter.bind(auditState)}
					/>
					<Selectize 
						Id="Status"
						Title="Статус"
						List={toSelectList(statuses)}
						Value={auditState.Filter.Status}
						isSearchable={false}
						RowClass="col col25"
						onChange={auditState.collectFilter.bind(auditState)}
					/>
					<DateInput 
						Id="ShiftDate"
						Title="Дата проведения ревизии"
						Value={auditState.Filter.ShiftDate}
						RowClass="col col25"
						onChange={auditState.collectFilter.bind(auditState)}
					/>
				</div>
				<div className="buttons class col col20"><button>Найти</button></div>
			</form>
		)
	}
}
AuditFilter = observer(AuditFilter);
class AuditItem extends Component {
	constructor(props) {
		super(props);

		if (auditState.Audit.Id !== -1) {
			this.getActual();
			this.getRawProducts(auditState.Audit.RawIds)

			if (!auditState.IsAccepted) this.getStock();
		}
	}

	/** API запрос «Stock» на получение настроек склада */
	getStock() {
		getRequest("Stock", { Id: auditState.Audit.StockId, OnlyProducts: true }, function (data) {
			auditState.StockProducts = data.Success && !_.isNull(data.Products) ? data.Products : [];
		});
	}

	/** API запрос «Stock» на получение актуального состояния склада */
	getActual() {
		getRequest("StockActual", { Id: auditState.Audit.StockId }, function (data) {
			auditState.ActualProducts = data.Success && !_.isNull(data.Products) ? data.Products : [];
		});
	}

	/** API запрос «ContainingProducts» на получение списка куда входят указанные продукты */
	getRawProducts (ids) {
		getRequest("ContainingProducts", { Ids : ids }, function (data) {
			if (data.Success && !_.isNull(data.Products))
				auditState.Products = _.concat(auditState.Products, _.map(data.Products, function(v) {
					return _.extend(v, { ProductId : v.Id });
				}));
		});
	}

	/** Закрыть окно с ревизией */
	handleClose(event) {
		event.preventDefault();
		this.props.setId(undefined);
	}

	/** Сохранение ревизии */
	handleSubmit(event) {
		event.preventDefault();

		if (!auditState.validateData())
			return false;

		var model = this,
			text = <span>Вы действительно хотите cохранить ревизию?</span>;

		confirmState.openConfirm(text, function() { model.saveAudit(); });
	}

	/** Подтверждение ревизии */
	handleAccept(event) {
		event.preventDefault();

		if (!auditState.validateData())
			return false;

		var model = this,
			date = formatDate(auditState.Audit.Date),
			text = <span>Вы действительно хотите подтвердить ревизию от&nbsp;<b key="b1">«{date}»</b>?</span>;

		confirmState.openConfirm(text, function() { model.saveAudit("accept"); });
	}

	saveAudit(type) {
		var model = this,
			result = auditState.getSaveData();

		auditState.ButtonLoading = true;

		getRequest("SaveRevision", result, function (data) {
			if (data.Success) {
				if (type === "accept") {
					var id = data.Id;
					getRequest("AcceptRevision", { Id : data.Id }, function (data) {
						if (data.Success) {
							auditState.OperationType = "View";
							model.props.getItem(id);
							model.getActual();
						}
			
						auditState.ButtonLoading = false;
						auditState.ErrorCode = data.Success ? "SUCCESS_ACCEPT" : data.ErrorCode;
						auditState.SuccessSave = data.Success;
					});
				} else {
					model.props.getItem(data.Id);
					model.getStock();
				}
			}

			auditState.ButtonLoading = type && type !== "accept";
			auditState.ErrorCode = data.Success ? "SUCCESS_SAVE" : data.ErrorCode;
			auditState.SuccessSave = data.Success;
		});
	}

	render() {
		var style = { minHeight: uistate.sectionHeight - 10 },
			title = auditState.Audit.Id === -1 ? "NewAudit" : auditState.Audit.Status === 1
				? "EditAudit" : "ViewAudit";

		return (
			<section className={"clearfix item " + (uistate.IsTablet ? "tablet" : "")} id="AuditItem">
				<form onSubmit={this.handleSubmit.bind(this)}>
					<ItemHeader
						Title={buttonTitles[title]}
						Error={auditState.ErrorCode}
						Loading={auditState.ButtonLoading}
						CanManage={auditState.UserCanSave}
						CanAccept={auditState.UserCanAccept}
						Success={auditState.SuccessSave}
						handleClose={this.handleClose.bind(this)}
						onAccept={this.handleAccept.bind(this)}
					/>
					{auditState.Audit.Status !== 2 &&
						<TextInput	
							Id="FilterMask"
							Title="Поиск по продуктам"
							RowClass="col col20 productSearch"
							Value={auditState.FilterMask}
							onChange={auditState.collectProductsFilter.bind(auditState)}
						/>
					}
					<div className="clearfix section" style={style}>
						{auditState.Audit.Id === -1 && !auditState.StartRevision &&
							<AuditTemplate 
								getStock={this.getStock.bind(this)} 
								getActual={this.getActual.bind(this)}
								getRawProducts={this.getRawProducts.bind(this)}
							/>
						}
						{auditState.StartRevision && <AuditProducts /> }
						{auditState.StartRevision && auditState.IsAccepted && auditState.CanAccept && 
							<AuditSummary /> 
						}
					</div>
				</form>
			</section>
		)
	}
}
AuditItem = observer(AuditItem);

class AuditTemplate extends Component {
	/**
	 * Сбор данных с формы ревизии
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	handleChange(value, id) { 
		auditState.collectAudit(id, value); 

		if (auditState.Audit.StockId !== "" && auditState.Audit.StockId !== -1) {
			this.props.getStock();
			this.props.getActual();
		}
	}

	/** Начало ревизии */
	startRevision(event) {
		event.preventDefault();
		if (!auditState.validateStartRevision()) return false;

		if (auditState.Audit.Type === 1) {
			auditState.Products = _.filter(auditState.StockProducts, { Revision : true })
			this.props.getRawProducts(_.map(_.filter(auditState.StockProducts, { Revision : true }), "ProductId"));
		} else if(auditState.Audit.Type === 2) {
			auditState.Products = _.map(auditState.CheckedTemplateProducts, function (v) {
				return { ProductId : v, Count : "" }
			});
			this.props.getRawProducts(auditState.CheckedTemplateProducts);	
		}

		auditState.StartRevision = true;
		auditState.FilterMask = "";
	}

	render() {
		var types = [{ Id: 1, Name: "По шаблону" }, { Id: 2, Name: "Выборочная" }];

		return (
			<div className="clearfix template">
				<div className="clearfix">
					<h4>Настройка шаблона ревизии</h4>
					<DateInput
						Id="Date"
						Title="Дата ревизии"
						Value={auditState.Audit.Date}
						RowClass="col col30"
						onChange={this.handleChange.bind(this)}
					/>
					<Selectize
						Id="StockId"
						Title="Склад"
						List={toSelectList(authUserState.Stocks)}
						Value={auditState.Audit.StockId}
						RowClass="col col30"
						isSearchable={false}
						onChange={this.handleChange.bind(this)}
					/>
					<Selectize
						Id="Type"
						Title="Тип ревизии"
						List={toSelectList(types)}
						Value={auditState.Audit.Type}
						RowClass="col col30"
						isSearchable={false}
						onChange={this.handleChange.bind(this)}
					/>
				</div>
				{auditState.Audit.Type === 2 && auditState.Audit.StockId !== "" && auditState.Audit.StockId !== -1 &&
					<AuditTemplateProducts />
				}
				<a href="/" className="button start" onClick={this.startRevision.bind(this)}>Начать</a>
			</div>
		)
	}
}
AuditTemplate = observer(AuditTemplate);

class AuditTemplateProducts extends Component {
	render() {
		var types = { 1 : "Сырье", 2 : "Полуфабрикаты" };

		// TODO найти к в секбокс дублируются ключи

		return(
			<div className="clearfix templateProducts">
				{_.map(auditState.TemplateStockProducts, function (groups) {
					return(<div className="block type clearfix" key={"g" + groups.Type}>
						<h4>{types[groups.Type]}</h4>
						{_.map(groups.Sections, function (section, i) {
							var ids = _.map(section.Products, "Id"),
								all = _.isEqual(_.intersection(ids, auditState.CheckedTemplateProducts), ids);

							return(<div className="col col30" key={"s" + section.Id}>
								<CheckGroup
									Id={"TemplateProducts_All_" + section.Id}
									List={[{ Id : "true", Title : section.Name }]}
									RowClass="checkAll"
									Type="checkbox"
									Value={ all ? ["true"] : []}
									onChange={auditState.collectTemplateProducts.bind(auditState)}
								/>
								<CheckGroup
									Id={"TemplateProducts"}
									List={toCheckList(section.Products)}
									Type="checkbox"
									Value={auditState.CheckedTemplateProducts}
									onChange={auditState.collectTemplateProducts.bind(auditState)}
								/>
							</div>)
						})}
					</div>)
				})}
			</div>
		)
	}
}
AuditTemplateProducts = observer(AuditTemplateProducts);

class AuditProducts extends Component {
	render() {
		return (
			<div className="clearfix">
				{auditState.Audit.Id !== -1 && <AuditMainInfo /> }
				{_.map(auditState.TemplateProducts, function (type, i) {
					return (<AuditProductType Item={type} Index={i} key={"t" + i} TypePage="Audit" />)
				})}
			</div>
		)
	}
}
AuditProducts = observer(AuditProducts);

class AuditMainInfo extends Component {
	render() {
		return(
			<div className="clearfix audit block processStockStat">
				<div className="clearfix tr">
					<div className="col col50 create td">
						<h5><i></i>Проведена</h5>
						<div className="clearfix row">
							<span className="col col40 title">Пользователь</span>
							<span className="col col60">{auditState.Audit.User}</span>
						</div>
						<div className="clearfix row">
							<span className="col col40 title">Склад</span>
							<span className="col col60">{auditState.Audit.Stock}</span>
						</div>
						<div className="clearfix row">
							<span className="col col40 title">Дата смены</span>
							<span className="col col60">{formatDate(auditState.Audit.ShiftDate)}</span>
						</div>
						<div className="clearfix row">
							<span className="col col40 title">Дата ревизии</span>
							{auditState.Audit.Status === 2 &&
								<span className="col col60">{formatDate(auditState.Audit.Date, "datetime")}</span>}
							{auditState.Audit.Status !== 2 &&
								<DateInput
									Id="Date"
									Value={auditState.Audit.Date}
									RowClass="col col60 nomargin"
									onChange={auditState.collectAudit.bind(auditState)}
								/>
							}
						</div>
					</div>
					{auditState.Audit.Status === 2 &&
						<div className="col col50 td accept">
							<h5><i></i>Подтверждена</h5>
							<div className="clearfix row">
								<span className="col col40 title">Пользователь</span>
								<span className="col col60">{auditState.Audit.AcceptUser}</span>
							</div>
							<div className="clearfix row">
								<span className="col col40 title">Дата подтверждения</span>
								<span className="col col60">{formatDate(auditState.Audit.AcceptDate)}</span>
							</div>
						</div>
					}
				</div>
			</div>
		)
	}
}
AuditMainInfo = observer(AuditMainInfo);


class AuditProductType extends Component {
	render() {
		var view = this,
			types = { 1: "Сырье", 2: "Полуфабрикаты", 3: "Продукция" };

		return (
			<div className="clearfix block">
				<h4>{types[this.props.Item.Type]}</h4>
				<div className="clearfix">
					{_.map(this.props.Item.Sections, function (section, i) {
						return (<AuditProductGroup
							key={"s" + i}
							Item={section}
							Index={i}
							SectionName={section.SectionName}
							AmountPos={section.AmountPos}
							AmountNeg={section.AmountNeg}
							VirtualAmountPos={section.VirtualAmountPos}
							VirtualAmountNeg={section.VirtualAmountNeg}
							Type={view.props.Index}
							TypePage={view.props.TypePage}
						/>)
					})}
				</div>
			</div>
		)
	}
}
AuditProductType = observer(AuditProductType);
class AuditProductGroup extends Component {
	render() {
		var view = this,
			isLefts = this.props.TypePage === "Leftovers",
			isAudit = this.props.TypePage === "Audit",
			isRaw = actualStockState.Filter ? actualStockState.Filter.ProductType === 1 : false,
			noStock = actualStockState.Filter ? actualStockState.Filter.StockId === -1 : false,
			col = isRaw ? noStock ? 60 : 70 : noStock ? 45 : 70;
		
		return (
			<div className="clearfix">
				<h5 className="clearfix">
					<span className={"col col" + col}>{this.props.SectionName}</span>
					{isLefts &&
						<span className="col col15 pos">{getRubs(isRaw ?
							this.props.VirtualAmountPos : this.props.AmountPos, true, true)}</span>
					}
					{isLefts &&
						<span className="col col15 neg">{getRubs(isRaw ?
							this.props.VirtualAmountNeg : this.props.AmountNeg, true, true)}</span>
					}
				</h5>
				<div className="clearfix titles">
					<span className={isLefts ? "col col30" : "col col40"}>Название</span>
					{((isAudit && !auditState.IsAccepted) || (isLefts && !noStock)) &&
						<span className="col col15" title="Должно быть на складе">Мин. кол-во</span>
					}
					<span className="col col15" title="Остаток в программе">Остаток в программе</span>
					{(!isLefts || !noStock) &&
						<span className="col col10" title="Дата подтверждения">Дата подтверждения</span>
					}
					{isAudit && !auditState.IsAccepted &&
						<span className="col col15" title="Фактически на складе">Факт. на складе</span>
					}
					{isAudit && auditState.IsAccepted &&
						<span className="col col15" title="Разница при ревизии">Разница, кол-во</span>
					}
					{isAudit && auditState.IsAccepted &&
						<span className="col col15" title="Разница при ревизии">Разница, руб.</span>
					}
					{isLefts && isRaw &&
						<span className="col col15">В ПФ и товарах</span>
					}
					{isLefts &&
						<span className="col col15">Сумма</span>
					}
				</div>
				<div className="clearfix">
					{_.map(this.props.Item.Products, function (product, i) {
						return (<AuditProductItem key={product.ProductId}
							Item={product} Index={i} Type={view.props.Type}
							Section={view.props.Index} TypePage={view.props.TypePage} />)
					})}
				</div>
			</div>
		)
	}
}
AuditProductGroup = observer(AuditProductGroup);
class AuditProductItem extends Component {
	/**
	 * Сбор данных с формы проведения ревизии
	 * @param {number} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange(value, id) { auditState.collectProducts(id, value, this.props.Item.ProductId); }
	
	render() {
		var item = this.props.Item,
			isLefts = this.props.TypePage === "Leftovers",
			isAudit = this.props.TypePage === "Audit",
			isRaw = actualStockState.Filter ? actualStockState.Filter.ProductType === 1 : false,
			noStock = actualStockState.Filter ? actualStockState.Filter.StockId === -1 : false,
			color = item.Count > 0 ? "plus" : "minus";

		return (
			<div className="clearfix item">
				<span className={isLefts ? "col col30" : "col col40"}>{item.Product}</span>
				{((isAudit && !auditState.IsAccepted) || (isLefts && !noStock)) &&
					<span className="col col15">{setUnit(item.MinCount, item.UnitName)}</span>
				}
				<span className="col col15">
					{(!isLefts || !isRaw) && setUnit(item.Actual, item.UnitName)}
					{isLefts && isRaw && setUnit(item.VirtualCount, item.UnitName)}
				</span>
				{(!isLefts || !noStock) &&
					<span className="col col10 date">{formatDate(item.LastUpdate)}</span>
				}
				{isAudit && !auditState.IsAccepted &&
					<TextInput
						Id="Count"
						Value={item.Count}
						Type="number"
						Step="0.001"
						RowClass="col col15"
						onChange={this.handleChange.bind(this)}
					/>
				}
				{isAudit && auditState.IsAccepted &&
					<span className={"col col15 " + color}>{setUnit(item.Count, item.UnitName)}</span>
				}
				{isAudit && auditState.IsAccepted &&
					<span className={"col col15 " + color}>{getRubs(item.Cost, true, true)}</span>
				}
				{isLefts && isRaw &&
					<span className="col col15">{setUnit(item.VirtualCount - item.Actual, item.UnitName)}</span>
				}
				{isLefts &&
					<span className="col col15">{getRubs(isRaw ? item.VirtualAmount : item.Amount, true, true)}</span>
				}
			</div>
		)
	}
}
AuditProductItem = observer(AuditProductItem);
class AuditSummary extends Component {
	render() {
		return(
			<div className="clearfix block summary">
				<h5 className="col col75">Итого</h5>
				<div className="col col25">
					<p className="plus">Сумма «+»: {getRubs(auditState.Audit.AmountPos, true, true)} руб.</p>
					<p className="minus">Сумма «-»: {getRubs(-auditState.Audit.AmountNeg, true, true)} руб.</p>
				</div>
			</div>
		)
	}
}
AuditSummary = observer(AuditSummary);

export class ActualStockPage extends Component {
	constructor(props) {
		super(props);

		actualStockState.setDefault();
		
		this.getActual();

		getRequest("Stocks", {}, function (data) {
			actualStockState.Stocks = data.Success && !_.isNull(data.Stocks) ? data.Stocks : []
		});
	}

	/** API запрос «Stock» для получения настроек склада */
	getStock() {
		getRequest("Stock", { Id: actualStockState.Filter.StockId, OnlyProducts: true }, function (data) {
			actualStockState.StockProducts = data.Success && !_.isNull(data.Products) ? data.Products : [];
		});
	}

	/** API запрос «StockActual» для получения остатков на складе */
	getActual() {
		actualStockState.IsSendRequest = false;
		getRequest("StockActual", { Id: actualStockState.Filter.StockId }, function (data) {
			actualStockState.ActualProducts = data.Success && !_.isNull(data.Products) ? data.Products : [];
			actualStockState.Amounts = data.Success ? { Real : data.Amount, Virtual : data.VirtualAmount } : {};
			actualStockState.IsSendRequest = true;
		});
	}

	render() {
		return (
			<div className="page" id="ActualStockPage">
				<section className="listpage">
					<div className="head clearfix">
						<h2>{dashboardsTitles[this.props.Type] || this.props.Type}</h2>
						{!_.isEmpty(actualStockState.Stocks) &&
							<StockForm 
								getStock={this.getStock.bind(this)} 
								getActual={this.getActual.bind(this)} 
							/>
						}
					</div>
					<div className="section clearfix">
						{!_.isEmpty(actualStockState.TemplateProducts) &&
							_.map(actualStockState.TemplateProducts, function (type, i) {
								return (<AuditProductType Item={type} Index={i} key={"t" + i}
									TypePage="Leftovers"/>)
							})
						}
						{actualStockState.IsSendRequest && !_.isEmpty(actualStockState.Amounts) &&
							<ActualSummaryBlock />
						}
						{_.isEmpty(actualStockState.TemplateProducts) && actualStockState.IsSendRequest &&
							<p>По вашему запросу ничего не найдено</p>
						}
					</div>
				</section>
			</div>
		)
	}	
}
ActualStockPage = observer(ActualStockPage);

class StockForm extends Component {
	/**
	 * Сбор данных с формы
	 * @param {number} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange (value, id) {
		if (/ProductType/.test(id)) actualStockState.Filter.ProductType = value;
		else if (/NameFilter/.test(id)) actualStockState.NameFilter = value;
		else if (/StockId/.test(id)) {
			actualStockState.Filter.StockId = value;
			this.props.getActual();
			if (value !== -1) this.props.getStock();
		}
	}

	render () {
		var types = [{ Id : 1, Name : "Сырье"}, { Id : 2, Name : "Полуфабрикаты"}];

		return(
			<form className="col col60">
				<Selectize
					Id="StockId"
					List={toSelectList(actualStockState.Stocks)}
					Value={actualStockState.Filter.StockId}
					RowClass="col col30"
					onChange={this.handleChange.bind(this)}
				/>
				<Selectize
					Id="ProductType"
					List={toSelectList(types)}
					Value={actualStockState.Filter.ProductType}
					RowClass="col col30"
					isClearable={false}
					onChange={this.handleChange.bind(this)}
				/>
				<TextInput
					Id="NameFilter"
					Title="Поиск по продуктам"
					Value={actualStockState.NameFilter}
					RowClass="col col30"
					onChange={this.handleChange.bind(this)}
				/>
			</form>
		)
	}
}
StockForm = observer(StockForm);

class ActualSummaryBlock extends Component {
	render() {
		var filter = actualStockState.Filter,
			col = filter.ProductType === 1 ? filter.StockId === -1 ? 60 : 85 : filter.StockId === -1 ? 45 : 70;
		
		return(
			<div className="clearfix summary block">
				<span className={"col col" + col}>ИТОГО на всем складе продуктов на сумму:</span>
				<span className="col col15">{getRubs(actualStockState.Amounts.Real, true, true)}</span>
			</div>
		)
	}
}
ActualSummaryBlock = observer(ActualSummaryBlock);