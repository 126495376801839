import { observable, decorate } from 'mobx';
import _ from 'lodash';
import { formatDate, getRubs } from '../functions';

class BonusState {
	Rules = [];
	IsSendRequest = false;
	Active = 1;

	Rule = undefined;
	ButtonLoading = false;
	ErrorCode = "";
	SuccessSave = false;
	Conditions = [];
	Actions = [];

	UseProducts = false;
	SystemProducts = [];
	
	RuleDummy = {
		Id : -1,
		Description : "",
		StartDate : formatDate("today", "r"),
		EndDate : "",
		Customer : "",
		MaxCount: "",
		Active : true,
		IsFinal : false,
		BonusDuration : ""
	}
	ConditionDummy = {
		Id : -1,
		Type : -1,
		Operand : -1,
		Amount : "",
		Count : "",
		Date : "",
		Period : "",
		VendorCodes : "", 
		GroupType : -1,
		DateType : -1,
		ProductIds : [],
		PeriodMonth : "",
		PeriodWeek : []
	}
	ActionDummy = {
		Id : -1,
		ActionType : -1,
		VendorCode : "",
		Amount : "",
		BonusDuration : "",
		GroupType : -1
	}
	ProductDummy = {
		Id : -1,
		VendorCode : "",
		ProductId: -1,
		Amount : ""
	}

	setProducts (data) {
		this.SystemProducts = data.Success && !_.isNull(data.Products) ? data.Products : [];
		this.UseProducts = !_.isEmpty(this.SystemProducts);
	}

	setList(data) {
		this.Rules = data.Success ? data.Rules : [];
		this.IsSendRequest = true;
	}

	setItem(rule) {
		var store = this;

		this.Rule = _.isUndefined(rule) ? undefined : rule.Id === -1 ? _.clone(this.RuleDummy) 
			: _.extend(_.omit(_.clone(rule), ["Success", "ErrorCode"]), {
				BonusDuration : _.isNull(rule.Actions) || _.isEmpty(rule.Actions) ? "" : _.first(rule.Actions).BonusDuration
			});
		this.Conditions = _.isUndefined(rule) ? [] : rule.Id === -1 || _.isNull(rule.Conditions) || 
			_.isEmpty(rule.Conditions) ? [_.clone(this.ConditionDummy)] : _.map(rule.Conditions, function (item) {
				return _.extend(item, {
					GroupType : _.indexOf([1,7], item.Type) !== -1 ? 1 : item.Type === 2 ? 2 : item.Type === 5 ? 3
						: item.Type === 6 ? 4 : -1,
					DateType : item.Date !== "" ? "date" : /w/.test(item.Period) ? "w" : /m/.test(item.Period) ? "m" : "",
					PeriodMonth : /m/.test(item.Period) ? item.Period.split("-")[1] : "",
					PeriodWeek : /w/.test(item.Period) ? (item.Period.split("-")[1]).split(",") : [],
					ProductIds : store.UseProducts && !_.isEmpty(store.SystemProducts) && item.VendorCodes !== "" && !_.isNull(item.VendorCodes) 
						? _.compact(_.map(item.VendorCodes.split(";"), function (v) {
							var product = _.find(store.SystemProducts, { VendorCode : v });
							return product ? product.Id : null;
						})) : [],
					VendorCodes : !_.isNull(item.VendorCodes) ? item.VendorCodes.replace(/;/g, ", ") : "",
					Amount : getRubs(item.Amount)
				});
			});

		var isNoActions = _.isUndefined(rule) ? true : rule.Id === -1 || _.isNull(rule.Actions) || _.isEmpty(rule.Actions),
			isProducts = _.isUndefined(rule) || isNoActions ? false : _.indexOf(_.map(rule.Actions, "ActionType"), 5) !== -1,
			actions = _.isUndefined(rule) || isNoActions ? [] : isProducts ? _.groupBy(rule.Actions, "ActionType") : rule.Actions;
		this.Actions = _.isUndefined(rule) ? [] : rule.Id === -1 || _.isNull(rule.Actions) || 
			_.isEmpty(rule.Actions) ? [_.clone(this.ActionDummy)] : !isProducts ? _.map(actions, function (item) {
				return _.extend(item, {
					GroupType : item.ActionType === 1 || item.ActionType === 2 ? 1 
						: item.ActionType === 3 || item.ActionType === 4 ? 2 
						: item.ActionType === 5 ? 3 : item.ActionType === 6 || item.ActionType === 7 ? 4 : -1,
					Amount : item.ActionType === 4 ? getRubs(item.Amount) : item.Amount
				})
			}) : [{
				Id : -1,
				GroupType : 3,
				Type : 5,
				Products : _.map(actions[5], function (product) {
					var pr = _.find(store.SystemProducts, { VendorCode : product.VendorCode });
					return _.extend(product, {
						Id : product.Id,
						VendorCode : product.VendorCode,
						ProductId : store.UseProducts && !_.isUndefined(pr) ? pr.Id : -1,
						Amount : getRubs(product.Amount)
					})
				})
			}]

		this.ButtonLoading = false;
		this.ErrorCode = "";
	}

	collectItem(id, value) {
		if (/IsFinal/.test(id)) this.Rule.IsFinal = value;
		else this.Rule[id] = value;
	}

	collectCondition(id, value, index) {
		if (/PeriodWeek/.test(id)) {
			var val = id.split("_")[1],
				i = val && !_.isNaN(parseInt(val, 10)) ? parseInt(val, 10) : null,
				period = this.Conditions[index].PeriodWeek;

			if (value && !_.isNull(i)) this.Conditions[index].PeriodWeek = _.concat(period, i);
			else if (!value && !_.isNull(i)) this.Conditions[index].PeriodWeek = _.without(period, i);
		} this.Conditions[index][id] = value;

		if (/GroupType/.test(id)) {
			this.Conditions[index].Type = value === 2 ? 2 : value === 3 ? 5 : value === 4 ? 6 : -1;
			this.Conditions[index].Operand = value === 4 ? "=" : "";
		}
	}
	collectAction(id, value, index, productIndex) {
		if (!_.isUndefined(productIndex)) this.Actions[index].Products[productIndex][id] = value;
		else if (/ActionType/.test(id)) this.Actions[index].ActionType = parseInt(value, 10);
		else this.Actions[index][id] = value;

		if (/GroupType/.test(id)) {
			this.Actions[index].ActionType = value === 3 ? 5 : -1;
			this.Actions[index].Products = value === 3 ? [_.clone(this.ProductDummy)] : [];
		}
	}

	getSaveData() {
		var store = this,
			useProducts = this.UseProducts,
			products = this.SystemProducts,
			data = _.clone(this.Rule);

		if (data.MaxCount === "") data.MaxCount = 0;

		_.each(this.Conditions, function(condition, index) {
			var name = "Conditions." + index + ".";

			data[name + "Id"] = condition.Id;
			data[name + "Type"] = condition.Type;
			data[name + "Operand"] = condition.Operand;
			data[name + "Count"] = condition.Type === 7 ? condition.Count : "";
			data[name + "Amount"] = _.indexOf([1,2], condition.Type) !== -1 ? (condition.Amount*100).toFixed(0) : "";
			data[name + "Date"] = condition.Type === 6 && condition.DateType === "date" ? condition.Date : "";
			data[name + "Period"] = condition.Type !== 6 || condition.DateType === "date" ? "" 
				: condition.DateType === "w" ? "1w-" + condition.PeriodWeek.join(",") 
				: "1m-" + condition.PeriodMonth;
			data[name + "VendorCodes"] = condition.Type !== 5 ? "" : !useProducts 
				? condition.VendorCodes.replace(/(,\s)|\s|,/gi, ";")
				: _.compact(_.map(condition.ProductIds, function (v) {
					var product = _.find(products, { Id : v });
					return product ? product.VendorCode : null;
				})).join(";");
		});

		_.each(this.Actions, function(action, index) {
			var name = "Actions." + index + ".";

			if (action.ActionType === 5) {
				_.each(action.Products, function (product, i) {
					name = "Actions." + i + ".";

					data[name + "Id"] = product.Id;
					data[name + "ActionType"] = action.ActionType;
					data[name + "VendorCode"] = useProducts && _.find(products, { Id : product.ProductId}) 
						? _.find(products, { Id : product.ProductId}).VendorCode : product.VendorCode;
					data[name + "Amount"] = (product.Amount*100).toFixed(0);
					data[name + "BonusDuration"] = store.Rule.BonusDuration;
				});
			} else {
				data[name + "Id"] = action.Id;
				data[name + "ActionType"] = action.ActionType;
				data[name + "VendorCode"] = "";
				data[name + "Amount"] = action.ActionType === 4 ? (action.Amount*100).toFixed(0) : action.Amount;
				data[name + "BonusDuration"] = store.Rule.BonusDuration;
			}
		});

		data = _.omit(data, ["BonusDuration"]);

		if (data.StartDate === "" || _.isNull(data.StartDate)) data = _.omit(data, "StartDate");
		if (data.EndDate === "" || _.isNull(data.EndDate)) data = _.omit(data, "EndDate");

		return data;
	}

	validateData() {
		var store = this,
			isValid = true;

		this.ErrorCode = "";
		this.SuccessSave = false;

		if (_.isEmpty(this.Conditions)) {
			this.ErrorCode = "BONUS_CONDITION_REQUIRED";
			return false;
		}

		_.each(this.Conditions, function (condition) {
			if (condition.GroupType === "" || condition.GroupType === -1) {
				store.ErrorCode = "BONUS_COND_TYPE_REQUIRED";
				isValid = false;
				return false;
			}

			if (condition.Type === "" || condition.Type === -1) {
				store.ErrorCode = "BONUS_COND_TYPE_REQUIRED";
				isValid = false;
				return false;
			}

			if (_.indexOf([1,2,3], condition.GroupType) !== -1 && _.indexOf(["", -1], condition.Operand) !== -1) {
				store.ErrorCode = "BONUS_OPERAND_REQUIRED";
				isValid = false;
				return false;
			}

			if (_.indexOf([1,2], condition.Type) !== -1 && _.indexOf(["", 0], condition.Amount) !== -1) {
				store.ErrorCode = "BONUS_COND_AMOUNT_REQUIRED";
				isValid = false;
				return false;
			}

			if (_.indexOf([7], condition.Type) !== -1 && _.indexOf(["", 0], condition.Count) !== -1) {
				store.ErrorCode = "BONUS_COND_COUNT_REQUIRED";
				isValid = false;
				return false;
			}

			if (condition.GroupType === 3) {
				if (store.UseProducts && _.isEmpty(condition.ProductIds)) {
					store.ErrorCode = "BONUS_COND_PRODUCTS_REQUIRED"
					isValid = false;
					return false;
				}

				if (!store.UseProducts && condition.VendorCodes === "") {
					store.ErrorCode = "BONUS_COND_PRODUCTS_REQUIRED"
					isValid = false;
					return false;
				}
			}

			if (condition.GroupType === 4) {
				if (condition.DateType === "" || condition.DateType === -1) {
					store.ErrorCode = "BONUS_COND_DATETYPE_REQUIRED";
					isValid = false;
					return false;
				}

				if (condition.DateType === "date" && (condition.Date === "" || _.isNull(condition.Date))) {
					store.ErrorCode = "BONUS_COND_DATE_REQUIRED";
					isValid = false;
					return false;
				}

				if (condition.DateType === "w" && _.isEmpty(condition.PeriodWeek)) {
					store.ErrorCode = "BONUS_COND_WEEK_REQUIRED";
					isValid = false;
					return false;
				}
				
				if (condition.DateType === "m" && condition.PeriodMonth === "") {
					store.ErrorCode = "BONUS_COND_DAYS_REQUIRED";
					isValid = false;
					return false;
				}
			}
		});

		if (!isValid) return false;

		if (_.isEmpty(this.Actions)) { 
			this.ErrorCode = "BONUS_ACTIONS_REQUIRED";
			return false;
		}

		_.each(this.Actions, function (action) {
			if (action.GroupType === -1 || action.GroupType === "") {
				store.ErrorCode = "BONUS_ACTION_TYPE_REQUIRED";
				isValid = false;
				return false;
			}

			if (action.ActionType === -1 || action.ActionType === "") {
				store.ErrorCode = "BONUS_ACTION_TYPE_REQUIRED";
				isValid = false;
				return false;
			}

			if (action.GroupType === 1 && action.Amount === "") {
				store.ErrorCode = "BONUS_ACT_COUNT_REQUIRED";
				isValid = false;
				return false;
			}

			if (action.GroupType === 2 && action.Amount === "") {
				store.ErrorCode = "BONUS_ACT_SALE_REQUIRED";
				isValid = false;
				return false;
			}

			if (action.GroupType === 3) _.each(action.Products, function (product) {
				if (store.UseProducts && (product.ProductId === "" || product.ProductId === -1)) {
					store.ErrorCode = "BONUS_ACT_PRODUCT_REQUIRED";
					isValid = false;
					return false;
				}

				if (!store.UseProducts && product.VendorCode === "") {
					store.ErrorCode = "BONUS_ACT_PRODUCT_REQUIRED";
					isValid = false;
					return false;
				}

				if (product.Amount === "") {
					store.ErrorCode = "BONUS_ACT_AMOUNT_REQUIRED";
					isValid = false;
					return false;
				}
			})
		})

		return isValid;
	}

	getOrderData() { return { Ids: _.map(_.clone(this.Rules), "Id").join(",") }; }
}
decorate(BonusState, {
	Rules: observable,
	IsSendRequest: observable,
	Active: observable,

	UseProducts: observable,
	SystemProducts: observable,

	Rule: observable,
	Actions: observable,
	Conditions: observable,

	ButtonLoading: observable,
	ErrorCode: observable,
	SuccessSave: observable,
});
export const bonusState = new BonusState();

class PromocodeState {
	Promocodes = [];
	IsSendRequest = false;
	Active = 1;

	Promocode = undefined;
	ButtonLoading = false;
	ErrorCode = "";
	SuccessSave = false;
	Actions = [];

	UseProducts = false;
	SystemProducts = [];
	PromocodeChars = 4;
	
	PromocodeDummy = {
		Id : -1,
		Promocode : "",
		StartDate : formatDate("today", "r"),
		EndDate : "",
		Customer : "",
		MaxCount: "",
		Active : true,
		BonusDuration : ""
	}
	ActionDummy = {
		Id : -1,
		ActionType : -1,
		VendorCode : "",
		Amount : "",
		BonusDuration : "",
		GroupType : -1
	}
	ProductDummy = {
		Id : -1,
		VendorCode : "",
		ProductId: -1,
		Amount : ""
	}

	setProducts (data) {
		this.SystemProducts = data.Success && !_.isNull(data.Products) ? data.Products : [];
		this.UseProducts = !_.isEmpty(this.SystemProducts);
	}

	setList(data) {
		this.Promocodes = data.Success ? _.map(data.Promocodes, function (item) {
			var types = _.compact(_.map(item.ActionsTypes, function (v) {
				return v === 1 || v === 2 ? "Баллы" : v === 3 || v === 4 ? "Скидка" : v === 5 ? "Спец. предложение" : "";
			})).join(", ");

			return _.extend(item, { ActionType : types })
		}) : [];
		this.IsSendRequest = true;
	}

	setItem(promocode) {
		var store = this;

		this.Promocode = _.isUndefined(promocode) ? undefined : promocode.Id === -1 ? _.clone(this.PromocodeDummy) 
			: _.extend(_.omit(_.clone(promocode), ["Success", "ErrorCode"]), {
				BonusDuration : _.isNull(promocode.Actions) || _.isEmpty(promocode.Actions) ? "" : _.first(promocode.Actions).BonusDuration
			});

		var isNoActions = _.isUndefined(promocode) ? true : promocode.Id === -1 || _.isNull(promocode.Actions) 
				|| _.isEmpty(promocode.Actions),
			isProducts = _.isUndefined(promocode) || isNoActions ? false : _.indexOf(_.map(promocode.Actions, "ActionType"), 5) !== -1,
			actions = _.isUndefined(promocode) || isNoActions ? [] : isProducts 
				? _.groupBy(promocode.Actions, "ActionType") : promocode.Actions;
		this.Actions = _.isUndefined(promocode) ? [] : promocode.Id === -1 || _.isNull(promocode.Actions) || 
			_.isEmpty(promocode.Actions) ? [_.clone(this.ActionDummy)] : !isProducts ? _.map(actions, function (item) {
				return _.extend(item, {
					GroupType : item.ActionType === 1 || item.ActionType === 2 ? 1 
						: item.ActionType === 3 || item.ActionType === 4 ? 2 : item.ActionType === 5 ? 3 : -1,
					Amount : item.ActionType === 4 ? getRubs(item.Amount) : item.Amount
				})
			}) : [{
				Id : -1,
				GroupType : 3,
				Type : 5,
				Products : _.map(actions[5], function (product) {
					var pr = _.find(store.SystemProducts, { VendorCode : product.VendorCode });
					return _.extend(product, {
						Id : product.Id,
						VendorCode : product.VendorCode,
						ProductId : store.UseProducts && !_.isUndefined(pr) ? pr.Id : -1,
						Amount : getRubs(product.Amount)
					})
				})
			}]

		this.ButtonLoading = false;
		this.ErrorCode = "";
	}

	collectItem(id, value) { this.Promocode[id] = value; }

	collectAction(id, value, index, productIndex) {
		if (!_.isUndefined(productIndex)) this.Actions[index].Products[productIndex][id] = value;
		else if (/ActionType/.test(id)) this.Actions[index].ActionType = parseInt(value, 10);
		else this.Actions[index][id] = value;

		if (/GroupType/.test(id)) {
			this.Actions[index].ActionType = value === 3 ? 5 : -1;
			this.Actions[index].Products = value === 3 ? [_.clone(this.ProductDummy)] : [];
		}
	}

	getSaveData() {
		var store = this,
			useProducts = this.UseProducts,
			products = this.SystemProducts,
			data = _.clone(this.Promocode);

		if (data.MaxCount === "") data.MaxCount = 0;

		_.each(this.Actions, function(action, index) {
			var name = "Actions." + index + ".";

			if (action.ActionType === 5) {
				_.each(action.Products, function (product, i) {
					name = "Actions." + i + ".";

					data[name + "Id"] = product.Id;
					data[name + "ActionType"] = action.ActionType;
					data[name + "VendorCode"] = useProducts && _.find(products, { Id : product.ProductId}) 
						? _.find(products, { Id : product.ProductId}).VendorCode : product.VendorCode;
					data[name + "Amount"] = (product.Amount*100).toFixed(0);
					data[name + "BonusDuration"] = store.Promocode.BonusDuration;
				});
			} else {
				data[name + "Id"] = action.Id;
				data[name + "ActionType"] = action.ActionType;
				data[name + "VendorCode"] = "";
				data[name + "Amount"] = action.ActionType === 4 ? (action.Amount*100).toFixed(0) : action.Amount;
				data[name + "BonusDuration"] = store.Promocode.BonusDuration;
			}
		});

		data = _.omit(data, ["BonusDuration"]);

		if (data.StartDate === "" || _.isNull(data.StartDate)) data = _.omit(data, "StartDate");
		if (data.EndDate === "" || _.isNull(data.EndDate)) data = _.omit(data, "EndDate");

		return data;
	}

	validateData() {
		var store = this,
			isValid = true;

		this.ErrorCode = "";
		this.SuccessSave = false;

		if (_.isEmpty(this.Actions)) { 
			this.ErrorCode = "BONUS_ACTIONS_REQUIRED";
			return false;
		}

		_.each(this.Actions, function (action) {
			if (action.GroupType === -1 || action.GroupType === "") {
				store.ErrorCode = "BONUS_ACTION_TYPE_REQUIRED";
				isValid = false;
				return false;
			}

			if (action.ActionType === -1 || action.ActionType === "") {
				store.ErrorCode = "BONUS_ACTION_TYPE_REQUIRED";
				isValid = false;
				return false;
			}

			if (action.GroupType === 1 && action.Amount === "") {
				store.ErrorCode = "BONUS_ACT_COUNT_REQUIRED";
				isValid = false;
				return false;
			}

			if (action.GroupType === 2 && action.Amount === "") {
				store.ErrorCode = "BONUS_ACT_SALE_REQUIRED";
				isValid = false;
				return false;
			}

			if (action.GroupType === 3) _.each(action.Products, function (product) {
				if (store.UseProducts && (product.ProductId === "" || product.ProductId === -1)) {
					store.ErrorCode = "BONUS_ACT_PRODUCT_REQUIRED";
					isValid = false;
					return false;
				}

				if (!store.UseProducts && product.VendorCode === "") {
					store.ErrorCode = "BONUS_ACT_PRODUCT_REQUIRED";
					isValid = false;
					return false;
				}

				if (product.Amount === "") {
					store.ErrorCode = "BONUS_ACT_AMOUNT_REQUIRED";
					isValid = false;
					return false;
				}
			})
		})

		return isValid;
	}
}
decorate(PromocodeState, {
	Promocodes: observable,
	IsSendRequest: observable,
	Active: observable,

	UseProducts: observable,
	SystemProducts: observable,
	PromocodeChars: observable,

	Promocode: observable,
	Actions: observable,
	Conditions: observable,

	ButtonLoading: observable,
	ErrorCode: observable,
	SuccessSave: observable,
});
export const promocodeState = new PromocodeState();