import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import moment from 'moment';

import { NavLink } from 'react-router-dom';

import { toSelectList, checkRights, getRequest, getRubs, setUnit, formatDate, getHourTime } from '../functions';
import { buttonTitles } from '../dictionary';

import { ItemHeader, TableReportPage } from './PageTemplates';
import { Selectize, TextInput, DateInput } from '../forms/FormItems';

import { uistate, confirmState, authUserState } from '../stores/common';
import { transferState } from '../stores/transfers';
import { autorun } from 'mobx';

export class TransferPage extends Component {
	constructor(props) {
		super(props);

		transferState.setTransfer(undefined);
		transferState.setFilter();

		this.state = {
			isOpen : false
		};

		this.getData = this.getData.bind(this);
		this.getData();

		var model = this;
		getRequest("Stocks", {}, function (data) { model.setData(data, "Stocks"); });
		getRequest("CancelReasons", {}, function (data) { model.setData(data, "CancelReasons"); });
		getRequest("Products", { Active : true }, function (data) { model.setData(data, "Products"); });
		getRequest("Requests", { Type : 2, Status : 1 }, function (data) { model.setData(data, "Requests"); });
	}

	setData (data, param) { this.setState({ [param] : data.Success ? data[param] : [] }); }

	getData() {
		transferState.IsSendRequest = false;
		getRequest("Transfers", transferState.getFilterData(), function (data) {
			transferState.setTransfers(data);
		});
	}

	getItem(id) {
		getRequest("Transfer", { Id : id }, function (data) {
			transferState.setTransfer(data.Success ? data : undefined);
		});
	}

	setId(id, type) {
		if (!_.isUndefined(id)) {
			if (id !== -1) {
				this.getItem(id);
				transferState.OperationType = _.capitalize(type);
			} else {
				transferState.OperationType = "Create";
				transferState.setTransfer({ Id: -1 });
			}
		} else {
			transferState.setTransfer(undefined);
			transferState.OperationType = "";
			this.getData();
		}
	}

	toggleFilter (event) {
		event.preventDefault();
		this.setState({ isOpen : !this.state.isOpen });
	}

	handleChange(value, id) { transferState.collectFilter(id, value); }

	handleSubmit(event) {
		event.preventDefault();
		transferState.Filter.Offset = 0;
		this.getData();
	}

	changePage (offset) { 
		transferState.Filter.Offset = offset;
		this.getData();
	}

	removeId(id) {
		if (_.isUndefined(id)) return false;

		var model = this,
			transfer = _.find(transferState.Transfers, { Id : id }),
			text = <span>Вы действительно хотите удалить перемещение&nbsp; 
				<b key="b1">«{transfer ? transfer.TransferNumberName : ""}»</b>?</span>;

		confirmState.openConfirm(text, function() {
			getRequest("RemoveTransfer", { Id : id }, function (data) { model.getData(); });
		});
	}

	render() {
		var canManage = checkRights("ManageTransfers") || checkRights("AcceptTransfers"),
			statuses = [{ Id : 1, Name : "Отправлено" }, { Id : 2, Name : "Принято" }, { Id : 3, Name : "Подтверждено" }],
			columns = {
				ShiftDate: {}, TransferNumberName: {}, 
				FromStock: {
					Cell: row => <span title={row.original.FromStock}>{row.original.FromStock}</span>
				}, 
				ToStock: {
					Cell: row => <span title={row.original.ToStock}>{row.original.ToStock}</span>
				},
				FromUser: { Cell: function (row) {
					var from = row.original.FromUser + moment(row.original.FromDate).format(", DD.MM.YYYY HH:mm");
					return <span title={from}>{from}</span>
				} },
				ToUser: { Cell: function (row) {
					var to = row.original.ToUser ? row.original.ToUser + 
						moment(row.original.ToDate).format(", DD.MM.YYYY HH:mm") : "";
					
					return <span title={to}>{to}</span>
				} },
				Status: { Cell: row => _.find(statuses, { Id: row.original.Status }).Name, width : 80 }
			};

		if (checkRights("AcceptTransfers")) _.extend(columns, { Amount: {} });

		if (canManage) _.extend(columns, { Take: {}, Edit : {}, View : {}, Remove : {} });

		return (
			<div className="page" id="TransfersPage">
				{_.isUndefined(transferState.Transfer) &&
					<div className="head">
						<h2>Перемещения</h2>
						<a href="/" className={"toggleFilter " + (this.state.isOpen ? "active" : "")} 
							title="Фильтр поиска" onClick={this.toggleFilter.bind(this)}><i></i></a>
						{canManage &&
							<button className="add" onClick={(e) => { this.setId(-1) }}>
								{buttonTitles[this.props.Type] || "Добавить"}</button>
						}
					</div>
				}
				{this.state.isOpen && _.isUndefined(transferState.Transfer) &&
					<form onSubmit={this.handleSubmit.bind(this)} className="filters clearfix">
						<div className="col col80">
							<Selectize 
								Id="FromStockId"
								Title="Откуда"
								List={toSelectList(this.state.Stocks)}
								Value={transferState.Filter.FromStockId}
								onChange={this.handleChange.bind(this)}
							/>
							<Selectize 
								Id="ToStockId"
								Title="Куда"
								List={toSelectList(this.state.Stocks)}
								Value={transferState.Filter.ToStockId}
								onChange={this.handleChange.bind(this)}
							/>
							<Selectize 
								Id="Status"
								Title="Статус"
								List={toSelectList(statuses)}
								Value={transferState.Filter.Status}
								onChange={this.handleChange.bind(this)}
							/>
							<DateInput 
								Id="ShiftDate"
								Title="Дата перемещения"
								Value={transferState.Filter.ShiftDate}
								onChange={this.handleChange.bind(this)}
							/>
						</div>
						<div className="buttons class col col20"><button>Найти</button></div>
					</form>
				}
				{!_.isEmpty(transferState.Transfers) && _.isUndefined(transferState.Transfer) &&
					<TableReportPage
						Type="Movement"
						TableData={transferState.Transfers}
						TableColumns={columns}
						
						FilterHeight={this.state.isOpen ? 125 : 0}
						
						Pagination={true}
						Limit={transferState.Limit}
						HasMore={transferState.HasMore}
						Offset={transferState.Filter.Offset}
						
						changePage={this.changePage.bind(this)}
						editRow={this.setId.bind(this)}
						removeRow={this.removeId.bind(this)}

						getTrProps={(state, rowInfo, column) => {
							return { className: rowInfo && rowInfo.original.Status === 3 ? "notactive" : "" };
						}}
					/>
				}
				{transferState.IsSendRequest && _.isEmpty(transferState.Transfers) && _.isUndefined(transferState.Transfer) &&
					<p>По вашему запросу ничего не найдено</p>
				}
				{!_.isUndefined(transferState.Transfer) &&
					<TransferItem
						Stocks={this.state.Stocks}
						Products={_.filter(this.state.Products, function (v) { return v.Type !== 3; })}
						CancelReasons={_.filter(this.state.CancelReasons, { Type : "Kitchen" })}
						Requests={this.state.Requests}

						setId={this.setId.bind(this)}
						getItem={this.getItem.bind(this)}
					/>
				}
			</div>
		)
	}
}
TransferPage = observer(TransferPage);

class TransferItem extends Component {
	constructor(props) {
		super(props);

		if (transferState.Transfer.FromStockId !== -1) this.getActual();
	}

	getActual() {
		getRequest("StockActual", { Id: transferState.Transfer.FromStockId }, function (data) {
			transferState.setActual(data.Success ? data.Products : []);
		});
	}

	handleClose(event) {
		event.preventDefault();
		this.props.setId(undefined);
	}

	handleSubmit(event) {
		event.preventDefault();
		this.saveTransfer();		
	}

	handleAccept(event) {
		event.preventDefault();

		var model = this,
			text = <span>Вы действительно хотите подтвердить перемещение&nbsp;
				<b key="b1">«{transferState.Transfer.TransferNumberName}»</b>?</span>;

		confirmState.openConfirm(text, function() { model.saveTransfer("accept"); });
	}

	saveTransfer (type) {
		if (!transferState.validateData()) return false;
		
		var model = this,
			request = transferState.OperationType === "Original" ? "Save" : transferState.OperationType;

		transferState.ButtonLoading = true;

		getRequest(request + "Transfer", transferState.getSaveData(), function (data) {
			if (data.Success) {
				transferState.OperationType = "Original";
				
				if (type === "accept") model.acceptTransfer();
				else model.props.getItem(data.Id);
			}
			transferState.ButtonLoading = data.Success ? type !== "accept" : false;
			transferState.ErrorCode = data.Success ? "SUCCESS_SAVE" : data.ErrorCode;
			transferState.SuccessSave = data.Success;
		});
	}

	acceptTransfer () {
		var model = this;
		getRequest("AcceptTransfer", { Id : transferState.Transfer.Id }, function (data) {
			if (data.Success) {
				transferState.OperationType = "View";
				model.props.getItem(transferState.Transfer.Id);
			}

			transferState.ButtonLoading = false;
			transferState.ErrorCode = data.Success ? "SUCCESS_ACCEPT" : data.ErrorCode;
			transferState.SuccessSave = data.Success;
		});
	}
	
	handleChange (value, id) {
		if (/RequestId/.test(id)) transferState.setRequest(_.find(this.props.Requests, { Id : value }));
	}

	render() {
		var style = { height : uistate.sectionHeight - (transferState.Transfer.Id === -1 ? 67 : 5) },
			title = transferState.Transfer.Id === -1 ? buttonTitles.NewTransfer 
				: buttonTitles.EditTransfer + " " + transferState.Transfer.TransferNumberName,
			canAccept = transferState.OperationType === "Original" && transferState.Transfer.Status === 2
				&& checkRights("AcceptTransfers"),
			requests = _.map(this.props.Requests, function (v) { return {
				Id : v.Id,
				Name : "Склад " + v.StockName + " от " + moment(v.Date).format("DD.MM.YYYY")
			}});

		return (
			<section className="clearfix item" id="TransferItem">
				<form onSubmit={this.handleSubmit.bind(this)}>
					<ItemHeader
						Title={title}
						Error={transferState.ErrorCode}
						Loading={transferState.ButtonLoading}
						CanManage={checkRights("ManageTransfers") || checkRights("AcceptTransfers")}
						CanAccept={canAccept}
						Success={transferState.SuccessSave}
						handleClose={this.handleClose.bind(this)}
						onAccept={this.handleAccept.bind(this)}
					/>
					{transferState.Transfer.Id === -1 &&
						<div className="clearfix main backblock">
							<Selectize 
								Id="RequestId"
								Title="Создать на базе заявки"
								List={toSelectList(requests)}
								RowClass="col col50"
								onChange={this.handleChange.bind(this)}
							/>
						</div>
					}
					<div className="clearfix section" style={style}>
						<MainTransfers Stocks={this.props.Stocks} getActual={this.getActual.bind(this)} />
						<ProductTransferBlock Products={this.props.Products} CancelReasons={this.props.CancelReasons} />
					</div>
				</form>
			</section>
		)
	}
}
TransferItem = observer(TransferItem);

class MainTransfers extends Component {
	handleChange (value, id) { 
		transferState.collectTransfer(id, value); 

		if (/FromStockId/.test(id) && value !== "" && value !== -1) this.props.getActual();
	}

	render() {
		var from = transferState.Transfer.FromDate !== "" ? moment(transferState.Transfer.FromDate) : "",
			to = transferState.Transfer.ToDate !== "" ? moment(transferState.Transfer.ToDate) : "",
			minutes = from !== "" && to !== "" ? to.diff(from, "minutes") : "";

		return(
			<div className="clearfix main">
				<div className="clearfix processStockStat">
					<div className="clearfix tr">
						<div className="col col25 td from">
							<h5><i></i>Откуда</h5>
							{transferState.Transfer.Id !== -1 && 
								<div className="clearfix row">
									<span className="col col40 title">Пользователь</span>
									<span className="col col60">{transferState.Transfer.FromUser}</span>
								</div>
							}
							<div className="clearfix row">
								<span className="col col40 title">Склад</span>
								{transferState.Transfer.Id === -1 &&
									<Selectize
										Id="FromStockId"
										List={toSelectList(_.sortBy(this.props.Stocks, function (v) {
											return authUserState.Shift.Active ? v.FilialId !== authUserState.Shift.FilialId : true;
										}))}
										RowClass="col col60 nomargin"
										Value={transferState.Transfer.FromStockId}
										onChange={this.handleChange.bind(this)}
									/>
								}
								{transferState.Transfer.Id !== -1 &&
									<span className="col col60">{transferState.Transfer.FromStock}</span>
								}
							</div>
							{transferState.Transfer.Id !== -1 &&
								<div className="clearfix row">
									<span className="col col40 title">Дата смены</span>
									<span className="col col60">{formatDate(transferState.Transfer.ShiftDate)}</span>
								</div>
							}
							<div className="clearfix row">
								<span className="col col40 title">Дата отправки</span>
								{transferState.Transfer.Id !== -1 && transferState.OperationType !== "Original" &&
									<span className="col col60">
										{formatDate(transferState.Transfer.FromDate, "DD.MM.YYYY HH:mm")}
									</span>}
								{(transferState.Transfer.Id === -1 || transferState.OperationType === "Original") &&
									<DateInput
										Id="FromDate"
										Value={transferState.Transfer.FromDate}
										WithTime={true}
										RowClass="col col60 nomargin"
										onChange={this.handleChange.bind(this)}
									/>
								}
							</div>
						</div>
						<div className="col col25 td move">
							<h5><i></i>Перемещение</h5>
							<div className="clearfix row">
								<span className="col col40 title">Курьер</span>
								{transferState.Transfer.Id === -1 &&
									<TextInput
										Id="CourierName"
										Required={true}
										RowClass="col col60 nomargin"
										Value={transferState.Transfer.CourierName}
										onChange={this.handleChange.bind(this)}
									/>
								}
								{transferState.Transfer.Id !== -1 &&
									<span className="col col60">{transferState.Transfer.CourierName}</span>
								}
							</div>
							{transferState.Transfer.Id !== -1 && minutes !== "" &&
								<div className="clearfix row">
									<span className="col col40 title">Время в пути</span>
									<span className="col col60">{getHourTime(minutes)}</span>
								</div>
							}
						</div>
						<div className="col col25 td to">
							<h5><i></i>Куда</h5>
							{(transferState.Transfer.Status === 2 || transferState.Transfer.Status === 3) && 
								<div className="clearfix row">
									<span className="col col40 title">Пользователь</span>
									<span className="col col60">{transferState.Transfer.ToUser}</span>
								</div>
							}
							<div className="clearfix row">
								<span className="col col40 title">Склад</span>
								{transferState.Transfer.Id === -1 &&
									<Selectize
										Id="ToStockId"
										List={toSelectList(_.sortBy(this.props.Stocks, function (v) {
											return authUserState.Shift.Active ? v.FilialId === authUserState.Shift.FilialId : true;
										}))}
										RowClass="col col60 nomargin"
										Value={transferState.Transfer.ToStockId}
										onChange={this.handleChange.bind(this)}
									/>
								}
								{transferState.Transfer.Id !== -1 &&
									<span className="col col60">{transferState.Transfer.ToStock}</span>
								}
							</div>
							<div className="clearfix row">
								<span className="col col40 title">Дата приемки</span>
								{((transferState.Transfer.Status === 2 && transferState.OperationType !== "Original") 
										|| transferState.Transfer.Status === 3) &&
									<span className="col col60">
										{formatDate(transferState.Transfer.ToDate, "DD.MM.YYYY HH:mm")}
									</span>}
								{((transferState.Transfer.Status === 1 && transferState.OperationType === "Take") ||
									(transferState.Transfer.Status === 2 && transferState.OperationType === "Original")) &&
									<DateInput
										Id="ToDate"
										Value={transferState.Transfer.ToDate}
										WithTime={true}
										RowClass="col col60 nomargin"
										onChange={this.handleChange.bind(this)}
									/>
								}
							</div>
						</div>
						{transferState.Transfer.Status === 3 &&
							<div className="col col25 td accept">
								<h5><i></i>Подтверждено</h5>
								<div className="clearfix row">
									<span className="col col40 title">Пользователь</span>
									<span className="col col60">{transferState.Transfer.AcceptUser}</span>
								</div>
								<div className="clearfix row">
									<span className="col col40 title">Дата подтверждения</span>
									<span className="col col60">{formatDate(transferState.Transfer.AcceptDate)}</span>
								</div>
								{checkRights("AcceptTransfers") &&
									<div className="clearfix row">
										<span className="col col40 title">Сумма перемещения</span>
										<span className="col col60">{getRubs(transferState.Transfer.Amount, true, true)} руб.</span>
									</div>
								}
							</div>
						}
					</div>
				</div>
				{((transferState.Transfer.Status === 3 && transferState.Transfer.Description !== "") ||
						transferState.Transfer.Status !== 3) &&
					<div className="clearfix block">
						{transferState.Transfer.Status !== 3 &&
							<TextInput
								Id="Description"
								Title="Комментарий"
								Value={transferState.Transfer.Description}
								Type="textarea"
								RowClass="col col75"
								onChange={this.handleChange.bind(this)}
							/>
						}
						{transferState.Transfer.Status === 3 && <h5>Комментарий</h5>}
						{transferState.Transfer.Status === 3 && <p>{transferState.Transfer.Description}</p>}
					</div>
				}
			</div>
		)
	}
}
MainTransfers = observer(MainTransfers);

class ProductTransferBlock extends Component {
	handleAdd(event) {
		event.preventDefault();

		transferState.Products = _.concat(transferState.Products, _.clone(transferState.ProductDummy));
	}

	render() {
		var view = this,
			editNew = transferState.OperationType === "Original" && transferState.Transfer.Status === 1;

		return(
			<div className="clearfix productList block">
				<h4>Состав перемещения</h4>
				<div className="clearfix products">
					<div className="clearfix titles">
						<span className="col col25">Продукт</span>
						<span className="col col15">На складе откуда</span>
						{transferState.Transfer.Status !== 3 &&
							<span className="col col10">ЕИ</span>
						}
						<span className="col col10">Заказ</span>
						<span className="col col10">Отправлено</span>
						<span className="col col10">Принято</span>
						{transferState.OperationType !== "Create" && !editNew &&
							<span className="col col20">Расхождение</span>
						}
						{transferState.Transfer.Status === 3 && checkRights("AcceptTransfers") &&
							<span className="col col10">Сумма</span>
						}
					</div>
					{_.map(transferState.Products, function (product, i) {
						if (transferState.Transfer.Status !== 3)
							return(<ProductItem key={"p" + transferState.pCounter++} Index={i}
								Item={product} Products={view.props.Products} CancelReasons={view.props.CancelReasons} />)
						else return(<ProductViewItem key={"p" + transferState.pCounter++} Index={i}
							Item={product} CancelReasons={view.props.CancelReasons} />)
					})}
					{transferState.OperationType !== "View" &&
						<a href="/" className="icon button add" onClick={this.handleAdd.bind(this)}>
							<i></i>Добавить сырье</a>
					}
				</div>
			</div>
		)
	}
}
ProductTransferBlock = observer(ProductTransferBlock);

class ProductItem extends Component {
	handleDelete(event) {
		event.preventDefault();

		var index = this.props.Index;
		transferState.Products = _.filter(transferState.Products, function (v,i) { return i !== index; });
	}

	handleChange (value, id) { 
		var product = undefined;

		if (/ProductId/.test(id)) product = _.find(this.props.Products, { Id : value });
		transferState.collectProducts(id, value, this.props.Index, product); 
	}

	render() {
		return(
			<div className="clearfix item">
				<Selectize 
					Id="ProductId"
					List={toSelectList(this.props.Products)}
					Value={this.props.Item.ProductId}
					RowClass="col col25"
					Disabled={transferState.OperationType === "Take" || transferState.OperationType === "View"}
					onChange={this.handleChange.bind(this)}
				/>
				<span className="col col15">
					{this.props.Item.Actual !== "" && !_.isUndefined(this.props.Item.Actual) &&
						setUnit(this.props.Item.Actual, this.props.Item.UnitName) + " " + this.props.Item.LastUpdate
					}
				</span>
				<TextInput 
					Id="UnitName"
					Value={this.props.Item.UnitName}
					RowClass="col col10"
					Disabled={true}
				/>
				<TextInput 
					Id="Request"
					Value={this.props.Item.Request}
					RowClass="col col10"
					Disabled={true}
				/>
				<TextInput 
					Id="CountOut"
					Value={this.props.Item.CountOut}
					RowClass={"col col10 " + (this.props.Item.CountOut > this.props.Item.Actual
						&& this.props.Item.CountOut !== "" ? "alert" : "")}
					Disabled={transferState.OperationType === "Take" || transferState.OperationType === "View"}
					Required={true}
					Type="number"
					Step="0.001"
					onChange={this.handleChange.bind(this)}
				/>
				<TextInput 
					Id="CountIn"
					Value={this.props.Item.CountIn}
					RowClass={"col col10 " + (this.props.Item.CountIn < this.props.Item.CountOut
						&& this.props.Item.CountIn !== "" ? "alert" : "")}
					Disabled={transferState.OperationType === "Create" || transferState.OperationType === "View" 
						|| (transferState.OperationType === "Original" && transferState.Transfer.Status === 1)}
					Type="number"
					Step="0.001"
					onChange={this.handleChange.bind(this)}
				/>
				{this.props.Item.CountIn < this.props.Item.CountOut && this.props.Item.CountIn !== "" &&
					<Selectize 
						Id="CancelReasonId"
						List={toSelectList(this.props.CancelReasons)}
						Value={this.props.Item.CancelReasonId}
						RowClass="col col20"
						Disabled={transferState.OperationType === "View"}
						onChange={this.handleChange.bind(this)}
					/>
				}
				{transferState.OperationType !== "View" && transferState.OperationType !== "Take" &&
					<a href="/" className="button icon remove" onClick={this.handleDelete.bind(this)}>
						<i></i></a>
				}
			</div>
		)
	}
}
ProductItem = observer(ProductItem);

export class ProductViewItem extends Component {
	render() {
		return(
			<div className="clearfix item">
				<span className="col col25">{this.props.Item.Product}</span>
				<span className="col col15">
					{!_.isUndefined(this.props.Item.Actual) &&
						setUnit(this.props.Item.Request, this.props.Item.UnitName) + this.props.Item.LastUpdate
					}
				</span>
				<span className="col col10">{setUnit(this.props.Item.Request, this.props.Item.UnitName)}</span>
				<span className="col col10">{setUnit(this.props.Item.CountOut, this.props.Item.UnitName)}</span>
				<span className="col col10">{setUnit(this.props.Item.CountIn, this.props.Item.UnitName)}</span>
				<span className="col col20">{this.props.Item.CancelReason}</span>
				<span className="col col10">{getRubs(this.props.Item.Amount, true, true)}</span>
			</div>
		)
	}
}
ProductViewItem = observer(ProductViewItem)

export class TransferWidget extends Component {
	constructor(props) {
		super(props);
		this.state = {}

		this.getData = this.getData.bind(this);
		this.getData();
	}

	disposer = autorun(() => {
		if (authUserState.Shift.Active) this.getData();
	});

	getData() {
        transferState.IsSendRequest = false;
        getRequest("Transfers", { Status : 1 }, function (data) { transferState.setWidgetTransfers(data); });
	}

	render() {
		return(
			<div className="widget" id="ReleaseWidget">
				<h4>Перемещения</h4>
				<NavLink to="/Movement" className="openPage" title="Перейти на страницу перемещений"><i></i></NavLink>

                {transferState.IsSendRequest && _.isEmpty(transferState.WidgetTransfers) &&
					<p className="comment">У вас нет непринятых перемещений</p>
				}
                {!_.isEmpty(transferState.WidgetTransfers) && transferState.IsSendRequest && 
                    _.map(transferState.WidgetTransfers, function (item, i) {
                        return(<WidgetTransferItem key={"wt" + i} Item={item} Index={i}/>);
                    })
                }
			</div>
		)
	}
}
TransferWidget = observer(TransferWidget);

class WidgetTransferItem extends Component {
	render() {
		var item = this.props.Item;
		
		return(
			<div className="clearfix item expired">
				<span className="col col30 name"><i></i>{item.TransferNumberName}</span>
				<span className="col col45" title={item.FromStock}>&nbsp;c {item.FromStock}</span>
				<span className="col col25 ">&nbsp;от {formatDate(item.FromDate, "time")}</span>
			</div>
		)
	}
}