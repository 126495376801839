import { observable, decorate, computed } from 'mobx';
import _ from 'lodash';

import { authUserState } from './common';
import { checkRights, collectCheckBox, compactProducts } from '../functions';

class RequestState {
	Request = undefined;
	Requests = [];
	IsSendRequest = false;

	Operation = "";
	Filter = "";
	
	RequestDummy = {
		Id: -1,
		Date: "",
		Status: 1,
		User: "",
		StockId: -1,
		TemplateType: -1,
		RawIds : []
	}
	ProductDummy = {
		ProductId: -1,
		Product: "",
		SectionId: -1,
		Section: "",
		Type: -1,
		MinCount: "",
		Actual: "",
		LastUpdate: "",
		UnitName: "",
		ProviderId: -1,
		Order: "",
		Count: "",
	}

	pCounter = 0;
	ErrorCode = "";
	ButtonLoading = false;

	/**
	 * Разбор заявок в таблицу
	 * @param {object} data ответ от сервера со списком заявок
	 */
	setRequests(data) {
		this.Requests = !data.Success ? [] : _.map(data.Requests, function (v) {
			return _.extend(v, {
				Stock: v.StockName,
				CanRemove: v.Status === 1 && ((checkRights("ManagePurchase") || v.User === authUserState.User.Name)),
				CanProcess: checkRights("ManagePurchase") && v.Status === 1 && v.Type === 1
			})
		});
		this.IsSendRequest = true;
	}

	/**
	 * Разбор выбранной заявки
	 * @param {object} request ответ от сервера со заявкой
	 */
	setRequest(request) {
		this.Request = _.isUndefined(request) ? request : request.Id === -1
			? _.clone(this.RequestDummy) : request;

		this.ProductsAll = _.isUndefined(request) ? [] : request.Products;
		this.CheckedTemplateProducts = [];
		this.ShowTemplate = !_.isUndefined(request) && request.Id === -1;

		this.ErrorCode = "";
		this.ButtonLoading = false;
		this.pCounter = 0;
		this.Filter = "";
	}

	/** Набор продуктов для шаблона */
	get TemplateProducts () {
		return _.isUndefined(this.StockProducts) ? [] : 
			_.map(_.groupBy(this.StockProducts, "Type"), function(list, type) {
				var sections = _.groupBy(list, "SectionId");
				return {
					Type: parseInt(type, 10),
					Sections: _.map(sections, function (products, section) {
						return {
							SectionId: parseInt(section),
							SectionName: products[0].Section,
							Products: _.map(products, function(product) {
								return { Id : product.ProductId, Name : product.Product }
							})
						}
					})
				};
			});
	}

	/**
	 * Сбор данных с формы шаблона заявки
	 * @param {string} id название параметра
	 * @param {value} value значение параметра
	 */
	collectTemplateProducts (id, value) {
		if (/All/.test(id)) {
			var sectionId = id.split("_").length >= 3 ? parseInt(id.split("_")[2], 10) : null;

			if (!_.isNull(sectionId)) {
				var products = _.map(_.filter(this.StockProducts, { SectionId : sectionId }), "ProductId");

				if (value) this.CheckedTemplateProducts = _.concat(this.CheckedTemplateProducts, products);
				else this.CheckedTemplateProducts = _.filter(this.CheckedTemplateProducts, function(v) {
					return _.indexOf(products, v) === -1; });
			}
		} else {
			var productId = id.split("_").length === 2 ? parseInt(id.split("_")[1], 10) : null;;
			if (!_.isNull(productId)) 
				this.CheckedTemplateProducts = collectCheckBox(productId, value, this.CheckedTemplateProducts);
		}
	}

	/** Начало заполнения заявки после выбора шаблона */
	startRequest() {
		var product = this.ProductDummy;

		this.ChangedValues = [];

		if (this.Request.TemplateType === 2)
			this.ProductsAll = _.map(this.CheckedTemplateProducts, function(v) {
				return _.extend(_.clone(product), { ProductId : v });
			});
		else 
			this.ProductsAll = _.map(this.StockProducts, function (v) {
				return _.extend(_.clone(product), { ProductId : v.ProductId });
			});
		this.ShowTemplate = false;
	}

	/** Объединение данных по продуктам из заявки, склада и остатков */
	get RequestsProducts () {
		if (_.isUndefined(this.Request)) return [];

		var stockProducts = this.StockProducts,
			actualProducts = this.StockActualProducts,
			changedValues = this.ChangedValues;

		return _.map(this.ProductsAll, function(item) {
			var stock = _.find(stockProducts, { ProductId : item.ProductId }),
				actual = _.find(actualProducts, { ProductId : item.ProductId }),
				notUpdate = _.indexOf(changedValues, item.ProductId) !== -1;

			return _.extend(_.clone(item), {
				Product : stock ? stock.Product : item.Product,
				UnitName : stock ? stock.UnitName : item.UnitName,
				SectionId : stock ? stock.SectionId : item.SectionId,
				Section : stock ? stock.Section : item.Section,
				Type : stock ? stock.Type : item.Type,
				MinCount : stock ? stock.Count : item.MinCount,
				Actual: actual ? actual.Count : item.Actual,
				LastUpdate: actual ? actual.LastUpdate : item.LastUpdate,
				Count: ((item.Count && item.Count !== "") || notUpdate) ? item.Count : actual ? actual.Count.toFixed(3) : "",
				Order: ((item.Order && item.Order !== "") || notUpdate) ? item.Order : stock && stock.Count > item.Count ? 
					(stock.Count - item.Count).toFixed(3) : 0
			});
		});
	}
	
	/** Фильтрация продуктов по поисковой строке */
	get filteredProducts () {
		var mask = _.toLower(this.Filter);

		return _.filter(this.RequestsProducts, function (product) {
			var name = _.toLower(product.Product);
			return name.indexOf(mask) !== -1;
		});
	}

	/** Группировка продуктов по типу и разделам */
	get grouppedProducts () {
		return _.map(_.groupBy(this.filteredProducts, "Type"), function (list, type) {
			var sections = _.groupBy(list, "SectionId");
			return {
				Type: _.isUndefined(type) ? -1 : parseInt(type),
				Sections: _.map(sections, function (products, section) {
					return {
						SectionId: parseInt(section),
						SectionName: products[0].Section,
						Products: products
					}
				})
			};
		});
	}

	/**
	 * Сбор данных с формы редактирования продуктов заявки
	 * @param {string} id Название параметра
	 * @param {string} value Значение параметра
	 * @param {number} productId ID редактируемого продукта
	 */
	collectProduct(id, value, productId) {
		this.ChangedValues = _.concat(this.ChangedValues, productId);
		var product = _.find(this.ProductsAll, { ProductId : productId });
		product[id] = value;
	}

	/**
	 * Сбор данных с формы редактирования заявки
	 * @param {number} id название параметра
	 * @param {string} value значение параметра
	 */
	collectRequest(id, value) { this.Request[id] = value; }

	/**
	 * Сбор данных со строки поиска по продуктам
	 * @param {string} value 
	 */
	collectFilter(value) { this.Filter = value; }

	

	/**
	 * Сбор данных для сохранения заявки
	 * @returns {object} параметры запроса для сохранения на сервер
	 */
	getSaveData() {
		var data = { 
				StockId: this.Request.StockId,
				TemplateType : this.Request.TemplateType,
				RawIds : this.CheckedTemplateProducts.join(",")
			},
			products = compactProducts(this.grouppedProducts),
			index = 0;

		_.each(products, function (v) {
			if (_.isUndefined(v.Count) || v.Count === "") return true;
			
			var name = "Products." + index + ".";
			data[name + "ProductId"] = v.ProductId;
			data[name + "Count"] = v.Count;
			index++;
		});

		return data;
	}

	/**
     * Сбор данных для обработки заявки
     * @returns {object} параметры запроса для обработки
     */
	getProcessData() {
		var stock = this.Request.StockId,
			data = { RequestId: this.Request.Id },
			products = compactProducts(this.grouppedProducts, false),
			index = 0;

		_.each(products, function (v) {
			if (v.Order === "0" || v.Order === 0 || v.Order === "") return true;
			
			var name = "Products." + index + ".";
			data[name + "ProductId"] = v.ProductId;
			data[name + "ProviderId"] = v.ProviderId;
			data[name + "StockId"] = stock;
			data[name + "Count"] = v.Order;
			index++;
		});

		return data;
	}

	/**
	 * Проверка корректности данных для обработки заявки
	 * @returns {boolean} данные корректны
	 */
	validateProcess() {
		var isValid = true;

		var products = _.filter(this.ProductsAll, function (v) {
				return v.Order !== "0" && v.Order !== 0 && v.Order !== "";
			}),
			emptyProviders = _.filter(this.ProductsAll, function (v) {
				return _.isUndefined(v.ProviderId) || v.ProviderId === -1 || v.ProviderId === "";
			});

		if (_.isEmpty(products)) {
			this.ErrorCode = "PROCESS_PRODUCTS_REQUIRED";
			return false;
		}

		if (!_.isEmpty(emptyProviders)) {
			this.ErrorCode = "PROVIDER_REQUIRED";
			return false;
		}

		return isValid;
	}
}
decorate(RequestState, {
	Request: observable,
	Products: observable,
	Operation: observable,
	Filter: observable,
	Providers: observable,

	StockProducts: observable,
	StockActualProducts: observable,
	CheckedTemplateProducts: observable,
	ShowTemplate: observable,
	ProductsAll: observable,

	Requests: observable,
	IsSendRequest: observable,
	ChangedValues: observable,

	ButtonLoading: observable,
	ErrorCode: observable,

	RequestsProducts: computed,
	filteredProducts: computed,
	grouppedProducts: computed,
});
export const requestState = new RequestState();

class ShopListState {
	Lists = [];
	IsSendRequest = false;

	ShopList = undefined;
	ShopListDummy = {
		Id: -1,
		StockId: -1,
		ProviderId: -1,
		Date: "",
		Status: "",
		MinAmount: "",
		Amount: "",
		Products: [],
	}
	Products = [];
	ProductDummy = {
		ProductId: -1,
		Count: "",
		MinCount: "",
		VAT: "",
		UnitName: "",
		Price: ""
	}
	pCounter = 0
	Provider = undefined;
	ButtonLoading = false;
	SuccessSave = false;
	ErrorCode = "";

	/** Настройка страницы по умолчанию */
	setDefault () {
		this.Lists = [];
		this.Providers = [];
		this.setList(undefined);

		// this.ButtonLoading = false;
		// this.SuccessSave = false;
		// this.ErrorCode = "";
	}

	/** Разбор списков закупок от сервера
	 * @param {object} data ответ от сервера
	 */
	setLists(data) {
		this.Lists = data.Success ? _.map(data.ShopLists, function (v) {
			return _.extend(v, {
				CanSend: v.Status === 1, CanEdit: v.Status === 1,
				CanView: v.Status !== 1, CanRemove: v.Status === 1
			});
		}) : [];
		this.IsSendRequest = true;
	}

	setList(shopList) {
		this.ShopList = _.isUndefined(shopList) ? shopList
			: _.omit(_.clone(shopList), ["Success", "ErrorCode"]);

		this.Provider = _.isUndefined(shopList) ? undefined : this.Provider;
		this.Products = _.isUndefined(shopList) || _.isNull(shopList.Products) ? [] : shopList.Products;

		this.ButtonLoading = false;
		this.ErrorCode = "";
		this.pCounter = 0;
	}

	setProvider(provider) {
		this.Provider = _.isUndefined(provider) ? provider
			: _.omit(_.clone(provider), ["Success", "ErrorCode"]);

		if (!_.isUndefined(provider)) {
			this.Products = _.map(this.Products, function (v, i) {
				var product = _.find(provider.Products, { ProductId: v.ProductId }),
					unit = product ? _.find(authUserState.Units, { Id: product.UnitId }) : ""

				return _.extend(v, {
					UnitName: unit ? unit.Name : "",
					Price: product ? product.Price : "",
					MinCount: product ? product.MinCount : "",
					VAT: product ? product.VAT : ""
				})
			})
		}
	}

	collectProducts(id, value, index) {
		this.Products[index][id] = value;

		if (/ProductId/.test(id)) {
			var product = _.find(this.Provider.Products, { ProductId: value }),
				unit = product ? _.find(authUserState.Units, { Id: product.UnitId }) : "";

			this.Products[index] = _.extend(this.Products[index], {
				UnitName: unit ? unit.Name : "",
				Price: product ? product.Price : "",
				MinCount: product ? product.MinCount : "",
				VAT: product ? product.VAT : ""
			})
		}
	}

	getSaveData() {
		var data = { Id: this.ShopList.Id };

		_.each(this.Products, function (v, i) {
			var name = "Products." + i + ".";

			data[name + "ProductId"] = v.ProductId;
			data[name + "Count"] = v.Count === "" ? "0" : v.Count;
		});

		return data;
	}

}
decorate(ShopListState, {
	ShopList: observable,
	Products: observable,
	Provider: observable,

	Lists: observable,
	IsSendRequest: observable,
	Providers: observable,

	ButtonLoading: observable,
	ErrorCode: observable,
	SuccessSave: observable,
});
export const shopListState = new ShopListState();

