import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import moment from 'moment';

import { TextInput, Selectize, DateInput, CheckGroup, SubmitButton } from '../forms/FormItems';

import { TableReportPage } from './PageTemplates';

import { CassaTransactionTypes, dashboardsTitles, errors } from '../dictionary';
import { checkRights, getRequest, toSelectList, formatDate, toCheckList, getRubs } from '../functions';
import { uistate, confirmState, authUserState, errorState } from '../stores/common';
import { cassaState } from '../stores/cassa';

class CassaPage extends Component {
	constructor(props) {
		super(props);

		cassaState.setDefault();

		this.state = {};

		this.getData();
		this.getCassaBalance();

		var model = this;
		getRequest("CassaTransactionTags", {}, function (data) { model.setData(data, "CassaTransactionTag"); });
		getRequest("PaymentMethods", {}, function(data) { model.setData(data, "PaymentMethods") });
	}

	/**
	 * Разбор ответа от сервера и сотановка данных модели
	 * @param {object} data ответ от сервера
	 * @param {string} param название параметра
	 */
	setData(data, param) { this.setState({ [param]: data.Success ? data[param] : [] }); }

	/** API запрос на получение списка кассовых операций */
	getData() {
		if (_.isUndefined(cassaState.posList) || _.isEmpty(cassaState.posList)) return false;
		cassaState.IsSendRequest = false;
		getRequest("CassaTransactions", cassaState.getFilterData(), function (data) { cassaState.setCassa(data); });

		this.getCourierCash();
	}

	/** API запрос «CassaAmount» на получение остатка в кассе */
	getCassaBalance () {
		if (_.isUndefined(cassaState.posList) || _.isEmpty(cassaState.posList)) return false;

		getRequest("CassaAmount", { POSId : cassaState.getFilterData().POSId }, function(data) {
			cassaState.CassaAmount = data.Success ? data.Amount : 0;
		});
	}

	/** API запрос на получение долгов курьеров */
	getCourierCash() {
		if (_.isUndefined(cassaState.posList) || _.isEmpty(cassaState.posList)) return false;
		
		getRequest("CourierCash", { POSId : cassaState.Filter.POSId, IsDetails : true }, function (data) {
			cassaState.CouriersCash = data.Success ? data.Couriers : [];
			cassaState.CouriersAmount = data.Success ? data.Amount : 0;
		});
	}

	/** API запрос на открытие/закрытие кассы */
	changeCassaState () {
		var model = this;

		getRequest("ChangeStateCassaShift", cassaState.getCassaShift(), function (data) { 
			if (data.Success) model.getData();
			else errorState.setError(data.ErrorCode, undefined, data.ErrorMessage);
			
			cassaState.ConfimOpen = false;
			cassaState.ButtonLoading = false
		});
	}

	render() {
		var isShift = authUserState.Shift.Active,
			canViewAll = checkRights("CanViewAllCassa"),
			canViewShift = checkRights("ManageCassa") && isShift,
			canViewCassa = canViewAll || canViewShift,
			canViewDates = (checkRights("CanViewLastMonthCassa") || checkRights("CanViewAllPeriodCassa")) && checkRights("ManageCassa"),
			hasCassa = !_.isEmpty(cassaState.posList);

		return (
			<div className={"page " + (uistate.IsMobile ? "mobile" : "")} id="CassaPage">
				<div className="head clearfix">
					<h2>{dashboardsTitles[this.props.Type] || this.props.Type}</h2>
					{canViewCassa && <OpenCassa />}
					{canViewCassa && cassaState.IsOpen && !uistate.IsMobile && <CassaTransactions />}
				</div>
				{!hasCassa && authUserState.Shift.Active &&
					<p>У вас нет доступных касс для работы, обратитесь к администратору системы</p>
				}
				{checkRights("ManageCassa") && !canViewAll && !isShift && hasCassa &&
					<p>{errors.SHIFT_CLOSE}</p>
				}
				{canViewCassa && hasCassa &&
					<CassaFilter 
						CassaTransactionTags={this.state.CassaTransactionTag}
						getData={this.getData.bind(this)} 
						getCassaBalance={this.getCassaBalance.bind(this)}
					/>
				}
				{cassaState.IsSendRequest && !_.isEmpty(cassaState.Stat) && canViewCassa && !cassaState.CassaMulty &&
					<CassaMainStat CassaOpen={true} />
				}
				{hasCassa && cassaState.IsSendRequest && canViewCassa && _.isEmpty(cassaState.Dates) && 
					<CassaMainStat CassaOpen={false} />
				}
				{/* {hasCassa && cassaState.IsSendRequest && canViewCassa && _.isEmpty(cassaState.Dates) && 
					<p>{errors.EMPTY_RESPONSE}</p>
				} */}
				{cassaState.IsSendRequest && !_.isEmpty(cassaState.Transactions) && canViewCassa && !cassaState.CassaMulty &&
					<CassaSimpleTable 
						Transactions={cassaState.Transactions} 
						getData={this.getData.bind(this)}
					/>
				}
				
				{cassaState.IsSendRequest && canViewDates && !_.isEmpty(cassaState.Dates) && cassaState.CassaMulty &&
					<CassaReportTable 
						PaymentMethods={_.filter(this.state.PaymentMethods, { Fiscal : true })}
						getData={this.getData.bind(this)} 
					/>
				}

				{cassaState.ConfimOpen && <CassaConfirm changeCassaState={this.changeCassaState.bind(this)} />}
				{!_.isUndefined(cassaState.CassaTransaction) &&
					<CassaTransactionBlock 
						CassaTransactionTags={this.state.CassaTransactionTag}
						getData={this.getData.bind(this)}
					/>
				}
				{uistate.IsMobile && !_.isUndefined(cassaState.TransactionInfo) &&
					<CassaTransactionInfoBlock />
				}
			</div>
		)
	}
}
CassaPage = observer(CassaPage);

class OpenCassa extends Component {
	/** Открытие подтверждающего окна */
	handleClick () { cassaState.ConfimOpen = true; }

	/** API запрос на снятие Х-отчета по кассе */
	getXReport (event) {
		event.preventDefault();

		cassaState.XReportLoading = true;
		getRequest("CassaXReport", { POSId : cassaState.CurrentPOSId }, function(data) {
			if (!data.Success) errorState.setError(data.ErrorCode, null, data.ErrorMessage);
			cassaState.XReportLoading = false;
		});
	}

	render() {
		var	filial = _.find(authUserState.Filials, { Id : authUserState.Shift.FilialId }),
			poses = _.isUndefined(filial) ? [] : _.map(filial.POS, "Id"),
			canOpen = _.indexOf(poses, cassaState.Filter.POSId) !== -1,
			canReport = checkRights("ManageFiscalCassa") && cassaState.isFiscal,
			isSameShift = !authUserState.Shift.Active || cassaState.ShiftDate === "" ? true :
				authUserState.Shift.Date === cassaState.ShiftDate;

		return(
			<div className="openCassa">
				{authUserState.Shift.Active && canOpen && !uistate.IsMobile &&
					<button className={"button " + (cassaState.IsOpen ? "red" : "green")} 
						onClick={this.handleClick.bind(this)}>{(cassaState.IsOpen ? "Закрыть" : "Открыть") + " кассу"}
					</button>
				}
				{cassaState.IsOpen && canReport && !uistate.IsMobile &&
					<form onSubmit={this.getXReport.bind(this)}>
						<SubmitButton Title="X-отчет" Loading={cassaState.XReportLoading} />
					</form>
				}
				{!isSameShift && !uistate.IsMobile &&
					<p className="alert">Кассовая смена открыта <b>{formatDate(cassaState.ShiftDate)}</b>!</p>
				}
				{uistate.IsMobile && cassaState.IsOpen &&
					<p className={isSameShift ? "" : "alert"}>Кассовая смена открыта <b>{formatDate(cassaState.ShiftDate)}</b>!</p>
				}
				{uistate.IsMobile && !cassaState.IsOpen &&
					<p>Кассовая смена закрыта</p>
				}
			</div>
		)
	}
}
OpenCassa = observer(OpenCassa);

class CassaConfirm extends Component {
	/**
	 * Сбор данных с формы подтверждения
	 * @param {string} value значение параметра
	 */
	handleChange(value) { cassaState.SendZReport = value; }

	/** Закрытие подтверждающего окна */
	onCancel(event) {
		event.preventDefault();
		cassaState.ConfimOpen = false;
	}
	/** Подтверждение действия */
	onConfirm(event) {
		event.preventDefault();
		if (this.props.changeCassaState) {
			cassaState.ButtonLoading = true;
			this.props.changeCassaState();
		}
	}

	render() {
		var pos = _.find(cassaState.posList, { Id : cassaState.CurrentPOSId }),
			canReport = cassaState.isFiscal && checkRights("ManageFiscalCassa");
			
		return(
			<div className="back">
				<div className="confirmBlock window">
					<form onSubmit={this.onConfirm.bind(this)}>
						<p>Вы действительно хотите {cassaState.IsOpen ? "закрыть" : "открыть"} кассу&nbsp;
							<b key="b1">«{pos ? pos.Name : ""}»</b>?</p>
						{cassaState.isFiscal &&
							<p className="comment">Открытие и закрытие фискальных касс может занять 
								некоторое время. Пожалуйста, подождите.</p>
						}
						{cassaState.IsOpen && canReport &&
							<CheckGroup
								Id="ZReport"
								Type="checkbox"
								RowClass="nomargin"
								List={toCheckList([{ Id : "true", Name : "Отправить Z-отчет" }])}
								Value={cassaState.SendZReport ? ["true"] : []}
								onChange={this.handleChange.bind(this)}
							/>
						}
						<div className="buttons clearfix">
							<SubmitButton Loading={cassaState.ButtonLoading} Title ="Подтвердить"/>
							<a href="/" onClick={this.onCancel.bind(this)} className="button gray">Отменить</a>
						</div>
					</form>
				</div>
			</div>
		)
	}
}
CassaConfirm = observer(CassaConfirm);

class CassaTransactions extends Component {
	/** Открытие окна с проведением кассовой операции */
	handleClick(event) {
		var group = parseInt(event.currentTarget.getAttribute("rel"));
		if (!_.isNaN(group)) cassaState.setCassaTransaction(group);
	}

	render() {
		return(
			<div className="cassaTransactions">
				<button className="button icon add blue" rel={1} onClick={this.handleClick.bind(this)}>
					<i></i>Внесение</button>
				<button className="button icon minus blue" rel={2} onClick={this.handleClick.bind(this)}>
					<i></i>Изъятие</button>
			</div>
		)
	}
}

class CassaTransactionBlock extends Component {
	/**
	 * Сбор данных с формы кассовой операции
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange(value, id) { cassaState.collectCassaTransaction(id, value); }

	/** Закрытие кассовой операции */
	onCancel(event) {
		event.preventDefault();
		cassaState.setCassaTransaction(undefined);
	}
	/** API запрос на проведение кассовой операции */
	onConfirm(event) {
		event.preventDefault();
		var model = this;

		cassaState.ButtonLoading = true;

		getRequest("CassaTransaction", cassaState.getTransactionData(), function(data) {
			cassaState.ErrorCode = data.Success ? "" : data.ErrorCode;
			if (data.Success) {
				cassaState.setCassaTransaction(undefined);
				if (model.props.getData) model.props.getData();
			}
			cassaState.ButtonLoading = false;
		});
	}

	render() {
		var pos = _.find(cassaState.posList, { Id : cassaState.CurrentPOSId }),
			isFiscal = pos ? pos.Fiscal : false,
			transaction = cassaState.CassaTransaction,
			cassaTypes = _.filter(this.props.CassaTransactionTags, { Group : transaction.Group });
		
		return(
			<div className="back">
				<div className="confirmBlock transaction window medium">
					<form onSubmit={this.onConfirm.bind(this)}>
						<h4>{CassaTransactionTypes[transaction.Group]}</h4>
						<div className="clearfix">
							<TextInput
								Id="Amount"
								Title="Сумма"
								Type="number"
								RowClass="col col25"
								Value={transaction.Amount}
								Required={true}
								onChange={this.handleChange.bind(this)}
							/>
							<Selectize
								Id="TagId"
								Title="Тип операции"
								List={toSelectList(cassaTypes)}
								Value={transaction.TagId}
								RowClass="col col40"
								onChange={this.handleChange.bind(this)}
							/>
							{isFiscal && checkRights("ManageFiscalCassa") &&
								<CheckGroup
									Id="Fiscal"
									Type="checkbox"
									RowClass="col col35"
									Value={transaction.Fiscal ? ["true"] : []}
									List={toCheckList([{ Id : "true", Name : "Фискальная операция" }])}
									onChange={this.handleChange.bind(this)}
								/>
							}
							<TextInput
								Id="Description"
								Title="Комментарий"
								Type="textarea"
								RowClass="col col100"
								Value={transaction.Description}
								onChange={this.handleChange.bind(this)}
							/>	
						</div>
						{cassaState.ErrorCode !== "" &&
							<p className="clearfix error">{errors[cassaState.ErrorCode] || errors.DEFAULT}</p>
						}
						<div className="buttons clearfix">
							<SubmitButton Title="Подтвердить" Loading={cassaState.ButtonLoading}/>
							<a href="/" onClick={this.onCancel.bind(this)} className="button gray">Отменить</a>
						</div>
					</form>
				</div>
			</div>
		)
	}
}
CassaTransactionBlock = observer(CassaTransactionBlock);

class CassaTransactionInfoBlock extends Component {
	/** Закрытие информации о кассовой операциия */
	handleClose(event) {
		event.preventDefault();
		cassaState.TransactionInfo = undefined;
	}

	render() {
		var transaction = cassaState.TransactionInfo;

		return(
			<div className="back">
				<div className="confirmBlock transaction info window medium">
					<h4>{CassaTransactionTypes[transaction.Group]} на {getRubs(transaction.Amount, true, true)}</h4>
					<a href="/" className="icon close" onClick={this.handleClose.bind(this)}><i></i></a>
					<div className="clearfix">
						<div className="col col50">
							<h5>Дата</h5>
							<p>{formatDate(transaction.Date, "datetime")}</p>
						</div>
						<div className="col col50">
							<h5>Пользователь</h5>
							<p>{transaction.User}</p>
						</div>
						{transaction.Tag !== "" &&
							<div className="col 100">
								<span className={"tag " + (transaction.Group === 1 ? "green" : "red")}>
									{transaction.Tag}</span>
							</div>
						}
						{transaction.Description !== "" &&
							<div className="col 100">
								<h5>Комментарий</h5>
								<p>{transaction.Description}</p>
							</div>
						}
					</div>
				</div>
			</div>
		)
	}
}
CassaTransactionBlock = observer(CassaTransactionBlock);

class CassaFilter extends Component {
	/**
	 * Сбор данных с формы фильтра 
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange(value, id) { 
		cassaState.collectFilter(id, value); 

		if (/POSId/.test(id) && this.props.getData) {
			this.props.getData();
			this.props.getCassaBalance();
		}
	}
	/** Отправка поискового запроса на сервер */
	handleSubmit (event) {
		event.preventDefault();
		if (this.props.getData) this.props.getData();
	}

	render() {
		var canViewDates = checkRights("CanViewLastMonthCassa") || checkRights("CanViewAllPeriodCassa"),
			cassaGroup = [{ Id : 1, Name : "Внесение" }, { Id : 2, Name : "Изъятие" }],
			cassaTypes = _.filter(this.props.CassaTransactionTags, function(v) {
				return cassaState.Filter.Group === -1 ? true : v.Group === cassaState.Filter.Group
			}),
			minDate = moment().startOf("month").subtract(1, "month");

		return (
			<form className="filter clearfix" onSubmit={this.handleSubmit.bind(this)}>
				<Selectize
					Id="POSId"
					Title="Касса"
					List={toSelectList(cassaState.posList)}
					Value={cassaState.Filter.POSId}
					RowClass={"col " + (uistate.IsMobile ? "col100" : "col20")}
					isClearable={false}
					isSearchable={false}
					onChange={this.handleChange.bind(this)}
				/>
				{canViewDates &&
					<DateInput
						Id="StartShiftDate"
						Title="Дата начала"
						Value={cassaState.Filter.StartShiftDate}
						RowClass={"col " + (uistate.IsMobile ? "col50" : "col10")}
						MaxDate="today"
						MinDate={checkRights("CanViewAllPeriodCassa") ? undefined : minDate}
						IsClearable={false}
						onChange={this.handleChange.bind(this)}
					/>
				}
				{canViewDates &&
					<DateInput
						Id="EndShiftDate"
						Title="Дата окончания"
						Value={cassaState.Filter.EndShiftDate}
						RowClass={"col " + (uistate.IsMobile ? "col50" : "col10")}
						MaxDate="today"
						MinDate={checkRights("CanViewAllPeriodCassa") ? undefined : minDate}
						IsClearable={false}
						onChange={this.handleChange.bind(this)}
					/>
				}
				<Selectize
					Id="Group"
					Title="Вид операции"
					List={toSelectList(cassaGroup)}
					Value={cassaState.Filter.Group}
					RowClass={"col " + (uistate.IsMobile ? "col50" : "col10")}
					isSearchable={false}
					onChange={this.handleChange.bind(this)}
				/>
				<Selectize
					Id="TagId"
					Title="Тип операции"
					List={toSelectList(cassaTypes)}
					Value={cassaState.Filter.TagId}
					RowClass={"col " + (uistate.IsMobile ? "col50" : "col15")}
					onChange={this.handleChange.bind(this)}
				/>
				<CheckGroup
					Id="ShowOrders"
					List={toCheckList([{ Id : "true", Name : "Отображать заказы" }])}
					Value={cassaState.Filter.ShowOrders ? ["true"] : []}
					Type="checkbox"
					RowClass={"col " + (uistate.IsMobile ? "col50 showOrders" : "col15")}
					onChange={this.handleChange.bind(this)}
				/>
				<button className={"button col " + (uistate.IsMobile ? "col45" : "col15")}>Найти</button>
			</form>
		)
	}
}
CassaFilter = observer(CassaFilter);

class CassaMainStat extends Component {
	render() {
		var stat = cassaState.Stat,
			methods = !this.props.CassaOpen || _.isNull(cassaState.Stat.Orders) ? 0 : 
				cassaState.Stat.Orders.length,
			couriers = _.filter(cassaState.CouriersCash, function(v) { return v.Amount > 0; }),
			balance = !this.props.CassaOpen ? cassaState.CassaBalance : cassaState.IsOpen ? 
				stat.ActualAmount : stat.EndAmount;

		return(
			<div className="clearfix stat" cellSpacing="2">
				{!uistate.IsMobile && 
					<div className="clearfix row titles">
						<div className="td dates">Смена</div>
						<div className="td summary">Касса</div>
						<div className="td orders">Заказы</div>
						<div className="td couriers">Курьеры</div>
					</div>
				}
				<div className="row clearfix">
					<div className={"td dates " + (uistate.IsMobile ? "clearfix" : "")}>
						<p>Открыта {formatDate(stat.StartDate, "DD.MM.YYYY HH:mm")}</p>
						{stat.EndDate !== "" && <p>Закрыта {formatDate(stat.EndDate, "DD.MM.YYYY HH:mm")}</p>}
					</div>
					<div className={"td summary " + (uistate.IsMobile ? "clearfix" : "")}>
						<div className="col col20">
							<h5>{uistate.IsMobile ? "Нач. Ост." : "Нач. Остаток"}</h5>
							{this.props.CassaOpen && <p>{getRubs(stat.StartAmount, true, true)}</p>}
						</div>
						<div className="col col20">
							<h5>Выручка</h5>
							{this.props.CassaOpen && <p>{getRubs(stat.Proceeds, true, true)}</p>}
						</div>
						<div className="col col20">
							<h5>Внесения</h5>
							{this.props.CassaOpen && <p>{getRubs(stat.CashIn, true, true)}</p>}
						</div>
						<div className="col col20">
							<h5>Изъятия</h5>
							{this.props.CassaOpen && <p>{getRubs(stat.CashOut, true, true)}</p>}
						</div>
						<div className="col col20">
							<h5>Остаток</h5>
							<p>{getRubs(balance, true, true)}</p>
						</div>
					</div>
					<div className={"td orders " + (uistate.IsMobile ? "clearfix" : "")}>
						{this.props.CassaOpen &&
							<div className="col col30 titles">
								<h5>Всего</h5>
								<h5>Оплачено</h5>
								<h5>Не оплачено</h5>
							</div>
						}
						{this.props.CassaOpen &&
							<div className="col col70">
								{!_.isNull(stat.Orders) && _.map(stat.Orders, function(v,i) {
									return(<div className={"col col1_" + methods} key={i}>
										<h5>{v.PaymentMethod}</h5>
										<p>{getRubs(v.Amount, true, true)} ({v.Count})</p>
										<p>{getRubs(v.Payment, true, true)} ({v.PaymentCount})</p>
										<p>{getRubs(v.Amount - v.Payment, true, true)} ({v.Count - v.PaymentCount})</p>
									</div>)
								})}
							</div>
						}
					</div>
					<div className={"td couriers " + (uistate.IsMobile ? "clearfix" : "")}>
						<h5>На руках у курьеров</h5>
						<p>{getRubs(cassaState.CouriersAmount, true, true)}</p>
						{!_.isEmpty(couriers) &&
							<div className="details clearfix">
								{_.map(couriers, function (item) {
									return(<p className="item clearfix">
										<span className="col col70">{item.Courier}</span>
										<span className="col col30">{getRubs(item.Amount)}</span>
									</p>)
								})}
							</div>
						}
					</div>
				</div>
			</div>
		)
	}
}
CassaMainStat = observer(CassaMainStat);

class CassaSimpleTable extends Component {
	/**
	 * API запрос на удаление кассовой операции
	 * @param {number} id ID операции
	 */
	removeId(id) {
		if (_.isUndefined(id)) return false;

		var model = this,
			item = _.find(this.props.Transactions, { Id : id }),
			itemType = item ? item.Group === 1 ? "внесение" : "изъятие" : "",
			text = <span>Вы действительно хотите удалить <b key="b2">{itemType}</b> на&nbsp;
				<b key="b1">«{item ? getRubs(item.Amount, true, true) : ""}»</b>?</span>;

		confirmState.openConfirm(text, function(){
			getRequest("RemoveCassaTransaction", { TransactionId : id, POSId : cassaState.CurrentPOSId }, function () { 
				model.props.getData(); 
			});
		});
	}

	/**
	 * Отображение подробной информации об операции
	 * @param {number} id 
	 */
	openTransactionInfo(id) {
		cassaState.TransactionInfo = _.find(this.props.Transactions, { Id : id });
	}

	render() {
		var columnsFull = { Date : { Header : "Дата операции", width : 130 }, 
				Group : { Header : "Вид операции", width : 130 },
				Tag : { width : 150 }, User: { width : 150 }, Amount : { width: 80 }, Description : {}},
			columnsMobile = { 
				Date : { Header : "Дата", Cell : row => formatDate(row.original.Date, "HH:mm DD.MM"), width : 90 }, 
				Group : { Header : "Вид операции", width : 110 },
				Amount : {}, View : {} 
			},
			columns = uistate.IsMobile ? columnsMobile : columnsFull;

		if (checkRights("RemoveCassaTransactions") && this.props.Type !== "subcomponent") 
			columns = _.extend(columns, { Remove : {} });

		console.log(this.props.CantHasChild)

		return(
			<div className={"clearfix " + (this.props.Type ? this.props.Type : "")}>
				<TableReportPage
					Type="CassaTransactionTag"
					TableColumns={columns}
					TableData={this.props.Transactions}
					TableFilterable={false}
					IsSortable={false}

					removeRow={this.removeId.bind(this)}
					editRow={this.openTransactionInfo.bind(this)}

					getTrProps={(state, rowInfo, column) => {
						var sub = _.isNull(rowInfo.original.SubTransactions) || 
								_.isUndefined(rowInfo.original.SubTransactions) ? "no-transaction " : "",
							groupsColors = { 1 : "pos", 2 : "neg", 4 : "add", 6 : "add" };
						
						 return { className: sub + (groupsColors[rowInfo.original.Group] ? 
							groupsColors[rowInfo.original.Group] : "") }; 
					}}

					SubComponent={row => {return(_.isNull(row.original.SubTransactions)
						|| _.isUndefined(row.original.SubTransactions) ? undefined : 
							<CassaSimpleTable 
								Transactions={row.original.SubTransactions} 
								Type="subcomponent"
								CantHasChild={true}
							/>); 
					}}
				/>
			</div>
		)
	}
}
CassaSimpleTable = observer(CassaSimpleTable);

class CassaReportTable extends Component {
	render() {
		var columns = { 
				StartDate : { width : 120, Cell : row => (formatDate(row.original.StartDate, "DD.MM.YYYY HH:mm")) }, 
				EndDate : { width : 120, Cell : row => (formatDate(row.original.EndDate, "DD.MM.YYYY HH:mm")) }, 
				StartAmount : {}, Proceeds : {}, CashIn : {}, CashOut : {}, 
				EndAmount : { Cell : row => cassaState.IsOpen && row.original.ShiftDate === authUserState.Shift.Date
					? getRubs(row.original.ActualAmount, true, true) : getRubs(row.original.EndAmount, true, true) }
			};

		_.each(this.props.PaymentMethods, function (method, i) {
			columns = _.extend(columns, { ["Order_" + method.Id] : {
				Header: "Заказы " + method.Name,
				accessor: "Order_" + method.Id + "_Amount",
				show: true,
				setvisible: false,
				Cell : row => <span className="block">
					<span>{getRubs(row.original["Order_" + method.Id + "_Amount"], true, true)}</span>
					<span>{getRubs(row.original["Order_" + method.Id + "_NonPayment"], true, true)}</span>
				</span>
			} });
		});

		return(
			<TableReportPage
				TableColumns={columns}
				TableData={cassaState.Dates}
				TableFilterable={false}
				IsSortable={false}
				
				getTrProps={(state, rowInfo, column) => { return { className: "days " +
					(_.isNull(rowInfo.original.Transactions) || _.isEmpty(rowInfo.original.Transactions) 
					? "no-transaction" : "")  }; }}

				SubComponent={row => {return( _.isNull(row.original.Transactions) 
					|| _.isEmpty(row.original.Transactions) ? undefined : 
					<CassaSimpleTable Transactions={row.original.Transactions} Type="subcomponent" />); 
				}}
			/>
		)
	}
}
CassaReportTable = observer(CassaReportTable);

export default CassaPage;