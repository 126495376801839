import { observable, decorate, computed } from 'mobx';
import _ from 'lodash';


class MenuState {
	MenuGroup = 1;
	IsSendRequest = false;
	// Sections = [];

	CurrentSection = -1;
	AllProducts = [];
	Product = undefined;
	Filter = "";
	ProductCount = 1;

	/** 
	 * Сброс настреок страницы 
	 * @param {number} group Группа меню: 1 — кухня, 2 — бар
	 */
	setDefault(group) {
		this.MenuGroup = group;
		this.AllSections = [];
		this.CurrentSection = -1;
		this.AllProducts = [];
		this.Product = undefined;
		this.ProductCount = 1;
	}

	/** 
	 * Разбор ответа от сервера с разделами
	 * @param {[object]} sections список с разделами
	 */
	setSections(sections) {
		this.AllSections = _.isUndefined(sections) || _.isNull(sections) ? [] : sections;

		var hasSemi = !_.isEmpty(_.filter(this.AllSections, { Type : 2 }));
		this.CurrentSection = _.isEmpty(this.AllSections) ? -1 : hasSemi ? -1 : 0;
		this.IsSendRequest = true;
	}

	/** Сортировка разделов по скрытым разделам и соответствию по типам */
	get Sections() {
		if (_.isUndefined(this.AllSections) || _.isEmpty(this.AllSections)) return []

		var allSemi = { Id: -1, Order: -1, Type: 2, Name: "Все полуфабрикаты" },
			allProd = { Id: 0, Order: -1, Type: 3, Name: "Вся продукция" },
			allSections = _.filter(this.AllSections, function (v) { return v.Type !== 1 && !v.IsHidden && !v.System; });

		if (!_.isEmpty(_.filter(allSections, { Type: 2 }))) allSections = _.concat(allSections, allSemi);
		if (!_.isEmpty(_.filter(allSections, { Type: 3 }))) allSections = _.concat(allSections, allProd);

		return _.sortBy(allSections, ["Type", "Order"]);
	}

	/**
	 * Установка выбранного типа товара по активному разделу
	 * @return {number} тип товара (ПФ, продукция)
	 */
	get CurrentType() {
		return _.isEmpty(this.Sections) ? -1 : _.find(this.Sections, { Id: this.CurrentSection }) ?
			_.find(this.Sections, { Id: this.CurrentSection }).Type : -1;
	}

	/**
	 * Разбор ответа от сервера с продуктами
	 * @param {[object]} products список продукции
	 */
	setProducts(products) {
		this.AllProducts = _.isUndefined(products) || _.isNull(products) ? [] : products;
		this.IsSendRequest = true;
	}

	/** Фильтруем продукты, которые находятся в неоступных разделах */
	get SectionProducts() {
		if (_.isEmpty(this.Sections) || _.isEmpty(this.AllProducts)) return [];
		
		var sections = _.map(this.Sections, "Id");
		return _.filter(this.AllProducts, function (v) { return _.indexOf(sections, v.SectionId) !== -1; });
	}

	/**
	 * Фильтрация списка продуктов по выбранному разделу и строке поиска
	 * @return {[object]} список отфильтрованный по разделу продуктов
	 */
	get Products() {
		var isAll = this.CurrentSection === -1 || this.CurrentSection === 0,
			products = isAll ? _.filter(this.SectionProducts, { Type: this.CurrentType })
				: _.filter(this.SectionProducts, { SectionId: this.CurrentSection }),
			filter = _.toLower(this.Filter);

		products = _.filter(products, function (v) {
			var name = _.toLower(v.Name);
			return filter === "" ? true : name.indexOf(filter) !== -1;
		});

		return _.sortBy(products, "Name");
	}

	/**
	 * Разбор ответа от сервера с техникой приготовления конкретного продукта
	 * @param {object} product информация о выбранном продукте
	 */
	setProduct(product) {
		this.Product = product;
		this.ProductCount = 1;
	}

	/**
	 * Подсчет кол-ва продукта в составе в зависомсти от необходимо кол-ва на производство
	 * @return {[object]} Состав продукта
	 */
	get composition() {
		if (_.isUndefined(this.Product) || _.isNull(this.Product.Composition) || this.ProductCount === "")
			return [];

		var count = this.ProductCount;

		return _.map(_.clone(this.Product.Composition), function (v) {
			return _.extend(_.clone(v), { Count: _.clone(v.Count) * count, SourceTrash: _.clone(v.SourceTrash) * count });
		});
	}

	/**
	 * Открытие/закрытие изображения в сплывающем окне
	 * @param {string} photoURL адрес изображения
	 */
	setProductPhoto(photoURL) {
		this.ProductPhoto = photoURL && photoURL !== "" ? photoURL : undefined;
	}
}
decorate(MenuState, {
	MenuGroup: observable,
	CurrentSection: observable,
	Product: observable,
	IsSendRequest: observable,
	AllProducts: observable,
	AllSections: observable,
	Filter: observable,
	ProductCount: observable,
	ProductPhoto: observable,

	Products: computed,
	CurrentType: computed,
	Sections: computed
});
export const menuState = new MenuState();