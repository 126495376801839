/**
 * /pages/Accpunt.js – Работа со личной информацией пользователя
 * /stores/account.js – бизнес логика
 * 
 * Отображается информация по авторизованному пользователю
 * 
 * Для получения информации о пользователе необходимо сделать запрос «Account»
 * Для изменения настроек пользователя необходимо сделать запрос «EditAccount»
 * 
 * Если пользователь является получателем чаевых
 * Для получения информации о лимитах карты необходимо отправить запрос «CardLimits»
 * Для привязки номера телефона необходимо отправить запрос «ConfirmPhone», в результате запроса будет перенаправление на страницу ПШ
 * Для получения комиссии по выводу денег на карту необходимо отправить запрос «PayOutFee» 
 * Для вывода денег на карту отправляется запрос «PayOut», в результате запроса будет перенаправление на страницу ПШ
 */

import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import BestExecutorImage from '../images/bestCheckList.svg';
import EmptyCheckListImage from '../images/emptyCheckList.svg';

import { TextInput, FileInput, SubmitButton } from '../forms/FormItems';

import { errors, TipsActiveTitles } from '../dictionary';
import { getRequest, getRubs, formatDate, parseCardMask, getUpload } from './../functions';

import { uistate, authUserState, errorState } from '../stores/common';
import { accountState } from '../stores/account';
import moment from 'moment';
import { AddDocumentsIcon, AddPhoneIcon, ArrowIcon, CardIcon, EditIcon, LinkIcon, MoneyOutIcon, OpenLinkIcon } from '../images/icons';

export class AccountPage extends Component {
	constructor(props) {
		super(props);

		accountState.setDefault();

		this.getAccount();
		if (authUserState.HasCheckList);
			this.getStat();

		if (authUserState.HasTips)
			this.getLimits();
	}

	/** API запрос «Account» на получение данных о профиле пользователя */
	getAccount() { getRequest("Account", accountState.Dates, function(data) { accountState.setAccount(data); }); }

	/** API запрос «Account» на получение данных о профиле пользователя */
	getLimits() {
		getRequest("CardLimit", {}, function(data) { accountState.Limits = data.Success ? data : undefined; });
	}

	/** API запросы для получения рейтингов
	 * CheckListRateUsers — получение рейтинга пользователей
	 * CheckListRateTasks — получение рейтинга задач
	 */
	getStat () {
		getRequest("CheckListRateUsers", accountState.RateFilter, (data) => {
			accountState.UsersRate = data.Success && !_.isUndefined(data.Users) ? data : [];
		});

		getRequest("CheckListRateTasks", accountState.RateFilter, (data) => {
			accountState.TasksRate = data.Success && !_.isUndefined(data.Tasks) && !_.isEmpty(data.Tasks) ?
				data.Tasks : [];
		});
	}

	/** API запрос «EditAccount» на сохранение данных пользователя */
	handleSubmit(event) {
		event.preventDefault();

		accountState.ButtonLoading = true;

		getUpload("EditAccount", accountState.getSaveData(), function (data) {
			accountState.ErrorCode = data.Success ? "SUCCESS_SAVE" : data.ErrorCode;
			accountState.SuccessSave = data.Success;
			accountState.ButtonLoading = false;
		});
	}

	render() {
		return (
			<div className={"page " + (uistate.IsMobile ? "mobile" : "")} id="AccountPage">
				<section className="item">
					<h2>Личный кабинет</h2>
					<UserInfo />
					{authUserState.HasCheckList &&
						<CheckListInfo />
					}
					{!_.isUndefined(accountState.Recipient) && authUserState.HasTips &&
						<RecipientBlock getData={this.getAccount.bind(this)} />
					}
					{!_.isUndefined(accountState.Recipient) && authUserState.HasTips &&
						<TransactionsBlock getData={this.getAccount.bind(this)} />
					}
					{!_.isUndefined(accountState.ConfirmURL) &&
						<ConfirmPhoneBlock getData={this.getAccount.bind(this)} />
					}
					{!_.isUndefined(accountState.PayOutData) &&
						<PayOutBlock getData={this.getAccount.bind(this)} />
					} 
					{!_.isUndefined(accountState.CardURL) &&
						<CardInfoBlock getData={this.getAccount.bind(this)} />
					}
					{!_.isUndefined(accountState.ChangePassword) &&
						<ChangePasswordBlock getData={this.getAccount.bind(this)} />
					}
					{accountState.ChangePhoto &&
						<ChangePhotoBlock getData={this.getAccount.bind(this)} />

					}
				</section>
			</div>
		)
	}
}
AccountPage = observer(AccountPage);

class UserInfo extends Component {
	/** Открытие окна смены пароля */
	handlePass(event) {
		event.preventDefault();
		accountState.setChangePassword(true);
	}

	/** Открытие окна смены фото */
	handlePhoto(event) {
		event.preventDefault();
		accountState.ChangePhoto = true;
	}

	render() {
		return (
			<div className='section userinfo'>
				<div className='photo' style={{ backgroundImage: "url(" + accountState.Account.PhotoURL + ")" }}></div>
				<a href="/" className='iconSvg edit' title='Редактировать фото' onClick={this.handlePhoto.bind(this)}><EditIcon /></a>
				<h4>{accountState.Account.Name}</h4>
				<p className='phone'>{accountState.Account.Phone}</p>
				<div className='merchants'>
					{_.map(accountState.Account.Merchants, (merchant, i) => {
						return(
							<div key={i}>
								<h5>{merchant.Name}</h5>
								<p><b>Должность:</b> {merchant.Roles}</p>
								<p><b>Филиалы:</b> {merchant.Filials}</p>
							</div >
						)
					})}
				</div>
				<a href="/" className="button" onClick={this.handlePass.bind(this)}>Сменить пароль</a>
			</div>
		)
	}
}
UserInfo = observer(UserInfo);

class ChangePhotoBlock extends Component {
	/** Закрытие окна */
	handleClose(event) {
		event.preventDefault();
		accountState.ChangePhoto = false;
	}

	/** Закрытие окна */
	handleBackClose(event) {
		if (event.target.getAttribute("rel") === "back") accountState.ChangePhoto = false;
	}

	/** API запрос «EditAccount» на сохранение данных пользователя */
	handleSubmit(event) {
		event.preventDefault();

		accountState.ButtonLoading = true;

		getUpload("EditAccount", accountState.getSaveData(), (data) => {
			accountState.ErrorCode = data.Success ? "SUCCESS_SAVE" : data.ErrorCode;
			accountState.ButtonLoading = false;
			if (data.Success) accountState.ChangePhoto = false;
		});
	}

	render() {
		return(
			<div className="back" rel="back">
				<div className="confirmBlock clearfix window" id="ChangePhoto">
					<p>Изменение фото</p>
					<form className="buttons clearfix" onSubmit={this.handleSubmit.bind(this)}>
						<FileInput
							Id="Photo"
							Title="Фотография"
							File={accountState.Account.PhotoURL}
							RowClass="nomargin"
							MaxSize={1024000}
							onChange={accountState.collectUser.bind(accountState)}
						/>
						{accountState.ErrorCode !== "" &&
							<p className="error">{errors[accountState.ErrorCode] || errors.DEFAULT}</p>
						}
						<div className="clearfix buttons">
							<SubmitButton Loading={accountState.ButtonLoading} Title="Подтвердить" />
							<a href="/" role="button" onClick={this.handleClose.bind(this)} className="button gray">Отменить</a>
						</div>
					</form>
				</div>
			</div>
		)
	}
}

class CheckListInfo extends Component {
	render() {
		return(
			<div className="section checklist">
				<h4>Выполнение задач</h4>
				<CheckListRating />
				{!_.isEmpty(accountState.TasksRate) && _.isEmpty(accountState.TaskRating) &&
					<img src={BestExecutorImage} alt="Лучший среди всех" />
				}
				{!_.isEmpty(accountState.TasksRate) && !_.isEmpty(accountState.TaskRating) &&
					<>
						<h5>Задачи, которые вызывают у вас проблемы</h5>
						<div className='table'>
							<h5>Задача</h5>
							<h5>Чек лист</h5>
							<h5>Не выполнена</h5>
							<h5>Выполнена не во время</h5>
							{_.map(accountState.TaskRating, function (item,i) {
								return(<CheckListItem Item={item} key={i} />)
							})}
						</div>
					</>
				}
			</div>
		)
	}
}
CheckListInfo = observer(CheckListInfo);

class CheckListRating extends Component {
	render() {
		var last = accountState.UserRating.last,
			middle = accountState.UserRating.middle,
			first = accountState.UserRating.first;

		if (_.isUndefined(accountState.CurrentUserRating))
			return(
				<>
					<img src={EmptyCheckListImage} alt="Нет данных для рейтинга" />
					<p>Начните выполнять задачи, чтобы появится в рейтинге</p>
				</>
			)

		return(
			<>
				<div className='rate'>
					{!_.isUndefined(last) &&
						<div className='last' title={last.Rate + " место"} style={{ backgroundImage : "url(" + last.Photo + ")"}}>
							{!last.Photo && last.Photo !== "" && (last.FIO || last.Rate)}
						</div>
					}
					{!_.isUndefined(middle) &&
						<div className='middle' style={{ left: middle.Position, backgroundImage : "url(" + last.Photo + ")" }} title={middle.Rate + " место"} >
							{!middle.Photo && last.Photo !== "" && middle.FIO}</div>
					}
					{!_.isUndefined(first) &&
						<div className='first' title='Первое место' style={{ backgroundImage : "url(" + first.Photo + ")"}}>
							{!first.Photo && first.FIO}
						</div>
					}
				</div>
				<p>{accountState.MotivationTitle}</p>
			</>
		)
	}
}
CheckListRating = observer(CheckListRating);

class CheckListItem extends Component {
	render() {
		return(
			<>
				<span>{this.props.Item.Name}</span>
				<span>{this.props.Item.CheckList}</span>
				<span>{this.props.Item.Failed} из {this.props.Item.Total}</span>
				<span>{this.props.Item.Expired} из {this.props.Item.TotalDone}</span>
			</>
		)
	}
}

class ChangePasswordBlock extends Component {
	/** Закрытие окна смены пароля */
	handleClose(event) {
		event.preventDefault();
		accountState.setChangePassword(false);
	}

	/** Закрытие окна вывода денег */
	handleBackClose(event) {
		if (event.target.getAttribute("rel") === "back") accountState.setChangePassword(false);
	}

	/** API запрос «changePassword» для смены пароля пользователя */
	handleSubmit (event) {
		event.preventDefault();

		if (!accountState.validatePass()) return false;

		accountState.ButtonLoading = true;

		var model = this;
		getUpload("ChangePassword", accountState.getPassData(), function(data) {
			if (data.Success) model.props.getData();
			else accountState.ErrorCode = data.ErrorCode;
			accountState.ButtonLoading = false;
		});
	}

	render() {
		var data = accountState.ChangePassword;

		return(
			<div className="back" rel="back">
				<div className="confirmBlock clearfix window" id="ChangePass">
					<p>Изменение пароля</p>
					<form className="buttons clearfix" onSubmit={this.handleSubmit.bind(this)}>
						<TextInput
							Id="OldPassword"
							Title="Старый пароль"
							Value={data.OldPassword}
							Required={true}
							onChange={accountState.collectPass.bind(accountState)}
						/>
						<TextInput
							Id="NewPassword"
							Title="Новый пароль"
							Value={data.NewPassword}
							Required={true}
							onChange={accountState.collectPass.bind(accountState)}
						/>
						<TextInput
							Id="RepeatNewPassword"
							Title="Повтор пароля"
							Value={data.RepeatNewPassword}
							Required={true}
							onChange={accountState.collectPass.bind(accountState)}
						/>
						{accountState.ErrorCode !== "" &&
							<p className="error">{errors[accountState.ErrorCode] || errors.DEFAULT}</p>
						}
						<div className="clearfix buttons">
							<SubmitButton Loading={accountState.ButtonLoading} Title="Подтвердить" />
							<a href="/" role="button" onClick={this.handleClose.bind(this)} className="button gray">Отменить</a>
						</div>
					</form>
				</div>
			</div>
		)
	}
}
ChangePasswordBlock = observer(ChangePasswordBlock);

class RecipientBlock extends Component {
	/** Скопировать сслыку получателя в буфер */
	copyLink(event) {
		event.preventDefault();
		var link = event.currentTarget.getAttribute("href");
		if (navigator.clipboard) navigator.clipboard.writeText(link);
	}

	/** API запрос «ConfirmPhone» для открытия окна подтверждения номера телефона или паспорта */
	confirmPhone(event) { 
		event.preventDefault();
		var type = parseInt(event.currentTarget.getAttribute("rel"), 10);

		getRequest("ConfirmPhone", { Type : !_.isNaN(type) ? type : 1 }, (data) => {
			if (data.Success) accountState.ConfirmURL = data.RedirectURL;
		});
	}

	/** API запрос «GetCard» для открытия окна подтверждения номера телефона */
	getCard(event) { 
		event.preventDefault();

		getRequest("GetCard", {}, (data) => { if (data.Success) accountState.CardURL = data.RedirectURL; });
	}

	/** Открытие окна вывода денег на карту */
	handlePayOut(event) {
		event.preventDefault();
		accountState.PayOutData = { Amount : "", Comission : 0, PayOutURL : undefined, ErrorCode : "" }
	}

	/** API запрос «EditAccount» на сохранение данных пользователя */
	handleSubmit(event) {
		event.preventDefault();

		getUpload("EditAccount", accountState.getSaveData(), (data) => {
			if (!data.Success) errorState.setError(data.ErrorCode, this.props.getData);
			else this.props.getData();
		});
	}

	render() {
		return(
			<div className='section tips form'>
				{accountState.UserCode !== "" &&
					<div className='code'>
						<TextInput
							Title="Код пользователя"
							Value={accountState.UserCode}
							Disabled={true}
						/>
						<a href={accountState.PaymentLink} className="iconSvg" onClick={this.copyLink.bind(this)}
							title="Скопировать ссылку для получения чаевых"><LinkIcon/></a>
						<a href={accountState.PaymentLink} target="_blank" rel="noopener noreferrer" title="Открыть ссылку для получения чаевых"><OpenLinkIcon/></a>
					</div>
				}
				<div className='card'>
					<p className="amount">
						<b>{getRubs(accountState.Recipient.Balance, true)} ₽</b>
						{accountState.CanPayOut &&
			 					<a href="/" className="iconSvg" onClick={this.handlePayOut.bind(this)} title="Вывести деньги на карту"><MoneyOutIcon /></a>
						}
					</p>
					{!_.isUndefined(accountState.Limits) &&
						<div className='amountLimit'>
							<div className='balance' style={{ width : (accountState.Recipient.Balance*100/accountState.Limits.MaxBalance) + "%" }}></div>
							<p>МАХ лимит</p>
							<p>{getRubs(accountState.Limits.MaxBalance, true, true)}</p>
						</div>
					}
					<div className='cardNumber dark'>
						<TextInput
							Value={parseCardMask(accountState.Recipient.PANCard)}
							Disabled={true}
						/>
						<TextInput
							Value="**/**"
							Disabled={true}
						/>
						<TextInput
							Value="***"
							Disabled={true}
						/>
						{accountState.CanGetCard &&
							<a href="/" className='iconSvg' onClick={this.getCard.bind(this)} title="Посмотреть данные карты"><CardIcon/></a>
						}
					</div>
				</div>
				<div className='status'>
						<p>{TipsActiveTitles[accountState.Recipient.TipsActive]}</p>
						<div>
							{accountState.CanConfirmPhone &&
								<a href="/"  className="iconSvg" onClick={this.confirmPhone.bind(this)} rel="1" title={(accountState.Recipient.TipsActive === 0 ? "Привязать" : "Изменить") + " номер телефона"}>
									<AddPhoneIcon /></a>
							}
							{accountState.CanConfirmPassport &&
								<a href="/" onClick={this.confirmPhone.bind(this)} rel="2" title="Подтвердить личность по паспорту"><AddDocumentsIcon /></a>
							}
						</div>
				</div>
				<div className='info'>
					<TextInput
						Id="FIO"
						Title="Имя для страницы чаевых"
						Value={accountState.Recipient.FIO}
						Required={true}
						onChange={accountState.collectRecipient.bind(accountState)}
					/>
					<TextInput
						Id="SavingUpFor"
						Title="Коплю на..."
						Type="textarea"
						Value={accountState.Recipient.SavingUpFor}
						onChange={accountState.collectRecipient.bind(accountState)}
					/>
					<button onClick={this.handleSubmit.bind(this)}>Сохранить изменения</button>
				</div>
			</div>
			
		)
	}
}
RecipientBlock = observer(RecipientBlock);

class TransactionsBlock extends Component {
	/** Изменение периода транзакций */
	changeDates (event) {
		event.preventDefault();
		var type = event.currentTarget.getAttribute("rel"),
			from = accountState.Dates.DateFrom,
			to = accountState.Dates.DateTo;
		
		if (type === "prev") {
			from = formatDate(moment(from).subtract(31, "days"), "r");
			to = formatDate(moment(to).subtract(31, "days"), "r");
		} else {
			from = formatDate(moment(from).add(31, "days"), "r");
			to = formatDate(moment(to).add(31, "days"), "r");
		}

		accountState.Dates = { DateFrom : from, DateTo : to };
		this.props.getData();
	}

	render() {
		var dates = _.clone(accountState.Dates),
			dateTo = moment(dates.DateTo).subtract(1, "days");

		return(
			<div className="section transactions">
				<div className="head">
					<a href="/" rel="prev" className="iconSvg" onClick={this.changeDates.bind(this)}><ArrowIcon /></a>
					<span>{formatDate(dates.DateFrom)} – {formatDate(dateTo)}</span>
					<a href="/" rel="next" className="iconSvg" onClick={this.changeDates.bind(this)}><ArrowIcon /></a>
				</div>
				<div className='table'>
					<h5>Сумма</h5>
					<h5>Дата</h5>
					<h5>Описание</h5>
					{_.map(accountState.Transactions, (item, i) => {
						var char = item.Type === 1 ? "+ " : "- ";
						
						return(
							<>
								<span>{char}{getRubs(item.Amount)}</span>
								<span>{formatDate(item.Date, "datetime")}</span>
								<span>{item.Type === 1 ? "Пополнение" : "Списание"}</span>
							</>
						)
					})}

				</div>
			</div>
		)
	}
}
TransactionsBlock = observer(TransactionsBlock);

class ConfirmPhoneBlock extends Component {
	/** Закрытие окна Подтверждения телефона */
	handleClose(event) {
		event.preventDefault();
		accountState.ConfirmURL = undefined;
		if (this.props.getData) this.props.getData();
	}

	/** Закрытие окна вывода денег */
	handleBackClose(event) {
		if (event.target.getAttribute("rel") === "back") {
			accountState.ConfirmURL = undefined;
			if (this.props.getData) this.props.getData();
		}
	}

	render() {
		return(
			<div className="back" rel="back" onClick={this.handleBackClose.bind(this)}>
				<div className="confirmBlock clearfix window medium" id="ConfirmPhone">
					{/* <h5>Подтверждение номера телефона</h5> */}
					<a href="/" className="icon close" onClick={this.handleClose.bind(this)}><i></i></a>
					<iframe src={accountState.ConfirmURL} title="Подтверждение номера телефона"></iframe>

					<p className="comment">Подтверждение данных может занять некоторое время. Следите за своим статусом в личном кабинете.</p>
				</div>
			</div>
		)
	}
}
ConfirmPhoneBlock = observer(ConfirmPhoneBlock);

class CardInfoBlock extends Component {
	/** Закрытие окна Подтверждения телефона */
	handleClose(event) {
		event.preventDefault();
		accountState.CardURL = undefined;
	}

	/** Закрытие окна вывода денег */
	handleBackClose(event) {
		if (event.target.getAttribute("rel") === "back") accountState.CardURL = undefined;
	}

	render() {
		return(
			<div className="back" rel="back" onClick={this.handleBackClose.bind(this)}>
				<div className="confirmBlock clearfix window medium" id="ConfirmPhone">
					{/* <h5>Подтверждение номера телефона</h5> */}
					<a href="/" className="icon close" onClick={this.handleClose.bind(this)}><i></i></a>
					<iframe src={accountState.CardURL} title="Просмотр полной информации о виртуальной карте"></iframe>
				</div>
			</div>
		)
	}
}
CardInfoBlock = observer(CardInfoBlock);

class PayOutBlock extends Component {
	/** Закрытие окна вывода денег */
	handleClose(event) {
		event.preventDefault();
		accountState.PayOutData = undefined;
		if (this.props.getData) this.props.getData();
	}

	/** Закрытие окна вывода денег */
	handleBackClose(event) {
		if (event.target.getAttribute("rel") === "back") accountState.PayOutData = undefined;
		if (this.props.getData) this.props.getData();
	}

	/**
	 * Сбор данных с формы вывода денег
	 * @param {number} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange(value, id) {
		if (/Amount/.test(id)) {
			accountState.PayOutData.Amount = value;
			if (value && value !== 0 && value !== "") this.payOutFee(value*100);
		}
	}

	/** API запрос «PayOutFee» для расчета комисии за вывод денег */
	payOutFee (amount) {
		getRequest("PayOutFee", { Amount : amount }, function(data) {
			accountState.PayOutData.Comission = data.Success ? data.Fee : 0;
		});
	}

	/** API запрос «PayOut» для вывода денег на карту */
	payOut (event) {
		event.preventDefault();
		
		if (!accountState.validateAmount()) return false;

		var params = {
			Amount : accountState.PayOutData.Amount*100,
			Fee : accountState.PayOutData.Comission
		}
		getRequest("PayOut", params, function(data) {
			accountState.PayOutData.PayOutURL = data.Success ? data.RedirectURL : undefined;
			accountState.PayOutData.ErrorCode = data.Success ? "" : data.ErrorCode === "WRONG_PAYOUT_AMOUNT" ? 
				"WRONG_PAYOUT_AMOUNT" : "PAYOUT_ERROR";
		});
	}

	render() {
		var data = accountState.PayOutData,
			buttonAmount = data.Amount*100 + data.Comission;

		return(
			<div className="back" rel="back" onClick={this.handleBackClose.bind(this)}>
				<div className="confirmBlock clearfix window medium" id="PayOut">
					<a href="/" className="icon close" onClick={this.handleClose.bind(this)}><i></i></a>
					{_.isUndefined(data.PayOutURL) &&
						<div className="clearfix">
							<h5>Вывод средств на карту</h5>
							<form onSubmit={this.payOut.bind(this)}>
								<TextInput
									Id="Amount"
									Title="Введите сумму для перевода"
									Value={data.Amount}
									Step={1}
									Type="number"
									onChange={this.handleChange.bind(this)}
								/>
								{data.Comission !== 0 &&
									<p className="comment">Комиссия за перевод составит <b>{getRubs(data.Comission)} руб.</b></p>
								}
								{data.ErrorCode !== "" &&
									<p className="error">{errors[data.ErrorCode] || errors.DEFAULT}</p>
								}
								<SubmitButton Title={"Оплатить " + getRubs(buttonAmount) + " руб."} 
									Disabled={data.Amount === 0 || data.Amount === ""} />
							</form>
						</div>
					}
					{!_.isUndefined(data.PayOutURL) && 
						<iframe src={data.PayOutURL} title="Вывод средств на карту"></iframe>
					}
				</div>
			</div>
		)
	}
}
PayOutBlock = observer(PayOutBlock);