import React, { Component } from 'react';
import { observer } from 'mobx-react';
import pageLoader from '../images/loader_big.svg';

import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/ru';

import { toSelectList, getRubs, getRequest, formatDate } from '../functions';
import { buttonTitles, VATTitles, dashboardsTitles, daysShort } from '../dictionary';

import { ItemHeader, TableReportPage } from './PageTemplates';
import { Selectize, TextInput, DateInput, SubmitButton } from '../forms/FormItems';

import { uistate, authUserState, confirmState } from '../stores/common';
import { invoiceState, paymentsState } from '../stores/invoices';

export class InvoicePage extends Component {
	constructor(props) {
		super(props);
		
		invoiceState.setDefault();

		this.state = { isOpen : false };

		this.getData = this.getData.bind(this);
		this.getData();

		getRequest("Stocks", {}, function(data) { 
			invoiceState.Stocks = data.Success && !_.isNull(data.Stocks) ? data.Stocks : [];
		});
		getRequest("Providers", { Active : true }, function(data) { 
			invoiceState.Providers = data.Success && !_.isNull(data.Providers) ? data.Providers : [];
		});
		getRequest("ShopLists", { Status : 2 }, function(data) { 
			invoiceState.ShopLists = data.Success && !_.isNull(data.ShopLists) ? data.ShopLists : [];
		});
	}

	/** API запрос «Invoices» на получение списка накладных */
	getData() {
		invoiceState.IsSendRequest = false;
		getRequest("Invoices", invoiceState.getFilterData(), function(data) { invoiceState.setInvoices(data); });
	}

	/**
	 * API запрос «Invoice» на получение накладной
	 * @param {number} id ID накладной
	 */
	getItem(id) {
		getRequest("Invoice", { Id : id }, function (data) {
			invoiceState.setInvoice(data.Success ? data : undefined);
		});
	}

	/**
	 * API запрос «PaymentInvoice» для оплаты накладной
	 * @param {number} id ID накладной
	 */
	paymentInvoice(id) {
		if (_.isUndefined(id)) return false;

		var model = this,
			item = _.find(invoiceState.Invoices, { Id : id }),
			types = { 1 : "№", 2 : "ТН", 3 : "СФ" },
			type = item ? types[item.InvoiceType] : "",
			text = <span>Вы действительно хотите оплатить накладную&nbsp;
				<b key="b1">«{type} {item.InvoiceNumber || ""}»</b>?</span>;

		confirmState.openConfirm(text, function () {
			getRequest("PaymentInvoice", { Id : id }, function (data) { model.getData(); });
		});
	}

	setId(id) {
		if (!_.isUndefined(id)) {
			if (id !== -1) this.getItem(id)
			else invoiceState.setInvoice({ Id: -1 });
		} else {
			invoiceState.setInvoice(undefined);
			this.getData();
		}
	}

	toggleFilter (event) {
		event.preventDefault();
		this.setState({ isOpen : !this.state.isOpen });
	}

	handleChange(value, id) { invoiceState.collectFilter(id, value); }

	handleSubmit(event) {
		event.preventDefault();
		invoiceState.Filter.Offset = 0;
		this.getData();
	}

	changePage (offset) { 
		invoiceState.Filter.Offset = offset;
		this.getData();
	}

	removeId(id) {
		if (_.isUndefined(id)) return false;

		var model = this,
			item = _.find(invoiceState.Invoices, { Id : id }),
			type = item ? invoiceState.invoiceTypes[item.InvoiceType] : "",
			text = <span>Вы действительно хотите удалить накладную&nbsp;
				<b key="b1">«{type} {item.InvoiceNumber || ""}»</b>?</span>;

		confirmState.openConfirm(text, function() {
			getRequest("RemoveInvoice", { Id : id }, function (data) { model.getData(); });
		});
	}

	render() {
		var paymentTypes = invoiceState.paymentTypes,
			columns = { 
				Date: { filterable : false, Header: "Дата", width : 85, Cell: row => formatDate(row.original.Date) }, 
				InvoiceNumber : { },
				Provider : {
					Cell: row => <span title={row.original.Provider}>{row.original.Provider}</span>
				}, 
				User: {}, Stock: {}, Amount : {}, 
				PaymentType : {
					Cell: function(row) {
						var type = _.find(paymentTypes, { Id : row.original.PaymentType });

						if (type) return <span title={type.Name} className={"icon type_" + type.Id}></span>;
						else return "";
					}
				},
				DeadlinePayment: { width: 85, Cell: row => row.original.Status !== 2 ? "" : formatDate(row.original.DeadlinePayment) },
				Status: { filterable : false, width: 85, 
					Cell: row => row.original.StatusName
				}, 
			};

		if (invoiceState.CanManage) _.extend(columns, { Edit : {}, View : {}, Remove : {} });
		if (invoiceState.CanPayment) _.extend(columns, { View : {}, Pay : {} });
		
		return (
			<div className="page" id="InvoicePage">
				{_.isUndefined(invoiceState.Invoice) &&
					<div className="head">
						<h2>Накладные</h2>
						<a href="/" className={"toggleFilter " + (this.state.isOpen ? "active" : "")} 
							title="Фильтр поиска" onClick={this.toggleFilter.bind(this)}><i></i></a>
						{invoiceState.CanManage &&
							<button className="add blue" onClick={(e) => { this.setId(-1) }}>
								{buttonTitles[this.props.Type] || "Добавить"}</button>
						}
					</div>
				}
				{this.state.isOpen && _.isUndefined(invoiceState.Invoice) &&
					<InvoiceFilter handleSubmit={this.handleSubmit.bind(this)} />
				}
				{!_.isEmpty(invoiceState.Invoices) && _.isUndefined(invoiceState.Invoice) &&
					<TableReportPage
						TableData={invoiceState.Invoices}
						Type="Invoices"
						TableColumns={columns}
						FilterHeight={this.state.isOpen ? 125 : 0}
						Pagination={true}
						Limit={invoiceState.Limit}
						HasMore={invoiceState.HasMore}
						Offset={invoiceState.Filter.Offset}
						
						changePage={this.changePage.bind(this)}
						editRow={this.setId.bind(this)}
						payRow={this.paymentInvoice.bind(this)}
						removeRow={this.removeId.bind(this)}

						getTrProps={(state, rowInfo, column) => {
							return { className : rowInfo.original.StatusColor }
						}}
					/>
				}
				{invoiceState.IsSendRequest && _.isEmpty(invoiceState.Invoices) &&
					<p>По вашему запросу ничего не найдено</p>
				}

				{!_.isUndefined(invoiceState.Invoice) &&
					<InvoiceItem
						setId={this.setId.bind(this)}
						getItem={this.getItem.bind(this)}
					/>
				}
			</div>
		)
	}
}
InvoicePage = observer(InvoicePage);


class InvoiceFilter extends Component {
	render() {
		return(
			<form onSubmit={this.props.handleSubmit} className="filters clearfix">
				<div className="col col80">
					<Selectize 
						Id="ProviderId"
						Title="Поставщик"
						List={toSelectList(invoiceState.Providers)}
						Value={invoiceState.Filter.ProviderId}
						onChange={invoiceState.collectFilter.bind(invoiceState)}
					/>
					<Selectize 
						Id="StockId"
						Title="Склад"
						List={toSelectList(invoiceState.Stocks)}
						Value={invoiceState.Filter.StockId}
						onChange={invoiceState.collectFilter.bind(invoiceState)}
					/>
					<Selectize 
						Id="Status"
						Title="Статус"
						List={toSelectList(invoiceState.invoiceStatuses)}
						Value={invoiceState.Filter.Status}
						onChange={invoiceState.collectFilter.bind(invoiceState)}
					/>
					<DateInput 
						Id="Date"
						Title="Дата создания накладной"
						Value={invoiceState.Filter.Date}
						onChange={invoiceState.collectFilter.bind(invoiceState)}
					/>
				</div>
				<div className="buttons class col col20"><button>Найти</button></div>
			</form>
		)
	}
}
InvoiceFilter = observer(InvoiceFilter);

class InvoiceItem extends Component {
	constructor(props) {
		super(props);

		if (invoiceState.Invoice.Id !== -1 && invoiceState.Invoice.Status === 1)
			this.getProvider(invoiceState.Invoice.ProviderId);
	}

	/**
	 * API запрос «Provider» на получение информации о выбранном поставщике
	 * @param {number} id ID поставщика
	 */
	getProvider(id) {
		getRequest("Provider", { Id : id }, function (data) { if (data.Success) invoiceState.setProvider(data); });
	}

	/** Закрытие окна с накладной */
	handleClose(event) {
		event.preventDefault();
		this.props.setId(undefined);
	}

	/** Обработка клика на кнопку «Сохранить» */
	handleSubmit(event) {
		event.preventDefault();
		this.saveInvoice();
	}

	/** Обработка клика на кнопку «Подтвердить» */
	handleAccept (event) {
		event.preventDefault();
		invoiceState.InvoiceConfirm = true;
		invoiceState.InvoiceConfirmLoading = false;
	}

	/**
	 * API запрос «SaveInvoice» на сохранение накладной
	 * @param {string} type тип запроса — с подтверждением или без
	 */
	saveInvoice (type) {
		if (!invoiceState.validateData())
			return false;

		var model = this;

		invoiceState.ButtonLoading = true;
		invoiceState.InvoiceConfirmLoading = true;

		getRequest("SaveInvoice", invoiceState.getSaveData(), function (data) {
			if (data.Success) {
				if (type === "accept") model.acceptInvoice();
				else model.props.getItem(data.Id);
			}

			invoiceState.ButtonLoading = data.Success ? type !== "accept" : false;
			invoiceState.ErrorCode = data.Success ? "SUCCESS_SAVE" : data.ErrorCode;
			invoiceState.SuccessSave = data.Success;
		});
	}

	/** API запрос «AcceptInvoice» на подтверждение накладной */
	acceptInvoice () {
		var model = this;
		getRequest("AcceptInvoice", { Id : invoiceState.Invoice.Id }, function (data) {
			if (data.Success) model.props.getItem(invoiceState.Invoice.Id);

			invoiceState.ButtonLoading = false;
			invoiceState.ErrorCode = data.Success ? "SUCCESS_ACCEPT" : data.ErrorCode;
			invoiceState.SuccessSave = data.Success;
			invoiceState.InvoiceConfirm = false;
			invoiceState.InvoiceConfirmLoading = false;
		});
	}

	/**
	 * API запрос «ShopList» на получение состава списка покупок
	 * @param {number} value ID спика покупок
	 */
	changeShopList(value) {
		var model = this;

		invoiceState.ShopListId = value;

		if (value === "") return false;

		getRequest("ShopList", { Id : value }, function (data) {
			invoiceState.setShopList(data);
			model.getProvider(data.ProviderId);
		});
	}

	render() {
		var style = { height : uistate.sectionHeight - (invoiceState.Invoice.Id !== -1 ? 5 : 67) },
			title = invoiceState.Invoice.Id === -1 ? buttonTitles.NewInvoice : buttonTitles.ViewInvoice + " " +
				invoiceState.invoiceTypes[invoiceState.Invoice.InvoiceType] + invoiceState.Invoice.InvoiceNumber + " от " +
				formatDate(invoiceState.Invoice.Date);

		return (
			<section className="clearfix item" id="InvoiceItem">
				<form onSubmit={this.handleSubmit.bind(this)}>
					<ItemHeader
						Title={title}
						Error={invoiceState.ErrorCode}
						Loading={invoiceState.ButtonLoading}
						CanManage={invoiceState.CanManageInvoice}
						CanAccept={invoiceState.CanAcceptInvoice}
						Success={invoiceState.SuccessSave}
						handleClose={this.handleClose.bind(this)}
						onAccept={this.handleAccept.bind(this)}
					/>
					{ invoiceState.Invoice.Id === -1 &&
						<div className="clearfix main backblock">
							<Selectize 
								Id="ShopListId"
								Title="Создать на базе закупки"
								List={toSelectList(invoiceState.ShopsLists)}
								RowClass="col col50"
								onChange={this.changeShopList.bind(this)}
							/>
						</div>
					}
					<div className="clearfix section" style={style}>
						<div className={"clearfix " + (invoiceState.Invoice.Status > 1 ? "processStockStat" : "")}>
							<div className="clearfix td">
								<MainInfoInvoice getProvider={this.getProvider.bind(this)} />
								<PaymentBlock getItem={this.props.getItem} />
							</div>
						</div>
						<ProductInvoiceBlock Products={invoiceState.ProviderProducts} />
					</div>
				</form>
				{invoiceState.InvoiceConfirm &&
					<InvoiceConfirm onConfirm={this.saveInvoice.bind(this)} />
				}
			</section>
		)
	}
}
InvoiceItem = observer(InvoiceItem);

class InvoiceConfirm extends Component {
	onCancel (event) {
		event.preventDefault();
		invoiceState.InvoiceConfirm = false;
	}

	onConfirm (event) {
		event.preventDefault();
		this.props.onConfirm("accept");
	}

	render() {
		var invoice = invoiceState.Invoice,
			type = invoiceState.invoiceTypes[invoice.InvoiceType],
			text = <span>Вы действительно хотите подтвердить накладную&nbsp;
				<b key="b1">«{type} {invoice.InvoiceNumber}»</b>?</span>;
		
		return(
			<div className="back">
				<div className={"confirmBlock clearfix window medium " + (uistate.IsMobile ? "mobile" : "")} id="InvoiceConfirm">
					<p>{text}</p>
					<p className='icon date'><i></i>{formatDate(invoice.Date)}</p>
					<p className='icon provider'><i></i>от поставщика <b>{invoice.Provider}</b></p>
					<p className='icon stock'><i></i>на склад <b>{invoice.Stock}</b></p>
					<p className='icon amount'><i></i>ну сумму {invoice.Amount} руб.</p>
					<form className="buttons clearfix" onSubmit={this.onConfirm.bind(this)}>
						<SubmitButton  Loading={invoiceState.InvoiceConfirmLoading} Title="Подтвердить" />
						<a href="/" onClick={this.onCancel.bind(this)} className="button gray">Отменить</a>
					</form>
				</div>
			</div>
		)
	}
}
InvoiceConfirm = observer(InvoiceConfirm);

class MainInfoInvoice extends Component {
	handleChange(value, id) { 
		invoiceState.collectInvoice(id, value); 
		if (/ProviderId/.test(id)) this.props.getProvider(value);
	}

	render() {
		var invoiceTypes = [{ Id : 1, Name : "Номер" }, { Id : 2, Name : "Товарная накладная" }, 
				{ Id : 3, Name : "Счет-фактура" }];

		if (invoiceState.Invoice.Status === 1)
			return(
				<div className="col col70 main">
					<Selectize 
						Id="InvoiceType"
						Title="Тип накладной"
						List={toSelectList(invoiceTypes)}
						Value={invoiceState.Invoice.InvoiceType}
						RowClass="col col1_3 full_select"
						onChange={this.handleChange.bind(this)}
					/>
					<TextInput
						Id="InvoiceNumber"
						Title="Номер накладной"
						Value={invoiceState.Invoice.InvoiceNumber}
						RowClass="col col1_3"
						Required={true}
						onChange={this.handleChange.bind(this)}
					/>
					<DateInput
						Id="Date"
						Title="Дата накладной"
						Value={invoiceState.Invoice.Date}
						RowClass="col col1_3"
						Required={true}
						onChange={this.handleChange.bind(this)}
					/>
					<Selectize 
						Id="StockId"
						Title="Склад"
						List={toSelectList(invoiceState.Stocks)}
						Value={invoiceState.Invoice.StockId}
						RowClass="col col50"
						onChange={this.handleChange.bind(this)}
					/>
					<Selectize 
						Id="ProviderId"
						Title="Поставщик"
						List={toSelectList(invoiceState.Providers)}
						Value={invoiceState.Invoice.ProviderId}
						RowClass="col col50"
						onChange={this.handleChange.bind(this)}
					/>
					<TextInput
						Id="Description"
						Title="Комментарий"
						Type="textarea"
						Value={invoiceState.Invoice.Description}
						RowClass="col col100"
						onChange={this.handleChange.bind(this)}
					/>
				</div>
			)
		else 
			return(
				<div className="col col70 main td">
					<div className="col col50 create td">
						<h5><i></i>Создана</h5>
						<div className="clearfix row">
							<span className="col col40 title">Пользователь</span>
							<span className="col col60">{invoiceState.Invoice.User}</span>
						</div>
						<div className="clearfix row">
							<span className="col col40 title">Дата создания</span>
							<span className="col col60">{formatDate(invoiceState.Invoice.Date)}</span>
						</div>
						<div className="clearfix row">
							<span className="col col40 title">Склад</span>
							<span className="col col60">{invoiceState.Invoice.Stock}</span>
						</div>
						<div className="clearfix row">
							<span className="col col40 title">Поставщик</span>
							<span className="col col60">{invoiceState.Invoice.Provider}</span>
						</div>
						{invoiceState.Invoice.Description !== "" &&
							<div className="clearfix row">
								<span className="col col40 title">Комментарий</span>
								<span className="col col60">{invoiceState.Invoice.Description}</span>
							</div>
						}
					</div>
					{invoiceState.Invoice.Status !== 1 && invoiceState.Invoice.AcceptDate !== "" &&
						<div className="col col50 accept td">
							<h5><i></i>Подтверждено</h5>
							<div className="clearfix row">
								<span className="col col40 title">Пользователь</span>
								<span className="col col60">{invoiceState.Invoice.AcceptUser}</span>
							</div>
							<div className="clearfix row">
								<span className="col col40 title">Дата подтверждения</span>
								<span className="col col60">{formatDate(invoiceState.Invoice.AcceptDate)}</span>
							</div>
						</div>
					}
				</div>
			)
	}
}
MainInfoInvoice = observer(MainInfoInvoice);

class PaymentBlock extends Component {
	handlePay(event) {
		event.preventDefault();

		var model = this,
			types = { 1 : "№", 2 : "ТН", 3 : "СФ" },
			type = types[invoiceState.Invoice.InvoiceType],
			text = <span>Вы действительно хотите оплатить накладную&nbsp;
				<b key="b1">«{type} {invoiceState.Invoice.InvoiceNumber}»</b>?</span>;

		confirmState.openConfirm(text, function () {
			getRequest("PaymentInvoice", { Id : invoiceState.Invoice.Id }, function (data) {
				invoiceState.ErrorCode = data.ErrorCode;
				if (data.Success) model.props.getItem(invoiceState.Invoice.Id);
			});
		});
	}

	handleChange(value, id) { invoiceState.collectInvoice(id, value); }

	render() {
		var paymentTypes = [{ Id : 2, Name : "По счету" }, { Id : 3, Name : "Бизнес-карта" },
				{ Id : 1, Name : "Наличные" } ],
			currentType = invoiceState.Invoice.PaymentType && _.find(paymentTypes, { Id : invoiceState.Invoice.PaymentType})
				? _.find(paymentTypes, { Id : invoiceState.Invoice.PaymentType}).Name : "", 
			today = moment(),
			deadline = moment(invoiceState.Invoice.DeadlinePayment),
			delta = deadline.diff(today, "days");

		return(
			<div className="col col30 payment td">
				<h5><i></i>Оплата</h5>
				{invoiceState.Invoice.Status === 1 &&
					<Selectize 
						Id="PaymentType"
						Title="Тип оплаты"
						List={toSelectList(paymentTypes)}
						Value={invoiceState.Invoice.PaymentType}
						onChange={this.handleChange.bind(this)}
					/>
				}
				{invoiceState.Invoice.Status !== 1 &&
					<div className="clearfix row">
						<span className="col col40 title">Тип оплаты</span>
						<span className="col col60">{currentType}</span>
					</div>
				}
				{invoiceState.Invoice.Status === 3 &&
					<div className="clearfix row">
						<span className="col col40 title">Пользователь</span>
						<span className="col col60">{invoiceState.Invoice.PaymentUser}</span>
					</div>
				}
				{invoiceState.Invoice.Status === 3 &&
					<div className="clearfix row">
						<span className="col col40 title">Дата оплаты</span>
						<span className="col col60">{formatDate(invoiceState.Invoice.PaymentDate)}</span>
					</div>
				}
				{invoiceState.Invoice.Status === 2 &&
					<div className="clearfix row">
						<span className="col col40 title">Оплатить до</span>
						<span className="col col60">{formatDate(invoiceState.Invoice.DeadlinePayment)}</span>
					</div>
				}
				{invoiceState.Invoice.Status === 2 &&
					<div className="clearfix">
						{today.isAfter(deadline) && <p className="error">Срочно оплатить!</p> }
						{!today.isAfter(deadline) && delta < 4 && <p className="error">Осталось {delta} дня</p> }
						<a href="/" className="button pay" onClick={this.handlePay.bind(this)}>Оплатить</a>
					</div>
				}
			</div>
		)
	}
}
PaymentBlock = observer(PaymentBlock);

class ProductInvoiceBlock extends Component {
	handleAdd(event) {
		event.preventDefault();
		invoiceState.Products = _.concat(invoiceState.Products, _.clone(invoiceState.ProductDummy));
	}

	render() {
		var view = this;

		return(
			<div className="clearfix invoiceList">
				<h4>Заказ</h4>
				<div className="clearfix products">
					<div className="clearfix titles">
						<span className="col col25">Сырье</span>
						<span className={invoiceState.Invoice.Status === 1 ? "col col10" : "col col15"}>Кол-во нетто</span>
						{invoiceState.Invoice.Status === 1 &&
							<span className="col col5">ЕИ нетто</span>
						}
						<span className={invoiceState.Invoice.Status === 1 ? "col col10" : "col col15"}>Кол-во брутто</span>
						{invoiceState.Invoice.Status === 1 &&
							<span className="col col5">ЕИ брутто</span>
						}
						<span className="col col10">Цена за ед.</span>
						<span className="col col10">Сумма</span>
						<span className="col col15">НДС, %</span>
						<span className="col col10">НДС, руб.</span>
					</div>
					{_.map(invoiceState.Products, function (product, i) {
						return(<ProductInvoiceItem key={"p" + invoiceState.pCounter++} Index={i}
							Item={product} Products={view.props.Products} />)
					})}
					{invoiceState.Invoice.Status === 1 &&
						<a href="/" className="icon button add blue" onClick={this.handleAdd.bind(this)}>
							<i></i>Добавить сырье</a>
					}
				</div>
				<SummaryBlock />
			</div>
		)
	}
}
ProductInvoiceBlock = observer(ProductInvoiceBlock);

class ProductInvoiceItem extends Component {
	handleDelete(event) {
		event.preventDefault();

		var index = this.props.Index;
		invoiceState.Products = _.filter(invoiceState.Products, function (v,i) { return i !== index; });
	}

	handleChange (value, id) { 
		var product = {}

		if (/ProductId/.test(id)) product = _.find(invoiceState.ProviderProducts, { ProductId : value });
		invoiceState.collectProducts(id, value, this.props.Index, product); 
	}

	render() {
		var productList = _.map(this.props.Products, function (v) { return { Id : v.ProductId, Name : v.Product }; })

		if (invoiceState.Invoice.Status === 1)
			return(
				<div className="clearfix item">
					<Selectize 
						Id="ProductId"
						List={toSelectList(productList)}
						Value={this.props.Item.ProductId}
						RowClass="col col25"
						onChange={this.handleChange.bind(this)}
					/>
					<TextInput 
						Id="Count"
						Value={this.props.Item.Count}
						RowClass="col col10"
						Required={true}
						Type="number"
						Step="0.001"
						onChange={this.handleChange.bind(this)}
					/>
					<TextInput 
						Id="UnitNet"
						Value={this.props.Item.UnitNet}
						RowClass="col col5"
						Disabled={true}
						onChange={this.handleChange.bind(this)}
					/>
					<TextInput 
						Id="CountGross"
						Value={this.props.Item.CountGross}
						RowClass="col col10"
						Type="number"
						Step="0.001"
						Required={true}
						onChange={this.handleChange.bind(this)}
					/>
					<TextInput 
						Id="UnitGross"
						Value={this.props.Item.UnitGross}
						RowClass="col col5"
						Disabled={true}
						onChange={this.handleChange.bind(this)}
					/>
					<TextInput 
						Id="Price"
						Value={this.props.Item.Price}
						RowClass="col col10"
						Type="number"
						Step="0.01"
						Required={true}
						onChange={this.handleChange.bind(this)}
					/>
					<TextInput 
						Id="Amount"
						Value={this.props.Item.Amount}
						RowClass="col col10"
						Type="number"
						Step="0.01"
						Required={true}
						onChange={this.handleChange.bind(this)}
					/>
					<Selectize 
						Id="VAT"
						List={toSelectList(VATTitles)}
						Value={this.props.Item.VAT}
						RowClass="col col15"
						onChange={this.handleChange.bind(this)}
					/>
					<TextInput 
						Id="VATAmount"
						Value={this.props.Item.VATAmount}
						RowClass="col col10"
						Type="number"
						Step="0.01"
						Required={true}
						onChange={this.handleChange.bind(this)}
					/>
					<a href="/" className="button icon remove" onClick={this.handleDelete.bind(this)}>
							<i></i></a>
				</div>
			)
		else
		return(
			<div className="clearfix item">
				<span className="col col25">{this.props.Item.ProductName}</span>
				<span className="col col15">{this.props.Item.Count} {this.props.Item.UnitNet}</span>
				<span className="col col15">{this.props.Item.CountGross} {this.props.Item.UnitGross}</span>
				<span className="col col10">{this.props.Item.Price}</span>
				<span className="col col10">{this.props.Item.Amount}</span>
				<span className="col col15">{this.props.Item.VATTitle}</span>
				<span className="col col10">{this.props.Item.VATAmount}</span>
			</div>
		)
	}
}
ProductInvoiceItem = observer(ProductInvoiceItem);

class SummaryBlock extends Component {
	handleChange(value, id) { invoiceState.collectInvoice(id, value); }

	render() {
		if (invoiceState.Invoice.Status === 1) return(
				<div className="clearfix summary">
					<h4 className="col col70">Итого</h4>
					<TextInput
						Id="Amount"
						Title="Сумма накладной"
						Value={invoiceState.Invoice.Amount}
						RowClass="col col15"
						Required={true}
						onChange={this.handleChange.bind(this)}
					/>
					<TextInput
						Id="VATAmount"
						Title="В т.ч. НДС"
						Value={invoiceState.Invoice.VATAmount}
						RowClass="col col15"
						Required={true}
						onChange={this.handleChange.bind(this)}
					/>
				</div>)
		else return(
			<div className="clearfix summary">
				<h4 className="col col70">Итого</h4>
				<div className="col col30">
					<h5 className="col col60">Сумма накладной</h5>
					<p className="col col40">{invoiceState.Invoice.Amount} руб.</p>
					<h5 className="col col60">В т.ч. НДС</h5>
					<p className="col col40">{invoiceState.Invoice.VATAmount} руб.</p>
				</div>
			</div>
		)	
	}
}
SummaryBlock = observer(SummaryBlock);

export class PaymentsPage extends Component {
	constructor(props) {
		super(props);

		this.state = {};

		paymentsState.setDefault();

		this.getData = this.getData.bind(this);
		this.getData();
	}

	/** API запрос на получение платежек за указанный месяц */
	getData() {
		paymentsState.Dates = {};
		paymentsState.setProvider(undefined);
		getRequest("InvoicesCalendar", { Date : paymentsState.CurrentMonth }, function (data) {
			paymentsState.setMonth(data.Success ? data.Dates : []);
		});
	}

	/** Изменение активного месяца */
	changeMonth(event) {
		event.preventDefault();

		paymentsState.CurrentMonth = event.target.getAttribute("href").replace("#", "");
		this.getData();
	}

	render() {
		moment.locale('ru');
		
		var today = moment(paymentsState.CurrentMonth),
			prev = moment(paymentsState.CurrentMonth).subtract(1, "months"),
			next = moment(paymentsState.CurrentMonth).add(1, "months");

		return(
			<div className="page" id="PaymentPage">
				<div className="head clearfix">
					<h2>{dashboardsTitles[this.props.Type] || this.props.Type}</h2>
					<div className="monthsFilter">
						<a className="prev" href={"#" + prev.format("YYYY-MM")} 
							onClick={this.changeMonth.bind(this)}>{prev.format("MMMM YYYY")}</a>
						<span>{today.format("MMMM YYYY")}</span>
						<a className="next" href={"#" + next.format("YYYY-MM")} 
							onClick={this.changeMonth.bind(this)}>{next.format("MMMM YYYY")}</a>
					</div>
				</div>
				{_.isEmpty(paymentsState.Dates) &&
				<section className="clearfix"><div className="loader"><img src={pageLoader} alt="logo" /></div></section>
				}
				{!_.isEmpty(paymentsState.Dates) &&	
					<section className="clearfix table">
						<div className="clearfix titles">
							<div className="weekday col">{daysShort[this.props.Index - 1]}</div>
							{_.map(paymentsState.Weeks, function (v,i) {
								return(<span className="col" style={{width : paymentsState.dayWidth}} 
									key={"wt" + i}>{v.Title}</span>)
							})}
						</div>
						<div className="clearfix dates">
							{_.map(paymentsState.Dates, function (days, i) {
								return(<DayLineBlock key={"w" + i} Days={days} Index={i} />)
							})}
						</div>
						<WeekSummaryBlock />
						{!_.isEmpty(paymentsState.CurrentProvider) &&
							<ProviderPopup getData={this.getData} />
						}
					</section>
				}
			</div>
		)
	}
}
PaymentsPage = observer(PaymentsPage);

class DayLineBlock extends Component {
	render() {
		var view = this;

		return(
			<div className="clearfix line">
				<div className="weekday col">{daysShort[this.props.Index - 1]}</div>
				{_.map(this.props.Days, function (day, i) {
					return(<DayBlock Index={i} Day={day} key={"d" + paymentsState.dayCounter++}
						DayIndex={view.props.Index}/>)
				})}
			</div>
		)
	}
}
DayLineBlock = observer(DayLineBlock);
class DayBlock extends Component {
	handleClick (event) {
		event.preventDefault();
		paymentsState.setProvider(this.props.DayIndex, this.props.Day.Date, 
			event.currentTarget.getAttribute("href").replace("#", ""));
	}

	render() {
		var view = this,
			isToday = this.props.Day.Today ? " today" : "",
			style = {
				width : paymentsState.dayWidth,
				minWidth : paymentsState.dayWidth,
				maxWidth : paymentsState.dayWidth
			};

		return(
			<div className={"col day" + (this.props.Day.Disabled ? " disabled" : "") + isToday} 
					style={style}>
				{(_.isUndefined(this.props.Day.Disabled) || !this.props.Day.Disabled) &&
					<h5>{moment(this.props.Day.Date).format("DD.MM.YYYY")}</h5>
				}
				{!_.isEmpty(this.props.Day.Providers) &&
					_.map(this.props.Day.Providers, function(v,i) {
						var statusName = v.Status === 3 ? "pay" : v.Alert ? "alert" : "notPay";

						return(
							<a className={"clearfix provider " + statusName} key={"p" + i} href={"#" + v.Provider}
									onClick={view.handleClick.bind(view)}>
								<span className="col col60" title={v.Provider}>{v.Provider}</span>
								<span className="col col40">
									<i className={"icon type_" + v.PaymentType}></i>
									{getRubs(v.Total, true, true)}&nbsp;₽
								</span>
							</a>
						)
					})
				}
			</div>
		)
	}
}
DayBlock = observer(DayBlock);

class ProviderPopup extends Component {
	/** Закрытие всплывающего окна с поставщиком */
	handleClose(event) {
		event.preventDefault();
		paymentsState.setProvider(undefined);
		this.props.getData();
	}

	/** API запрос на оплату накладной с подтверждением */
	handlePay(event) {
		event.preventDefault();

		var id = parseInt(event.currentTarget.getAttribute("href").replace("#", ""), 10),
			item = _.find(paymentsState.CurrentProvider.Invoices, { Id : id }),
			types = { 1 : "№", 2 : "ТН", 3 : "СФ" },
			type = item ? types[item.InvoiceType] : "",
			text = <span>Вы действительно хотите оплатить накладную&nbsp;
				<b key="b1">«{type} {item ? item.InvoiceNumber : ""}»</b>?</span>;

		confirmState.openConfirm(text, function () {
			getRequest("PaymentInvoice", { Id : id }, function (data) { 
				if (data.Success) item = _.extend(item, {
					Status : 3,
					PaymentDate : moment().format("YYYY-MM-DD"),
					PaymentUser : authUserState.User.Name
				}); 
			});
		});
	}

 	render() {
		var view = this,
			types = { 1 : "Наличные", 2 : "По счету", 3 : "Бизнес-карта" },
			paymentTypes = _.groupBy(paymentsState.CurrentProvider.Invoices, "PaymentType"),
			currentDate = moment(paymentsState.CurrentProvider.Date),
			style ={ maxHeight : uistate.sectionHeight - 10 }

		return(
			<div className="popup clearfix" style={style}>
				<a href="/" className="close" onClick={this.handleClose.bind(this)}><i></i></a>
				<h5 className="clearfix">
					<span className="provider col col80">{paymentsState.CurrentProvider.Provider}</span>
					<span className="date col col20">{currentDate.format("DD.MM")}</span>
					{paymentsState.CurrentProvider.Status !== 3 &&
						<span className="deadline col col80">
							Оплатить до&nbsp; {formatDate(paymentsState.CurrentProvider.DeadlinePayment)}
						</span>
					}
				</h5>
				<div className="clearfix">
					{_.map(paymentTypes, function (invoices, type) {
						var statusGroup = _.groupBy(invoices, "Status");

						return(<div className="clearfix type" key={"p" + type}>
							<h5><i className={"icon type_" + type}></i>{types[type]}</h5>
							{_.map([2,3], function (status) {
								if (!_.isUndefined(statusGroup[status]))
									return(<div className="clearfix" key={"ps" + status}>
										{_.map(statusGroup[status], function(payment, i) {
											var statusName = payment.Status === 3 ? "pay" : payment.Alert ? "alert" : "notpay";
											
											return(<div className={"clearfix payment " + statusName} key={"psp" + i}>
												<span className="col col90">
													{payment.Name} от {currentDate.format("DD.MM.YYYY")}
												</span>
												<span className="col col90">
													Сумма: {getRubs(payment.Amount, true, true, " ", true)}&nbsp;₽,&nbsp;
													НДС: {getRubs(payment.VATAmount, true, true, " ", true)}&nbsp;₽
												</span>
												{payment.Status !== 3 &&
													<a href={"#" + payment.Id} title="Пометить как оплаченную" 
														className="icon pay button"
														onClick={view.handlePay.bind(view)}><i></i></a>
												}
												{payment.Status === 3 &&
													<span className="comment col col100">
														{moment(payment.PaymentDate).format("DD.MM.YYYY ")}
														{payment.PaymentUser}
													</span>
												}
											</div>)
										}) }
										<div className="clearfix summary">
											<p><b>ИТОГО:</b></p>
											<p>{_.map(statusGroup[status], "Name").join(", ")}
												&nbsp;от&nbsp;{currentDate.format("DD.MM.YYYY")}</p>
											<p>
												Сумма: {getRubs(_.sumBy(statusGroup[status], "Amount"), true, true, " ", true)}&nbsp;₽,&nbsp;
												НДС: {getRubs(_.sumBy(statusGroup[status], "VATAmount"), true, true, " ", true)}&nbsp;₽
											</p>
										</div>
									</div>)
							}) }
						</div>)
					}) }
				</div>
			</div>
		)
	}
}
ProviderPopup = observer(ProviderPopup);

class WeekSummaryBlock extends Component {
	render() {
		var types = { 1 : "Наличные", 2 : "По счету", 3 : "Бизнес-карта" }
		return(
			<div className="clearfix summary">
				<div className="weekday col">{daysShort[this.props.Index - 1]}</div>
				{_.map(paymentsState.Weeks, function (week,i) {
					return(<div className="col sum" style={{ width : paymentsState.dayWidth}} key={"ws" + i}>
						{_.map(week.Amounts, function (type, k) {
							var statusName = type.Status === 3 ? "pay" : type.Alert ? "alert" : "notPay";
							return(<p className={"clearfix " + statusName} key={"wa" + k}>
								<span className="total col col60" title={types[type.Type] + " не оплачено"}>
									<i className={"icon type_" + type.Type}></i>
									{getRubs(type.Total, true, true)}&nbsp;₽</span>
								<span className="payment col col40" title={types[type.Type] + " оплачено"}>
									{getRubs(type.Payment, true, true)}&nbsp;₽</span>
							</p>)
						})}
					</div>)
				})}
			</div>
		)
	}
}
WeekSummaryBlock = observer(WeekSummaryBlock);