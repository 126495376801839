import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/ru';

import { toSelectList, checkRights, getRequest, getRubs, formatDate } from '../functions';
import { buttonTitles, dashboardsTitles } from '../dictionary';

import { ItemHeader, TableReportPage } from './PageTemplates';
import { Selectize, TextInput, DateInput } from '../forms/FormItems';

import { uistate, authUserState, confirmState } from '../stores/common';
import { writeOffState } from '../stores/writeoffs';

export class WriteOffRequestsPage extends Component {
	constructor(props) {
		super(props);

		writeOffState.StockActive = -1;
		writeOffState.setRequest();
		writeOffState.setWriteOff(undefined);

		this.state = {};

		var model = this;
		getRequest("CancelReasons", {}, function (data) { model.setList(data, "CancelReasons"); });
		getRequest("Products", { Active: true }, function (data) { model.setList(data, "Products"); });
	}

	setList (data, param) { this.setState({ [param]: data.Success ? data[param] : []}); }

	render() {
		return (
			<div className="page" id="WriteOffRequestsPage">
				{!checkRights("ManageWriteOff") &&
					<WriteOffRequestItem Products={this.state.Products} CancelReasons={this.state.CancelReasons} />
				}
				{checkRights("ManageWriteOff") &&
					<WriteOffSendItem Products={this.state.Products} CancelReasons={this.state.CancelReasons} />
				}
			</div>
		)
	}
}
WriteOffRequestsPage = observer(WriteOffRequestsPage);

class WriteOffRequestItem extends Component {
	handleSubmit(event) {
		event.preventDefault();

		// console.log(writeOffState.getRequestData())
		// return false;

		if (!writeOffState.validateRequest()) return false;

		writeOffState.ButtonLoading = true;

		getRequest("CreateWriteOffRequest", writeOffState.getRequestData(), function (data) {
			writeOffState.ErrorCode = data.Success ? "SUCCESS_SAVE" : data.ErrorCode;
			writeOffState.SuccessSave = data.Success
			writeOffState.ButtonLoading = false;

			if (data.Success) setTimeout(function() { writeOffState.setRequest(); }, 1000);
		});
	}

	render() {
		return (
			<section className="clearfix item" id="WriteOffRequestItem">
					<form onSubmit={this.handleSubmit.bind(this)}>
						<ItemHeader
							Title={buttonTitles["RequestWriteOff"]}
							Error={writeOffState.ErrorCode}
							Loading={writeOffState.ButtonLoading}
							CanManage={authUserState.Shift.Active}
							Success={writeOffState.SuccessSave}
							HideClose={true}
						/>
						{!authUserState.Shift.Active &&
							<p>Для работы с данным разделом необходимо открыть смену</p>
						}
						{authUserState.Shift.Active &&
							<div className="clearfix section" style={{ height: uistate.sectionHeight - 5 }}>
								<RequestProducts Products={this.props.Products} CancelReasons={this.props.CancelReasons}/>
							</div>
						}
					</form>
			</section>
		)
	}
}
WriteOffRequestItem = observer(WriteOffRequestItem);

class RequestProducts extends Component {
	handleChange(value, id) { writeOffState.WriteOffRequest.StockId = value; }
	
	render() {
		return (
			<div className="clearfix"  >
				<div className="clearfix block">
					<Selectize
						Id="StockId"
						Title="Склад"
						List={toSelectList(authUserState.Stocks)}
						Value={writeOffState.WriteOffRequest.StockId}
						RowClass="col col30"
						isSearchable={false}
						onChange={this.handleChange.bind(this)}
					/>
				</div>
				<div className="clearfix block">
					<ProductWriteOffGroup Type="Request" ProductsList={writeOffState.RequestProducts} 
						Products={this.props.Products} CancelReasons={this.props.CancelReasons} />
				</div>
			</div>
		)
	}
}
RequestProducts = observer(RequestProducts);

class WriteOffSendItem extends Component {
	handleSubmit(event) {
		event.preventDefault();
		this.saveWriteOff();
	}

	handleSend(event) {
		event.preventDefault();
		this.saveWriteOff("send");
	}

	saveWriteOff(type) {
		if (!writeOffState.validateData()) return false;

		writeOffState.ButtonLoading = true;

		var model = this;
		getRequest("EditWriteOff", writeOffState.getSaveData(), function (data) {
			if (data.Success) {
				if (type === "send") {
					writeOffState.WriteOff.Id = data.Id;
					model.sendWriteOff();
				} else model.getItem();
			}

			writeOffState.ErrorCode = data.Success ? "SUCCESS_SAVE" : data.ErrorCode;
			writeOffState.SuccessSave = data.Success;
			writeOffState.ButtonLoading = data.Success && type !== "send";
		});
	}

	sendWriteOff () {
		var model = this;
		getRequest("SendWriteOff", { Id : writeOffState.WriteOff.Id }, function (data) {
			if (data.Success) setTimeout(function () { model.getItem()}, 500);

			writeOffState.ErrorCode = data.Success ? "SUCCESS_SEND" : data.ErrorCode;
			writeOffState.SuccessSave = data.Success;
			writeOffState.ButtonLoading = false;
		});
	}

	changeStock(value, id) { 
		writeOffState.StockActive = value; 
		this.getItem();
	}

	getRequests() {
		getRequest("WriteOffRequests", { StockId : writeOffState.StockActive, Status : 1 }, function (data) {
			writeOffState.setRequests(data.Success && !_.isNull(data.Requests) ? data.Requests : []);
		});
	}

	getItem() {
		var model = this;

		getRequest("WriteOff", { StockId : writeOffState.StockActive }, function (data) {
			writeOffState.setWriteOff(data.Success ? data : undefined);
			model.getRequests();
		});
	}

	handleChange(value, id) { writeOffState.collectWriteOff(id, value); }

	render() {
		var shiftActive = authUserState.Shift.Active,
			writeOff = writeOffState.WriteOff,
			hasStock = writeOffState.StockActive !== "" && writeOffState.StockActive !== -1,
			canSave = writeOff && (writeOff.Date === "" || writeOff.Id > 0);

		return (
			<section className="clearfix item" id="WriteOffRequestItem">
				<form onSubmit={this.handleSubmit.bind(this)}>
					<ItemHeader
						Title={buttonTitles["NewWriteOff"]}
						Error={writeOffState.ErrorCode}
						Loading={writeOffState.ButtonLoading}
						CanManage={shiftActive && canSave}
						CanSend={shiftActive && hasStock}
						Success={writeOffState.SuccessSave}
						HideClose={true}
						onSend={this.handleSend.bind(this)}
					/>
					{!shiftActive && <p>Для работы с данным разделом необходимо открыть смену</p> }
					{shiftActive &&
						<div className="clearfix main backblock">
							<Selectize
								Id="StockId"
								Title="Склад"
								isSearchable={false}
								List={toSelectList(authUserState.Stocks)}
								RowClass="col col25"
								Value={writeOffState.StockActive}
								onChange={this.changeStock.bind(this)}
							/>
							{checkRights("AcceptWriteOff") && hasStock &&
								<DateInput
									Id="Date"
									Title="Дата списания"
									WithTime={true}
									RowClass="col col25"
									Value={writeOff ? writeOff.Date : ""}
									onChange={this.handleChange.bind(this)}
								/>
							}
						</div>
					}
					{shiftActive && !_.isUndefined(writeOff) &&
						<div className="clearfix section">
							<GroupWriteOffBlock Products={this.props.Products} CancelReasons={this.props.CancelReasons} />
						</div>
					}
				</form>
			</section>
		)
	}
}
WriteOffSendItem = observer(WriteOffSendItem);


export class WriteOffsPage extends Component {
	constructor(props) {
		super(props);

		writeOffState.setWriteOff(undefined);

		this.state = {
			isOpen: false
		};

		this.getData = this.getData.bind(this);
		this.getData();

		var model = this;
		getRequest("CancelReasons", {}, function (data) { model.setModel(data, "CancelReasons"); });
		getRequest("Products", { Active : true }, function (data) { model.setModel(data, "Products"); });
	}

	setModel (data, param) { this.setState({ [param] : data.Success ? data[param] : [] }) }

	getData() {
		writeOffState.IsSendRequest = false;
		getRequest("WriteOffs", writeOffState.getFilterData(), function (data) { writeOffState.setWriteOffs(data); });
	}

	getItem(id) {
		getRequest("WriteOff", { Id: id }, function (data) {
			writeOffState.setWriteOff(data.Success ? data : undefined);
		});
	}

	setId(id) {
		if (!_.isUndefined(id)) {
			if (id !== -1) this.getItem(id)
			else writeOffState.setWriteOff({ Id: -1 });
		} else {
			writeOffState.setWriteOff(undefined);
			this.getData();
		}
	}

	toggleFilter(event) {
		event.preventDefault();
		this.setState({ isOpen: !this.state.isOpen });
	}

	handleChange(value, id) { writeOffState.collectFilter(id, value); }

	handleSubmit(event) {
		event.preventDefault();
		writeOffState.Filter.Offset = 0;
		this.getData();
	}

	changePage(offset) {
		writeOffState.Filter.Offset = offset;
		this.getData();
	}

	render() {
		var statuses = [{ Id: 1, Name: "Новое" }, { Id: 2, Name: "Отправлено" }, { Id: 3, Name: "Подтверждено" }],
			columns = {
				ShiftDate: {}, User: {}, Stock: {}, 
				Status: { filterable: false, Cell: row => _.find(statuses, { Id: row.original.Status }).Name },
			};

		if (checkRights("AcceptWriteOff")) _.extend(columns, { Amount: {} });
		if (checkRights("AcceptWriteOff") || checkRights("ManageWriteOff")) _.extend(columns, { View: {}, Edit: {} });

		return (
			<div className="page" id="WriteOffPage">
				{_.isUndefined(writeOffState.WriteOff) &&
					<div className="head">
						<h2>{dashboardsTitles[this.props.Type]}</h2>
						<a href="/" className={"toggleFilter " + (this.state.isOpen ? "active" : "")}
							title="Фильтр поиска" onClick={this.toggleFilter.bind(this)}><i></i></a>
					</div>
				}
				{this.state.isOpen && _.isUndefined(writeOffState.WriteOff) &&
					<form onSubmit={this.handleSubmit.bind(this)} className="filters clearfix">
						<div className="col col80">
							<Selectize
								Id="StockId"
								Title="Склад"
								List={toSelectList(authUserState.allStocks)}
								Value={writeOffState.Filter.StockId}
								isSearchable={false}
								onChange={this.handleChange.bind(this)}
							/>
							<Selectize
								Id="Status"
								Title="Статус"
								isSearchable={false}
								List={toSelectList(statuses)}
								Value={writeOffState.Filter.Status}
								onChange={this.handleChange.bind(this)}
							/>
							<DateInput
								Id="ShiftDate"
								Title="Дата отправки списаний"
								Value={writeOffState.Filter.ShiftDate}
								onChange={this.handleChange.bind(this)}
							/>
						</div>
						<div className="buttons class col col20"><button>Найти</button></div>
					</form>
				}
				{!_.isEmpty(writeOffState.WriteOffs) && _.isUndefined(writeOffState.WriteOff) &&
					<TableReportPage
						TableData={writeOffState.WriteOffs}
						Type="WriteOffs"
						TableColumns={columns}
						FilterHeight={this.state.isOpen ? 125 : 0}
						Pagination={true}
						Limit={writeOffState.Limit}
						HasMore={writeOffState.HasMore}
						Offset={writeOffState.Filter.Offset}

						changePage={this.changePage.bind(this)}
						editRow={this.setId.bind(this)}

						getTrProps={(state, rowInfo, column) => {
							return { className : rowInfo.original.Status === 3 ? "notactive" : 
								rowInfo.original.AutoSend ? "notSend" : ""};
						}}
					/>
				}
				{writeOffState.IsSendRequest && _.isEmpty(writeOffState.WriteOffs) &&
					<p>По вашему запросу ничего не найдено</p>
				}
				{!_.isUndefined(writeOffState.WriteOff) &&
					<WriteOffItem
						CancelReasons={this.state.CancelReasons}
						Products={this.state.Products}

						setId={this.setId.bind(this)}
						getItem={this.getItem.bind(this)}
					/>
				}
			</div>
		)
	}
}
WriteOffsPage = observer(WriteOffsPage);

class WriteOffItem extends Component {
	handleClose(event) {
		event.preventDefault();
		this.props.setId(undefined);
	}

	handleSubmit(event) {
		event.preventDefault();

		if (!writeOffState.validateData()) return false;
		this.saveWriteOff();
	}

	handleAccept(event) {
		event.preventDefault();

		var model = this,
			date = moment(writeOffState.WriteOff.Date).format("DD.MM.YYYY"),
			text = <span>Вы действительно хотите подтвердить списание от&nbsp;<b key="b1">«{date}»</b>?</span>;

		confirmState.openConfirm(text, function() { model.saveWriteOff("accept"); });
	}

	saveWriteOff (type) {
		writeOffState.ButtonLoading = true;

		var model = this;
		getRequest("EditWriteOff", writeOffState.getSaveData(), function (data) {
			if (data.Success) {
				if (type === "accept") {
					var id = data.Id;
					getRequest("AcceptWriteOff", { Id : data.Id }, function (data) {
						if (data.Success) model.props.getItem(id);

						writeOffState.ErrorCode = data.Success ? "SUCCESS_ACCEPT" : data.ErrorCode;
						writeOffState.SuccessSave = data.Success;
						writeOffState.ButtonLoading = false;
					});
				} else model.props.getItem(data.Id);
			}

			writeOffState.ErrorCode = data.Success ? "SUCCESS_SAVE" : data.ErrorCode;
			writeOffState.SuccessSave = data.Success;
			writeOffState.ButtonLoading = type !== "accept";
		});
	}

	handleChange (value, id) { writeOffState.collectWriteOff(id, value); }

	render() {
		var style = { height: uistate.sectionHeight - 5 },
			title = (writeOffState.WriteOff.Status === 3 ? "View" : "Edit") + "WriteOff",
			canManage = checkRights("AcceptWriteOff") || checkRights("ManageWriteOff")

		return (
			<section className="clearfix item" id="WriteOffRequestItem">
				<form onSubmit={this.handleSubmit.bind(this)}>
					<ItemHeader
						Title={buttonTitles[title]}
						Error={writeOffState.ErrorCode}
						Loading={writeOffState.ButtonLoading}
						CanManage={canManage && writeOffState.WriteOff.Status === 2}
						CanAccept={checkRights("AcceptWriteOff") && writeOffState.WriteOff.Status === 2}
						Success={writeOffState.SuccessSave}
						handleClose={this.handleClose.bind(this)}
						onAccept={this.handleAccept.bind(this)}
					/>
					<div className="clearfix section" style={style}>
						{writeOffState.WriteOff.Id !== -1 &&
							<div className="clearfix main processStockStat">
								<div className="clearfix tr">
								<div className="col col1_3 create td">
									<h5><i></i>Создано</h5>
									<div className="clearfix row">
										<span className="col col40 title">Пользователь</span>
										<span className="col col60">{writeOffState.WriteOff.User}</span>
									</div>
									<div className="clearfix row">
										<span className="col col40 title">Склад</span>
										<span className="col col60">{writeOffState.WriteOff.Stock}</span>
									</div>
									<div className="clearfix row">
										<span className="col col40 title">Дата смены</span>
										<span className="col col60">{formatDate(writeOffState.WriteOff.ShiftDate)}</span>
									</div>
									<div className="clearfix row">
										<span className="col col40 title">Дата списания</span>
										{writeOffState.WriteOff.Status === 3 && <span className="col col60">
												{formatDate(writeOffState.WriteOff.Date, "DD.MM.YYYY HH:mm")}
											</span>}
										{writeOffState.WriteOff.Status === 2 && checkRights("AcceptWriteOff") &&
											<DateInput
												Id="Date"
												Value={writeOffState.WriteOff.Date}
												RowClass="col col60 nomargin"
												WithTime={true}
												onChange={this.handleChange.bind(this)}
											/>
										}
									</div>
								</div>
								<div className="col col1_3 send td">
									<h5><i></i>Отправлено</h5>
									<div className="clearfix row">
										<span className="col col40 title">Пользователь</span>
										<span className="col col60">{writeOffState.WriteOff.SendUser}</span>
									</div>
									<div className="clearfix row">
										<span className="col col40 title">Дата отправки</span>
										<span className="col col60">
											{formatDate(writeOffState.WriteOff.SendDate, "DD.MM.YYYY HH:mm")}
										</span>
									</div>
								</div>
								{writeOffState.WriteOff.Status === 3 &&
									<div className="col col1_3 accept td">
										<h5><i></i>Подтверждено</h5>
										<div className="clearfix row">
											<span className="col col40 title">Пользователь</span>
											<span className="col col60">{writeOffState.WriteOff.AcceptUser}</span>
										</div>
										<div className="clearfix row">
											<span className="col col40 title">Дата подтверждения</span>
											<span className="col col60">{formatDate(writeOffState.WriteOff.AcceptDate)}</span>
										</div>
										{checkRights("AcceptWriteOff") &&
											<div className="clearfix row">
												<span className="col col40 title">Сумма списания</span>
												<span className="col col60">{getRubs(writeOffState.WriteOff.Amount, true, true)} руб.</span>
											</div>
										}
									</div>
								}
								</div>
							</div>
						}
						<GroupWriteOffBlock Products={this.props.Products} CancelReasons={this.props.CancelReasons} />
					</div>
				</form>
			</section>
		)
	}
}
WriteOffItem = observer(WriteOffItem);

class GroupWriteOffBlock extends Component {
	render() {
		var view = this;
		return(
			<div className="clearfix block">
				<ProductWriteOffGroup Products={view.props.Products} CancelReasons={view.props.CancelReasons}
					ProductsList={writeOffState.Products} />
			</div>
		)
	}
}
GroupWriteOffBlock = observer(GroupWriteOffBlock)

class ProductWriteOffGroup extends Component {
	render() {
		var view = this,
			types = { 1 : "Сырье", 2: "Полуфабрикаты", 3: "Готовая продукция" };
		
		return (
			<div className="clearfix writeoffList block">
				{/* {_.map(writeOffState.Products, function(typeProducts, type) { */}
				{_.map(this.props.ProductsList, function(typeProducts, type) {
					return(<div className="clearfix block" key={"type" + type}>
						<h4>{types[type]}</h4>
						{view.props.Type === "Request" &&
							<ProductWriteOffBlock key={"wt" + type} ReasonType="" 
									Products={_.filter(view.props.Products, { Type : parseInt(type, 10)})} 
									ProductsList={typeProducts} CancelReasons={view.props.CancelReasons}
									ProductType={type} Type={view.props.Type} />
						}
						{view.props.Type !== "Request" &&
							_.map(typeProducts, function (products, reason) {
								return(<ProductWriteOffBlock key={"wt" + reason} ReasonType={reason} 
									Products={_.filter(view.props.Products, { Type : parseInt(type, 10)})} 
									ProductsList={products} CancelReasons={view.props.CancelReasons}
									ProductType={type} Type={view.props.Type} />)
						})} 
					</div>)
				})}
				
			</div>
		)
	}
}
ProductWriteOffGroup = observer(ProductWriteOffGroup);

class ProductWriteOffBlock extends Component {
	handleAdd(event) {
		event.preventDefault();

		if (this.props.Type === "Request")
			writeOffState.RequestProducts[this.props.ProductType] = 
				_.concat(writeOffState.RequestProducts[this.props.ProductType], _.clone(writeOffState.ProductDummy));
		else 
			writeOffState.Products[this.props.ProductType][this.props.ReasonType] = 
				_.concat(writeOffState.Products[this.props.ProductType][this.props.ReasonType], _.clone(writeOffState.ProductDummy));
	}

	render() {
		var view = this,
			cancelReason = _.find(this.props.CancelReasons, { Id : parseInt(this.props.ReasonType, 10) });
		
		return (
			<div className="clearfix writeoffList block">
				{this.props.Type !== "Request" && <h5>{cancelReason ? cancelReason.Name : ""}</h5>}
				
				<div className="clearfix products">
					<ProductTitles Type={(this.props.Type === "Request" || writeOffState.WriteOff.Status !== 3) 
						? "edit" : "view"} />
					{_.map(this.props.ProductsList, function (product, i) {
						// console.log(product)
						if (view.props.Type === "Request" || writeOffState.WriteOff.Status !== 3) 
							return (<ProductEditItem key={"p" + writeOffState.pCounter++} Index={i} 
								Item={product} Type={view.props.Type} ReasonType={view.props.ReasonType}
								Products={view.props.Products} CancelReasons={view.props.CancelReasons}
								ProductType={view.props.ProductType} />)
						else return(<ProductViewItem key={"p" + writeOffState.pCounter++} Item={product}/>)
					})}
					{(this.props.Type === "Request" || writeOffState.WriteOff.Status !== 3)  &&
						<a href="/" className="icon button add" onClick={this.handleAdd.bind(this)}>
							<i></i>Добавить продукт</a>
					}
				</div>
			</div>
		)
	}
}
ProductWriteOffBlock = observer(ProductWriteOffBlock);

class ProductTitles extends Component {
	render() {
		return(
			<div className="clearfix titles">
				<span className="col col30">Продукт</span>
				<span className={this.props.Type === "edit" ? "col col10" : "col col20"}>Кол-во</span>
				{this.props.Type === "edit" && <span className="col col10">ЕИ</span> }
				<span className="col col20">Причина списания</span>
				<span className="col col20">Комментарий</span>
				{this.props.Type === "view" && writeOffState.WriteOff.Status === 3 && checkRights("AcceptWriteOff") &&
					<span className="col col10">Сумма</span>
				}
			</div>
		)
	}
}

class ProductEditItem extends Component {
	handleDelete(event) {
		event.preventDefault();

		var index = this.props.Index;

		if (this.props.Type === "Request")
			writeOffState.RequestProducts[this.props.ProductType] = 
				_.filter(writeOffState.RequestProducts[this.props.ProductType], function (v, i) { return i !== index; });
		else
			writeOffState.Products[this.props.ProductType][this.props.ReasonType] = 
				_.filter(writeOffState.Products[this.props.ProductType][this.props.ReasonType], 
					function (v, i) { return i !== index; });
	}

	handleChange(value, id) {
		var product = undefined;
		if (/ProductId/.test(id)) product = _.find(this.props.Products, { Id: value });
		
		if (this.props.Type === "Request")
			writeOffState.collectRequestProducts(id, value, this.props.Index, this.props.ProductType,product);
		else writeOffState.collectProducts(id, value, this.props.Index, this.props.ReasonType, this.props.ProductType, product);
	}

	render() {
		// console.log(this.props.Item)
		return(
			<div className="clearfix item">
				<Selectize
					Id="ProductId"
					List={toSelectList(this.props.Products)}
					RowClass="col col30"
					Value={this.props.Item.ProductId}
					onChange={this.handleChange.bind(this)}
				/>
				<TextInput
					Id="Count"
					Type="number"
					Step="0.001"
					RowClass="col col10"
					Value={this.props.Item.Count}
					Required={this.props.Item.ProductId !== "" && this.props.Item.ProductId !== -1}
					onChange={this.handleChange.bind(this)}
				/>
				<TextInput
					Id="UnitName"
					RowClass="col col10"
					Value={this.props.Item.UnitName}
					Disabled={true}
				/>
				<Selectize
					Id="CancelReasonId"
					List={toSelectList(this.props.CancelReasons)}
					RowClass="col col20"
					Value={this.props.Item.CancelReasonId}
					onChange={this.handleChange.bind(this)}
				/>
				<TextInput
					Id="Description"
					RowClass="col col20"
					Value={this.props.Item.Description}
					onChange={this.handleChange.bind(this)}
				/>
				{this.props.Item.New && <span className="col col10 new">Новое!</span>}
				<a href="/" className="button icon remove" onClick={this.handleDelete.bind(this)}>
						<i></i></a>
			</div>
		)
	}
}
ProductEditItem = observer(ProductEditItem);

class ProductViewItem extends Component {
	render() {
		return(
			<div className="clearfix item">
				<span className="col col30">{this.props.Item.Product}</span>
				<span className="col col20">{this.props.Item.Count} {this.props.Item.UnitName}</span>
				<span className="col col20">{this.props.Item.CancelReason}</span>
				<span className="col col20">{this.props.Item.Description}</span>
				{checkRights("AcceptWriteOff") &&
					<span className="col col10">{getRubs(this.props.Item.Amount, true, true)}</span>
				}
			</div>
		)
	}
}