import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _, { replace } from 'lodash';

import pageLoader from '../images/loader_big.svg';
import '../styles/order.css';

import 'react-virtualized/styles.css';
import List from 'react-virtualized/dist/commonjs/List';
import { CellMeasurer, CellMeasurerCache } from 'react-virtualized';

import Parser from 'html-react-parser';
import ReactToPrint from 'react-to-print';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

import moment from 'moment';
import 'moment/locale/ru';

import { checkRights, toSelectList, getRequest, toCheckList, formatDate, getRubs, setLabel, parsePhoneMask,
	getHourTime, convertHex, getLocalShift, addressToPatternString, getCPFC } from '../functions';
import { buttonTitles, OrderTimeTitles, OrderAggTitles, OrderLabels, OrderReceivingTitles, PersonLabels,
	PrintFormatesTitles, TimeListTitles, HomeOrdersTitles, errors, POSTypesTitles } from '../dictionary';

import { TextInput, Selectize, CheckGroup, TagsPreset, ButtonGroup, DateInput, SpinnerGroup, 
	AutoCompletePhone, SelectInput, SubmitButton } from '../forms/FormItems';

import { uistate, confirmState, authUserState, errorState } from '../stores/common';
import { ItemHeader } from './PageTemplates';

import { orderState } from '../stores/orders';
import { CustomerStatCommon, CustomerOrderHistory, MainCustomerInfo, AddressBlock, CustomerOrderStat,
	CustomerBrandsStat } from './Customers';
import { BillPaperItem, BillTapeItem } from './Bills';
import { animateScroll as scroll, scroller } from "react-scroll";

const cache = new CellMeasurerCache({
	defaultHeight: 155,
	fixedWidth: true
});

export class OrdersPage extends Component {
	constructor(props) {
		super(props);
		console.log(orderState.OrderInterval)

		orderState.setFilter();


		this.state = {}
		this.getData();
		this.getPayments();
		this.getAggregators();

		var model = this;
		getRequest("Marks", { AllMerchants: true }, function (data) { model.setModel(data, "Marks"); });
		getRequest("CancelReasons", { AllMerchants: true }, function (data) {
			orderState.CancelReasons = data.Success ? _.filter(data.CancelReasons, { Type: "Order" }) : [];
		});
		getRequest("Brands", { AllMerchants: true }, function (data) {
			orderState.Brands = data.Success ? data.Brands : [];
		});
		getRequest("OrderStatuses", {}, function (data) {
			orderState.OrderStatuses = data.Success ? data.OrderStatuses : [];
		});
		getRequest("Couriers", { Active: true }, function (data) {
			model.setModel(data, "Users");
			orderState.AllCouriers = data.Success ? data.Users : [];
		});

		if (_.isUndefined(orderState.OrderInterval)) 
			orderState.OrderInterval = setInterval(function () {
				if (!orderState.UserAction) model.getData();
			}, 60000)
	}


	/**
	 * Установка состояний модели
	 * @param {object} data ответ от сервера
	 * @param {string} param название нужного параметра, содержащего массив с данными
	 */
	setModel(data, param) { this.setState({ [param]: data.Success ? data[param] : [] }); }

	/** API запрос на получение списка заказов */
	getData() {
		var params = orderState.getFilterData();

		getRequest("Orders", params, function (data) { orderState.setOrders(data); });
		this.getCouriers();
	}

	/** API запрос на получение доступных курьеров */
	getCouriers() {
		getRequest("CouriersShift", { FilialId: authUserState.Shift.FilialId }, function (data) {
			orderState.AvailableCouriers = data.Success ?
				_.concat([{ Id: -1, Name: "Не назначен" }], data.Users) : [];
		});
	}

	/**
	 * Выбор/сброс редактируемого заказа
	 * @param {int|undefined} id ID заказа или -1 (новый) или undefined (сброс выбора)
	 */
	setId(id) {
		if (!_.isUndefined(id)) {
			if (id === -1) orderState.setOrder({ Id: -1 });
			else this.getItem(id);
			orderState.UserAction = true;
		} else {
			orderState.setOrder(undefined);
			orderState.UserAction = false;
			this.getData();
		}
	}

	/**
	 * API запрос на получение заказа по ID
	 * @param {number} id ID заказа
	 */
	getItem(id) {
		getRequest("Order", { Id: id }, function (data) { orderState.setOrder(data.Success ? data : undefined) });
	}

	/** API запрос на получение списка способов оплаты конкретного мерчанта */
	getPayments() {
		var model = this,
			merchant = orderState.Filter.MerchantId;

		if (merchant !== -1) getRequest("PaymentMethods", { MerchantId: merchant }, function (data) {
			model.setState({ PaymentMethods: data.Success ? data.PaymentMethods : [] });
		});
		else this.setState({ PaymentMethods: [] });
	}

	/** API запрос на получение списка агрегаторов конкретного мерчанта */
	getAggregators() {
		if (orderState.Filter.MerchantId !== -1)
			getRequest("Aggregators", { MerchantId: orderState.Filter.MerchantId },
				function (data) { orderState.Aggregators = data.Success ? data.Aggregators : []; });
		else orderState.Aggregators = [];
	}

	/**
	 * Сбор данных с формы поиска заказов
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleFilterChange(value, id) {
		if (/MerchantId/.test(id)) {
			orderState.collectFilter(id, value);

			orderState.Filter.PaymentMethodId = -1;
			orderState.AddFilter.AggregatorId = -1;

			this.getData();
		} else if (/Time/.test(id)) {
			if (_.indexOf(orderState.AddFilter.FilterTime, value) !== -1)
				orderState.AddFilter.FilterTime = _.without(orderState.AddFilter.FilterTime, value);
			else orderState.AddFilter.FilterTime = _.concat(orderState.AddFilter.FilterTime, value);
		} else if (/OrderNumber/.test(id)) orderState.AddFilter[id] = value;
		else if (/CustomerName/.test(id)) orderState.AddFilter[id] = _.toLower(value);
		else if (/CustomerAddress/.test(id)) orderState.AddFilter[id] = _.toLower(value);
		else if (/CourierId/.test(id)) orderState.AddFilter[id] = value;
		else if (/CustomerLogin/.test(id))
			orderState.AddFilter.CustomerLogin = value.replace(/\D/gi, "");
		else if (/AggregatorId/.test(id)) orderState.AddFilter[id] = value;
		else {
			orderState.collectFilter(id, value);
			this.getData();
		}

		if (/MerchantId/.test(id)) {
			this.getPayments();
			this.getAggregators();
		}
	}

	render() {
		var canOrder = checkRights("ManageOrders") &&
			(checkRights("CanViewOrdersWithoutShift") || authUserState.Shift.Active);

		return (
			<div className={"page " + (uistate.IsMobile ? "mobile" : "")} id="OrdersPage">
				{_.isUndefined(orderState.Order) &&
					<section className="listpage">
						<div className="head clearfix">
							<OrdersHeader />
							{canOrder && checkRights("CanCreateOrder") && !uistate.IsMobile &&
								<button className="add" onClick={(e) => { this.setId(-1) }}>
									{buttonTitles[this.props.Type] || "Добавить"}</button>
							}
							{canOrder && !uistate.IsMobile &&
								<AddFilterBlock handleChange={this.handleFilterChange.bind(this)} />
							}
						</div>
						{canOrder && uistate.IsMobile &&
							<MobileAddFilterBlock
								Type={this.props.Type}

								getData={this.getData.bind(this)}
								setId={this.setId.bind(this)}
								handleChange={this.handleFilterChange.bind(this)}
							/>
						}
						{canOrder &&
							<FilterBlock
								Couriers={this.state.Users}
								PaymentMethods={this.state.PaymentMethods}

								getData={this.getData.bind(this)}
								handleChange={this.handleFilterChange.bind(this)}
							/>
						}
						{canOrder && !_.isEmpty(orderState.Orders) && orderState.IsSendRequest &&
							<OrdersTable
								Marks={this.state.Marks}
								getData={this.getData.bind(this)}
								setId={this.setId.bind(this)}
							/>
						}
						{!canOrder && <p className="noSearch">{errors.SHIFT_CLOSE}</p>}
						{canOrder && _.isEmpty(orderState.Orders) && orderState.IsSendRequest &&
							<p className="noSearch">{errors.EMPTY_RESPONSE}</p>
						}
					</section>
				}
				{!_.isUndefined(orderState.Order) &&
					<OrderItem
						Marks={this.state.Marks}
						getItem={this.getItem.bind(this)}
						setId={this.setId.bind(this)}
					/>
				}
				{!_.isUndefined(orderState.RemoveOrder) &&
					<RemoveOrderBlock getData={this.getData.bind(this)} />
				}
				{!_.isUndefined(orderState.ChangeOrderCustomer) && <ChangeCustomer />}
				{!_.isUndefined(orderState.PaymentOrder) &&
					<PaymentOrderBlock
						PaymentMethods={this.state.PaymentMethods}
						getData={this.getData.bind(this)}
					/>
				}
				{!_.isUndefined(orderState.Customer) && orderState.ShowCustomerCard &&
					<CustomerBlock InOrder={!_.isUndefined(orderState.Order)} />
				}
				{!_.isUndefined(orderState.QRCode) && <QRCodeOrderBlock />}
				{!_.isUndefined(orderState.SaleContent) && <SalesBlock />}
				{!_.isUndefined(orderState.OrderHistory) && <OrderHistoryBlock />}
			</div>
		)
	}
}
OrdersPage = observer(OrdersPage);

class OrdersHeader extends Component {
	render() {
		var canOrder = checkRights("ManageOrders") &&
			(checkRights("CanViewOrdersWithoutShift") || authUserState.Shift.Active),
			stat = orderState.orderStat;

		if (!_.isEmpty(stat) && canOrder)
			return (
				<h2 className="stat">
					<span>Заказы <b>{stat.Count}</b></span>
					<span>Средний чек <b>{Parser(getRubs(stat.Avg, true, true, "&nbsp;", null, true))}</b></span>
					<span>Выручка <b>{Parser(getRubs(stat.Amount, true, true, "&nbsp;", null, true))}</b></span>
				</h2>
			)
		else return <h2>Заказы</h2>
	}
}
OrdersHeader = observer(OrdersHeader);

class RemoveOrderBlock extends Component {
	/**
	 * Сбор данных с формы отказа/списания заказа
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange(value, id) { orderState.collectRemoveOrder(id, value); }

	/** Отмена действия отказа/списания заказа */
	onCancel(event) {
		event.preventDefault();
		orderState.setRemoveOrder(undefined);
	}

	/** API запрос «ChangeOrderStatus» на смену статуса отказа/списания */
	onConfirm(event) {
		event.preventDefault();
		var model = this;
		getRequest("ChangeOrderStatus", orderState.getRemoveData(), function (data) {
			orderState.setRemoveOrder(undefined);

			if (data.Success) model.props.getData();
			else errorState.setError(data.ErrorCode);
		});
	}

	render() {
		var order = orderState.RemoveOrder,
			products = _.map(order.Products, function (v) { return { Id: v.ProductId, Name: v.ProductName } }),
			cancelList = _.filter(orderState.CancelReasons, { MerchantId: order.MerchantId });

		return (
			<div className="back">
				<div className="confirmBlock window writeoff medium">
					<form>
						<h4>{order.RejectMode ? "Отказ от" : "Списание"} заказа №{order.OrderNumber}</h4>
						<CheckGroup
							Id="CancelReasonId"
							Type="radio"
							ParseType="number"
							Title={"Укажите причину " + (order.RejectMode ? "отказа" : "списания")}
							List={toCheckList(cancelList)}
							Value={[order.CancelReasonId]}
							onChange={this.handleChange.bind(this)}
						/>
						{!order.RejectMode &&
							<CheckGroup
								Id="ReleasedProducts"
								Type="checkbox"
								Title="Отметьте произведенные продукты для списания"
								List={toCheckList(products)}
								Value={order.ReleasedProducts}
								onChange={this.handleChange.bind(this)}
							/>
						}
						<div className="buttons clearfix">
							<a href="/" onClick={this.onConfirm.bind(this)} className="button blue">Подтвердить</a>
							<a href="/" onClick={this.onCancel.bind(this)} className="button gray">Отменить</a>
						</div>
					</form>
				</div>
			</div>
		)
	}
}
RemoveOrderBlock = observer(RemoveOrderBlock);

class PaymentOrderBlock extends Component {
	constructor(props) {
		super(props);

		this.state = { IsConfirm: false };
	}

	/**
	 * Подтверждение операции при отличающихся смене польователя и смене кассы
	 * @param {boolean} value подтверждение операции
	 */
	handleChange(value) { this.setState({ IsConfirm: value }); }

	/** Закрытие окна оплаты */
	onCancel(event) {
		event.preventDefault();
		orderState.setPaymentOrder(undefined);
		orderState.UserAction = false;
	}

	/** Проведение оплаты/возврата */
	onConfirm(event) {
		event.preventDefault();

		orderState.PaymentOrder.ButtonLoading = true;
		this.setPayment(orderState.PaymentOrder.Type === 1 ? "payment" : "refund");
	}

	/** Обработка нажатия на альтернативный способ оплаты */
	onChangeMethod(event) {
		event.preventDefault();
		var id = parseInt(event.currentTarget.getAttribute("rel"), 10);
		this.changePaymentMethod(id);
	}

	/** API запрос «PaymentOrder»/«RefundPaidOrder» на оплату/возврат заказа */
	setPayment(type) {
		var request = type === "payment" ? "PaymentOrder" : type === "refund" ? "RefundPaidOrder" : "",
			params = type === "payment" ? { Id: orderState.PaymentOrder.Id } :
				{ OrderId: orderState.PaymentOrder.Id },
			order = _.find(orderState.Orders, { Id: orderState.PaymentOrder.Id }),
			isPaymentFiscal = type === "payment" && _.indexOf([1, 3], orderState.IsFiscal) !== -1,
			isRefundFiscal = type === "refund" && _.indexOf([3, 5], orderState.IsFiscal) !== -1;

		getRequest(request, params, function (data) {
			if (data.Success)
				getRequest("Order", { Id: order.Id }, function (data) {
					order.Paid = data.Success ? data.Paid : order.Paid;
					order.IsFiscal = data.Success ? data.IsFiscal : order.IsFiscal;
					order.CashInHand = data.Success ? data.CashInHand : order.CashInHand;
				});
			else {
				errorState.setError(data.ErrorCode, null, data.ErrorMessage);
				order.IsFiscal = isPaymentFiscal ? 3 : isRefundFiscal ? 5 : order.IsFiscal;
			}

			orderState.setPaymentOrder(undefined);
			orderState.UserAction = false;
		});
	}

	/**
	 * API запрос «ChangeOrderPaymentType» на изменение способа оплаты
	 * @param {number} id выбранный способ оплаты
	 */
	changePaymentMethod(id) {
		var model = this,
			order = _.find(orderState.Orders, { Id: orderState.PaymentOrder.Id }),
			params = orderState.getPaymentMethodData(order, id);

		orderState.PaymentOrder.ButtonLoading = true;

		getRequest("ChangeOrderPaymentType", params, function (data) {
			if (data.Success)
				getRequest("Order", { Id: order.Id }, function (data) {
					order.PaymentMethodId = data.Success ? data.PaymentMethodId : order.PaymentMethodId;
					order.POSId = data.Success ? data.POSId : order.POSId;
					order.IsFiscal = data.Success ? data.IsFiscal : order.IsFiscal;

					model.setPayment("payment");
				});
			else {
				orderState.setPaymentOrder(undefined);
				errorState.setError(data.ErrorCode);
				orderState.UserAction = false;
			}
		});
	}

	render() {
		var order = orderState.PaymentOrder || {},
			types = { 1: "оплату заказа", 2: "отмену оплаты заказа", 3: "возврат по заказу" },
			cassa = _.find(authUserState.CassaShifts, { POSId: order.POSId }),
			cassaMethod = order.IsCassa || order.IsFiscal > 0,
			sameShift = cassa && authUserState.Shift.Active ? authUserState.Shift.Date
				=== cassa.CassaShiftDate : true,
			posMethods = orderState.getMethodsForPOS(order.FilialId, order.POSId),
			text = "Я подтверждаю, что провожу операцию по кассе в другую кассовую смену",
			canConfirm = sameShift || !cassaMethod || (!sameShift && cassaMethod && this.state.IsConfirm),
			currentMethod = _.find(posMethods, { Id: order.PaymentMethodId }),
			currentIcon = currentMethod.ShowCassa ? "icon icon_1" : currentMethod.Fiscal ? "icon icon_2" : "",
			methods = _.reject(_.clone(posMethods), { Id: order.PaymentMethodId }),
			addMethod = !currentMethod.Fiscal ? undefined : _.find(methods, { Fiscal: true }),
			addIcon = !addMethod ? "" : addMethod.ShowCassa ? "icon icon_1" : addMethod.Fiscal ? "icon icon_2" : "";

		methods = _.filter(methods, function (v) {
			var isNoFiscal = !v.Fiscal ? checkRights("CanSetOrderPayed") : true,
				isFiscal = v.Fiscal ? checkRights("CanFiscalOrderPayed") : true,
				rejectId = addMethod ? addMethod.Id : -1;

			return isNoFiscal && isFiscal && v.Id !== rejectId;
		});

		return (
			<div className="back">
				<div className="confirmBlock window paymentconfirm refund medium">
					<form>
						<a href="/" onClick={this.onCancel.bind(this)} className="icon close"><i></i></a>
						<p>Вы действительно хотите провести {types[order.Type]}&nbsp;
							{order.Type === 1 ? <b>«{order.PaymentMethod}»</b> : ""}&nbsp;
							№{order.OrderNumber} в точке продаж <b>«{order.POS}»</b>?</p>
						{order.IsFiscal > 0 &&
							<p className="comment">Фискальная операция может занять некоторое время.
								Пожалуйста подождите.</p>
						}
						{!sameShift && cassaMethod &&
							<p className="comment alert">
								<b>Внимание!</b> Ваша смена открыта <b>{formatDate(authUserState.Shift.Date)}</b>,
								а смена в кассе открыта за <b>{formatDate(cassa.CassaShiftDate)}</b>
							</p>
						}
						{!sameShift && cassaMethod &&
							<CheckGroup
								Id="Confirm"
								Type="checkbox"
								List={toCheckList([{ Id: "true", Name: text }])}
								Value={this.state.IsConfirm ? ["true"] : []}
								onChange={this.handleChange.bind(this)}
							/>
						}
						{order.Type === 1 &&
							<div className="buttons clearfix">
								<button className={"button green " + currentIcon} disabled={!canConfirm}
									onClick={this.onConfirm.bind(this)}>
									{currentIcon !== "" && <i></i>}{order.PaymentMethod}</button>
								{!_.isUndefined(addMethod) &&
									<button className={"button blue add right " + addIcon}
										rel={addMethod.Id} onClick={this.onChangeMethod.bind(this)}
										disabled={!canConfirm}><i></i>{addMethod.Name}</button>
								}
								<Selectize
									Id="PaymentMethodId"
									Title="Или пользователь выбрал другой метод оплаты?"
									Value={order.PaymentMethodId}
									List={toSelectList(methods)}
									RowClass={_.isUndefined(addMethod) ? "col col50 right nomargin"
										: "separated"}
									onChange={this.changePaymentMethod.bind(this)}
								/>
							</div>
						}
						{order.Type !== 1 &&
							<div className="buttons clearfix">
								<button className="button blue" disabled={!canConfirm}
									onClick={this.onConfirm.bind(this)}>Подтвердить</button>
							</div>
						}
					</form>
					{orderState.PaymentOrder.ButtonLoading &&
						<div className="loading"><img src={pageLoader} alt="logo" /></div>
					}
				</div>
			</div>
		)
	}
}
PaymentOrderBlock = observer(PaymentOrderBlock);

class AddFilterBlock extends Component {
	render() {
		return (
			<div className="filter form">
				{checkRights("CanViewHomeOrders") &&
					<ButtonGroup
						Id="IsHome"
						List={HomeOrdersTitles}
						RowClass="nomargin home"
						Value={orderState.Filter.IsHome ? 2 : 1}
						onChange={this.props.handleChange}
					/>
				}
				<TagsPreset
					Id="Time"
					List={TimeListTitles}
					RowClass="nomargin time"
					Value={orderState.AddFilter.FilterTime}
					onChange={this.props.handleChange}
				/>
			</div>
		)
	}
}
AddFilterBlock = observer(AddFilterBlock);

class MobileAddFilterBlock extends Component {
	constructor(props) {
		super(props);

		this.state = { OpenDates: false }
	}

	/** Показ/скрытие окна с выбором фильтра по датам */
	toggleDates(event) {
		event.preventDefault();
		this.setState({ OpenDates: !this.state.OpenDates });
	}

	render() {
		var canNewOrder = checkRights("ManageOrders") && checkRights("CanCreateOrder") &&
			(checkRights("CanViewOrdersWithoutShift") || authUserState.Shift.Active);

		return (
			<div className="addFilter form clearfix">
				{canNewOrder &&
					<button className="add" onClick={(e) => { this.props.setId(-1) }}>
						{buttonTitles[this.props.Type] || "Добавить"}</button>
				}
				<a href="/" className={"icon button date " + (this.state.OpenDates ? "active" : "")}
					onClick={this.toggleDates.bind(this)}><i></i></a>
				<TagsPreset
					Id="Time"
					List={TimeListTitles}
					RowClass="nomargin time"
					Value={orderState.AddFilter.FilterTime}
					onChange={this.props.handleChange}
				/>

				{this.state.OpenDates &&
					<div className="clearfix dates">
						<ButtonGroup
							Id="ShiftDate"
							Value={orderState.Filter.ShiftDate}
							RowClass="nomargin"
							Type="date"
							List={orderState.getDateList(false, false)}
							onChange={this.props.handleChange}
						/>
						{checkRights("CanViewHistoryOrder") &&
							<PeriodFilterBlock getData={this.props.getData} />
						}
					</div>
				}
			</div>
		)
	}
}
AddFilterBlock = observer(AddFilterBlock);
class FilterBlock extends Component {
	constructor(props) {
		super(props);

		this.state = { ShowDates: false }
	}

	/**
	 * Сбор данных с формы поиска заказов по дате
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleDateChange(value, id) {
		if (value === "period") {
			this.setState({ ShowDates: value === "period" });
			orderState.collectFilter(id, value);
		} else if (this.props.handleChange) this.props.handleChange(value, id);
	}

	/** Скрыть окно с указанием периода */
	сlosePeriod(event) {
		event.preventDefault();
		this.setState({ ShowDates: false });
	}

	/** Показать/скрыть фильтр на мобильных устройствах */
	toggleFilter(event) {
		event.preventDefault();
		orderState.ShowAddFilter = !orderState.ShowAddFilter
	}

	render() {
		var posTypesList = checkRights("CanViewHomeOrders") ? POSTypesTitles : _.reject(POSTypesTitles, { Id: 4 });

		return (
			<form className={"clearfix filter " + (uistate.IsMobile ? "" : "fixed")}>
				{uistate.IsMobile &&
					<Selectize
						Id="FilialId"
						Placeholder="Филиал..."
						RowClass="nomargin col col15"
						List={toSelectList(orderState.filialsFilterList)}
						Value={orderState.Filter.FilialId}
						onChange={this.props.handleChange}
					/>
				}
				{uistate.IsMobile &&
					<Selectize
						Id="Status"
						Placeholder="Статус заказа..."
						List={orderState.statusFilterList}
						Type="OrderStatus"
						RowClass="nomargin status"
						Value={orderState.Filter.Status}
						isSearchable={false}
						onChange={this.props.handleChange}
					/>
				}
				{(!uistate.IsMobile || orderState.ShowAddFilter) &&
					<div className="clearfix hideFilter">
						{uistate.IsMobile &&
							<Selectize
								Id="IsHome"
								List={toSelectList(HomeOrdersTitles)}
								Value={orderState.Filter.IsHome ? 2 : 1}
								RowClass="nomargin"
								isClearable={false}
								isSearchable={false}
								onChange={this.props.handleChange}
							/>
						}
						{!uistate.IsMobile &&
							<Selectize
								Id="ShiftDate"
								Placeholder="Дата..."
								List={toSelectList(orderState.getDateList(true, true))}
								Value={orderState.Filter.ShiftDate}
								RowClass="nomargin col col15"
								isClearable={false}
								isSearchable={false}
								onChange={this.handleDateChange.bind(this)}
							/>
						}
						{!uistate.IsMobile && checkRights("CanViewHistoryOrder") && this.state.ShowDates &&
							<PeriodFilterBlock
								сlosePeriod={this.сlosePeriod.bind(this)}
								getData={this.props.getData}
							/>
						}
						{authUserState.MerchantType === 2 &&
							<Selectize
								Id="MerchantId"
								Placeholder="Предприятие..."
								List={toSelectList(orderState.merchantsFilterList)}
								Value={orderState.Filter.MerchantId}
								RowClass="col col10 nomargin"
								onChange={this.props.handleChange}
							/>
						}
						{authUserState.MerchantType !== 2 && !uistate.IsMobile &&
							<span className="col col10"></span>
						}
						<Selectize
							Id="OrderType"
							Placeholder="Тип заказа..."
							List={toSelectList(posTypesList)}
							Value={orderState.Filter.OrderType}
							RowClass="col col10 nomargin"
							onChange={this.props.handleChange}
						/>
						{orderState.Filter.MerchantId !== -1 &&
							<Selectize
								Id="PaymentMethodId"
								Placeholder="Тип оплаты..."
								RowClass="nomargin col col10"
								List={toSelectList(this.props.PaymentMethods)}
								Value={orderState.Filter.PaymentMethodId}
								onChange={this.props.handleChange}
							/>
						}
						{orderState.Filter.MerchantId === -1 && !uistate.IsMobile &&
							<span className="col payment"></span>
						}
						{!uistate.IsMobile &&
							<Selectize
								Id="FilialId"
								Placeholder="Филиал..."
								RowClass="nomargin col filial"
								List={toSelectList(orderState.filialsFilterList)}
								Value={orderState.Filter.FilialId}
								onChange={this.props.handleChange}
							/>
						}
						<Selectize
							Id="BrandId"
							Placeholder="Бренд..."
							List={toSelectList(orderState.brandsFilterList)}
							RowClass="nomargin col col10"
							Value={orderState.Filter.BrandId}
							onChange={this.props.handleChange}
						/>
						<Selectize
							Id="CourierId"
							Placeholder="Курьер..."
							List={toSelectList(orderState.couriersFilterList)}
							RowClass="nomargin col courier"
							Value={orderState.AddFilter.CourierId}
							onChange={this.props.handleChange}
						/>
						{!uistate.IsMobile &&
							<Selectize
								Id="Status"
								Placeholder="Статус заказа..."
								List={orderState.statusFilterList}
								RowClass="nomargin col status"
								Value={orderState.Filter.Status}
								isSearchable={false}
								Type="OrderStatus"
								onChange={this.props.handleChange}
							/>
						}
					</div>
				}
				<a href="/" className="toggleFilter" onClick={this.toggleFilter.bind(this)}>
					<i></i></a>
				{orderState.ShowAddFilter &&
					<div className="clearfix addDesktop">
						<TextInput
							Id="OrderNumber"
							Title="Номер заказа"
							RowClass="col col15"
							Type="number"
							Focused={true}
							Value={orderState.AddFilter.OrderNumber}
							onChange={this.props.handleChange}
						/>
						<TextInput
							Id="CustomerLogin"
							Title="Номер телефона"
							RowClass="col col15"
							Focused={true}
							Value={orderState.AddFilter.CustomerLogin}
							onChange={this.props.handleChange}
						/>
						<TextInput
							Id="CustomerName"
							Title="Имя клиента"
							RowClass="col col15"
							Focused={true}
							Value={orderState.AddFilter.CustomerName}
							onChange={this.props.handleChange}
						/>
						<TextInput
							Id="CustomerAddress"
							Title="Адрес"
							RowClass="col col20"
							Focused={true}
							Value={orderState.AddFilter.CustomerAddress}
							onChange={this.props.handleChange}
						/>
						<Selectize
							Id="AggregatorId"
							Title="Источник заказа"
							List={toSelectList(orderState.receivingFilterList)}
							RowClass="col col15"
							Value={orderState.AddFilter.AggregatorId}
							isSearchable={false}
							onChange={this.props.handleChange}
						/>
					</div>
				}
			</form>
		)
	}
}
FilterBlock = observer(FilterBlock);

class PeriodFilterBlock extends Component {
	/**
	 * Сбор данных с формы поиска по датам
	 * @param {*} value значение параметра
	 * @param {*} id название параметра
	 */
	handleChange(value, id) { orderState.collectFilter(id, value); }

	handleSubmit(event) {
		event.preventDefault();
		if (this.props.getData) this.props.getData();
	}

	render() {
		return (
			<div className="datePeriod clearfix">
				{!uistate.IsMobile && <h5>Период</h5>}
				{!uistate.IsMobile &&
					<a href="/" className="close" onClick={this.props.сlosePeriod}><i></i></a>
				}
				<DateInput
					Id="ShiftDateFrom"
					Title="Начальная дата"
					Value={orderState.Filter.ShiftDateFrom}
					WithTime={true}
					RowClass={uistate.IsMobile ? "" : "col col1_3"}
					onChange={this.handleChange.bind(this)}
				/>
				<DateInput
					Id="ShiftDateTo"
					Title="Конечная дата"
					Value={orderState.Filter.ShiftDateTo}
					RowClass={uistate.IsMobile ? "" : "col col1_3"}
					WithTime={true}
					onChange={this.handleChange.bind(this)}
				/>
				<a href="/" className={"button " + (!uistate.IsMobile ? "col col1_3" : "")}
					onClick={this.handleSubmit.bind(this)}>Найти</a>
			</div>
		)
	}
}
PeriodFilterBlock = observer(PeriodFilterBlock);


class OrdersTable extends Component {
	rowRenderer({ index, isScrolling, key, parent, style }) {
		return (
			<CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={index} >
				{({ measure, registerChild }) => (
					<div ref={registerChild} style={style}>
						<OrderTableItem
							Item={orderState.sortedOrders[index]}
							Marks={this.props.Marks}
							PaymentMethods={this.props.PaymentMethods}
							CanRepeatOrder={false}

							setId={this.props.setId}
							getData={this.props.getData}
						/>
					</div>
				)}
			</CellMeasurer>
		);
	}

	componentWillReceiveProps(nextProps) {
		// Some change in data occurred, I'll probably use Immutable.js here
		// if (this.props.list.length !== nextProps.list.length) {
		//   this.measure();
		//   this.list.recomputeRowHeights();
		// }
		// if (this.props.Data.length !== nextProps.list.length) {
		cache.clearAll();
		// this.virtualizedList && this.virtualizedList.recomputeRowHeights(); //We need to recompute the heights

		// }
	}

	render() {
		// TODO разъезжается блоки по высоте

		return (
			<div className="clearfix table ordersTable">
				<div className="clearfix tableBody">
					<List
						width={orderState.containerWidth}
						height={orderState.containerHeight}
						rowCount={orderState.sortedOrders.length}
						deferredMeasurementCache={cache}
						rowHeight={cache.rowHeight}
						rowRenderer={this.rowRenderer.bind(this)}
					/>
				</div>
			</div>
		)
	}
}
OrdersTable = observer(OrdersTable);
export class OrderTableItem extends Component {
	constructor(props) {
		super(props);

		this.state = { PaymentMethods: orderState.getMethodsForOrder(this.props.Item) }
	}

	/** API запрос «Customer» на получение клиента */
	getCustomer(event) {
		event.preventDefault();

		// TODO убрать запрет, когда сделаем карточку клиента в норм виде
		if (uistate.IsMobile) return false;

		getRequest("Customer", { Login: this.props.Item.CustomerLogin }, function (data) {
			orderState.setCustomer(data.Success ? data : undefined, true);
		});
	}

	/** Открытие окна списания заказа */
	removeOrder(event) {
		event.preventDefault();
		if (orderState.WriteOffStatusId === -1) return false;

		orderState.setRemoveOrder(this.props.Item, orderState.WriteOffStatusId, false);
	}

	/**
	 * API запрос «ChangeOrderPaymentType» на изменение способа оплаты
	 * @param {number} value выбранный способ оплаты
	 */
	changePaymentMethod(value) {
		var method = _.find(this.state.PaymentMethods, { Id: value }),
			order = _.find(orderState.Orders, { Id: this.props.Item.Id }),
			params = orderState.getPaymentMethodData(order, value),
			text = <span>Вы действительно хотите способ оплаты заказа
				&nbsp;<b key="b1">«№{this.props.Item.OrderNumber}»</b>
				&nbsp;на <b key="b2">«{method ? method.Name : ""}»</b>?</span>

		orderState.UserAction = true;
		confirmState.openConfirm(text, function () {
			getRequest("ChangeOrderPaymentType", params, function (data) {
				if (data.Success)
					getRequest("Order", { Id: order.Id }, function (data) {
						order.PaymentMethodId = data.Success ? data.PaymentMethodId : order.PaymentMethodId;
						order.IsFiscal = data.Success ? data.IsFiscal : order.IsFiscal;
						order.POSId = data.Success ? data.POSId : order.POSId;
					});
				else errorState.setError(data.ErrorCode);

				orderState.UserAction = false;
			});
		}, function () { orderState.UserAction = false; });
	}

	/** Открытие окна на проведение оплаты заказа */
	setPayment() { this.setPaymentOperation(1); }

	/** Открытие окна на проведение отмены оплаты/возврата заказа */
	setRefund() { this.setPaymentOperation(this.props.Item.IsFiscal === 0 ? 2 : 3); }

	/**
	 * Открытие окна с операциями оплаты
	 * @param {number} type тип платежной операции: 1 — оплата, 2 — отмена, 3 — возврат
	 */
	setPaymentOperation(type) {
		var method = _.find(this.state.PaymentMethods, { Id: this.props.Item.PaymentMethodId });
		orderState.setPaymentOrder(this.props.Item, type, method);
	}

	/** API запрос «SetOrderCashInHand» на забор денег у курьра */
	getCourierCash() {
		var item = this.props.Item,
			order = _.find(orderState.Orders, { Id: item.Id }),
			text = <span>Вы действительно хотите забрать деньги у курьера&nbsp;
				<b key="b1">«{item.CourierName}»</b> по заказу <b key="b2">№{item.OrderNumber}</b>?</span>

		orderState.UserAction = true;
		confirmState.openConfirm(text, function () {
			getRequest("SetOrderCashInHand", { OrderId: item.Id }, function (data) {
				if (data.Success)
					getRequest("Order", { Id: order.Id }, function (data) {
						order.CashInHand = data.Success ? data.CashInHand : order.CashInHand;
					});
				else errorState.setError(data.ErrorCode);
				orderState.UserAction = false;
			});
		}, function () { orderState.UserAction = false; });
	}

	/** 
	 * API запрос «ChangeOrderStatus» на изменение статуса заказа 
	 * @param {string} value выбранный статус
	 */
	changeStatus(value) {
		var model = this,
			order = _.find(orderState.Orders, { Id: this.props.Item.Id });

		if (value === orderState.RejectStatusId)
			orderState.setRemoveOrder(this.props.Item, orderState.RejectStatusId, true);
		else getRequest("ChangeOrderStatus", { Id: model.props.Item.Id, StatusId: value }, function (data) {
			if (data.Success) {
				if (data.ShouldUpdate) model.props.getData();
				else order = _.extend(order, { StatusId: value });
			} else errorState.setError(data.ErrorCode);
			orderState.UserAction = false;
		});
	}

	/**
	 * API запрос «SetCourierOrder» на привязку курьера к заказу
	 * @param {number} value ID курьера
	 */
	setCourier(value) {
		var order = _.find(orderState.Orders, { Id: this.props.Item.Id }),
			courier = _.find(orderState.AllCouriers, { Id: value });

		getRequest("SetCourierOrder", { CourierId: value, OrderId: this.props.Item.Id }, function (data) {
			if (data.Success) {
				order.CourierId = value;
				order.CourierLogin = courier ? courier.Login : "";
			} else errorState.setError(data.ErrorCode);
			orderState.UserAction = false;
		});
	}

	/** API запрос «OrderHistory» на получение истории изменения заказа */
	getHistory(event) {
		var bound = event.currentTarget.getBoundingClientRect(),
			user = this.props.Item.UserName;

		getRequest("OrderHistory", { Id: this.props.Item.Id }, function (data) {
			orderState.setOrderHistory(data.Success ? data.OrderHistory : undefined, bound, user);
		});
	}

	/** Повтор предыдущего заказа */
	repeatOrder() { orderState.repeatCustomerOrder(this.props.Item); }

	/**
	 * API запрос «ChangeOrderPriority» на изменение порядка заказа в списке
	 * @param {number} index новый порядковый номер заказа
	 */
	changeOrderPriority(index) {
		var model = this;

		orderState.UserAction = true;
		getRequest("ChangeOrderPriority", { OrderId: this.props.Item.Id, NewIndex: index },
			function () { model.props.getData(); });
	}

	/** API запрос «Order» для получения списка продуктов выполненного заказа */
	getOrderProducts() {
		var order = _.find(orderState.Orders, { Id: this.props.Item.Id });

		getRequest("Order", { Id: this.props.Item.Id }, function (data) {
			if (data.Success) order.Products = data.Products;
		});
	}

	render() {
		var item = this.props.Item,
			method = _.find(this.state.PaymentMethods, { Id: item.PaymentMethodId }),
			fiscal = _.isUndefined(method) ? false : method.Fiscal,
			cassa = _.isUndefined(method) ? false : method.ShowCassa,
			forCustomer = this.props.Type === "customer",
			status = _.find(orderState.OrderStatuses, { Id: item.StatusId }),
			statusColor = status ? status.Color : forCustomer ? item.StatusColor : "#FFFFFF",
			orderStatuses = orderState.getOrderStatuses(item.MerchantId, item.OrderTypeId, item.StatusId),
			isWriteOff = item.StatusId === orderState.WriteOffStatusId,
			isReject = item.StatusId === orderState.RejectStatusId,
			canWriteOff = orderState.WriteOffStatusId !== -1 && (orderState.RejectStatusId === -1
				|| isReject),
			shiftActive = authUserState.Shift.Active,
			shiftFilial = shiftActive ? authUserState.Shift.FilialId === item.FilialId : false,
			canPaid = shiftActive && !item.Paid &&
				((item.IsFiscal === 0 && !cassa && checkRights("CanSetOrderPayed"))
					|| ((_.indexOf([1, 3], item.IsFiscal) !== -1 || (cassa && item.IsFiscal === 0))
						&& checkRights("CanFiscalOrderPayed") && shiftFilial)),
			canRefund = shiftActive && item.Paid && shiftFilial && _.indexOf([2, 5], item.IsFiscal) !== -1 &&
				checkRights("CanRefundFiscalOrder") && item.CashInHand === 0,
			canCancel = shiftActive && item.Paid && (item.IsFiscal === 0 && checkRights("CanSetOrderPayed")),
			canChangePriority = orderState.IsOneFilial && checkRights("CanChangePriority")
				&& !item.IsFinalStatus,
			canChangeStatus = !item.IsFinalStatus || (item.IsFinalStatus &&
				item.ShiftDate === authUserState.Shift.Date);

		return (
			<div className="clearfix item tr form">
				{!uistate.IsMobile &&
					<OrderTableItemHead
						Item={this.props.Item}
						Type={this.props.Type}
						OrderStatuses={orderStatuses}
						Status={status}
						Style={{ background: statusColor }}
						IsWriteOff={isWriteOff}
						CanWriteOff={canWriteOff}
						CanChangeStatus={canChangeStatus}
						CanChangePriority={canChangePriority}
						CanRepeatOrder={this.props.CanRepeatOrder}

						setId={this.props.setId}
						getData={this.props.getData}
						changeStatus={this.changeStatus.bind(this)}
						setCourier={this.setCourier.bind(this)}
						getHistory={this.getHistory.bind(this)}
						repeatOrder={this.repeatOrder.bind(this)}
						changeOrderPriority={this.changeOrderPriority.bind(this)}
					/>
				}
				{uistate.IsMobile &&
					<OrderMobileTableItemHead
						Item={this.props.Item}
						Type={this.props.Type}
						OrderStatuses={orderStatuses}
						Status={status}
						Style={{ background: statusColor }}
						IsWriteOff={isWriteOff}
						CanPaid={canPaid}
						CanRefund={canRefund}
						CanCancel={canCancel}
						CanChangeStatus={canChangeStatus}

						Fiscal={fiscal}
						PaymentMethods={this.state.PaymentMethods}
						CanWriteOff={canWriteOff}

						setId={this.props.setId}
						getData={this.props.getData}
						changeStatus={this.changeStatus.bind(this)}
						setRefund={this.setRefund.bind(this)}
						setCourier={this.setCourier.bind(this)}
						setPayment={this.setPayment.bind(this)}
						changePaymentMethod={this.changePaymentMethod.bind(this)}
						removeOrder={this.removeOrder.bind(this)}
						getCourierCash={this.getCourierCash.bind(this)}
						getHistory={this.getHistory.bind(this)}
					/>
				}
				{uistate.IsMobile &&
					<OrderMobileTableItemBody
						Item={this.props.Item}
						Type={this.props.Type}
						Marks={this.props.Marks}
						Style={{ background: convertHex(statusColor, 20) }}

						getCustomer={this.getCustomer.bind(this)}
					/>
				}
				{!uistate.IsMobile &&
					<OrderTableItemBody
						Item={this.props.Item}
						Type={this.props.Type}
						PaymentMethods={this.state.PaymentMethods}
						Marks={this.props.Marks}
						CanWriteOff={canWriteOff}
						CanPaid={canPaid}
						CanRefund={canRefund}
						CanCancel={canCancel}
						Style={{ background: convertHex(statusColor, 20) }}

						setPayment={this.setPayment.bind(this)}
						setRefund={this.setRefund.bind(this)}
						changePaymentMethod={this.changePaymentMethod.bind(this)}
						getCustomer={this.getCustomer.bind(this)}
						removeOrder={this.removeOrder.bind(this)}
						getCourierCash={this.getCourierCash.bind(this)}
						getOrderProducts={this.getOrderProducts.bind(this)}
					/>
				}
			</div>
		)
	}
}
OrderTableItem = observer(OrderTableItem);

class OrderTableItemHead extends Component {
	/** Обработка клика на изменение заказа */
	handleClick(event) {
		event.preventDefault();
		this.props.setId(this.props.Item.Id);
	}

	/** Фокус на элементе */
	handleSelectFocus(id) { orderState.UserAction = true; }

	/** Обработка клика на изменение приоритета */
	handlePriority(event) {
		event.preventDefault();
		var type = event.currentTarget.getAttribute("rel"),
			index = _.findIndex(orderState.sortedOrders, { Id: this.props.Item.Id }),
			newIndex = type === "up" ? index - 1 : type === "down" ? index + 1 : index;

		if (this.props.changeOrderPriority && index !== newIndex)
			this.props.changeOrderPriority(newIndex);
	}

	render() {
		var item = this.props.Item,
			forCustomer = this.props.Type === "customer",
			reciving = _.find(OrderReceivingTitles, { Id: item.OrderReceivingType }),
			title = "№" + item.OrderNumber + " от " + formatDate(item.CreationTime, "HH:mm"),
			fiscal = _.indexOf([4, 5], item.IsFiscal) !== -1 ? "Возврат"
				: _.indexOf([2, 3], item.IsFiscal) !== -1 ? "Фискальный" : "",
			fiscalClass = _.indexOf([3, 5], item.IsFiscal) !== -1 ? "alert" : "",
			showPriorityCol = (checkRights("CanWriteOffOrder") || this.props.CanChangePriority) && !forCustomer,
			index = _.findIndex(orderState.sortedOrders, { Id: this.props.Item.Id }),
			isFirst = index === 0,
			isLast = index === orderState.sortedOrders.length - 1;

		return (
			<div className="head clearfix" style={this.props.Style}>
				<div className="col td products">
					<span className="col orderNumber" onClick={this.props.getHistory} title={title}>
						{Parser(formatDate(item.CreationTime, "<b>H:mm</b> DD.MM.YYYY "))} (№{item.OrderNumber})
					</span>
					{!forCustomer &&
						<button className={"button icon " + (item.IsFinalStatus ? "view" : "edit")}
							onClick={this.handleClick.bind(this)}><i></i></button>
					}
				</div>
				{forCustomer &&
					<div className="col amount td">
						{this.props.CanRepeatOrder && <button onClick={this.props.repeatOrder}>Повторить</button>}
					</div>
				}
				{!forCustomer && <OrderTablePrint Id={item.Id} />}
				<div className="col td price">
					{fiscal !== "" && <span className={"tag " + fiscalClass}>{fiscal}</span>}
				</div>
				<div className="col td filial">
					{reciving && checkRights("CanViewReceivingType") &&
						<div className="reciving">
							<h5>Канал</h5>
							<span className="tag">{reciving.Name}</span>
						</div>
					}
					{!forCustomer &&
						<div className="reference">
							<h5>Эталон</h5>
							<span className="tag">{item.ReferenceTime} м</span>
						</div>
					}
				</div>
				<OrderTableItemHeadTimes
					Item={item}
					Type={this.props.Type}
					setCourier={this.props.setCourier}
				/>
				{!forCustomer &&
					<div className="col col15 td customer">
						{!this.props.CanChangeStatus && <span className="tag">{item.StatusName}</span>}
						{this.props.CanChangeStatus &&
							<SelectInput
								Id="StatusId"
								Type="number"
								List={toSelectList(this.props.OrderStatuses)}
								Value={this.props.Item.StatusId}
								onChange={this.props.changeStatus}
								onFocus={this.handleSelectFocus.bind(this)}
							/>
						}
					</div>
				}
				{showPriorityCol && <div className="col td remove">
					{this.props.CanChangePriority && !isFirst &&
						<a href="/" className="priority up" onClick={this.handlePriority.bind(this)}
							title="Поднять приоритет выполнения заказа" rel="up"><i></i></a>
					}
					{this.props.CanChangePriority && !isLast &&
						<a href="/" className="priority down" onClick={this.handlePriority.bind(this)}
							title="Опустить приоритет выполнения заказа" rel="down"><i></i></a>
					}
				</div>
				}
			</div>
		)
	}
}
OrderTableItemHead = observer(OrderTableItemHead);

class OrderTableItemHeadTimes extends Component {
	constructor(props) {
		super(props);

		this.state = { OpenPhone: false }
	}

	/** Фокус на элементе */
	handleSelectFocus(id) { orderState.UserAction = true; }

	/** Показать/скрыть номер телефона курьера */
	togglePhone(event) {
		event.preventDefault();
		orderState.ShowCourier = orderState.ShowCourier === this.props.Item.Id ? -1 : this.props.Item.Id;
	}

	/** Скопировать номер курьера в буфер */
	copyPhone(event) {
		event.preventDefault();
		navigator.clipboard.writeText(this.props.Item.CourierLogin);
	}

	render() {
		var item = this.props.Item,
			forCustomer = this.props.Type === "customer",
			isFinal = item.IsFinalStatus,
			isDelivery = item.OrderTypeId === 3,
			sameShift = authUserState.Shift.FilialId === item.FilialId,
			canSelectCourier = !isFinal && sameShift && !forCustomer,
			courierPhone = this.props.Item.CourierLogin,
			showPhone = orderState.ShowCourier === item.Id,
			toTime = item.ToTime || item.MustCompletedTime !== "";

		return (
			<div className="col td time">
				<div className={"col times " + (forCustomer ? "col40" : "col55")}>
					{!isFinal && !forCustomer && !_.isNull(item.LeftCook) && authUserState.MerchantType !== 2 &&
						<div className="col">
							<h5>С кухни</h5>
							<span className="tag"><i className={"time " + item.LightCook}></i>{getHourTime(item.LeftCook)}</span>
						</div>
					}
					{!isFinal && !forCustomer && !_.isNull(item.LeftMinutes) &&
						<div className="col">
							<h5>Клиенту</h5>
							<span className="tag"><i className={"time " + item.Light}></i>{getHourTime(item.LeftMinutes)}</span>
						</div>
					}
					{isFinal && !_.isNull(item.CompleteMinutes) &&
						<div className="col">
							<h5>{this.props.IsWriteOff ? "Отменен через" : toTime ? "Выполнен к" : "Выполнен за"}</h5>
							{toTime && <span className="tag">{formatDate(item.LeadTime, "time")}</span>}
							{!toTime && <span className="tag">{item.CompleteMinutes} м</span>}
						</div>
					}
				</div>
				{isDelivery &&
					<div className={"col couriers " + (forCustomer ? "col60" : "col45")}>
						{!canSelectCourier && <span className="tag">{item.CourierName}</span>}
						{canSelectCourier &&
							<SelectInput
								Id="CourierId"
								Type="number"
								List={toSelectList(orderState.AvailableCouriers)}
								Placeholder="Курьер"
								Disabled={item.CashInHand > 0}
								RowClass="select"
								Value={item.CourierId}
								onChange={this.props.setCourier}
								onFocus={this.handleSelectFocus.bind(this)}
							/>
						}
						{item.CourierId > 0 && !forCustomer &&
							<a href="/" className={"icon info " + (showPhone ? "active" : "")}
								onClick={this.togglePhone.bind(this)}><i></i></a>
						}
						{showPhone &&
							<div className="orderActionsList phones">
								<span className="">{parsePhoneMask(courierPhone, "Россия")}</span>
								<a href="/" className="icon copy" onClick={this.copyPhone.bind(this)}><i></i></a>
							</div>
						}
					</div>
				}
			</div>
		)
	}
}
OrderTableItemHeadTimes = observer(OrderTableItemHeadTimes);

class OrderMobileTableItemHead extends Component {
	constructor(props) {
		super(props);

		this.state = { OpenMenu: false }
	}

	/** Открыть/закрыть меню опций */
	handleOpenMenu() { this.setState({ OpenMenu: !this.state.OpenMenu }); }

	/** Открытие мобильного заказа на редактирование */
	handleClick() { if (this.props.setId) this.props.setId(this.props.Item.Id); }

	/** Фокус на элементе */
	handleSelectFocus(id) { orderState.UserAction = true; }

	render() {
		var item = this.props.Item,
			forCustomer = this.props.Type === "customer",
			title = "№" + item.OrderNumber + " от " + formatDate(item.CreationTime, "HH:mm"),
			isFinal = item.IsFinalStatus,
			isDelivery = item.OrderTypeId === 3,
			sameShift = authUserState.Shift.FilialId === item.FilialId;

		return (
			<div className="head clearfix" style={this.props.Style}>
				<div className="col col100 td">
					<span className="col orderNumber" onClick={this.props.getHistory} title={title}>
						{Parser(formatDate(item.CreationTime, "<b>H:mm</b> DD.MM.YYYY "))} (№{item.OrderNumber})
					</span>

					<Selectize
						Id="StatusId"
						List={toSelectList(this.props.OrderStatuses)}
						Value={this.props.Item.StatusId}
						Disabled={!this.props.CanChangeStatus}
						RowClass="nomargin status"
						isClearable={false}
						isSearchable={false}
						onChange={this.props.changeStatus}
					/>
				</div>
				<div className="col td time col100">
					{!item.IsFinalStatus &&
						<div className="col">
							<h5>Эталон</h5>
							<span className="tag">{item.ReferenceTime} мин</span>
						</div>
					}
					{!item.IsFinalStatus && !_.isNull(item.LeftMinutes) &&
						<div className="col">
							<h5>{item.LeftMinutes > 0 ? "Осталось" : "Опаздывает на"}</h5>
							<span className="tag"><i className={"time " + item.Light}></i>{item.LeftMinutes} мин</span>
						</div>
					}
					{item.IsFinalStatus && !_.isNull(item.CompleteMinutes) &&
						<div className="col">
							<h5>{this.props.IsWriteOff ? "Отменен через" : "Выполнен за"}</h5>
							<span className="tag">{item.CompleteMinutes} мин</span>
						</div>
					}
					<button className="icon option col" onClick={this.handleOpenMenu.bind(this)}><i></i></button>
					{this.state.OpenMenu &&
						<div className="optionMenu">
							{isDelivery && !isFinal && sameShift && !forCustomer &&
								<div className="clearfix row">
									<SelectInput
										Id="CourierId"
										Type="number"
										List={toSelectList(orderState.AvailableCouriers)}
										Placeholder="Курьер"
										Disabled={item.CashInHand > 0}
										Value={item.CourierId}
										onChange={this.props.setCourier}
										onFocus={this.handleSelectFocus.bind(this)}
									/>
								</div>
							}
							<div className="clearfix row">
								<OrderTableItemPayment
									Item={this.props.Item}
									PaymentMethods={this.props.PaymentMethods}
									CanPaid={this.props.CanPaid}
									CanRefund={this.props.CanRefund}
									CanCancel={this.props.CanCancel}
									BlockClass="col100"

									changePaymentMethod={this.props.changePaymentMethod}
									setPayment={this.props.setPayment}
									getCourierCash={this.props.getCourierCash}
								/>
							</div>
							<div className="clearfix row buttons">
								<button className={"button col " + (this.props.CanWriteOff ? "col80" : "col100")}
									onClick={this.handleClick.bind(this)} disabled>
									{this.props.Item.IsFinalStatus ? "Посмотреть" : "Изменить"}
								</button>
								{this.props.CanWriteOff &&
									<a href="/" className="button icon remove col col20"
										onClick={this.props.removeOrder}><i></i></a>
								}
							</div>
						</div>

					}
				</div>
			</div>
		)
	}
}
OrderMobileTableItemHead = observer(OrderMobileTableItemHead);

class OrderTableItemBody extends Component {
	render() {
		var forCustomer = this.props.Type === "customer";

		return (
			<div className="body clearfix" style={this.props.Style}>
				<OrderTableItemProducts
					Products={this.props.Item.Products}
					Type={this.props.Type}
					getOrderProducts={this.props.getOrderProducts}
				/>
				<OrderTableItemAmount
					Item={this.props.Item}
					BlockClass="col10"
				/>
				<OrderTableItemPayment
					Item={this.props.Item}
					Type={this.props.Type}
					BlockClass="col10"
					CanPaid={this.props.CanPaid}
					CanRefund={this.props.CanRefund}
					CanCancel={this.props.CanCancel}

					changePaymentMethod={this.props.changePaymentMethod}
					setPayment={this.props.setPayment}
					setRefund={this.props.setRefund}
					getCourierCash={this.props.getCourierCash}
				/>
				<OrderTableItemFilial
					Item={this.props.Item}
					Type={this.props.Type}
				/>
				<OrderTableItemDescription
					Item={this.props.Item}
					Marks={this.props.Marks}
				/>
				{!forCustomer &&
					<OrderTableItemCustomer
						Item={this.props.Item}
						BlockClass="col15"
						getCustomer={this.props.getCustomer}
					/>
				}
				{checkRights("CanWriteOffOrder") && !forCustomer &&
					<div className="col td remove">
						{this.props.CanWriteOff && !this.props.Item.IsFinalStatus &&
							<a href="/" className="button icon remove" onClick={this.props.removeOrder}>
								<i></i></a>
						}
					</div>
				}
			</div>
		)
	}
}
OrderTableItemBody = observer(OrderTableItemBody);

class OrderTableItemProducts extends Component {
	/** Показать список продуктов выполненного заказа */
	toggleProducts(event) {
		event.preventDefault();
		this.props.getOrderProducts();
	}

	render() {
		var forCustomer = this.props.Type === "customer";

		return (
			<div className={"col products td " + (this.props.BlockClass ? this.props.BlockClass : "")}>
				{!_.isEmpty(this.props.Products) &&
					_.map(this.props.Products, function (product, i) {
						var ready = product.Released === product.Count;

						return (<div className={"clearfix " + (ready ? "ready" : "")} key={i}>
							<OrderTableItemProductItem Item={product} IsModificator={false} />
							{!_.isNull(product.Modifications) && _.map(product.Modifications, function (mod, k) {
								return (<OrderTableItemProductItem Item={mod} IsModificator={true} key={k} />)
							})}
						</div>)
					})}
				{_.isEmpty(this.props.Products) && !forCustomer &&
					<a href="/" className="toggle" onClick={this.toggleProducts.bind(this)}>
						Показать список продуктов</a>
				}
			</div>
		)
	}
}
class OrderTableItemProductItem extends Component {
	render() {
		var item = this.props.Item;

		return (
			<div className={"clearfix " + (this.props.IsModificator ? "mod" : "")}>
				<span className="col col70" title={item.ProductName}>{item.ProductName}</span>
				{!this.props.IsModificator && _.map(item.Prices, function (v, k) {
					return (<span className="col col30" key={k}>
						<span className="col col1_3">{v.Count}</span>
						<span className="col col2_3 price">
							{Parser(getRubs(v.Price, true, true, "&nbsp;", null, true))}
						</span>
					</span>)
				})}
				{this.props.IsModificator &&
					<span className="col col30">
						<span className="col col1_3">{item.Count}</span>
						<span className="col col2_3 price">
							{Parser(getRubs(item.Price, true, true, "&nbsp;", null, true))}
						</span>
					</span>
				}
			</div>
		)
	}
}

class OrderTableItemAmount extends Component {
	render() {
		var item = this.props.Item,
			fiscal = _.indexOf([4, 5], this.props.Item.IsFiscal) !== -1 ? "ФВ"
				: _.indexOf([2, 3], this.props.Item.IsFiscal) !== -1 ? "ФЧ" : "",
			fiscalClass = _.indexOf([3, 5], this.props.Item.IsFiscal) !== -1 ? "alert" : "",
			hasDiscount = item.Discount !== 0 && item.Discount !== "",
			hasChange = item.ChangeFrom !== 0 && item.ChangeFrom !== "";

		return (
			<div className={"col amount td " + (this.props.BlockClass ? this.props.BlockClass : "")}>
				<div className="clearfix">
					{uistate.IsMobile &&
						<div className="pay">
							<span className="tag">{item.PaymentMethodName}</span>
							{item.Paid && <span className="tag green">Оплачено</span>}
							{fiscal !== "" && <span className={"tag " + fiscalClass}>{fiscal}</span>}
						</div>
					}
					<h5>Сумма</h5>
					<p>{Parser(getRubs(item.Amount, true, true, "&nbsp;", null, true))}</p>
					{hasDiscount && <h5>Скидка</h5>}
					{hasDiscount && <p>{Parser(getRubs(item.Discount, true, true, "&nbsp;", null, true))}</p>}
					<h5>К оплате</h5>
					<p><b>{Parser(getRubs(item.TotalAmount, true, true, "&nbsp;", null, true))}</b></p>
					{hasChange && <h5>Сдача с</h5>}
					{hasChange && <p>{Parser(getRubs(item.ChangeFrom, true, true, "&nbsp;", null, true))}</p>}
				</div>

			</div>
		)
	}
}

class OrderTableItemDescription extends Component {
	render() {
		var item = this.props.Item,
			marks = _.filter(this.props.Marks, function (v) {
				return _.indexOf(item.Marks, v.Id) !== -1;
			}),
			pattern = "<b>{%%ss%, }%%SS%, %%HH%{ %%HB%}{, %%FF%}</b>{, п %%ee%}{, э %%ff%}{, домофон %%ii%}",
			address = !_.isEmpty(item.CustomerAddress) ? addressToPatternString(item.CustomerAddress, pattern)
				: item.Address,
			cancelReason = _.find(orderState.CancelReasons, { Id: item.CancelReasonId }),
			mustPattern = item.ShiftDate === authUserState.Shift.Date ? "к HH:mm" : "к HH:mm DD.MM",
			isDelivery = item.OrderTypeId === 3,
			orderType = _.find(POSTypesTitles, { Id: item.OrderTypeId });

		return (
			<div className={"col description td " + (this.props.BlockClass ? this.props.BlockClass : "")}>
				<p>
					<span className="tag green">{setLabel(item.PersonsNumber, PersonLabels)}</span>
					{!isDelivery && orderType && <span className="tag green">{orderType.Name}</span>}
					{item.MustCompletedTime !== "" &&
						<span className="tag red">{formatDate(item.MustCompletedTime, mustPattern)}</span>
					}
					{item.AggregatorId > 0 &&
						<span className="tag orange">{item.AggregatorName}</span>
					}
					{item.OrderTypeId === 1 && <span className="tag orange">Столик №{item.TableNumber}</span>}
				</p>
				{cancelReason && <p><span className="tag red">{cancelReason.Name}</span></p>}
				{isDelivery && <p>{Parser(address)}</p>}
				{isDelivery && item.DeliveryArea !== "" && <p>Зона доставки: {item.DeliveryArea}</p>}
				{item.ChangeFrom > 0 && <p><i>
					(Купюра: {getRubs(item.ChangeFrom)} руб., Сдача: {getRubs(item.ChangeFrom - item.TotalAmount)} руб.)
				</i></p>}
				<p>{_.map(marks, function (mark) {
					return (<span key={mark.Name} className="tag">{mark.Name}</span>)
				})}</p>
				{item.DescriptionAdmin !== "" && <h5>Комментарий для администратора</h5>}
				{item.DescriptionAdmin !== "" && <p>{item.DescriptionAdmin}</p>}

				{item.DescriptionCook !== "" && <h5>Комментарий для повара</h5>}
				{item.DescriptionCook !== "" && <p>{item.DescriptionCook}</p>}
			</div>
		)
	}
}

class OrderTableItemCustomer extends Component {
	render() {
		var item = this.props.Item,
			brand = item.CustomerBrandCount,
			orders = item.CustomerOrdersCount,
			countTitle = "Количество заказов в данном бренде — " + brand +
				". Общее кол-во заказов по всем брендам — " + orders,
			newOrder = "Нов. клиент",
			isCustomer = item.CustomerLogin !== "";

		if (isCustomer)
			return (
				<div className={"col customer td " + (this.props.BlockClass ? this.props.BlockClass : "")}>
					<h4><a href="/" onClick={this.props.getCustomer} title={"Информация о " +
						item.CustomerName}><i></i>{item.CustomerName}</a></h4>
					<h5>{parsePhoneMask(item.CustomerLogin, "Россия")}</h5>
					{((uistate.IsMobile && this.props.IsActive) || !uistate.IsMobile) &&
						<p>
							{brand <= 1 &&
								<span className="tag red" title={countTitle}>{newOrder} ({orders})</span>
							}
							{brand > 1 &&
								<span className="tag yellow" title={countTitle}>
									{setLabel(brand, OrderLabels)} ({orders})
								</span>
							}
						</p>
					}
				</div>
			)
		else if (!isCustomer && !uistate.IsMobile)
			return (
				<div className="col customer td"><span className="tag red">Без клиента</span></div>
			)
		else return null;
	}
}

class OrderTableItemFilial extends Component {
	render() {
		var forCustomer = this.props.Type === "customer",
			item = this.props.Item,
			filial = _.find(authUserState.OrderFilials, { Id: item.FilialId }),
			pos = filial ? _.find(filial.POS, { Id: item.POSId }) : undefined,
			brand = _.find(orderState.Brands, { Id: item.BrandId });

		return (
			<div className={"col filial td " + (this.props.BlockClass ? this.props.BlockClass : "")}>
				<h5>Филиал</h5>
				<p>{this.props.Item.FilialName}</p>
				<h5>Точка продаж</h5>
				<p>{pos ? pos.Name : item.POSName}</p>
				<h5>Бренд</h5>
				<p>{this.props.Item.BrandName}</p>
				{!_.isUndefined(brand) && !uistate.IsMobile && brand.SmallLogo !== "" && !forCustomer &&
					<img src={brand.SmallLogo} alt={brand.Name} />
				}
			</div>
		)
	}
}
OrderTableItemFilial = observer(OrderTableItemFilial);

class OrderTableItemPayment extends Component {
	/** Фокус на поле изменения оплаты */
	handleSelectFocus(id) { orderState.UserAction = true; }

	/** открытие окна с QR кодом */
	handleQRClick() { orderState.setQRCode(this.props.Item); }

	render() {
		var item = this.props.Item,
			isFinal = item.IsFinalStatus,
			disabledMethod = item.Paid || item.IsFiscal > 3,
			forCustomer = this.props.Type === "customer",
			isSameFilial = authUserState.Shift.FilialId === item.FilialId,
			canRefundChange = item.CashInHand === 1 && isSameFilial,
			canTakeCash = _.indexOf([2, 3], item.CashInHand) !== -1 && isSameFilial,
			showQR = authUserState.User.IsAdmin && _.indexOf([2, 4, 5], item.IsFiscal) !== -1,
			methods = orderState.getMethodsForOrder(this.props.Item)

		return (
			<div className={"col payment td " + (this.props.BlockClass ? this.props.BlockClass : "")}>
				{item.Paid && !uistate.IsMobile &&
					<p>
						<span className="tag green">Оплачено</span>
						{showQR && <button className="icon qr button" onClick={this.handleQRClick.bind(this)}
							title="Показать QR код для чека"><i></i></button>
						}
					</p>
				}
				{!isFinal && !forCustomer &&
					<SelectInput
						Id="PaymentMethodId"
						Type="number"
						Title="Способ оплаты"
						List={toSelectList(methods)}
						Value={item.PaymentMethodId}
						Disabled={disabledMethod}
						RowClass={uistate.IsMobile ? "col col60" : ""}
						onChange={this.props.changePaymentMethod}
						onFocus={this.handleSelectFocus.bind(this)}
					/>
				}
				{(isFinal || forCustomer) && <p><span className="tag">{item.PaymentMethodName}</span></p>}
				{this.props.CanPaid && !forCustomer &&
					<button className="button blue" onClick={this.props.setPayment}>Оплатить</button>
				}
				{this.props.CanRefund && !forCustomer &&
					<button className="button gray" onClick={this.props.setRefund}>Возврат</button>
				}
				{this.props.CanCancel && !forCustomer &&
					<button className="button gray" onClick={this.props.setRefund}>Отмена</button>
				}
				{item.CashInHand > 0 && !isSameFilial && !forCustomer &&
					<span className="tag red">Деньги у курьера</span>
				}
				{canRefundChange && !forCustomer &&
					<button className="button red" onClick={this.props.getCourierCash}>Забрать сдачу</button>
				}
				{canTakeCash && !forCustomer &&
					<button className="button red" onClick={this.props.getCourierCash}>Забрать оплату</button>
				}
			</div>
		)
	}
}
OrderTableItemPayment = observer(OrderTableItemPayment);

class OrderMobileTableItemBody extends Component {
	render() {
		return (
			<div className="body clearfix" style={this.props.Style}>
				<div className="clearfix row">
					<OrderTableItemProducts
						Products={this.props.Item.Products}
						IsFinal={this.props.Item.IsFinalStatus}
						BlockClass="col65"
					/>
					<OrderTableItemAmount Item={this.props.Item} BlockClass="col35" />
				</div>

				<div className="row clearfix">
					<OrderTableItemDescription
						Item={this.props.Item}
						Marks={this.props.Marks}
						BlockClass="col65"
					/>
					<OrderTableItemFilial Item={this.props.Item} BlockClass="col35" />
				</div>
				<div className="row clearfix">
					<OrderTableItemCustomer
						Item={this.props.Item}
						BlockClass="col100"
						IsActive={true}
						getCustomer={this.props.getCustomer}
					/>
				</div>
			</div>
		)
	}
}
OrderMobileTableItemBody = observer(OrderMobileTableItemBody)

class OrderTablePrint extends Component {
	/** API метод генерации пречека по заказу */
	generateBill() {
		if (_.isEmpty(orderState.PrintFormats)) {
			getRequest("GenerateBill", { OrderId: this.props.Id }, function (data) { orderState.setBill(data); });
			orderState.UserAction = true;
		} else {
			orderState.setBill({ Success: false });
			orderState.UserAction = false;
		}
	}

	/** Закрытие диалогового окна принтера либо при печати, либо при отмене */
	handlePrint() {
		orderState.setBill({ Success: false });
		orderState.UserAction = false;
	}

	render() {
		var view = this,
			style = { height: 1, overflow: "hidden", width: 1 };

		return (
			<div className="col amount td">
				<button className="button icon print" onClick={this.generateBill.bind(this)}><i></i></button>
				{!_.isEmpty(orderState.PrintFormats) && orderState.Bill && orderState.Bill.OrderId === this.props.Id &&
					<div className="printFormats orderActionsList">
						<div className="clearfix">
							{_.map(orderState.PrintFormats, function (item) {
								return (<ReactToPrint key={item.Id}
									trigger={() => <span className="">{item.Name}</span>}
									onAfterPrint={view.handlePrint.bind(view)}
									content={() => view["componentRef_" + item.Id]}
								/>)
							})}
						</div>
						<div className="billTemplate" style={style}>
							{_.map(orderState.PrintFormats, function (item) {
								return (<ComponentToPrint key={item.Id}
									ref={el => (view["componentRef_" + item.Id] = el)}

									Bill={orderState.Bill}
									Format={item.Id}
								/>)
							})}
						</div>
					</div>
				}
			</div>
		)
	}
}
OrderTablePrint = observer(OrderTablePrint);

class OrderHistoryBlock extends Component {
	/** Закрытие блока с историей */
	handleClose(event) {
		if (/back/.test(event.target.className)) orderState.setOrderHistory(undefined);
		orderState.UserAction = false;
	}

	render() {
		var order = orderState.OrderHistory,
			position = order.Position,
			style = { left: position ? position.left : 0, top: position ? position.top : 0 };

		return (
			<div className="back" onClick={this.handleClose.bind(this)}>
				<div className="clearfix history window" style={style}>
					{_.map(order.History, function (item, i) {
						return (<div key={i} className="clearfix">
							<p className="col col35">
								<i style={{ background: item.StatusColor }}></i> {item.Status}
							</p>
							<p className="col col35">{Parser(formatDate(item.Date, "<b>H:mm</b> DD.MM.YYYY "))}</p>
							<p className="col col30">{item.UserName}</p>
						</div>)
					})}
					<div className="clearfix user">
						<p className="col col50">Автор заказа</p>
						<p className="col col50"><b>{order.User}</b></p>
					</div>
				</div>
			</div>
		)
	}
}

class OrderItem extends Component {
	constructor(props) {
		super(props);

		if (orderState.Order.MerchantId > 0) {
			this.getProducts();
			this.getTags();
			this.getPaymentMethods();

			if (orderState.Order.CityId > 0 && orderState.Order.OrderTypeId === 3)
				this.getDeliveryAreas();
		}

		if (_.isNumber(orderState.Order.Id) && orderState.Order.Id !== -1)
			this.getCustomer(orderState.Order.CustomerLogin, orderState.Order.CustomerAddressId);
	}

	/** API запрос «Tags» на получение списка отметок заказа согласно выбранному предприятию */
	getTags() {
		getRequest("Tags", { MerchantId: orderState.Order.MerchantId }, function (data) {
			orderState.Tags = data.Success ? data.Tags : [];
		});
	}

	/** API запрос «PaymentMethods» на получение списка способов оплаты согласно выбранному предприятию */
	getPaymentMethods() {
		getRequest("PaymentMethods", { MerchantId: orderState.Order.MerchantId }, function (data) {
			orderState.PaymentMethods = data.Success ? data.PaymentMethods : [];
		});
	}

	/** API запрос «Sections»/«Products» на получение списка разделов и продукции согласно выбранному предприятию */
	getProducts() {
		getRequest("Sections", { Type: 3, MerchantId: orderState.Order.MerchantId }, function (sections) {
			if (sections.Success)
				getRequest("Products", { Type: 3, Active: true, MerchantId: orderState.Order.MerchantId },
					function (data) { orderState.setProducts(sections.Sections, data.Products); });
		});
	}

	/** API запрос «DeliveryAreas» на получение списка зон доставки по предприятию и городу */
	getDeliveryAreas() {
		var order = orderState.Order;

		getRequest("DeliveryAreas", { CityId: order.CityId, MerchantId: order.MerchantId }, function (data) {
			orderState.DeliveryAreas = data.Success ? data.DeliveryAreas : []
		});
	}

	/** Обработка сабмита формы */
	handleSubmit(event) {
		event.preventDefault();

		if (!orderState.validateData()) return false;
		orderState.ButtonLoading = true;

		if (orderState.IsOrderExist) this.saveOrder();
		else if (orderState.Order === -1 || _.isString(orderState.Order.Id)) this.createOrder(true);
	}

	/**
	 * API запрос «CreateOrder» на создание заказа (вызывается по мере заполнения формы)
	 * @param {boolean} isProcess завершающий вызов данной функции
	 */
	createOrder(isProcess) {
		var model = this;
		getRequest("CreateOrder", orderState.getData(), function (data) {
			orderState.Order.Id = data.Success ? data.Id : orderState.Order.Id;
			orderState.ErrorCode = data.Success ? "" : data.ErrorCode;

			if (data.Success && !_.isNull(data.ReferenceTimes))
				orderState.ReferenceTime = _.sumBy(data.ReferenceTimes, "Time")

			if (data.Success && isProcess) model.processOrder(data.Id);
		});
	}

	/**
	 * API запрос «ProcessOrder» на сохранение нового заказа в базу
	 * @param {string} id ID заказа
	 */
	processOrder(id) {
		var model = this;
		getRequest("ProcessOrder", { Id: id }, function (data) {
			orderState.ErrorCode = data.Success ? "" : data.ErrorCode;
			orderState.ButtonLoading = false;

			if (data.Success) model.props.setId(undefined);
		});
	}

	/** API запрос «CancelOrder» на отмену создания заказа (до сохранения) */
	cancelOrder() {
		var model = this;
		getRequest("CancelOrder", { Id: orderState.Order.Id }, function () {
			model.props.setId(undefined);
		});
	}

	/** API запрос «SaveOrder» на сохранение существующего заказа */
	saveOrder() {
		var model = this;

		getRequest("SaveOrder", orderState.getData(), function (data) {
			if (data.Success) model.props.getItem(data.Id);

			orderState.ErrorCode = data.Success ? "SUCCESS_SAVE" : data.ErrorCode;
			orderState.ButtonLoading = false;
			orderState.SuccessSave = data.Success;
		});
	}

	/** API запрос «GetLastOrder» на получение последнего несохраненного заказа для выбранного клиента */
	getLastOrder() {
		getRequest("GetLastOrder", { CustomerLogin: orderState.Order.CustomerLogin, MerchantId: 1 },
			function (data) { if (data.Success) orderState.setOrder(data); });
	}

	/** 
	 * API запрос «Customer» на получение данных клиента
	 * @param {string} login номер телефона клиента
	 * @param {number} addressId ID адреса в заказе для «общих» номеров
	 */
	getCustomer(login, addressId) {
		var params = { Login: login };
		if (!_.isUndefined(addressId)) params = _.extend(params, { AddressId: addressId });
		orderState.CheckOrderCustomer = false;

		getRequest("Customer", params, function (data) {
			orderState.setCustomer(data.Success ? data : undefined);
			orderState.setOrderCustomer(data);
		});
	}

	/** Закрытие окна заказа */
	handleClose(event) {
		event.preventDefault();

		orderState.setCustomer(undefined);
		orderState.CustomersList = [];

		if (_.isString(orderState.Order.Id)) this.cancelOrder();
		else this.props.setId(undefined);
	}

	/** Установка высоты страницы заказа */
	setHeight() {
		orderState.orderHeight = orderState.orderReferenceElement.offsetHeight;
	}

	/** Выбор кнопки Информация о заказе/Заказ */
	changeButton(event) {
		event.preventDefault();

		var id = event.currentTarget.getAttribute("rel");
		orderState.OrderStateActive = id;

		if (id === "info") scroll.scrollToTop();
		else if (id === "order")
			scroller.scrollTo('scrollOrder', { duration: 800, smooth: 'true', offset: -135 });
	}

	render() {
		var order = orderState.Order,
			title = uistate.IsMobile ? "Заказ" : buttonTitles[!orderState.IsOrderExist ? "NewOrder" :
				"EditOrder"] + (orderState.IsOrderExist ? order.OrderNumber : "");

		return (
			<section className="clearfix item two-cols" id="OrderItem">
				<form onSubmit={this.handleSubmit.bind(this)} >
					<ItemHeader
						Title={title}
						Error={orderState.ErrorCode}
						Loading={orderState.ButtonLoading}
						CanManage={order.IsFinalStatus ? checkRights("CanChangeFinishOrders")
							: checkRights("ManageOrders")}
						Success={orderState.SuccessSave}
						handleClose={this.handleClose.bind(this)}
					/>
					{uistate.IsMobile &&
						<OrderMobileTabs changeButton={this.changeButton.bind(this)} />
					}
					<div className="clearfix section" ref={(element) => this.element = element}>
						{!uistate.IsMobile &&
							<OrderInfo
								Marks={_.filter(this.props.Marks, { MerchantId: order.MerchantId })}

								createOrder={this.createOrder.bind(this)}
								getLastOrder={this.getLastOrder.bind(this)}
								getProducts={this.getProducts.bind(this)}
								getTags={this.getTags.bind(this)}
								getCustomer={this.getCustomer.bind(this)}
								getPaymentMethods={this.getPaymentMethods.bind(this)}
								getDeliveryAreas={this.getDeliveryAreas.bind(this)}
								setHeight={this.setHeight.bind(this)}
							/>
						}
						{!uistate.IsMobile &&
							<MenuInfo
								createOrder={this.createOrder.bind(this)}
								setHeight={this.setHeight.bind(this)}
							/>
						}
						{uistate.IsMobile &&
							<OrderMobileItem
								Marks={_.filter(this.props.Marks, { MerchantId: order.MerchantId })}

								createOrder={this.createOrder.bind(this)}
								getLastOrder={this.getLastOrder.bind(this)}
								getProducts={this.getProducts.bind(this)}
								getTags={this.getTags.bind(this)}
								getCustomer={this.getCustomer.bind(this)}
								getPaymentMethods={this.getPaymentMethods.bind(this)}
								getDeliveryAreas={this.getDeliveryAreas.bind(this)}
							/>
						}
					</div>
					{!_.isUndefined(orderState.ModificatorsList) &&
						<ProductModificatorsBlock
						/>
					}
				</form>
			</section>
		)
	}
}
OrderItem = observer(OrderItem);

class OrderInfo extends Component {
	constructor(props) {
		super(props);

		var model = this;
		setTimeout(function () { orderState.orderReferenceElement = model.element; }, 50);
		setTimeout(function () { model.props.setHeight(); }, 100);
	}

	/** Установка высоты страницы заказа */

	/** Смена активной вкладки */
	changeTab(event) {
		event.preventDefault();
		if (/active/.test(event.currentTarget.className)) return false;

		var model = this,
			id = event.currentTarget.getAttribute("rel");

		orderState.OrderStateActive = id;
		setTimeout(function () { model.props.setHeight(); }, 100);
	}

	/** Изменение высоты страницы */
	changeHeight() {
		var model = this;
		setTimeout(function () { model.props.setHeight(); }, 100);
	}

	render() {
		var active = orderState.OrderStateActive,
			infoClass = uistate.IsMobile ? "clearfix" : ("col " +
				(active === "info" ? "col3_4 active" : "col1_4")),
			orderClass = uistate.IsMobile ? "clearfix" : ("col " +
				(active === "order" ? "col3_4 active" : "col1_4")),
			style = !uistate.isDevice ? { minHeight: uistate.sectionHeight - 5 } : {};

		return (
			<div className={"order " + (uistate.IsMobile ? "clearfix" : "col col55")} style={style}
				ref={(element) => this.element = element}>
				<div className="clearfix">
					<div className={infoClass}>
						{!uistate.IsMobile &&
							<a className={"clearfix tab " + (orderState.OrderStateActive === "info" ? "active" : "")}
								rel="info" href="/" onClick={this.changeTab.bind(this)}>Информация о заказе</a>
						}
						<InfoBlock
							Marks={this.props.Marks}

							changeHeight={this.changeHeight.bind(this)}
							createOrder={this.props.createOrder}
							getLastOrder={this.props.getLastOrder}
							getProducts={this.props.getProducts}
							getTags={this.props.getTags}
							getCustomer={this.props.getCustomer}
							getPaymentMethods={this.props.getPaymentMethods}
							getDeliveryAreas={this.props.getDeliveryAreas}
						/>
					</div>
					<div className={orderClass}>
						{!uistate.IsMobile &&
							<a className={"clearfix tab " + (orderState.OrderStateActive === "order" ? "active" : "")}
								rel="order" href="/" onClick={this.changeTab.bind(this)}>Заказ</a>
						}
						<OrderBlock
							createOrder={this.props.createOrder}
							changeHeight={this.changeHeight.bind(this)}
							setHeight={this.props.setHeight}
						/>
					</div>
				</div>
			</div>
		)
	}
}
OrderInfo = observer(OrderInfo);

class OrderMobileItem extends Component {
	/** Смена активной вкладки */
	changeTab(event) {
		event.preventDefault();
		if (/active/.test(event.currentTarget.className)) return false;

		var id = event.currentTarget.getAttribute("rel");
		orderState.OrderStateActive = id;
	}

	render() {
		return (
			<div className="order clearfix">
				<InfoBlock
					Marks={this.props.Marks}

					createOrder={this.props.createOrder}
					getLastOrder={this.props.getLastOrder}
					getProducts={this.props.getProducts}
					getTags={this.props.getTags}
					getCustomer={this.props.getCustomer}
					getPaymentMethods={this.props.getPaymentMethods}
					getDeliveryAreas={this.props.getDeliveryAreas}
				/>
				<OrderBlock
					Type="products"
					createOrder={this.props.createOrder}
				/>
				<MenuInfo
					createOrder={this.props.createOrder}
				/>
				<OrderBlock
					Type="payment"
					createOrder={this.props.createOrder}
				/>
			</div>
		)
	}
}
OrderInfo = observer(OrderInfo);

class OrderMobileTabs extends Component {
	render() {
		return <div className="orderMobileTabs clearFix">
			<a className={orderState.OrderStateActive === "info" ? "activeLink" : ""}
				rel="info" href="/" onClick={this.props.changeButton}>Информация о заказе</a>
			<a className={orderState.OrderStateActive === "order" ? "activeLink" : ""}
				rel="order" href="/" onClick={this.props.changeButton}>Заказ</a>
		</div>
	}
}
OrderMobileTabs = observer(OrderMobileTabs);

class InfoBlock extends Component {
	/**
	 * Сбор данных с формы
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange(value, id) {
		if (!/CustomerLogin/.test(id)) orderState.collectOrder(id, value);

		if (/BrandId/.test(id) && value !== "") {
			var brand = _.find(orderState.Brands, { Id: value });
			if (brand) {
				orderState.Order.MerchantId = brand.MerchantId;
				this.props.getProducts();
				this.props.getTags();
				this.props.getPaymentMethods();
			}
		}

		if (/CityId/.test(id) || /OrderTypeId/.test(id) || /BrandId/.test(id)) {
			orderState.Order.DeliveryAreaId = 0;
			if (orderState.Order.CityId !== -1 && orderState.Order.OrderTypeId === 3)
				this.props.getDeliveryAreas();
		}

		if (/FilialId/.test(id)) this.getFilial();

		if (/CustomerLogin/.test(id)) {
			if ((value).replace(/\D/gi, "").length > 8) this.getCustomers((value).replace(/\D/gi, ""));
			orderState.collectOrder(id, value);
		}

		var textInputs = ["CustomerLogin", "CustomerName", "DescriptionAdmin", "DescriptionCook", "CustomerInfo"];
		if (_.indexOf(textInputs, id) === -1 && this.props.createOrder &&
			(orderState.Order.Id === -1 || _.isString(orderState.Order.Id))) this.props.createOrder();

		if (this.props.changeHeight) this.props.changeHeight();

		if (orderState.IsOrderExist && /DeliveryAreaId/.test(id)) orderState.IsChangeDeliveryZone = true;

		// TODO изменение цен при смене города
	}
	/** 
	 * Выбор клиента по номеру телефона
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleCheck(value, id) {
		if (/CustomerLogin/.test(id)) {
			orderState.collectOrder(id, value);
			this.props.getCustomer((value).replace(/\D/gi, ""));
		}
	}

	/** 
	 * Уход фокуса с поля
	 * @param {string} id название параметра
	 */
	handleBlur(id) {
		if (this.props.createOrder && (orderState.Order.Id === -1 || _.isString(orderState.Order.Id)))
			this.props.createOrder();

		if (id === "CustomerLogin" && orderState.Order.Id === -1 && this.props.getLastOrder)
			this.props.getLastOrder();
	}

	/** API запрос «Filial» на получение выбранного филиала */
	getFilial() {
		getRequest("Filial", { Id: orderState.Order.FilialId }, function (data) { orderState.setFilial(data); });
	}

	/** API запрос «CustomerSearch» поиска клиентов по номеру телефона
	 * @param {string} value первые 6 и более цифр номера телефона
	 */
	getCustomers(value) {
		getRequest("CustomerSearch", { Login: value }, function (data) {
			orderState.CustomersList = data.Success ? data.Customers : [];
		});
	}

	render() {
		if (orderState.OrderStateActive !== "info" && !uistate.IsMobile)
			return <InfoBlockStatic Marks={this.props.Marks} />
		else if ((orderState.OrderStateActive === "info" && !uistate.IsMobile) || uistate.IsMobile) return (
			<div className="clearfix tabBlock">
				<h4>Здравствуйте! Оператор {authUserState.User.Name}</h4>
				<div className={"orderBlock " + (uistate.IsMobile ? "clearfix" : "col col65")}>
					<InfoBlockBrand
						handleChange={this.handleChange.bind(this)}
						changeHeight={this.props.changeHeight}
					/>
					<InfoBlockCustomer
						handleChange={this.handleChange.bind(this)}
						handleCheck={this.handleCheck.bind(this)}
						handleBlur={this.handleBlur.bind(this)}
					/>
					<InfoBlockLocation
						handleChange={this.handleChange.bind(this)}
					/>
					<InfoBlockTime
						handleChange={this.handleChange.bind(this)}
					/>
					<InfoBlockDescription
						handleChange={this.handleChange.bind(this)}
						handleBlur={this.handleBlur.bind(this)}
					/>
					<InfoBlockAggregator
						handleChange={this.handleChange.bind(this)}
					/>
					{uistate.IsMobile &&
						<InfoBlockSystem
							Marks={this.props.Marks}
							handleChange={this.handleChange.bind(this)}
						/>
					}
					<h4 name="scrollOrder">Что будете заказывать?</h4>
				</div>
				{!uistate.IsMobile &&
					<div className="col col35 orderBlock">
						<h5>Служебная информация</h5>
						<InfoBlockCustomerInfo
							handleChange={this.handleChange.bind(this)}
							handleBlur={this.handleBlur.bind(this)}
						/>
						<InfoBlockSystem
							Marks={this.props.Marks}
							handleChange={this.handleChange.bind(this)}
						/>
					</div>
				}
			</div>
		)
		else return null;
	}
}
InfoBlock = observer(InfoBlock);

class InfoBlockBrand extends Component {
	constructor(props) {
		super(props);

		this.state = { Open: orderState.Order.BrandId === -1 || orderState.Order.BrandId === "" }
	}

	/** Скрыть/показать полный список брендов */
	toggleList(event) {
		event.preventDefault();
		this.setState({ Open: !this.state.Open });

		if (!uistate.IsMobile && !uistate.IsTablet) this.props.changeHeight();
	}

	/** Сбор данных с формы */
	handleChange(value, id) {
		this.setState({ Open: false });
		this.props.handleChange(value, id);
	}

	render() {
		var order = orderState.Order,
			brandsList = !this.state.Open ? _.filter(orderState.brandsFilterList, { Id: order.BrandId })
				: orderState.brandsFilterList;

		return (
			<div className="clearfix brand">
				<CheckGroup
					Id="BrandId"
					Title="Выберите необходимый бренд"
					Type="radio"
					ParseType="number"
					RowClass="brands"
					List={toCheckList(brandsList)}
					Value={[orderState.Order.BrandId]}
					Disabled={orderState.IsOrderExist}
					onChange={this.handleChange.bind(this)}
				/>
				{orderState.Order.BrandId !== -1 && orderState.Order.BrandId !== "" &&
					<a href="/" className="toggleBrands dotted" onClick={this.toggleList.bind(this)}>
						{this.state.Open ? "Скрыть" : "Показать все"}</a>
				}
			</div>
		)
	}
}
InfoBlockBrand = observer(InfoBlockBrand);

class InfoBlockCustomer extends Component {
	constructor(props) {
		super(props);

		this.state = { Open: orderState.Order.CustomerInfo !== "" }
	}

	/** Показать окно с информацией о клиенте */
	openCustomer(event) {
		event.preventDefault();
		orderState.ShowCustomerCard = true;
	}

	/** Показать/скрыть блок с текстом о клиенте */
	toggle(event) {
		event.preventDefault();
		this.setState({ Open: !this.state.Open });
	}

	/** Показать окно смены клиента */
	changeCustomer(event) {
		event.preventDefault();
		orderState.setChangeOrderCustomer(orderState.Order);
	}

	render() {
		var order = orderState.Order,
			canManage = (checkRights("ManageOrders") || checkRights("CanCreateOrder")) && !order.IsFinalStatus,
			customerList = _.map(orderState.CustomersList, function (v) {
				return { Id: v.Login, Name: parsePhoneMask(v.Login, "Россия") };
			});

		return (
			<div className="clearfix customer">
				<div className="clearfix">
					<AutoCompletePhone
						Id="CustomerLogin"
						Title={"Ваш номер телефона " + parsePhoneMask(order.CustomerLogin, "Россия") + "?"}

						List={customerList}
						RowClass="col col80"
						Mask="7 (999) 999-99-99"
						Disabled={orderState.IsOrderExist}
						Value={order.CustomerLogin}

						onInputChange={this.props.handleChange}
						onChange={this.props.handleCheck}
					/>
					{!_.isUndefined(orderState.Customer) && !orderState.Order.IsFinalStatus && orderState.Customer.CommonPhone && orderState.IsOrderExist &&
						<a href="/" className="icon changeCustomerIcon" onClick={this.changeCustomer.bind(this)}>
							<i></i></a>
					}
					{!_.isUndefined(orderState.Customer) &&
						<a href="/" className="customerInfo" onClick={this.openCustomer.bind(this)}>
							<i></i></a>
					}
					{uistate.IsMobile && !_.isUndefined(orderState.Customer) &&
						<a href="/" className={"icon customerStat " + (this.state.Open ? "active" : "")}
							onClick={this.toggle.bind(this)}><i></i></a>
					}
				</div>
				<TextInput
					Id="CustomerName"
					Title="Ваше имя?"
					Value={order.CustomerName}
					Disabled={!canManage}
					onChange={this.props.handleChange}
					onBlur={this.props.handleBlur}
				/>
				{uistate.IsMobile && this.state.Open &&
					<InfoBlockCustomerInfo
						onChange={this.props.handleChange}
						onBlur={this.props.handleBlur}
					/>
				}
			</div>
		)
	}
}
InfoBlockCustomer = observer(InfoBlockCustomer);

class ChangeCustomer extends Component {
	/** Закрытие окна смены заказа */
	handleClose(event) {
		event.preventDefault();
		orderState.setChangeOrderCustomer(undefined);
	}

	/**
	 * Сбор данных с формы смены клиента в заказе
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange(value, id) { orderState.collectChangeOrderCustomer(value, id); }

	/** API запрос «ChangeOrderCustomer» на изменение клиента в заказе */
	handleSubmit(event) {
		event.preventDefault();
		var params = orderState.getChangeOrderCustomer()

		if (!orderState.validateChangeOrderCustomer()) return false;

		orderState.ChangeOrderCustomer.ButtonLoading = true;

		getRequest("ChangeOrderCustomer", params, function (data) {
			if (data.Success) {
				getRequest("Order", { Id: params.OrderId }, function (data) {
					if (data.Success) {
						orderState.setOrder(data);
						getRequest("Customer", { Login: params.CustomerLogin }, function (data) {
							if (data.Success) {
								orderState.setCustomer(data);
								orderState.setOrderCustomer(data);
							}
						});
					}
				});
			} else errorState.setError(data.ErrorCode);

			orderState.setChangeOrderCustomer(undefined);
		});
	}

	render() {
		var customer = orderState.ChangeOrderCustomer;

		return (
			<div className="back">
				<div className="confirmBlock window changeCustomer">
					<form onSubmit={this.handleSubmit.bind(this)}>
						<p>Вы действительно хотите сменить клиента <b>«{customer.OldCustomerName}»</b>&nbsp;
							в заказе <b>№{customer.OrderNumber}</b> на другого?</p>
						<div className="clearfix">
							<TextInput
								Id="CustomerLogin"
								Title="Номер телефона нового клиента"
								Value={customer.CustomerLogin}
								Mask="7 (999) 999-99-99"
								RowClass="col col70"
								onChange={this.handleChange.bind(this)}
							/>
							<TextInput
								Id="CustomerName"
								Title="Имя клиента"
								Value={customer.CustomerName}
								RowClass="col col70"
								onChange={this.handleChange.bind(this)}
							/>
						</div>
						{customer.ErrorCode !== "" &&
							<p className="error clearfix">{errors[customer.ErrorCode] || errors.DEFAULT}</p>
						}
						<div className="buttons clearfix">
							<SubmitButton Loading={customer.ButtonLoading} Title="Подтвердить" />
							<a href="/" className="button gray" onClick={this.handleClose.bind(this)}>Отменить</a>
						</div>
					</form>
				</div>
			</div>
		)
	}
}
ChangeCustomer = observer(ChangeCustomer);

class InfoBlockCustomerInfo extends Component {
	render() {
		var isFinal = orderState.Order.IsFinalStatus,
			canManage = ((checkRights("ManageOrders") || checkRights("CanCreateOrder")) && !isFinal)
				|| (isFinal && checkRights("CanChangeFinishOrders")),
			customer = orderState.Customer,
			ordersCount = _.isUndefined(customer) ? 0 : customer.OrdersCount,
			brandsCount = _.isUndefined(customer) ? 0 : orderState.CustomerBrandsCount,
			newOrderCustomer = (!orderState.IsOrderExist && (_.isUndefined(customer) || brandsCount === 0))
				|| (orderState.IsOrderExist && !_.isUndefined(customer) && brandsCount === 1),
			existOrderCustomer = (orderState.IsOrderExist && !_.isUndefined(customer) && brandsCount > 1)
				|| (!orderState.IsOrderExist && !_.isUndefined(customer) && brandsCount === 1);

		return (
			<div className="customerDescription clearfix">
				{orderState.CheckOrderCustomer && newOrderCustomer &&
					<span className="tag red">Новый клиент ({ordersCount})</span>
				}
				{orderState.CheckOrderCustomer && existOrderCustomer &&
					<span className="tag yellow">{setLabel(brandsCount, OrderLabels)} ({ordersCount})</span>
				}
				<TextInput
					Id="CustomerInfo"
					Title="Информация о клиенте"
					Type="textarea"
					Value={orderState.Order.CustomerInfo}
					Disabled={!canManage}
					onChange={this.props.handleChange}
					onBlur={this.props.handleBlur}
				/>
				{!_.isUndefined(customer) && customer.BirthDate !== "" &&
					<p><i className="icon birth"></i>ДР: {formatDate(customer.BirthDate)}</p>
				}
				{!_.isUndefined(customer) && customer.BlackList &&
					<p><i className="icon bl"></i> Клиент в ЧС с&nbsp;
						{formatDate(customer.BlackListDate)} по причине: {customer.BlackListDescription}</p>
				}
			</div>
		)
	}
}
InfoBlockCustomerInfo = observer(InfoBlockCustomerInfo);

class InfoBlockLocation extends Component {
	/** 
	 * Выбор адреса из списка адресов пользователя
	 * @param {number} id ID адреса пользователя
	 */
	selectAddress(id) {
		orderState.Order.CustomerAddress = _.clone(_.find(orderState.Customer.Address, { Id: id }));
		this.getDeliveryArea();
	}

	/**
	 * Изменение типа подачи заказа OrderTypeId
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChangeOrderType(value, id) {
		this.props.handleChange(value, id);
		if (value === 3) this.getDeliveryArea();
	}

	/**
	 * Получение адреса из Dadata и разбор по полям
	 * @param {object} value ответ от DaData
	 */
	setAddress(value) {
		orderState.setAddress(value);
		this.getDeliveryArea();
	}

	/** API запрос «GetOrderDeliveryArea» на получение зоны доставки по городу и координатам */
	getDeliveryArea() {
		var order = orderState.Order,
			params = {
				MerchantId: order.MerchantId,
				CityId: order.CityId,
				Lat: order.CustomerAddress.Lat,
				Long: order.CustomerAddress.Long,
				BrandId: order.BrandId
			};

		if (order.BrandId === -1 || order.MerchantId === -1 || order.CityId === -1 || _.isEmpty(order.CustomerAddress))
			return false;

		getRequest("GetOrderDeliveryArea", params, function (data) {
			order.DeliveryAreaId = data.Success ? data.DeliveryAreaId : -1;
			if (data.Success && data.DeliveryAreaId > 0 && !orderState.IsStartProcess && !orderState.IsEndProcess) {
				orderState.POSId = -1;
				order.FilialId = data.MainFilialId;
			}
		});
	}

	render() {
		var order = orderState.Order,
			canManage = (checkRights("ManageOrders") || checkRights("CanCreateOrder")) && !order.IsFinalStatus,
			isDelivery = order.OrderTypeId === 3,
			hasArea = !isDelivery || (isDelivery && order.DeliveryAreaId > 0),
			disableProcess = orderState.IsOrderExist && orderState.IsStartProcess,
			disableFiscal = orderState.IsFiscalPaidOrder && orderState.IsOrderExist,
			disable = disableProcess || disableFiscal || order.IsFinalStatus || !hasArea,
			dontFindZone = isDelivery && order.DeliveryAreaId === -1,
			noDeliveryToAddress = isDelivery && order.DeliveryAreaId === -2,
			area = _.find(orderState.DeliveryAreas, { Id: order.DeliveryAreaId }),
			someFilialsArea = area && orderState.availableFilials.length !== area.Filials.length,
			type = _.find(POSTypesTitles, { Id: order.OrderTypeId }),
			filialsList = _.map(orderState.availableFilials, function (v) {
				var brand = _.find(v.Brands, { Id: order.BrandId }),
					method = _.find(v.PaymentMethods, { Id: order.PaymentMethodId }),
					filials = area ? _.map(area.Filials, "Id") : [],
					noArea = isDelivery && area && _.indexOf(filials, v.Id) === -1;

				return {
					Id: v.Id, Name: v.Address,
					Disabled: (orderState.IsOrderExist && (_.isUndefined(brand) || _.isUndefined(method))) || noArea
				};
			}),
			addressList = !_.isUndefined(orderState.Customer) && !_.isUndefined(orderState.Customer.Address)
				? orderState.Customer.Address : [_.clone(orderState.AddressDummy)],
			city = _.find(authUserState.OrderCities, { Id: orderState.Order.CityId });

		return (
			<div className="clearfix location">
				<Selectize
					Id="CityId"
					Title="Из какого города вы звоните?"
					List={toSelectList(authUserState.OrderCities)}
					Value={orderState.Order.CityId}
					Disabled={orderState.IsOrderExist}
					isClearable={false}
					onChange={this.props.handleChange}
				/>
				<ButtonGroup
					Id="OrderTypeId"
					Title="Вы желаете заказать доставку или забрать самовывозом?"
					List={orderState.POSTypes}
					Type="number"
					Value={order.OrderTypeId}
					Disabled={!canManage}
					CanIcon={true}
					RowClass="orderType"
					onChange={this.handleChangeOrderType.bind(this)}
				/>
				{isDelivery &&
					<AddressBlock
						Info={addressList}
						City={city ? city.Name : undefined}
						Type="order"
						BlockClass="clearfix"
						AddressId={order.CustomerAddress ? order.CustomerAddress.Id : -1}
						Disabled={!canManage}
						Address={order.CustomerAddress}

						selectAddress={this.selectAddress.bind(this)}
						setAddress={this.setAddress.bind(this)}
						collectAddress={orderState.collectAddress.bind(orderState)}
					/>
				}
				{dontFindZone &&
					<p className="comment">Не удалось определить зону доставки, выберите зону доставки из списка</p>
				}
				{noDeliveryToAddress &&
					<p className="comment">Доставка по указанному адресу не производится. Если адрес определился не верно есть возможность выбрать зону вручную</p>
				}
				{isDelivery &&
					<Selectize
						Id="DeliveryAreaId"
						Title="Выберите район доставки"
						List={toSelectList(orderState.OrderDeliveryAreas)}
						Value={order.DeliveryAreaId}
						Disabled={!canManage}
						onChange={this.props.handleChange}
					/>
				}
				<CheckGroup
					Id="FilialId"
					Title={type ? type.OrderFilialTitle : ""}
					Type="radio"
					ParseType="number"
					Disabled={disable}
					List={toCheckList(filialsList)}
					Value={[order.FilialId]}
					onChange={this.props.handleChange}
				/>
				{orderState.IsChangeDeliveryZone &&
					<p className="comment error">Вы изменили зону существующего заказа. Прежде чем озвучить
						клиенту время, нажмите на кнопку «Сохранить» для актуализации информации.</p>

				}
				{someFilialsArea &&
					<p className="comment">Выбранная зона доставки обслуживается не всеми филиалами.</p>
				}
				{!hasArea &&
					<p className="comment">Для выбора филиала необходимо выбрать район доставки</p>
				}
				{disableProcess &&
					<p className="comment">
						Заказ уже начал готовиться, чтобы изменить филиал, необходимо отменить
						производенные товары и вернуть статус заказа до начала производства
					</p>
				}
			</div>
		)
	}
}
InfoBlockLocation = observer(InfoBlockLocation);

class InfoBlockTime extends Component {
	render() {
		var order = orderState.Order,
			not = ["", 0, -1],
			canManage = (checkRights("ManageOrders") || checkRights("CanCreateOrder"))
				&& !orderState.Order.IsFinalStatus,
			canSet = _.indexOf(not, order.BrandId) === -1 && _.indexOf(not, order.CityId) === -1 &&
				_.indexOf(not, order.OrderTypeId) === -1 && _.indexOf(not, order.FilialId) === -1;

		return (
			<div className="clearfix time">
				<ButtonGroup
					Id="OrderToTime"
					Title="Заказ доставить сейчас или к определенному времени?"
					List={OrderTimeTitles}
					Type="number"
					Value={orderState.Order.OrderToTime ? 2 : 1}
					Disabled={!canManage || !canSet}
					onChange={this.props.handleChange}
				/>
				{!canSet &&
					<p className="comment">Для того, чтобы поставить заказ «ко времени» необходимо&nbsp;
					выбрать бренд, город, тип заказа и филиал</p>
				}
				{orderState.Order.OrderToTime &&
					<div className="clearfix">
						<DateInput
							Id="MustCompletedTime"
							Title="Заказ ко времени"
							Value={orderState.Order.MustCompletedTime}
							Disabled={!canManage}
							WithTime={true}
							Step={5}
							//MinDate={moment().add(times.ShiftToTime, "minutes")}
							MinDate={moment().add(getLocalShift(orderState.Order.CityId), "minutes")}

							WorkDays={orderState.workTimes}
							RowClass="col col70 toDate"
							WithIcon={true}
							onChange={this.props.handleChange}
						/>

					</div>
				}
			</div>
		)
	}
}
InfoBlockTime = observer(InfoBlockTime);

class InfoBlockDescription extends Component {
	render() {
		var canManage = checkRights("ManageOrders") || checkRights("CanCreateOrder"),
			disabled = (orderState.Order.IsFinalStatus && !checkRights("CanChangeFinishOrders")) || !canManage;

		return (
			<div className="clearfix description">
				<TextInput
					Id="DescriptionAdmin"
					Title="Комментарий для администратора"
					Type="textarea"
					Value={orderState.Order.DescriptionAdmin}
					Disabled={disabled}
					onChange={this.props.handleChange}
					onBlur={this.props.handleBlur}
				/>
				<TextInput
					Id="DescriptionCook"
					Title="Комментарий для повара"
					Type="textarea"
					Value={orderState.Order.DescriptionCook}
					Disabled={disabled}
					onChange={this.props.handleChange}
					onBlur={this.props.handleBlur}
				/>
			</div>
		)
	}
}
InfoBlockDescription = observer(InfoBlockDescription);

class InfoBlockAggregator extends Component {
	constructor(props) {
		super(props);

		this.state = { Open: orderState.Order.ByAggregator && orderState.Order.AggregatorId === -1 }
	}

	/** Скрыть/показать полный список брендов */
	toggleList(event) {
		event.preventDefault();
		this.setState({ Open: !this.state.Open });
	}

	/** Сбор данных с формы */
	handleChange(value, id) {
		var order = orderState.Order;

		if (/ByAggregator/.test(id)) this.setState({ Open: value === 2 && order.AggregatorId === -1 });
		if (/AggregatorId/.test(id)) this.setState({ Open: false });

		this.props.handleChange(value, id);
	}

	render() {
		var order = orderState.Order,
			aggregators = _.isUndefined(orderState.currentFilial) ? [] :
				_.sortBy(orderState.currentFilial.Aggregators, "Id"),
			aggregatorsList = this.state.Open ? aggregators :
				_.filter(aggregators, { Id: order.AggregatorId });

		return (
			<div className="clearfix aggregator">
				<ButtonGroup
					Id="ByAggregator"
					Type="number"
					List={OrderAggTitles}
					Value={order.ByAggregator ? 2 : 1}
					Disabled={order.IsFinalStatus}
					onChange={this.handleChange.bind(this)}
				/>
				{order.ByAggregator &&
					<div className="clearfix aggregators">
						<CheckGroup
							Id="AggregatorId"
							Title="Через какой агрегатор сделан заказ?"
							Type="radio"
							ParseType="number"
							List={toCheckList(aggregatorsList)}
							Value={[order.AggregatorId]}
							Disabled={order.IsFinalStatus}
							onChange={this.handleChange.bind(this)}
						/>
						{_.indexOf([-1, ""], order.AggregatorId) === -1 &&
							<a href="/" className="toggleBrands dotted" onClick={this.toggleList.bind(this)}>
								{this.state.Open ? "Скрыть" : "Показать все"}</a>
						}
					</div>
				}

			</div>
		)
	}
}
InfoBlockAggregator = observer(InfoBlockAggregator);

class InfoBlockSystem extends Component {
	render() {
		var canManage = (checkRights("ManageOrders") || checkRights("CanCreateOrder")) &&
			!orderState.Order.IsFinalStatus;

		return (
			<div className="clearfix aggregator">
				{checkRights("CanViewHomeOrders") &&
					<CheckGroup
						Id="HomeOrder"
						Type="checkbox"
						RowClass="homeorder"
						List={[{ Id: "true", Title: "Внутренний заказ" }]}
						Disabled={!canManage || orderState.Order.OrderTypeId === 4}
						Value={orderState.Order.HomeOrder ? ["true"] : []}
						onChange={this.props.handleChange}
					/>
				}
				<TagsPreset
					Id="Marks"
					Title="Отметки заказа"
					List={this.props.Marks}
					Disabled={!canManage}
					Value={orderState.Order.Marks}
					onChange={this.props.handleChange}
				/>
			</div>
		)
	}
}
InfoBlockSystem = observer(InfoBlockSystem);

class InfoBlockStatic extends Component {
	render() {
		var view = this,
			order = orderState.Order,
			filial = orderState.currentFilial,
			marks = _.compact(_.map(order.Marks, function (v) {
				return _.find(view.props.Marks, { Id: v }) ? _.find(view.props.Marks, { Id: v }).Name : "";
			})),
			city = _.find(authUserState.Cities, { Id: order.CityId }),
			posType = _.find(orderState.POSTypes, { Id: order.OrderTypeId }),
			aggregator = order.ByAggregator && filial ? _.find(filial.Aggregators, { Id: order.AggregatorId })
				: undefined,
			brand = filial ? _.find(filial.Brands, { Id: order.BrandId }) : undefined,
			merchant = _.find(authUserState.Merchants, { Id: order.MerchantId });

		return (
			<div className="clearfix tabBlock">
				<div className="clearfix block">
					{authUserState.MerchantType === 2 && merchant && <p><b>{merchant.Name}</b></p>}
					<p><b>{city ? city.Name : ""}</b></p>
					<p>{order.CustomerName}</p>
					{posType && filial && <p><b>{posType.Name}&nbsp;</b>{filial.Name}</p>}
					{aggregator && <p>Через <b>{aggregator.Name}</b></p>}
					{order.OrderToTime && order.MustCompletedTime !== "" &&
						<p>Должен быть готов <b>{formatDate(order.MustCompletedTime, "HH:mm DD.MM.YYYY")}</b></p>
					}
				</div>
				{!_.isUndefined(order.CustomerInfo) && order.CustomerInfo !== "" &&
					<div className="clearfix block">
						<p><b>Информация о клиенте:</b></p>
						<p>{order.CustomerInfo}</p>
					</div>
				}
				<div className="clearfix block">
					<p><b>Комментарии к заказу:</b></p>
					<p>{order.DescriptionAdmin}</p>
					<p>{order.DescriptionCook}</p>
				</div>
				<div className="clearfix block">
					<p>Бренд: <b>{brand ? brand.Name : ""}</b></p>
					<p><b>Отметки заказа:</b></p>
					<p>{marks.join(", ")}</p>
				</div>
			</div>
		)
	}
}
InfoBlockStatic = observer(InfoBlockStatic);

class OrderBlock extends Component {
	constructor(props) {
		super(props);

		var model = this;
		if (!uistate.isDevice) setTimeout(function () { model.props.setHeight(); }, 100);
	}

	render() {
		if (orderState.OrderStateActive !== "order" && !uistate.IsMobile)
			return (<OrderBlockStatic />)
		else if (orderState.OrderStateActive === "order" || uistate.IsMobile)
			return (
				<div className="clearfix tabBlock active">
					{(!uistate.IsMobile || (uistate.IsMobile && this.props.Type === "products")) &&
						<ProductsBlock
							createOrder={this.props.createOrder}
							changeHeight={this.props.changeHeight}
						/>
					}
					{(!uistate.IsMobile || (uistate.IsMobile && this.props.Type === "payment")) &&
						<PaymentBlock
							createOrder={this.props.createOrder}
							changeHeight={this.props.changeHeight}
						/>
					}
				</div>
			)
		else return null;
	}
}
OrderBlock = observer(OrderBlock);

class OrderBlockStatic extends Component {
	render() {
		var method = orderState.Order.PaymentMethodId !== "" && orderState.Order.PaymentMethodId !== -1
			&& !_.isUndefined(orderState.currentFilial)
			? _.find(orderState.currentFilial.PaymentMethods, { Id: orderState.Order.PaymentMethodId }) : "";

		return (
			<div className="clearfix tabBlock">
				<div className="clearfix block">
					{_.map(orderState.orderProducts, function (v) {
						return (<p key={v.ProductId}><b>{v.ProductName}</b><span> – {v.Count} шт</span></p>)
					})}
				</div>
				<div className="clearfix block">
					<p>Кол-во персон: <b>{orderState.Order.PersonsNumber}</b></p>
					{orderState.Order.OrderTypeId === 1 &&
						<p>Столик: <b>{orderState.Order.TableNumber}</b></p>
					}
				</div>
				{orderState.ReferenceTime !== "" && <ReferenceBlock />}
				<div className="clearfix block">
					<p><b>ИТОГО: {getRubs(orderState.Amount, true, true)} руб.</b></p>
					<p>Скидка: {getRubs(orderState.Order.Discount * 100, true, true)} руб.</p>
					<p>К оплате: {getRubs(orderState.TotalAmount, true, true)} руб.</p>
					<p><b>Оплата</b> {method ? method.Name : ""}</p>
				</div>
			</div>
		)
	}
}
OrderBlockStatic = observer(OrderBlockStatic);

class ProductsBlock extends Component {
	render() {
		var view = this;

		return (
			<div className="clearfix block products">
				{orderState.HasWrongPOSType && <p className="comment">{errors.WRONG_ORDER_PRODUCT_POSTYPE}</p>}
				{orderState.IsEndProcess && <p className="comment">{errors.ORDER_IS_ALREADY_COOKED}</p>}
				{orderState.IsCashInHand && <p className="comment">{errors.COURIER_CIH_PRODUCTS}</p>}
				<div className="clearfix titles">
					<span className={"col " + (uistate.IsMobile ? "col30" : "col35")}>Название</span>
					<span className={"col " + (uistate.IsMobile ? "col35" : "col25")}>Кол-во</span>
					{!uistate.IsMobile && <span className="col col20">Цена</span>}
					{!uistate.IsMobile && <span className="col col20">Сумма</span>}
					{uistate.IsMobile && <span className="col col35">Цена/Сумма</span>}
				</div>
				{_.map(orderState.orderProducts, function (item, i) {
					return (<ProductItem key={i} Item={item} Index={i}
						createOrder={view.props.createOrder}
						changeHeight={view.props.changeHeight}
					/>)
				})}
				<div className="clearfix">
					<h5>Желаете напиток или десерт?</h5>
					{_.map(orderState.OrderRecomendations, function (v, i) { return (<p key={i}>{v}</p>); })}
				</div>
			</div>
		)
	}
}
ProductsBlock = observer(ProductsBlock);
class ProductItem extends Component {
	/**
	 * Подсчет количества продуков в заказе
	 * @param {number} count итоговое количество продукта
	 */
	handleChangeProduct(count) {
		console.log("product", count)
		orderState.collectProducts(count, this.props.Index, this.props.Item.ProductId);

		if (this.props.createOrder && !orderState.IsOrderExist) this.props.createOrder();
		if (!uistate.isDevice) this.props.changeHeight();
	}

	/**
	 * Сбор данных с формы заказа модификатора
	 * @param {number} count итоговое кол-во модификатора в продукте
	 * @param {number} index порядковый номер модификатора
	 * @param {number} productId ID модификатора
	 */
	handleChangeMod(count, index, productId) {
		console.log("mod", count, index, productId)
		orderState.collectModificators(count, index, productId, this.props.Index);

		if (this.props.createOrder && !orderState.IsOrderExist) this.props.createOrder();
		if (!uistate.isDevice) this.props.changeHeight();
	}

	/** Показать окно с доступными модификаторами */
	openModificators(event) {
		event.preventDefault();
		orderState.setModificators(this.props.Item, this.props.Index, event.currentTarget.getBoundingClientRect());
	}

	render() {
		var view = this,
			isStart = this.props.Item.Started > 0 || this.props.Item.Released > 0;

		return (
			<div className="clearfix">
				<ProductItemRow
					Item={this.props.Item}
					CanModificate={this.props.Item.Modificators === 1}
					IsStart={isStart}

					handleChange={this.handleChangeProduct.bind(this)}
					openModificators={this.openModificators.bind(this)}
				/>
				{!_.isEmpty(this.props.Item.Modifications) && this.props.Item.Modificators === 1 &&
					_.map(this.props.Item.Modifications, function (mod, i) {
						return (<ProductItemRow key={i}
							Item={mod}
							IsModificator={true}
							IsStart={isStart}
							Index={i}

							handleChange={view.handleChangeMod.bind(view)}
						/>)
					})}
			</div>
		)
	}
}
ProductItem = observer(ProductItem);

class ProductItemRow extends Component {
	/**
	 * Сбор данных с формы ввода продукта/модификатора
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange(value, id) {
		if (!/Count/.test(id)) return false;

		console.log("internal change", this.props.IsModificator)
		console.log(this.props.Item)

		if (!this.props.IsModificator) this.props.handleChange(value);
		else this.props.handleChange(value, this.props.Index, this.props.Item.ProductId);
	}

	render() {
		var item = this.props.Item,
			canManage = checkRights("ManageOrders") || checkRights("CanCreateOrder"),
			productClass = (this.props.IsStart ? "started" : "") + (this.props.IsModificator ?
				" mod " : "") + (item.WrongPosType ? " alert" : "");

		return (
			<div className="clearfix item">
				<div className={(uistate.IsMobile ? "col col30 " : "col col35 ")}>
					<span className={productClass}>
						{/* {this.props.IsModificator && Parser("&npsp;+&npsp")} */}
						{item.ProductName}
					</span>
					{this.props.CanModificate && <a href="/" className="mods"
						onClick={this.props.openModificators}><i>+</i>Ингредиенты</a>
					}
				</div>
				<SpinnerGroup
					Id="Count"
					RowClass={uistate.IsMobile ? "col col35" : "col col25"}
					Value={item.Count}
					Disabled={item.Disabled || !canManage}
					DisabledLess={!orderState.IsEndProcess && this.props.IsStart}
					onChange={this.handleChange.bind(this)}
				/>
				{!uistate.IsMobile && <span className="col col20">{getRubs(item.Price, true, true)}</span>}
				{!uistate.IsMobile && <span className="col col20">{getRubs(item.Amount, true, true)}</span>}
				{uistate.IsMobile && <span className="col col35">{getRubs(item.Price, true, true)}/
					{getRubs(item.Amount, true, true)}</span>}
			</div>
		)
	}
}
ProductItemRow = observer(ProductItemRow);

class ProductModificatorsBlock extends Component {
	/** Закрытие окна добавления модификаторов */
	handleBackClose(event) {
		if (event.target.getAttribute("rel") === "back") orderState.ModificatorsList = undefined;
	}

	/** Добавление в заказ модификатора из списка */
	handleModificatorAdd(event) {
		event.preventDefault();
		var id = parseInt(event.currentTarget.getAttribute("rel"), 10),
			modificator = orderState.ModificatorsList;

		orderState.checkModificators(id, modificator.ProductIndex);
	}

	render() {
		var view = this,
			position = orderState.ModificatorsList.Position,
			products = orderState.ModificatorsList.List,
			style = { left: position ? position.left : 0, top: position ? position.top : 0 };


		return (
			<div className="back" rel="back" onClick={this.handleBackClose.bind(this)}>
				<div className="window modificators" style={style}>
					{!_.isEmpty(products) && _.map(products, function (v, i) {
						return (<a href="/" className="clearfix item" key={i} rel={v.ProductId}
							onClick={view.handleModificatorAdd.bind(view)}>
							<span className="col col70">{v.ProductName}</span>
							<span className="col col30 price">{getRubs(v.Price, true, true, " ")} руб.</span>
						</a>)
					})}
					{_.isEmpty(products) && <p>Для данного товара нет модификаторов</p>}
				</div>
			</div>
		)
	}
}
ProductModificatorsBlock = observer(ProductModificatorsBlock);

class PaymentBlock extends Component {
	/**
	 * Сбор данных с формы заказа
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange(value, id) {
		if (/POSId/.test(id)) orderState.POSId = value;
		else orderState.collectOrder(id, value);

		var textInputs = ["Discount", "TableNumber", "ChangeFrom"];

		if (this.props.createOrder && _.indexOf(textInputs, id) === -1 &&
			(orderState.Order.Id === -1 || _.isString(orderState.Order.Id))) this.props.createOrder();

		this.props.changeHeight();
	}
	/** Уход фокуса с поля формы */
	handleBlur() {
		if (this.props.createOrder && (orderState.Order.Id === -1 || _.isString(orderState.Order.Id)))
			this.props.createOrder();
	}

	/** Сбор данных с пресетов */
	handleClickPreset(event) {
		event.preventDefault();
		var amount = parseInt(event.currentTarget.getAttribute("rel"), 10);

		if (!_.isNaN(amount)) orderState.collectOrder("ChangeFrom", amount);
	}
	/** Сбор данных с калькулятора */
	handleClickNumber(event) {
		event.preventDefault();

		if (event.currentTarget.getAttribute("rel") === "clear") orderState.collectOrder("ChangeFrom", "");
		else {
			var amount = parseInt(event.currentTarget.getAttribute("rel"), 10),
				changeFrom = orderState.Order.ChangeFrom === "" ? 0 : orderState.Order.ChangeFrom;

			if (!_.isNaN(amount)) orderState.collectOrder("ChangeFrom", changeFrom * 10 + amount);
		}
	}

	/** API запрос «GetContent» получения акций по бренду */
	openSales(event) {
		event.preventDefault();
		getRequest("GetContent", { Id: 4, BrandId: orderState.Order.BrandId }, function (data) {
			orderState.setContent(data.Success ? data.Content : undefined);
		});
	}

	render() {
		var canManage = checkRights("ManageOrders") || checkRights("CanCreateOrder"),
			canSetPaid = !orderState.IsFiscalOrder && !orderState.IsCashOrder &&
				authUserState.Shift.Active && checkRights("CanSetOrderPayed") &&
				_.indexOf(["", -1, 0], orderState.Order.PaymentMethodId) === -1,
			isPaid = orderState.IsOrderExist && orderState.Order.Paid,
			isCIH = orderState.IsCashInHand && checkRights("CanChangePOS");

		return (
			<div className="clearfix block payment">
				<div className="clearfix amountBlock block">
					<div className="col clo30 sales">
						<a href="/" className="button blue" onClick={this.openSales.bind(this)}>Акции</a>
					</div>
					<div className="col col70 total">
						<h5 className="col col75">Всего продукции на сумму</h5>
						<span className="col col25">{getRubs(orderState.Amount)}</span>
						<h5 className="col col75">Скидка на сумму</h5>
						<TextInput
							Id="Discount"
							Type="number"
							Step="0.01"
							Value={orderState.Order.Discount}
							Disabled={!canManage || orderState.Order.IsFinalStatus || orderState.IsFiscalPaidOrder || isCIH}
							RowClass="col col25"
							onChange={this.handleChange.bind(this)}
							onBlur={this.handleBlur.bind(this)}
						/>
						<h5 className="col col75">К оплате</h5>
						<span className="col col25">{getRubs(orderState.TotalAmount)}</span>
					</div>
				</div>
				<div className="clearfix block">
					<SpinnerGroup
						Id="PersonsNumber"
						Title="Количество персон"
						Value={orderState.Order.PersonsNumber}
						Min={1}
						RowClass="col col50"
						Disabled={!canManage || orderState.Order.IsFinalStatus}
						onChange={this.handleChange.bind(this)}
					/>
					{orderState.Order.OrderTypeId === 1 &&
						<TextInput
							Id="TableNumber"
							Type="number"
							Title="Номер столика"
							Value={orderState.Order.TableNumber}
							RowClass="col col40"
							Disabled={!canManage || orderState.Order.IsFinalStatus}
							onChange={this.handleChange.bind(this)}
							onBlur={this.handleBlur.bind(this)}
						/>
					}
				</div>
				<div className="clearfix block">
					<div className="clearfix">
						{isPaid &&
							<p className="comment">Заказ уже оплачен. Для того что бы изменить
							способ оплаты, необходимо в списке заказов нажать на кнопку
							«Отменить оплату» и затем уже изменить способ оплаты.</p>
						}
						{isCIH &&
							<p className="comment">
								Нельзя изменить изменить точку продаж, так как курьеру выданы деньги из кассы.
								Верните деньги, откатите статус заказа до выдачи сдачи и измените точку продаж.
							</p>
						}
					</div>
					<div className="col col50">
						{!orderState.Order.IsFinalStatus &&
							<CheckGroup
								Id="PaymentMethodId"
								Title="Какой тип оплаты?"
								Type="radio"
								ParseType="number"
								RowClass="paymentMethod"
								Value={[orderState.Order.PaymentMethodId]}
								Disabled={!canManage || orderState.Order.IsFinalStatus || orderState.Order.Paid}
								List={toCheckList(orderState.availableMethods)}
								onChange={this.handleChange.bind(this)}
							/>
						}
						{orderState.Order.IsFinalStatus &&
							<div className="clearfix">
								<h5>Метод оплаты</h5>
								<p>{orderState.Order.PaymentMethodName}</p>
							</div>
						}
						{canSetPaid &&
							<CheckGroup
								Id="Paid"
								Type="checkbox"
								List={toCheckList([{ Id: "true", Name: "Заказ оплачен" }])}
								Value={orderState.Order.Paid ? ["true"] : []}
								Disabled={isPaid}
								onChange={this.handleChange.bind(this)}
							/>
						}
					</div>
					<div className="col col50">
						{orderState.IsCashOrder && !orderState.Order.IsFinalStatus &&
							<ChangeFromBlock
								TotalAmount={orderState.TotalAmount}
								ChangeFrom={orderState.Order.ChangeFrom}
								Change={orderState.Change}
								CashInHand={orderState.Order.CashInHand}

								handleBlur={this.handleBlur.bind(this)}
								handleChange={this.handleChange.bind(this)}
								handleClickPreset={this.handleClickPreset.bind(this)}
								handleClickNumber={this.handleClickNumber.bind(this)}
							/>
						}
						{orderState.IsCashOrder && orderState.Order.IsFinalStatus && orderState.Order.ChangeFrom !== 0 &&
							<p>Купюра: {getRubs(orderState.Order.ChangeFrom, true, true)} руб.</p>
						}
						{checkRights("CanChangePOS") && orderState.availablePOS.length > 1 &&
							<Selectize
								Id="POSId"
								Title="Точка продаж"
								List={toSelectList(orderState.availablePOS)}
								Disabled={orderState.Order.IsFinalStatus || orderState.Order.Paid || orderState.IsFiscalPaidOrder || isCIH}
								Value={orderState.calculatedPosId}
								onChange={this.handleChange.bind(this)}
							/>
						}
					</div>
				</div>
				{orderState.ReferenceTime !== "" && <ReferenceBlock />}
			</div>
		)
	}
}
PaymentBlock = observer(PaymentBlock);

export class ChangeFromBlock extends Component {
	render() {
		return (
			<div className={"clearfix changeBlock " + (this.props.Type ? this.props.Type : "")}>
				<h4>Расчет сдачи</h4>
				<div className="clearfix main">
					<p className="col col50">Итого</p>
					<p className="col col50">{getRubs(this.props.TotalAmount)}</p>
					<p className="col col50">Внесено</p>
					{!uistate.IsMobile &&
						<TextInput
							Id="ChangeFrom"
							Type="number"
							Step={0.01}
							Disabled={_.isUndefined(this.props.CashInHand) ? false : this.props.CashInHand > 0}
							RowClass="nomargin col col50"
							Value={this.props.ChangeFrom}
							onChange={this.props.handleChange}
							onBlur={this.props.handleBlur}
						/>
					}
					{uistate.IsMobile &&
						<p className="col col50">{getRubs(this.props.ChangeFrom)}</p>
					}
					<p className="col col50">Сдача</p>
					<p className="col col50">{getRubs(this.props.Change)}</p>
				</div>
				<div className="clearfix calc">
					<div className="numbers col col75">
						<div className="col col1_3">
							<a href="/" rel="7" onClick={this.props.handleClickNumber}>7</a>
							<a href="/" rel="4" onClick={this.props.handleClickNumber}>4</a>
							<a href="/" rel="1" onClick={this.props.handleClickNumber}>1</a>
						</div>
						<div className="col col1_3">
							<a href="/" rel="8" onClick={this.props.handleClickNumber}>8</a>
							<a href="/" rel="5" onClick={this.props.handleClickNumber}>5</a>
							<a href="/" rel="2" onClick={this.props.handleClickNumber}>2</a>
							<a href="/" rel="0" onClick={this.props.handleClickNumber}>0</a>
						</div>
						<div className="col col1_3">
							<a href="/" rel="9" onClick={this.props.handleClickNumber}>9</a>
							<a href="/" rel="6" onClick={this.props.handleClickNumber}>6</a>
							<a href="/" rel="3" onClick={this.props.handleClickNumber}>3</a>
							<a href="/" rel="clear" onClick={this.props.handleClickNumber} className="clear">C</a>
						</div>
					</div>
					<div className="preset col col25">
						<a href="/" onClick={this.props.handleClickPreset} rel="5000">5&nbsp;000</a>
						<a href="/" onClick={this.props.handleClickPreset} rel="2000">2&nbsp;000</a>
						<a href="/" onClick={this.props.handleClickPreset} rel="1000">1&nbsp;000</a>
						<a href="/" onClick={this.props.handleClickPreset} rel="500">500</a>
					</div>
				</div>
			</div>
		)
	}
}
ChangeFromBlock = observer(ChangeFromBlock);

class ReferenceBlock extends Component {
	render() {
		var isExist = orderState.IsOrderExist,
			times = orderState.setOrderTimes(orderState.Order, orderState.ReferenceTime),
			isComplete = !_.isNull(times.CompleteMinutes);

		return (
			<div className="clearfix block">
				{_.isString(orderState.Order.Id) && <p>Приблизительное время доставки:</p>}
				{_.isString(orderState.Order.Id) &&
					<p><b>{_.clone(orderState.ReferenceTime) - 5} – {_.clone(orderState.ReferenceTime) + 5} минут</b></p>
				}
				{isExist && !isComplete && !_.isNull(times.LeftMinutes) && <p>Осталось: </p>}
				{isExist && !isComplete && !_.isNull(times.LeftMinutes) &&
					<p><b>{_.clone(times.LeftMinutes - 5)} – {_.clone(times.LeftMinutes + 5)} минут</b></p>
				}
				{isExist && isComplete && <p>Заказ доставлен за:</p>}
				{isExist && isComplete && <p><b>{getHourTime(times.CompleteMinutes)}</b></p>}
			</div>
		)
	}
}
ReferenceBlock = observer(ReferenceBlock);

class MenuInfo extends Component {
	constructor(props) {
		super(props);

		this.state = { Open: false }
	}

	/** Смена активного раздела продуктов */
	handleClick(event) {
		event.preventDefault();
		if (/active/.test(event.currentTarget.className)) return false;

		var id = parseInt(event.currentTarget.getAttribute("rel"), 10);
		orderState.SectionActive = id;
	}

	/**
	 * Сбор данных с формы поиска продуктов
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleFilter(value, id) { orderState.collectSearch(id, value, "input"); }

	/**
	 * Выбор продукта в корзину из выпадающего списка
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleCheck(value, id) {
		if (orderState.IsEndProcess) return false;

		var model = this,
			product = _.find(orderState.productsInSection, { Id: value });

		if (product) {
			orderState.checkProducts(value);
			orderState.OrderStateActive = "order";

			if (!orderState.IsOrderExist && this.props.createOrder) this.props.createOrder();
		} else orderState.collectSearch(id, value, "check");

		if (!uistate.isDevice) setTimeout(function () { model.props.setHeight(); }, 100);
	}

	render() {
		var view = this,
			mobile = uistate.IsMobile,
			products = _.map(_.clone(orderState.Products), "ProductId"),
			showProducts = !uistate.IsMobile || (uistate.IsMobile && orderState.ShowProducts),
			hasComment = orderState.IsEndProcess || orderState.IsCashInHand;

		return (
			<div className={"menu " + (mobile ? "clearfix" : "col col45")}
				style={mobile ? {} : orderState.menuHeight}>
				<div className="clearfix table">
					<div className="clearfix row">
						{!mobile && <MenuSection handleClick={this.handleClick.bind(this)} />}
						<div className={"products " + (mobile ? "clearfix" : "col")}>
							<MenuSearch
								handleCheck={this.handleCheck.bind(this)}
								handleFilter={this.handleFilter.bind(this)}
								handleClick={this.handleClick.bind(this)}
							/>
							{hasComment &&
								<div className="clearfix">
									{orderState.IsEndProcess &&
										<p className="comment">Новые товары можно добавить только в&nbsp;статусах:
										Новый, Подтвержден, в&nbsp;Производстве. Поменяйте статус заказа
										на&nbsp;допустимый прежде, чем&nbsp;добавить новый товар.</p>
									}
									{orderState.IsCashInHand && <p className="comment">{errors.COURIER_CIH_PRODUCTS}</p>}
								</div>
							}
							{showProducts &&
								<div className="clearfix block productList">
									<div className="clearfix productContainer">
										{!_.isUndefined(orderState.filteredProducts) &&
											_.map(orderState.filteredProducts, function (item) {
												var active = _.indexOf(products, item.Id) !== -1;

												return (<MenuProductItem
													key={item.Id} Item={item} Active={active}
													setHeight={view.props.setHeight}
													createOrder={view.props.createOrder}
												/>)
											})}
									</div>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		)
	}
}
MenuInfo = observer(MenuInfo);

class MenuSection extends Component {
	render() {
		var view = this,
			sections = orderState.filteredSections,
			active = orderState.SectionActive;

		return (
			<div className="col sections">
				<a href="/" className={"item " + (active === -1 ? "active" : "")} rel="-1"
					onClick={this.props.handleClick}>Все</a>
				{!_.isUndefined(sections) && _.map(sections, function (item, i) {
					return (<a href="/" className={"item " + (active === item.Id ? "active" : "")}
						rel={item.Id} key={i} onClick={view.props.handleClick}
						title={item.Name}>{item.Name}</a>)
				})}
			</div>
		)
	}
}
MenuSection = observer(MenuSection);

class MenuSearch extends Component {
	/** Скрыть/показать фильтр по тэгам */
	toggleFilter(event) {
		event.preventDefault();
		orderState.ShowTagFilter = !orderState.ShowTagFilter;
	}

	/** Скрыть/показать фильтр по тэгам */
	toggleProducts(event) {
		event.preventDefault();
		orderState.ShowProducts = !orderState.ShowProducts;
	}

	/**
	* Выбор раздела меню
	* @param {number} value значение параметра
	 */
	changeMobileForm(value) { orderState.SectionActive = value; }

	render() {
		return (
			<div className="clearfix searchBlock">
				{uistate.IsMobile &&
					<div className="clearfix">
						<Selectize
							Id="SectionId"
							Title="Разделы меню"
							List={toSelectList(orderState.filteredSections)}
							Value={orderState.SectionActive}
							onChange={this.changeMobileForm}
						/>
					</div>
				}
				<div className="clearfix">
					<Selectize
						Id="SearchProducts"
						Placeholder="Поиск по разделу..."
						Icon="search"
						List={toSelectList(orderState.productsInSection)}
						Value={orderState.SearchProducts}
						Disabled={orderState.Order.IsFinalStatus || orderState.IsFiscalPaidOrder}
						RowClass="col col80 searchInput nomargin"
						onChange={this.props.handleCheck}
						onInputChange={this.props.handleFilter}
					/>
					<a className={"icon toggleFilter " + (orderState.ShowTagFilter ? "active" : "")}
						href="/" onClick={this.toggleFilter.bind(this)}><i></i></a>
					{uistate.IsMobile &&
						<a className={"icon toggleProducts " + (orderState.ShowProducts ? "active" : "")}
							href="/" onClick={this.toggleProducts.bind(this)}><i></i></a>
					}
				</div>
				{orderState.ShowTagFilter &&
					<TagsPreset
						Id="ProductTags"
						List={orderState.Tags}
						Value={orderState.ProductTags}
						onChange={this.props.handleFilter}
					/>
				}
			</div>
		)
	}
}
MenuSearch = observer(MenuSearch);

class MenuProductItem extends Component {
	/** Добавление продуктов в корзину по клику на плитку в меню */
	handleCheck(event) {
		event.preventDefault();

		if (/disabled/.test(event.currentTarget.getAttribute("class"))) return false;

		orderState.checkProducts(this.props.Item.Id);
		orderState.OrderStateActive = "order";

		if (!orderState.IsOrderExist && this.props.createOrder) this.props.createOrder();

		var model = this;
		if (!uistate.isDevice) setTimeout(function () { model.props.setHeight(); }, 100);
	}

	render() {
		var item = this.props.Item,
			style = item.Color !== "" && !item.Disabled ? { background: item.Color } : {},
			tooltipStyle = { maxWidth: 150 },
			tooltipText = <p>
				{item.POSTypesTitle !== "" && <span>{item.POSTypesTitle}</span>}
				{item.POSTypesTitle !== "" && <br />}
				<span><b>{item.Name}</b></span><br />
				{item.Description !== "" && <span>{item.Description}</span>}
				{item.Description !== "" && <br />}
				<span>КБЖУ на блюдо {getCPFC(item.Calories, item.WeightReady)}/
					{getCPFC(item.Proteins, item.WeightReady)}/
					{getCPFC(item.Fats, item.WeightReady)}/
					{getCPFC(item.Carbohydrates, item.WeightReady)}</span><br />
				<span>Вес {item.WeightReady} г.</span>
			</p>

		return (
			<div className="item col">
				<Tooltip
					overlay={tooltipText}
					overlayStyle={tooltipStyle}
					mouseLeaveDelay={0}
					placement="bottom"
					arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
				>
					<a href="/" style={style} onClick={this.handleCheck.bind(this)}
						className={(this.props.Active ? "active" : "") + (item.Disabled ? " disabled" : "")} >
						<span className="name">{item.Name}</span>
						{item.Price !== "" && <span className="price">{getRubs(item.Price, true, true)}</span>}
					</a>
				</Tooltip>
			</div>
		)
	}
}
MenuProductItem = observer(MenuProductItem);

class SalesBlock extends Component {
	/** Заркытие окна с акциями */
	handleClose(event) {
		event.preventDefault()
		orderState.setContent(undefined);
	}

	/** Закрытие окна с акциями по клику вне блока */
	handleBackClose(event) {
		if (event.target.getAttribute("rel") === "back") orderState.setContent(undefined);
	}

	/** Открытие/Закрытие текста статей */
	toggleArticle(event) {
		event.preventDefault();
		var id = parseInt(event.currentTarget.getAttribute("rel"), 10);

		orderState.OpenContent = orderState.OpenContent === id ? 0 : id;
	}

	render() {
		var view = this,
			groups = orderState.SaleContent,
			style = uistate.windowLargeDimensions;

		return (
			<div className="back" onClick={this.handleBackClose.bind(this)} rel="back">
				<div className="clearfix content window large" style={style}>
					<a href="/" className="close" onClick={this.handleClose.bind(this)}><i></i></a>
					<div className="clearfix">
						{_.isEmpty(groups) && <p>{errors.EMPTY_RESPONSE}</p>}
						{!_.isEmpty(groups) && _.map(groups, function (group, i) {

							return (<div className="clearfix group" key={i}>
								<h3 className="clearfix">{group.Name}</h3>
								{_.map(group.Group, function (item, k) {
									var isOpenSale = orderState.OpenContent === item.Id;

									return (<div className={"clearfix article " + (isOpenSale ? "open" : "")} key={k}>
										<h4 className="clearfix"><a href="/" className="toggleContent icon"
											onClick={view.toggleArticle.bind(view)} rel={item.Id}>
											<i></i>{item.Name}</a>
										</h4>
										{isOpenSale &&
											<div className="clearfix body">{Parser(item.Text)}</div>
										}
									</div>)
								})}
							</div>)
						})}
					</div>
				</div>
			</div>
		)
	}
}
SalesBlock = observer(SalesBlock);


class CustomerBlock extends Component {
	constructor(props) {
		super(props);

		getRequest("Orders", { CustomerId: orderState.Customer.Id, IsHome: -1 }, function (data) {
			orderState.Customer.OrdersHistory = data.Success ? _.map(data.Orders, function (item) {
				var times = orderState.setOrderTimes(item);

				return _.extend(item, { CompleteMinutes: times.CompleteMinutes });
			}) : [];
		});
	}

	/** Закрытие окна с клиентом */
	handleClose(event) {
		event.preventDefault();
		if (!this.props.InOrder) orderState.setCustomer(undefined);
		orderState.ShowCustomerCard = false;
		orderState.UserAction = false;
	}

	/** Смена активной вкладки */
	toggleTabs(event) {
		event.preventDefault();
		orderState.CustomerTab = replace(event.currentTarget.getAttribute("href"), "#", "");
	}

	render() {
		var style = uistate.windowLargeDimensions,
			tab = orderState.CustomerTab,
			canViewStat = checkRights("ViewCustomerStat"),
			canViewHistory = checkRights("ViewCustomerHistory");

		return (
			<div className="back" style={{ display: "block" }}>
				<div className="clearfix window customerItem large" id="CustomerBlock" style={style}>
					<div className="clearfix windowHeader">
						<h4>{orderState.Customer.Name}</h4>
						<a href="/" className="close" onClick={this.handleClose.bind(this)}><i></i></a>
					</div>
					<div className="clearfix windowContainer">
						<div className="clearfix tabsNav">
							<a href="#info" className={tab === "info" ? "active" : ""}
								onClick={this.toggleTabs.bind(this)}>Информация</a>
							{canViewStat &&
								<a href="#stat" className={tab === "stat" ? "active" : ""}
									onClick={this.toggleTabs.bind(this)}>Статистика</a>
							}
							{canViewHistory &&
								<a href="#orders" className={tab === "orders" ? "active" : ""}
									onClick={this.toggleTabs.bind(this)}>Заказы</a>
							}
						</div>
						<div className="clearfix tabsContent">
							{tab === "info" && <CustomerMain Info={orderState.Customer} />}
							{tab === "stat" &&
								<div className="clearfix tab">
									{!_.isEmpty(orderState.Customer.OrdersStat) &&
										<CustomerOrderStat Stat={orderState.Customer.OrdersStat} Count={6} />
									}
									{!_.isNull(orderState.Customer.Brands) &&
										<CustomerBrandsStat Brands={orderState.Customer.Brands} />
									}
								</div>
							}
							{tab === "orders" &&
								<div className="clearfix tab">
									{!_.isEmpty(orderState.Customer.OrdersHistory) &&
										<CustomerOrderHistory History={orderState.Customer.OrdersHistory}
											CanRepeatOrder={true} />
									}
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		)
	}
}
CustomerBlock = observer(CustomerBlock);

class CustomerMain extends Component {
	render() {
		var info = this.props.Info;

		return (
			<div className="clearfix tab mainInfo">
				<div className="col col60">
					<MainCustomerInfo Info={info} Type="view" BlockClass="clearfix block" />
					<AddressBlock Info={info.Address} Type="view" BlockClass="clearfix block" />
				</div>
				<div className="col col40">
					{!_.isUndefined(info.Stat) && <CustomerStatCommon Stat={info.Stat} />}
				</div>
			</div>
		)
	}
}
CustomerMain = observer(CustomerMain);

class QRCodeOrderBlock extends Component {
	/** Закрытие окна с QR кодом */
	handleClose(event) {
		event.preventDefault();
		orderState.setQRCode(undefined);
	}

	render() {
		var order = orderState.QRCode;

		return (
			<div className="back">
				<div className="clearfix qrcode window medium">
					<a href="/" className="close" onClick={this.handleClose.bind(this)}><i></i></a>
					<div className="clearfix">
						<h4>QR код для получения чека по заказу №{order.OrderNumber}</h4>
						<img src={"/api/GetQRCodeOrder?OrderId=" + order.OrderId}
							alt={"QR по заказу №" + order.OrderNumber} />
					</div>
				</div>
			</div>
		)
	}
}
QRCodeOrderBlock = observer(QRCodeOrderBlock);

class ComponentToPrint extends Component {
	render() {
		var height = this.props.Bill.Manufacturer !== "" ? 1050 : 525,
			format = _.find(PrintFormatesTitles, { Id: this.props.Format }),
			type = format ? format.Type : "",
			width = format ? format.Width : "";

		if (type === "tape")
			return (<BillTapeItem Bill={this.props.Bill} Width={width} />)

		if (type === "paper" && this.props.Format !== 4)
			return (<BillPaperItem Bill={this.props.Bill} Width={width} />)

		if (type === "paper" && this.props.Format === 4)
			return (<div>
				<BillPaperItem Bill={this.props.Bill} Width={width} Height={height} />
				<BillPaperItem Bill={this.props.Bill} Width={width} Height={height} />
			</div>)

		return null;
	}
}
