/**
 * /pages/Merchant.js – Работа со настройками предприятия
 * /stores/merchant.js – бизнес логика
 * 
 * Страница доступна только «директору» предприятия
 * 
 * Для получения информации о предприятии необходимо сделать запрос «Merchant»
 * Для изменения настроек предприятия необходимо сделать запрос «SaveMerchant»
 * 
 * На странице блоки настроек зависят от типа предприятия и от подключенных модулей
 */

import React, { Component } from 'react';
import { observer } from "mobx-react";
import _ from 'lodash';

import account from '../images/account-blocked.png';

import { ItemHeader } from './PageTemplates';
import { TextInput, CheckGroup, Selectize, FileInput, EditableTagsPreset } from '../forms/FormItems';

import { toSelectList, getRequest, toCheckList, getUpload, formatDate } from '../functions';
import { buttonTitles, merchantTypes } from '../dictionary';

import { merchantState } from '../stores/merchant';
import { authUserState, uistate } from '../stores/common';
import { DeleteIcon } from '../images/icons';

export class MerchantPage extends Component {
	constructor(props) {
		super(props);

		merchantState.setMerchant(undefined);

		// this.state = {};

		// this.getData = this.getData.bind(this);
		if (authUserState.User.IsAdmin) this.getData();
	}

	/** API запрос «Merchant» на получение настроек выбранного предприятия */
	getData() {
		merchantState.IsSendRequest = false;
		getRequest("Merchant", {}, (data) => { 
			merchantState.setMerchant(data.Success ? data : undefined); 
		});
	}

	/** API запрос «SaveMerchant» на сохранение данных выбранного предприятия */
	handleSubmit(event) {
		event.preventDefault();

		if (!merchantState.validateData()) return false;

		merchantState.ButtonLoading = true;

		getUpload("SaveMerchant", merchantState.getSaveData(), function (data) {
			merchantState.ButtonLoading = false;
			merchantState.ErrorCode = data.Success ? "SUCCESS_SAVE" : data.ErrorCode;
			merchantState.SuccessSave = data.Success;
		});
	}

	changeTab(event) {
		event.preventDefault();
		merchantState.ActiveTab = event.currentTarget.dataset.tab
		// event.currentTarget.dataset.datefrom
	}

	render() {
		var mail = {
			subject: "Оплата ЧЛ: " + authUserState.CurrentMerchant.Name,
			body: "Я хочу оплатить сервис по текущему тарифу на ___ месяцев. Необходимо количество пользователей " + authUserState.CurrentMerchant.UserLimit
		};

		return (
			<div className={"page " + (uistate.IsMobile ? "mobile" : "")} id="MerchantPage">
				<section className={"page clearfix item two-cols " + (uistate.IsMobile ? "mobile" : "")}>
					{merchantState.IsSendRequest && !_.isUndefined(merchantState.Merchant) && merchantState.Merchant.Active &&
						<form onSubmit={this.handleSubmit.bind(this)}>
							<ItemHeader 
								Title="Настройки предприятия"
								Error={merchantState.ErrorCode}
								Loading={merchantState.ButtonLoading}
								CanManage={authUserState.User.IsAdmin}
								Success={merchantState.SuccessSave}
								HideClose={true}
							/>
							{/* <div className='tabs'>
								<a href='/' data-tab="common" onClick={this.changeTab.bind(this)} className={merchantState.ActiveTab === "common" ? "active" : ""}>Общие настройки</a>
								{authUserState.HasTips &&
									<a href='/' data-tab="tips" onClick={this.changeTab.bind(this)} className={merchantState.ActiveTab === "tips" ? "active" : ""}>Настройки чаевых</a>
								}
								{authUserState.HasERP &&
									<a href='/' data-tab="bonus" onClick={this.changeTab.bind(this)} className={merchantState.ActiveTab === "bonus" ? "active" : ""}>Настройки системы лояльности</a>
								}
							</div> */}
							<div className="clearfix section flexSection">
								{/* <div className={"main " + (uistate.IsMobile ? "block" : "")}> */}
									<CommonSettings />
									{!authUserState.isCallCentre && authUserState.HasERP &&
										<h3>Настройки работы ERP системы</h3>
									}
									{!authUserState.isCallCentre && (authUserState.HasERP || authUserState.HasTips) && 
										<ListsBlock Type="Entities" />
									}
									{!authUserState.isCallCentre && (authUserState.HasERP || authUserState.HasTips) && 
										<ListsBlock Type="Brands" />
									}
									{!authUserState.isCallCentre && authUserState.HasERP &&
										<ListsBlock Type="Aggregators" />
									}
									{!authUserState.isCallCentre && authUserState.HasERP &&
										<ListsBlock Type="PaymentMethods" />
									}
								{/* </div> */}
								{/* <div className={"gray"}> */}
									{!authUserState.isCallCentre && authUserState.HasERP &&
										<OrderSettings />
									}
									{!authUserState.isCallCentre && authUserState.HasTips &&
										<TipsSettings />
									}
									{!authUserState.isCallCentre && authUserState.HasERP &&
										<BonusSettings />
									}
								{/* </div> */}
							</div>
						</form>
					}
					{merchantState.IsSendRequest && _.isUndefined(merchantState.Merchant) &&
						<p>У вас нет прав на просмотр данного раздела</p>
					}
					{merchantState.IsSendRequest && !_.isUndefined(merchantState.Merchant) && !merchantState.Merchant.Active &&
						<div className='blockedMerchant section'>
							<h4>У вас закончился оплаченный период</h4>
							<img src={account} alt="Blocked merchant by Storyset" />
							<p>Для оплаты напишите пожалуйста на <a href={"mailto:sales@ectrum.ru?body=" + mail.body + "&subject=" + mail.subject} target="_blank" rel="noopener noreferrer">sales@ectrum.ru</a></p>
						</div>
					}
				</section>
			</div>
		)
	}
}
MerchantPage = observer(MerchantPage);

class CommonSettings extends Component {
	render() {
		var type = _.find(merchantTypes, { Id : merchantState.Merchant.MerchantType }),
			token = merchantState.Merchant.Token,
			showBlock = type || (token && token !== ""),
			mail = {
				subject: "Оплата ЧЛ: " + merchantState.Merchant.Name,
				body: "Я хочу оплатить сервис по текущему тарифу на ___ месяцев. Необходимо количество пользователей " + merchantState.Merchant.UserLimit
			},
			mailSupportSubject = "Обращение в ТП: " + merchantState.Merchant.Name;

		return(
			<div className="main">
				<div className='commonSettings'>
					<TextInput
						Id="Name"
						Title="Название предприятия"
						Required={true}
						Value={merchantState.Merchant.Name}
						onChange={(value,id) => merchantState.collectMerchant(id, value)}
					/>
					{/* {!authUserState.isCallCentre &&
						<Selectize
							Id="Currency"
							Title="Валюта предприятия"
							List={toSelectList([{ Id : "rub", Name : "Рубли" }])}
							Value={merchantState.Merchant.Currency}
							onChange={(value,id) => merchantState.collectMerchant(id, value)}
						/>
					} */}
					{showBlock &&
						<div className="type">
							{type && <p>Тип предприятия: {type.Name}</p>}
							{token && token !== "" && <p>Авторизационный токен: {token}</p>}
						</div>
					}
				</div>
				<div className='paymentInfo'>
					<h5>Информация о тарифе и оплате</h5>
					{merchantState.Merchant.TestPeriod &&
						<>
							<p>Вы пользуетесь бесплатной версией. Тестовый доступ предоставлен до <b>{formatDate(merchantState.Merchant.PaymentDate)}</b></p>
							<p>По вопросам оплаты и изменения лимитов пользователей, напишите пожалуйста на <a href={"mailto:sales@ectrum.ru?body=" + mail.body + "&subject=" + mail.subject} target="_blank" rel="noopener noreferrer">sales@ectrum.ru</a></p>
						</>
					}
					{!merchantState.Merchant.TestPeriod && merchantState.Merchant.PaymentDate !== "" &&
						<>
							<p>Сервис оплачен до <b>{formatDate(merchantState.Merchant.PaymentDate)}</b></p>
							<p>По вопросам оплаты и изменения лимитов пользователей, напишите пожалуйста на <a href={"mailto:sales@ectrum.ru?body=" + mail.body + "&subject=" + mail.subject} target="_blank" rel="noopener noreferrer">sales@ectrum.ru</a></p>
						</>
					}
					{merchantState.Merchant.PaymentDate === "" &&
						<>
							<p>Сервис предоставлен в бессрочное пользование</p>
						</>
					}
					{merchantState.Merchant.UserLimit !== -1 &&
						<p>Количество активных пользователей на текущем тарифе: <b>{merchantState.Merchant.UserLimit}</b></p>
					}
					{merchantState.Merchant.UserLimit === -1 &&
						<p>Количество пользователей на текущем тарифе: <b>безлимитно</b></p>
					}
					<p className='support'>Если у вас возникли вопросы или какие либо проблемы с настройкой сервиса, напишите в нашу тех поддержку <a href={"mailto:support@ectrum.ru?subject=" + mailSupportSubject} target="_blank" rel="noopener noreferrer">support@ectrum.ru</a>, мы поможем вам со всем разобраться.</p>
				</div>
			</div>
		)
	}
}
CommonSettings = observer(CommonSettings);

class OrderSettings extends Component {
	render() {
		return(
			<div className="clearfix block">
				<h4>Настройки приема заказов</h4>
				<CheckGroup
					Id="OrderNumberType"
					List={toCheckList([{ Id : "true", Name : "Сброс нумерации заказов каждый день" }])}
					Value={merchantState.Merchant.OrderNumberType ? ["true"] : []}
					RowClass="nomargin"
					Type="checkbox"
					onChange={(value,id) => merchantState.collectMerchant(id, value)}
				/>
				<CheckGroup
					Id="RoundOrder"
					List={toCheckList([{ Id : "true", Name : "Округлять сумму заказа до рубля" }])}
					Value={merchantState.Merchant.RoundOrder ? ["true"] : []}
					RowClass="nomargin"
					Type="checkbox"
					onChange={(value,id) => merchantState.collectMerchant(id, value)}
				/>
			</div>
		)
	}
}
OrderSettings = observer(OrderSettings);

class TipsSettings extends Component {
	render () {
		var merchant = merchantState.Merchant,
			commissionList = [
				{ Id : 0, Name : "Не выводить" },
				{ Id : 1, Name : "Выводить, не выбрано" },
				{ Id : 2, Name : "Выводить, выбрано по умолчанию" },
				{ Id : 3, Name : "Выводить, выбрано всегда" },
			],
			optionList = [
				{ Id : 0, Name : "Без опций" },
				{ Id : 1, Name : "С предустановленными суммами чаевых" },
				{ Id : 2, Name : "С суммой чека и процентами", Disabled : true },
			];
		
		return(
			<div className="clearfix block">
				<h4>Настройки оплаты чаевых</h4>
				<CheckGroup
					Id="GuestCommission"
					Title="Комиссия за счет гостя"
					Type="radio"
					List={toCheckList(commissionList)}
					ParseType="number"
					Value={[merchant.GuestCommission]}
					onChange={(value,id) => merchantState.collectMerchant(id, value)}
				/>
				<CheckGroup
					Id="PaymentTipsType"
					Title="Опции оплаты"
					Type="radio"
					List={toCheckList(optionList)}
					ParseType="number"
					Value={[merchant.PaymentTipsType]}
					onChange={(value,id) => merchantState.collectMerchant(id, value)}
				/>
				{(merchant.PaymentTipsType === 1 || merchant.PaymentTipsType === 2) &&
					<EditableTagsPreset
						Id="PaymentTipsPreset"
						Type="number"
						Value={merchant.PaymentTipsPreset}
						Limit={4}
						onChange={(value,id) => merchantState.collectMerchant(id, value)}
					/>
				}
			</div>
		)
	}
}
TipsSettings = observer(TipsSettings);

class BonusSettings extends Component {
	render() {
		return(
			<div className="clearfix block">
				<h4>Настройки бонусной системы</h4>
				<div className="clearfix">
					<TextInput
						Id="ExpDate"
						Title="Срок действия бонусов в днях"
						Type="number"
						RowClass="col col50"
						Value={merchantState.Merchant.ExpDate}
						onChange={(value,id) => merchantState.collectMerchant(id, value)}
					/>
					<TextInput
						Id="BonusMaxDiscount"
						Title="Максимально возможная оплата бонусами от суммы заказа"
						Type="number"
						Step={0.01}
						Max={1}
						RowClass="col col50"
						Value={merchantState.Merchant.BonusMaxDiscount}
						onChange={(value,id) => merchantState.collectMerchant(id, value)}
					/>
					<CheckGroup
						Id="OneTypeBonus"
						Type="checkbox"
						RowClass="col col100 nomargin"
						List={toCheckList([{ Id : "true", Name : "При оплате бонусами, бонусы на остаток не начисляются"}])}
						Value={merchantState.Merchant.OneTypeBonus ? ["true"] : []}
						onChange={(value,id) => merchantState.collectMerchant(id, value)}
					/>
				</div>
				<div className="clearfix ip">
					<TextInput
						Id="IPList"
						Title="Доступные IP"
						Type="textarea"
						Value={merchantState.Merchant.IPList}
						onChange={(value,id) => merchantState.collectMerchant(id, value)}
					/>
					<p className="comment">
						Для безопасности работы системы необходимо задать IP адреса вашего сервера, откуда будут 
						производиться запросы на начисления и списания бонусов для ваших покупателей
					</p>
				</div>
			</div>
		)
	}
}
BonusSettings = observer(BonusSettings);

class ListsBlock extends Component {
	/** Добавление элемента списка */
	handlerAdd (event) { 
		event.preventDefault();
		var type = this.props.Type;
		merchantState[type] = _.concat(merchantState[type], _.clone(merchantState[type + "Dummy"]));
	}

	render() {
		var view = this;
		
		return(
			<div className={this.props.Type === "Entities" ? "entities" : "block list"}>
				<h4>{buttonTitles[this.props.Type]}</h4>
				<div className={this.props.Type === "Entities" ? "entitiesList" : "clearfix"}>
					{_.map(merchantState[this.props.Type], (item, i) => {
						if (this.props.Type === "Entities") 
							return(<EntityItem key={merchantState.Counter++} Item={item} Index={i} 
								Type={view.props.Type} />)	
						return(<ListItem key={merchantState.Counter++} Item={item} Index={i} 
							Type={view.props.Type} />)
					})}
				</div>
				{authUserState.HasERP &&
					<a href="/" className="button icon add blue" onClick={this.handlerAdd.bind(this)}>
						<i></i>Добавить</a>
				}
			</div>
		)
	}
}
ListsBlock = observer(ListsBlock)

class ListItem extends Component {
	/** Удаление элемента списка */
	handleDelete (event) { 
		event.preventDefault();
		
		var index = this.props.Index,
			type = this.props.Type;
		merchantState[type] = _.filter(merchantState[type], function (v,i) { return i !== index; });
	}

	render() {
		var type = this.props.Type;

		return(
			<div className={"item col " + (uistate.isDevice ? "col1_2" : "col1_3")}>
				<TextInput
					Id="Name"
					Title="Название"
					Value={this.props.Item.Name}
					Required={true}
					Disabled={type === "PaymentMethods" && this.props.Item.Default}
					RowClass="col col95"
					onChange={(value, id) => merchantState["collect" + type](id, value, this.props.Index)}
				/>
				{type === "Brands" && 
					<TextInput
						Id="Description"
						Title="Описание"
						Type="textarea"
						Value={this.props.Item.Description}
						RowClass="col col95"
						onChange={(value, id) => merchantState["collect" + type](id, value, this.props.Index)}
					/>
				}
				{type === "Entities" && authUserState.HasERP &&
					<CheckGroup
						Id="Main"
						Type="checkbox"
						List={toCheckList([{ Id : "true", Name : "Основной" }])}
						Value={merchantState.Merchant.MainEntity === this.props.Index ? ["true"] : []}
						RowClass="col col95 nomargin"
						onChange={(value, id) => merchantState["collect" + type](id, value, this.props.Index)}
					/>
				}
				{type === "Entities" &&
					<TextInput
						Id="INN"
						Title="ИНН организации"
						Mask="999999999999"
						Value={this.props.Item.INN}
						RowClass="col col95"
						onChange={(value, id) => merchantState["collect" + type](id, value, this.props.Index)}
					/>
				}
				{type === "Entities" &&
					<TextInput
						Id="OGRN"
						Title="ОГРН организации"
						Mask="9999999999999"
						Value={this.props.Item.OGRN}
						RowClass="col col95"
						onChange={(value, id) => merchantState["collect" + type](id, value, this.props.Index)}
					/>
				}
				{type === "PaymentMethods" &&
					<CheckGroup
						Id="Fiscal"
						Type="checkbox"
						List={toCheckList([{ Id : "true", Name : "Фискальный чек" }])}
						Value={this.props.Item.Fiscal ? ["true"] : []}
						Disabled={this.props.Item.Default}
						RowClass="col col95 nomargin"
						onChange={(value, id) => merchantState["collect" + type](id, value, this.props.Index)}
					/>
				}
				{type === "PaymentMethods" &&
					<CheckGroup
						Id="ShowCourier"
						Type="checkbox"
						List={toCheckList([{ Id : "true", Name : "Курьер может принимать оплату" }])}
						Value={this.props.Item.ShowCourier ? ["true"] : []}
						Disabled={this.props.Item.Default}
						RowClass="col col95 nomargin"
						onChange={(value, id) => merchantState["collect" + type](id, value, this.props.Index)}
					/>
				}
				{type === "Brands" &&
					<FileInput 
						Id="SmallLogo"
						Title="Маленький логотип"
						RowClass="col col95 nomargin small"
						File={this.props.Item.SmallLogoURL}
						MaxSize={256000}
						onChange={(value, id) => merchantState["collect" + type](id, value, this.props.Index)}
					/>
				}
				{type === "Brands" &&
					<FileInput 
						Id="BigLogo"
						Title="Большой логотип"
						RowClass="col col95 nomargin"
						File={this.props.Item.BigLogoURL}
						MaxSize={256000}
						onChange={(value, id) => merchantState["collect" + type](id, value, this.props.Index)}
					/>
				}
				{type === "Brands" && authUserState.HasTips &&
					<CheckGroup
						Id={"BackGroundColor_" + this.props.Index} 
						Type="radio"
						Title="Цвет платежной страницы"
						RowClass="col col95"
						List={toCheckList(merchantState.BGColor)}
						Value={[this.props.Item.BackGroundColor]}
						onChange={(value, id) => merchantState["collect" + type](id, value, this.props.Index)}
					/>
				}
				{!this.props.Item.Default && authUserState.HasERP &&
					<a className="icon remove" href="/" title="Удалить"
						onClick={this.handleDelete.bind(this)}><i></i></a>
				}
			</div>
		)
	}
}
ListItem = observer(ListItem);

class EntityItem extends Component {
	/** Удаление элемента списка */
	handleDelete (event) { 
		event.preventDefault();
		
		var index = this.props.Index,
			type = this.props.Type;
		merchantState[type] = _.filter(merchantState[type], function (v,i) { return i !== index; });
	}

	render() {
		var type = this.props.Type;

		return(
			<>
				<TextInput
					Id="Name"
					Title="Название"
					Value={this.props.Item.Name}
					Required={true}
					// Disabled={type === "PaymentMethods" && this.props.Item.Default}
					onChange={(value, id) => merchantState["collect" + type](id, value, this.props.Index)}
				/>
				<CheckGroup
					Id="Main"
					Type="checkbox"
					List={toCheckList([{ Id : "true", Name : "Основной" }])}
					Value={merchantState.Merchant.MainEntity === this.props.Index ? ["true"] : []}
					onChange={(value, id) => merchantState["collect" + type](id, value, this.props.Index)}
				/>
				<TextInput
					Id="INN"
					Title="ИНН организации"
					Mask="999999999999"
					Value={this.props.Item.INN}
					onChange={(value, id) => merchantState["collect" + type](id, value, this.props.Index)}
				/>
				<TextInput
					Id="OGRN"
					Title="ОГРН организации"
					Mask="9999999999999"
					Value={this.props.Item.OGRN}
					onChange={(value, id) => merchantState["collect" + type](id, value, this.props.Index)}
				/>
				<a className="iconSvg remove" href="/" title="Удалить"
					onClick={this.handleDelete.bind(this)}><DeleteIcon /></a>
			</>
		)
	}
}
EntityItem = observer(EntityItem)