import { observable, decorate } from 'mobx';
import _ from 'lodash';
import { authUserState } from './common';
import { checkRights } from '../functions';
import { contentTypesIndex, rightTypes } from '../dictionary';
import moment from 'moment';

export class ContentState {
	ContentType = "";
	AllFilialsContent = [];
	AllContent = [];
	Article = {};
	SearchMask = "";
	Widget = false;
	IsSendRequest = false;
	
	EditMode = false;
	ArticleCounter = 0;
	GroupNames = [];
	ErrorCode = "";
	ButtonLoading = false;
	
	ArticleDummy = {
		Id : -1,
		Name : "",
		Text : "",
		FilialsId : [],
		Active: true,
		Group : "",
		GroupId : -1,
		Order : 0
	};
	GroupDummy = {
		Name : "",
		Id : -1,
		Group : []
	};
	
	OpenGroup = -1;
	OpenContent = -1;
	EditContent = -1;
	EditGroup = -1;

	setDefault (type) {
		this.ContentType = type;
		this.CanManage = checkRights(rightTypes[this.ContentType]);

		this.OpenContent = -1;
		this.OpenGroup = -1;

		this.AllContent = [];
	}

	get isDocs () { return this.ContentType === "Documents"; }
	get isNews () { return this.ContentType === "News"; }
	get isStock () { return this.ContentType === "Stock"; }

	/**
	 * Функция, разбирающая ответ от сервера со всем контентом на странице
	 * @param {[object]} data ответ от сервера
	 * @param {string} type тип контента
	 * @param {boolean} isWidget флаг того, что данные размещаются в виджите
	 * @value AllContent – переменная в которой хранится весь ответ от сервера до последующей обработки
	 */
	setContent (data, type, isWidget) {
		var canManage = this.CanManage;
		
		this.Widget = isWidget ? isWidget : false;
		this.AllContent = !data.Success ? [] :
			_.filter(data.Content, function (v) { return canManage ? v : v.Active; });

		if (data.Success) this.filterContent(authUserState.Filial.Id, authUserState.Role.Id);

		this.SearchMask = "";
		this.IsSendRequest = true;

		this.EditContent = -1;
		this.EditGroup = -1;

		this.OpenContent = -1;
		this.OpenGroup = -1;
	}

	// get FilteredContent () {
	// 	if (_.isEmpty(this.AllContent)) return [];

	// 	var canManage = this.CanManage,
	// 		mask = _.toLower(this.SearchMask)

	// }

	/**
	 * Функция фильтрующая контент по доступности от активной должности и филиала
	 * @param {number} filial ID активного филиала
	 * @param {number} role ID активной должности
	 * @value FilialContent массив статей, доступных с учетом активного филиала и должности
	 * @value Groups - итоговый массив с нужными статьями
	 */
	filterContent (filial, role) {
		var canManage = checkRights(rightTypes[this.ContentType]),
			type = this.ContentType,
			isWidget = this.Widget;

		this.FilialContent = _.filter(this.AllContent, function (v) {
			var canFilial = canManage ? v : _.indexOf(v.Filials, filial) !== -1,
				canRole = type !== "News" ? true : canManage && !isWidget ? v : _.indexOf(v.Roles, role) !== -1;
			
			return canFilial && canRole;
		});
		
		this.FilialContent = _.sortBy(this.FilialContent, "GroupOrder");
		
		this.Groups = _.map(_.groupBy(this.FilialContent, "Group"), function (group, name) {
			if (type === "News")
				return { 
					Name : name, 
					Group : _.sortBy(group, [function(v) { return -moment(v.LastUpdate); }]), 
					Id : group[0].GroupId 
				}
			else
				return { Name : name, Group : _.sortBy(group, "Order"), Id : group[0].GroupId }
		});
		this.GroupNames = _.map(this.Groups, function (v) { return v.Name });

		if (_.isEmpty(this.Groups) && canManage && this.ContentType === "News") {
			this.Groups = [_.extend(this.GroupDummy, { Name : "Новости" }) ];
			this.GroupNames = ["Новости"]
		}

		this.ArticleCounter = 0;
	}

	/**
	 * Функция, фильтрующая раздел по поисковой строке
	 * @param {string} mask поисковая строка
	 * @value Groups итоговый массив с нужными статьями
	 */
	getSearching (mask) {
		var content = this.FilialContent;

		if (mask !== "")
			content = _.filter(this.FilialContent, function (item) {
				var title = _.toLower(item.Name),
					text = _.toLower(item.Text);

				return title.indexOf(_.toLower(mask)) !== -1 || text.indexOf(_.toLower(mask)) !== -1;
			});
		
		content = _.sortBy(content, "GroupOrder");
		this.Groups = _.map(_.groupBy(content, "Group"), function (group, name) {
			return { Name : name, Group : _.sortBy(group, "Order") }
		});
	}

	/**
	 * Функция, разбирающая данные для редактирования статьи
	 * @param {object} article данные статьи (undefined, если сброс открытой статьи)
	 * @param {number} groupId ID раздела
	 * @value Article - переменная, содержащая информацию о статье
	 */
	setArticle (article, groupId) {
		this.Article = _.omit(article, ["Filials", "Roles"]);

		if (!_.isUndefined(article)) {
			this.Article.FilialsId = article.Filials ? article.Filials : [];
			this.Article.RolesId = article.Roles ? article.Roles : [];
			this.Article.GroupId = groupId;
		}

		this.ErrorCode = "";
		this.ButtonLoading = "";
	}

	/**
	 * Сбор с данные с формы для статьи
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 * @value Article - переменная, содержащая информацию о статье
	 */
	collectArticle (id, value) { 
		if (/CanPrint/.exec(id)) this.Article.CanPrint = value;
		else if (/Push/.exec(id)) this.Article.Push = value;
		else this.Article[id] = value; 
	}
	
	/**
	 * Функция, собирающая данные по статье для отправки на сервер
	 * @return {object} данные по статье для отправки на сервер
	 */
	getSaveData () { 
		var data = this.Article;

		if (_.isUndefined(data.FilialsId) || _.isEmpty(data.FilialsId)) data.FilialsId = [];
		if (_.isUndefined(data.RolesId) || _.isEmpty(data.RolesId)) data.RolesId = [];

		if (this.ContentType !== "News") data = _.omit(data, ["RolesId"]);
		if (this.ContentType !== "Stock" || data.BrandId < 1) data = _.omit(data, ["Brand", "BrandId"]);



		if (data.GroupId === -1) data.GroupName = _.last(this.GroupNames);
		else data = _.omit(data, "GroupName");

		data = _.omit(data, "Group");

		return _.extend(data, { 
			GroupDashboard : authUserState.DashboardId, 
			GroupType : contentTypesIndex[this.ContentType]
		}); 
	}

	/**
	 * Функция, собирающая данные с формы для разделов
	 * @param {string} value значение параметра
	 * @param {number} index идекс раздела
	 * @value GroupNames[index] – название раздела
	 */
	setGroup (value, index) { this.GroupNames[index] = value; }

	/**
	 * Функция, собирающая данные по разделу для отправки на сервер
	 * @param {number} index индекс раздела
	 */
	getGroupData (index) {
		return {
			Group : this.Groups[index].Id,
			Name : this.GroupNames[index],
			ContentIds : _.map(this.Groups[index].Group, function (v) { return v.Id; }).join(","),
			Dashboard : authUserState.DashboardId,
			Type : contentTypesIndex[this.ContentType]
		}
	}
	/**
	 * Функция сбора порядка разделов контента для отправки на сервер
	 * @return {object} GroupIds – ID разделов через «,»
	 */
	getOrderGroup () { return { GroupIds : _.map(this.Groups, function (v) { return v.Id; }).join(",") }; }
}

decorate(ContentState, {
	Roles: observable,		// Список должностей
	Filials: observable,	// Список филиалов
	Brands: observable,		// Список брендов

	IsSendRequest: observable,	// Флаг Отправки запроса
	ContentType: observable,	// Тип Контента
	SearchMask: observable,		// Маска для поиска по тексту
	
	AllContent: observable,		// Список всех статей

	Article: observable,
	Groups: observable,
	
	GroupNames: observable,
	
	OpenContent : observable,
	OpenGroup: observable,
	EditContent : observable,
	EditGroup: observable,

	ErrorCode: observable,
	ButtonLoading: observable,
	
	EditMode: observable,
	
	Widget : observable
});
export const contentState = new ContentState();