/**
 * /pages/Catalog.js – Работа со настройками каталога для сайта и внешних источников
 * /store/catalog.js – бизнес логика для каталога
 * 
 * Список разделов и продуктов в каталоге доступен всем пользователям
 * 
 * При загрузке страницы один раз выполняется запрос «Brands» для получения всех брендов активного предприятия
 * Также при загрузке страницы выполняется запрос «Products» только активной продукции.
 * 
 * Для получения разделов по брендам используется запрос «CatalogSections»
 * Для редактирования, добавления, удаления и пересортировки разделов необходимо право «ManageProductCatalog»
 * Для редактирования/создания нового раздела необходимо вызвать метод «SaveCatalogSection», передав 
 * 		все необходимые параметры
 *  - Название раздела должно быть уникально в рамках бренда
 *  - Разделы могут иметь только один уровень вложенности
 * Для удаления раздела необходимо вызвать метод «DeleteCatalogSection» с ID выбранного раздела
 *  - Раздел должен быть пустой
 * Для пересортировки разделов используется метод «ChangeOrderCatalogSection» с указанным порядком ID разделов
 *  — Сортировать разделы можно только в рамках одного бренда и одного уровня вложенности
 * 
 * Для получения списка карточек товара используется запрос «CatalogProducts»
 * Для получения одной карточки продукта необходимо вызвать запрос «CatalogProduct»
 * Для редактирования, добавления, удаления и пересортировки разделов необходимо право «ManageProductCatalog»
 * Для удаления продукта необходимо вызвать метод «DeleteCatalogProduct» с ID продукта
 * Для пересортировки продуктов внутри раздела используется метод «ChangeOrderCatalogProduct» с указанным
 * 		порядком ID продуктов
 * Для создания/редактирования карточки продукта необходимо вызвать метод «SaveCatalogProduct»
 * 	- Название карточки должно быть уникально в рамках бренда
 * 	- Должны быть заполнены привязки ТТК к карточке
 * 		- При привязке ТТК должна подтягиваться информация о продукте с помощью запроса «Product» с ID ТТК
 * 	- Группы и названия свойств не должны повторяться в рамках одной карточки
 */

import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import '../styles/products.css';
import '../styles/catalog.css';

import { TextInput, Selectize, CheckGroup, FileInput } from '../forms/FormItems';
import { ItemHeader } from './PageTemplates';

import { buttonTitles, dashboardsTitles, errors } from '../dictionary';
import { toSelectList, getRubs, getRequest, toCheckList, getUpload, getCPFC } from '../functions';

import { uistate, confirmState, authUserState, errorState } from '../stores/common';
import { catalogState } from '../stores/catalog';

import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

const DragHandle = SortableHandle(() => <i className="SortHandle"></i>);

export class CatalogPage extends Component {
	constructor(props) {
		super(props);

		catalogState.setDefault();

		this.state = {};

		this.getSections();
		this.getData();

		getRequest("Brands", {}, function (data) { catalogState.Brands = data.Success ? data.Brands : []; });
		getRequest("Products", { Type: 3, Active : true }, function (data) { catalogState.TTKProducts = data.Success ? data.Products : []; })
		getRequest("Sections", { Type: 3 }, function (data) { catalogState.TTKSections = data.Success ? data.Sections : []; })
	}

	/** API запрос «CatalogSections» на получение списка разделов от сервера */
	getSections() {
		getRequest("CatalogSections", {}, function (data) {
			catalogState.setSections(data.Success && !_.isNull(data.Sections) ? data.Sections : []);
		});
	}

	/** API запрос «CatalogProducts» на получение списка карточек продукта от сервера */
	getData() {
		catalogState.IsSendRequest = false;
		getRequest("CatalogProducts", { }, function(data) {
			catalogState.setProducts(data.Success ? data.Products : [], "active");
		});
	}

	/** API запрос «CatalogProduct» на получение карточки товара от сервера 
	 * @param {number} id ID продукта 
	 * @param {string} operationType тип операции — создание или копирование
	 */
	getItem(id, operationType) {
		getRequest("CatalogProduct", { Id : id }, function (data) {
			catalogState.setProduct(data.Success ? data : undefined, operationType);
		});
	}

	/** Выбор/сброс активной карточки продукта
	 * @param {number} id ID продукта 
	 * @param {string} type тип операции edit или copy
	 */
	setId(id, type) {
		if (_.isUndefined(id)) {
			catalogState.setProduct(undefined);
			this.getData();
		} else {
			if (id === -1) catalogState.setProduct({ Id : -1 });
			else this.getItem(id, type);
		}
	}

	/** API запрос«DeleteCatalogProduct» на удаление карточки продукта
	 * @param {number} id ID продукта 
	 */
	removeId(id) {
		if (_.isUndefined(id)) return false;

		var model = this,
			item = _.find(catalogState.Products, { Id: id }),
			text = <span>Вы действительно хотите удалить продукт <b key="b1">«{item ? item.Name : ""}»</b>?</span>;

		confirmState.openConfirm(text, function() {
			getRequest("DeleteCatalogProduct", { Id : id }, function() { model.getData(); });
		});
	}

	render() {
		var showAddButton = _.isUndefined(catalogState.Section) && catalogState.CurrentSection > 0;

		return (
			<div className="page" id="CatalogPage">
				{_.isUndefined(catalogState.Product) &&
					<div className="head clearfix">
						<h2>{dashboardsTitles[this.props.Type] || this.props.Type}</h2>
						{catalogState.CanManage && showAddButton &&
							<button className="add" onClick={(e) => { this.setId(-1) }}>
								{buttonTitles[this.props.Type] || "Добавить"}</button>
						}
					</div>
				}
				{_.isUndefined(catalogState.Product) &&
					<ProductTable
						setId={this.setId.bind(this)}
						removeId={this.removeId.bind(this)}
						getData={this.getData.bind(this)}
						getSections={this.getSections.bind(this)}
					/>
				}
				{!_.isUndefined(catalogState.Product) &&
					<ProductItem
						Type={this.props.Type}
						setId={this.setId.bind(this)}
					/>
				}
			</div>
		)
	}
}
CatalogPage = observer(CatalogPage);

class ProductTable extends Component {
	/** Изменение порядка карточек */
	onSortEnd = ({ oldIndex, newIndex }) => {
		arrayMove.mutate(catalogState.SectionProducts, oldIndex, newIndex);
		this.changeOrder();
	};

	/** API запрос «ChangeOrderCatalogProduct» на изменение порядка карточек внутри раздела */
	changeOrder() {
		var model = this;
		getRequest("ChangeOrderCatalogProduct", catalogState.getProductsOrder(), function () { model.props.getData(); });
	}

	render() {
		var view = this,
			SortableItem = SortableElement(({ value, sortIndex }) => {
				return (<ProductTableItem Item={value} Index={sortIndex} 
						setId={view.props.setId}
						removeId={view.props.removeId} 
				/>)
			}),
			SortableList = SortableContainer(({ items }) => {
				return(<div className="SortableList">{items.map((value, index) => (
					<SortableItem key={"ag" + index} index={index} value={value} sortIndex={index} />
				))}</div>);
			});

		return (
			<div className="clearfix section">
				<SectionMenu getSections={this.props.getSections} />
				{catalogState.IsSendRequest && 
					<div className="col col80 products">
						{!_.isEmpty(catalogState.Products) && 
							<div className="table">
								<div className="titles clearfix">
									<span className="col col10">&nbsp;</span>
									<span className="col col20">Название</span>
									<span className="col col25">Краткое описание</span>
									<span className="col col10">Цена</span>
									<span className="col col10">Опции</span>
									<span className="col col10">Активен</span>
								</div>
								<div className="clearfix tableBody" >
									<SortableList
										items={catalogState.SectionProducts}
										onSortEnd={this.onSortEnd}
										useDragHandle
										helperClass="SortableHelper"
										axis="xy"
										lockToContainerEdges={true}
										disableAutoscroll={true}
									/>
								</div>
							</div>
						}
						{_.isEmpty(catalogState.SectionProducts) && <p>{errors.EMPTY_RESPONSE}</p>}
					</div>
				}
			</div>
		)
	}
}
ProductTable = observer(ProductTable);

class ProductTableItem extends Component {
	render() {
		var item = this.props.Item,
			// price = _.find(item.Prices, { CityId : authUserState.City }),
			// active = price ? price.Active : false,
			photo = { backgroundImage : "url(" + item.PhotoPreviewURL + ")" };
		
		return(
			<div className={"clearfix item orderSortItem " + (!item.Active ? "notactive" : "")}>
				{catalogState.CanManage && <DragHandle />}
				<span className="col col10"><div className="photo" style={photo}></div></span>
				<span className="col col20">{item.Name}</span>
				<span className="col col25">{item.ShortDescription}</span>
				{/* <span className="col col10">{price ? getRubs(price.Price, true) : ""}</span> */}
				<span className="col col10">{getRubs(item.Price, true)}</span>
				<span className="col col10">{item.HasGroups ? "да" : ""}</span>
				<span className="col col10">{!item.Active && <i className="hidden"></i>}</span>

				{!catalogState.CanManage &&
					<span className="col edit"><button className="icon view" title="Посмотреть"
						onClick={() => this.props.setId(item.Id, "edit")}><i></i></button></span>
				}
				{catalogState.CanManage &&
					<span className="col edit"><button className="icon edit" title="Редактировать"
						onClick={() => this.props.setId(item.Id, "edit")}><i></i></button></span>
				}
				{catalogState.CanManage &&
					<span className="col edit"><button className="icon copy" title="Копировать"
						onClick={() => this.props.setId(item.Id, "copy")}><i></i></button></span>
				}
				{catalogState.CanManage &&
					<span className="col edit"><button className="remove icon" title="Удалить"
						onClick={() => this.props.removeId(item.Id)}><i></i></button></span>
				}
			</div>
		)
	}
}

class SectionMenu extends Component {
	/** Добавление нового бренда */
	addBrand() {
		catalogState.Sections = _.concat(catalogState.Sections, _.clone(catalogState.SectionDummy));
		catalogState.setSection(_.last(catalogState.Sections))
	}

	render() {
		var view = this,
			allBrands = _.isEmpty(catalogState.brandList);
			
		return (
			<div className={"col col20 productsNav"}>
				{_.map(catalogState.BrandSections, function (brand, i) {
					return(<SectionBrandBlock Item={brand} key={i} getSections={view.props.getSections} />)
				})}
				{_.isUndefined(catalogState.Section) && catalogState.CanManage && !allBrands && 
					<button className="" onClick={this.addBrand.bind(this)}>Добавить бренд</button>
				}
			</div>
		)
	}
}
SectionMenu = observer(SectionMenu);

class SectionBrandBlock extends Component {
	/** Добавление нового раздела в бренд */
	addSection(event) {
		event.preventDefault();

		catalogState.Sections = _.concat(catalogState.Sections, _.extend(_.clone(catalogState.SectionDummy), 
			{ BrandId : this.props.Item.BrandId }));
		catalogState.setSection(_.last(catalogState.Sections));
	}

	/**
	 * Сбор данных с формы редактирования раздела
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange(value, id) { catalogState.collectSection(id, value); }

	render() {
		var view = this,
			item = this.props.Item,
			hasBrand = item.BrandId !== -1 && item.BrandName && item.BrandName !== "";

		return(
			<div className="clearfix container brand">
				{hasBrand && <h5>{item.BrandName}</h5>}
				{!_.isUndefined(catalogState.Section) && !hasBrand &&
					<form>
						<Selectize
							Id="BrandId"
							Title="Название бренда"
							List={toSelectList(catalogState.brandList)}
							Value={catalogState.Section.BrandId}
							onChange={view.handleChange.bind(view)}
						/>
					</form>
				}
				<SectionSortList Sections={item.Sections} getSections={this.props.getSections}
					BrandId={item.BrandId} ParentId={-1} />
				{catalogState.CanManage && _.isUndefined(catalogState.Section) && 
					<a href="/" className="add icon" onClick={view.addSection.bind(view)}><i></i>Добавить</a>
				}
			</div>
		)
	}
}
SectionBrandBlock = observer(SectionBrandBlock);

class SectionSortList extends Component {
	/** Изменение порядка разделов */
	onSortEnd = ({ oldIndex, newIndex }) => {
		arrayMove.mutate(this.props.Sections, oldIndex, newIndex);
		this.changeOrder();
	};

	/** API запрос «ChangeOrderCatalogSection» на изменение порядка разделов */
	changeOrder() {
		var model = this,
			params = { SectionIds : _.map(this.props.Sections, "Id").join(",") };
		
		getRequest("ChangeOrderCatalogSection", params, function () { model.props.getSections(); });
	}

	render() {
		var view = this,
			SortableItem = SortableElement(({ value, sortIndex }) => {
				return(<SectionMenuItem Item={value} Index={sortIndex} BrandId={view.props.BrandId}
					getSections={view.props.getSections} ParentId={view.props.ParentId}/>)
			}),
			SortableList = SortableContainer(({ items }) => {
				return(<div className="SortableList">{items.map((value, index) => (
					<SortableItem key={"ag" + index} index={index} value={value} sortIndex={index} />
				))}</div>);
			});

		return(
			<SortableList
				items={this.props.Sections}
				onSortEnd={this.onSortEnd}
				useDragHandle
				helperClass="SortableHelper"
				axis="xy"
				lockToContainerEdges={true}
				disableAutoscroll={true}
			/>
		)
	}
}
SectionSortList = observer(SectionSortList);

class SectionMenuItem extends Component {
	render() {
		if (!_.isUndefined(catalogState.Section) && catalogState.Section.Id === this.props.Item.Id)
			return (<SectionMenuEditItem getSections={this.props.getSections} />)
		else
			return (<SectionMenuViewItem Item={this.props.Item} ParentId={this.props.ParentId}
				BrandId={this.props.BrandId} getSections={this.props.getSections} />)
	}
}
SectionMenuItem = observer(SectionMenuItem);

class SectionMenuViewItem extends Component {
	/** Выбор активного раздела */
	changeSection(event) {
		event.preventDefault();

		if (/active/.test(event.currentTarget.className)) return false;
		else catalogState.CurrentSection = this.props.Item.Id;
	}

	/** Включить/отключить режим редактирования выбранного раздела */
	handleEdit() { catalogState.setSection(_.find(catalogState.Sections, { Id : this.props.Item.Id })); }

	/** API запрос «DeleteCatalogSection» на удаление выбранного раздела */
	handleRemove() {
		if (_.isUndefined(this.props.Item.Id)) return false;

		var model = this,
			id = this.props.ParentId === -1 ? this.props.Item.Id : this.props.ParentId,
			item = _.find(catalogState.Sections, { Id: id }),
			section = this.props.ParentId === -1 ? item : _.find(item.SubSections, { Id : this.props.Item.Id }),
			text = <span>Вы действительно хотите удалить категорию <b key="b1">«{section ? section.Name : ""}»</b>?</span>;

		confirmState.openConfirm(text, function() {
			getRequest("DeleteCatalogSection", { Id : model.props.Item.Id }, function (data) { 
				if (data.Success) model.props.getSections(); 
				else errorState.setError(data.ErrorCode);
			});
		});
	}

	/** Добавление подраздела в раздел */
	handleAdd() {
		var section = _.find(catalogState.Sections, { Id : this.props.Item.Id });
		section.SubSections = _.concat(section.SubSections, _.extend(_.clone(catalogState.SectionDummy), {
			BrandId : this.props.Item.BrandId, ParentId : this.props.Item.Id }));
		catalogState.setSection(_.last(section.SubSections));
	}

	render() {
		var item = this.props.Item;

		return (
			<div className="clearfix">
				<a className={"icon sectionItem " + (catalogState.CurrentSection === item.Id ? " active" : "")}
						href="/" onClick={this.changeSection.bind(this)} >
					{catalogState.CanManage && <DragHandle />}
					<span>{item.Name}</span>
					{!item.Active && <i className="hidden"></i>}
					{catalogState.CanManage && <i className="edit" onClick={this.handleEdit.bind(this)}></i>}
					{catalogState.CanManage && this.props.ParentId === -1 && <i className="add" onClick={this.handleAdd.bind(this)}></i>}
					{catalogState.CanManage && <i className="remove" onClick={this.handleRemove.bind(this)}></i>}
				</a>
				{!_.isEmpty(item.SubSections) && 
					<SectionSortList Sections={item.SubSections} getSections={this.props.getSections}
					BrandId={item.BrandId} ParentId={item.Id} />
				}
			</div>
		)
	}
}
SectionMenuViewItem = observer(SectionMenuViewItem);

class SectionMenuEditItem extends Component {
	/** Закрыть режим редактирования раздела */
	handleClose(event) {
		event.preventDefault();
		catalogState.setSection(undefined);
		catalogState.Sections = _.reject(catalogState.Sections, { Id : -1 });
	}

	/**
	 * Сбор данных с формы редактирования раздела
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange(value, id) { catalogState.collectSection(id, value); }

	/** API запрос «SaveCatalogSection» на изменение данных раздела */
	handleSubmit(event) {
		event.preventDefault();

		if (!catalogState.validSectionData()) return false;

		var model = this;
		getUpload("SaveCatalogSection", catalogState.getSectionData(), function (data) {
			if (data.Success) {
				model.props.getSections();
				catalogState.setSection(undefined);
			} else catalogState.ErrorCode = data.ErrorCode;
		});
	}

	render() {
		var section = catalogState.Section;

		return (
			<form className="section" onSubmit={this.handleSubmit.bind(this)}>
				<TextInput
					Id="Name"
					Title="Название раздела"
					Value={section.Name}
					Required={true}
					onChange={this.handleChange.bind(this)}
				/>
				<CheckGroup
					Id="Active"
					Type="checkbox"
					RowClass="nomargin"
					List={[{ Id: "true", Title: "Активный раздел" }]}
					Value={section.Active ? ["true"] : []}
					onChange={this.handleChange.bind(this)}
				/>
				<TextInput
					Id="Description"
					Title="Описание"
					Type="textarea"
					Value={section.Description}
					onChange={this.handleChange.bind(this)}
				/>
				<FileInput
					Id="Photo"
					Title="Картинка раздела"
					MaxSize={512000}
					RowClass="nomargin"
					File={section.PhotoURL}
					onChange={this.handleChange.bind(this)}
				/>
				{catalogState.ErrorCode !== "" &&
					<p className="error clearfix">{errors[catalogState.ErrorCode] || errors.DEFAULT}</p>
				}
				<div className="clearfix buttons">
					<button>Сохранить</button>
					<a href="/" className="close icon" onClick={this.handleClose.bind(this)}><i></i></a>
				</div>
			</form>
		)
	}
}
SectionMenuEditItem = observer(SectionMenuEditItem);

class ProductItem extends Component {
	/** Закрытие окна выбранного продукта */
	handleClose(event) {
		event.preventDefault();
		this.props.setId(undefined);
	}

	/**
	 * Сбор данных с формы редактирования продукта
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange(value, id) { catalogState.collectProduct(id, value); }

	/** API запрос «SaveCatalogProduct» на редактирование продукта */
	handleSubmit(event) {
		event.preventDefault();
		if (!catalogState.validateProductData()) return false;

		catalogState.ButtonLoading = true;

		getUpload("SaveCatalogProduct", catalogState.getProductData(), function (data) {
			catalogState.ErrorCode = data.Success ? "SUCCESS_SAVE" : data.ErrorCode;
			catalogState.SuccessSave = data.Success;
			catalogState.Product.Id = data.Success ? data.ProductId : catalogState.Product.Id;
			catalogState.ButtonLoading = false;
		});
	}

	render() {
		var product = catalogState.Product,
			style = { left: uistate.IsSideBarOpen ? 260 : 50 };

		return (
			<section className="clearfix item two-cols" id="CatalogItem">
				<form onSubmit={this.handleSubmit.bind(this)} >
					<ItemHeader
						Title={buttonTitles[(product.Id === -1 ? "New" : "Edit") + this.props.Type]}
						Error={catalogState.ErrorCode}
						Loading={catalogState.ButtonLoading}
						CanManage={catalogState.CanManage}
						Success={catalogState.SuccessSave}
						handleClose={this.handleClose.bind(this)}
					/>
					<div className="clearfix main backblock fixed" style={style}>
						<TextInput
							Id="Name"
							Title="Название"
							Required={true}
							Disabled={!catalogState.CanManage}
							Value={product.Name}
							RowClass="col col25"
							onChange={this.handleChange.bind(this)}
						/>
						<Selectize
							Id="SectionId"
							Title="Категория"
							Type="Sections"
							List={catalogState.sectionProductList}
							Value={product.SectionId}
							Required={true}
							RowClass="col col25"
							Disabled={!catalogState.CanManage}
							onChange={this.handleChange.bind(this)}
						/>
					</div>
					<div className="clearfix section">
						<div className="clearfix">
							<div className="col col65">
								<ProductMain handleChange={this.handleChange.bind(this)} />
								<OptionsBlock />
							</div>
							<div className="col col35 gray">
								<h4>Настройки для сайта</h4>
								<h4>Настройки для агрегаторов</h4>
							</div>
						</div>
					</div>
				</form>
			</section>
		)
	}
}
ProductItem = observer(ProductItem);

class ProductMain extends Component {
	render() {
		var product = catalogState.Product,
			products = _.reject(catalogState.TTKProductList, { Modificators : 2 });

		return (
			<div className="block clearfix main">
				<h4>Описание товара</h4>
				<div className="clearfix">
					<Selectize
						Id="TTKProductId"
						Title="ТТК продукта"
						List={toSelectList(products)}
						Value={product.TTKProductId}
						Disabled={!catalogState.CanManage}
						onChange={this.props.handleChange}
					/>
					{product.TTKProductId !== -1 &&
						<TTKInfoBlock
							Item={_.find(catalogState.TTKProducts, { Id : product.TTKProductId })}
							Cities={product.Cities}
							handleChange={this.props.handleChange}
						/>
					}
				</div>
				<div className="clearfix">
					<TextInput
						Id="ShortDescription"
						Title="Краткое описание"
						Type="textarea"
						RowClass="col col65"
						Value={product.ShortDescription}
						Disabled={!catalogState.CanManage}
						onChange={this.props.handleChange}
					/>
					<FileInput
						Id="PhotoPreview"
						Title="Превью"
						MaxSize={2097152}
						RowClass="col col35 nomargin"
						File={product.PhotoPreviewURL}
						Disabled={!catalogState.CanManage}
						onChange={this.props.handleChange}
					/>
					<TextInput
						Id="Description"
						Title="Краткое описание"
						Type="textarea"
						RowClass="col col65"
						Value={product.Description}
						Disabled={!catalogState.CanManage}
						onChange={this.props.handleChange}
					/>
					<FileInput
						Id="Photo"
						Title="Основное фото"
						MaxSize={2097152}
						RowClass="col col35 nomargin"
						File={product.PhotoURL}
						Disabled={!catalogState.CanManage}
						onChange={this.props.handleChange}
					/>
				</div>
			</div>
		)
	}
}
ProductMain = observer(ProductMain);

class TTKInfoBlock extends Component {
	render() {
		var view = this,
			item = this.props.Item,
			weight = item.WeightReady;

		return(
			<div className="clearfix productInfo">
				<div className="clearfix">
					<div className="col col40">
						<h5>КБЖУ</h5>
						<p>{getCPFC(item.Calories, weight)}/{getCPFC(item.Proteins, weight)}/{getCPFC(item.Fats, weight)}/{getCPFC(item.Carbohydrates, weight)}</p>
						<h5>Вес</h5>
						<p>{weight} г</p>
					</div>
					<div className="col col60">
						<h5>Условия хранения</h5>
						{item.ExpDateRoom !== 0 && <p>При температуре +20º – {item.ExpDateRoom} ч.</p>}
						{item.ExpDate !== 0 && <p>При температуре +2º - +4º – {item.ExpDate} ч.</p>}
						{item.ExpDateFreeze !== 0 && <p>При температуре -16º – {item.ExpDateFreeze} ч.</p>}
					</div>
				</div>
				<div className="clearfix prices">
					<div className="clearfix titles">
						<span className="col col40">Доступно в городе</span>
						<span className="col col30">Цена</span>
						<span className="col col30">Старая цена</span>
					</div>
					{_.map(item.Prices, function(price, i) {
						return(<ProductPriceItem Item={price} key={i} Cities={view.props.Cities}
							handleChange={view.props.handleChange} />)
					})}
				</div>
			</div>
		)
	}
}
TTKInfoBlock = observer(TTKInfoBlock);

class ProductPriceItem extends Component {
	render() {
		var item = this.props.Item,
			city = _.find(authUserState.Cities, { Id : item.CityId });
		
		return(
			<div className="clearfix">
				<CheckGroup
					Id="Cities"
					Type="checkbox"
					List={city ? toCheckList([city]) : []}
					Value={this.props.Cities}
					Disabled={!catalogState.CanManage}
					RowClass="nomargin col col40"
					onChange={this.props.handleChange}
				/>
				<span className="col col30">{getRubs(item.Price)} руб.</span>
				<TextInput
					Value={getRubs(item.Price)}
					Disabled={true}
					RowClass="col col30 nomargin"
				/>
			</div>
		)
	}
}

class OptionsBlock extends Component {
	/** Добавление группы опций */
	handleAdd(event) {
		event.preventDefault();
		catalogState.OptionsGroup = _.concat(catalogState.OptionsGroup, 
			_.extend(_.clone(catalogState.OptionGroupDummy), { Options : [_.clone(catalogState.OptionDummy)] }));
	}

	render() {
		return(
			<div className="block clearfix optionsBlock">
				<h4>Свойства товара</h4>
				{_.map(catalogState.OptionsGroup, function(item, i) {
					return(<OptionsGroupBlock Item={item} key={i} Index={i} />)
				})}
				{catalogState.CanManage &&
					<a href="/" className="button blue" onClick={this.handleAdd.bind(this)}>Добавить группу свойств</a>
				}
			</div>
		)
	}
}
OptionsBlock = observer(OptionsBlock);

class OptionsGroupBlock extends Component {
	/** Удаление группы опций */
	handleRemove(event) {
		event.preventDefault();
		var index = this.props.Index
		catalogState.OptionsGroup = _.reject(catalogState.OptionsGroup, function(v,i) { return i === index; });
	}

	/** Добавление опции в группу */
	handleAdd(event) {
		event.preventDefault();
		var group = catalogState.OptionsGroup[this.props.Index].Options
		catalogState.OptionsGroup[this.props.Index].Options = _.concat(group, _.clone(catalogState.OptionDummy));
	}

	/**
	 * Сбор данных с формы заполнения группы опций
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange(value, id) { catalogState.collectOptions(id, value, this.props.Index); }

	render() {
		var view = this,
			item = this.props.Item,
			countTypeList = [
				{ Id : 1, Name : "Только одна опция" },
				{ Id : 2, Name : "Несколько в одном кол-ве" },
				{ Id : 3, Name : "Несколько в любом кол-ве" }
			];
			
		return(
			<div className="block clearfix">
				<div className="col col40 group">
					{catalogState.CanManage &&
						<a href="/" className="icon remove" onClick={this.handleRemove.bind(this)}
							title="Удалить группу свойств"><i></i></a>
					}
					<TextInput
						Id="Name"
						Title="Название группы опций"
						Value={item.Name}
						Required={true}
						Disabled={!catalogState.CanManage}
						RowClass="col col90"
						onChange={this.handleChange.bind(this)}
					/>
					<CheckGroup
						Id="RequiredType"
						Type="checkbox"
						Value={item.RequiredType ? ["true"] : []}
						List={toCheckList([{ Id : "true", Name : "Обязательно для выбора" }])}
						Disabled={!catalogState.CanManage}
						RowClass="nomargin col col90"
						onChange={this.handleChange.bind(this)}
					/>
					<CheckGroup
						Id={"CountType_" + this.props.Index}
						Type="radio"
						ParseType="number"
						Title="Способ выбора свойств"
						RowClass="col col90"
						Disabled={!catalogState.CanManage}
						Value={[item.CountType]}
						List={toCheckList(countTypeList)}
						onChange={this.handleChange.bind(this)}
					/>
				</div>
				<div className="col col60">
					{_.map(item.Options, function(option, i) {
						return(<OptionsBlockItem Item={option} key={i} Index={i} GroupIndex={view.props.Index} 
							DefaultIndex={item.DefaultIndex} />)
					})}
					{catalogState.CanManage &&
						<a href="/" className="button blue" onClick={this.handleAdd.bind(this)}>Добавить свойство</a>
					}
				</div>
			</div>
		)
	}
}
OptionsGroupBlock = observer(OptionsGroupBlock);

class OptionsBlockItem extends Component {
	/**
	 * Сбор данных с формы заполнения свойств
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange(value, id) { catalogState.collectOptions(id, value, this.props.GroupIndex, this.props.Index); }

	/** Удаление опции из группы */
	handleRemove (event) {
		event.preventDefault();
		var index = this.props.Index,
			group = catalogState.OptionsGroup[this.props.GroupIndex].Options;
			catalogState.OptionsGroup[this.props.GroupIndex].Options = _.reject(group, function(v,i) { return i === index; });
	}

	render() {
		var item = this.props.Item,
			byDefault = this.props.Index === this.props.DefaultIndex;

		return(
			<div className="clearfix block">
				<div className="clearfix">
					<TextInput
						Id="Name"
						Title="Название опции"
						Value={item.Name}
						Required={true}
						Disabled={!catalogState.CanManage}
						RowClass="col col35"
						onChange={this.handleChange.bind(this)}
					/>
					<Selectize
						Id="TTKProductId"
						Title="Продукт из ТТК"
						List={toSelectList(catalogState.TTKProductList)}
						Value={item.TTKProductId}
						RowClass="col col65"
						Disabled={!catalogState.CanManage}
						onChange={this.handleChange.bind(this)}
					/>
					<CheckGroup
						Id="ByDefault"
						Type="checkbox"
						Value={byDefault ? ["true"] : []}
						List={toCheckList([{ Id : "true", Name : "Выбрано по умолчанию" }])}
						RowClass="nomargin col col100"
						Disabled={!catalogState.CanManage}
						onChange={this.handleChange.bind(this)}
					/>
					{catalogState.CanManage &&
						<a href="/" className="icon remove" onClick={this.handleRemove.bind(this)}
							title="Удалить свойство"><i></i></a>
					}
				</div>
				{item.TTKProductId !== -1 &&
					<TTKInfoBlock 
						Item={_.find(catalogState.TTKProducts, { Id : item.TTKProductId })}
						Cities={item.Cities}
						handleChange={this.handleChange.bind(this)}
					/>
				}
			</div>
		)
	}
}
OptionsBlockItem = observer(OptionsBlockItem);