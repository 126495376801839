import { observable, decorate } from 'mobx';
import _ from 'lodash';
import { authUserState } from './common';
import { getRubs } from '../functions';

class ProviderState {
	ProviderDummy = {
		Id: -1,
		Name: "",
		Active: true,
		CityId: -1,
		Description: "",
		INN: "",
		KPP: "",
		BIK: "",
		Bank: "",
		CorrAccount: "",
		CheckAccount: "",
		Address: "",
		DefermentPayment: "",
		MinAmount: "",
		Contacts: [],
		WorkHours: [],
		Products: []
	}
	ContactDummy = {
		Id: -1,
		FIO: "",
		Phone: "",
		Email: ""
	}
	WorkHourDummy = {
		Id: -1,
		Day: -1,
		StartTime: "",
		EndTime: ""
	}
	ProductDummy = {
		Id: -1,
		ProductId: -1,
		MinCount: "",
		VAT: -1
	}

	/** Установка дефолтного состояния страницы */
	setDefault() {
		this.Filter = { Active : true };
		this.Providers = [];
		this.IsSendRequest = false;

		this.setProvider(undefined);
	}

	/**
	 * Разбор ответа от сервера со списком поставщиков
	 * @param {object} data ответ от сервера
	 */
	setProviders(data) {
		this.Providers = data.Success ? data.Providers : [];
		this.IsSendRequest = true;
	}
	
	/**
	 * Разбор ответа от сервера с активным поставщиком
	 * @param {object} provider ответ от сервера
	 */
	setProvider(provider) {
		var store = this;

		this.Provider = _.isUndefined(provider) ? provider : provider.Id === -1 ? _.clone(this.ProviderDummy)
			: _.omit(provider, ["WorkHours", "Contacts", "Products", "Success", "ErrorCode"]);

		if (!_.isUndefined(provider))
			this.Provider.MinAmount = this.Provider.MinAmount === "" ? "" : getRubs(this.Provider.MinAmount);
		
		this.Contacts = _.isUndefined(provider) ? [] : provider.Id === -1 || _.isEmpty(provider.Contacts)
			? [_.clone(this.ContactDummy)] : provider.Contacts;
		this.Products = _.isUndefined(provider) ? [] : provider.Id === -1 || _.isEmpty(provider.Products)
			? [_.clone(this.ProductDummy)] : _.map(provider.Products, function (v) {
				var unit = _.find(authUserState.Units, { Id : v.UnitId });
				return _.extend(v, { Unit : unit ? unit.Name : "" });
			});

		this.WorkHours = _.isUndefined(provider) ? [] : _.map(_.range(7), function (v) {
			var day = provider.WorkHours ? _.find(provider.WorkHours, { Day: v }) : {};
			return _.extend(_.clone(store.WorkHourDummy), day);
		});

		this.History = [];

		this.ButtonLoading = false;
		this.ErrorCode = "";
		this.SuccessSave = false;
		this.ContactsCounter = 0;
		this.ProductsCounter = 0;
		this.AllHistory = false;
	}

	/**
	 * Сбор данных с формы редактирования поставщика
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectProvider(id, value) { this.Provider[id] = value; }
	/**
	 * Сбор данных с формы редактирования контактов
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 * @param {number} index порядковый номер контакта
	 */
	collectContacts(id, value, index) { this.Contacts[index][id] = value; }
	/**
	 * Сбор данных с формы редактирования рабочего времени
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 * @param {number} index порядковый номер дня
	 */
	collectWorkDays(id, value, index) {
		var name = id.split("_")[2];
		if (/Day/.exec(name)) this.WorkHours[index].Day = value ? index : -1;
		else if (/Time/.exec(name)) {
			this.WorkHours[index].StartTime = value.StartTime;
			this.WorkHours[index].EndTime = value.EndTime;
		}
	}
	/**
	 * Сбор данных с формы редактирования доступных продуктов
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 * @param {number} index порядковый номер контакта
	 * @param {object} product продукт
	 */
	collectProducts(id, value, index, product) {
		var name = id.split("_")[2];

		this.Products[index][name] = value;
		if (name === "ProductId" && !_.isUndefined(product))
			this.Products[index].Unit = product.UnitNet;
	}

	/**
	 * Разбор ответа от сервера с реквизитами ЮЛ
	 * @param {object} value ответ от сервера dadata
	 */
	setSuggestProvider(value) {
		var data = value.data;
		this.Provider.Address = data.address ? data.address.value : this.Provider.Address;
		this.Provider.INN = data.inn ? data.inn : "";
		this.Provider.KPP = data.kpp ? data.kpp : "";
		this.Provider.Name = data.name ? data.name.short_with_opf : this.Provider.Name;
	}
	/**
	 * Разбор ответа от сервера с реквизитами банка
	 * @param {object} value ответ от сервера dadata
	 */
	setSuggestBank(value) {
		var data = value.data;
		this.Provider.BIK = data.bic ? data.bic : "";
		this.Provider.Bank = data.name ? data.name.payment : "";
		this.Provider.CorrAccount = data.correspondent_account;
	}

	/**
	 * Сбор данных для отправки на сервер
	 * @returns {object}
	 */
	getSaveData() {
		var data = _.clone(this.Provider);

		data.MinAmount = data.MinAmount*100;
		
		_.each(data, function (v,i) { if (v === "") data = _.omit(data, [i]); });

		if (data.INN) data.INN = data.INN.replace(/\D/g, "");
		if (data.KPP) data.KPP = data.KPP.replace(/\D/g, "");
		if (data.BIK) data.BIK = data.BIK.replace(/\D/g, "");
		if (data.CorrAccount) data.CorrAccount = data.CorrAccount.replace(/\D/g, "");
		if (data.CheckAccount) data.CheckAccount = data.CheckAccount.replace(/\D/g, "");

		_.each(this.Contacts, function (contact, i) {
			var name = "Contacts." + i + ".";
			if (contact.FIO !== "") _.each(contact, function(value, field) { 
				data[name + field] = field === "Phone" ? value.replace(/\D/gi,"") : value; 
			}); 
		});

		var index = 0;
		_.each(this.Products, function (product, i) {
			var name = "Products." + index + ".";
			if (product.ProductId === "" || product.ProductId === -1) return true;

			_.each(product, function(value, field) { 
				if (field !== "Price" && field !== "UnitId" && field !== "Unit")
					data[name + field] = value; 
			});
			index++;
		});

		var days = _.filter(this.WorkHours, function (v) { return v.Day !== -1 && v.Day !== "" 
			&& v.StartTime !== "" && v.EndTime !== "" });

		_.each(days, function(day, i) {
			var name = "WorkHours." + i + ".";
			_.each(day, function(value, field) { data[name + field] = value; });
		});

		return data;
	}

	/**
	 * Проверка корректности заполнения данных поставщиках
	 * @returns {boolean} 
	 */
	validateData() {
		var store = this,
			isValid = true;

		_.each(this.Contacts, function (v) {
			if (v.Phone.replace(/\D/gi, "") === "" && v.Email === "") {
				store.ErrorCode = "EMPTY_CONTACTS";
				isValid = false;
				return false;
			}

			if (v.Phone.replace(/\D/gi, "") !== "" &&  v.Phone.replace(/\D/gi, "").length < 11) {
				store.ErrorCode = "WRONG_PHONE_LENGTH";
				isValid = false;
				return false;
			}
		});

		if (this.Provider.CityId === -1 || this.Provider.CityId === "") {
			this.ErrorCode = "WRONG_PARAMS_CITY";
			return false;
		}

		return isValid;
	}
}

decorate(ProviderState, {
	Providers: observable,
	IsSendRequest: observable,
	Filter: observable,

	Provider: observable,
	Contacts: observable,
	WorkHours: observable,
	Products: observable,
	History: observable,
	AllHistory: observable,

	ButtonLoading: observable,
	ErrorCode: observable,
	SuccessSave: observable
});
export const providerState = new ProviderState();