import { observable, decorate, computed } from 'mobx';
import _ from 'lodash';

class CustomerState {
	Customers = [];
	IsSendRequest = false;

	Customer = undefined;
	ButtonLoading = false;
	ErrorCode = "";
	SuccessSave = false;
	TabState="info";
	HasMore = false;
	Limit = 100;
	Address = [];

	Filter = {
		Login : "",
		// Active : -1,
		BlackList : -1,
		Limit : 100,
		Offset : 0
	};

	CustomerDummy = {
		Id: -1,
		Name: "",
		Login: "",
		Email: "",
		BirthDate: "",
		CustomerInfo: "",
		CommonPhone : false
	};

	AddressDummy = {
		Id : -1,
		City : "",
		Settlement : "",
		Street : "",
		House : "",
		HouseBuilding : "",
		Entrance : "",
		Floor : "",
		Flat : "",
		Intercom : "",
		Main : false,
		Long : "",
		Lat : "",
	}
	
	/** Установка дефолтного состояния страницы */
	setDefault() { 
		this.Filter = { Login : "",
			// Active : -1,
			BlackList : -1,
			Limit : 100,
			Offset : 0 
		}

		this.IsSendRequest = false;
		this.SuccessSave = false;
		this.ErrorCode = "";
		this.HasMore = false;
		this.Total = 0;

		this.Customers = [];
		this.Customer = undefined;
	}
	/**
	 * Функция сбора данных с формы поиска клиентов
	 * @param {string} id название параметра 
	 * @param {string} value значение параметра
	 * @value Filter коллекция фильтров поиска
	 */
	collectFilter(id, value) { this.Filter[id] = value; }

	/** Функция сборки данных для отправки запроса
	 * @return {object} данные для запроса
	 */
	getFilter() {
		var data = _.clone(this.Filter);

		if (data.Login) data.Login = data.Login.replace(/\D/gi, "");
		if (data.Login === "") data = _.omit(data, "Login");

		// if (data.Active === "true") data.Active = 1;
		// else if (data.Active === "false") data.Active = -1;
		// else data = _.omit(data, "Active");

		if (data.BlackList === "true") data.BlackList = 1;
		else if (data.BlackList === "false") data.BlackList = -1;
		else data = _.omit(data, "BlackList");

		return data;
	}

	/** 
	 * Функция обработки ответа от сервера — список клиентов
	 * @param {object} ответ от сервера с массивом клиентов
	 * @value Customers массив с клиентами
	 * @value IsSendRequest флаг окончания отправки запроса
	*/
	setList(data) {
		this.Customers = data.Success ? _.map(data.Customers, function (v) { return _.extend(v,{ Id: v.Login }); }) : [];
		this.IsSendRequest = true;
		this.HasMore = data.Success ? data.HasMore : false;
		this.Total = data.Success ? data.Total : 0;
	}

	/**
	 * Функция обработки ответа от сервера — клиент
	 * @param {object} customer 
	 * @value Customer объект с информация о клиенте
	 * @value Address массив адесов клиента
	 * @value MainAddress сновной адрес клиента
	 */
	setItem(customer) {
		this.Customer = _.isUndefined(customer) ? customer : customer.Id === -1 ? _.clone(this.CustomerDummy)
			: _.omit(_.clone(customer), ["Success", "ErrorCode"]);

		this.Address = _.isUndefined(customer) ? [] : customer.Id === -1 || _.isNull(customer.Address) 
			|| _.isUndefined(customer.Address) ? [_.clone(this.AddressDummy)] : customer.Address;
		this.MainAddress = _.isUndefined(customer) || customer.Id === -1 || _.isNull(customer.Address) ? -1
			: _.findIndex(customer.Address, { Main : true });

		this.ButtonLoading = false;
		this.ErrorCode = "";
		this.SuccessSave = false;
		this.TabState = "info";

		this.aCounter = 0;
	}

	/** Объект со статистикой заказов по брендам
	 * @returns {[object]} массив заказов по брендам {Brand, Count, Amount}
	 */
	get brands () {
		return _.isUndefined(this.Customer) || _.isNull(this.Customer.Brands) ? [] : this.Customer.Brands;
	}

	/** Объект со статистикой заказов по брендам для отрисовки графика
	 * @returns {[object]} массив заказов по брендам {angle, label}
	 */
	get brandsChart () {
		if (_.isUndefined(this.Customer) || _.isNull(this.Customer.Brands)) return [];

		var angle = 360/_.sumBy(this.Customer.Brands, "Count");
		return _.map(this.Customer.Brands, function(v) {
			return { angle : angle*v.Count, label : v.Brand }
		});
	}

	/** Объект с информацией о нахождении клиента в черном списке
	 * @returns {object} объект, содержащий флаг черного списка, дату и причину добавления
	*/
	get blackList () {
		var customer = this.Customer;

		return {
			BlackList : _.isUndefined(customer) || customer.Id === -1 ? false : customer.BlackList,
			BlackListDate : _.isUndefined(customer) || customer.Id === -1 ? "" : customer.BlackListDate,
			BlackListDescription : _.isUndefined(customer) || customer.Id === -1 ? "" : customer.BlackListDescription
		}
	}

	/** Объект с основной статистикой пользователя 
	 * @returns {object} объект, содержащий кол-во заказов, сумму, средний чек и дату последнего заказа
	*/
	get commonStat () {
		if (_.isUndefined(this.Customer)) return {}

		return {
			Count: this.Customer.OrdersCount,
			Amount: this.Customer.OrdersAmount,
			Avg: this.Customer.OrdersCount === 0 ? 0 : this.Customer.OrdersAmount/this.Customer.OrdersCount,
			Last: this.Customer.LastOrder
		}
	}

	/** Массив со статистикой заказов
	 * @returns {[object]} сводка заказов пользователя в — предприятие, месяц, кол-во и сумма заказов
	 */
	get ordersStat () {
		if (_.isUndefined(this.Customer || _.isNull(this.Customer.Orders))) return [];

		var brands = _.groupBy(this.Customer.Orders, "BrandId"),
			orders = _.map(brands, function(brand) {
				return _.map(brand, function(item) {
					return { x : item.Date, y : item.Amount/100, label : brand[0].Brand, width: 30 }
				})
			});
		return orders;
	}

	/**
	 * Сбор данных о клиенте с формы
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectCustomer(id, value) { 
		if (/Name/.test(id)) this.Customer.Name = _.upperFirst(value);
		else if (/CommonPhone/.test(id)) this.Customer.CommonPhone = value;
		else this.Customer[id] = value; 
	}

	/**
	 * Разбор полей из DaData
	 * @param {object} value ответ от DaData
	 * @param {number} index порядковый номер адреса в массиве
	 */
	setAddress(value, index) {
		this.Address[index].City = !_.isNull(value.city) ? value.city : 
			!_.isNull(value.area_with_type) ? value.area_with_type : "";
		this.Address[index].Settlement = !_.isNull(value.settlement_with_type) ? value.settlement_with_type : "";
		this.Address[index].Street = !_.isNull(value.street_with_type) ? value.street_with_type : "";
		this.Address[index].House = !_.isNull(value.house) ? value.house : "";
		this.Address[index].HouseBuilding = !_.isNull(value.block) ? value.block_type + " " + value.block : "";
		this.Address[index].Flat = !_.isNull(value.flat) ? value.flat : "";

		this.Address[index].Lat = !_.isNull(value.geo_lat) ? value.geo_lat : "";
		this.Address[index].Long = !_.isNull(value.geo_lon) ? value.geo_lon : "";
	}
	/**
	 * Сбор данных с формы воода адресов
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 * @param {number} index порядковый номер адреса
	 */
	collectAddress (id, value, index) {
		if (/Main/.test(id)) this.MainAddress = value ? index : -1;
		else this.Address[index][id] = value;
	}

	/** Сбор данных для отправки запроса на редактирование пользователя
	 * @return {object} данные пользователя
	 */
	getSaveData() {
		var store = this,
			data = {
				Id : this.Customer.Id,
				Login : this.Customer.Login,
				Name : this.Customer.Name,
				BirthDate : this.Customer.BirthDate ? this.Customer.BirthDate : "",
				Email : this.Customer.Email ? this.Customer.Email : "",
				CustomerInfo : this.Customer.CustomerInfo ? this.Customer.CustomerInfo : "",
				CommonPhone : this.Customer.CommonPhone ? this.Customer.CommonPhone : false
			},
			index = 0;

		if (data.Login.indexOf("(") !== -1)
			data.Login = data.Login.replace(/\D/gi, "");

		if (data.Email === "") data = _.omit(data, "Email");

		_.each(this.Address, function(address) {
			var name = "Addresses." + index + ".";

			if (address.City !== "" && address.Street !== "" && address.House !== "") {
				_.each(address, function(value, field) { data[name + field] = value; });
				data[name + "Main"] = store.MainAddress === index;
				index++;
			}
		});

		return data;
	}

	/** Проверка на валидность данных перед сохранением пользователя
	 * @return {boolean} флаг валидности введенных данных
	 */
	validateData() {
		var isValid = true;

		if (this.Customer.Login.replace(/\D/gi, "").length < 11) {
			this.ErrorCode = "WRONG_PHONE_LENGTH";
			return false;
		}

		return isValid;
	}

	/** Показ/скрытие окна подтверждение добавления в черный список
	 * @param {boolean} add показать/скрыть
	 */
	setBlackList(add) {
		this.AddBlackList = add ? { Login : this.Customer.Login, Description : "" } : undefined;
	}
	/**
	 * Сбор данных с формы черного списка
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectBlackList(id, value) { this.AddBlackList[id] = value; }
}
decorate(CustomerState, {
	Customer: observable,
	Orders: observable,
	Address : observable,
	MainAddress : observable,
	AddBlackList : observable,
	TabState: observable,

	Customers: observable,
	IsSendRequest: observable,
	Filter: observable,
	HasMore: observable,
	Total: observable,

	ButtonLoading: observable,
	ErrorCode: observable,
	SuccessSave: observable,

	commonStat: computed,
	// ordersHistory: computed,
	ordersStat: computed,
	blackList: computed,
	brands: computed
});
export const customerState = new CustomerState();