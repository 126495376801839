import { observable, decorate } from 'mobx';
import _ from 'lodash';
import moment from 'moment';
import { clearEmptyParams, formatDate } from '../functions';

export class UserReport {
	Report = {};
	Groups = [];
	ReportId = -1;
	TemplateId = -1;
	// ButtonSave = false;
	// ButtonSend = false;
	OpenReport = -1;
	// ErrorCode = "";

	setDefault () {
		this.ActiveState = "form";

		this.ButtonSave = false;
		this.ButtonSend = false;

		this.ErrorCode = "";


	}

	setReport(template, fields, templateId, id, isSent) {
		if (_.isUndefined(template) || _.isNull(template) || _.isEmpty(template)) this.Report = {};
		else {
			var report = {};

			_.each(template, function(v) {
				var field = _.find(fields, { Id: v.Id });

				report["Field_" + v.Id] = _.extend(v, {
					Value: _.isNull(fields) || _.isUndefined(field) || field.Text === "-1" ? "" : field.Text,
					Disabled: isSent
				});
			});

			
			this.ReportId = id === 0 ? -1 : id;

			this.Report = report;
			this.Groups = _.uniq(_.map(template, function (v) { return v.Group; }));
		}

		this.TemplateId = templateId
		this.ButtonSave = false;
		this.ButtonSend = false;
		this.OpenReport = -1;
	}

	collectReport(id, value) { this.Report[id].Value = value; }

	getSaveData() {
		var data = {
				Id : this.ReportId,
				// ReportTemplateId : this.TemplateId
			},
			counter = 0;

		_.each(this.Report, function (v) {
			var name = "Fields." + counter + ".";

			data[name + "Id"] = v.Id;
			data[name + "Text"] = v.Type === "Number" && v.Value === "" ? -1 : v.Value;
			counter++;
		});

		return data;
	}

	validateReport() {
		var store = this,
			isValid = true;

		_.each(this.Report, function (v) {
			if (v.Required && (v.Value === "")) {
				isValid = false;
				store.ErrorCode = "EMPTY_REQUIRED";
				return false;
			}
		});

		return isValid;
	}
}
decorate(UserReport, {

	ActiveState: observable,
	Report: observable,
	Groups: observable,
	ButtonSave: observable,
	ButtonSend: observable,
	OpenReport: observable,
	ErrorCode : observable
});
export const userReport = new UserReport();

class ShiftReportState {
	Limit = 100;
	DatePreset = "month";

	/** Установка хранилища страницы по умолчанию 
	 * @param {string} type – тип страницы
	 */
	setDefault(type) {
		this.Type = type;
		this.IsCourier = type === "ShiftReportCourier";

		this.Filter = {
			Role : -1,
			User : -1,
			Filial : -1,
			DateFrom: formatDate(moment().subtract(1, "M"), "r"),
			DateTo : formatDate("today", "r"),
			IsCourier : type === "ShiftReportCourier",
			Limit : this.Limit,
			Offset : 0
		}

		this.IsSendRequest = false;
		this.HasMore = false;
		this.List = [];
		this.DatePreset = "month";
	}

	/**
	 * разбор ответа сервера со списком пользователей
	 * @param {object} data 
	 */
	setUsers (data) {
		var isCourier = this.IsCourier;

		this.Users = data.Success ? _.map(data.Users, function(v) {
			return _.extend(v, { Name : isCourier ? v.Name : v.FirstName + " " + v.LastName });
		}) : [];
	}

	/**
	 * Сбор данных с формы поиска отчетов по сменам
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectFilter (id, value) { this.Filter[id] = value; }

	/** Пересборка данных фильтра для отправки на сервер 
	 * @returns {object} параметры запроса
	 */
	getFilter () { return clearEmptyParams(this.Filter, [-1, ""]); }

	/**
	 * Разбор данных со списком смен от сервера
	 * @param {object} data ответ от сервера
	 * @value {[object]} List – список смен
	 * @value {boolean} IsSendRequest – флаг окончания отправки запроса
	 */
	setShifts (data) {
		this.List = data.Success && !_.isNull(data.Shifts) ? _.map(data.Shifts, function(item){
			var isDates = item.ShiftOpen !== "" && item.ShiftClose !== "",
				start = item.ShiftOpen !== "" ? moment(item.ShiftOpen) : "",
				end = item.ShiftClose !== "" ? moment(item.ShiftClose) : ""

			return _.extend(item, { Time : isDates ? end.diff(start, "minutes") : "" });
		}) : [];
		this.HasMore = data.Success ? data.HasMore : false;
		this.IsSendRequest = false;
	}
}
decorate(ShiftReportState, {
	Filter: observable,
	HasMore: observable,
	IsSendRequest: observable,
	List : observable,
	DatePreset: observable,
	Users: observable
});
export const shiftReportState = new ShiftReportState();

class ShiftTransferState {
	Filter = {
		GroupType : -1,
		Role : "",
		User : "",
		Filial : "",
		Date : "",
		IsAlerted : false,
		IsAutoSend : false,
		Limit : 100,
		Offset : 0
	}
	Limit = 100;
	HasMore = false;

	IsSendRequest = false;
	Reports = [];
	ErrorCode = "";

	/**
	 * Установка дефолтного состояния страницы отчетов
	 * @param {number} group тип отчета
	 */
	setDefault (group) {
		this.Filter = {
			GroupType : group,
			RoleId : "",
			UserId : "",
			FilialId : "",
			Date : "",
			IsAlerted : false,
			IsAutoSend : false,
			Limit : 100,
			Offset : 0
		}

		this.IsSendRequest = false;
		this.Reports = [];
		this.ErrorCode = "";
	}


	/**
	 * разбор ответа сервера со списком пользователей
	 * @param {object} data 
	 */
	 setUsers (data) {
		this.Users = data.Success ? _.map(data.Users, function(v) {
			return _.extend(v, { Name : v.FirstName + " " + v.LastName });
		}) : [];
	}

	/**
	 * Сбор данных с формы поиска отчетов
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectFilter (id, value) { 
		if (/IsAlerted/.exec(id)) this.Filter.IsAlerted = value;
		else if (/IsAutoSend/.exec(id)) this.Filter.IsAutoSend = value;
		else this.Filter[id] = value; 
	}

	/** Получение данных фильтра для запроса к серверу */
	getFilter () { return clearEmptyParams(this.Filter); }

	
	/**
	 * Разбор данных от сервера с отчетами
	 * @param {object} data ответ от сервера
	 */
	setReports (data) {
		this.Reports = data.Success ? this.parseReports(data.Reports) : [];

		this.IsSendRequest = true;
		this.ErrorCode = data.ErrorCode;
		this.HasMore = data.Success ? data.HasMore : false;
	}

	/** Обработка и группировка отчетов */
	parseReports (data) {
		var keyData = _.map(data, function(v) {
				return _.extend(v, { 
					Key : v.ShiftDate + "_" + v.Role + "_" + v.Filial + (!v.GroupReport ? ("_" + v.User) : "" ) 
				});
			})

		return _.map(_.groupBy(keyData, "Key"), function (group) {
			if (group.length === 1) return group[0];
			else {
				var fields = _.flatten(_.map(group, function (item) {
					return _.map(item.Fields, function(f) { 
						return _.extend(f, { User : item.User, Date : item.Date, Group : true })
					});
				}))

				return _.extend(group[0], { User : "", Fields : fields });
			}
		})
	}
}
decorate(ShiftTransferState, {
	Filter: observable,
	HasMore: observable,
	IsSendRequest: observable,
	Reports: observable,
	ErrorCode: observable,

	Users : observable

});
export const shiftTransferState = new ShiftTransferState();