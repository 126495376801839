import { observable, decorate } from 'mobx';
import _ from 'lodash';
import moment from 'moment';
import { checkRights } from '../functions';

class WriteOffState {
	WriteOffs = [];
	IsSendRequest = false;
	StockActive = -1;

	Filter = {
		StockId: "",
		UserId: "",
		ShiftDate: "",
		Status: "",
		Limit: 100,
		Offset: 0
	}
	Limit = 100;
	HasMore = false;

	WriteOffRequest = {};

	WriteOff = undefined;
	WriteOffDummy = {
		Id: -1,
		Date: "",
		StockId: -1,
	}
	Products = {};
	RequestProducts = {};
	ProductDummy = {
		ProductId: -1,
		Count: "",
		UnitName: "",
		CancelReasonId: -1,
		Description: ""
	}

	pCounter = 0;
	ErrorCode = "";
	ButtonLoading = false;
	SuccessSave = false;

	setRequest() {
		this.WriteOffRequest = { StockId: "" };
		this.RequestProductsAll = [_.clone(this.ProductDummy)];

		this.groupRequestProducts();

		this.ErrorCode = "";
		this.ButtonLoading = false;
		this.SuccessSave = false;
	}
	collectRequestProducts(id, value, index, type, product) {
		this.RequestProducts[type][index][id] = value;
		if (product) this.RequestProducts[type][index].UnitName = product.UnitNet;
	}
	getRequestData() {
		var data = { StockId: this.WriteOffRequest.StockId },
			products = _.flatMap(_.clone(this.RequestProducts)),
			index = 0;
		
		_.each(products, function (product) {
			var name = "Products." + index + ".";

			if (product.ProductId !== "" && product.ProductId !== -1) {
				_.each(product, function(value, field) { if (field !== "UnitName") data[name + field] = value; });
				index++;
			}
		});

		return data;
	}
	validateRequest() {
		var store = this,
			isValid = true;

		if (this.WriteOffRequest.StockId === "" || this.WriteOffRequest.StockId === -1) {
			store.ErrorCode = "STOCK_REQUIRED";
			return false;
		}

		if (_.isEmpty(this.RequestProducts)) {
			store.ErrorCode = "AUDIT_PRODUCTS_REQUIRED";
			return false;
		}

		_.each(this.RequestProducts, function (product) {
			if (product.ProductId === -1 || product.ProductId === "") {
				store.ErrorCode = "AUDIT_PRODUCTS_REQUIRED";
				isValid = false;
				return false;
			}

			if (product.CancelReasonId === -1 || product.CancelReasonId === "") {
				store.ErrorCode = "CANCELREASON_REQUIRED";
				isValid = false;
				return false;
			}
		});

		return isValid;
	}

	groupRequestProducts() {
		var store = this;
		_.each([1,2,3], function (v) { store.RequestProducts[v] = [_.clone(store.ProductDummy)]; });
	}

	setFilter() {
		this.Filter = {
			StockId: "",
			UserId: "",
			ShiftDate: "",
			Status: "",
			Limit: 100,
			Offset: 0
		}
	}

	collectFilter(id, value) { this.Filter[id] = value; }
	getFilterData() { return _.omitBy(_.clone(this.Filter), function (v) { return v === "" || v === false }); }

	setWriteOffs(data) {
		this.WriteOffs = data.Success ? _.map(data.WriteOffs, function (v) {
			return _.extend(v, {
				CanEdit : v.Status !== 3,
				CanView : v.Status === 3,
			});
		}) : [];
		this.HasMore = data.HasMore;
		this.IsSendRequest = true;
	}

	setWriteOff(writeOff) {
		this.WriteOff = _.isUndefined(writeOff) ? undefined : writeOff.Id === -1 ? _.clone(this.WriteOffDummy) 
			: _.omitBy(_.clone(writeOff), ["Success", "ErrorCode", "Products"]);

		this.ProductsAll = !_.isUndefined(writeOff) && !_.isNull(writeOff.Products) 
			? _.map(writeOff.Products, function (v) { return _.extend(v, { New : false }); }) : _.clone(this.ProductDummy);

		this.groupProducts();

		this.ErrorCode = "";
		this.ButtonLoading = false;
		this.SuccessSave = false;
	}
	setRequests(requests) {
		// console.log(requests)
		this.RequestIds = _.map(requests, "Id");
		var products = _.map(_.flatten(_.map(requests, "Products")), function(v) { 
			return _.extend(v, { New : true }); });
		
		this.ProductsAll = _.concat(this.ProductsAll, products);
		// console.log(this.ProductsAll)
		// console.log("group requests")
		this.groupProducts();

	}

	groupProducts() {
		var store = this,
			type = [1,2,3],
			types = _.groupBy(this.ProductsAll, "Type");

		_.each(type, function (v) {
			var products = _.isUndefined(types[v]) ? [_.clone(store.ProductDummy)] : types[v];
			store.Products[v] = _.groupBy(products, "CancelReasonId");
		});

		// console.log(store.Products)
	}

	collectWriteOff(id, value) { this.WriteOff[id] = value; }

	collectProducts(id, value, index, reason, type, product) {
		this.Products[type][reason][index][id] = value;
		if (product) this.Products[type][reason][index].UnitName = product.UnitNet;
	}

	getSaveData() {
		var data = {
				Id : this.WriteOff.Id > 0 ? this.WriteOff.Id : -1,
				StockId: this.WriteOff.StockId > 0 ? this.WriteOff.StockId : this.StockActive,
			},
			index = 0,
			products = _.flatten(_.map(_.flatMap(this.Products), function (v) { return _.flatMap(v) }));

		if (checkRights("AcceptWriteOff") && this.WriteOff.Date !== "")
			data.Date = moment(this.WriteOff.Date).format("YYYY-MM-DD HH:mm")

		_.each(products, function (product, i) {
			var name = "Products." + index + ".";

			if (product.ProductId !== "" && product.ProductId !== -1) {
				data[name + "ProductId"] = product.ProductId;
				data[name + "Count"] = product.Count;
				data[name + "CancelReasonId"] = product.CancelReasonId;
				data[name + "Description"] = product.Description;
				index++;
			}
		});

		if (!_.isEmpty(this.RequestIds)) data.Requests = this.RequestIds.join(",");

		return data;
	}

	validateData() {
		var store = this,
			isValid = true,
			products = _.flatten(_.map(_.flatMap(this.Products), function (v) { return _.flatMap(v) }));

			
		products = _.filter(products, function (v) { return v.ProductId !== "" && v.ProductId !== -1 });

		if (_.isEmpty(products)) {
			this.ErrorCode = "AUDIT_PRODUCTS_REQUIRED";
			return false;
		}

		_.each(products, function (product) {
			if (product.CancelReasonId === -1 || product.CancelReasonId === "") {
				store.ErrorCode = "CANCELREASON_REQUIRED";
				isValid = false;
				return false;
			}
		});

		return isValid;
	}
}
decorate(WriteOffState, {
	WriteOffs: observable,
	IsSendRequest: observable,
	Filter: observable,
	HasMore: observable,

	WriteOffRequest: observable,
	RequestProducts: observable,

	StockActive: observable,
	WriteOff: observable,
	Products: observable,

	ButtonLoading: observable,
	ErrorCode: observable,
	SuccessSave: observable
});
export const writeOffState = new WriteOffState();