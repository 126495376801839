import { observable, decorate } from 'mobx';
import _ from 'lodash';
import { authUserState } from './common';
import { checkRights, collectCheckBox, formatDate } from '../functions';

/* --- ROLE STATE --- */
class RoleState {
	RoleDummy = {
		Id: -1,
		Active: true,
		Name : "",
		DepartmentId: -1,
		Rights: null,
		Dashboards: null,
		Notifications: [],
		IsCourier: false,
		IsDefault: false
	};

	/** Установка дефолтного состояния страницы */
	setDefault () {
		this.CanManage = checkRights("ManageRoles");

		this.Roles = [];
		this.Departments = [];
		this.Notifications = [];
		this.IsSendRequest = false;

		this.setRole(undefined);
		this.setDepartment(undefined);
	}

	/** ----- РАБОТА СО СПИСКОМ ДОЛЖНОСТЕЙ ----- */
	/**
	 * Разбор данных от сервера со списком должностей
	 * @param {object} data 
	 */
	setRoles(data) {
		this.Roles = !data.Success ? [] : _.map(data.Roles, function (v) { 
			return _.extend(v, { CanRemove : !v.IsDefault }); });
		this.IsSendRequest = true;
	}

	/** ФОрмирование списка уведомлений */
	get NotificationsList () {
		if (_.isUndefined(this.Notifications) ||  _.isEmpty(this.Notifications)) return [];

		return _.reject(_.clone(this.Notifications), { Group : "Orders" });
	}

	/** ----- РАБОТА СО СТРАНИЦЕЙ ДОЛЖНОСТИ ----- */
	/** Выбор активного отдела */
	setDepartment(department) { this.Department = _.isUndefined(department) ? undefined : { Id : -1, Name : "" }; }
	
	/**
	 * Сбор данных с формы отдела
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectDepartment(id, value) { this.Department[id] = value; }
	
	/**
	 * Разбор данных от сервера с должностью
	 * @param {object} role 
	 */
	setRole (role) {
		this.Role = _.isUndefined(role) ? undefined : role.Id === -1 ? _.clone(this.RoleDummy) 
			: _.extend(_.clone(this.RoleDummy), role, { 
				Notifications : _.isNull(role.Notifications) ? [] : role.Notifications
			});

		if (_.isUndefined(role)) {
			checkedRights.setRights(undefined);
			checkedBoards.setBoards(undefined);
		} else {
			checkedRights.setRights(_.isNull(this.Role.Rights) ? [] : this.Role.Rights);
			checkedBoards.setBoards(_.isNull(this.Role.Dashboards) ? [] : this.Role.Dashboards);
		}

		this.ButtonLoading = false;
		this.ErrorCode = "";
		this.SuccessSave = false;
	}

	/**
	 * Сбор данных с формы редактирования должности
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectRole (id, value) { 
		if (/Notifications/.test(id)) {
			var index = id.split("_")[1] ? parseInt(id.split("_")[1], 10) : undefined;
			this.Role.Notifications = collectCheckBox(index, value, this.Role.Notifications)
		} else this.Role[id] = value; 
	}

	/**
	 * Сбор данных должности для отправки на сервер
	 * @returns {object}
	 */
	getSaveData () {
		var data = this.Role;

		if (checkedRights.Checked.length === 0) data = _.omit(data, "Rights"); 
		else data = _.extend(data, { Rights : checkedRights.Checked.join(",")});

		_.each(checkedBoards.Checked, function (v,i) { 
			if (!_.isEmpty(v) && !_.isNull(v)) data["Dashboards." + i] = v.join(","); 
		});

		data = _.omit(data, "Dashboards", "IsDefault", "IsCourier", "ErrorCode");

		data.Notifications = data.Notifications.join(",")
		return data;
	}
}
decorate(RoleState, {
	Notifications: observable,	// Список доступных уведомлений
	Roles : observable,			// Список доступных должностей
	IsSendRequest: observable,	// Флаг отправки запроса

	Departments: observable,	// Список доступных отделов
	Department: observable, 	// Модель редактироваемого отдела

	Role: observable,			// Модель редактируемой должности
	ButtonLoading: observable,	// Флаг лоудера на кнопке
	ErrorCode: observable,		// Код ошибки редактирования
	SuccessSave: observable		// Флаг успешного сохранения
});
export const roleState = new RoleState();

/* --- USER STATE --- */
class UserState {
	UserDummy = {
		Id: -1,
		Active: true,
		FirstName : "",
		Patronymic : "",
		LastName : "",
		Login : "",
		Password : "",
		Email : "",
		IPList : "",
		IsAdmin : 0,
		Notifications : [],
		INN : "",
		IsCourier : false,
		TipsState : -1,
		TipsActive : -1,
		TipsRecipient : false,
		ShowINNFiscal : false
	};

	/** 
	 * Установка дефолтного состояния страницы 
	 * @param {string} type – тип пользователя — пользователь или курьер
	 */
	setDefault(type) {
		this.CanManageUsers = checkRights("ManageUsers");
		this.CanManageCouriers = checkRights("ManageCouriers");
		this.CanManage = type === "Couriers" ? this.CanManageCouriers : this.CanManageUsers;
		this.CanOpenShift = checkRights("CanCourierShift");
		this.CanRecipient = checkRights("ManageRecipient");
		this.CanManageINN = checkRights("ManageCourierINN");

		this.CourierInterface = type === "Couriers";

		this.Filter = authUserState.HasERP && userState.CanRecipient && !this.CourierInterface ? 
			{ Active : true, TipsRecipient : false } : { Active : true }
		this.Users = [];
		this.IsSendRequest = false;
		this.ActiveUsers = 0;

		this.allFilials = [];
		this.allRoles = [];
		this.allMerchants = [];
		this.UserLimit = !_.isUndefined(authUserState.CurrentMerchant) ? authUserState.CurrentMerchant.UserLimit : 0;

		
		this.setUser(undefined);
		this.setCourierShift(undefined);
	}
	/** Сбор данных с фильтра для отправки на сервер */
	getFilter() { return _.clone(this.Filter); }

	/**
	 * Разбор данных от сервера со списком пользователей
	 * @param {object} data 
	 */
	setUsers (data) {
		var isCourier = this.CourierInterface;

		this.Users = data.Success ? _.map(data.Users, function (v) {
			return _.extend(v, { 
				Name : isCourier ? v.Name : v.FirstName + " " + v.LastName,
				CanRemove : !v.IsAdmin 
			});
		}) : [];

		this.ActiveUsers = this.Filter.Active ? _.size(this.Users) : this.ActiveUsers;
		this.IsSendRequest = true;
	}

	/**
	 * Получение списка курьерских должностей
	 * @param {[object]} список курьерских должностей 
	 */
	get courierRoles () {
		if (_.isEmpty(this.allRoles) || _.isUndefined(this.allRoles)) return [];
		return _.filter(this.allRoles, { IsCourier : true });
	}

	/**
	 * Получение списка предприятий с учетом того, кто создается
	 * @param {[object]} список курьерских должностей 
	 */
	get merchantList () {
		if (_.isEmpty(this.allMerchants) || _.isUndefined(this.allMerchants)) return [];

		var couriers = this.courierRoles,
			isCourier = this.CourierInterface,
			merchants = isCourier ? _.filter(this.allMerchants, function(v) {
				return v.Type !== 2; }) : this.allMerchants;

		return _.map(merchants, function (v) {
			var role = _.find(couriers, { MerchantId : v.Id });

			return _.extend(v, { Disabled : isCourier ? role ? false : true : false });
		});
	}

	/**
	 * Получение списка должностей для пользователей
	 * @param {[object]} список должностей 
	 */
	get roleList () {
		if (_.isEmpty(this.allRoles) || _.isUndefined(this.allRoles)) return [];

		var roles = _.filter(this.allRoles, { MerchantId : authUserState.Merchant, IsCourier : false });

		return _.map(roles, function(v) { return _.extend(v, { Disabled : v.Name === "Директор" }); });
	}

	/**
	 * Получение списка уведомлений для пользователей
	 * @param {[object]} список уведомлений 
	 */
	get NotificationsList () {
		if (_.isUndefined(this.AllNotifications) ||  _.isEmpty(this.AllNotifications)) return [];

		return _.reject(_.clone(this.AllNotifications), { Group : "Orders" });
	}

	/**
	 * Разбор данных от сервера с пользователем
	 * @param {object} user
	 * @param {boolean} isCourier флаг, что пользователь курьер
	 */
	setUser (user, isCourier) {
		this.User = _.isUndefined(user) ? user : user.Id === -1 ? _.extend(_.clone(this.UserDummy), {
				IsCourier : isCourier,
			}) : _.extend(_.clone(this.UserDummy), _.omit(user, ["CreateDate", "RemoveDate"]), {
				Notifications : _.isNull(user.Notifications) ? [] : user.Notifications,
				TipsState : user.TipsRecipient ? user.TipsState : -1
			});

		this.CheckAllFilials = {};
		this.Filials = _.isUndefined(user) || user.Id === -1 || _.isNull(this.User.Filials) ? [] 
			: this.User.Filials;
		if (!_.isUndefined(user) && !authUserState.HasERP && user.Id === -1 && this.allFilials.length === 1) {
			this.Filials = _.map(this.allFilials, "Id");
		}

		this.POS = _.isUndefined(user) || user.Id === -1 || _.isNull(this.User.Filials) ? [] : this.User.POS;
		this.Cassa = _.isUndefined(user) || user.Id === -1 || _.isNull(this.User.Filials) ? [] : this.User.Cassa;
		this.MerchantIds = _.isUndefined(user) || user.Id === -1 || _.isNull(this.User.MerchantIds) ? [] 
			: this.User.MerchantIds;
		
		var store = this,
			cities = _.groupBy(this.allFilials, "City");
		_.each(cities, function (filials, city) {
			var filialsId = _.map(filials, "Id");

			store.CheckAllFilials[city] = _.isEmpty(_.difference(filialsId, store.Filials));
		});
		
		this.Roles = _.isUndefined(user) || user.Id === -1 || _.isNull(this.User.Roles) ? [] : this.User.Roles;

		checkedRights.setRights(_.isUndefined(user) ? undefined : _.isNull(this.User.Rights) ? [] : this.User.Rights);
		checkedBoards.setBoards(_.isUndefined(user) ? undefined : _.isNull(this.User.Dashboards) ? [] : this.User.Dashboards);

		this.ButtonLoading = false;
		this.ErrorCode = "";
		this.SuccessSave = false;
	}
	/**
	 * Сбор данных с формы редактирования пользователя
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectUser (id, value) { 
		console.log(id, value)
		if (/Notifications/.test(id)) {
			var index = id.split("_")[1] ? parseInt(id.split("_")[1], 10) : undefined;
			this.User.Notifications = collectCheckBox(index, value, this.User.Notifications);
		} else if (/TipsRecipient/.test(id)) this.User.TipsRecipient = value;
		else if (/ShowINNFiscal/.test(id)) this.User.ShowINNFiscal = value;
		else this.User[id] = value; 
	}

	/** Проверка условия, что флаг показа ИНН можно выбрать */
	get canShowInn () {
		if (_.isUndefined(this.User)) return false;

		var isINN = this.User.INN.replace(/\D/gi,"").length === 12,
			isMerchant = _.indexOf(this.MerchantIds, authUserState.Merchant) !== -1;

		return isINN && isMerchant;
	}

	/**
	 * Сбор данных с формы редактирования доступа к филиалам
	 * @param {string} name название параметра
	 * @param {string} value значение параметра
	 * @param {string} city название города
	 */
	collectFilialsPOS (name, value, city) {
		var store = this,
			filialsCities = _.groupBy(this.allFilials, "City"),
			filialsPos = _.map(this.allFilials, function(v) { 
				return {
					Id : v.Id,
					POS : _.map(v.POS, function(i) { return i.Id; })}
			});

		if (/CheckAll/.exec(name)) {
			var filialsPosCity = _.map(filialsCities[city], function(v) { 
					return {
						Id : v.Id,
						POS : _.map(v.POS, function(i) { return i.Id; })}
				}),
				filials = _.map(filialsPosCity, function(v) { return v.Id; }),
				poses = _.flatten(_.map(filialsPosCity, function(v) { return v.POS; }));

			this.Filials = value ? _.union(this.Filials, filials) : _.difference(this.Filials, filials);
			this.POS = value ? _.union(this.POS, poses) : _.difference(this.POS, poses);
			this.Cassa = value ? this.Cassa : _.difference(this.Cassa, poses);
		} else {
			var type = name.split("_")[0],
				id = parseInt(name.split("_")[1], 10);

			if (type === "Filials") {
				if (value) {
					this.Filials = _.union(this.Filials, [id]);
					var filial = _.find(filialsPos, { Id : id });
					this.POS = _.union(this.POS, filial.POS)
				} else {
					_.remove(this.Filials, function(v) { return v === id; });
					_.remove(this.POS, function (v) {
						var fil = _.find(filialsPos, { Id : id });

						return _.indexOf(fil.POS, v) !== -1;
					});
					_.remove(this.Cassa, function (v) {
						var fil = _.find(filialsPos, { Id : id });

						return _.indexOf(fil.POS, v) !== -1;
					});
				}
				
			} else if (type === "POS") {
				if (value) this.POS = _.union(this.POS, [id]);
				else {
					_.remove(this.POS, function(v) { return v === id; });
					_.remove(this.Cassa, function(v) { return v === id; });
				}
			} else if (type === "Cassa") {
				if (value) this.Cassa = _.union(this.Cassa, [id]);
				else _.remove(this.Cassa, function(v) { return v === id; });
			}
		}

		_.each(this.CheckAllFilials, function(v, i) {
			var filials = _.map(filialsCities[i], function (f) { return f.Id});
			store.CheckAllFilials[i] = _.isEmpty(_.difference(filials, store.Filials));
		});
	}

	getRightsBoards (type, added, id) {
		var store = this,
			role = _.find(this.allRoles, { Id : id }),
			roles = _.filter(this.allRoles, function (v) { return _.indexOf(store.Roles, v.Id) !== -1; }),
			collection = _.map(roles, function (v) { return v[type]; });

		if (type === "Rights") {
			collection = _.compact(_.flatten(collection));
			var rights = _.clone(checkedRights.Checked);

			if (added) return _.union(rights, role.Rights);
			else {
				_.each(_.difference(role.Rights, collection), function (v) { rights = _.without(rights, v); });
				return rights;
			} 
		} else if (type === "Dashboards") {
			var boards = _.clone(checkedBoards.Checked),
				colls = {
					Menu : _.uniq(_.compact(_.flatten(_.map(collection, "Menu")))),
					Sidebar : _.uniq(_.compact(_.flatten(_.map(collection, "Sidebar")))),
					Widget : _.uniq(_.compact(_.flatten(_.map(collection, "Widget"))))
				}

			if (added)
				_.each(boards, function (checked, name) {
					boards[name] = _.union(checked, role.Dashboards[name]);
				});
			else {
				_.each(boards, function (checked, name) {
					var board = _.clone(checked);
					_.each(_.difference(role.Dashboards[name], colls[name]), function (v) { 
						board = _.without(board, v); });

					boards[name] = board;
				});
			}

			return boards;
		} else if (type === "Notifications") {
			collection = _.compact(_.flatten(collection));
			var notifications = _.clone(this.User.Notifications);

			if (added) return _.union(notifications, role.Notifications)
			else {
				_.each(_.difference(role.Notifications, collection), function (v) { notifications = _.without(notifications, v); });
				return notifications;
			}
		}
	}
	/**
	 * Сбор данных с формы редактирования доступа к роли
	 * @param {string} name название параметра
	 * @param {string} value значение параметра
	 */
	collectRoles (id, value) {
		var index = parseInt(id.split("_")[1], 10);

		this.Roles = collectCheckBox(index, value, this.Roles);
		
		checkedRights.setRights(this.getRightsBoards("Rights", value, index));
		checkedBoards.setBoards(this.getRightsBoards("Dashboards", value, index));

		this.User.Notifications = this.getRightsBoards("Notifications", value, index)
	}

	/**
	 * Сбор данных с формы редактирования доступа к предприятию
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectMerchants (id, value) {
		var index = parseInt(id.split("_")[1], 10);
		this.MerchantIds = collectCheckBox(index, value, this.MerchantIds);

		if (this.CourierInterface) {
			var role = _.find(this.courierRoles, { MerchantId : index });
			if (role) this.collectRoles("Roles_" + role.Id, value);
		}
	}
	/**
	 * Сбор данных пользователя для отправки на сервер
	 * @returns {object}
	 */
	getSaveData () {
		var data = this.User;

		if (data.Login.indexOf("(") !== -1) data.Login = data.Login.replace(/\D/gi,"");

		if (_.isNull(checkedRights.Checked) || checkedRights.Checked.length === 0) data = _.omit(data, "Rights"); 
		else data = _.extend(data, { Rights : checkedRights.Checked.join(",")});

		_.each(checkedBoards.Checked, function (v,i) { 
			if (!_.isEmpty(v) && !_.isNull(v))
				data["Dashboards." + i] = v.join(","); 
		});
		data = _.omit(data, "Dashboards");

		if (data.Patronymic === "") data = _.omit(data, "Patronymic");

		if (_.isNull(this.Filials) || this.Filials.length === 0) data = _.omit(data, "Filials");
		else data = _.extend(data, { Filials : this.Filials.join(",")});

		if (_.isNull(this.MerchantIds) || this.MerchantIds.length === 0) data = _.omit(data, "MerchantIds");
		else data = _.extend(data, { MerchantIds : this.MerchantIds.join(",")});

		if (_.isNull(this.POS) || this.POS.length === 0) data = _.omit(data, "POS");
		else data = _.extend(data, { POS : this.POS.join(",")});

		if (_.isNull(this.Cassa) || this.Cassa.length === 0) data = _.omit(data, "Cassa");
		else data = _.extend(data, { Cassa : this.Cassa.join(",")});

		if (_.isNull(this.Roles) || this.Roles.length === 0) data = _.omit(data, "Roles");
		else data = _.extend(data, { Roles : this.Roles.join(",")});

		if (data.INN) data.INN = data.INN.replace(/\D/gi,"");

		if (_.isNull(data.Notifications) || data.Notifications.length === 0) 
			data = _.omit(data, "Notifications");
		else data.Notifications = data.Notifications.join(",");
		
		return data;
	}

	/**
	 * Проверка корректности заполнения данных пользователя
	 * 1. Телефон должен состоять из 11 цифр, включая код
	 * 2. Проверка на длину пароля и корректные символы
	 * 3. ИНН кассира должен состоять из 12 цифр
	 * 4. Если активен, должен быть подключен хотя бы один филиал
	 * 5. Если активен, должна быть подключена хотя бы одна должность
	 * @returns {boolean} флаг корректности заполнения
	 */
	validateData () {
		this.ErrorCode = "";
		this.SuccessSave = false;

		if (this.User.Login.replace(/\D/gi,"").length < 11) {
			this.ErrorCode = "WRONG_PHONE_LENGTH";
			return false;
		}

		if (!/.+@.+\..+/i.test(this.User.Email)) {
			this.ErrorCode = "WRONG_USER_EMAIL";
			return false;
		}

		if (this.User.INN !== "" && this.User.INN.replace(/\D/gi,"").length !== 12) {
			this.ErrorCode = "WRONG_USER_INN";
			return false;
		}
		
		if (this.User.Active && _.isEmpty(this.Roles)) {
			this.ErrorCode = "EMPTY_USER_ROLE";
			return false;
		}

		if (this.User.Active && _.isEmpty(this.Filials)) {
			this.ErrorCode = "EMPTY_USER_FILIAL";
			return false;
		}

		return true;
	}

	/** ------- COURIER SHIFT ------- */
	/** Открытие окна курьерской смены 
	 * @param {object} courier данные курьера
	 */
	setCourierShift(courier) {
		this.CourierShift = _.isUndefined(courier) ? undefined : _.extend(_.clone(courier), {
			FilialId : authUserState.Shift.FilialId,
			FilialName : _.find(authUserState.Filials, { Id : authUserState.Shift.FilialId }).Name,
			Date : formatDate("today", "rtime"),
			CloseAfterResolve : false
		});
		this.ErrorCodeShift = "";
	}

	/**
	 * Сбор данных с формы открытия/закрытия курьерской смены
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectCourierShift (id, value) {
		if (/CloseAfterResolve/.test(id)) this.CourierShift.CloseAfterResolve = value;
		else this.CourierShift[id] = value;
	}

	/** Формат данных для запроса открытия/закрытия курьерской смены */
	getCourierShift() { return _.omit(_.clone(this.CourierShift), ["CourierName", "FilialName"]); }
}
decorate(UserState, {
	User: observable,
	Roles: observable,
	Filials: observable,
	POS: observable,
	Cassa: observable,
	CheckAllFilials: observable,
	MerchantIds: observable,
	AllNotifications: observable,

	CourierInterface: observable,

	Users: observable,
	IsSendRequest: observable,
	Filter: observable,

	CourierShift: observable,
	ErrorCodeShift: observable,
	
	ButtonLoading: observable,
	ErrorCode: observable,
	SuccessSave: observable
});
export const userState = new UserState();

/* --- RIGHTS STATE FOR ROLES AND USERS --- */
class CheckedRights {
	allRights = [];
	Checked = [];
	CheckAll = false;

	setRights (rights) {
		var allRights = _.map(_.clone(this.allRights), function (v) { return v.Name }),
			userAdmin = userState.User && userState.User.IsAdmin > 0;
		
		this.Checked = _.isUndefined(rights) ? [] : userAdmin ? allRights : rights;
		this.CheckAll = _.isUndefined(rights) ? false : this.allRights.length === this.Checked.length;
	}

	collectRights (id, value) {
		var all = _.map(this.allRights, function(v) { return v.Name; });

		if (id === "CheckAll_all") this.Checked = value ? all : [];
		else {
			var name = _.last(id.split("_"));
			
			if (value) this.Checked = _.union(this.Checked, [name])
			else _.remove(this.Checked, function(v) { return v === name; })
		}
		
		this.CheckAll = this.Checked.length === all.length;
	}
}
decorate(CheckedRights, {
	allRights: observable,
	Checked: observable,
	CheckAll: observable,
})
export const checkedRights = new CheckedRights();

/* --- DASHBOARDS STATE FOR ROLES AND USERS --- */
class CheckedBoards {
	allBoards = [];
	Checked = [];
	CheckAllMenu = false;
	CheckAllSidebar = false;
	CheckAllWidget = false;
	BoardsDummy = {
		Menu : [],
		Sidebar : [],
		Widget : []
	}

	setBoards (boards) {
		var store = this,
			groups = _.groupBy(this.allBoards, "Type");

		this.Checked = _.isUndefined(boards) || _.isEmpty(boards) ? _.clone(this.BoardsDummy) : boards;

		_.each(["Menu", "Sidebar", "Widget"], function (type) {
			if (_.isNull(store.Checked[type])) store.Checked[type] = [];
			store["CheckAll" + type] = _.isUndefined(groups[type]) || _.isNull(groups[type]) ? false 
				: store.Checked[type].length === groups[type].length;
		});
	}

	collectBoards (id, value, type) {
		var all = _.map(_.filter(this.allBoards, { Type : type }), function(v) { return v.Id; });

		if (/CheckAll/.exec(id)) this.Checked[type] = value ? all : [];
		else {
			var name = parseInt(_.last(id.split("_")), 10);

			if (value) this.Checked[type] = _.union(this.Checked[type], [name])
			else _.remove(this.Checked[type], function(v) { return v === name; })

			if (name === 99 && type === "Menu" && !authUserState.HasERP) {
				if (value) {
					this.Checked.Sidebar = _.union(this.Checked.Sidebar, [name]);
					this.Checked.Widget = _.union(this.Checked.Widget, [name]);
				} else { 
					_.remove(this.Checked.Sidebar, function(v) { return v === name; });
					_.remove(this.Checked.Widget, function(v) { return v === name; });
				}
			}
		}

		this["CheckAll" + type] = this.Checked[type].length === all.length;
	}
}
decorate(CheckedBoards, {
	allBoards: observable,
	Checked: observable,
	CheckAllMenu: observable,
	CheckAllSidebar: observable,
	CheckAllWidget: observable
})
export const checkedBoards = new CheckedBoards();