import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import loader from "../images/loader.svg";

import { checkRights, toSelectList, getRequest } from '../functions';
import { dashboardsTitles, buttonTitles, rightTypes, directoryTitles, directoryAPI, directoryFields, 
	errors } from '../dictionary';

import { TextInput, Selectize, IconPreset } from '../forms/FormItems';
import { DataTable } from '../tables/Tables';

import { confirmState } from '../stores/common';
import { directoryState } from '../stores/directories';

export class Tags extends Component {
	render() { return (<Directories Type={this.props.Type} FilterActive={false} />) }
}
export class CancelReasons extends Component {
	render() { return (<Directories Type={this.props.Type} FilterActive={false} />) }
}
export class Inventory extends Component {
	render() { return (<Directories Type={this.props.Type} FilterActive={true} />) }
}
export class Marks extends Component {
	render() { return (<Directories Type={this.props.Type} FilterActive={false} />) }
}
export class WorkShops extends Component {
	render() { return (<Directories Type={this.props.Type} FilterActive={false} />) }
}

export class CassaTransactions extends Component {
	render() { return (<Directories Type={this.props.Type} FilterActive={false} />) }
}

class Directories extends Component {
	constructor(props) {
		super(props);

		directoryState.DirectoryType = this.props.Type;
		directoryState.setItem(undefined);

		this.state = {}

		this.getData = this.getData.bind(this);
		this.getData();

		if (this.props.Type === "Inventories") this.getSections();
	}

	/** API запрос на получение списка по типу справочника */
	getData() {
		var params = this.props.Type === "Inventories" ? { Active : directoryState.Active } : {};

		directoryState.IsSendRequest = false;
		getRequest(directoryAPI[this.props.Type].List, params, function (data) {
			directoryState.setList(data);
		});
	}

	/** API запрос на получение разделов инвентаря */
	getSections () {
		var model = this;
		getRequest("InventorySections", {}, function (data) { 
			model.setState({ Sections : data.Success ? data.Sections : [] });
		});
	}

	/** API запрос на редактировниии позиции справочника */
	handleSubmit(event) {
		event.preventDefault();

		var model = this;

		getRequest(directoryAPI[this.props.Type].Save, directoryState.getData(), function (data) {
			if (data.Success) {
				directoryState.setItem(undefined);
				model.getData();
			} else directoryState.ErrorCode = data.ErrorCode;
		});
	}

	/** Показать скрыть форму редактирования позиции справочника */
	toggleForm(event) {
		event.preventDefault();
		directoryState.setItem(!_.isUndefined(directoryState.DirectoryItem) ? undefined 
			: { Id: -1 }, directoryFields[this.props.Type]);
	}

	/**
	 * Выбор активной позиции справочника по ID
	 * @param {number} id 
	 */
	editRow(id) {
		directoryState.setItem(_.find(directoryState.Directory, { Id: id }), directoryFields[this.props.Type]);
	}

	/**
	 * API запоос на удалении позиции справочника по ID
	 * @param {number} id 
	 */
	removeRow(id) {
		if (_.isUndefined(id)) return false;

		var model = this,
			item = _.find(directoryState.Directory, { Id : id }),
			action = this.props.Type === "Inventories" && !directoryState.Active ? "восстановить" : "удалить",
			text = <span>Вы действительно хотите {action} {directoryTitles["remove" + this.props.Type]}
						&nbsp;<b key="b1">«{item ? item.Name : ""}»</b>?</span>;

		confirmState.openConfirm(text, function() {
			getRequest(directoryAPI[model.props.Type].Remove, { Id : id }, function () { model.getData(); });
		});
	}

	getColumns(fields) {
		var columns = {},
			canManage = checkRights(rightTypes[this.props.Type]);

		_.each(fields, function (v) { columns[v.Id] = {} });
		if (canManage) _.extend(columns, { Edit: {}, Remove: {} });

		return columns;
	}

	/** Смена фильтра активности */
	setActive (event) {
		event.preventDefault();
		directoryState.Active = !directoryState.Active;
		this.getData();
	}

	render() {
		return (
			<div className={"page " + this.props.Type} id="Directory">
				<div className="head">
					<h2>{dashboardsTitles[this.props.Type] || this.props.Type}</h2>
					{this.props.FilterActive &&
						<a href="/" className="toggleActive" onClick={this.setActive.bind(this)}>
							{buttonTitles[(directoryState.Active ? "not" : "") + "Active" + this.props.Type]}</a>
					}
					{_.isUndefined(directoryState.DirectoryItem) && checkRights(rightTypes[this.props.Type]) &&
						<button className="add" onClick={this.toggleForm.bind(this)}>
							{buttonTitles[this.props.Type] || "Добавить"}</button>
					}
				</div>
				{!_.isUndefined(directoryState.DirectoryItem) && this.props.Type !== "Inventories" &&
					<EditForm
						handleSubmit={this.handleSubmit.bind(this)}
						onClose={this.toggleForm.bind(this)}
					/>
				}
				{!_.isUndefined(directoryState.DirectoryItem) && this.props.Type === "Inventories" &&
					<EditInventory
						Sections={this.state.Sections}

						getSections={this.getSections.bind(this)}

						handleSubmit={this.handleSubmit.bind(this)}
						onClose={this.toggleForm.bind(this)}
					/>
				}
				{directoryState.IsSendRequest && !_.isEmpty(directoryState.Directory) &&
					<DataTable
						Data={directoryState.Directory}
						Columns={this.getColumns(directoryFields[this.props.Type])}
						Type={this.props.Type}

						editRow={(id) => this.editRow(id)}
						removeRow={(id) => this.removeRow(id)}

						getTrProps={(state, rowInfo, column) => {
							return { className: rowInfo && !_.isUndefined(rowInfo.original.Active)
								&& !rowInfo.original.Active ? "notactive" : "" };
						}}
					/>
				}
				{directoryState.IsSendRequest && _.isEmpty(directoryState.Directory) &&
					<p>По вашему запросу ничего не найдено</p>
				}
			</div>
		)
	}
}
Directories = observer(Directories);
class EditForm extends Component {
	onChange(value, id) { directoryState.collectItem(id, value); }

	render() {
		var view = this,
			isLoad = directoryState.ButtonLoading,
			item = directoryState.DirectoryItem;
		
		return (
			<div className="addNew clearfix" id={directoryState.DirectoryType + "Item"}>
				<form onSubmit={this.props.handleSubmit}>
					<div className="col col75">
						{_.map(directoryFields[directoryState.DirectoryType], function (field, i) {
							if (field.Type === "text" || field.Type === "number") {
								return (
									<TextInput
										key={"f" + i}
										Id={field.Id}
										Title={field.Title}
										Type={field.Type}
										Value={item[field.Id]}
										RowClass="col col1_3"
										onChange={view.onChange.bind(this)}
									/>
								)
							}
							if (field.Type === "select") {
								return (
									<Selectize
										key={"f" + i}
										Id={field.Id}
										Title={field.Title}
										Value={item[field.Id]}
										List={field.List ? field.List : []}
										RowClass="col col1_3"
										onChange={view.onChange.bind(this)}
									/>
								)
							}
							if (field.Type === "icon") {
								return(
									<IconPreset
										key={"f" + i}
										Id={field.Id}
										Title={field.Title}
										Value={item[field.Id]}
										Type={field.IconType}
										RowClass="col col1_3"
										onChange={view.onChange.bind(this)}
									/>
								)
							}
						})}
					</div>
					<div className="col col25 buttons">
						<button className={isLoad ? "loading" : ""} disabled={isLoad}>
							{isLoad && <img src={loader} alt="loading" /> }
							{!isLoad && "Сохранить" }
						</button>
						<a href="/" className="button cancel" title="Отменить"
							onClick={this.props.onClose}><i></i></a>
					</div>
					{directoryState.ErrorCode !== "" &&
						<p className="error">{errors[directoryState.ErrorCode] || errors.DEFAULT}</p>
					}
				</form>
			</div>
		)
	}
}
EditForm = observer(EditForm);
class EditInventory extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isAddSection: false,
			newSection : ""
		}
	}

	onChange(value, id) { directoryState.collectItem(id, value); }

	onSectionChange (value, id) {
		this.setState({ newSection : value });
	}

	toggleSection (event) {
		if (event) event.preventDefault();

		this.setState({ 
			isAddSection : !this.state.isAddSection,
			newSection : ""
		});
	}

	saveSection (event) {
		event.preventDefault()

		var model = this;

		getRequest("SaveInventorySection", { Id : -1, Name : this.state.newSection }, function (data) {
			if (data.Success) {
				model.props.getSections();
				model.toggleSection();
				directoryState.DirectoryItem.SectionId = data.Id;
			} else directoryState.ErrorCode = data.ErrorCode;	
		});
	}

	render() {
		var view = this,
			sectionList = this.props.Sections ? toSelectList(this.props.Sections) : [],
			isLoad = directoryState.ButtonLoading,
			item = directoryState.DirectoryItem;

		return (
			<div className="addNew clearfix" id={directoryState.DirectoryType + "Item"}>
				<form onSubmit={this.props.handleSubmit}>
					<div className="col col75">
						{_.map(directoryFields[directoryState.DirectoryType], function (field, i) {
							if (field.Type === "text" || field.Type === "number") {
								return (
									<TextInput
										key={"f" + i}
										Id={field.Id}
										Title={field.Title}
										Type={field.Type}
										Value={item[field.Id]}
										RowClass="col col1_3"
										Step={field.Id === "Price" ? 0.01 : 1}
										onChange={view.onChange.bind(this)}
									/>
								)
							}
						})}
						{!this.state.isAddSection && 
							<div className="clearfix sections">
								<Selectize
									Id="SectionId"
									Title="Раздел"
									Value={item.SectionId}
									List={sectionList}
									RowClass="col col1_3"
									onChange={view.onChange.bind(this)}
								/>
								<a href="/" className="button" onClick={this.toggleSection.bind(this)}>
									Создать раздел</a>
							</div>
						}
						{this.state.isAddSection && 
							<div className="clearfix sections edit">
								<TextInput
									Id="SectionId"
									Title="Название раздела"
									Value={this.state.newSection}
									RowClass="col col1_3"
									onChange={view.onSectionChange.bind(this)}
								/>
								<a href="/" className="button" title="Сохранить"
									onClick={this.saveSection.bind(this)}>Добавить</a>
								<a href="/" className="button cancel" title="Отменить" 
									onClick={this.toggleSection.bind(this)}><i></i></a>
							</div>
						}
					</div>
					<div className="col col25 buttons">
						<button className={isLoad ? "loading" : ""} disabled={isLoad}>
							{isLoad && <img src={loader} alt="loading" /> }
							{!isLoad && "Сохранить" }
						</button>
						<a href="/" className="button cancel" title="Отменить"
							onClick={this.props.onClose}><i></i></a>
						{directoryState.ErrorCode !== "" &&
							<p className="error">{errors[directoryState.ErrorCode] || errors.DEFAULT}</p>
						}
					</div>
				</form>
			</div>
		)
	}
}
EditInventory = observer(EditInventory);