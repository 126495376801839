import React, { Component } from 'react';
import { observer } from "mobx-react";
import pageLoader from '../images/loader_big.svg';
import _ from 'lodash';

import { Selectize, SubmitButton, ButtonGroup, DateInput, MonthPreset, TextInput, CheckGroup } from '../forms/FormItems';
import { DataTable } from '../tables/Tables';

import { toSelectList, formatDate, getHourTime, checkRights, toCheckList } from '../functions';
import { errors } from '../dictionary';
import { authUserState, confirmState, uistate } from '../stores/common';
import { payoutState } from '../stores/payouts';
import { ItemHeader, TableReportPage } from './PageTemplates';

const SalaryStaff = {
	Success: true,
	Staff: [
		{
			Name: "Антон",
			Id : 1,
			Role: "Курьер",
			Accrual: 123400, 
			Payout: 567800, 
		},
		{
			Name: "Вася",
			Role: "Курьер",
			Accrual: 0, 
			Id : 2,
			Payout: 872400,
		},
		{
			Name: "Тотоха",
			Role: "Курьер",
			Accrual: 23400, 
			Id : 3,
			Payout: 1567800,
		}
	]
}

const SalaryUser = {
	Success : true,
	Id : 1,
	Name : "Антон",
	IsCourier : true,
	Accruals : [
		{
			Id : 8,
			Amount : 53400,
			Date : "2020-11-09",
			SalaryDate : "2020-11-08",
			Description : "Вышел за Тотоху",
			PaymentType : 1
		},
		{
			Id : 9,
			Amount : 15000,
			Date : "2020-11-09",
			SalaryDate : "2020-11-07",
			Description : "",
			PaymentType : 1
		},
		{
			Id : 10,
			Amount : 55000,
			Date : "2020-11-10",
			SalaryDate : "2020-11-06",
			Description : "",
			PaymentType : 2
		}
	],
	Payouts : [
		{
			Id : 1,
			Amount : 381000, 
			Date : "2020-11-05",
			SalaryDate : "2020-11-03",
			Description : "",
			PaymentType : 1,
			POSId : 1,
			POS : "Гагарина зал"
		},
		{
			Id : 2,
			Amount : 186800, 
			Date : "2020-11-05",
			SalaryDate : "2020-11-04",
			Description : "",
			PaymentType : 2,
			POSId : -1,
			POS : ""
		}
	]

}

const ShiftReport = {
	Success : true,
	Shifts : [
		{
			AutoClose: false,
			Filial: "Гагарина",
			Role: "Директор",
			ShiftClose: "2020-11-11 22:43",
			ShiftOpen: "2020-11-11 13:11",
			ShiftDate: "2020-11-11",
			User: "Антон"
		},
		{
			AutoClose: false,
			Filial: "Гагарина",
			Role: "Директор",
			ShiftClose: "2020-11-13 14:57",
			ShiftOpen: "2020-11-13 10:09",
			ShiftDate: "2020-11-13",
			User: "Антон"
		},
		{
			AutoClose: false,
			Filial: "Гагарина",
			Role: "Директор",
			ShiftClose: "2020-11-12 14:07",
			ShiftOpen: "2020-11-12 13:20",
			ShiftDate: "2020-11-12",
			User: "Антон"
		}
	]
}

const DeliveryOrdersStat = {	
	Success : true,
	Users : [
		{
			Name : "Антон",
			Count : 13,
			Amount : 763400,
			Avg : 58723,
			Date : "2020-11-11"
		},
		{
			Name : "Антон",
			Count : 7,
			Amount : 982600,
			Avg : 140371,
			Date : "2020-11-13"
		}
	]
}


export class PayoutsUsers extends Component {
	render() { return (<PayoutsPage Type={this.props.Type} />) }
}

export class PayoutsCourier extends Component {
	render() { return (<PayoutsPage Type={this.props.Type} />) }
}

class PayoutsPage extends Component {
	constructor(props) {
		super(props);
		this.state = {}

		payoutState.setDefault(this.props.Type);

		this.getData();
	}

	/**
	 * Установка в состоянии модели ответа от сервера
	 * @param {object} data ответ от сервера
	 * @param {string} param название параметра
	 */
	setData(data, param) { this.setState({ [param]: data.Success ? data[param] : [] }); }

	/** API запрос на получение данных с сервера */
	getData() {
		payoutState.IsSendRequest = false;
		payoutState.setList(SalaryStaff.Staff);

		// TODO расскоментить это
		// getRequest("SalaryStaff", payoutState.getFilter(), 
		// 	function (data) { payoutState.setList(data.Success ? data.Staff : []); });
	}

	/** Сабмит формы поиска */
	handleSubmit(event) {
		if (event) event.preventDefault();

		if (!payoutState.validateFilter()) return false;

		this.getData();
	}

	handleChange (value, id) {
		payoutState.collectFilter(id, value);
	}

	/**
	 * Получение ID для отображения
	 * @param {number} id ID пользователя или undefined
	 */
	setId (id) {
		if (_.isUndefined(id)) {
			payoutState.setUser(id);
			this.getData();
		} else this.getItem(id);
	}

	/**
	 * API запрос на получение пользователя по ID
	 * @param {number} id 
	 */
	getItem(id) {
		payoutState.setUser(SalaryUser);
		// TODO расскоментить это
		// getRequest("SalaryUser", { UserId : id, Date : payoutState.Filter.Date, FilialId : payoutState.Filter.FilialId },
		// function(data) { payoutState.setUser(data.Success ? data : undefined ); });
	}

	render() {
		var titles = {
			PayoutUser: "Выплаты сотрудникам",
			PayoutCourier: "Выплаты курьерам"
		}

		return (
			<div className={"clearfix page " + (uistate.IsMobile ? "mobile" : "")} id="PayoutPage">
				{_.isUndefined(payoutState.User) &&
					<div className="head clearfix">
						<h2>{titles[this.props.Type]}</h2>
						{checkRights("ManageSalary") &&
							<PayoutFilter handleChange={this.handleChange.bind(this)} />
						}
					</div>
				}
				{_.isUndefined(payoutState.User) &&
					<StaffTable Type={this.props.Type} setId={this.setId.bind(this)} />
				}
				{!_.isUndefined(payoutState.User) &&
					<StaffItem setId={this.setId.bind(this)} />
				}
				{!_.isUndefined(payoutState.Accrual) &&
					<NewAccrualWindow getItem={this.getItem.bind(this)} />
				}
				{!_.isEmpty(payoutState.Payments) &&
					<AccrualPayoutWindow getItem={this.getItem.bind(this)} />
				}
			</div>
		)
	}
}
PayoutsPage = observer(PayoutsPage);

class PayoutFilter extends Component {
	render() {
		return(
			<form className="filter">
				<MonthPreset
					Id="Date"
					Value={payoutState.Filter.Date}
					MinDate={checkRights("CanViewAllSalary") ? undefined : formatDate("today", "r")}
					MaxDate={formatDate("today", "r")}
					RowClass="col col60 nomargin"
					onChange={this.props.handleChange}
				/>
				<Selectize
					Id="FilialId"
					Placeholder="Филиал..."
					List={toSelectList(authUserState.Filials)}
					Value={payoutState.Filter.FilialId}
					RowClass="col col30 nomargin"
					isClearable={false}
					isSearchable={false}
					onChange={this.props.handleChange}
				/>
			</form>
		)
	}
}
PayoutFilter = observer(PayoutFilter);



class StaffTable extends Component {
	render() {
		var columns = { Name : {}, Accrual : {}, Payout : {}, View : {}}

		return (
			<div className="clearfix table">
				{!payoutState.IsSendRequest &&
					<div className="loader"><img src={pageLoader} alt="logo" /></div>
				}
				{payoutState.IsSendRequest && _.isEmpty(payoutState.Staff) && <p>{errors.EMPTY_RESPONSE}</p>}
				{payoutState.IsSendRequest && !_.isEmpty(payoutState.Staff) &&
					<DataTable
						Data={payoutState.Staff}
						Columns={columns}
						Type={this.props.Type}

						IsSortable={false}

						getTrProps={(state, rowInfo, column) => {
							return { className: rowInfo && rowInfo.original.Name === rowInfo.original.Role ? "section" : 
								rowInfo.original.Accrual > 0 ? "alert" : "" };
						}}

						editRow={this.props.setId}
					/>
				}
				
			</div>
		)
	}
}
StaffTable = observer(StaffTable);

class StaffItem extends Component {
	constructor(props) {
		super(props);
		this.state = {}

		this.getShiftStat();
		if (payoutState.User.IsCourier) this.getDeliveryStat();
	}

	/** API запрос на получение отчетов по открытию смен пользователя */
	getShiftStat() {
		payoutState.UserShift = ShiftReport.Shifts;

		// TODO uncomment this
		// getRequest("ShiftReport", payoutState.getStatFilter("ShiftReport"), function(data) {
		// 	payoutState.UserShift = data.Success ? data.Shifts : [];
		// });
	}
	/** API запрос на получение отчетов по получение доставок пользователя */
	getDeliveryStat() {
		payoutState.UserOrders = DeliveryOrdersStat.Users;
		// TODO uncomment this
		// getRequest("DeliveryOrdersStat", payoutState.getStatFilter(), function(data) {
		// 	payoutState.UserOrders = data.Success ? data.Users : [];
		// });
	}



	/** Закрытие окна сотрудника */
	handleClose(event) {
		event.preventDefault();
		this.props.setId(undefined);
	}

	/**
	 * Сбор данных с формы фильтра статистики сотрудника
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleFilterChange (value, id) {
		payoutState.collectStatFilter(id, value);
		this.getDeliveryStat();
		this.getShiftStat();
	}



	render() {
		return(
			<section className="clearfix item two-cols" id="PayoutItem">
				<ItemHeader
					Title={"Выплаты «" + payoutState.User.Name + "»"}
					Error={""}
					CanManage={false}
					handleClose={this.handleClose.bind(this)}
				/>
				
				<div className="clearfix section">
					<div className="clearfix">
						<div className="col col65 gray">
							<div className="clearfix">
								<PaymentBlock Data={payoutState.Accruals} Type="Accrual" />
								<PaymentBlock Data={payoutState.Payouts} Type="Payout" />
							</div>
						</div>
						<div className="col col35">
							<SalaryStat handleChange={this.handleFilterChange.bind(this)} />
							<SalaryCalc />
						</div>
					</div>
				</div>
			</section>
		)
	}
}
StaffItem = observer(StaffItem);

class NewAccrualWindow extends Component {
	/** API запрос на сохранение начисления */
	handleSubmit (event) {
		event.preventDefault();

		// var model = this;

		// TODO убрать заглушки
		var id = _.maxBy(payoutState.Accruals, "Id"),
			data = payoutState.getAccrual(),
			isNew = data.Id === -1,
			item = isNew ? _.extend(data, { Id : id + 1 }) : data;

		if (isNew) payoutState.Accruals = _.concat(payoutState.Accruals, item);
		else {
			var index = _.findIndex(payoutState.Accruals, { Id : data.Id });
			payoutState.Accruals[index] = data;
		}
		payoutState.setAccrual(undefined);


		// getRequest("SalaryAccrual", payoutState.getAccrual(), function(data) {
		// 	if (data.Success) {
		// 		model.getItem(payoutState.User.Id);		
		// 		payoutState.setAccrual(undefined);
		// 	} else payoutState.ErrorCode = data.ErrorCode;
		// })
	}

	/** Закрытие окна начисления */
	handleClose(event) {
		event.preventDefault()
		payoutState.setAccrual(undefined);
	}

	/**
	 * Сбор данных с формы начисления/выплаты
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange(value, id) { payoutState.collectAccrual(id, value); }

	render() {
		var typeList = [{ Id : 1, Name : "Нал" }, { Id : 2, Name : "Б/нал" }]

		return(
			<div className="back">
				<div className="confirmBlock clearfix payout window">
					<h4>Начисление</h4>
					<form className="clearfix" onSubmit={this.handleSubmit.bind(this)}>
						<div className="clearfix">
							<DateInput
								Id="SalaryDate"
								Title="Дата ЗП"
								Value={payoutState.Accrual.SalaryDate}
								RowClass="col col35"
								onChange={this.handleChange.bind(this)}
							/>
							<TextInput
								Id="Amount"
								Title="Сумма начисления"
								Type="number"
								Step="0.01"
								Value={payoutState.Accrual.Amount}
								RowClass="col col30"
								onChange={this.handleChange.bind(this)}
							/>
							<ButtonGroup
								Id="PaymentType"
								Title="Тип оплаты"
								List={typeList}
								Type="number"
								Value={payoutState.Accrual.PaymentType}
								RowClass="col col35"
								onChange={this.handleChange.bind(this)}
							/>
							<TextInput
								Id="Description"
								Title="Комментарий"
								Type="textarea"
								Value={payoutState.Accrual.Description}
								RowClass="col col100"
								onChange={this.handleChange.bind(this)}
							/>
						</div>
						{payoutState.ErrorCode !== "" &&
							<p className="error">{errors[payoutState.ErrorCode] || errors.DEFAULT}</p>
						}
						<div className="clearfix buttons">
							<SubmitButton Loading={payoutState.ButtonLoading} Title="Сохранить" />
							<a href="/" role="button" onClick={this.handleClose.bind(this)} className="button close">Отменить</a>
						</div>
					</form>
				</div>
			</div>
		)
	}
}
NewAccrualWindow = observer(NewAccrualWindow);

class AccrualPayoutWindow extends Component {
	/** API запрос на выплаты по начислениям */
	handleSubmit (event) {
		event.preventDefault();
		// var model = this;

		// TODO убрать заглушку
		var data = payoutState.getPayout(),
			ids = _.map(data.Ids.split(","), function(v) { return parseInt(v, 10); }),
			accruals = _.filter(payoutState.Accruals, function(v) { return _.indexOf(ids, v.Id) !== -1; }),
			payouts = _.map(accruals, function(v){ return _.extend(v, { POS : data.POS }); })

			console.log(ids, accruals)

		payoutState.Payouts = _.concat(payoutState.Payouts, payouts);
		payoutState.Accruals = _.filter(payoutState.Accruals, function(v) { return _.indexOf(ids, v.Id) === -1; })
		payoutState.setPayments(false);
		console.log(payoutState.Payouts)


		// getRequest("AccrualPayout", payoutState.getPayout(), function(data) {
		// 	if (!data.Success) payoutState.ErrorCode = data.ErrorCode;
		// 	else {
		// 		model.props.getItem();
		// 		payoutState.setPayments(false);
		// 	}
		// });
	}

	/** Закрытие окна выплат по начислениям */
	handleClose(event) {
		event.preventDefault()
		payoutState.setPayments(false);
	}

	/**
	 * Сбор данных с формы выплат по начислениям
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange(value, id) { payoutState.collectPayout(id, value); }

	render() {
		var view = this;

		return(
			<div className="back">
				<div className="confirmBlock clearfix payout payment window">
					<form className="clearfix" onSubmit={this.handleSubmit.bind(this)}>
						{_.map(payoutState.Payments, function (items, group) {
							return(<AccrualPayoutGroup Items={items} Group={parseInt(group, 10)} 
								key={group} handleChange={view.handleChange.bind(view)} />)
						})}
						{payoutState.ErrorCode !== "" &&
							<p className="error">{errors[payoutState.ErrorCode] || errors.DEFAULT}</p>
						}
						<div className="clearfix buttons">
							<SubmitButton Loading={payoutState.ButtonLoading} Title="Выплатить" />
							<a href="/" role="button" onClick={this.handleClose.bind(this)} className="button close">Отменить</a>
						</div>
					</form>
				</div>
			</div>
		)
	}
}
AccrualPayoutWindow = observer(AccrualPayoutWindow);

class AccrualPayoutGroup extends Component {
	render() {
		var typeList = { 1 : "Нал", 2 : "Б/нал" },
			filial = _.find(authUserState.Filials, { Id : payoutState.Payout.FilialId }),
			poses = filial ? _.filter(filial.POS, { HasCassa : true }) : [],
			payoutPos = _.find(poses, { Payout : true });

		return(
			<div className="clearfix block">
				<h4>Выплата по начислениям «{typeList[this.props.Group]}»</h4>
				<CheckGroup
					Id="Ids"
					Type="checkbox"
					List={toCheckList(this.props.Items)}
					Value={payoutState.Payout.Ids}
					RowClass="nomargin col col100"
					onChange={this.props.handleChange}
				/>
				{this.props.Group === 1 &&
					<Selectize
						Id="FilialId"
						Title="Филиал выплаты"
						List={toSelectList(authUserState.Filials)}
						Value={payoutState.Payout.FilialId}
						RowClass="col col50"
						isClearable={false}
						isSearchable={false}
						onChange={this.props.handleChange}
					/>
				}
				{this.props.Group === 1 && _.isUndefined(payoutPos) &&
					<Selectize
						Id="POSId"
						Title="Касса выплаты"
						List={toSelectList(poses)}
						Value={payoutState.Payout.POSId}
						isClearable={false}
						isSearchable={false}
						RowClass="col col50"
						onChange={this.props.handleChange}
					/>
				}
			</div>
		)
	}
}
AccrualPayoutGroup = observer(AccrualPayoutGroup);

class PaymentBlock extends Component {
	/** API запрос на удаление начисления/выплаты */
	removePayment (id) {
		console.log(id)
		// TODO убрать заглушки
		// var model = this,
		// 	request = {
		// 		Accrual : "RemoveSalaryAccrual",
		// 		Payout : "RemoveSalaryPayout"
		// 	},
		var	type = this.props.Type,
			list = payoutState[this.props.Type + "s"],
			item = _.find(list, { Id : id }),
			text = <span>Вы действительно хотите удалить {type === "Accrual" ? "начисление" 
				: "выплату"} от <b key="b1">«{item ? formatDate(item.Date) : ""}»</b>?</span>;

		confirmState.openConfirm(text, function() {
			if (type === "Accrual")
				payoutState.Accruals = _.filter(list, function(v) { return v.Id !== id; });
			if (type === "Payout")
				payoutState.Payouts = _.filter(list, function(v) { return v.Id !== id; });
	
			// getRequest(request[this.props.Type], { Id : id }, function(data) {
			// 	if (data.Success) {}
			// 	else errorState.setError(data.ErrorCode);
			// });
		})

	}

	/** Открытие окна создания начисления */
	openAccrual (event) {
		event.preventDefault();
		payoutState.setAccrual({ Id : -1 });
	}

	/** Открытие окна создания начисления */
	openPayout (event) {
		event.preventDefault();
		payoutState.setPayments(true);
	}

	/**
	 * Открытие окна редактирования начисления
	 * @param {number} id ID начисления
	 */
	setAccrual (id) { payoutState.setAccrual(_.find(this.props.Data, { Id : id })); }

	render() {
		var paymentTypes = [{ Id : 1, Name : "Нал" }, { Id : 2, Name : "Б/нал" }],
			title = this.props.Type === "Payout" ? "Выплаты" : "Начисления",
			columns = { Date : { Header : "Дата", width: 90, Cell: row => formatDate(row.original.Date) }, 
				SalaryDate : {}, Amount : {}, 
				PaymentType : {
					Cell: function(row) {
						var type = _.find(paymentTypes, { Id : row.original.PaymentType });
						if (type) return <span title={type.Name} className={"icon type_" + type.Id}></span>;
						else return "";
					}
				}, Description : {}
			}

		if (this.props.Type === "Accrual") columns = _.extend(columns, { Edit : {}, Remove : {} });
		if (this.props.Type === "Payout") columns = _.extend(columns, { POS : {}, Remove : {} });

		return(
			<div className="clearfix payments block">
				<div className="clearfix">
					<h4>{title}</h4>
					{this.props.Type === "Accrual" &&
						<button className="button green" onClick={this.openPayout.bind(this)}>
							Выплатить</button>
					}
					{this.props.Type === "Accrual" &&
						<button className="button blue" onClick={this.openAccrual.bind(this)}>
							Новое начисление</button>
					}
				</div>
				<TableReportPage	
					TableData={this.props.Data}
					TableColumns={columns}
					Type= {this.props.Type}

					editRow={this.setAccrual.bind(this)}
					removeRow={this.removePayment.bind(this)}
				/>
			</div>
		)
	}
}
PaymentBlock = observer(PaymentBlock);

class SalaryStat extends Component {
	render () {
		// TODO сделана таблица, расчет и запросы только под курьеров. под остальных пользователей надо делать подругому
		return(
			<div className="block clearfix stat">
				<h4>Статистика по курьеру</h4>
				<form className="form clearfix">
					<DateInput
						Id="DateFrom"
						Title="Дата начала"
						Value={payoutState.StatFilter.DateFrom}
						IsClearable={false}
						RowClass="col col50"
						onChange={this.props.handleChange}
					/>
					<DateInput
						Id="DateTo"
						Title="Дата окончания"
						Value={payoutState.StatFilter.DateTo}
						IsClearable={false}
						RowClass="col col50"
						onChange={this.props.handleChange}
					/>
				</form>
				<div className="clearfix titles">
					<span className="col col30">Дата смены</span>
					<span className="col col35">Часов на смене</span>
					<span className="col col35">Кол-во доставок</span>
				</div>
				{_.map(payoutState.userStat, function(date, i) {
					return(<div className="clearfix item" key={i}>
						<span className="col col30">{formatDate(date.ShiftDate)}</span>
						<span className="col col35">{getHourTime(date.Time)}</span>
						<span className="col col35">{date.Orders}</span>
					</div>);
				})}
			</div>
		)
	}
}
SalaryStat = observer(SalaryStat);

class SalaryCalc extends Component {
	handleChange(value, id) {

	}
	render() {
		// var calcFields = {
		// 	courier : [
		// 		{ Id : "Count", Title : "", Value : 0 },
		// 		{ Id : "Count", Title : "", Value : 0 },
		// 		{ Id : "Count", Title : "", Value : 0 }
		// 	]
		// }

		return(
			<div className="clearfix calc block">
				<h4>Калькулятор начислений</h4>
				<form className="clearfix">
					<div className="clearfix item">
						<TextInput
							Id=""
							Title="Кол-во часов"
							Type="number"
							Value={0}
							RowClass="col col50"
							onChange={this.handleChange.bind(this)}
						/>
						<TextInput
							Id=""
							Title="Ставка за час"
							Value={0}
							Type="number"
							Step="0.01"
							RowClass="col col50"
							onChange={this.handleChange.bind(this)}
						/>
					</div>
					<div className="clearfix item">
						<TextInput
							Id=""
							Title="Кол-во доставок"
							Value={0}
							Type="number"
							RowClass="col col50"
							onChange={this.handleChange.bind(this)}
						/>
						<TextInput
							Id=""
							Title="Ставка за час"
							Value={0}
							Type="number"
							Step="0.01"
							RowClass="col col50"
							onChange={this.handleChange.bind(this)}
						/>
					</div>
					<div className="clearfix item">
						<TextInput
							Id=""
							Title="Кол-во км"
							Value={0}
							Type="number"
							RowClass="col col50"
							onChange={this.handleChange.bind(this)}
						/>
						<TextInput
							Id=""
							Title="Ставка за км"
							Value={0}
							Type="number"
							Step="0.01"
							RowClass="col col50"
							onChange={this.handleChange.bind(this)}
						/>
					</div>
					<div className="clearfix item">
						<span className="col col50">ИТОГО</span>
						<span className="col col50">0.00 руб.</span>
					</div>
				</form>
			</div>
		)
	}
}
SalaryCalc = observer(SalaryCalc);