import { getJSONRequest, getRequest, getUpload } from "../functions";

/** API метод «Filials», отдающий список доступных филиалов пользователя */
export function getFilialsList(callback) {
	getRequest("Filials", {}, (data) => { if (callback) callback(data); });
}

/** API метод «Users», отдающий список пользователей 
 * @param {boolean} [active=true] флаг активных/удаленных пользователей
 */
export function getUsersList(active = true, callback) {
	getRequest("Users", { Active : active}, (data) => { if (callback) callback(data); });
}

/** API метод «Roles», отдающий список должностей */
export function getRolesList(callback) {
	getRequest("Roles", {}, (data) => { if (callback) callback(data); });
}

//** ----- МЕТОДЫ РАБОТЫ С АККАУНТОМ ПОЛЬЗОВАТЕЛЯ ----- */
/** */
export function getAccount(params = {}, callback) {
	getRequest("Account", params, (data) => { if (callback) callback(data); }); 
}

//** ----- МЕТОДЫ РАБОТЫ С ЧЕК ЛИСТАМИ ----- */
/** API метод «Checklists», отдающий список чек листов 
 * @param {boolean} template флаг запроса шаблонов/чек листов предприятия
 * @param {funсtion} callback функция обработки результата
 * @returns {{ Success : boolean, ErrorCode : string, Checklists : [object] }} data ответ от сервера
 */
export function getCheckLists(template=false, callback) {
	getRequest("Checklists", { Templates : template }, (data) => { if (callback) callback(data); });
}
/** API метод «Checklist», отдающий чек лист */
export function getCheckListbyId(id, callback) {
	getRequest("CheckList", { Id : id }, (data) => { if (callback) callback(data); });
}
/** API метод «ChecklistRemove», удаляющий чек лист 
 * @param {number} id ID удаляемой задачи
 * @param {funсtion} callback функция обработки результата
 * @returns {{ Success : boolean, ErrorCode : string }} data ответ от сервера
 */
export function removeCheckListbyId(id, callback) {
	getRequest("ChecklistRemove", { Id : id }, (data) => { if (callback) callback(data); });
}
/** API метод «ChecklistSave», для сохранения чек листа 
 * @returns {{ Success : boolean, ErrorCode : string, Id : number }} data ответ от сервера
 */
export function saveCheckList(params, callback) {
	getJSONRequest("ChecklistSave", params, (data) => { if (callback) callback(data); });
}

/** API метод «TasksList», отдающий список задач на сегодня 
 * @param {funсtion} callback функция обработки результата
 * @returns {{ Success : boolean, ErrorCode : string, Tasks : [object] }} data ответ от сервера
 */
export function getTasks(callback) {
	getRequest("TasksList", {}, (data) => { if (callback) callback(data); });
}
/** API метод «DoneTask», выполняющий задачу 
 * @param {{ Id : number, Comment : string, Photos : [file]}} params параметры запроса
 * @param {funсtion} callback функция обработки результата
 * @returns {{ Success : boolean, ErrorCode : string, Id : number }} data ответ от сервера
 */
export function doneTaskbyId(params, callback) {
	getUpload("DoneTask", params, (data) => { if (callback) callback(data); });
}

/** API метод «ChecklistReport, получающий отчет по чек листам в виде групп 
 * @param {object} params параметры запроса
 * @param {funсtion} callback функция обработки результата
 * @returns {{ Success : boolean, ErrorCode : string, TaskReportGroups : [object], HasMore : boolean }} data ответ от сервера
 */
export function getTasksReportGroup(params, callback) {
	getRequest("ChecklistReport", params, (data) => { if (callback) callback(data); });
}

/** API метод «ChecklistStats», получающий статистику выполнения задач
 * @param {object} params параметры запроса
 * @param {funсtion} callback функция обработки результата
 */
export function getTasksReportStat(params, callback) {
	getRequest("ChecklistStats", params, (data) => { if (callback) callback(data); });
}

/** API метод «ChecklistReport, получающий отчет по конкретной группе чек листа
 * @param {string} params ключ группы
 * @param {funсtion} callback функция обработки результата
 * @returns {{ Success : boolean, ErrorCode : string, TaskReportElements : [object] }} data ответ от сервера
 */
export function getTasksReportGroupById(group, callback) {
	getRequest("GetGroupTasks", { GroupName : group }, (data) => { if (callback) callback(data); });
}

/** API метод «ControlTasksList», получающий список задач, по которым нужно провести ревью
 * @param {funсtion} callback функция обработки результата
 * @returns {{ Success : boolean, ErrorCode : string, Tasks : [object] }} data ответ от сервера
 */
export function getTasksOnControl(callback) {
	getJSONRequest("ControlTasksList", { OnControl : true }, (data) => { if (callback) callback(data); });
}

/** API метод «ReviewTask», принимающая/возвращающая задачу
 * @param {{ Id : number, Comment : string, Approve : boolean, Photos : [file]}} params ключ группы
 * @param {funсtion} callback функция обработки результата
 * @returns {{ Success : boolean, ErrorCode : string }} data ответ от сервера
 */
export function reviewTaskById(params, callback) {
	getUpload("ReviewTask", params, (data) => { if (callback) callback(data); });
}