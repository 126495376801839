import React, { Component } from 'react';
import { observer } from "mobx-react";
import _ from 'lodash';
import moment from 'moment';

import { NavLink } from 'react-router-dom';

import { uistate, authUserState } from '../stores/common';
import { releaseState } from '../stores/release';

import { getRequest } from '../functions';
import { dashboardsTitles, errors } from '../dictionary';
import { TextInput } from '../forms/FormItems';
import { autorun } from 'mobx';

export class ReleasePage extends Component {
	constructor(props) {
		super(props);
		this.state = {}

		this.getData();
	}

	getData() {
		releaseState.IsSendRequest = false;
		getRequest("Semimanufactures", {}, function (data) { releaseState.setRelease(data); });
	}

	toggleTasks(event) {
		event.preventDefault();
		releaseState.ShowAll = !releaseState.ShowAll;
		releaseState.groupTasks();
	}

	render() {
		var view = this,
			style = { height: uistate.sectionHeight + uistate.TitleHeight, overflow: "auto" };

		return (
			<div className={"clearfix page " + (uistate.IsTablet ? "tablet" : "")} id="ReleasePage" style={style}>
				<div className="head clearfix">
					<h2>
						{dashboardsTitles[this.props.Type]}
						<a className="toggleTask" href="/" onClick={this.toggleTasks.bind(this)}><i></i>
							{releaseState.ShowAll ? "Скрыть выполненные" : "Показать все"}</a>
					</h2>
				</div>
				{!authUserState.Shift.Active &&
					<h4>Откройте смену для формирования списка заготовок</h4>
				}
				{authUserState.Shift.Active && releaseState.IsSendRequest && _.isEmpty(releaseState.Release) &&
					<h4>У вас нет списка необходимых заготовок на сегодня</h4>
				}
				{!_.isEmpty(releaseState.Release) && releaseState.IsSendRequest &&
					_.map(releaseState.Release, function (stock, i) {
						return (<StockGroup key={"s" + i} Stock={stock} Index={i}
							getData={view.getData.bind(view)} />);
					})
				}
			</div>
		)
	}
}
ReleasePage = observer(ReleasePage);
class StockGroup extends Component {
	render() {
		var view = this;

		return (
			<div className="clearfix">
				<h4>{this.props.Stock.StockName}</h4>
				{_.map(this.props.Stock.Products, function (item, i) {
					return (<ReleaseItem key={"i" + i} Item={item} StockIndex={view.props.Index}
						Index={i} getData={view.props.getData} />)
				})}
			</div>
		)
	}
}
StockGroup = observer(StockGroup);
class ReleaseItem extends Component {
	constructor(props) {
		super(props);
		this.state = { ErrorCode: "" }
	}

	handleSubmit(event) {
		event.preventDefault();

		var model = this,
			params = releaseState.getRelease(this.props.Item.TaskId);

		if (_.isEmpty(params)) {
			this.setState({ ErrorCode: "WRONG_PARAMS" });
			return false;
		}

		getRequest("ReleaseSemiByTask", params, function (data) {
			model.setState({ ErrorCode: data.ErrorCode });
			if (data.Success) model.props.getData();
		});
	}

	handleChange(value, id) { releaseState.collectProduct(id, value, this.props.Index, this.props.StockIndex); }

	render() {
		var start = this.props.Item.StartTime,
			end = this.props.Item.EndTime,
			done = this.props.Item.DoneTime !== "",
			expired = moment().isAfter(moment(end)),
			disabled = moment().isBefore(moment(start));

		return (
			<form className={"clearfix item " + (done ? "done" : expired ? "expired" : disabled
				? "disabled" : "")} onSubmit={this.handleSubmit.bind(this)}>
				<span className={"col name " + (this.props.Type === "Widget" ? "col50" : "col35")}>
					{this.props.Type === "Widget" && <i></i>}
					{this.props.Item.ProductName}
				</span>
				<span className="col col10">{this.props.Item.Count} {this.props.Item.UnitName}</span>
				<span className={"col time " + (this.props.Type === "Widget" ? "col40" : "col20")}>
					c {moment(start).format("HH:mm")} по {moment(end).format("HH:mm")}
				</span>
				{!done && this.props.Type !== "Widget" &&
					<TextInput
						Id="ReleaseCount"
						Value={this.props.Item.ReleaseCount}
						Required={true}
						Disabled={disabled}
						RowClass="col col10"
						Type="number"
						Step="0.001"
						onChange={this.handleChange.bind(this)}
					/>
				}
				{!done && this.props.Type !== "Widget" &&
					<button className={"button col col20 " + (expired ? "red" : "")}>Изготовить</button>
				}
				{done && this.props.Type !== "Widget" && <span className="col col30 action">Выполнено</span>}
				{this.state.ErrorCode !== "" &&
					<p className="error">{errors[this.state.ErrorCode] || errors.DEFAULT}</p>
				}
			</form>
		)
	}
}
ReleaseItem = observer(ReleaseItem);

export class ReleaseWidget extends Component {
	constructor(props) {
		super(props);
		this.state = {}

		this.getData = this.getData.bind(this);
		this.getData();
	}

	disposer = autorun(() => {
		if (authUserState.Shift.Active) this.getData();
	});

	getData() {
		releaseState.IsSendRequest = false;
		getRequest("Semimanufactures", {}, function (data) { releaseState.setWidgetRelease(data); });
	}

	render() {
		return (
			<div className="widget" id="ReleaseWidget">
				<h4>Заготовки</h4>
				<NavLink to="/Release" className="openPage" title="Перейти на страницу заготовок"><i></i></NavLink>

				{!authUserState.Shift.Active &&
					<p className="comment">Откройте смену для формирования списка заготовок</p>
				}
				{authUserState.Shift.Active && releaseState.IsSendRequest && _.isEmpty(releaseState.WidgetRelease) &&
					<p className="comment">У вас нет списка необходимых заготовок на сегодня</p>
				}
				{!_.isEmpty(releaseState.WidgetRelease) && releaseState.IsSendRequest &&
					_.map(releaseState.WidgetRelease, function (item, i) {
						return (<ReleaseItem key={"s" + i} Item={item} Index={i} Type="Widget" />);
					})
				}
			</div>
		)
	}
}
ReleaseWidget = observer(ReleaseWidget);