import { observable, decorate } from 'mobx';
import _ from 'lodash';
import { authUserState } from './common';
import { checkRights } from '../functions';

class TipsPrintState {
	// TipsURL = "https://tips.ectrum.ru/paymentLink";
	// TipsURL = "http://localhost:3001/tips";
	DocumentTypes = [
		{ Id: 6, Name: "QR код для печати", Description: "Ваш индивидуальный QR код в PDF.", Image: "TipsQR", Size : "100 на 100 мм" },
		{ Id: 1, Name: "Визитка", Description: "Вертикальная визитка.", Image: "TipsBCard", Size : "50 на 90 мм" },
		{ Id: 2, Name: "Тейбл тент А6", Description: "Плакат для вставки в тейбл тент.", Image: "TipsTableTent", Size : "105 на 148 мм" },
		{ Id: 3, Name: "Тейбл тент А5", Description: "Плакат для вставки в тейбл тент.", Image: "TipsTableTent", Size : "148 на 210 мм " },
		{ Id: 4, Name: "Наклейка/Стикер", Description: "Наклейки для столов или вертикальный поверхностей.", Image: "TipsStiker", Size : "134 на 168 мм" },
		{ Id: 5, Name: "Треугольник", Description: "Складной бумажный треугольник.", Image: "TipsTiangle", Size : "в сборке 90 на 112 мм" },
	]

	/** Установка дефолтного состояния страницы */
	setDefault() {
		this.CanManage = checkRights("ManageRecipient");
		this.CanView = this.CanManage || authUserState.TipsRecipient;

		this.Users = [];
		this.Brands = [];
		
		this.Filter = {
			BrandId: -1,
			UserId: this.CanManage ? -1 : authUserState.TipsRecipient ? authUserState.User.Id : -1
		}

		this.ButtonLoading = false;
		this.ErrorCode = "";
	}

	/** Форматирование списка получателей */
	get UserList () {
		if (_.isUndefined(this.Users) || _.isEmpty(this.Users)) return [];

		return _.map(this.Users, function(v) { return { Id : v.Id, Name : v.FirstName + " " + v.LastName }; });
	}

	/**
	 * Сбор данных с фильтра
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	collectFilter(value, id) { 
		this.Filter[id] = value; 
	}

	/** Флаг, что параметры выбраны и пользователь может скачать материалы */
	get CanDownload () { return this.CanView && this.Filter.BrandId !== -1 && this.Filter.UserId !== -1; }

	/**
	 * Сбор данных документа для скачивания
	 * @param {number} type тип документа
	 * @returns {object} данные для запроса
	 */
	getDownloadData(type) {
		return _.extend(_.clone(this.Filter), {
			DocumentType: type,
			DownloadType: 2
		});
	}

	/**
	 * Сбор данных документа для ссылки
	 * @returns {object} данные для запроса
	 */
	getLinkData() { return _.extend(_.clone(this.Filter), { DownloadType: 1 }); }
}
decorate(TipsPrintState, {
	Filter: observable,	// Установки для скачивания материалов
	Users: observable, 	// Список получателей чаевых
	Brands: observable, // Список брендов

	ButtonLoading: observable, 	// Состояние лоадера на кнопке
	ErrorCode: observable, 		// Код ошибки
});
export const tipsPrintState = new TipsPrintState();