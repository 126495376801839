/**
 * /pages/Order_delivery.js – Работа с доставкой заказа курьерами
 * /stores/orders.js – Стор для реализации бизнес логики
 * 
 * Список заказов на приготовление доступен если
 * - пользователь курьер
 * - у курьера есть право производить заказы «ManageOrder»
 * - у курьера открыта смена в филиале, на который оформлен заказ
 * - заказ названчен на курьера
 * 
 * При загрузке страницы выполняется запрос «DeliveryOrders» — получение заказов на доставку
 * - Заказы отдаются только за сегодняшнюю смену, в филиале смены
 * - Можно отдельно посмотреть список завершенных заказов и активных заказов (параметр IsActiveOrder)
 * 
 * Для справочников вызываются следующие методы один раз при загрузке страницы
 *  - «Marks» — список отметок заказа
 *  - «OrderStatuses» — список статусов заказа для смены статусов и отображения эталонного времени
 * 
 * По клику на карточку заказа она раскрывается на весь экран.
 * Выполнить заказ можно только в том статусе, на котором выдается сдача курьеру.
 *
 * Курьер может принять оплату, с учетом фискальных методов оплаты в выбранной точке, заказа 
 * 		с помощью метода «PaymentCourierOrder»
 * - В настройках способа оплаты должна быть отметка «Курьер может принимать оплату»
 * - У курьера должны быть права «Оплачивать фискальные/нефискальные заказы»
 * - Заказ должен быть еще не оплачен
 * -- С точки зрения интерфейса методы вызываются последовательно (без действий пользователя) — смена способа оплаты, оплата, выполнение 
 * Курьер может выполнить заказ с помощью метода «PaymentCourierOrder»
 * - заказ должен быть оплачен
 * 
 * Если заказ оплачен фискальным методом оплаты в интерфейсе курьера есть возможность показать QR код фискального чека
 */

import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import Parser from 'html-react-parser';

import pageLoader from '../images/loader_big.svg';
import '../styles/order.css';


import { getRequest, formatDate, getRubs, getHourTime, addressToPatternString } from '../functions';
import { dashboardsTitles, errors } from '../dictionary';

import { ButtonGroup } from '../forms/FormItems';
import { ChangeFromBlock } from './Orders';

import { uistate, confirmState, authUserState, errorState } from '../stores/common';
import { deliveryOrderState } from '../stores/orders';

export class OrdersDeliveryPage extends Component {
	constructor(props) {
		super(props);

		deliveryOrderState.setDefault();

		this.state = {}

		this.getData();

		var model = this;
		getRequest("Marks", { AllMerchants : true }, function (data) { model.setModel(data, "Marks"); });
		getRequest("OrderStatuses", {}, function (data) { 
			deliveryOrderState.OrderStatuses = data.Success ? data.OrderStatuses : [];
		});
	}

	/** Прослушивание PUSH сообщений */
	// async componentDidMount() {
	// 	var model = this;
	// 	if (!uistate.IsMobile && ('serviceWorker' in navigator) && ('PushManager' in window)) {
	// 		navigator.serviceWorker.addEventListener("message", (message) => {
	// 			let msg = message.data['firebase-messaging-msg-data'] ? message.data['firebase-messaging-msg-data'] : message.data;

	// 			if (msg.Group === "Orders") model.getData();
	// 		});
	// 	}
	// }

	/**
	 * Установка данных модели
	 * @param {object} data ответ от сервера
	 * @param {string} param название параметра
	 */
	setModel(data, param) { this.setState({ [param]: data.Success ? data[param] : [] }); }

	/** API запрос «DeliveryOrders» на получение списка заказов для повара */
	getData() {
		var model = this;
		
		getRequest("DeliveryOrders", deliveryOrderState.getFilter(), function (data) { 
			deliveryOrderState.setOrders(data); 

			if (uistate.IsMobile) setTimeout(function () { model.getData(); }, 45000);
		});
	}

	/**
	 * Сбор данных с формы фильтра заказов
	 * @param {string} value значение параметра
	 */
	handleChange(value) { 
		deliveryOrderState.Filter.IsActiveOrder = value; 
		this.getData();
	}

	render() {
		var view = this,
			isActive = authUserState.Shift.Active,
			isSend = deliveryOrderState.IsSendRequest,
			activeList = [{ Id : "true", Name : "Активные" }, { Id : "false", Name : "Завершенные" }];

		return (
			<div className={"page " + (uistate.IsMobile ? "mobile" : "")} id="DeliveryOrdersPage">
				{_.isUndefined(deliveryOrderState.Order) &&
					<section className="">
						<div className="head clearfix">
							<h2>{dashboardsTitles[this.props.Type] || this.props.Type}</h2>
							{isActive && 
								<form>
									<ButtonGroup
										Id="IsActiveOrder"
										List={activeList}
										Value={deliveryOrderState.Filter.IsActiveOrder}
										RowClass="nomargin"
										onChange={this.handleChange.bind(this)}
									/>
								</form>
							}
						</div>
						{!isActive && isSend && <p className="noSearch">{errors.SHIFT_CLOSE}</p>} 
						{isActive && !_.isEmpty(deliveryOrderState.Orders) &&
							<div className="clearfix deliveryList">
								{_.map(deliveryOrderState.Orders, function(item , i) {
									return(<DeliveryBlockItem 
										Item={item} key={i} 
										Marks={view.state.Marks}
									/>)
								})}
							</div>
						}
						{isActive && _.isEmpty(deliveryOrderState.Orders) && isSend &&
							<p className="noSearch">{errors.EMPTY_RESPONSE}</p>
						} 
					</section>
				}
				{!_.isUndefined(deliveryOrderState.Order) &&
					<DeliveryItem Marks={this.state.Marks} getData={this.getData.bind(this)} />
				}
			</div>
		)
	}
}
OrdersDeliveryPage = observer(OrdersDeliveryPage);

class DeliveryBlockItem extends Component {
	/** Открытие окна с QR кодом */
	handleQRClick () { deliveryOrderState.setQRCode(this.props.Item); }

	render() {
		var item = this.props.Item,
			inOrder = this.props.Type === "order",
			noPaid = (!item.Paid && !inOrder) || (!item.Paid && inOrder && !item.CanPaid),
			address = this.props.Item.CustomerAddress || "",
			addressLink = address !== "" ? address.Long + "," + address.Lat : "",
			hasCommentBlock = item.Description !== "" || (inOrder && !_.isEmpty(this.props.Item.Marks)),
			must = item.MustCompletedTime,
			style = { background : this.props.Item.StatusColor },
			pattern = "<b>{%%ss%, }%%SS%, %%HH% {%%HB%}  {, %%FF%}</b>{, п %%ee%}{, э %%ff%}",
			addressString = item.CustomerAddress !== "" ? addressToPatternString(address,pattern) : "",
			showQR = inOrder && item.Paid && _.indexOf([2,4,5], item.IsFiscal) !== -1;

		console.log(item.Id)

		return(
			<div className="clearfix item" onClick={e => deliveryOrderState.setOrder(item.Id)}>
				<div className="head clearfix" style={style}>
					<div className="clearfix">
						<span className="col col50">
							<b>{formatDate(item.CreationTime, "HH:mm")}</b> №{item.OrderNumber}
						</span>
						<span className="col col50 amount">
							<b>{getRubs(item.TotalAmount, true, true)}</b> руб.
						</span>
					</div>
					<div className="clearfix">
						<span className="tag">{item.StatusName}</span>
						{must !== "" &&
							<span className="tag red">Заказ ко времени: {formatDate(must, "time")}</span>
						}
						{!item.IsFinalStatus &&
							<div className="col times">
								<h5>{item.Left > 0 ? "Осталось" : "Опаздывает на"}</h5>
								<span className="tag"><i className={item.Light}></i>{getHourTime(item.Left)}</span>
							</div>
						}
					</div>
				</div>
				<div className="body clearfix">
					<div className="clearfix block">
						<p className="filial">Филиал: {item.FilialName}<br /></p>
						{inOrder && <p className="customer">Клиент: {item.CustomerName}</p>}
						<p className="address">
							{Parser(addressString)}
							<a href={"https://yandex.ru/maps/?ll=" + addressLink + "&pt=" +
								addressLink + "&z=12"} className="icon geo button" target="_blank"
								rel="noopener noreferrer"><i></i></a>
						</p>
						{item.DeliveryArea !== "" && <p>Зона доставки: {item.DeliveryArea}</p>}
						{address.Intercom !== "" && <p className="intercom">Домофон: {address.Intercom}</p>}
					</div>
					{hasCommentBlock &&
						<div className="clearfix block">
							{item.Description !== "" && <h5>Комментарий к заказу</h5>}
							{item.Description !== "" && <p>{item.Description}</p>}
							{inOrder && !_.isEmpty(this.props.Item.Marks) &&
								<p className="marks">
									{_.map(this.props.Item.Marks, function (v) { 
										return(<span className="tag" key={v}>{v}</span>)
									})}
								</p>
							}
						</div>
					}
					<div className="clearfix block paymentInfo">
						<span className="tag">{item.PaymentMethodName}</span>
						{item.Paid && <span className="tag green">Заказ оплачен</span>}
						{showQR && <button className="icon qr button" onClick={this.handleQRClick.bind(this)}
								title="Показать QR код для чека"><i></i></button>}
						{noPaid && <span className="tag red">Заказ неоплачен</span>}
						{item.ChangeFrom !== 0 &&
							<p className="change">
								Купюра: {getRubs(item.ChangeFrom, true, true)},&nbsp;
								Сдача: <b>{getRubs(item.ChangeFrom - item.TotalAmount, true, true)}</b> руб.
							</p>
						}
					</div>
				</div>
			</div>
		)
	}
}

class DeliveryItem extends Component {
	constructor(props) {
		super(props);

		this.state = { ShowCalc : false }
	}

	/** Закрыть окно заказа */
	handleClose(event) {
		event.preventDefault();
		deliveryOrderState.setOrder(undefined);
		if (this.props.getData) this.props.getData();
	}

	/** Обработка клика по кнопке «выполнить» заказ */
	handleResolve() { this.paymentCourierOrder(deliveryOrderState.Order.PaymentMethodId); }

	/** Обработка клика на выбор способа оплаты */
	handlePay(event) {
		event.preventDefault();
		var model = this,
			id = parseInt(event.currentTarget.getAttribute("rel"), 10),
			method = _.find(deliveryOrderState.availableMethods, { Id : id }),
			text = <span>Вы действительно хотите оплатить заказ 
				&nbsp;<b key="b1">«№{deliveryOrderState.Order.OrderNumber}»</b> 
				&nbsp;с помощью <b key="b2">«{method ? method.Name : ""}»</b>?</span>

		confirmState.openConfirm(text, function() {
			model.paymentCourierOrder(id);
		});
	}

	/**
	 * API запрос «PaymentCourierOrder» на комплексную оплату заказа
	 * @param {number} id способ оплаты заказа
	 */
	paymentCourierOrder (id) {
		var model = this,
			params = {
				Id : deliveryOrderState.Order.Id,
				PaymentMethodId : id,
				POSId : deliveryOrderState.Order.POSId
			};

		deliveryOrderState.Loading = true;

		getRequest("PaymentCourierOrder", params, function (data) {
			if (data.Success) deliveryOrderState.setOrder(undefined);
			else errorState.setError(data.ErrorCode, null, data.ErrorMessage);
			
			model.props.getData();
			deliveryOrderState.Loading = false;
		});
	}

	/** Показать/скрыть окно с калькулятором сдачи */
	toggleCalc (event) {
		event.preventDefault();
		this.setState({ ShowCalc : !this.state.ShowCalc });
	}

	render(){
		var order = deliveryOrderState.Order;

		return(
			<div className="clearfix" id="DeliveryItem">
				<div className="head clearfix">
					<a className="button icon phone green col col45" href={"tel:+" + order.CustomerLogin}>
						<i></i>Позвонить</a>
					<a href="/" className="close col col50 button" onClick={this.handleClose.bind(this)}>
						Закрыть</a>
				</div>
				<div className="order clearfix">
					<DeliveryBlockItem Item={order} Type="order" Marks={this.props.Marks} />
					<div className="clearfix products">
						{!_.isNull(order.Products) && _.map(order.Products, function(product, i) {
							return(<div className="clearfix product" key={i}>
								<p className="clearfix">
									<span className="col col80">{product.ProductName}</span>
									<span className="col col20">{product.Count}</span>
								</p>
								{!_.isNull(product.Modifications) && _.map(product.Modifications, function (mod, k) {
									return(
										<p className="clearfix" key={k}>
											<span className="col col80">+&nbsp;{mod.ProductName}</span>
											<span className="col col20">{mod.Count}</span>
										</p>
									)
								})}
							</div>)
						})}
					</div>
				</div>
				{!order.IsFinalStatus && deliveryOrderState.canDelivery &&
					<DeliveryItemPayment
						handleResolve={this.handleResolve.bind(this)}
						handlePay={this.handlePay.bind(this)}
						toggleCalc={this.toggleCalc.bind(this)}
					/>
				}
				{deliveryOrderState.Loading &&
					<div className="loading"><img src={pageLoader} alt="logo" /></div>
				}
				{this.state.ShowCalc &&
					<DelivaryChangeCalc toggleCalc={this.toggleCalc.bind(this)} />
				}
				{!_.isUndefined(deliveryOrderState.QRCode) &&
					<QRCodeOrderBlock />
				}
			</div>
		)
	}
}
DeliveryItem = observer(DeliveryItem);

class DeliveryItemPayment extends Component {
	render() {
		var isPaid = deliveryOrderState.Order.Paid,
			canPaid = deliveryOrderState.Order.CanPaid,
			methods = deliveryOrderState.availableMethods,
			methodId = deliveryOrderState.Order.PaymentMethodId,
			currentMethod = isPaid || _.isEmpty(methods) ? undefined
				: _.find(methods, { Id : methodId }),
			currentDefault = currentMethod ? currentMethod.IsDefault : false,
			currentCash = currentMethod ? currentMethod.ShowCassa : false,
			currentClass = "button col main icon green pay " + (currentCash ? "icon_1 col75" 
				: currentDefault ? "icon_2 col100" : "col100"),
			addMethod = isPaid || _.isEmpty(methods) ? undefined : 
				_.find(methods, function(v) { return v.Id !== methodId && v.IsDefault; }),
			addCash = addMethod ? addMethod.ShowCassa : false,
			addClass = "button col icon blue pay col100 " + (addCash ? "icon_1 " : "icon_2");

		return(
			<div className="buttons">
				{isPaid && <button className="button blue col col100 main" 
					onClick={this.props.handleResolve}>Доставлено</button>}
				{currentMethod && canPaid &&
					<div className="clearfix">
						<a href="/" className={currentClass} onClick={this.props.handlePay}
							rel={currentMethod.Id}>{currentDefault && <i></i>}{currentMethod.Name}
						</a>
						{currentCash &&
							<a href="/" className="icon calc button gray col col20 main"
								onClick={this.props.toggleCalc}><i></i></a>
						}
					</div>
				}
				{addMethod && canPaid && currentDefault && 
					<div className="clearfix adds">
						<div className="col col35">
							<a href="/" className={addClass} onClick={this.props.handlePay}
								rel={addMethod.Id}><i></i>{addMethod.Name}</a>
						</div>
						<p className="col col60">Или клиент хочет оплатить «<b>{addMethod.Name}</b>»?</p>
					</div>
				}
				{!isPaid && canPaid && 
					<p className="payment">
						Если клиент хочет оплатить заказ другим способом оплаты, пожалуйста, 
						свяжитесь с администратором
					</p>
				}
				{!isPaid && !canPaid && 
					<p className="payment">Заказ не оплачен клиентом, пожалуйста, свяжитесь с администратором</p>
				}
			</div>
		)
	}
}
DeliveryItemPayment = observer(DeliveryItemPayment);

class DelivaryChangeCalc extends Component {
	/** Сбор данных с пресетов */
	handleClickPreset(event) {
		event.preventDefault();
		var amount = parseInt(event.currentTarget.getAttribute("rel"), 10);
		
		if (!_.isNaN(amount)) deliveryOrderState.collectChange(amount);
	}

	/** Сбор данных с калькулятора */
	handleClickNumber(event) {
		event.preventDefault();

		if (event.currentTarget.getAttribute("rel") === "clear") deliveryOrderState.collectChange("");
		else {
			var amount = parseInt(event.currentTarget.getAttribute("rel"), 10),
				changeFrom = deliveryOrderState.Order.ChangeFrom === "" ? 0 : deliveryOrderState.Order.ChangeFrom;
	
			if (!_.isNaN(amount)) deliveryOrderState.collectChange(changeFrom*10 + amount);
		}
	}

	render() {
		return(
			<div className="back">
				<div className="confirmBlock clearfix calc window medium">
					<ChangeFromBlock
						TotalAmount={deliveryOrderState.Order.TotalAmount}
						ChangeFrom={deliveryOrderState.Order.ChangeFrom}
						Change={deliveryOrderState.Order.Change}

						handleClickNumber={this.handleClickNumber.bind(this)}
						handleClickPreset={this.handleClickPreset.bind(this)}
					/>
					<a href="/" className="button close" onClick={this.props.toggleCalc}><i></i></a>
				</div>
			</div>
		)
	}
}
DelivaryChangeCalc = observer(DelivaryChangeCalc);

class QRCodeOrderBlock extends Component {
	/** Закрыть окно с QR кодом */
	handleClose(event) {
		event.preventDefault();
		deliveryOrderState.setQRCode(undefined);
	}

	render() {
		var order = deliveryOrderState.QRCode;
		
		return(
			<div className="back">
				<div className="clearfix qrcode window medium">
					<a href="/" className="close" onClick={this.handleClose.bind(this)}><i></i></a>
					<div className="clearfix">
						<h4>QR код для получения чека по заказу №{order.OrderNumber}</h4>
						<img src={"/api/GetQRCodeOrder?OrderId=" + order.OrderId} 
							alt={"QR по заказу №" + order.OrderNumber}/>
					</div>
				</div>
			</div>
		)
	}
}
QRCodeOrderBlock = observer(QRCodeOrderBlock);