export const dadataKey = {
	test : "ad2a35bcade46241f85fdb20ebeccc63a5f2e43e",
	prod : "0c9fbab5e812a2e87f4c56372075a674ae7c8494"
}

export const dashboardsTitles = {
	Menu: "Доступ к разделам Верхнего меню",
	Sidebar: "Вывод разделов в левое меню",
	Widget: "Вывод виджетов на рабочий стол",

	Orders: "Заказы",
	CallCentre: "Колл-центр",
	Production: "Производство",
	Staff: "Персонал",
	Directory: "Справочник",
	Store: "Склад",
	Money: "Деньги",
	Management: "Управление",
	Statistics: "Статистика",
	Settings: "Настройки",

	Scripts: "Скрипты",
	Algorithms: "Алгоритмы",
	Documents: "Документы",
	Report: "Отчеты",
	Reports: "Отчеты",
	CheckList: "Чек-листы",
	Stock: "Акции",

	MenuList: "Меню",
	Instructions: "Инструкции",
	MedicalBooks: "Медицинские книжки",
	StaffFood: "Питание персонала",
	Revisions: "Ревизии",
	StopList: "Стоп-лист",
	CCTV: "Видеонаблюдение",
	Release : "Заготовки",

	Timetable: "Расписание",
	TimeSheet: "Табель",
	Employees: "Сотрудники",
	Couriers: "Курьеры",
	

	Goods: "Товары",
	Raw: "Сырье",
	Customers: "Клиенты",
	Inventory: "Инвентарь",
	Inventories: "Инвентарь",
	SalesMarkups: "Скидки и наценки",
	Tags: "Подсказки к продуктам",
	OrderStatus: "Статусы заказа",

	OrderMark: "Отметки заказа",
	OrderMarks: "Отметки заказа",
	Marks: "Отметки заказа",
	Providers: "Поставщики",
	CancelReasons: "Причины списания",
	Sertificates: "Сертификаты",
	GuildType: "Типы цехов",
	WorkShops: "Типы цехов",
	CassaTransactionTag: "Типы кассовых операций",

	Order: "Заказ",
	Purchase: "Закупка",
	Invoices: "Накладные",
	Movement: "Перемещения",
	WriteOffs: "Списания",
	Leftovers: "Остатки",
	Audits: "Ревизии",
	Inventorization: "Инвентаризация",

	WriteOffRequest : "Списание",
	WriteOffList : "Список списаний",

	Cassa: "Касса",
	Salary: "Зарплаты",
	Costs: "Прочие расходы",
	Payouts: "Выплаты",
	PayoutUser: "Сотрудники",
	PayoutCourier: "Курьеры",

	Manager: "Менеджер",
	Administrator: "Администратор",
	Bookkeeping: "Бухгалтерия",
	Marketing: "Маркетинг",
	Barista: "Кассир-бариста",
	News: "Новости",
	Tasks: "Задачи",
	Calendar: "Календарь",

	Summary: "Сводка",
	EmployeesStat: "Сотрудники",
	SalesReports: "Отчеты по продажам",
	StaffReports: "Отчеты по персоналу",
	CheckListReports: "Отчеты по чек-листам",
	StoreReports: "Отчеты по складу",
	FinanceReports: "Отчеты по финансам",

	Common: "Общее",
	Users: "Пользователи",
	Roles: "Должности",
	Filials: "Филиалы",
	Stocks: "Склады",
	CheckLists: "Чек-листы",
	Check: "Печать чека",
	SalesChanels: "Каналы продаж",
	Map: "Карты и зоны",
	FiscalOffice: "Фискальные кассы",
	SOS: "Кнопка SOS",
	Push: "Уведомления производства",
	StockOrder: "Заявка для складов",
	TipsPrint: "Печатные материалы (чаевые)",

	Kitchen: "Кухня",
	Bar: "Бар",
	FoodSafety: "Пищевая безопасность",
	SafetyEngineering: "Техника безопасности",
	Rules: "Правила",
	Inspect: "Провести ревизию",
	InspectList: "Список ревизий",

	Products: "Продукция",
	SemiManufactures: "Полуфабрикаты",

	ShiftTransfer: "Отчеты о смене",
	CourierTaxes: "Тарифы курьеров",
	Payments: "Платежи",
	Personnel: "Кадры",
	Monitors: "Мониторы",
	AddTask: "Добавить задачу",
	Execution: "Выполнение",

	Revenue: "Выручка",
	SalesMarkUps: "Скидки и наценки",
	ExecutionTime: "Время исполнения",
	TimeSales: "Продажи по часам",
	CostPrice: "Себестоимость",
	ProductsStat: "Продукция",
	RevisionsStat: "Ревизии",
	RawMovement: "Движение сырья",
	PurchaseHistory: "История закупок",
	ShiftReport: "Открытие смен",
	ShiftReportCourier: "Открытие смен курьерам",

	ShiftTransferReport: "Отчеты о передаче смен",
	ShiftSecretReport: "Отчеты о сменах",
	KitchenMenu: "Кухня",
	BarMenu: "Бар",

	PaymentMethods: "Методы оплаты",
	BonusRules: "Правила бонусной системы",
	BonusPromocodes : "Промокоды бонусной системы",
	WriteoffStat : "Списания",
	PurchaseSummary : "Сводка по закупкам",

	TakingOrders : "Прием заказов",
	ProcessOrders : "Производство",
	DeliveryOrders : "Доставка",

	Site : "Сайт",
	Catalog : "Каталог"

}

export const rightsTitles = [
	{ Id : "User", Name : "Пользователи и должности", Description : "" },
	{ Id : "ManageUsers", Name : "Управление пользователями", Description : "Данное право позволяет просматривать и редактировать пользователя (не курьеров). Право не влияет на просмотр списка пользователей" },
	{ Id : "ManageRoles", Name : "Управление должностями", Description : "Данное право позволяет просматривать и редактировать должность, создавать отделы. Право не влияет на просмотр списка должностей" },
	{ Id : "CanChangeShift", Name : "Менять должность/филиал при открытой смене", Description : "Данное право позволяет пользователю изменять филиал и рабочий стол в левом меню когда открыта смена. При отсутствии данного права пользователь не может изменить свой рабочий стол и активный филиал." },
	{ Id : "ManageCouriers", Name : "Управление курьерами", Description : "Данное право позволяет просматривать и редактировать курьеров. Право не влияет на просмотр списка курьеров" },
	{ Id : "CanCourierShift", Name : "Управление сменами курьеров", Description : "Данное право позволяет открывать и закрывать смены курьеру." },
	{ Id : "ManageRecipient", Name : "Управление получателями чаевых", Description : "Данное право позволяет регистрировать получателей чаевых в системе и работать с печатными материалами для чаевых" },
	{ Id : "ManageCourierINN", Name : "Управление ИНН курьера", Description : "Данное право позволяет изменять ИНН курьера в предприятии. Данное право не влияет на работу с ИНН обычного пользователя" },


	{ Id : "Filial", Name : "Филиалы", Description : "" },
	{ Id : "ManageFilials", Name : "Управление филиалами", Description : "Данное право позволяет просматривать и редактировать настройки филиала. Право не влияет на просмотр списка филиалов" },
	{ Id : "ManageStocks", Name : "Управление складами", Description : "Данное право позволяет просматривать и редактировать настройки склада. Право не влияет на просмотр списка складов" },

	{ Id : "Checklist", Name : "Чек-листы", Description : "" },
	{ Id : "ManageCheckList", Name : "Управление чек-листами", Description : "Данное право позволяет просматривать и редактировать чек-листы. Право не влияет на просмотр списка чек-листов" },
	{ Id : "ReportCheckList", Name : "Отчеты по чек листам", Description : "Данное право позволяет просматривать отчеты о выполнении задач пользователями из чек листов. По данному праву отображаются только те задачи, которые в настройках чек листа не были привязаны к конкретному пользователю" },
	{ Id : "ReportIndividualCheckList", Name : "Отчеты по индивидуальным чек листам", Description : "Данное право позволяет просмавать отчеты о выполнении задач, привязанные к конкретному пользователю — индивидуальные чек листы" },

	{ Id : "Content", Name : "Контент-страницы", Description : "" },
	{ Id : "ManageContent", Name : "Управление контентом", Description : "Данное право позволяет создавать/редактировать/удалять статьи, содержащиеся на страницах FAQ, скрипты, алгоритмы, документы, техника безопасности" },
	{ Id : "ManageSaleOuts", Name : "Управление акциями", Description : "Данное право позволяет создавать/редактировать/удалять статьи, содержащиеся на страницах «акции»" },
	{ Id : "ManageNews", Name : "Управление новостями", Description : "Данное право позволяет создавать/редактировать/удалять новости" },

	{ Id : "Reports", Name : "Отчеты", Description : "" },
	{ Id : "ShiftReport", Name : "Отчеты по сменам", Description : "Данное право дает возможность просматривать отчеты по приходу/уходу персонала" },
	{ Id : "ShiftTransferReportProduction", Name : "Отчеты о передаче смены «Производство»", Description : "Данное право дает пользователю возможность просматривать блок отчетов «отчет о смене». Данное право не влияет на просмотр отчета о предыдущей смене в своей должности" },
	{ Id : "ShiftTransferReportCallCentre", Name : "Отчеты о передаче смены «Колл-центр»", Description : "" },
	{ Id : "ShiftTransferReportProductionSecret", Name : "Отчеты о прошедшей смене «Производство»", Description : "Данное право дает возможность просмотра блока отчета «Отчет для руководства». Также это право дает возможность отмечать как прочитанные отчеты, требующие внимания руководства" },
	{ Id : "ShiftTransferReportCallCentreSecret", Name : "Отчеты о прошедшей смене «Колл-центр»", Description : "" },

	{ Id : "Directories", Name : "Справочники", Description : "" },
	{ Id : "ManageTags", Name : "Управление подсказками продукта", Description : "Данное право дает возможность создавать/редактировать/удалять подсказки к продуктам. Право не влияет на возможность просмотра списка подсказок" },
	{ Id : "ManageReasons", Name : "Управление причинами списания", Description : "Данное право дает возможность создавать/редактировать/удалять причины списаний. Право не влияет на возможность просмотра списка причин списания" },
	{ Id : "ManageProviders", Name : "Управление поставщиками", Description : "Данное право дает возможность создавать и редактировать всю информацию о поставщике. Право не влияет на возможность просмотра списка и карточки поставщика" },
	{ Id : "ManageProvidersFinance", Name : "Просмотр фин. информации поставщиков", Description : "Пользователь с данным правом, при отсутствии права «Управлять поставщиками» может просматривать информацию о ценах поставщика и его поставках" },
	{ Id : "ManageProducts", Name : "Управление продуктами", Description : "Данное право дает возможность создавать и редактировать всю информацию о сырье/полуфарикатах. Право не влияет на возможность просмотра списка и карточки продукта" },
	{ Id : "ManageProductsFinance", Name : "Просмотр фин. информации продуктов", Description : "Пользователь с данным правом, при отстуствии права «Управлять продуктами» может дополнительно просматривать информацию о ценах и поставках продукта" },
	{ Id : "ManageOrderStatuses", Name : "Управление статусами заказа", Description : "Данное право дает возможность редактировать время нахождения в статусе заказа. Создавать, удалять и редактировать основные настройки статуса заказа может только директор преприятия. Право не влияет на возможность просмотра списка статусов" },
	{ Id : "ManageMarks", Name : "Управление отметками к заказу", Description : "Данное право дает возможность создавать/редактировать/удалять отметки к заказу. Право не влияет на возможность просмотра отметок" },
	{ Id : "ManageWorkShops", Name : "Управление цехами", Description : "Данное право дает возможность создавать/редактировать/удалять цеха приготовления. Право не влияет на возможность просмотра списка цехов" },
	{ Id : "ManageCassaTransactionTags", Name : "Управление типами кассовых операций", Description : "Данное право дает возможность создавать/редактировать/удалять типы кассовых операций. Право не влияет на возможность просмотра списка типов" },

	{ Id : "StockProcess", Name : "Складские процессы", Description : "" },
	{ Id : "ManagePurchase", Name : "Управление закупками", Description : "Пользователь с данным правом может обрабатывать заявки на закупку и работать с листами покупок для поставщиков. Право не влияет на возможность создания заявки на закупку" },
	{ Id : "ManageInvoices", Name : "Управление накладными", Description : "Данное право дает возможность создавать и редактировать неподтвержденные накладные" },
	{ Id : "AcceptInvoices", Name : "Подтверждение накладных", Description : "Данное право дает возможность отметить накладную как оплаченную" },
	{ Id : "PaymentInvoices", Name : "Оплата накладных", Description : "Данное право дает возможность подтвердить накладную. Подтвеждрение — процесс, при котором продукты из накладной региструются в системе и происходит пересчет складских остатков. После подтверждения накладная не доступна к изменению" },
	{ Id : "ManageTransfers", Name : "Управление перемещениями", Description : "Данное право дает возможность создавать, редактировать и принимать неподтвержденные перемещения" },
	{ Id : "AcceptTransfers", Name : "Подтверждение перемещений", Description : "Данное право дает возможность подтвердить перемещение. Подтвеждрение — процесс, при котором продукты из перемещения региструются в системе и происходит пересчет складских остатков. После подтверждения перемещение не доступно к изменению" },
	{ Id : "ManageRevision", Name : "Управление ревизиями", Description : "Данное право дает возможность проводить и редактировать неподтвержденную ревизию" },
	{ Id : "AcceptRevision", Name : "Подтверждение ревизий", Description : "Данное право дает возможность подтвердить ревизию. Подтвеждрение — процесс, при котором продукты из ревизии региструются в системе и происходит пересчет складских остатков. После подтверждения ревизия не доступна к изменению" },
	{ Id : "ManageWriteOff", Name : "Управление списаниями", Description : "Данное право дает возможность редактировать неподтвержденные списания и просматривать список всех списаний. Право не вияет на возможность создания — для создания списания нужно чтобы у пользователя была открыта смена в соответствующем филиале" },
	{ Id : "AcceptWriteOff", Name : "Подтверждение списаний", Description : "Данное право дает возможность подтвердить списание. Подтвеждрение — процесс, при котором продукты из списания региструются в системе и происходит пересчет складских остатков. После подтверждения списание не доступна к изменению" },
	{ Id : "ManageRelease", Name : "Выпуск заготовок", Description : "Данное право дает возможность выпускать заготовки полуфаьрикатов. При наличии данного права при открытии смены пользователю будет отображаться список необходимых для приготовления полуфабрикатов, указнных в разделе «настройки склада»" },
	{ Id : "ManageInventoryProcess", Name : "Управление инвентаризациями", Description : "Данное право дает возможность пользователю проводить инвентаризацию" },
	{ Id : "ReportStockProcess", Name : "Отчеты по складским процессам", Description : "Данное право дает пользователю доступ ко всем отчетам, связанным со складскими процессами" },
	{ Id : "ManageStopList", Name : "Управление стоп-листами", Description : "Данное право дает возможность пользователю с открытой сменой добавлять товары в стоп лист в соответствующем смене филиале" },

	{ Id : "Orders", Name : "Заказы", Description : "" },
	{ Id : "CanCreateOrder", Name : "Создание нового заказа", Description : "Пользователь с данным правом может создавать новый заказ в системе. Данное право не влияет на просмотр списка заказов, надо добавлять доступ отдельно." },
	{ Id : "ManageOrders", Name : "Управление заказами", Description : "Данное право дает возможность пользователю просматривать список заказов. Пользователь с данным правом может видеть заказы только при открытой смене. Пользователь с данным правом может видеть только заказы за сегодня и завтра" },
	{ Id : "CanReleaseOrder", Name : "Возможность производить заказ", Description : "Данное право дает возможность производить заказ, а также доступ к разделу производства. Пользователь с данным правом может видеть и производить заказы при открыйтой смене в соответствующем филиале" },
	{ Id : "CanViewHistoryOrder", Name : "Просмотр истории заказов ", Description : "Пользователь с данным правом получает возможность просматривать заказы за прошедший период" },
	{ Id : "CanViewWriteoffOrder", Name : "Просмотр списанных заказов", Description : "Пользователь с данным правом может видить список и просматривать заказы, находящиеся в статусе «Списан»" },
	{ Id : "CanWriteOffOrder", Name : "Возможность списывать заказ", Description : "Пользователь с данным правом может выставить заказу статус «Списан»" },
	{ Id : "CanViewReceivingType", Name : "Просмотр «каналов приема» заказа", Description : "Данное право позволяет в списке заказов видеть канал приема заказа" },
	{ Id : "CanChangePOS", Name : "Возможность изменять точку продаж", Description : "Пользователь с данным правом может в ручную изменить точку продаж, к которой относится заказ" },
	{ Id : "CanChangePriority", Name : "Возможность изменять приоритет заказов", Description : "Пользователь с данным правом может вручную изменят порядок приоритета приготовления заказов в списке заказов." },
	{ Id : "CanSetOrderPayed", Name : "Оплата нефискальных заказов", Description : "Пользователь с данным правом может отмечать как оплаченный/неоплаченный заказ с нефискальным методом оплаты — не нал и не б/нал" },
	{ Id : "CanFiscalOrderPayed", Name  : "Оплата фискальных заказов", Description : "Пользователь с данным правом может отмечать как оплаченный заказ с фискальным методом оплаты — нал или б/нал" },
	{ Id : "CanRefundFiscalOrder", Name  : "Возврат фискальных заказов", Description : "По данному праву пользователь может совершать возврат фискализированного заказа с выбитием фискального чека" },
	{ Id : "CanViewHomeOrders", Name : "Просмотр «внутренних» заказов", Description : "Данное право дает возможность видитеть заказы, отмеченные как «внутренние», в интерфейсе списка заказов" },
	{ Id : "CanChangeFinishOrders", Name  : "Возможность изменять заказ после завершения", Description : "Пользователь с данным правом может изменять комментарии к заказу после того, как заказу выставлен статус «Выполнен»" },
	{ Id : "CanViewOrdersWithoutShift", Name  : "Возможность просмотра заказов без открытия смены", Description : "Пользователь с данным правом может просматривать список заказов без открытия смены" },
	{ Id : "ReportOrderDelivery", Name  : "Просмотр отчетов по доставке", Description : "Данное право дает пользователю возможность просматривать отчет по доставке заказов курьерами" },
	{ Id : "ReportOrderTaking", Name  : "Просмотр отчетов по приему заказов", Description : "Данное право дает пользователю возможность просматривать отчет по приему заказов сотрудниками" },
	{ Id : "ReportOrderProcess", Name  : "Просмотр отчетов по выручке заказов", Description : "Данное право дает пользователю возможность просматривать отчет о выручке по заказам" },

	{ Id : "BonusSystem", Name  : "Бонусная система", Description : "" },
	{ Id : "ManageBonusRules", Name  : "Управление правилами", Description : "Данное право позволяет создавать, редактировать и просмотривать правила бонусной системы. Право не влияет на просмотр списка правил. Право не влияет на применение правил бонусной системы." },
	{ Id : "ManageCustomerBonusRule", Name  : "Назначение индивидуальных правил на клиента", Description : "Данное право повзволяет пользователю при создании/редактировании правила указывать конкретного клиента" },
	{ Id : "ManageBonusPromocodes", Name  : "Управление промокодами", Description : "Данное право позволяет создавать, редактировать и просмотривать промокоды. Право не влияет на просмотр списка промокодов. Право не влияет на применение промокодов." },
	{ Id : "ManageCustomerBonus", Name  : "Начисления/списание бонусов клиента вручную", Description : "Данное право позволяет пользователю вручную начислять/списывать бонусы в карточке клиента" },

	{ Id : "Cassa", Name : "Кассы", Description : "" },
	{ Id : "ManageCassa", Name : "Управление кассами", Description : "Данное право дает возможность открывать/закрывать кассы, доступные пользователю. По данному праву пользователь может видеть операции в кассе только за текущую открытую смену. С данным правом пользователь может создавать кассовые пополнения или изъятия. Доступ к кассам разрешается только пользователю с открытой сменой в соответствующем филиале " },
	{ Id : "ManageFiscalCassa", Name : "Управление фискальными кассами", Description : "Данное право дает возможность работать с фискальными кассами — открывать/закрывать фискальные кассы, снимать X-отчеты и Z-отчеты" },
	{ Id : "RemoveCassaTransactions", Name : "Возможность удалять кассовые операции", Description : "Данное право дает пользователю удалять кассовые операции (пополнения и изъятия) в течение своей смены" },
	{ Id : "CanViewLastMonthCassa", Name : "Просмотр кассы за текущий и предыдущий месяцы", Description : "Данное право позволяет пользователю смотреть историю кассовых операций не только за текущую смену, но за весь текущий и прошлый календарный месяцы." },
	{ Id : "CanViewAllPeriodCassa", Name : "Просмотр кассы за весь период", Description : "Данное право дает пользователю возможность смотреть отчеты по кассовым операциям за любой период" },
	{ Id : "CanViewAllCassa", Name : "Возможность просмотра касс без открытия смены", Description : "Данное право снимает с пользователя ограничение о необходимости открытой смены в соответствующем филиале" },

	{ Id : "Bill", Name : "Чеки", Description : "" },
	{ Id : "ManageBillTemplate", Name : "Управление шаблонами пречеков", Description : "Данное право позволяет создавать и редактировать настройки пречеков заказа. Данное право не влияет на возможность распечатывать пречеки в интерфейсе заказа." },

	{ Id : "Customers", Name: "Клиенты", Description : "" },
	{ Id : "ManageCustomers", Name : "Управление клиентами", Description : "Данное право позволяет редактировать настройки клиента. Право не влияет на просмотр общей информации клиента и списка клиентов" },
	{ Id : "ManageCustomersBlackList", Name : "Управление черным списком клиентов", Description : "Данное право позволяет добавлять/удалять клиента в черный список. Данное право не влияет на просмотр инфорации, находится ли клиент в черном списке" },
	{ Id : "ViewCustomerStat", Name : "Просмотр статистики по клиенту", Description : "Данное право дает возможность просматривать статистику по заказам клиента" },
	{ Id : "ViewCustomerHistory", Name : "Просмотр истории заказов клиента", Description : "Данное право дает возможность просматривать историю заказов в карточке клиента" },

	{ Id: "Site", Name: "Сайт", Description : "" },
	{ Id: "ManageProductCatalog", Name: "Управление каталогом", Description : "Данное право дает возможность поьзователю формировать карточки товаров для внешних ресурсов. Право не влияет на просмотр карточек" },
]

export const notifications = {
	CheckLists: "Чек-листы",
	TaskReady : "Начало выполнения задачи",
	Orders: "Заказы",
	NewOrder: "Новый заказ",
	ChangeOrderStatus: "Изменение статуса заказа",
	CookingOrder: "Приготовление блюд заказа",
	PaidOrder: "Оплата заказа",
	ChangeOrder: "Изменение порядка заказов",
	ChangePaymentOrder: "Изменение способа оплаты",

}
export const errors = {
	"USER_NOT_AUTH": "Пользователь не авторизован",
	"WRONG_PARAMS": "Неправильные параметры запроса",
	"EMPTY_USER_EMAIL" : "В вашем аккаунте не указан Email для воостановления пароля. Обратитесь к вашему администратору.",
	"INTERNAL_ERROR": "Произошла системная ошибка, попробуйте еще раз",
	"USER_BLOCK": "Пользователь заблокирован. Обратись к вашему администратору",
	"USER_NOT_ACTIVE": "Пользователь заблокирован. Обратись к вашему администратору",
	"NOT_ALLOWED": "Вы пользуетесь системой в неразрешенном месте",
	"INSUFFICIENT_RIGHTS": "Недостаточно прав пользователя для совершения данного действия",
	"MERCHANT_USERLIMIT": "У вас достигнут лимит пользователей. Перейдите на страницу настройки предприятия для увеличения лимита.",
	"DUPLICATE_USER_LOGIN" : "Пользователь с таким номером телефона уже существует. Если это ваш номер телефона, напишите нам на support@ectrum.ru для решения данной ситуации.",

	"PRODUCT_REQUIRED": "Необходимо выбрать продукты",
	"ERR_PRODUCT_RECURSION": "Товар добавлен сам в себя или возникла кольцевая ссылка",
	"UNIT_REQUIRED": "Необходимо указать единицы измерения",
	"SECTION_REQUIRED": "Необходимо указать раздел",
	"EMPTY_REQUIRED": "Не заполнены обязательные поля",
	"WEIGHT_REQUIRED": "Поле «Вес» обязательно для заполнения",
	"VAT_REQUIRED": "Необходимо указать тип НДС",
	"PROVIDER_REQUIRED": "Необходимо выбрать поставщика",
	"INVOICE_NUMBER_REQUIRED": "Необходимо указать номер и тип накладной",
	"INVOICE_DATE_REQUIRED": "Необходимо указать дату накладной",
	"STOCK_REQUIRED": "Необходимо выбрать склад",
	"PAYMENTTYPE_REQUIRED": "Необходимо выбрать тип оплаты",
	"AMOUNT_REQUIRED": "Необходимо указать общую суммуи НДС накладной",
	"COUNT_REQUIRED": "Необходимо указать кол-во каждого продукта",
	"P_AMOUNT_REQUIRED": "Необходимо указать цену и НДС каждого продукта",
	"DIFFERENT_STOCK_REQUIRED": "Склады не могут совпадать",
	"PROCESS_PRODUCTS_REQUIRED": "Для обработки заявки необходимо выбрать продукты",
	"INVOICE_PRODUCT_REQUIRED": "Для формирования накладной необходимо выбрать продукты",
	"AUDIT_TYPE_REQUIRED": "Необходимо выбрать тип ревизии",
	"AUDIT_PRODUCTS_REQUIRED": "Необходимо выбрать продукты",
	"REVISION_PRODUCTS_REQUIRED": "Необходимо указать кол-во хотя бы одного продукта",
	"CANCELREASON_REQUIRED" : "Необходимо указать причину списания",
	"FILIAL_REQUIRED" : "Необходимо указать филиал",
	"DATE_REQUIRED" : "Необходимо выбрать дату",
	"INVENTORY_REQUIRED" : "Необходимо выбрать инвентарь",
	"FILIAL_MENU_REQUIRED" : "Необходимо выбрать склад для производства с бара и кухни",
	"STAT_DATE_REQUIRED" : "Необходимо указать даты отчета",
	"STAT_CITY_REQUIRED" : "Необходимо выбрать город",
	"INVENTORY_ACTION_REQUIRED" : "Необходимо выбрать пополнение или списание",
	"CURRENCY_REQUIRED" : "Необходиму выбрать валюту предприятия",
	"ENTITY_REQUIRED" : "Необходимо указать хотя бы одно юр. лицо",
	"MAIN_ENTITY_REQUIRED" : "Необходимо выбрать основное юр. лицо",
	"FILIAL_MAIN_REQUIRED" : "Необходимо выбрать основную точку продаж",
	"FILIAL_ENTITY_REQUIRED" : "Необходимо выбрать ЮЛ для точки продаж",
	"POSTYPE_REQUIRED" : "Необходимо выбрать тип точки продажи",
	"CUSTOMER_LOGIN_REQUIRED" : "Необходимо заполнить телефон клиента",
	"ORDER_PRODUCTS_REQUIRED" : "Необходимо выбрать товары для заказа",
	"ORDER_POS_REQUIRED" : "Необходимо выбрать точку продаж",
	"ORDER_TIME_REQUIRED" : "Необходимо указать время, к которому должен быть готов заказ",
	"ORDER_AGGREGATOR_REQUIRED" : "Необходимо указать агрегатор",
	"ORDER_BRAND_REQUIRED" : "Необходимо выбрать бренд",
	"ORDER_PAYMENT_REQUIRED" : "Необходимо указать тип оплаты",
	"ORDER_TYPE_REQUIRED" : "Необходимо указать тип получения заказа",
	"ORDER_MERCHANT_REQUIRED" : "Необходимо выбрать предприятие",
	"ORDER_FILIAL_REQUIRED" : "Необходимо выбрать филиал, где будет производится заказ",
	"ORDER_PERSON_REQUIRED" : "Количество персон должно быть больше «0»",
	"ORDER_ADDRESS_REQUIRED" : "При доставке необходимо указать корректный адрес клиента",
	"CUSTOMER_NAME_REQUIRED" : "Необходимо указать имя клиента",
	"WRONG_ORDER_POS_PRODUCT" : "При данном типе подачи заказа недоступны некоторые продукты из корзины",
	"PRINT_FORMAT_REQUIRED" : "Необходимо выбрать хотя бы один формат печати",
	"ORDER_CUSTOMERLOGIN_REQUIRED" : "Для «самовывоза» или «доставки» необходим номер телефона клиента",
	"ORDER_WITHOUT_COURIER" : "Необходимо назначить курьера на данный заказ",
	"ORDER_DELIVERYAREA_REQUIRED" : "Необходимо указать зону доставки",

	"STATUS_RELEASE_USED" : "Статус со свойством «Выпуск» уже существует",
	"STATUS_WRITEOFF_USED" : "Статус со свойством «Списание» уже существует",
	"ERR_WRONG_PARAMS_DATE" : "Неверно указана дата, к которой доставить заказ",
	"ERR_WRONG_PARAMS_TIME" : "Неверно указано время статуса",

	"TAG_EXIST": "Подсказка с таким именем уже существует",
	"INVENTORY_NUMBER_EXIST": "Такой инвентарный номер уже существует",
	"CANCEL_REASON_EXIST": "Такая причина списания уже существует",
	"SECTION_EXIST": "Раздел с таким именем уже существует",
	"PRODUCT_EXIST": "Продукт с таким именем уже существует",
	"REASON_EXIST": "Причина списания с таким именем уже существует",
	"USER_EXIST": "Пользователь с таким логином уже существует",
	"VENDORCODE_EXIST": "Такой артикул уже существует",
	"NAME_EXIST" : "Такое название уже существует",

	"EMPTY_MENU_GROUP": "Выберите тип меню",
	"WRONG_USER": "Неверно указан логин или пароль пользователя",
	"WRONG_PARAMS_PASSWORD": "Неверно указан логин или пароль пользователя",
	"WRONG_PARAMS_LOGIN": "Неверно указан логин или пароль пользователя",

	"WRONG_PARAMS_ID": "Ошибка при сохранении, переоткройте карточку и попробуйте еще раз",
	"WRONG_PARAMS_NAME": "Ошибка заполнения поля «Название»",
	"WRONG_PASS_FORMAT": "Длина пароля должна быть не меньше 8 символов. Пароль должен содержать только символы A-Za-z0-9()*!$@?%_",
	"WRONG_PHONE_LENGTH": "Введите валидный номер телефона",
	"WRONG_USER_EMAIL": "Укажите валидный email адреса",
	"EMPTY_CONTACTS": "В блоке «Контакты» должен быть заполнен либо номер телефона либо E-mail",
	// "WRONG_PASS_SYMBOLS": "Пароль должен содержать только символы A-Za-z0-9()*!$@?%_",
	"WRONG_PARAMS_MERCHANT": "Ошибка заполнения поля «Терминал»",
	"WRONG_PARAMS_ROLE": "Ошибка заполнения поля «Должность»",
	"WRONG_PARAMS_FILIAL": "Ошибка заполнения поля «Филиал»",
	"WRONG_PARAMS_RIGHT": "Ошибка заполнения поля «Права пользователя»",
	"WRONG_PARAMS_MENU": "Ошибка заполнения поля «Рабочие столы»",
	"WRONG_PARAMS_WIDGET": "Ошибка заполнения поля «Рабочие столы»",
	"WRONG_PARAMS_SIDEBAR": "Ошибка заполнения поля «Рабочие столы»",
	"WRONG_PARAMS_POS": "Ошибка заполнения поля «Точка продаж»",
	"WRONG_PARAMS_DEPARTMENT": "Ошибка заполнения поля «Отдел»",
	"WRONG_PARAMS_ADDRESS": "Ошибка заполнения поля «Адрес»",
	"WRONG_PARAMS_CITY": "Ошибка заполнения поля «Город»",
	"WRONG_PARAMS_TIMEZONE": "Ошибка заполнения поля «Часовой пояс»",
	"WRONG_PARAMS_INN" : "ИНН организации должен содержать 10 или 12 (ИП) цифр",
	"WRONG_PARAMS_OGRN" : "ОГРН организации должен содержать 13 цифр",
	"TIPS_PRESET_REQUIRED" : "Пресеты чаевых должны быть указаны",

	"ERR_WRONG_PARAMS_STARTTIME": "Ошибка заполнения поля «Время начала»",
	"ERR_WRONG_PARAMS_ENDTIME": "Ошибка заполнения поля «Время окончания»",
	"ERR_WRONG_TASK": "Ошибка заполнения поля «Задача»",
	"ERR_WRONG_USER": "Ошибка заполнения поля «Пользователь»",
	"ERR_WRONG_TASKNAME": "Ошибка заполнения поля «Название»",
	"ERR_WRONG_PERIOD": "Ошибка заполнения поля «Период»",
	"ERR_WRONG_PARAMS_STARTDATE": "Ошибка заполнения поля «Начальной даты»",
	"ERR_WRONG_PARAMS_DASHBOARD": "Ошибка заполнения поля «Рабочие стол»",
	"ERR_WRONG_PARAMS_FILE": "Ошибка заполнения поля «Файл»",
	"ERR_WRONG_PARAMS_PERIOD_DESCRIPTION": "Ошибка заполнения поля «Периодичность»",
	"ERROR_WRONG_COMBO" : "Комбо продукт должен состоять только из конечной продукции",
	"ERR_WRONG_PARAMS_ENTITY" : "Необходимо выбрать ЮЛ для каждой точки продаж",
	"ERR_WRONG_PARAMS_TYPE" : "Необходимо выбрать тип продаж для каждой точки продаж",
	"WRONG_USER_INN" : "ИНН должно состоять из 12 цифр",
	"WRONG_ORDER_CHANGEFROM" : "Сумма сдачи с должна быть больше суммы к оплате",
	"EMPTY_USER_ROLE" : "У пользователя должна быть хотя бы одна должность",
	"EMPTY_USER_FILIAL" : "У пользователя должен быть хотя бы один филиал",

	"TASK_DONE": "Ошибка при выполнении задачи",
	"PHOTOS_REQUIRED": "При выполнении задачи необходимо сделать фото",
	"COUNT_PHOTOS_REQUIRED" : "Количество приложенных фото не совпадает с требуемым",
	"ROLE_DELETED": "Ошибка при удалении должности",
	"FILIAL_DELETED": "Ошибка при удалении филиала",
	"CHECKLIST_DELETED": "Ошибка при удалении чек-листа",
	"CONTENT_STATE": "Ошибка при изменении статуса",
	"SHIFT_OPEN": "Ошибка при открытии смены",
	
	"ROLE_BELONG": "К данному отделу привязаны должности",
	"LOST_REPORTS": "Отправьте отчет о смене перед закрытием смены",
	"EARLY_EXECUTION": "Дождитесь назначенного времени выполнения задачи",
	"PUSH_REQUIRED": "Для продолжения работы с приложением необходимо подписаться на уведомления (иконка «i» слева от адресной строки вашего браузера)",
	"ERR_HAS_PRODUCTS": "Нельзя удалить категорию, пока в ней есть продукты",
	"ERR_WRONG_PARAMS_COUNT": "Недостаточно продуктов на складе для проведения операции",
	"HAS_UNCONFIRMED_OPERATIONS" : "Сначала подтвердите все предыдущие операции",
	
	"ERR_NOT_ENOUGH_RAWS" : "Недостаточно продуктов на складе для проведения операции",

	"BONUS_CONDITION_REQUIRED" : "Должны быть заданы условия правила",
	"BONUS_COND_TYPE_REQUIRED" : "Необходимо выбрать тип условия",
	"BONUS_OPERAND_REQUIRED" : "Необходимо указать операцию сравнения условия",
	"BONUS_COND_AMOUNT_REQUIRED" : "Необходимо указать «сумму» для сравнения",
	"BONUS_COND_COUNT_REQUIRED" : "Необходимо указать «количество» для сравнения",
	"BONUS_COND_PRODUCTS_REQUIRED" : "Необходимо указать список продуктов",
	"BONUS_COND_DATETYPE_REQUIRED" : "Необходимо указать тип сравнения с датой",
	"BONUS_COND_DATE_REQUIRED" : "Необходимо указать дату события",
	"BONUS_COND_WEEK_REQUIRED" : "Необходимо выбрать дни недели события",
	"BONUS_COND_DAYS_REQUIRED" : "Необходимо указать дни месяца события",
	"BONUS_ACTIONS_REQUIRED" : "Должны быть заданы результаты действия правиа",
	"BONUS_ACTION_TYPE_REQUIRED" : "Необходимо выбрать тип результата",
	"BONUS_ACT_COUNT_REQUIRED" : "Необходимо указать количество баллов",
	"BONUS_ACT_SALE_REQUIRED" : "Необходимо указать сумму скидки",
	"BONUS_ACT_PRODUCT_REQUIRED" : "Необходимо выбрать продукт в результате",
	"BONUS_ACT_AMOUNT_REQUIRED" : "Необходимо указать сумму продукта. Если подарок — 0",
	"POS_CHANGE_CASHINHAND" : "Невозможно изменить способ оплаты, так как курьеру выдана сдача. Обратитесь к администратору системы",
	"CASH_COURIER_HAND" : "Перед совершением этого действия забери деньги у курьера",
	"WRONG_PAYOUT_AMOUNT" : "Сумма для вывода должна быть больше 100 рублей и меньше 15 000 рублей.",

	"WRONG_PRODUCT_VENDOR" : "Продукт не найден по артикулу",
	// "ERR_NOT_ENOUGH_RAWS" : "Недостаточно продуктов на складе",
	"WRONG_SHIFT_MERCHANT":"Вы закрываете смену не в том предприятии, в котором открыли",
	"TEMPLATE_POS_EXIST": "На данную точку продаж уже создан один шаблон чека",
	"CASSA_SHIFT_NOT_OPEN": "На выбранной кассе не открыта смена",

	"WRONG_ORDER_PRODUCT_POSTYPE" : "При данном типе подачи заказа недоступны некоторые продукты из корзины",
	"ORDER_IS_ALREADY_COOKED" : "Заказ уже приготовлен. Чтобы изменить состав, необходимо вернуть статус заказа в производстве",
	"COURIER_CIH_PRODUCTS" : "Заказ уже передан курьеру. Для изменения состава заказа надо забрать у курьера деньги и изменить статус до передачи курьеру",
	"ORDER_IN_PROGRESS": "В данный момент заказ находится в обработке другим пользователем. Попробуйте выполнить действие позднее",
	"WRONG_CHANGE_CUSTOMER" : "Нельзя изменить пользователя на указанного",
	"ORDER_IS_FINISHED" : "Заказ уже выполнен и недоступен для редактирования",

	"TTK_PRODUCT_REQUIRED" : "Необходимо привязать основной продукт из ТТК",
	"COUNTTYPE_REQUIRED" : "Для каждой группы должен быть указан способ выбора свойств",
	"CATALOG_OPTION_REQUIRED" : "В каждой группе должно быть хотя бы одно свойство",
	"TTK_PRODUCT_OPTION_REQUIRED" : "К каждому свойству необходимо привязать продукт из ТТК",
	"TTK_PRODUCT_UNIQ" : "Все продукты ТТК должны быть уникальны в рамках карточки",
	"WRONG_SECTION_PARENT" : "Нельзя удалить категорию, потому что у нее есть вложенные карточки или подразделы",

	"EMPTY_RESPONSE" : "По вашему запросу ничего не найдено",
	"EMPTY_TASKS" : "У вас нет задач на сегодня",
	"SHIFT_CLOSE": "Необходимо открыть смену для работы с данным разделом",
	"SHIFT_CLOSE_TASKS" : "Откройте смену для формирования задач по чек листам",
	"CASSA_SHIFT_CLOSE" : "Необходимо открыть кассовую смену",
	"CANT_REFUND_WRITEOFF" : "Нельзя списать заказ, по которому был произведен фискальный возврат",
	"ERR_FISCAL_PAID_ORDERS" : "В данной кассе остались нефискализированные оплаты заказов",
	"ERR_FISCAL_REFUND_ORDERS" : "В данной кассе остались нефискализированные возвраты заказов",
	"FISCAL_TRANSACTION_ORDERS" : "В кассе остались нефискализированные операции",
	"CASSA_NOTENOUGH_AMOUNT" : "В кассе недостаточно денег для совершения операции",

	"PAYOUT_ERROR" : "Произошла ошибка при выводе денег на карту. Проверьте ваш статус, баланс на карте и лимиты по операциям. Попробуйте повторить операцию позже.",

	"WRONG_OLD_PASSWORD" : "Некорректный текущий пароль",
	"WRONG_PASSWORDS_IDENTICAL" : "Старый и новый пароли должны отличаться",
	"WRONG_NEW_PASSWORD" : "Введенные пароли не совпадают",
	"EMPTY_RESET_EMAIL" : "Для сброса пароля необходимо указать E-mail",
	"EMPTY_EMAIL" : "У вас отсуствует E-mail для восстановления пароля, обратить к вашему руководству для настройки E-mail в вашем профиле",
	"WRONG_TOKEN" : "Указанный токен недействительный. Возможно закончился его срок действия. Повторите запрос на сброс пороля со странице входа.",

	"REVIEW_COMMENT_REQUIRED" : "Заполните причину отклонения задачи",

	"MERCHANT_BLOCKED" : "У предприятия закончился оплаченный период, обратитесь к вашему администратору",

	"SUCCESS_SAVE": "Сохранение прошло успешно!",
	"SUCCESS_NEW_USER_SAVE" : "Пользователь успешно создан! На указанную почту отправлено письмо с доступами.",
	"SUCCESS_ACCEPT": "Подтверждение прошло успешно!",
	"SUCCESS_SEND": "Отправка прошла успешно!",
	"SUCCESS_UPLOAD" : "Файл загружен",
	"SUCCESS_RESET" : "Пароль сброшен. Новые данные отправлены на почту",

	"DEFAULT": "Произошла ошибка"
}

export const daysShort = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];
export const daysShortSun = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
export const daysLong = ["Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"];
export const daysWorkLong = ["Понедельник", "Вторник", "Среда", "Четверг", "Пятница"];

export const phoneMasks = {
	"Россия": /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/
}

export const ShiftTitles = {
	Role: "Рабочий стол",
	Filial: "Филиал",
	Merchant: "Предприятие"
}

export const timeZonesTitles = {
	"Asia/Anadyr": "Россия/Анадырь",
	"Europe/Astrakhan": "Россия/Астрахань",
	"Asia/Barnaul": "Россия/Барнаул",
	"Asia/Vladivostok": "Россия/Владивосток",
	"Europe/Volgograd": "Россия/Волгоград",
	"Asia/Yekaterinburg": "Россия/Екатеринбург",
	"Asia/Irkutsk": "Россия/Иркутск",
	"Europe/Kaliningrad": "Россия/Калининград",
	"Asia/Kamchatka": "Россия/Камчатка",
	"Europe/Kirov": "Россия/Киров",
	"Asia/Krasnoyarsk": "Россия/Красноярск",
	"Asia/Magadan": "Россия/Магадан",
	"Europe/Moscow": "Россия/Москва",
	"Asia/Novokuznetsk": "Россия/Новокузнецк",
	"Asia/Novosibirsk": "Россия/Новосибирск",
	"Asia/Omsk": "Россия/Омск",
	"Europe/Samara": "Россия/Самара",
	"Europe/Saratov": "Россия/Саратов",
	"Asia/Sakhalin": "Россия/Сахалин",
	"Europe/Simferopol": "Россия/Симферополь",
	"Asia/Srednekolymsk": "Россия/Среднеколымск",
	"Asia/Tomsk": "Россия/Томск",
	"Europe/Ulyanovsk": "Россия/Ульяновск",
	"Asia/Ust-Nera": "Россия/Усть-Нера",
	"Asia/Khandyga": "Россия/Хандыга",
	"Asia/Chita": "Россия/Чита",
	"Asia/Yakutsk": "Россия/Якутск"
}

export const rightTypes = {
	Content: "ManageContent",
	Stock: "ManageSaleOuts",
	Documents: "ManageContent",
	News: "ManageNews",
	CancelReasons: "ManageReasons",
	Tags: "ManageTags",
	OrderStatus: "ManageOrderStatuses",
	Marks: "ManageMarks",
	CassaTransactionTag : "ManageCassaTransactionTags"
}

export const buttonTitles = {
	Content: "Добавить статью",
	Stock: "Добавить акцию",
	Documents: "Добавить документ",
	News: "Добавить новость",
	Tags: "Добавить подсказку",
	Marks: "Добавить отметку заказа",
	CancelReasons: "Добавить причину",
	Contacts: "Добавить контактное лицо",
	Roles: "Создать должность",
	Users: "Создать пользователя",
	Couriers: "Создать курьера",
	Filials: "Создать филиал",
	CheckLists: "Создать чек-лист",
	Providers: "Создать поставщика",
	ActiveUsers: "Активные пользователи",
	notActiveUsers: "Удаленные пользователи",
	TipsRecipientUsers: "Получатели чаевых",
	notTipsRecipientUsers: "Все пользователи",
	ActiveCouriers: "Активные курьеры",
	notActiveCouriers: "Удаленные курьеры",
	ActiveProviders: "Активные поставщики",
	notActiveProviders: "Удаленные поставщики",
	ActiveInventories: "Активный инвентарь",
	notActiveInventories: "Удаленный инвентарь",
	Raw: "Создать сырье",
	Products: "Создать продукцию",
	SemiManufactures: "Создать полуфабрикат",
	NewRaw: "Новое сырье",
	EditRaw: "Редактирование сырья",
	NewProducts: "Новая продукция",
	EditProducts: "Редактирование продукции",
	NewSemiManufactures: "Новый полуфабрикат",
	EditSemiManufactures: "Редактирование полуфабриката",
	NewUsers: "Новый пользователь",
	EditUsers: "Редактирование пользователя",
	NewCouriers: "Новый курьер",
	EditCouriers: "Редактирование курьера",
	NewRole: "Новая должность",
	EditRole: "Редактирование должности",
	NewFilial: "Новый филиал",
	EditFilial: "Редактирование филиала",
	NewCheckList: "Новый чек-лист",
	EditCheckList: "Редактирование чек-листа",
	NewProvider: "Новый поставщик",
	EditProvider: "Редактирование поставщика",
	AddProducts: "Добавить сырье",
	EditStock: "Настройка Склада",
	Order: "Создать заявку",
	NewRequest: "Новая заявка",
	EditRequest: "Обработка заявки",
	EditShopList: "Редактирование списка покупок",
	Invoices: "Создать накладную",
	NewInvoice: "Новая накладная",
	EditInvoice: "Редактирование накладной",
	ViewInvoice: "Накладная",
	Transfers: "Создать перемещение",
	NewTransfer: "Новое перемещение",
	EditTransfer: "Перемещение",
	ViewRequest: "Просмотр заявки",
	Audits: "Провести ревизию",
	NewAudit: "Новая ревизия",
	EditAudit: "Редактирование ревизии",
	ViewAudit: "Просмотр ревизии",
	NewWriteOff: "Заявки на списания",
	RequestWriteOff: "Заявка на списание",
	EditWriteOff: "Редактирование списка списаний",
	ViewWriteOff: "Просмотр списка списаний",
	NewInventorization: "Инвентаризация",
	Inventorization: "Провести инвентаризацию",
	Aggregators: "Доступные агрегаторы",
	Brands: "Настройка брендов",
	PaymentMethods: "Доступные методы оплаты",
	Entities: "Юридические лица",
	POSes : "Точки продаж",
	AddPOS : "Добавить точку продаж",
	RemovePOS : "Удалить точку продаж",
	Stores : "Склады",
	AddStore : "Добавить склад",
	RemoveStore : "Удалить склад",
	OrderStatus: "Добавить статус заказа",
	NewOrderStatus: "Новый статус заказа",
	EditOrderStatus: "Редактирование статуса заказа",
	WorkShops: "Добавить цех",
	Orders: "Новый заказ",
	NewOrder: "Новый заказ",
	EditOrder: "Заказ №",
	Customers: "Создать клиента",
	NewCustomer: "Новый клиент",
	EditCustomer: "Редактирование клиента",
	BonusRules: "Создать правило",
	NewBonusRule: "Новое правило",
	EditBonusRule: "Редактирование правила",
	ActiveBonusRules : "Активные правила",
	NotActiveBonusRules : "Удаленные правила",
	activeBonusPromocodes : "Активные промокоды",
	notactiveBonusPromocodes : "Удаленные промокоды",
	NewPromocode : "Новый промокод",
	EditPromocode : "Редактирование промокода",
	CassaTransactionTag: "Добавить тип операции",
	Check : "Новый шаблон чека",
	NewCheck : "Создание шаблона чека",
	EditCheck : "Редактирование шаблона чека",
	ShiftReport : "Отчеты по открытию смен пользователями",
	ShiftReportCourier : "Отчеты по открытию смен курьерами",
	Catalog : "Создать карточку товара",
	NewCatalog : "Новая карточка товара",
	EditCatalog : "Редактирование карточки товара",

}

export const VATTitles = [
	{ Id: 1, Name: "0%" },
	{ Id: 2, Name: "10%" },
	{ Id: 3, Name: "20%" }
]

export const contentTypesIndex = {
	Content: 1,
	Stock: 2,
	Documents: 3,
	News: 4
}

export const reportTemplate = {
	"Администратор": 1,
	"Оператор": 2,
	"Бариста": 3,
	"Повар": 4
}

export const directoryTitles = {
	removeTags: "подсказку",
	removeCancelReasons: "причину",
	removeMarks: "отметку заказа",
	removeWorkShops: "цех",
	removeCassaTransactionTag : "тип операции"
}

export const directoryAPI = {
	Tags: {
		List: "Tags",
		Save: "TagSave",
		Remove: "TagRemove"
	},
	CancelReasons: {
		List: "CancelReasons",
		Save: "CancelReasonSave",
		Remove: "CancelReasonRemove"
	},
	Inventories: {
		List: "Inventories",
		Save: "SaveInventory",
		Remove: "RemoveInventory"
	},
	Marks: {
		List: "Marks",
		Save: "SaveMark",
		Remove: "RemoveMark"
	},
	WorkShops: {
		List: "WorkShops",
		Save: "SaveWorkShop",
		Remove: "RemoveWorkShop"
	},
	CassaTransactionTag : {
		List: "CassaTransactionTags",
		Save: "SaveCassaTransactionTag",
		Remove: "RemoveCassaTransactionTag"
	}
}

export const directoryFields = {
	Tags: [
		{ Id: "Name", Type: "text", Title: "Название", Required: true }
	],
	Marks: [
		{ Id: "Name", Type: "text", Title: "Название", Required: true }
	],
	WorkShops: [
		{ Id: "Name", Type: "text", Title: "Название", Required: true },
		{ Id: "Icon", Type: "icon", Title: "Иконка", Required: true, IconType : "Kitchen" }
	],
	CancelReasons: [
		{ Id: "Name", Type: "text", Title: "Название", Required: true },
		{
			Id: "Type", Type: "select", Title: "Тип причины", Required: true, List: [
				{ Key: "Kitchen", Value: "Кухня/склад" },
				{ Key: "Order", Value: "Заказ" },
			]
		}
	],
	Inventories: [
		{ Id: "Name", Type: "text", Title: "Название", Required: true },
		{ Id: "InventoryNumber", Type: "text", Title: "Инвентарный номер", Required: true },
		{ Id: "Price", Type: "number", Title: "Цена", Required: true },
		{ Id: "SectionId", Type: "select", Title: "Раздел", Required: true },
	],
	CassaTransactionTag: [
		{ Id: "Name", Type: "text", Title: "Название", Required: true },
		{
			Id: "Group", Type: "select", Title: "Тип операции", Required: true, List: [
				{ Key: 1, Value: "Внесение" },
				{ Key: 2, Value: "Изъятие" },
			]
		}
	]
}

export const CancelReasonsTitles = {
	"Kitchen": "Кухня/склад",
	"Order": "Заказ",
}

export const MenuTitles = [
	{ Id: 1, Name: "Кухня" },
	{ Id: 2, Name: "Бар" }
]

export const ProductTypeTitles = [
	{ Id : 1, Name : "Сырье" },
	{ Id : 2, Name : "Полуфабрикаты" },
	{ Id : 3, Name : "Продукция" }
]

export const POSTypesTitles = [
	{ Id : 1, Name : "Зал", ShortName : "З", OrderFilialTitle : "Какой из ресторанов вы хотите посетить?" },
	{ Id : 2, Name : "Самовывоз", ShortName : "С", OrderFilialTitle : "С какого адреса вам удобно забрать?" },
	{ Id : 3, Name : "Доставка", ShortName : "Д", OrderFilialTitle : "Выберите филиал, из которого будет осуществляться доставка" },
	{ Id : 4, Name : "Внутренний самовывоз", ShortName : "В", Icon : "home", OrderFilialTitle : "Выберите филиал, из которого будет осуществляться самовывоз" },
]
export const IconsPreset = {
	Kitchen : [1,2,3,4,5,6]
}

export const OrderTimeTitles = [{ Id : 1, Name : "Текущий заказ" }, { Id : 2, Name : "Заказ ко времени" }];
export const OrderAggTitles = [{ Id : 1, Name : "Свой заказ" }, { Id : 2, Name : "Через агрегатор" }];

export const OrderReceivingTitles = [
	{ Id : 1, Name : "Онлайн" },
	{ Id : 2, Name : "Телефон" },
	{ Id : 3, Name : "Зал" },
	// { Id : 4, Name : "Агрегатор" }
]

export const OrderLabels = ["заказ", "заказа", "заказов"];
export const MinutesLabels = ["минута", "минуты", "минут"];
export const MinutesShortLabels = ["мин", "мин", "мин"];
export const PersonLabels = ["персона", "персоны", "персон"];

export const OrderStatusTypes = [
	{ Id : 0, Name : "Завершающий", Description : "Данный статус обозначает, что заказ является выполненным и дальнейшее его изменение невозможно." },
	{ Id : 4, Name : "Отказ покупателя", Description : "Данный статус обозначает, что пользователь отказался от заказа, и дает доступ к списанию заказа" },
	{ Id : 1, Name : "Списание", Description : "Данный статус обозначает, что вся выпущенная продукция по данному заказу будет списана, а сам заказ отменен" },
	{ Id : 3, Name : "Старт производства", Description : "Данный статус обозначает что кухня начала производить заказ" },
	{ Id : 2, Name : "Завершение выпуска продукции", Description : "Данный статус обозначает, что на этом этапе будет выпущена вся продукция по заказу" },
	{ Id : 5, Name : "Выдача сдачи", Description : "Данный статус обозначает, что курьеру была выдана сдача и внесена запись в кассу об этом" },
	{ Id : 6, Name : "Доставка", Description : "Данный статус обозначает, что в этом статусе происходит доставка заказа до пользователя. Необходим для расчета времени при использовании зон доставки" },
];

export const merchantTypes = [
	{ Id : 1, Name : "Самостоятельное предприятие" },
	{ Id : 2, Name : "Колл-центр" },
	{ Id : 3, Name : "Франшиза" }
]

export const ConditionGroupTypes = [
	{ Id : 1, Name : "Сравнение со всеми заказами пользователя", Description : "Данное правило использует всю историю заказов пользователя. Можно использовать для вычисления накопительной скидки или начисления баллов" },
	{ Id : 2, Name : "Сравнение с суммой текущего заказа", Description : "Данное правило проверяет текущую сумму заказа. Используется, если скидка, бонусы или подарки начисляются в зависимости от текущего заказа" },
	{ Id : 3, Name : "Сравнение с товарами в списке", Description : "Данное правило проверяет состав заказа и срабатывает если присутствует товар из списка" },
	{ Id : 4, Name : "Сравнение с датой", Description : "Данное правило производит сравнение с текущей датой заказа. Можно сравнивать с конкретной датой или задать периодичность в зависисмости от дней недели или месяца" },
]
export const ActionGroupTypes = [
	{ Id : 1, Name : "Начислить баллы", Description : "По данному правилу происходит начисление баллов пользователю для последующего использования" },
	{ Id : 2, Name : "Сделать скидку", Description : "Данное правило устанавливает скидку по текущему заказу" },
	{ Id : 3, Name : "Предложить подарок или продукт по спец. цене", Description : "По данному правилу пользователю предлагается набор товаров по специальной цене. Если это подарок — цена товара равна 0" },
	{ Id : 4, Name : "Не учитывать товар", Description : "При выборе данного правила товар из списка не участвует в расчете" },
]


export const CassaTypes = [
	{ Id : 1, Name : "Наземная касса" },
	{ Id : 2, Name : "Курьерская касса" }
]

export const PrintFormatesTitles = [
	{ Id : 0, Name : "50 мм", Type : "tape", Width : "200px" },
	{ Id : 1, Name : "58 мм", Type : "tape", Width : "228px" },
	{ Id : 2, Name : "80 мм", Type : "tape", Width : "313px" },
	{ Id : 3, Name : "Товарный (А4)", Type : "paper", Width : "196px" },
	{ Id : 4, Name : "Два товарных чека (А4)", Type : "paper", Width : "196px" }
]
export const TextAligns = {
	Titles : [{ Id : 1, Name : "Левое"}, { Id : 2, Name : "Центр"}, { Id : 3, Name : "Правое"}],
	Actions : { 1 : "left", 2 : "center", 3 : "right"}
}

export const CassaTransactionTypes = { 1 : "Внесение", 2 : "Изъятие", 3 : "Оплата заказа", 4 : "Сдача по заказу", 5 : "Возврат заказа", 6: "Возврат сдачи" }

export const CheckShiftTitles = {
	CashInHand: "Забрать деньги у курьеров", 
	CassaShift: "Закрыть кассовые смены",
	NonFinishedOrder: "Выполнить/списать все заказы за смену",
	NonPaidOrder: "Фискализировать все заказы за смену",
	Reports: "Отправить отчет по смене",
	ShiftCourier: "Закрыть смену курьерам",
	WriteOffs: "Отправить списания за смену",
	ZReportSent: "Снять Z-отчет с фискальных касс"
}

export const TimeListTitles = [{ Id : "IsLate", Name : "Опаздывают" }, { Id : "ToTime", Name : "Ко времени" }]
export const HomeOrdersTitles = [{ Id : 1, Name : "Коммерческие" }, { Id : 2, Name : "Внутренние" }];

export const TipsStateTitles = {
	0 : "Клиент зарегистрирован в ПЦ. Может иметь привязанную Виртуальную карту. Разрешены операции зачисления денег на Виртуальную карту пользователя, списание с карты — запрещено",
	1 : "Клиент зарегистрирован в ПЦ, имеет Виртуальную карту и привязанный к ней телефон. Разрешены операции зачисления и списания денег по Виртуальной карте пользователя.",
	2 : "Клиент зарегистрирован в ПЦ. По какой-то причине считается временно неактивным. Разрешены операции зачисления денег на Виртуальную карту пользователя, списание с карты — запрещено.",
	3 : "Клиент зарегистрирован в ПЦ, но заблокирован. Запрещены операции зачисления и списания средств по Виртуальной карте пользователя."
}

export const TipsActiveTitles = {
	0 : "Идентификация не проводилась, либо данные не подтверждены, телефон не привязан.",
	1 : "Идентификация не проводилась, либо данные не подтверждены, телефон привязан.",
	2 : "Идентификация, карта привязана, телефон привязан.",
	3 : "Идентификация запрошена, нет информации о результате проверки, телефон привязан.",
	4 : "Идентификация проведена, данные не подтверждены, телефон привязан.",
	5 : "Идентификация проведена, данные подтверждены, телефон привязан."
}




