import { observable, decorate } from 'mobx';
import _ from 'lodash';
import { authUserState } from './common';

class StopListState {
	// List = [];
	IsSendRequest = false;

	/** Установка значений по умолчанию */
	setDefault() {
		this.List = [];
		this.Products = [];
	}

	/**
	 * Разбор данных от сервера со списком стоп позиций
	 * @param {object} data ответ от сервера
	 */
	setList (data) {
		this.List = data.Success && !_.isNull(data.Goods) ? _.map(data.Goods, function(item) {
			return _.extend(item, { 
				CanRemove : authUserState.Shift.Active && authUserState.Shift.FilialId === item.FilialId
			});
		}) : [];
		this.IsSendRequest = true;
	}

	/** Формирования стоп-листа, разделение продуктов по предприятиям и филиалам
	 * @returns {[object]} Список продуктов в стоп листе
	 */
	get stopList () {
		if (_.isUndefined(this.List)) return [];

		var list = _.clone(this.List),
			stopFilial = _.filter(list, { FilialId : authUserState.Shift.FilialId }),
			needDummyFilial = authUserState.Shift.Active && _.isEmpty(stopFilial),
			filial = _.find(authUserState.Filials, { Id : authUserState.Shift.FilialId }),
			merchant = _.find(authUserState.Merchants, { Id : authUserState.Merchant })

		if (needDummyFilial) 
			list = _.concat(list, {
				MerchantId: authUserState.Merchant,
				Merchant: merchant ? merchant.Name : "",
				FilialId: authUserState.Shift.FilialId,
				Filial: filial ? filial.Name : "",
			});
		
		return _.map(_.groupBy(list, "MerchantId"), function(group, merchant) {
			return {
				MerchantId : parseInt(merchant),
				Merchant : group[0].Merchant,
				Filials : _.map(_.groupBy(group, "FilialId"), function(products, filial) {
					var filialId = parseInt(filial);
					
					return {
						FilialId : filialId,
						Filial : products[0].Filial,
						List : needDummyFilial && filialId === authUserState.Shift.FilialId ? [] : products
					}
				})
			}
		});
	}

	/** Разделы продукции, для того чтобы убрать продукты из скрытых категорий */
	get sectionsList () {
		if (_.isUndefined(this.Sections) || _.isEmpty(this.Sections)) return [];

		return _.filter(this.Sections, { System: false, IsHidden: false });
	}

	/** Фильтрация продуктов — удаляем из списка те, которые уже есть в стоп листе
	 * @returns {[object]} Список продуктов для стоп листа {Id, Name}
	 */
	get productsList () {
		if (_.isUndefined(this.Products) || _.isEmpty(this.Products)) return [];
		
		var listProducts = _.map(_.filter(this.List, { FilialId : authUserState.Shift.FilialId }), "ProductId"),
			sections = _.map(this.sectionsList, "Id"),
			products = _.isEmpty(this.List) ? this.Products : _.filter(this.Products, function(product) {
				return _.indexOf(listProducts, product.Id) === -1 && _.indexOf(sections, product.SectionId) !== -1; 
			});

		return _.sortBy(_.map(products, function(item) { return { Id : item.Id, Name : item.Name }; }), "Name");
	}
}

decorate(StopListState, {
	List: observable,
	IsSendRequest: observable,

	Products: observable,
	Sections: observable,
});
export const stopListState = new StopListState();