import { observable, decorate, computed } from 'mobx';
import _ from 'lodash';
import moment from 'moment';

import { intToBitArray, formatDate, arrayToBitString } from '../functions';
import { PrintFormatesTitles } from '../dictionary';
import { authUserState } from './common';

class BillTemplateState {
	Templates = [];
	IsSendRequest = false;
	POS = [];

	Template = undefined;
	Brands = {};
	Fields = [];
	ButtonLoading = false;
	SuccessSave = false;
	ErrorCode = "";
	BillState = "tape";

	TemplateDummy = {
		Id: -1,
		POSId: -1,
		Requisites: "",
		Manufacturer: "",
		LegalAddress: "",
		ShowCustomerAddress: false,
		ShowCustomerName: false,
		ShowDescription: false,
		ShowOrderTime: false,
		ShowPrintTime: false,
		ShowStaff: false,
		ShowComposition: false, 
		FinalText: "",
		FontSize: 12,
		FontSizeComposition: 10,
		PrintFormat: "",
		AutoRequisites : false,
		TextAlign : 1
	};
	FieldDummy = { Name : "" };
	BillDummy = {
		OrderId : 123,
		OrderTime : formatDate(moment().subtract(20, "minutes"), "YYYY-MM-DD HH:mm"),
		OrderNumber : "123",
		Brand : "",
		BrandBigLogo : "",
		Products : [{ 
				Product : "Фирменный напиток", 
				Count : 3, 
				Price : 10000,
				Composition : "Клюква, вода, сахар",
				Weight : 350,
				Calories : 214.5,
				CaloriesJoule : 894.057,
				Proteins : 0,
				Fats : 0,
				Carbohydrate : 53.6,
				ExpDateRoom : 5,
				ExpDate : 24,
				ExpDateFreeze : 0,
			}, { 
				Product : "Фирменное блюдо", 
				Count : 2, 
				Price : 25000,
				Composition : "Рис, нори, огурец, томаты, перец, курица, соус чесночный. Аллергены: Кунжут",
				Weight : 125,
				Calories : 311.8,
				CaloriesJoule : 1299.613,
				Proteins : 4.3,
				Fats : 10.1,
				Carbohydrate : 52.4,
				ExpDateRoom : 1,
				ExpDate : 12,
				ExpDateFreeze : 0,
			}, { 
				Product : "Блюдо от шефа", 
				Count : 1, 
				Price : 22500,
				Composition : "Булочка с кунжутом, сыр, соус Тар-Тар, бекон, котлета говяжья. Аллергены: Кунжут, Молоко",
				Weight : 300,
				Calories : 607.5,
				CaloriesJoule : 2543.481,
				Proteins : 10.8,
				Fats : 52.2,
				Carbohydrate : 24.0,
				ExpDateRoom : 3,
				ExpDate : 0,
				ExpDateFreeze : 0,
			},
		],
		Amount : 102500,
		Discount : 5000,
		TotalAmount : 97500,
		DescriptionAdmin : "Домофон не работает",
		PersonsNumber : 3,
		TableNumber : 4,
		Customer : {
			Login : "79991234567",
			Name : "Иван Петров",
			Address : {
				Street : "ул. Ленона",
				House  : "2",
				HouseBuilding  : "",
				Flat : "13",
				Intercom : "13В1331",
				Entrance : 3,
				Floor : 2,
			}
		},
		Date : formatDate(moment(), "YYYY-MM-DD HH:mm"),
		ProductionDate : formatDate(moment(), "YYYY-MM-DD HH:mm"),
	}

	/**
	 * Разбор данных списка шаблонов от сервера
	 * @param {object} data ответ от сервера
	 */
	setTemplates(data) {
		this.Templates = !data.Success ? [] : _.map(data.BillTemplates, function (bill) {
			var prints = intToBitArray(bill.PrintFormat);
			return _.extend(bill, {
				PrintFormat: _.map(prints, function (v) {
					return _.find(PrintFormatesTitles, { Id: v }).Name
				}).join(", ")
			});
		});
		this.IsSendRequest = true;
	}

	/**
	 * Разбор данных шаблона от сервера
	 * @param {object} template ответ от сервера
	 */
	setTemplate(template) {
		this.Template = _.isUndefined(template) ? undefined : template.Id === -1 ? _.clone(this.TemplateDummy)
			: _.extend(_.omit(_.clone(template), ["Success", "ErrorCode", "Fields"]), { 
				PrintFormat: intToBitArray(template.PrintFormat),
				AutoRequisites : false,
				FontSizeComposition : template.FontSizeComposition === 0 ? 10 : template.FontSizeComposition
			});

		this.Fields = _.isUndefined(template) || _.isNull(template.Fields) || template.Id === -1 ? [] 
			: template.Fields;

		this.ButtonLoading = false;
		this.ErrorCode = "";
		this.SuccessSave = false;
		this.FieldCounter = 0;
		this.BillState = "tape";
	}

	/**
	 * Сбор данных с формы настроек шаблона
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 * @param {number} index порядковый номер произвольного поля
	 */
	collectTemplate(id, value, index) {
		if (/AutoRequisites/.test(id)) {
			this.Template.AutoRequisites = value;
			this.Template.Requisites = value ? this.requisites : "";
		}
		else if (/ShowCustomerAddress/.test(id)) this.Template.ShowCustomerAddress = value;
		else if (/ShowCustomerName/.test(id)) this.Template.ShowCustomerName = value;
		else if (/ShowDescription/.test(id)) this.Template.ShowDescription = value;
		else if (/ShowOrderTime/.test(id)) this.Template.ShowOrderTime = value;
		else if (/ShowPrintTime/.test(id)) this.Template.ShowPrintTime = value;
		else if (/ShowStaff/.test(id)) this.Template.ShowStaff = value;
		else if (/ShowComposition/.test(id)) this.Template.ShowComposition = value;
		else if (/PrintFormat/.test(id)) {
			var format = parseInt(id.split("_")[1], 10);
			if (value) this.Template.PrintFormat = _.concat(this.Template.PrintFormat, format);
			else this.Template.PrintFormat = _.without(this.Template.PrintFormat, format);
		} else if (/Field/.test(id)) 
			this.Fields[index].Value = [_.toUpper(_.head(value)), _.tail(value).join("")].join("");
		else if (/FinalText/.test(id) || /Requisites/.test(id))
			this.Template[id] = [_.toUpper(_.head(value)), _.tail(value).join("")].join("");
		else this.Template[id] = value;
	}

	/**
	 * Сбор данных с формы шаблона для отправки на сервер
	 * @returns {object} данные шаблона
	 */
	getSaveData() {
		var data = this.Template,
			index = 0;
			
		data = _.omit(data, ["Fields", "AutoRequisites"]);
		
		_.each(this.Fields, function (v) {
			var name = "Fields." + index + ".Value";

			if (v.Value !== "") {
				data[name] = v.Value;
				index++;
			}
		});

		data.PrintFormat = arrayToBitString(data.PrintFormat, 5);

		return data;
	}

	/**
	 * Проверка корректности заполнения данных шаблона чека
	 * @returns {boolean} флаг корректности данных
	 */
	validateData () {
		var isValid = true;

		this.ErrorCode = "";
		this.SuccessSave = false;

		if (this.Template.POSId === -1 || this.Template.POSId === "") {
			this.ErrorCode = "ORDER_POS_REQUIRED";
			return false;
		}

		if (_.isEmpty(this.Template.PrintFormat)) {
			this.ErrorCode = "PRINT_FORMAT_REQUIRED";
			return false;
		}

		return isValid;
	}

	/** Получение реквизитов из настроек ЮЛ
	 * @return {string} реквизиты ЮЛ
	 */
	get requisites () {
		if (_.isEmpty(this.Entities) || this.Template.POSId === -1) return "";

		var posId = this.Template.POSId,
			entityId = _.find(this.POS, { Id: posId }) ? _.find(this.POS, { Id: posId }).EntityId : -1,
			entity = _.find(this.Entities, { Id: entityId });

		return ((entity.INN !== "" ? ("ИНН " + entity.INN) : "") + (entity.OGRN !== "" ? (" ОГРН " + entity.OGRN) : ""));
	}

	/** Получение данных бренда
	 * @return {object} бренд
	 */
	get brand () {
		if (_.isEmpty(this.POS) || _.isEmpty(this.Brands) || this.Template.POSId === -1) 
			return { Name : "", BigLogo : "" };


		var posId = this.Template.POSId,
			brandId = _.find(this.POS, { Id: posId }) ? _.find(this.POS, { Id: posId }).Brands[0] : -1;
		
		return _.find(this.Brands, { Id: brandId });
	}

	/** Генератор образца чека
	 * @return {object} данные готового чека
	 */
	get generateBill () {
		if (_.isUndefined(this.Template)) return undefined;
		
		return _.extend(_.clone(this.BillDummy), { 
			Brand : this.brand ? this.brand.Name : "",
			BrandBigLogo : this.brand ? this.brand.BigLogo: "",

			Requisites : this.Template.Requisites,
			Manufacturer : this.Template.ShowComposition ? this.Template.Manufacturer : "",
			LegalAddress : this.Template.ShowComposition ? this.Template.LegalAddress : "",
			Fields : _.map(this.Fields, "Value"), 
			FinalText : this.Template.FinalText,
			FontSize : this.Template.FontSize,
			FontSizeComposition : this.Template.FontSizeComposition,
			TextAlign : this.Template.TextAlign,

			User : this.Template.ShowStaff ? authUserState.User.Name : "",
			DescriptionAdmin : this.Template.ShowDescription ? this.BillDummy.DescriptionAdmin : "",
			OrderTime : this.Template.ShowOrderTime ? this.BillDummy.OrderTime : "",
			Date : this.Template.ShowPrintTime ? moment().format("YYYY-MM-DD HH:mm") : "",
			ProductionDate : this.Template.ShowComposition ? moment().format("YYYY-MM-DD HH:mm") : "",
			Customer : {
				Login : this.Template.ShowCustomerName ? this.BillDummy.Customer.Login : "",
				Name : this.Template.ShowCustomerName ? this.BillDummy.Customer.Name : "",
				Address : this.Template.ShowCustomerAddress ? this.BillDummy.Customer.Address : ""
			}
		})
	}
}
decorate(BillTemplateState, {
	Templates: observable,
	IsSendRequest: observable,

	Template: observable,
	Brands: observable,
	Fields: observable,

	BillState: observable,
	ButtonLoading: observable,
	ErrorCode: observable,
	SuccessSave: observable,

	generateBill: computed,
	requisites: computed
});
export const billTemplateState = new BillTemplateState();