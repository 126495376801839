import { observable, decorate } from 'mobx';
import _ from 'lodash';
import { authUserState } from './common';

class MerchantState {
	MerchantDummy = {
		Id : -1,
		Active : true,
		MerchantType : 0,
		Name : "",
		Currency : -1,
		OrderNumberType : false,
		RoundOrder : false,
		MainEntity : -1,
		ExpDate : 0,
		OneTypeBonus : false,
		BonusMaxDiscount : 0,
		IPList : "",
		PaymentTipsPreset : [],
		GuestCommission : 0,
		PaymentTipsType : 0
	}
	AggregatorsDummy = { Id : -1, Name : "" }
	EntitiesDummy = { Id : -1, Name : "", INN : "", OGRN : "" }
	PaymentMethodsDummy = { Id : -1, Name : "", Fiscal : false, ShowCourier: false, Default : false }
	BrandsDummy = {
		Id : -1,
		Name : "",
		BigLogo : null,
		SmallLogo : null,
		BigLogoUrl : "",
		SmallLogoUrl : "",
		BackGroundColor : "",
		Description : "",
	}

	BGColor = [
		{ Id : "FFFFFF", Name : "Светлый" },
		{ Id : "343844", Name : "Темный" },
	]

	ActiveTab = "common";

	/**
	 * Разбор ответа от сервера с данными предприятия
	 * @param {object} data  данные предприятия
	 */
	setMerchant (data) {
		this.Merchant = _.isUndefined(data) || !data.Success ? undefined : 
			_.extend(_.omit(_.clone(data), ["Success", "ErrorCode", "Aggregators", "Brands", "PaymentMethods", "Entities"]), {
				PaymentTipsPreset : data.PaymentTipsPreset === "" ? [] : data.PaymentTipsPreset.split(",")
			});
		
		if (!authUserState.isCallCentre && (authUserState.HasERP || authUserState.HasTips)) 
			this.Brands = _.isUndefined(data) || !data.Success || _.isNull(data.Brands) || 
				_.isEmpty(data.Brands) ? [_.clone(this.BrandsDummy)] : _.extend(data.Brands);
		else this.Brands = [];
		if (!authUserState.isCallCentre && (authUserState.HasERP || authUserState.HasTips)) 
			this.Entities = _.isUndefined(data) || !data.Success || _.isNull(data.Entities) || _.isEmpty(data.Entities) ? 
				[_.extend(_.clone(this.EntitiesDummy), { Main : true })] : data.Entities;
			
			if (!_.isUndefined(data) && !_.isEmpty(this.Entities)) 
				this.Merchant.MainEntity = _.findIndex(this.Entities, { Main : true });
		else this.Entities = [];
		if (!authUserState.isCallCentre && authUserState.HasERP)
			this.Aggregators = _.isUndefined(data) || !data.Success || _.isNull(data.Aggregators) ? [] 
				: data.Aggregators;
		else this.Aggregators = [];
		if (!authUserState.isCallCentre && authUserState.HasERP)
			this.PaymentMethods = _.isUndefined(data) || !data.Success || _.isNull(data.PaymentMethods) ? [] 
				: data.PaymentMethods;
		else this.PaymentMethods = [];

		this.IsSendRequest = true;
		this.ErrorCode = "";
		this.ButtonLoading = false;
		this.SuccessSave = false;
		this.Counter = 0;
	}

	/**
	 * Сбор данных с формы настроек предприятия
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	collectMerchant (id, value) {
		if (/OrderNumberType/.test(id)) this.Merchant.OrderNumberType = value;
		else if (/RoundOrder/.test(id)) this.Merchant.RoundOrder = value;
		else if (/OneTypeBonus/.test(id)) this.Merchant.OneTypeBonus = value;
		else this.Merchant[id] = value;
	}
	/**
	 * Сбор данных с формы настроек агрегаторов
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 * @param {number} index порядковый номер
	 */
	collectAggregators (id, value, index) { this.Aggregators[index][id] = value; }
	/**
	 * Сбор данных с формы настроек брендов
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 * @param {number} index порядковый номер
	 */
	collectBrands (id, value, index) { 
		if (/BackGroundColor/.test(id)) this.Brands[index].BackGroundColor = value;
		else this.Brands[index][id] = value; 
	}
	/**
	 * Сбор данных с формы настроек методов оплаты
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 * @param {number} index порядковый номер
	 */
	collectPaymentMethods (id, value, index) {
		if (/Fiscal/.test(id)) this.PaymentMethods[index].Fiscal = value;
		else if (/ShowCourier/.test(id)) this.PaymentMethods[index].ShowCourier = value;
		else this.PaymentMethods[index][id] = value;
	}
	/**
	 * Сбор данных с формы настроек ЮЛ
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 * @param {number} index порядковый номер
	 */
	collectEntities (id, value, index) {
		if (/Main/.test(id)) this.Merchant.MainEntity = value ? index : -1;
		else this.Entities[index][id] = value;
	}

	/** Сбор данных для отправки запроса сохранения предприятия
	 * @returns {object} данные предприятия
	 */
	getSaveData() {
		var store = this,
			data = _.clone(this.Merchant),
			index = 0;

		if (authUserState.HasTips && data.PaymentTipsType !== 0) {

			data.PaymentTipsPreset = !_.isEmpty(data.PaymentTipsPreset) ? data.PaymentTipsPreset.join(",") : "";
		} else data = _.omit(data, "PaymentTipsPreset");

		_.each(this.Aggregators, function (v, i) {
			var name = "Aggregators." + i + ".";

			data[name + "Id"] = v.Id;
			data[name + "Name"] = v.Name;
		});

		_.each(this.PaymentMethods, function (v, i) {
			var name = "PaymentMethods." + index + ".";

			if (!v.Default) {
				data[name + "Id"] = v.Id;
				data[name + "Name"] = v.Name;
				data[name + "Fiscal"] = v.Fiscal;
				data[name + "ShowCourier"] = v.ShowCourier;
				index++;
			}
		});

		_.each(this.Entities, function (v, i) {
			var name = "Entities." + i + ".";

			data[name + "Id"] = v.Id;
			data[name + "Name"] = v.Name;
			data[name + "Main"] = store.Merchant.MainEntity === i;
			data[name + "INN"] = v.INN ? (v.INN).toString().replace(/\D/g, "") : "";
			data[name + "OGRN"] = v.OGRN ? (v.OGRN).toString().replace(/\D/g, "") : "";
		});

		_.each(this.Brands, function (v, i) {
			var name = "Brands." + i + ".";

			data[name + "Id"] = v.Id;
			data[name + "Name"] = v.Name;
			data[name + "BigLogo"] = _.isObject(v.BigLogo) ? v.BigLogo.files : v.BigLogo === -1 ? -1 : null;
			data[name + "SmallLogo"] = _.isObject(v.SmallLogo) ? v.SmallLogo.files : v.SmallLogo === -1 ? -1 : null;
			data[name + "BackGroundColor"] = v.BackGroundColor;
			data[name + "Description"] = v.Description;
		});

		data = _.omit(data, ["MainEntity", "Active"]);

		return data;
	}

	/** Проверка корректности введенных данных
	 * 1. Проверка на заполненность валюты
	 * 2. Проверка на заполненность хотя бы одного юр.лица
	 * 3. Проверка что выбрано основное юр.лицо
	 * 4. Проверка корректности заполненности ИНН и ОГРН
	 * 5. Если есть чаевые и выбраны пресеты — пресеты должны быть заполнены
	 * @returns {boolean} флаг корректности данных
	 */
	validateData() {
		var store = this,
			isValid = true;

		this.ErrorCode = "";
		this.SuccessSave = false;

		if (this.Merchant.Currency === -1 || this.Merchant.Currency === "") {
			this.ErrorCode = "CURRENCY_REQUIRED";
			return false;
		}

		if (!authUserState.isCallCentre && (authUserState.HasERP || authUserState.HasTips) && _.isEmpty(this.Entities)) {
			this.ErrorCode = "ENTITY_REQUIRED";
			return false;
		}

		if (!authUserState.isCallCentre && (authUserState.HasERP || authUserState.HasTips) && !_.isEmpty(this.Entities) && this.Merchant.MainEntity === -1) {
			this.ErrorCode = "MAIN_ENTITY_REQUIRED";
			return false;
		}

		if (!_.isEmpty(this.Entities)) _.each(this.Entities, function (entity) {
			var inn = entity.INN,
				ogrn = entity.OGRN;

			if (inn && inn !== "" && (inn).replace(/\D/g, "").length !== 10 && (inn).replace(/\D/g, "").length !== 12) {
				store.ErrorCode = "WRONG_PARAMS_INN";
				isValid = false;
				return false;
			}
			if (ogrn && ogrn !== "" && (ogrn).replace(/\D/g, "").length !== 13) {
				store.ErrorCode = "WRONG_PARAMS_OGRN";
				isValid = false;
				return false;
			}
		});

		if (authUserState.HasTips && this.Merchant.PaymentTipsType > 0 && _.isEmpty(this.Merchant.PaymentTipsPreset)) {
			this.ErrorCode = "TIPS_PRESET_REQUIRED";
			return false;
		}

		return isValid;
	}
}

decorate(MerchantState, {
	IsSendRequest: observable,	// Флаг отправки запроса
	ErrorCode: observable,		// Код ошибки
	ButtonLoading: observable,	// Состояние кнопки сохранить
	SuccessSave: observable,	// Флаг успешного сохранения
	ActiveTab: observable,		// Активная вкладка
	
	Merchant: observable,		// Информация о предприятии

	Aggregators: observable,	// Список агрегаторов
	PaymentMethods: observable,	// Список методов оплаты
	Brands: observable,			// Список брендов
	Entities: observable,		// Список юр лиц
});
export const merchantState = new MerchantState();