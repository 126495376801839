import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _, { replace } from 'lodash';
import Parser from 'html-react-parser';
import moment from 'moment';

import {RadialChart, XYPlot, XAxis, YAxis, HorizontalGridLines, VerticalBarSeries } from 'react-vis';
import '../../node_modules/react-vis/dist/style.css';

import { AddressSuggestions } from 'react-dadata';
import 'react-dadata/dist/react-dadata.css';

import { TextInput, DateInput, SubmitButton, Selectize, CheckGroup } from '../forms/FormItems';
import { ItemHeader, TableReportPage } from './PageTemplates';

import { buttonTitles, dashboardsTitles, dadataKey, errors } from '../dictionary';
import { checkRights, getRequest, getRubs, formatDate, toSelectList, toCheckList, parsePhoneMask, 
	addressToPatternString } from './../functions';

import { customerState } from '../stores/customers';
import { OrderTableItem } from './Orders';

export class CustomersPage extends Component {
	constructor(props) {
		super(props);

		customerState.setDefault();

		this.state = {};

		this.getData();

		var model = this;
		getRequest("Brands", { AllMerchants : true }, function (data) { 
			model.setState({ Brands : data.Success ? data.Brands : [] }); });
	}

	/** API запрос на получение списка клиентов */
	getData() {
		customerState.IsSendRequest = false;
		getRequest("Customers", customerState.getFilter(), function(data) { customerState.setList(data); });
	}

	/** API запрос на получение списка клиентов 
	 * @param {string} id логин (номер телефона) клиента 
	*/
	getItem(id) {
		getRequest("Customer", { Login : id }, function(data) { 
			customerState.setItem(data.Success ? data : undefined); });
	}

	/** Получение пользователя
	 * @param {string} id логин (номер телефона) клиента 
	*/
	setId(id) {
		if (!_.isUndefined(id)) {
			if (id !== -1) this.getItem(id);
			else customerState.setItem({ Id: -1 });
		} else {
			customerState.setItem(undefined);
			this.getData();
		}
	}

	render() {
		return (
			<div className="page" id="CustomersPage">
				{_.isUndefined(customerState.Customer) && 
					<div className="head clearfix"><h2>{dashboardsTitles[this.props.Type]}</h2></div>
				}
				{_.isUndefined(customerState.Customer) && 
					<CustomerFilter getData={this.getData.bind(this)} />
				}
				{_.isUndefined(customerState.Customer) && customerState.IsSendRequest &&
					<CustomerTable Type="Customers" setId={this.setId.bind(this)} getData={this.getData.bind(this)} />
				}
				{!_.isUndefined(customerState.Customer) && 
					<CustomerItem setId={this.setId.bind(this)} Brands={this.state.Brands} />
				}
				{!_.isUndefined(customerState.AddBlackList) && 
					<ToggleBlackList getItem={this.getItem.bind(this)} />
				}
			</div>
		)
	}
}
CustomersPage = observer(CustomersPage);

class CustomerFilter extends Component {
	/**
	 * Сбор данных с формы поиска
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange(value, id) { customerState.collectFilter(id, value); }

	/** Отправка запроса на поиск с фильтром */
	handleSubmit(event) {
		event.preventDefault();
		this.props.getData();
	}

	render() {
		var blackList = [{ Id : "true", Name : "Только в черном списке" }, { Id : "false", Name : "Только не в черном списке" }];

		return(
			<form className="clearfix filter" onSubmit={this.handleSubmit.bind(this)}>
				<div className="col col80">
					<TextInput 
						Id="Login"
						Mask="7 (999) 999-99-99"
						Title="Логин"
						RowClass="col col1_3"
						Value={customerState.Filter.Login}
						onChange={this.handleChange.bind(this)}
					/>
					<Selectize
						Id="BlackList"
						List={toSelectList(blackList)}
						Title="Черный список"
						RowClass="col col1_3"
						Value={customerState.Filter.BlackList}
						onChange={this.handleChange.bind(this)}
					/>
				</div>
				<SubmitButton Title="Найти" Loading={false} />
			</form>
		)
	}
}
CustomerFilter = observer(CustomerFilter);
class CustomerTable extends Component {
	/**
	 * ЗАпрос на следующую страницу поиска
	 * @param {number} offset 
	 */
	changePage (offset) { 
		customerState.Filter.Offset = offset;
		this.props.getData();
	}

	render() {
		var columns = {
			CreateDate: { width: 100 }, Login: { width: 120 },
			Name: { Header: "Имя" }, Email: {}, BirthDate: {}, 
			OrderCount: {}, OrdersAmount: {}, LastOrder: {},
			CommonPhone : {}, BlackList: {},
		};

		if (checkRights("ManageCustomers")) _.extend(columns, { Edit: {} });
		else _.extend(columns, { View: {} });

		return (
			<div className="clearfix table">
				{!_.isEmpty(customerState.Customers) &&
					<TableReportPage
						TableData={customerState.Customers}
						TableColumns={columns}
						Type={this.props.Type}

						Pagination={true}Limit={customerState.Limit}
						PaginationType="number"
						HasMore={customerState.HasMore}
						Offset={customerState.Filter.Offset}
						Total={customerState.Total}
						
						changePage={this.changePage.bind(this)}
						editRow={this.props.setId}
					/>
				}
				{_.isEmpty(customerState.Customers) && <p>{errors.EMPTY_RESPONSE}</p>}
			</div>
		)
	}
}
CustomerTable = observer(CustomerTable);

class CustomerItem extends Component {
	constructor(props) {
		super(props);

		if (customerState.Customer.Id !== -1) this.getOrders(customerState.Customer.Id);
	}
	
	/**
	 * API запрос на получение заказов пользователя по ID пользователя
	 * @param {number} id ID пользователя
	 */
	getOrders (id) {
		getRequest("Orders", { CustomerId : id, IsHome: -1 }, function (data) {
			customerState.Orders = data.Success ? _.map(data.Orders, function(item) {
				var create = item.CreationTime === "" ? "" : moment(item.CreationTime),
					lead = item.LeadTime === "" ? "" : moment(item.LeadTime),
					complete = lead === "" || create === "" ? null : lead.diff(create, "minutes");

				return _.extend(item, { CompleteMinutes : complete })
			}) : [];
		});
	}

	/** API запрос на сохранение данных клиента */
	handleSubmit(event) {
		event.preventDefault();

		if (!customerState.validateData()) return false;

		customerState.ButtonLoading = true;

		getRequest("SaveCustomer", customerState.getSaveData(), function (data) {
			customerState.ErrorCode = data.Success ? "SUCCESS_SAVE" : data.ErrorCode;
			customerState.SuccessSave = data.Success;
			customerState.ButtonLoading = false;
		});
	}

	/** Закрытие карточки клиента */
	handleClose(event) {
		event.preventDefault();
		this.props.setId(undefined);
	}

	/** Смена активных вкладок */
	toggleTabs (event) {
		event.preventDefault();
		customerState.TabState = replace(event.currentTarget.getAttribute("href"), "#", "");
	}

	render() {
		var canViewStat = checkRights("ViewCustomerStat"),
			canViewHistory = checkRights("ViewCustomerHistory");

		return (
			<section className="clearfix item  two-cols customerItem" id="CustomerItem">
				<form onSubmit={this.handleSubmit.bind(this)}>
					<ItemHeader
						Title={buttonTitles[(customerState.Customer.Id === -1 ? "New" : "Edit") + "Customer"]}
						Error={customerState.ErrorCode}
						Loading={customerState.ButtonLoading}
						CanManage={checkRights("ManageCustomers")}
						Success={customerState.SuccessSave}
						handleClose={this.handleClose.bind(this)}
					/>
					<div className="clearfix section">
						<div className="clearfix">
							<div className="col col75">
								<div className="clearfix tabsNav">
									<a href="#info" className={customerState.TabState === "info" ? "active" : ""} 
										onClick={this.toggleTabs.bind(this)}>Информация</a>
									{canViewStat &&
										<a href="#stat" className={customerState.TabState === "stat" ? "active" : ""} 
											onClick={this.toggleTabs.bind(this)}>Статистика</a>
									}
									{canViewHistory &&
										<a href="#orders" className={customerState.TabState === "orders" ? "active" : ""} 
											onClick={this.toggleTabs.bind(this)}>Заказы</a>
									}
								</div>
								<div className="clearfix tabsContent">
									{customerState.TabState === "info" &&
										<div className="clearfix tab">
											<MainCustomerInfo 
												Info={customerState.Customer} 
												Type={checkRights("ManageCustomers") ? "edit" : "view"}
												BlockClass="clearfix block"
											/>
											{!customerState.Customer.CommonPhone &&
												<AddressBlock 
													Info={customerState.Address} 
													Type={checkRights("ManageCustomers") ? "edit" : "view"}
													BlockClass="clearfix block"
													collectAddress={customerState.collectAddress.bind(customerState)}
													setAddress={customerState.setAddress.bind(customerState)}
												/>
											}
										</div>
									}
									{customerState.TabState === "stat" &&
										<div className="clearfix tab">
											{!_.isEmpty(customerState.ordersStat) && 
												<CustomerOrderStat Stat={customerState.ordersStat} Count={6} />
											}
											{!_.isEmpty(customerState.brands) &&
												<CustomerBrandsStat 
													Brands={customerState.brands} 
													BrandsChart={customerState.brandsChart}
												/>
											}
										</div>
									}
									{customerState.TabState === "orders" &&
										<div className="clearfix tab">
											{!_.isEmpty(customerState.Orders) && 
												<CustomerOrderHistory History={customerState.Orders}
													CanRepeatOrder={false} />
											}
										</div>
									}
								</div>
							</div>
							<div className="col col25 gray">
								{!_.isEmpty(customerState.commonStat) && 
									<CustomerStatCommon Stat={customerState.commonStat} BlockClass="block" />
								}
								{!_.isEmpty(customerState.blackList) && 
									<CustomerBlackList BlockClass="block" Info={customerState.blackList} />
								}
							</div>
						</div>
						
					</div>
				</form>
			</section>
		)
	}
}
CustomerItem = observer(CustomerItem);

export class MainCustomerInfo extends Component {
	/**
	 * Сбор данных с формы клиента
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange(value, id) { if (this.props.Type === "edit") customerState.collectCustomer(id, value); }

	render() {
		var info = this.props.Info,
			commonList = [{ Id : "true", Name : "Единый номер телефона"}];

		if (this.props.Type === "view") return(
			<div className={"main " + (this.props.BlockClass ? this.props.BlockClass : "")}>
				<div className="clearfix">
					<h5 className="col col40">Телефон</h5>
					<p className="col col60">
						{parsePhoneMask(info.Login, "Россия")}
						{info.CommonPhone &&
							<i className="icon common" title="Единый номер телефона"></i>
						}
					</p>
					<h5 className="col col40">Имя</h5>
					<p className="col col60">{info.Name}</p>
					{info.Email && info.Email !== "" && <h5 className="col col40">Email</h5>}
					{info.Email && info.Email !== "" && <p className="col col60">{info.Email}</p>}
					{info.BirthDate && info.BirthDate !== "" && <h5 className="col col40">День рождения</h5>}
					{info.BirthDate && info.BirthDate !== "" && <p className="col col60">{formatDate(info.BirthDate)}</p>}
				</div>
				{info.CustomerInfo && info.CustomerInfo !== "" && 
					<div className="clearfix">
						<h5>Информация о клиенте</h5>
						<p>{info.CustomerInfo}</p>
					</div>
				}
			</div>
		)

		return (
			<div className={"main " + (this.props.BlockClass ? this.props.BlockClass : "")}>
				<TextInput
					Id="Login"
					Title="Логин"
					Value={this.props.Info.Login}
					Disabled={true}
					RowClass="col col50"
					Mask="7 (999) 999-99-99"
					onChange={this.handleChange.bind(this)}
				/>
				<TextInput
					Id="Email"
					Title="E-mail"
					Type="email"
					Value={this.props.Info.Email}
					RowClass="col col50"
					onChange={this.handleChange.bind(this)}
				/>
				<TextInput
					Id="Name"
					Title="Имя пользователя"
					Value={this.props.Info.Name}
					Required={true}
					RowClass="col col50"
					onChange={this.handleChange.bind(this)}
				/>
				<DateInput
					Id="BirthDate"
					Title="День рождения"
					Value={this.props.Info.BirthDate}
					DropYear={true}
					RowClass="col col50"
					onChange={this.handleChange.bind(this)}
				/>
				<CheckGroup
					Id="CommonPhone"
					Type="checkbox"
					List={toCheckList(commonList)}
					Value={this.props.Info.CommonPhone ? ["true"] : []}
					RowClass="col col50 nomargin"
					onChange={this.handleChange.bind(this)}
				/>
				<TextInput
					Id="CustomerInfo"
					Title="Информация о клиенте"
					Type="textarea"
					Value={this.props.Info.CustomerInfo}
					RowClass="col col100"
					onChange={this.handleChange.bind(this)}
				/>
			</div>
		)
	}
}
MainCustomerInfo = observer(MainCustomerInfo);

export class AddressBlock extends Component {
	/** Добавление нового адреса */
	handleAdd(event) {
		event.preventDefault();
		customerState.Address = _.concat(customerState.Address, _.clone(customerState.AddressDummy));
	}

	/**
	 * Выбор адреса из списка
	 * @param {number} value ID адреса пользователя
	 */
	handleSelect(value) { this.props.selectAddress(value); }

	render() {
		var view = this,
			isJustNew = this.props.Info.length === 1 && this.props.Type === "order" && this.props.Info[0].Id === -1,
			pattern = "%%CC%{ %%ss%}, %%SS%{, дом %%HH%} {%%HB%}",
			addressList = _.map(this.props.Info, function(address) {
				return {
					Id : address.Id,
					Name : address.Id === -1 ? "Новый адрес" : addressToPatternString(address, pattern)
				}
			});

		return(
			<div className={"addressBlock " + (this.props.BlockClass ? this.props.BlockClass : "")}>
				{this.props.Type !== "order" && <h4>Адреса клиента</h4>}
				{this.props.Type !== "order" &&
					_.map(this.props.Info, function(address, i) {
						return(<AddressItem 
							key={view.props.Types === "edit" ? customerState.aCounter++ : i}
							Item={address} Index={i} Type={view.props.Type}
							collectAddress={view.props.collectAddress}	
							setAddress={view.props.setAddress}	
						/>)
					})
				}
				{this.props.Type === "order" && !isJustNew &&
					<Selectize
						Id="Id"
						Title="На какой адрес вы хотите оформить доставку?"
						List={toSelectList(addressList)}
						Value={this.props.AddressId}
						Disabled={this.props.Disabled}
						isSearchable={false}
						isClearable={false}
						onChange={this.props.selectAddress}
					/>
				}
				{this.props.Type === "order" && this.props.Address && 
					<AddressItem 
						Item={this.props.Address} Index={0} 
						Disabled={this.props.Disabled}
						Type={this.props.Type} 
						City={this.props.City}

						collectAddress={this.props.collectAddress}	
						setAddress={this.props.setAddress}	
					/>
				}
				{this.props.Type === "edit" &&
					<a href="/" className="button icon add" onClick={this.handleAdd.bind(this)}>
						<i></i>Добавить адрес</a>
				}
			</div>
		)
	}
}
AddressBlock = observer(AddressBlock);

class AddressItem extends Component {
	constructor(props) {
		super(props);

		this.state = { FullAddress : false };
	}

	/**
	 * Сбор данных с формы адреса
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange(value, id) { 
		if (this.props.collectAddress) this.props.collectAddress(id, value, this.props.Index); 
	}

	/**
	 * Разбор подсказки DaData
	 * @param {object} value ответ от сервиса DaData
	 */
	handleSuggest(value) {
		var data = value.data;

		if (_.isNull(data.city) || _.isNull(data.street) || _.isNull(data.house))
			this.setState({ FullAddress : true });

		if (this.props.setAddress) this.props.setAddress(data, this.props.Index);
	}

	/** Показать/скрыть разобранный по полям адрес */
	toggleAddress(event) {
		event.preventDefault();
		this.setState({ FullAddress : !this.state.FullAddress });
	}

	/** Удалить адрес */
	handleDelete(event) {
		event.preventDefault();

		var index = this.props.Index;
		customerState.Address = _.filter(customerState.Address, function(v,i) { return i !== index; });
	}

	render() {
		var mainList = [{ Id : "true", Name : "Основной адрес" }],
			item = this.props.Item,
			city = this.props.City,
			pattern = "%%CC%{ %%ss%}, %%SS%, дом %%HH% {%%HB%}{, кв %%FF%}{, подъезд %%ee%}{, этаж %%ff%}{, домофон %%ii%}",
			addressString = addressToPatternString(item, pattern),
			address = _.extend(_.clone(item), { City : item.Id === -1 ? city : item.City }),
			addressDadata = addressToPatternString(address, "%%CC%{ %%ss%}, %%SS%{, дом %%HH%} {%%HB%}{, кв %%FF%}");

		if (this.props.Type === "view") return(this.props.Item.Id === -1 ? null : 
			<div className="clearfix address">
				<p><b>{this.props.Item.Main ? "ОСНОВНОЙ: " : ""}</b> {addressString}</p>
			</div>
		)

		return(
			<div className="clearfix address">
				<div className="dadata clearfix">
					<label className="focused">Введите адрес — город/район, деревню/СНТ, улицу, дом и квартиру</label>
					<AddressSuggestions 
						key={this.props.Item.Id + "_" + this.props.City}
						token={dadataKey.test}
						defaultQuery={addressDadata} 
						onChange={this.handleSuggest.bind(this)} 
					/>
					<a href="/" className={"toggle " + (this.state.FullAddress ? "up" : "down")}
						onClick={this.toggleAddress.bind(this)}><i></i></a>
				</div>
				{this.props.Type === "edit" &&
					<CheckGroup
						Id="Main"
						Type="checkbox"
						List={toCheckList(mainList)}
						Value={customerState.MainAddress === this.props.Index ? ["true"] : []}
						RowClass="nomargin"
						Disabled={this.props.Disabled}
						onChange={this.handleChange.bind(this)}
					/>
				}
				{this.state.FullAddress &&
					<div className="fullAddress clearfix">
						<p className="comment">
							Данные поля предназначены для уточнее разбора адреса клиента. Если какая то Информация
							отображена некорректно, исправьте ее в полях ниже.
						</p>
						<TextInput
							Id="City"
							Title="Город"
							Value={this.props.Item.City}
							RowClass="col col1_2"
							Disabled={this.props.Disabled}
							onChange={this.handleChange.bind(this)}
						/>
						<TextInput
							Id="Settlement"
							Title="Деревня/СНТ"
							Value={this.props.Item.Settlement}
							RowClass="col col1_2"
							Disabled={this.props.Disabled}
							onChange={this.handleChange.bind(this)}
						/>
						<TextInput
							Id="Street"
							Title="Улица"
							RowClass="col col100"
							Value={this.props.Item.Street}
							Disabled={this.props.Disabled}
							onChange={this.handleChange.bind(this)}
						/>
						<TextInput
							Id="House"
							Title="Дом"
							RowClass="col col1_3"
							Value={this.props.Item.House}
							Disabled={this.props.Disabled}
							onChange={this.handleChange.bind(this)}
						/>
						<TextInput
							Id="HouseBuilding"
							Title="Строение"
							Value={this.props.Item.HouseBuilding}
							RowClass="col col1_3"
							Disabled={this.props.Disabled}
							onChange={this.handleChange.bind(this)}
						/>
						<TextInput
							Id="Flat"
							Title="Квартира"
							Value={this.props.Item.Flat}
							RowClass="col col1_3"
							Disabled={this.props.Disabled}
							onChange={this.handleChange.bind(this)}
						/>
					</div>
				}
				<div className="clearfix">
					<TextInput
						Id="Entrance"
						Title="Подъезд"
						Value={this.props.Item.Entrance === 0 ? "" : this.props.Item.Entrance}
						RowClass="col col1_3"
						Disabled={this.props.Disabled}
						onChange={this.handleChange.bind(this)}
					/>
					<TextInput
						Id="Floor"
						Title="Этаж"
						Type="number"
						Value={this.props.Item.Floor === 0 ? "" : this.props.Item.Floor}
						RowClass="col col1_3"
						Disabled={this.props.Disabled}
						onChange={this.handleChange.bind(this)}
					/>

					<TextInput
						Id="Intercom"
						Title="Домофон"
						Value={this.props.Item.Intercom}
						RowClass="col col1_3"
						Disabled={this.props.Disabled}
						onChange={this.handleChange.bind(this)}
					/>
				</div>
				{this.props.Type === "edit" &&
					<a className="button remove" href="/" role="button" title={buttonTitles.RemovePOS}
						onClick={this.handleDelete.bind(this)}><i></i></a>
				}
			</div>
		)
	}
}
AddressItem = observer(AddressItem)

export class CustomerStatCommon extends Component {
	render() {
		return(
			<div className={"clearfix stat " + (this.props.BlockClass ? this.props.BlockClass : "")}>
				<div className="clearfix item">
					<h4 className="col col50">Кол-во заказов</h4>
					<p className="number col col50">{this.props.Stat.Count}</p>
				</div>
				<div className="clearfix item">
					<h4 className="col col50">Общая сумма заказов</h4>
					<p className="number col col50">
						{Parser(getRubs(this.props.Stat.Amount, true, true, "&nbsp;", null, true))} <span>руб.</span>
					</p>
				</div>
				<div className="clearfix item">
					<h4 className="col col50">Средний чек</h4>
					<p className="number col col50">
						{Parser(getRubs(this.props.Stat.Avg, true, true, "&nbsp;", null, true))} <span>руб.</span>
					</p>
				</div>
				<div className="clearfix item">
					<h4 className="col col50">Последний заказ</h4>
					<p className="number col col50">{formatDate(this.props.Stat.Last)}</p>
				</div>
			</div>
		)
	}
}
CustomerStatCommon = observer(CustomerStatCommon);

export class CustomerBlackList extends Component {
	/** Добавление/Удаление пользователя в ЧС */
	handleClick(event) {
		event.preventDefault()
		customerState.setBlackList(true);
	}

	render() {
		return(
			<div className={"clearfix blacklist " + (this.props.BlockClass ? this.props.BlockClass : "")}>
				{this.props.Info.BlackList &&
					<div className="clearfix">
						<p>В черном списке с {formatDate(this.props.Info.BlackListDate)}</p>
						<p>ПРИЧИНА: {this.props.Info.BlackListDescription}</p>
					</div>
				}
				{checkRights("ManageCustomers") && checkRights("ManageCustomersBlackList") &&
					<a href="/" className="button icon bl red" onClick={this.handleClick.bind(this)}><i></i>
						{this.props.Info.BlackList ? "Удалить из черного списка" : "Добавить в черный список"}</a>
				}
			</div>
		)
	}
}

export class CustomerOrderHistory extends Component {
	render() {
		var view = this;

		return(
			<div className="clearfix orderHistory">
				<div className="clearfix table ordersTable">
					{_.map(this.props.History, function (item, i) {
						return(<OrderTableItem key={i}
							Item={item}
							Type="customer"
							CanRepeatOrder={view.props.CanRepeatOrder}
						/>)
					})}
				</div>
			</div>
		)
	}
}
CustomerOrderHistory = observer(CustomerOrderHistory);

export class CustomerOrderStat extends Component {
	render() {
		return(
			<div className="clearfix orderStat">
				<h4>Заказы по месяцам</h4>
				<div className="clearfix graph">
					<XYPlot width={600} height={220}  xType="ordinal" stackBy="y" showLabels={true}
							// colorRange={["#97f2e1","#7bd9ef","#95b5fc"]}
							>
						<HorizontalGridLines />
						<XAxis />
						<YAxis />
						{_.map(this.props.Stat, function(item) {
							return(<VerticalBarSeries data={item} />)
						})}
					</XYPlot>
				</div>
			</div>
		)
	}
}
CustomerOrderStat = observer(CustomerOrderStat);

export class CustomerBrandsStat extends Component {
	render() {
		return(<div className="clearfix brands">
				<div className="col col50">
					<h4>Статистика по брендам</h4>
					{_.map(this.props.Brands, function(brand, i) {
						return(<div className="clearfix item" key={i}>
							<span className="col col50">{brand.Brand}</span>
							<span className="col col20">{brand.Count}</span>
							<span className="col col30">{getRubs(brand.Amount, true, true)} руб.</span>
						</div>)
					})}
				</div>
				{!_.isEmpty(this.props.BrandsChart) &&
					<div className="col col50 chart">
						<RadialChart
							data={this.props.BrandsChart}
							width={200}
							height={200}
							innerRadius={70}
							radius={100}
							showLabels={true}
							// colorRange={["#97f2e1","#7bd9ef","#95b5fc"]}
							labelsRadiusMultiplier={0.9}
						/>
					</div>
				}
		</div>)
	}
}

class ToggleBlackList extends Component {
	/**
	 * Сбор данных с формы добавления черного списка
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	handleChange(value, id) { customerState.collectBlackList(id, value); }
	
	/** Закрытие окна подтверждения */
	onCancel(event) {
		event.preventDefault();
		customerState.setBlackList(false);
	}

	/** API запрос на добавление/удаление из черного списка */
	onConfirm(event) {
		event.preventDefault();
		var model = this;
		getRequest("ToggleBlackList", customerState.AddBlackList, function () { 
			customerState.setBlackList(false);
			model.props.getItem(customerState.Customer.Login); 
		});
	}

	render() {
		var isBL = customerState.blackList.BlackList;

		return(
			<div className="back">
				<div className="confirmBlock window">
					<form>
						<h4>{!isBL ? "Добавить в черный список" : "Удалить из черного списка"}</h4>
						{!isBL &&
							<TextInput
								Id="Description"
								Type="textarea"
								Title="Причина добавления в черный список"
								Value={customerState.AddBlackList.Description}
								onChange={this.handleChange.bind(this)}
							/>
						}
						<div className="buttons clearfix">
							<a href="/" onClick={this.onConfirm.bind(this)} className="button confirm">Подтвердить</a>
							<a href="/" onClick={this.onCancel.bind(this)} className="button close">Отменить</a>
						</div>
					</form>
				</div>
			</div>
		)
	}
}
ToggleBlackList = observer(ToggleBlackList);