/**
 * /pages/Content.js – Работа со всеми контент страницами — FAQ, Алгоритмы, Акции, Скрипты, Документы, 
 * 		Новости, Инструкции, Тарифы курьеров
 * /stores/content.js – Хранилище для работы с контетом
 * 
 * Контент страницы бывают 4 типов
 * — Обычные текстовые страницы — право для редактирования «ManageContent»
 * – Маркетинговые страницы «Акций» — право для редактирования «ManageStock»
 * — Документы с возможностью печати — право для редактирования «ManageContent»
 * — Новостные разделы «Новости» — право для редактирования «ManageNews» (не запущено в бой)
 * 
 * Пользователь с правами видит все группы статей и статьи раздела по запросу «GetContent» 
 * 		с указанием ID рабочего стола, к которому привязаны статьи
 * 
 * Пользователь без прав видит только активные статьи, доступные ему либо по филиалу (все),
 * 		либо по должности (новости)
 * 
 * Для справочников вызываются следующие методы один раз при загрузке страницы
 *  - «Roles» — список должностей предприятия для ограничения доступа к новостям
 *  - «Filials» — список филиалов предприятия для ограничения доступа к всем страницам
 *  - «Brands» — список брендов по предприятию для привязки Акций к бренду
 * 
 * Для редактирования названия группы статей, а так же для изменения порядка статей в группе используется
 * 		метод «ChangeOrderContent» с названием группы и перечнем ID статей в нужном порядке
 * 
 * Для изменения порядка групп статей используется метод «GroupOrder» со списком ID групп в нужном порядке
 * 
 * Для изменения тела статьи используется метод ««SaveContent»
 * Изменение активности статьи происходит по методу «ChangeStateContent» с указанием ID статьи
 * Удаление статьи происходит по методу «RemoveContent» с указанием ID статьи
 */

import React, { Component } from 'react';
import { observer } from "mobx-react";
import { autorun } from 'mobx';
import _ from 'lodash';
import cookie from 'react-cookies';

import loader from "../images/loader.svg";

import Parser from 'html-react-parser';
import ReactToPrint from 'react-to-print';

import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';

import { TextInput, Selectize, TextEditor, CheckGroup } from '../forms/FormItems';
import { NavLink } from 'react-router-dom';

import { dashboardsTitles, errors, buttonTitles } from './../dictionary';
import { toSelectList, formatDate, getRequest } from '../functions';

import { authUserState, uistate, confirmState } from '../stores/common';
import { contentState } from '../stores/content';

const DragHandle = SortableHandle(() => <i className="SortHandle"></i>);
let disposer = undefined;

export class ContentPage extends Component {
	constructor(props) {
		super(props);

		contentState.setDefault(this.props.ContentType);
		contentState.ContentType = this.props.ContentType;

		this.state = {}

		var brandParams = authUserState.isCallCentre ? { AllMerchants : true } : {};

		getRequest("Roles", {}, function(data) { 
			contentState.Roles = data.Success && !_.isNull(data.Roles) ? data.Roles : [];
		});
		getRequest("Filials", {}, function(data) { 
			contentState.Filials = data.Success && !_.isNull(data.Filials) ? data.Filials : [];
		});
		getRequest("Brands", brandParams, function(data) { 
			contentState.Brands = data.Success && !_.isNull(data.Brands) ? data.Brands : [];
		});

		if (_.isUndefined(disposer))
			disposer = autorun(() => { 
				var contentIds = [3,4,5,6,8,15,16,17,23,24,65,66,71,72,73,74,79,80,84,85,92,94,149,150,151,152,153,154,155,156,157];
				
				if (_.indexOf(contentIds, authUserState.DashboardId) !== -1) {
					this.getData(); 
					contentState.OpenContent = -1;
					contentState.OpenGroup = -1;
				}
			});
	}

	/** API запрос «GetContent» на получение контента для данной страницы */
	getData() {
		var model = this;
		contentState.IsSendRequest = false;
		getRequest("GetContent", { Id : authUserState.DashboardId }, function(data) {
			contentState.setContent(data, model.props.ContentType, false);
		});
	}

	/**
	 * Сбор поисковых данных с формы
	 * @param {string} value значение поисковой строки
	 */
	searchChange(value) { 
		// contentState.SearchMask = value;
		contentState.getSearching(value); 
	}

	/** Добавление нового раздела в контенте */
	handleAdd (event) {
		event.preventDefault();

		if (/disabled/.exec(event.target.className)) return false;

		contentState.Groups = _.concat(contentState.Groups, _.extend(_.clone(contentState.GroupDummy),{
			Group : [_.clone(contentState.ArticleDummy)]
		}));

		contentState.OpenGroup = contentState.Groups.length - 1;
		contentState.OpenContent = 0;
		contentState.EditGroup = contentState.Groups.length - 1;
		contentState.EditContent = 0;

		contentState.setArticle(_.last(contentState.Groups).Group[0], -1);
	}

	/** Сортировка порядка разделов */
	onSortEnd = ({oldIndex, newIndex}) => {
		contentState.Groups = arrayMove(contentState.Groups, oldIndex, newIndex);
		this.changeOrderGroup();
	};

	/** API запрос «GroupOrder» на изменение порядка разделов */
	changeOrderGroup() {
		var model = this;
		getRequest("GroupOrder", contentState.getOrderGroup(), function() { model.getData(); })
	}

	render() {
		var view = this,
			sectionStyle = { minHeight : uistate.sectionHeight - 5 },
			isEditMode = contentState.EditGroup !== -1 || contentState.EditContent !== -1;

		var SortableItem = SortableElement(({value, sortIndex}) => {
				return(
					<ContentGroup 
						Index={sortIndex}
						Id={value.Id}
						Name={value.Name} 
						Items={value.Group} 
						
						getData={view.getData.bind(view)} 
					/>
				)
			}),
			SortableList = SortableContainer(({items}) => {
				return (
					<div className="SortableList">{items.map((value, index) => (
						<SortableItem key={"ag" + index} index={index} value={value} sortIndex={index} />
					))}</div>
				);
			});

		return (
			<div className={"page " + (uistate.IsMobile ? "mobile" : "")} id="ContentPage">
				<div className="head clearfix">
					<h2>{dashboardsTitles[this.props.Type] || this.props.Type}</h2>
					{contentState.ContentType !== "News" &&
						<form className={"col searchBlock " + (uistate.IsMobile ? "col50" : "col25")}>
							<TextInput 
								Id="Search" 
								Icon="search" 
								Title="Поиск по странице"
								RowClass="nomargin"
								onChange={this.searchChange.bind(this)} />
						</form>
					}
				</div>
				<section className={"clearfix " + contentState.ContentType} style={sectionStyle}>
					{_.isEmpty(contentState.AllContent) && !contentState.CanManage && <p>{errors.EMPTY_RESPONSE}</p>}
					{!_.isEmpty(contentState.AllContent) && _.isEmpty(contentState.Groups) && 
						<p>{errors.EMPTY_RESPONSE}</p>}
					{!_.isEmpty(contentState.Groups) && <SortableList 
						items={contentState.Groups} 
						onSortEnd={this.onSortEnd} 
						useDragHandle   
						helperClass="SortableHelper"
						axis="xy"
						lockToContainerEdges={true}
						disableAutoscroll={true}
					/>}
					{contentState.CanManage && !contentState.EditMode && contentState.ContentType !== "News" && !uistate.IsMobile &&
						<a href="/" className={"button icon add blue" + (isEditMode ? " disabled" : "")}
							onClick={this.handleAdd.bind(this)}><i></i>Добавить раздел</a>}
				</section>
			</div>
		)
	}
}
ContentPage = observer(ContentPage);

class ContentGroup extends Component {
	constructor(props) {
		super(props);
		this.state = {}
	}

	/** Свернуть/развернуть раздел */
	toggleList(event) {
		event.preventDefault();
		contentState.OpenGroup = contentState.OpenGroup === this.props.Index ? -1 : this.props.Index;
		contentState.OpenContent = -1;
		contentState.EditContent = -1;

		event.target.scrollIntoView({ block : "center", behavior : "smooth" });
	}

	/** Изменение состояния группы на редим «редактирования» и обратно */
	changeMode (event) {
		event.preventDefault();
		
		contentState.EditGroup = contentState.EditGroup === this.props.Index ? -1 : this.props.Index;
		contentState.EditContent = -1;
	}

	/** API запрос «ChangeOrderContent» на отправку данных с формы редактирования раздела */
	handleSubmit (event) {
		if (event) event.preventDefault();

		var model = this;
		getRequest("ChangeOrderContent", contentState.getGroupData(this.props.Index), function() {
			model.props.getData(); 
		});
	}

	/** Добавление новой статьи в разделе */
	handleAdd (event) {
		event.preventDefault();

		if (/disabled/.exec(event.target.className)) return false;

		var group = contentState.Groups[this.props.Index];

		contentState.Groups[this.props.Index].Group = _.concat(group.Group, 
			_.extend(_.clone(contentState.ArticleDummy), { Order : group.Group.length }));
		
		contentState.setArticle(_.last(contentState.Groups[this.props.Index].Group), group.Id);
		
		contentState.OpenContent = contentState.Groups[this.props.Index].Group.length - 1;
		contentState.EditContent = contentState.Groups[this.props.Index].Group.length - 1;
		contentState.OpenGroup = this.props.Index;
	}

	/** Сбор данных с формы редактирования раздела
	 * @param {string} value значение параметра «название раздела»
	 */
	onChange (value) { contentState.setGroup(value, this.props.Index); }

	/** Сортировка порядка статей */
	onSortEnd = ({oldIndex, newIndex}) => {
		var i = this.props.Index;
		
		contentState.Groups[i].Group = arrayMove(contentState.Groups[i].Group, oldIndex, newIndex);
		this.handleSubmit();
	};

	render() {
		var view = this,
			canManage = contentState.CanManage,
			isOpen = this.props.Index === contentState.OpenGroup,
			isEdit = this.props.Index === contentState.EditGroup,
			isEditMode = contentState.EditGroup !== -1 || contentState.EditContent !== -1;

		var SortableItem = SortableElement(({value, sortIndex}) => {
				return(
					<ContentItem 
						Item={value}
						Index={sortIndex}
						Group={view.props.Name}
						GroupIndex={view.props.Index}
						EditMode={value.Id === -1} 
						
						getData={view.props.getData} 
					/>
				)
			}),
			SortableList = SortableContainer(({items}) => {
				return (
					<div className="SortableList">{items.map((value, index) => (
						<SortableItem key={"a" + contentState.ArticleCounter++} index={index} value={value} sortIndex={index} />
					))}</div>
				);
			});

		return (
			<div className={"clearfix group " + (isOpen ? "open" : "close")}>
				{contentState.ContentType !== "News"  && 
					<form className="clearfix head" onSubmit={this.handleSubmit.bind(this)}>
						{canManage && !isEdit && !uistate.IsMobile && <DragHandle />}
						<h3 className="clearfix">
							{!isEdit && <a href="/" className="toggleContent" onClick={this.toggleList.bind(this)}>
								{!canManage && <i className="toggleIcon"></i>}
								{this.props.Name}
							</a>}
							{isEdit && <TextInput
								Id="Group"
								Title="Название раздела"
								Value={contentState.GroupNames[this.props.Index]}
								Required={true}
								RowClass={"col " + (uistate.IsMobile ? "col90" : "col60")}
								onChange={this.onChange.bind(this)}
							/>}
							{isEdit && this.props.Id !== -1 && <button>Сохранить</button>}
						</h3>
						<p className="icons">
							{canManage && !isEdit && <a href="/" title="Редактировать" 
								className="icon edit" onClick={this.changeMode.bind(this)}><i></i></a>}
							{isEdit && this.props.Id !== -1 && <a href="/" title="Отменить" 
								className="icon close" onClick={this.changeMode.bind(this)}><i></i></a>}
						</p>
					</form>
				}
				{isOpen && <SortableList 
					items={contentState.Groups[this.props.Index].Group} 
					onSortEnd={this.onSortEnd} 
					useDragHandle   
					helperClass="SortableHelper"
					axis="xy"
					lockToContainerEdges={true}
					disableAutoscroll={true}
				/>}
				{canManage && !uistate.IsMobile &&
					<a className={"button icon add blue" + (isEditMode ? " disabled" : "")} href="/" 
					onClick={this.handleAdd.bind(this)}><i></i>{buttonTitles[contentState.ContentType]}</a>}
			</div>
		)
	}
}
ContentGroup = observer(ContentGroup);

class ContentItem extends Component {
	/** Изменение режима «редактирования» статьи */
	changeMode (event) {
		event.preventDefault();

		if (contentState.EditContent === this.props.Index) {
			contentState.EditContent = -1;
			this.props.getData();

			contentState.setArticle(undefined);
		} else {
			contentState.OpenContent = this.props.Index;
			contentState.EditContent = this.props.Index;

			contentState.EditGroup = -1;

			var groupId = contentState.Groups[this.props.GroupIndex].Id;
			contentState.setArticle(this.props.Item, groupId);
		}
	}

	/** Свернуть/развернуть статью */
	toggleContent(event) {
		event.preventDefault();
		contentState.OpenContent = contentState.OpenContent === this.props.Index ? -1 : this.props.Index;
		
		var elem = event.currentTarget;
		setTimeout(function() { elem.scrollIntoView({ block : "center", behavior : "auto" }); }, 10);
	}

	/** API запрос «ChangeStateContent» на изменение режима видимости статьи (не админу) */
	toggleArticle (event) {
		event.preventDefault();

		var model = this;

		getRequest("ChangeStateContent", { Id: this.props.Item.Id, Active : !this.props.Item.Active }, 
			function () { model.props.getData(); });
	}

	/** API запрос «RemoveContent» на удаление статьи */
	removeId(event) { 
		event.preventDefault();

		var model = this,
			text = <span>Вы действительно хотите удалить статью <b key="b1">«{this.props.Item.Name}»</b>?</span>;

		confirmState.openConfirm(text, function() {
			getRequest("RemoveContent", { Id : model.props.Item.Id }, function() {
				model.props.getData(); });
		});
	}

	/**
	 * Сбор данных с формы редактирования статьи
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	onChange (value, id) { contentState.collectArticle(id, value); }

	/** API запрос «SaveContent» на сохранение изменений статьи */
	handleSubmit (event) {
		event.preventDefault();

		contentState.ButtonLoading = true;

		var model = this;

		getRequest("SaveContent", contentState.getSaveData(), function (data) {
			contentState.ButtonLoading = false;
			contentState.ErrorCode = data.Success ? "" : data.ErrorCode;
			model.setState({ EditMode : !data.Success });

			if (data.Success) model.props.getData();
		});
	}

	render() {
		var isOpen = contentState.OpenGroup === this.props.GroupIndex &&
				contentState.OpenContent === this.props.Index,
			articleClass = "clearfix article" + (!this.props.Item.Active ? " notactive" : ""),
			isEdit = contentState.OpenGroup === this.props.GroupIndex && 
				contentState.EditContent === this.props.Index;
		
		return (
			<div className={"clearfix SortableItem" + (isOpen ? " openContent" : "")}>
				{!isEdit &&
					<ArticleStateMode 
						Item={this.props.Item} 
						
						IsOpen={isOpen}
						ArticleClass={articleClass}

						toggleContent={this.toggleContent.bind(this)}
						changeMode={this.changeMode.bind(this)}
						toggleArticle={this.toggleArticle.bind(this)}
						removeArticle={this.removeId.bind(this)}
					/>
				}
				{isEdit &&
					<ArticleEditMode
						ArticleClass={articleClass + " edit"}

						handleSubmit={this.handleSubmit.bind(this)}
						changeMode={this.changeMode.bind(this)}
					/>
				}
			</div>
		)
	}
}
ContentItem = observer(ContentItem);

export class ArticleStateMode extends Component {
	/**
	 * Форматирование списка доступных должностей или филиалов
	 * @param {string} type филиал или должность
	 * @return {string} форматированная строка с перечнем доступных филиалов/должностей
	 */
	getNamesList(type) {
		return _.isNull(this.props.Item[type]) ? "нет" : _.compact(_.map(this.props.Item[type], function (v) {
					var item = _.find(contentState[type], { Id : v });
					return _.isUndefined(item) ? "" : item.Name;
				})).join(", ")
	}

	render() {
		var date = formatDate(this.props.Item.LastUpdate),
			item = this.props.Item;

		return (
			<div className={this.props.ArticleClass}>
				<div className="clearfix head">
					{contentState.CanManage && !contentState.isNews && !uistate.IsMobile && <DragHandle />}
					<h4 className="clearfix">
						<a href="/" className="toggleContent" onClick={this.props.toggleContent}>
							{(!contentState.CanManage || contentState.isNews) && <i className="toggleIcon"></i>}
							{item.Name}
						</a>
						{contentState.CanManage &&
							<p className="icons">
								{!uistate.IsMobile &&
									<a href="/" title="Редактировать" className="icon edit" 
										onClick={this.props.changeMode}><i></i></a>
								}
								<a href="/" title={item.Active ? "Скрыть" : "Показать"} 
									className={(item.Active ? "de" : "") + "activation icon"}
									onClick={this.props.toggleArticle}><i></i></a>
								<a href="/" title="Удалить" className="icon remove"
									onClick={this.props.removeArticle}><i></i></a>
								{item.CanPrint && contentState.isDocs && !uistate.IsMobile && this.props.IsOpen &&
									<ReactToPrint
										trigger={() => <span title="Распечатать документ" 
														className="icon print"><i></i></span>}
										content={() => this.componentRef}
									/>
								}
							</p>
						}
						{!contentState.CanManage && item.CanPrint && contentState.isDocs && !uistate.IsMobile && this.props.IsOpen &&
							<p className="icons">
								<ReactToPrint
									trigger={() => <span title="Распечатать документ" 
													className="icon print"><i></i></span>}
									content={() => this.componentRef}
								/>
							</p>
						}
					</h4>
					{contentState.isNews &&
						<div className="clearfix"><p className="comment">Новость от {item.UserName}, {date}</p></div>
					}
					{contentState.CanManage &&
						<div className="clearfix">
							{!contentState.isNews &&
								<p className="comment">Последнее изменение: {date} от {item.UserName}</p>
							}
							<p className="comment">Доступно филиалам: {this.getNamesList("Filials")}</p>
							{contentState.isNews && 
								<p className="comment">Доступно должностям: {this.getNamesList("Roles")}</p>}
							{contentState.isStock && item.BrandId > 0 &&
								<p className="comment">Для бренда: {item.Brand}</p>
							}
						</div>
					}
				</div>
				{this.props.IsOpen &&
					<div className="clearfix body">
						<ComponentToPrint ref={el => (this.componentRef = el)} Text={item.Text} />
					</div>
				}
			</div>
		)
	}
}
ArticleStateMode = observer(ArticleStateMode);

class ComponentToPrint extends Component { 
	render() { return (<div className="clearfix">{Parser(this.props.Text)}</div>); } 
}

class ArticleEditMode extends Component {
	/**
	 * Сбор данных с формы редактирования статьи
	 * @param {string} value значение параметра
	 * @param {string} id название параметра
	 */
	onChange (value, id) { contentState.collectArticle(id, value); }

	render() {
		var loading = contentState.ButtonLoading,
			isNews = contentState.ContentType === "News",
			isStock = contentState.ContentType === "Stock",
			isDocs = contentState.ContentType === "Documents";
		
		return(
			<form className={this.props.ArticleClass} onSubmit={this.props.handleSubmit}>
				<div className="clearfix head">
					<h4 className="clearfix">
						<div className="clearfix">
							<TextInput
								Id="Name"
								Title="Название статьи"
								Value={contentState.Article.Name}
								Required={true}
								RowClass="col col60"
								onChange={this.onChange.bind(this)}
							/>
							{isDocs &&
								<CheckGroup
									Id="CanPrint"
									Type="checkbox"
									List={[{ Id : "true", Title : "Можно печатать" }]}
									Value={contentState.Article.CanPrint ? ["true"] : []}
									RowClass="col col40"
									onChange={this.onChange.bind(this)}
								/>
							}
							{isNews &&
								<CheckGroup
									Id="Push"
									Type="checkbox"
									List={[{ Id : "true", Title : "Отправить уведомление" }]}
									Value={contentState.Article.Push ? ["true"] : []}
									RowClass="col col40"
									onChange={this.onChange.bind(this)}
								/>
							}
						</div>
						<div className="clearfix">
							<Selectize
								Id="FilialsId"
								Title="Филиалы"
								Value={contentState.Article.FilialsId}
								List={toSelectList(contentState.Filials)}
								Multi={true}
								RowClass="col col50"
								onChange={this.onChange.bind(this)}
							/>
							{isNews &&
								<Selectize
									Id="RolesId"
									Title="Должности"
									Value={contentState.Article.RolesId}
									List={toSelectList(contentState.Roles)}
									Multi={true}
									RowClass="col col50"
									onChange={this.onChange.bind(this)}
								/>
							}
							{isStock &&
								<Selectize
									Id="BrandId"
									Title="Бренд"
									Value={contentState.Article.BrandId}
									List={toSelectList(contentState.Brands)}
									RowClass="col col50"
									onChange={this.onChange.bind(this)}
								/>
							}
						</div>
						<p className="icons">
							<a href="/" title="Отменить" className="icon close"
								onClick={this.props.changeMode}><i></i></a>
						</p>
					</h4>
				</div>
				<div className="clearfix body">
					<TextEditor
						Id="Text"
						Value={contentState.Article.Text}
						Required={true}
						onChange={this.onChange.bind(this)}
					/>
					<div className="buttons">
						<button className={loading ? "loading" : ""} disabled={loading}>
							{loading && <img src={loader} alt="loading" /> }
							{!loading && "Сохранить" }
						</button>
						{contentState.ErrorCode !== "" &&
							<p className="error">{errors[contentState.ErrorCode] || errors.DEFAULT}</p>
						}
					</div>
				</div>
			</form>
		)
	}
}
ArticleEditMode = observer(ArticleEditMode);

export class ContentWidget extends Component {
	constructor(props) {
		super(props);

		this.getData = this.getData.bind(this);
		this.getData();
	}

	/** API запрос на получение данных контента в разделе «Новости» */
	getData() {
		contentState.IsSendRequest = false;
		getRequest("GetContent", { Id : 94 }, function(data) { contentState.setContent(data, "News", true); });
	}

	/** Переход на страницу «Новости» */
	handleSelect() {
		authUserState.DashboardId = 94;
		cookie.save("DashboardId", 94);
	}

	render() {
		return(<div className="widget" id="NewsWidget">
			<h4>Новости</h4>
			<NavLink to="/News" className="openPage" title="Перейти на страницу новостей"
				onClick={this.handleSelect.bind(this)}><i></i></NavLink>
			{(_.isUndefined(contentState.Groups) || _.isEmpty(contentState.Groups[0].Group)) 
				&& contentState.IsSendRequest && <p className="comment">У вас нет новостей</p>}
			
			{!_.isUndefined(contentState.Groups) && !_.isEmpty(contentState.Groups[0].Group) &&
				_.map(_.take(contentState.Groups[0].Group, 3), function (news, i) {
					return(<NewsItemWidget Item={news} Index={i} key={"n" + i} />);
			})}
		</div>)
	}
}
ContentWidget = observer(ContentWidget);
class NewsItemWidget extends Component {
	/** Переход на страницу «Новости» на конкретную статью */
	onClick (event) { 
		contentState.OpenContent = this.props.Index; 
		authUserState.DashboardId = 94;
		cookie.save("DashboardId", 94);
	}

	render() {
		return(
			<div className="clearfix news">
				<NavLink to="/News" title={this.props.Item.Name} onClick={this.onClick.bind(this)}>
					{this.props.Item.Name}</NavLink>
				<p className="comment">{this.props.Item.UserName}, {formatDate(this.props.Item.LastUpdate)}</p>
			</div>
		)
	}
}