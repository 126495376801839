import { observable, decorate } from 'mobx';
import _ from 'lodash';
import { POSTypesTitles } from '../dictionary';
import { authUserState } from './common';
import { checkRights } from '../functions';

class FilialState {
	FilialDummy = {
		Id: -1,
		Name: "",
		CityId: -1,
		Active: true,
		Address: "",
		Phone: "",
		TimeZone: "",
		ShiftHour: 0,
		Kitchen: undefined,
		Bar: undefined,
		MainPOS : undefined,
	}
	POSDummy = {
		Id: -1,
		Name: "",
		HasCassa: false,
		EntityId : -1,
		Brands : [],
		Aggregators : [],
		PaymentMethods : [],
		Main : false,
		Fiscal : false,
		CassaType : -1,
		FiscalCassaName : -1
	}
	StockDummy = {
		Id: -1,
		Name: "",
		Kitchen: false,
		Bar: false
	}
	WorkDayDummy = {
		Id: -1,
		Day: -1,
		StartTime: "",
		EndTime: ""
	}
	POSTypeDummy = {
		Id : -1,
		Type : -1,
		FirstTime : 0,
		FirstToTime : 0,
		LastTime : 0,
		LastToTime : 0
	}

	/** Состояние страницы по умолчанию */
	setDefault () {
		this.Filials = [];
		this.IsSendRequest = false;

		this.Cities = [];
		this.PaymentMethods = [];
		this.Brands = [];
		this.Aggregators = [];
		this.Entities = [];
		this.CassaList = [];

		this.CanManage = checkRights("ManageFilials");
		
		this.setFilial(undefined);
	}

	/**
	 * Разбор ответа от сервера со списком филиалов
	 * @param {object} data 
	 */
	setFilials (data) {
		var count = data.Success ? data.Filials.length : 0;
		this.Filials = data.Success ? _.map(data.Filials, function(v) {
			var types = _.uniq(_.flatten(_.map(v.POS, function(pos) {
				return _.compact(_.map(pos.Types, function(type) { 
					var typeTitle = _.find(POSTypesTitles, { Id : type.Type });
					return typeTitle ? typeTitle.ShortName : null;
				}));
			})))
			return _.extend(v, { 
				POSTypes : types.join(", "),
				CanRemove : count > 1
			});
		}) : [];
		this.IsSendRequest = true;
	}

	/**
	 * Разбор ответа от сервера с данными филиала
	 * @param {object} filial 
	 */
	setFilial(filial) {
		var store = this;

		this.Filial = _.isUndefined(filial) ? filial : filial.Id === -1 ? _.clone(this.FilialDummy)
			: _.omit(filial, ["WorkDays", "POS", "Stocks", "Success", "ErrorCode"]);

		if (!_.isUndefined(filial)) {
			this.Filial.TimeZone = _.isUndefined(filial) || filial.Id === -1 || this.Filial.GMT === "" 
				? "" : this.Filial.GMT;
			this.Filial.Kitchen = !_.isEmpty(filial.Stocks) && _.find(filial.Stocks, { Kitchen : true })
				? _.find(filial.Stocks, { Kitchen : true }).Id : undefined;
			this.Filial.Bar = !_.isEmpty(filial.Stocks) && _.find(filial.Stocks, { Bar : true })
				? _.find(filial.Stocks, { Bar : true }).Id : undefined;
			this.Filial.MainPOS = !_.isEmpty(filial.POS) && _.find(filial.POS, { Main : true }) 
				? _.findIndex(filial.POS, { Main : true }) : undefined;
		}

		this.POS = _.isUndefined(filial) || authUserState.isCallCentre || !authUserState.HasERP ? [] 
			: filial.Id === -1 || _.isEmpty(filial.POS) ? [_.extend(_.clone(this.POSDummy), {
				Types : _.map(POSTypesTitles, function (type) {
					return _.extend(_.clone(store.POSTypeDummy), { Type : type.Id })
				})
			})] : _.map(filial.POS, function (pos) {
				return _.extend(pos, {
					Brands : _.isNull(pos.Brands) ? [] : pos.Brands,
					Aggregators : _.isNull(pos.Aggregators) ? [] : pos.Aggregators,
					PaymentMethods : _.isNull(pos.PaymentMethods) ? [] : pos.PaymentMethods,
					Types : _.map(POSTypesTitles, function (type) {
						var item = _.find(pos.Types, { Type : type.Id });

						return _.extend(_.clone(store.POSTypeDummy), { Type : type.Id, Active : !_.isUndefined(item) }, 
							item ? item : {});
					})
				})
			});
		this.Stock = _.isUndefined(filial) || authUserState.isCallCentre || !authUserState.HasERP ? [] : 
			filial.Id === -1 || _.isEmpty(filial.Stocks) ? [_.clone(this.StockDummy)] : filial.Stocks;
		this.PSCounter = 0;

		this.WorkDays = _.isUndefined(filial) ? [] : _.map(_.range(7), function (v) {
			var day = filial.WorkDays ? _.find(filial.WorkDays, { Day : v }) : {};
			return _.extend(_.clone(store.WorkDayDummy), day);
		});

		this.ButtonLoading = false;
		this.ErrorCode = "";
		this.SuccessSave = false;
	}

	/**
	 * Сбор данных с формы филиала
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectFilial(id, value) { 
		this.Filial[id] = value; 
		
		if (/CityId/.test(id)) {
			var zone = _.find(this.Cities, { Id : value }) ? _.find(this.Cities, { Id : value }).GMT : "";
			this.Filial.TimeZone = zone;
		}
	}
	/**
	 * Сбор данных с формы склада
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 * @param {number} index порядковый номер склада
	 */
	collectStock(id, value, index) { this.Stock[index][id] = value; }
	/**
	 * Сбор данных с формы точек продаж
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 * @param {number} index порядковый номер точки
	 * @param {number} typeIndex порядковый номер типа точки
	 */
	collectPOS(id, value, index, typeIndex) {
		var item = "";
		
		if (/HasCassa/.test(id)) this.POS[index].HasCassa = value;
		else if (/Fiscal_/.test(id)) this.POS[index].Fiscal = value;
		else if (/Main/.test(id)) this.Filial.MainPOS = value ? index : undefined;
		else if (/Brands/.test(id)) {
			item = id.split("_")[1] ? parseInt(id.split("_")[1], 10) : "";
			if (item !== "" && value) this.POS[index].Brands = _.concat(this.POS[index].Brands, item);
			else if (item !== "" && !value) this.POS[index].Brands = _.without(this.POS[index].Brands, item);
		} else if (/Aggregators/.test(id)) {
			item = id.split("_")[1] ? parseInt(id.split("_")[1], 10) : "";
			if (item !== "" && value) this.POS[index].Aggregators = _.concat(this.POS[index].Aggregators, item);
			else if (item !== "" && !value) this.POS[index].Aggregators = _.without(this.POS[index].Aggregators, item);
		} else if (/PaymentMethods/.test(id)) {
			item = id.split("_")[1] ? parseInt(id.split("_")[1], 10) : "";
			if (item !== "" && value) this.POS[index].PaymentMethods = _.concat(this.POS[index].PaymentMethods, item);
			else if (item !== "" && !value) this.POS[index].PaymentMethods = _.without(this.POS[index].PaymentMethods, item);
		} else if (/PT/.test(id)) {
			item = id.replace("PT_", "");
			if (/Type/.test(item)) this.POS[index].Types[typeIndex - 1].Active = value;
			else this.POS[index].Types[typeIndex - 1][item] = value; 
		} else this.POS[index][id] = value;
	}

	/**
	 * Сбор данных с формы склада
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 * @param {number} stockId ID склада
	 */
	collectMenu(id, value, stockId) {
		if (/Kitchen/.test(id)) this.Filial.Kitchen = value ? stockId : undefined;
		else if (/Bar/.test(id)) this.Filial.Bar = value ? stockId : undefined;
	}

	/**
	 * Сбор данных с формы времени работы
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 * @param {number} index порядковый номер дня
	 */
	collectWorkDays (id, value, index) {
		if (/Day/.exec(id)) this.WorkDays[index].Day = value ? index : -1;
		else if (/Time/.exec(id)) {
			this.WorkDays[index].StartTime = value.StartTime;
			this.WorkDays[index].EndTime = value.EndTime;
		}
	}

	/**
	 * Сбор данных по филиалу для отправки на сервер
	 * @returns {object}
	 */
	getSaveData () {
		var store = this,
			data = _.clone(this.Filial),
			index = 0;

		data.Phone = data.Phone.replace(/\D/gi,"");

		_.each(this.POS, function (pos, i) {
			var name = "POS." + index + ".",
				kIndex = 0;

			if (pos.Name !== "") {
				data[name + "Id"] = pos.Id;
				data[name + "Name"] = pos.Name;
				data[name + "HasCassa"] = pos.HasCassa;
				data[name + "Main"] = i === store.Filial.MainPOS;
				data[name + "Brands"] = pos.Brands.join(",");
				data[name + "Aggregators"] = pos.Aggregators.join(",");
				data[name + "PaymentMethods"] = pos.PaymentMethods.join(",");
				data[name + "EntityId"] = pos.EntityId;
				if (pos.HasCassa) {
					data[name + "Fiscal"] = pos.Fiscal;
					data[name + "CassaType"] = pos.CassaType;
					data[name + "FiscalCassaName"] = pos.FiscalCassaName;
				}

				_.each(pos.Types, function (type) {
					var tname = name + "Types." + kIndex + ".";

					if (type.Active) {
						data[tname + "Id"] = type.Id;
						data[tname + "Type"] = type.Type;
						data[tname + "FirstTime"] = type.FirstTime;
						data[tname + "FirstToTime"] = type.FirstToTime;
						data[tname + "LastTime"] = type.LastTime;
						data[tname + "LastToTime"] = type.LastToTime;
						kIndex++;
					}
				})

				index++;
			}
		});

		_.each(this.Stock, function (stock, i) {
			var name = "Stocks." + i + ".";
			if (stock.Name !== "") {
				data[name + "Id"] = stock.Id;
				data[name + "Name"] = stock.Name;
				data[name + "Kitchen"] = stock.Id === store.Filial.Kitchen;
				data[name + "Bar"] = stock.Id === store.Filial.Bar;
			}
		});

		var days = _.filter(this.WorkDays, function (v) { return v.Day !== -1 && v.Day !== "" 
			&& v.StartTime !== "" && v.EndTime !== "" });
		
		_.each(days, function(day, i) {
			var name = "WorkDays." + i + ".";
			_.each(day, function(value, field) { data[name + field] = value; });
		});

		return data;
	}

	/**
	 * Проверка на корректность заполненных данных по филиалу
	 * 1. Корректность заполнения номера телефона
	 * 2. Если есть склады, должны быть выбраны склады для кухни и для бара
	 * 3. Если есть точки продаж, должна быть указана одна основная
	 * 4. Если есть точки продаж, к точке должно быть привязано юр. лицо
	 * 5. Если есть точки продаж, для каждой точки должен быть выбран хотя бы один тип точки
	 * @returns {boolean} флаг корректности данных
	 */
	validateData () {
		var store = this,
			isValid = true;

		this.ErrorCode = "";
		this.SuccessSave = false;

		if (this.Filial.CityId === "" || this.Filial.CityId < 1) {
			this.ErrorCode = "WRONG_PARAMS_CITY";
			return false;
		}

		if (this.Filial.Phone !== "" && this.Filial.Phone.replace(/\D/gi,"").length < 11) {
			this.ErrorCode = "WRONG_PHONE_LENGTH";
			return false;
		}

		if (authUserState.HasERP) {
			if (!_.isEmpty(this.Stock) && (this.Filial.Kitchen === undefined || this.Filial.Bar === undefined)) {
				this.ErrorCode = "FILIAL_MENU_REQUIRED";
				return false;
			}
	
			if (!_.isEmpty(this.POS) && this.Filial.MainPOS === undefined) {
				this.ErrorCode = "FILIAL_MAIN_REQUIRED";
				return false;
			}
	
			if (!_.isEmpty(this.POS)) 
				_.each(this.POS, function (v) {
					if (v.EntityId === "" || v.EntityId === -1) {
						store.ErrorCode = "FILIAL_ENTITY_REQUIRED";
						isValid = false;
						return false;
					}
	
					if (!_.some(_.map(v.Types, "Active"))) {
						store.ErrorCode = "POSTYPE_REQUIRED";
						isValid = false;
						return false;
					}
				});
		}

		return isValid;
	}
}
decorate(FilialState, {
	Filial: observable,
	POS: observable,
	Stock: observable,
	WorkDays: observable,
	
	ButtonLoading: observable,
	ErrorCode: observable,
	SuccessSave: observable,

	Filials: observable,
	IsSendRequest: observable,

	Cities: observable,
	PaymentMethods: observable,
	Brands: observable,
	Aggregators: observable,
	Entities: observable,
	CassaList: observable
});
export const filialState = new FilialState();

class StockState {
	Stocks = [];
	IsSendRequest = false;

	Stock = undefined;
	Products = [];
	Semimanufactures = {};
	ButtonLoading = false;
	ErrorCode = "";
	SuccessSave = false;

	sCounter = 0;
	
	TypeDummy = { Type : -1, Group : [] }
	SectionDummy = { SectionId : -1, Group : [] }
	ProductDummy = {
		Id : -1,
		ProductId : -1,
		Count : "",
		UnitName : "",
		Revision : false,
		NotRelease : false
	}
	DayDummy = {
		Id : -1,
		Day : "",
		Count : ""
	}

	SemiDummy = {
		Count: "",
		Day: [],
		EndTime: "",
		Id: -1,
		Product: "",
		ProductId: -1,
		Section: "",
		SectionId: -1,
		StartTime: "",
		UnitName: ""
	}

	/**
	 * Разбор данных от сервера со списком складов
	 * @param {object} data 
	 */
	setStocks(data) {
		this.Stocks = data.Success ? data.Stocks : [];
		this.IsSendRequest = true;
	}
	/**
	 * Разбор данных от сервера со складом
	 * @param {object} stock 
	 */
	setStock (stock) {
		var store = this;

		this.Stock = _.isUndefined(stock) ? stock : _.omit(_.clone(stock), 
			["Products", "Semimanufactures", "Success", "ErrorCode"]);
		this.Products = {};
		this.Semimanufactures = {};
		
		if (!_.isUndefined(stock)) {
			var types = _.isNull(stock.Products) ? [] : _.groupBy(stock.Products, "Type");

			_.each([1,2], function (type) {
				var groupP = _.isUndefined(types[type]) ? {"-1" : []}
					: _.groupBy(types[type], "SectionId");

				store.Products[parseInt(type, 10)] = _.map(groupP, function (group, section) {
					return {
						SectionId : _.isUndefined(section) ? -1 : parseInt(section, 10),
						Group : _.isEmpty(group) || _.isUndefined(group) ? [_.clone(store.ProductDummy)] : group
					}
				});
			});

			var semiAll = _.isNull(stock.Semimanufactures) ? [_.clone(this.SemiDummy)] : stock.Semimanufactures;
		
			this.Semimanufactures = _.map(_.groupBy(semiAll, "SectionId"), function(group) {
				return { SectionId : group[0].SectionId, Group : group }
			});
		}

		this.ErrorCode = "";
		this.ButtonLoading = false;
		this.SuccessSave = false;
		this.sCounter = 0;
	}

	/**
	 * Сбор данных с формы редактирования конфигурации
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 * @param {number} index порядковый номер продукта
	 * @param {number} group порядковый номер раздела
	 * @param {number} type тип раздела
	 * @param {string} unit ЕИ выбранного продукта
	 */
	collectProducts (id, value, index, group, type, unit) {
		if (_.isUndefined(index)) {
			if (/SectionId/.test(id)) this.Products[type][group].SectionId = value;
		} else {
			if (/Revision/.exec(id)) this.Products[type][group].Group[index].Revision = value;
			else if (/NotRelease/.exec(id)) this.Products[type][group].Group[index].NotRelease = value;
			else this.Products[type][group].Group[index][id] = value;

			if (/ProductId/.test(id)) this.Products[type][group].Group[index].UnitName = unit;
		}
	}

	/**
	 * Сбор данных с формы редактирования заготовок
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 * @param {number} index порядковый номер продукта
	 * @param {number} group порядковый номер раздела
	 * @param {object} product информация о выбранном продукте
	 */
	collectSemi (id, value, index, group, product) {
		if (_.isUndefined(index)) {
			if (/SectionId/.test(id)) this.Semimanufactures[group].SectionId = value;
		} else {
			if (/Day/.test(id)) {
				var day = parseInt(id.split("_")[1], 10),
					days = this.Semimanufactures[group].Group[index].Day;

				if (value) this.Semimanufactures[group].Group[index].Day = _.concat(days, day);
				else this.Semimanufactures[group].Group[index].Day = _.remove(days, day);
			} else if (/Time/.test(id))	{
				this.Semimanufactures[group].Group[index].StartTime = value.StartTime;
				this.Semimanufactures[group].Group[index].EndTime = value.EndTime;
			}
			else this.Semimanufactures[group].Group[index][id] = value;

			if (/ProductId/.test(id) && !_.isEmpty(product))
				this.Semimanufactures[group].Group[index].UnitName = product.UnitNet;
		}
	}

	/**
	 * Сбор данных с начтройками склада для отправки на сервер
	 * @returns {object}
	 */
	getSaveData () {
		var data = { Id : this.Stock.Id },
			products = _.flatten(_.map(_.flatMap(this.Products), "Group")),
			semi = _.flatten(_.map(this.Semimanufactures, "Group")),
			index = 0;

		_.each(products, function (v) {
			var name = "Products." + index + ".";

			if (v.ProductId !== -1 && v.ProductId !== "") {
				data[name + "Id"] = v.Id;
				data[name + "ProductId"] = v.ProductId;
				data[name + "Count"] = v.Count;
				data[name + "Revision"] = v.Revision;
				data[name + "NotRelease"] = v.NotRelease;
				index++;
			}
		});
		
		index = 0;

		_.each(semi, function (v) {
			var name = "Semimanufactures." + index + ".";

			if (v.ProductId !== -1 && v.ProductId !== "") {
				data[name + "Id"] = -1;
				data[name + "ProductId"] = v.ProductId;
				data[name + "Count"] = v.Count;
				data[name + "Day"] = v.Day.join(",");
				data[name + "StartTime"] = v.StartTime;
				data[name + "EndTime"] = v.EndTime;
				index++;
			}
		});

		return data;
	}
}
decorate(StockState, {
	Stock: observable,
	Products: observable,
	Semimanufactures: observable,

	Stocks: observable,
	IsSendRequest: observable,

	ButtonLoading: observable,
	ErrorCode: observable,
	SuccessSave: observable
});
export const stockState = new StockState();