import React, { Component } from 'react';
import { observer } from "mobx-react";
import { autorun } from 'mobx';
import _ from 'lodash';
import cookie from 'react-cookies';

import logo from './images/logo.svg';
import logosmall from './images/logo_small.svg';
import './App.css';
import './styles/mobile.css'; 

import { Switch, Route, NavLink, Redirect } from 'react-router-dom';
import { Nav, NavItem, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, Button } from 'reactstrap';

import { Selectize, SubmitButton, TextInput } from './forms/FormItems';
import { errors, dashboardsTitles, ShiftTitles, CheckShiftTitles} from './dictionary';
import { getRequest, formatDate, urlBase64ToUint8Array, checkRights, checkBoard, stringToObject, getErrorText, toSelectList } from './functions';

import { uistate, authUserState, errorState, confirmState, pushState } from './stores/common';
import { contentState } from './stores/content';

import { Confirm, ErrorMessage, PushBlock } from './pages/PageTemplates';
import { DashboardPage } from './pages/Dashboard';
import RolesPage from './pages/Roles';
import { CouriersPage, UsersPage } from './pages/Users';
import { FilialsPage, StocksPage } from './pages/Filials';
import ProvidersPage from './pages/Providers';
import { ContentPage } from './pages/Content';
import { CheckListManagePage, CheckListPage, CheckListReport } from './pages/CheckList';
import { UserReport, ShiftReport, ShiftTransferReport, ShiftSecretReport, ShiftReportCourier } from './pages/Reports';
import { Tags, CancelReasons, Inventory, Marks, WorkShops, CassaTransactions } from './pages/Directories';
import { Raw, SemiManufactures, Products } from './pages/Products';
import { KitchenMenu, BarMenu } from './pages/ProductionMenu';
import { RequestsPage, ShopListPage } from './pages/Requests';
import { InvoicePage, PaymentsPage } from './pages/Invoices';
import { TransferPage } from './pages/Transfers';
import { AuditPage, ActualStockPage } from './pages/Audits';
import { WriteOffRequestsPage, WriteOffsPage } from './pages/Writeoffs';
import { ReleasePage } from './pages/Release';
import { InventorizationPage } from './pages/Inventorization';
import { CostStat, RawStat, PurchaseStat, RevisionStat, SalesStat, WriteOffStat, PurchaseSummary } from './pages/Statistics';
import { MerchantPage } from './pages/Merchant';
import { OrdersPage } from './pages/Orders';
import { OrdersDeliveryPage } from './pages/Orders_delivery';
import { OrdersCookPage } from './pages/Orders_process';
import { CustomersPage } from './pages/Customers';
import { BonusPage, PromocodePage } from './pages/BonusRules';
import CassaPage from './pages/Cassa';
import { BillsPage } from './pages/Bills';
import { EmployeesStat, RevenueStat } from './pages/OrderStat';
import { PayoutsCourier, PayoutsUsers } from './pages/Payouts';
import { OrderStatuses } from './pages/OrderStatuses';
import { StopListPage } from './pages/StopLists';
import { CatalogPage } from './pages/Catalog';
import { AccountPage } from './pages/Account';
import { TipsPrintPage } from './pages/TipsPrint';
import { orderState, processOrderState } from './stores/orders';

class App extends Component {
	constructor(props) {
		super(props);

		// console.log(window.location)
		var params = stringToObject(window.location.search)
		// console.log(params)

		// console.log(window.location.pathname)

		this.state = {
			IsAuth : false,
			IsOpen : true,
			IsRegister : /Register/.test(window.location.pathname),
			IsRegisterSuccess : /Success/.test(window.location.pathname),
			IsRecoverPass : /ResetPassword/.test(window.location.pathname) && !_.isUndefined(params.Token) && params.Token !== "",
			RecoverParams : params
		};

		var model = this;
		setTimeout(function() { model.checkAuth(); }, 10);
	}

	disposer = autorun(() => { if (authUserState.SessionEnd) this.checkAuth(); });

	/** API запрос на подписку пользователя на PUSH уведомления для конкретной роил и филиала */
	getPushToken () {
		if (('serviceWorker' in navigator) && ('PushManager' in window)) {
			navigator.serviceWorker.ready.then(function(serviceWorkerRegistration) {
				// TODO: вытащить ключ куда нибудь (Антон потом придумает)
				serviceWorkerRegistration.pushManager.subscribe({userVisibleOnly: true, applicationServerKey: urlBase64ToUint8Array("BK0-qYGlmQV_mQ_XphBTc-C-WxU5bIIp23dcl5PGwosKHm8lcRqazvYQclvboOY10wZX50N5sazrV1Xc4Z0uhfU")})
				.then(function(subscription) {
					var token = JSON.stringify(subscription)
					if (token !== "" && !_.isUndefined(token))
						getRequest("SavePUSHToken", { Token : token }, function (data) { console.log(data); })
				  })
				.catch(function(e) {
					if (Notification.permission === 'denied') console.log('Permission for Notifications was denied');
					else console.log('Unable to subscribe to push.', e);
				});
			});
		} else console.log("PUSH doesn't support in this browser");
	}

	/** Прослушивание PUSH сообщений */
	async componentDidMount() {
		if (('serviceWorker' in navigator) && ('PushManager' in window)) {
			navigator.serviceWorker.addEventListener("message", (message) => {
				let msg = message.data['firebase-messaging-msg-data'] ? message.data['firebase-messaging-msg-data'] : message.data;

				if (msg.Group !== "Orders") pushState.setPush({ Title : msg.Title, Message : msg.Message });
				console.log(msg)
			});
		}
	}

	/** API запрос на проверку авторизации пользователя */
	checkAuth () {
		this.setState({ IsAuth : false });

		authUserState.IsCheckAuth = false;
		authUserState.IsAuth = false;

		getRequest("CheckAuth", {}, (data) => {
			if (!data.Success) this.setState({ IsAuth : data.Success });
			authUserState.setAuthUser(data.Success ? data : undefined);
				
			if (!_.isUndefined(cookie.load("DashboardId")))
				authUserState.DashboardId = parseInt(cookie.load("DashboardId"), 10);
			
			if (data.Success && data.Shift.Active) this.getPushToken();

			if (data.Success && authUserState.HasERP) this.getUnits();

			if (!data.Success && this.state.IsRegister) this.getCities();
				
			if (data.Success) this.setState({
				IsAuth : data.Success,
				IsOpen : !data.Shift.Active,
				IsRegister : false,
				IsRecoverPass : false
			});
		});
	}

	/** API запрос на получение доступных единиц измерения */
	getUnits () {
		getRequest("Units", {}, (data) => { authUserState.setUnits(data.Success ? data.Units : []) });
	}

	getCities () {
		getRequest("Cities", {}, (data) => { authUserState.AllCities = data.Success ? data.Cities : []; });
	}

	/** API запрос на открытие/закрытие смены */
	openShift () {
		var model = this,
			canOpenshift = !authUserState.Shift.Active,
			params = {
				Role : canOpenshift ? authUserState.Role.Id : authUserState.Shift.RoleId,
				Filial : canOpenshift ? authUserState.Filial.Id : authUserState.Shift.FilialId,
				Action : canOpenshift ? "open" : "close"
			},
			setCheckList = canOpenshift && authUserState.HasCheckList && !authUserState.HasERP;

		getRequest("OpenShift", params, function (data) {
			if (data.Success) {
				if (!authUserState.Shift.Active) model.getPushToken();

				model.checkAuth();
				authUserState.DashboardId = 0;

				if (setCheckList) {
					model.props.history.push('/CheckList');
					authUserState.DashboardId = 99;
				} else
					model.props.history.push('/Dashboard');
			} else errorState.setError(data.ErrorCode);
		});
	}

	/** API запрос на разлогинивание пользователя */
	logout (event) {
		event.preventDefault();
		// var model = this;

		getRequest("Logout", {}, (data) => { 
			if (data.Success) authUserState.IsAuth = false;
			this.checkAuth(); 
		});
	}

	/** API запрос на смену предприятия
	 * @param {number} id ID предприятия
	 */
	changeMerchant (id) {
		var model = this;

		getRequest("ChangeMerchant", { Id : id }, function (data) {
			model.checkAuth();
			if (data.Success) {
				cookie.save("RoleId", "");
				cookie.save("FilialId", "");
				cookie.save("MerchantId", id);
				authUserState.DashboardId = 0;
				model.props.history.push('/Dashboard');
			}
		});
	}

	toggleBar(isOpen) { this.setState({ IsOpen : isOpen }); }

	/** Изменение урла и состояния при успешной регистрации */
	changeRegisterUrl () {
		this.setState({ IsRegisterSuccess : true });
		this.props.history.push('/Success');
	}

	render() {
		var style = uistate.IsTablet ? { minHeight : "300px", height : uistate.WindowDimensions.height } : {};

		return (
			<div id="wrapper" className="clearfix" style={style}>
				{!this.state.IsRegister && 
					<Login 
						checkAuth={this.checkAuth.bind(this)} 
						RecoverState = {this.state.IsRecoverPass}
						RecoverParams = {this.state.RecoverParams}
					/>
				}
				{!this.state.IsAuth && (this.state.IsRegister || this.state.IsRegisterSuccess) &&
					<Register
						IsRegisterSuccess={this.state.IsRegisterSuccess}
						changeRegisterUrl={this.changeRegisterUrl.bind(this)}
						checkAuth={this.checkAuth.bind(this)}
					/>
				}
				{this.state.IsAuth &&
					<div className="page">
						<SideBar 
							openShift={this.openShift.bind(this)}  
							changeMerchant={this.changeMerchant.bind(this)} 
							logout={this.logout.bind(this)} 
							toggleBar={this.toggleBar.bind(this)}
						/>
						{!uistate.IsTablet && !uistate.IsMobile && <Header logout={this.logout.bind(this)} />}
						<Main styleHeader={this.state.IsOpen && !uistate.IsTablet ? 260 : 50} history={this.props.history} />
					</div>
				}
				<PushBlock />
				<ErrorMessage />
				<Confirm />
				<CheckShiftWindow 
					openShift={this.openShift.bind(this)}  
				/>
			</div>
		);
	}
}

class CheckShiftWindow extends Component {
	onCancel (event) {
		event.preventDefault();

		authUserState.openCheckShift(undefined);
	}

	onConfirm (event) {
		event.preventDefault();
		this.props.openShift();
		authUserState.openCheckShift(undefined);
	}

	render() {
		if (_.isUndefined(authUserState.CheckShift)) return null;

		return(
			<div className={"back " + (uistate.IsMobile ? "mobile" : "")}>
				<form className="confirmBlock clearfix checkShift window medium" onSubmit={this.onConfirm.bind(this)}>
					{authUserState.HasERP &&
						<h4>Рекомендации при закрытии смены</h4>
					}
					{!authUserState.HasERP &&
						<h4>Закрытие смены</h4>
					}
					{authUserState.HasERP &&
						<ul>
							{_.map(authUserState.CheckShift.Checks, function (item) {
								var classLi = (item.Must ? "alert " : "") + (item.Complete ? "complete" : "");
								
								return(<li key={item.Id} className={classLi}>
									<i></i>{CheckShiftTitles[item.Id]}
								</li>)
							})}
						</ul>
					}
					{authUserState.CheckShift.CanCloseShift &&
						<p>Вы действительно хотите закрыть смену?</p>
					}
					<div className="buttons clearfix">
						{authUserState.CheckShift.CanCloseShift &&
							<SubmitButton Loading={confirmState.ButtonLoading} Title="Подтвердить" />
						}
						<a href="/" role="button" onClick={this.onCancel.bind(this)} className="button gray">Отменить</a>
					</div>
				</form>
			</div>
		)
	}
}
CheckShiftWindow = observer(CheckShiftWindow);

class SideBar extends Component {
	/** Подтверждение открытия смены */
	handleClick(event) {
		event.preventDefault();

		if (authUserState.Shift.Active) this.checkShift();
		else {
			var confirmText = <span>Вы действительно хотите открыть смену в должности&nbsp;
				<b key="b1">«{authUserState.Role.Name}»</b> в филиале <b key="b2">«{authUserState.Filial.Name}»</b>?</span>;
	
			confirmState.openConfirm(confirmText, this.props.openShift);
		}
	}

	/** API запрос на возможность закрытия смены */
	checkShift () {
		getRequest("CheckShift", {}, function (data) { authUserState.openCheckShift(data); });
	}
	
	/** Свернуть/развернуть меню десктоп */
	toggleMenu (event) {
		event.preventDefault();
		uistate.IsSideBarOpen = !uistate.IsSideBarOpen;
		this.props.toggleBar(uistate.IsSideBarOpen);
	}

	/** Свернуть/развернуть меню мобилка/планшет */
	toggleMobile (event) {
		event.preventDefault();
		var active = event.currentTarget.getAttribute("href").replace("#", "");

		uistate.MenuActive = uistate.MenuActive === active ? "" : active
	}

	/** Выбор пункта меню */
	handleSelect (event) {
		var id = parseInt(event.currentTarget.getAttribute("eventkey"), 10);

		authUserState.DashboardId = id;
		uistate.MenuActive = "";
		cookie.save("DashboardId", id);
	}

	render () {
		var asideClass = uistate.IsMobile ? "mobile" : uistate.IsTablet ? "tablet" : uistate.IsSideBarOpen ? "open" : "close",
		    isNoFilials = _.isNull(authUserState.Filials) || _.isEmpty(authUserState.Filials),
			isNoRoles = _.isNull(authUserState.Roles) || _.isEmpty(authUserState.Roles),
			style = { height : uistate.IsMobile ? (uistate.WindowDimensions.height - 60) : "auto" },
			hasOrder = _.find(authUserState.Dashboards.Menu, { Group : "Orders" }),
			isCourier = authUserState.User.IsCourier,
			isCourierPage = isCourier && hasOrder && authUserState.Dashboards.Menu.length === 1;

		return(
			<aside className={asideClass}>
				<div className="logo">
					<NavLink to="/Dashboard" eventkey={0} onClick={this.handleSelect.bind(this)}>
						<img src={!uistate.IsSideBarOpen || uistate.IsTablet || uistate.IsMobile ? logosmall : logo} alt="logo" />
					</NavLink>
				</div>
				{!uistate.isDevice && uistate.IsSideBarOpen && 
					<Shift changeMerchant={this.props.changeMerchant} /> 
				}
				{!authUserState.Shift.Active && uistate.IsSideBarOpen && !isNoRoles && !isNoFilials && 
						!uistate.isDevice && 
					<OpenShift handleClick={this.handleClick.bind(this)} />
				}
				{!uistate.isDevice && !_.isUndefined(authUserState.Dashboards) &&
					<Menu List={authUserState.Dashboards.Sidebar} Type="aside" />
				}
				{!uistate.isDevice && authUserState.Shift.Active && 
					<OpenShift handleClick={this.handleClick.bind(this)} />
				}
				{!uistate.isDevice &&
					<a href="/" className="toggle" onClick={this.toggleMenu.bind(this)}><i></i><i></i><i></i><i></i></a>
				}
				{uistate.isDevice && !isCourierPage &&
					<a href="#Menu" className={"menu navigation " + (uistate.MenuActive === "Menu" ? "active" : "")} 
						onClick={this.toggleMobile.bind(this)}><i></i></a>
				}
				{uistate.isDevice &&
					<a href="#Shift" className={"menu shiftsettings " + (uistate.MenuActive === "Shift" ? "active" : "")} 
						onClick={this.toggleMobile.bind(this)}><i></i></a>
				}
				{uistate.isDevice && !_.isUndefined(hasOrder) &&
					<NavLink to={"/" + hasOrder.SubGroup} className="orders menu" eventkey={hasOrder.Id} 
						onClick={this.handleSelect.bind(this)}>
						<i></i>
					</NavLink>
				}
				{uistate.isDevice &&
					<NavLink to="Account" eventkey={0} className="account menu" 
						onClick={this.handleSelect.bind(this)}><i></i></NavLink>
				}
				{uistate.isDevice &&
					<a href="/" className="menu logout" onClick={this.props.logout}><i></i></a>
				}
				{uistate.isDevice && uistate.MenuActive !== "" &&
					<div className="tabletMenu" style={style}>
						{uistate.MenuActive === "Menu" && !_.isUndefined(authUserState.Dashboards) &&
							<Menu List={authUserState.Dashboards.Menu} Type="aside"/>
						}
						{uistate.MenuActive === "Shift" &&
							<div className="shiftBlock">
								<Shift changeMerchant={this.props.changeMerchant} /> 
								<OpenShift handleClick={this.handleClick.bind(this)} />
							</div>
						}
					</div>
				}
			</aside>
		)
	}
}
SideBar = observer(SideBar);
class OpenShift extends Component {
	render() {
		var title = authUserState.Shift.Active ? "Закрыть смену" : "Открыть смену",
			button = "shiftb " + (authUserState.Shift.Active ? "finish" : "open"),
			date = authUserState.Shift.Active ? authUserState.Shift.Date : "today",
			isCourier = authUserState.User.IsCourier;

		return(
			<div className="clearfix shiftToggle">
				{!isCourier &&
					<button className={button} onClick={this.props.handleClick} title={title}>
						{(uistate.IsSideBarOpen || uistate.IsTablet || uistate.IsMobile) && title}
						{!uistate.IsSideBarOpen && !uistate.IsTablet && !uistate.IsMobile && <i></i>}
					</button>
				}
				{isCourier && !authUserState.Shift.Active &&
					<p>Для открытия смены обратитесь к администратору</p>
				}
				{(uistate.IsSideBarOpen || uistate.IsTablet || uistate.IsMobile) && 
					<div className="date">{formatDate(date || "today", "DD.MM.YYYY, ddd")}</div>
				}
			</div>
		)
	}
}
OpenShift = observer(OpenShift);
class Shift extends Component {
	render() {
		var view = this,
			items = [],
			isNoFilials = _.isNull(authUserState.Filials) || _.isEmpty(authUserState.Filials),
			isNoRoles = _.isNull(authUserState.Roles) || _.isEmpty(authUserState.Roles),
			availableMerchants = _.filter(authUserState.Merchants, function (v) {
				return _.filter(authUserState.User.Roles, { MerchantId : v.Id }).length > 0
			});

		if (!_.isNull(authUserState.Merchants) && availableMerchants.length > 1)
			items = _.concat(items, "Merchant");
		if (!isNoRoles) items = _.concat(items, "Role");
		if (!isNoFilials) items = _.concat(items, "Filial");

		return(
			<div className="shift">
				{(uistate.IsTablet || uistate.IsMobile) && <p><b>{authUserState.User.Name}</b></p>}
				{_.map(items, function(item) {
					var list = item === "Merchant" ? availableMerchants : authUserState[item + "s"] || [];

					return(<ShiftItem key={item} Id={item} List={list} changeMerchant={view.props.changeMerchant} />);
				})}

				{(isNoRoles || isNoFilials) &&
					<p>У&nbsp;вас нет&nbsp;доступных филиалов или&nbsp;должностей в&nbsp;данном предприятии. 
						Обратитесь к&nbsp;администратору системы.</p>
				}
			</div>
		)
	}
}
Shift = observer(Shift);
class ShiftItem extends Component {
	constructor(props) {
		super(props);
		this.state = { dropdownOpen: false }
	}

	/** Показать/скрыть выпадающий список */
	toggle() { this.setState({ dropdownOpen: !this.state.dropdownOpen }); }

	/** Обработка клиека по пункту в выпадающем списке */
	handleClick (event) { 
		if (event.target.value !== "" && !_.isUndefined(event.target.value)) {
			var id = parseInt(event.target.value, 10);

			if (this.props.Id === "Role") {
				authUserState.Role = _.find(authUserState.Roles, { Id : id });
				cookie.save("RoleId", id);
			}
			if (this.props.Id === "Filial") {
				authUserState.Filial = _.find(authUserState.Filials, { Id : id });
				cookie.save("FilialId", id);
			} 
			if (this.props.Id === "Merchant") this.props.changeMerchant(id);

			if (!_.isUndefined(authUserState.Filial) && !_.isUndefined(authUserState.Role)){
				contentState.filterContent(authUserState.Filial.Id, authUserState.Role.Id);
			}
		}
	}

	render() {
		var list = this.props.List,
			item = !_.isUndefined(authUserState[this.props.Id]) && !_.isNull(authUserState[this.props.Id]) ? _.find(list, { Id : authUserState[this.props.Id].Id || authUserState[this.props.Id] }): undefined,
			name = !_.isUndefined(item) ? item.Name : !_.isEmpty(list) ? list[0].Name : "",
			id = !_.isUndefined(item) ? item.Id : !_.isEmpty(list) ? list[0].Id : "",
			activeId = authUserState.Shift.Active ? authUserState.Shift[this.props.Id + "Id"] : -1,
			canChange = (checkRights("CanChangeShift") || !authUserState.Shift.Active)
				&& !authUserState.User.IsCourier;
		
		list = _.reject(list, { Id : id });

		return (
			<Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle.bind(this)} 
					onClick={this.handleClick.bind(this)}>
				<Button>
					{ShiftTitles[this.props.Id]}: 
					<span className={id === activeId ? "active" : ""}>{name}</span>
				</Button>
				{!_.isEmpty(list) && canChange && <DropdownToggle caret />}
				{!_.isEmpty(list) &&
					<DropdownMenu>
						{list.map(function (v) { 
							return <DropdownItem key={v.Id} value={v.Id} 
								className={v.Id === activeId ? "active" : ""}>{v.Name}</DropdownItem>
						})}
					</DropdownMenu>
				}
			</Dropdown>
		)
	}
}
ShiftItem = observer(ShiftItem)

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = { dropdownOpen: false };
	}

	toggle() { this.setState({ dropdownOpen: !this.state.dropdownOpen }); }
	onMouseEnter() { this.setState({ dropdownOpen: true }); }
	onMouseLeave() { this.setState({ dropdownOpen: false }); }

	handleClick (event) { 
		if (event.target.value !== "" && !_.isUndefined(event.target.value)) {
			authUserState.City = parseInt(event.target.value, 10);
			authUserState.Filial = authUserState.Filials[0];
			cookie.save("FilialId", authUserState.Filials[0].Id)
		}
	}
	
	render() {
		var cities = _.filter(authUserState.Cities, function (v) { return v.Id !== authUserState.City }),
			active = _.find(authUserState.Cities, { Id : authUserState.City }),
			name = authUserState.User.FirstName + " " + authUserState.User.LastName,
			photoStyle = { backgroundImage : "url(" + authUserState.User.PhotoURL + ")" },
			style = { left : uistate.pageMargin };

		return (
			<header style={style}>
				{!_.isUndefined(authUserState.Dashboards) &&
					<Menu List={authUserState.Dashboards.Menu} Type="header" />
				}
				<div className="adds">
					<a className="logout" onClick={this.props.logout} href="/" title="Выйти"><i></i></a>
					<div className="user">
						{authUserState.User.PhotoURL !== "" && <div className="photo" style={photoStyle}></div>}
						<div className="userName">
							{/* <p title={name}>{name}</p> */}
							<NavLink to="Account" eventkey={0}>{name}</NavLink>
							<p className="position">{authUserState.Role ? authUserState.Role.Name : ""}</p>
						</div>
					</div>
					<div className="location">
						<Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle.bind(this)} 
							onMouseOver={this.onMouseEnter.bind(this)} onMouseLeave={this.onMouseLeave.bind(this)}
								onClick={this.handleClick.bind(this)}>
							<DropdownToggle caret><i></i><span>{active ? active.Name : ""}</span></DropdownToggle>
							{!_.isEmpty(cities) &&
								<DropdownMenu>
									{cities.map(function (v) { 
										return <DropdownItem key={v.Id} value={v.Id}>{v.Name}</DropdownItem>
									})}
								</DropdownMenu>
							} 
						</Dropdown>
					</div>
				</div>
				
			</header>
		)
	}
}
Header = observer(Header)

class Menu extends Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = { dropdownOpen : false };
	}

	/** Скрытие/показ вкладок меню */
	toggle() { this.setState({ dropdownOpen: !this.state.dropdownOpen }); }
	
	/** Скролл верхнего меню по кнопкам */
	scrollMenu(event) {
		event.preventDefault();

		var type = /next/.exec(event.currentTarget.className) ? "next" : "prev";
		uistate.getMenuPosition(type, _.size(_.groupBy(this.props.List, "Group")))
	}

	/** Выбор активного пункта меню */
	handleSelect (event) {
		var id = parseInt(event.currentTarget.getAttribute("eventkey"), 10);
		this.setState({ dropdownOpen: false });

		authUserState.DashboardId = id;
		uistate.MenuActive = "";
		cookie.save("DashboardId", id);

		if (!_.isUndefined(orderState)) {
			clearInterval(orderState.OrderInterval)
			orderState.OrderInterval = undefined;
		}

		if (!_.isUndefined(processOrderState)) {
			clearInterval(processOrderState.OrderInterval)
			processOrderState.OrderInterval = undefined;
		}
	}

	render() {
		var view = this,
			loc = window.location.pathname.replace("/", ""),
			group = _.groupBy(this.props.List, "Group"),
			styles = {
				header : { maxWidth: uistate.menuWidth },
				aside : uistate.IsTablet ? { height : "100%" } : uistate.IsMobile ? 
					{ height : uistate.WindowDimensions.height - 60 } : uistate.styleMenu,
				nav : { left : uistate.menuPosition }
			},
			scrollable = _.size(group)*(uistate.CommonWidth/uistate.MaxMenuCount) > uistate.menuWidth;
		
		if (!_.isUndefined(group.Orders)) {
			var orders = group.Orders,
				list = _.omit(group, "Orders");

			if (orders.length === 1) {
				var item = _.clone(orders[0]);
				orders = [{ Group: item.SubGroup, Id: item.Id, Name: "", SubGroup: "", Type: item.Type }];
			}
			
			group = _.extend({ Orders : orders }, list);
		}

		return (
			<div className={"menu" + (scrollable ? " scrollable" : "")} style={styles[this.props.Type]}>
				{this.props.Type === "header" && scrollable && uistate.menuPosition !== 0 &&
					<a href="/" className="arrow prev" onClick={this.scrollMenu.bind(this)}><i></i></a>
				}
				<Nav pills style={styles.nav} >
					{_.map(group, function (item, i) {
						var active = i + (loc === i ? " active" : ""),
							title = dashboardsTitles[i] ? dashboardsTitles[i] : i;

						if (item.length === 1 && item[0].SubGroup === "") 
							return (<NavItem key={i}>
										<NavLink to={item[0].Group} className={active} title={title} eventkey={item[0].Id}
										onClick={view.handleSelect.bind(view)}>
											<i></i><span>{title}</span></NavLink>
									</NavItem>);
						else return(<MenuDropDown key={i} Item={item} Title={title} ClassGroup={active}
							handleSelect={view.handleSelect.bind(view)} Type={view.props.Type} />);
					})}
				</Nav>
				{this.props.Type === "header" && scrollable && uistate.menuPosition === 0 &&
					<a href="/" className="arrow next" onClick={this.scrollMenu.bind(this)}><i></i></a>
				}
			</div>
		)
	}
}
Menu = observer(Menu);
class MenuDropDown extends Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			dropdownOpen: false
		};
	}

	/** Показать/скрыть выпадающий список */
	toggle() { this.setState({ dropdownOpen: !this.state.dropdownOpen }); }
	/** Показать выпадающий список при наведении мыши */
	onMouseEnter() { if (this.props.Type === "header") this.setState({dropdownOpen: true}); }
	/** Скрыть выпадающий список при уходе мыши */
	onMouseLeave() { if (this.props.Type === "header") this.setState({dropdownOpen: false}); }

	/** Выбор активного пункта по клику */
	onClick(event) {
		this.setState({ dropdownOpen : false });
		this.props.handleSelect();
	}

	render() {
		var view = this,
			loc = window.location.pathname.replace("/", ""),
			group = _.groupBy(this.props.Item, "SubGroup"),
			link = this.props.Item[0];

		return (
			<Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle} 
					onMouseOver={this.onMouseEnter.bind(this)} onMouseLeave={this.onMouseLeave.bind(this)}>
				<DropdownToggle nav caret className={this.props.ClassGroup}>
					{link.Group === "Orders" && !uistate.IsMobile && !uistate.IsTablet &&
						<NavLink to={link.SubGroup} eventkey={link.Id}
						onClick={view.props.handleSelect}><i></i>{this.props.Title}</NavLink>
					}
					{(link.Group !== "Orders" || uistate.IsMobile || uistate.IsTablet) &&
						<span><i></i>{this.props.Title}</span>
					}
				</DropdownToggle>
				<DropdownMenu>
					<div className={"covermenu " + (this.props.Item.length > 12 ? "cols" : "")}>
						{_.map(group, function (item, i) {
							var active = loc === i ? " active" : "",
								title = dashboardsTitles[i] || i;
							
							if (item.length > 1 || item[0].Name !== "") return(<MenuSubGroup Title={title} Item={item} key={i} 
								handleSelect={view.props.handleSelect}/>)
							else return(<NavLink to={i} key={i} className={active} eventkey={item[0].Id}
								onClick={view.props.handleSelect}>{title}</NavLink>)
						})}
					</div>
				</DropdownMenu>
			</Dropdown>
		)
	}
}
class MenuSubGroup extends Component {
	render() {
		var view = this,
			loc = window.location.pathname.replace("/", "");

		return(
			<div className="subGroup">
				<span className="title">{this.props.Title}</span>
				{_.map(this.props.Item, function (v) {
					var title = dashboardsTitles[v.Name] || v.Name,
						active = "subitem" + (loc === v.Name ? " active" : "");
					return(<NavLink to={v.Name} key={v.Name} className={active} eventkey={v.Id}
						onClick={view.props.handleSelect}>{title}</NavLink>)
				}) }
			</div>
		)
	}
}

class Main extends Component {
	render () {
		var style = { 
				marginLeft : uistate.IsMobile ? 0 : this.props.styleHeader,
				paddingTop : uistate.IsMobile ? "60px" : uistate.IsTablet ? "15px" : "85px"
			};

		return(
			<main id="page" style={style} className={uistate.IsMobile ? "mobile" : uistate.IsTablet ? "tablet" : ""}>
				<Switch>
					<Route path='/Dashboard' render={() => <DashboardPage />} />
					<Route path='/Account' render={() => <AccountPage />} />

					{/* Common */}
					{checkBoard("CheckList") && <Route path='/CheckList' render={() => <CheckListPage Type="CheckList" />} />}
					
					{/* Orders */}
					{checkBoard("TakingOrders") && <Route path='/TakingOrders' render={() => <OrdersPage Type="Orders" />} />}
					{checkBoard("ProcessOrders") && <Route path='/ProcessOrders' render={() => <OrdersCookPage Type="Orders" />} />}
					{checkBoard("DeliveryOrders") && <Route path='/DeliveryOrders' render={() => <OrdersDeliveryPage Type="Orders" />} />}
					
					{/* Settings */}
					{checkBoard("Common") && <Route path='/Common' render={() => <MerchantPage />} />}
					{checkBoard("Users") && <Route path='/Users' render={() => <UsersPage Type="Users" />} />}
					{checkBoard("Couriers") && <Route path='/Couriers' render={() => <CouriersPage Type="Couriers" />} />}
					{checkBoard("Filials") && <Route path='/Filials' render={() => <FilialsPage Type="Filials" />} />}
					{checkBoard("Stocks") && <Route path='/Stocks' render={() => <StocksPage Type="Stocks" />} />}
					{checkBoard("Roles") && <Route path='/Roles' render={() => <RolesPage Type="Roles" />} />}
					{checkBoard("CheckLists") && <Route path='/CheckLists' render={() => <CheckListManagePage Type="CheckLists" history={this.props.history} />} />}
					{checkBoard("Check") && <Route path='/Check' render={() => <BillsPage Type="Check" />} />}
					{checkBoard("TipsPrint") && <Route path='/TipsPrint' render={() => <TipsPrintPage Type="TipsPrint" />} />}

					{/* Bonus */}
					{checkBoard("BonusRules") && <Route path='/BonusRules' render={() => <BonusPage Type="BonusRules" />} />}
					{checkBoard("BonusPromocodes") && <Route path='/BonusPromocodes' render={() => <PromocodePage Type="BonusPromocodes" />} />}

					{/* Cassa */}
					{checkBoard("Cassa") && <Route path='/Cassa' render={() => <CassaPage Type="Cassa" />} />}
					{checkBoard("PayoutUser") && <Route path='/PayoutUser' render={() => <PayoutsUsers Type="PayoutUser" />} />}
					{checkBoard("PayoutCourier") && <Route path='/PayoutCourier' render={() => <PayoutsCourier Type="PayoutCourier" />} />}

					{/* Reports */}
					{checkBoard("CheckListReports") && <Route path='/CheckListReports' render={() => <CheckListReport Type="CheckList" />} />}
					{checkBoard("ShiftReport") && <Route path='/ShiftReport' render={() => <ShiftReport Type="ShiftReport" />} />}
					{checkBoard("ShiftReportCourier") && <Route path='/ShiftReportCourier' render={() => <ShiftReportCourier Type="ShiftReportCourier" />} />}
					{checkBoard("ShiftTransfer") && <Route path='/ShiftTransfer' render={() => <UserReport Type="UserReport" />} />}
					{checkBoard("ShiftTransferReport") && <Route path='/ShiftTransferReport' render={() => <ShiftTransferReport Type="ShiftTransferReport" />} />}
					{checkBoard("ShiftSecretReport") && <Route path='/ShiftSecretReport' render={() => <ShiftSecretReport Type="ShiftSecretReport" />} />}

					{/* Content */}
					{checkBoard("Scripts") && <Route path='/Scripts' render={() => <ContentPage Type="Scripts" ContentType="Content" />} />}
					{checkBoard("FAQ") && <Route path='/FAQ' render={() => <ContentPage Type="FAQ" ContentType="Content" />} />}
					{checkBoard("Algorithms") && <Route path='/Algorithms' render={() => <ContentPage Type="Algorithms" ContentType="Content" />} />}
					{checkBoard("FoodSafety") && <Route path='/FoodSafety' render={() => <ContentPage Type="FoodSafety" ContentType="Content" />} />}
					{checkBoard("SafetyEngineering") && <Route path='/SafetyEngineering' render={() => <ContentPage Type="SafetyEngineering" ContentType="Content" />} />}
					{checkBoard("Rules") && <Route path='/Rules' render={() => <ContentPage Type="Rules" ContentType="Content" />} />}
					{checkBoard("Kitchen") && <Route path='/Kitchen' render={() => <ContentPage Type="Kitchen" ContentType="Content" />} />}
					{checkBoard("Bar") && <Route path='/Bar' render={() => <ContentPage Type="Bar" ContentType="Content" />} />}
					{checkBoard("Stock") && <Route path='/Stock' render={() => <ContentPage Type="Stock" ContentType="Stock" />} />}
					{checkBoard("Documents") && <Route path='/Documents' render={() => <ContentPage Type="Documents" ContentType="Documents" />} />}
					{checkBoard("News") && <Route path='/News' render={() => <ContentPage Type="News" ContentType="News" />} />}
					{checkBoard("CourierTaxes") && <Route path='/CourierTaxes' render={() => <ContentPage Type="CourierTaxes" ContentType="Content" />} />}

					{/* Directory */}
					{checkBoard("CancelReasons") && <Route path='/CancelReasons' render={() => <CancelReasons Type="CancelReasons" />} />}
					{checkBoard("Tags") && <Route path='/Tags' render={() => <Tags Type="Tags" />} />}
					{checkBoard("CassaTransactionTag") && <Route path='/CassaTransactionTag' render={() => <CassaTransactions Type="CassaTransactionTag" />} />}
					{checkBoard("OrderMark") && <Route path='/OrderMark' render={() => <Marks Type="Marks" />} />}
					{checkBoard("GuildType") && <Route path='/GuildType' render={() => <WorkShops Type="WorkShops" />} />}
					{checkBoard("Inventories") && <Route path='/Inventories' render={() => <Inventory Type="Inventories" />} />}
					{checkBoard("OrderStatus") && <Route path='/OrderStatus' render={() => <OrderStatuses Type="OrderStatus" />} />}
					{checkBoard("Providers") && <Route path='/Providers' render={() => <ProvidersPage Type="Providers" />} />}
					
					{checkBoard("Raw") && <Route path='/Raw' render={() => <Raw Type="Raw" />} />}
					{checkBoard("SemiManufactures") && <Route path='/SemiManufactures' render={() => <SemiManufactures Type="SemiManufactures" />} />}
					{checkBoard("Products") && <Route path='/Products' render={() => <Products Type="Products" />} />}

					{checkBoard("Customers") && <Route path='/Customers' render={() => <CustomersPage Type="Customers" />} />}
					
					{/* Production */}
					{checkBoard("KitchenMenu") && <Route path='/KitchenMenu' render={() => <KitchenMenu Type="KitchenMenu" />} />}
					{checkBoard("BarMenu") && <Route path='/BarMenu' render={() => <BarMenu Type="BarMenu" />} />}
					{checkBoard("Release") && <Route path='/Release' render={() => <ReleasePage Type="Release" />} />}
					{checkBoard("StopList") && <Route path='/StopList' render={() => <StopListPage Type="StopList" />} />}

					{/* Stock */}
					{checkBoard("Order") && <Route path='/Order' render={() => <RequestsPage Type="Requests" />} />}
					{checkBoard("Purchase") && <Route path='/Purchase' render={() => <ShopListPage Type="ShopLists" />} />}
					{checkBoard("Invoices") && <Route path='/Invoices' render={() => <InvoicePage Type="Invoices" />} />}
					{checkBoard("Movement") && <Route path='/Movement' render={() => <TransferPage Type="Transfers" />} />}
					{checkBoard("Audits") && <Route path='/Audits' render={() => <AuditPage Type="Audits" />} />}
					{checkBoard("Leftovers") && <Route path='/Leftovers' render={() => <ActualStockPage Type="Leftovers" />} />}
					{checkBoard("WriteOffRequest") && <Route path='/WriteOffRequest' render={() => <WriteOffRequestsPage Type="WriteOffRequest" />} />}
					{checkBoard("WriteOffList") && <Route path='/WriteOffList' render={() => <WriteOffsPage Type="WriteOffList" />} />}
					{checkBoard("Inventorization") && <Route path='/Inventorization' render={() => <InventorizationPage Type="Inventorization" />} />}
					
					{checkBoard("Payments") && <Route path='/Payments' render={() => <PaymentsPage Type="Payments" />} />}

					{/* Statistics */}
					{checkBoard("CostPrice") && <Route path='/CostPrice' render={() => <CostStat Type="CostPrice" />} />}
					{checkBoard("RawMovement") && <Route path='/RawMovement' render={() => <RawStat Type="RawMovement" />} />}
					{checkBoard("PurchaseHistory") && <Route path='/PurchaseHistory' render={() => <PurchaseStat Type="PurchaseHistory" />} />}
					{checkBoard("ProductsStat") && <Route path='/ProductsStat' render={() => <SalesStat Type="ProductsStat" />} />}
					{checkBoard("RevisionsStat") && <Route path='/RevisionsStat' render={() => <RevisionStat Type="RevisionsStat" />} />}

					{checkBoard("WriteoffStat") && <Route path='/WriteoffStat' render={() => <WriteOffStat Type="WriteoffStat" />} />}
					{checkBoard("PurchaseSummary") && <Route path='/PurchaseSummary' render={() => <PurchaseSummary Type="PurchaseSummary" />} />}
					
					{checkBoard("EmployeesStat") && <Route path='/EmployeesStat' render={() => <EmployeesStat Type="EmployeesStat" />} />}
					{checkBoard("Revenue") && <Route path='/Revenue' render={() => <RevenueStat Type="Revenue" />} />}

					{/* Site */}
					{checkBoard("Catalog") && <Route path='/Catalog' render={() => <CatalogPage Type="Catalog" />} />}

					<Redirect from="/" to="/Dashboard" /> 
				</Switch>
			</main>
		)
	}
}

class Login extends Component {
	constructor(props) {
		super(props);

		this.state = { IsFocused: false };
	}

	/** API запрос «Login» на авторизацию */
	handleSubmit (event) {
		event.preventDefault();
		authUserState.ButtonLoading = true;

		getRequest("Login", authUserState.getLoginUser("login"), (data) => {
			if (data.Success) this.props.checkAuth();
			authUserState.LoginError = /WRONG_PARAMS/.test(data.ErrorCode) ? "WRONG_USER" : data.ErrorCode;
			authUserState.RetriesLeft = data.RetriesLeft;
			authUserState.ButtonLoading = false;
		});
	}

	/** API запрос «ResetPassword» на восстановление пароля */
	handleReset (event) {
		event.preventDefault();
		authUserState.ButtonLoading = true;

		getRequest("ResetPassword", authUserState.getLoginUser("recover"), (data) => {
			authUserState.LoginError = data.Success ? "SUCCESS_RECOVER" : data.ErrorCode;
			authUserState.ButtonLoading = false;
		});
	}

	/** Событие на фокус на поле ввода для скрытия лого на Android */
	handleFocus (id) { this.setState({ IsFocused : true }); }
	/** Событие на уход фокуса с поля ввода для скрытия лого на Android */
	handleBlur (id) { this.setState({ IsFocused : false }); }

	/** Обработка клика на восстановление пароля */
	resetPassword (event) {
		event.preventDefault();
		authUserState.IsRecoverPass = !authUserState.IsRecoverPass;
	}

	/** API запрос «GetNewPassword» на восстановление пароля */
	handleResetAccept (event) {
		event.preventDefault();
		authUserState.ButtonLoading = true;

		getRequest("GetNewPassword", { Token : this.props.RecoverParams.Token}, (data) => {
			authUserState.LoginError = data.Success ? "SUCCESS_RECOVER" : data.ErrorCode;
			authUserState.ButtonLoading = false;
		});
	} 

	render () {
		var error = errors[authUserState.LoginError] || errors.DEFAULT,
			retries = ". Осталось попыток: " + authUserState.RetriesLeft,
			isRecoverResult = authUserState.IsRecoverPass && authUserState.LoginError === "SUCCESS_RECOVER",
			isResetResult = this.props.RecoverState && authUserState.LoginError === "SUCCESS_RECOVER";

		if (authUserState.IsCheckAuth && authUserState.IsAuth) return null;
		if (this.props.RecoverState) return (
			<div className="backPage">
				<div id="loginPage" className='recover'>
					<img src={logo} alt="ectrum logo" />
					
					<form className="dark" onSubmit={this.handleResetAccept.bind(this)}>
						{!isResetResult &&
							<>
								<p>Для завершения процедуры сброса пароля нажмите на кнопку «Подтвердить»</p>
								{authUserState.LoginError !== "" && <p className="error">{error}</p>}
								<SubmitButton Title="Подтвердить" Loading={authUserState.ButtonLoading} />
							</>
						}
						{isResetResult &&
							<>
								<p>На указанную вами почту было отправлено письмо с данными для входа в личный кабинет.</p>
								<p>Если письмо долго не приходит, проверьте папку спам</p>
							</>
						}
					</form>
				</div>
			</div>
		)

		return(
			<div className="backPage">
				<div id="loginPage">
					{(!uistate.IsTablet || (uistate.IsTablet && !this.state.IsFocused)) &&
						<img src={logo} alt="ectrum logo" />
					}
					{authUserState.IsCheckAuth && !authUserState.IsAuth && !authUserState.IsRecoverPass &&
						<form className="dark" onSubmit={this.handleSubmit.bind(this)} >
							<TextInput 
								Id="Login" 
								Title="Логин" 
								Required={true} 
								Type={"tel"}
								Mask={uistate.IsMobile ? "" : "7 (999) 999-99-99"} 
								Value={authUserState.LoginUser.Login} 
								onChange={(value, id) => authUserState.collectLoginUser(id, value, "login")}
								onFocus={this.handleFocus.bind(this)}
								onBlur={this.handleBlur.bind(this)}
							/>
							<TextInput 
								Id="Password" 
								Title="Пароль" 
								Type={uistate.IsMobile && !uistate.IsTablet ? "password" : "text"}
								Required={true} 
								RowClass="pass" 
								Value={authUserState.LoginUser.Password} 
								onChange={(value, id) => authUserState.collectLoginUser(id, value, "login")}
								onFocus={this.handleFocus.bind(this)}
								onBlur={this.handleBlur.bind(this)}
							/>
							{authUserState.LoginError !== "" && <p className="error">{error} {retries}</p>}
							<SubmitButton Title="Войти" Loading={authUserState.ButtonLoading} />
							<a href='/' onClick={this.resetPassword.bind(this)}>Забыли пароль?</a>
						</form>
					}
					{authUserState.IsCheckAuth && !authUserState.IsAuth && authUserState.IsRecoverPass &&
						<form className="dark" onSubmit={this.handleReset.bind(this)}>
							{!isRecoverResult &&
								<>
									<p>Введите ваш логин для восстановления пароля</p>
									<TextInput 
										Id="Login" 
										Title="Логин" 
										Required={true} 
										Type={"tel"}
										Mask={uistate.IsMobile ? "" : "7 (999) 999-99-99"}
										Placeholder="7 (___) ___-__-__" 
										Value={authUserState.RecoverUser.Login} 
										onChange={(value, id) => authUserState.collectLoginUser(id, value, "recover")}
										onFocus={this.handleFocus.bind(this)}
										onBlur={this.handleBlur.bind(this)}
									/>
									{authUserState.LoginError !== "" && <p className="error">{error}</p>}
									<SubmitButton Title="Подтвердить" Loading={authUserState.ButtonLoading} />
									<a href='/' onClick={this.resetPassword.bind(this)}>Вернуться к вводу пароля</a>
								</>
							}
							{isRecoverResult &&
								<>
									<p>На указанную вами почту было отправлено письмо, для восстановления пароля следуйте инструкциям из письма.</p>
									<p>Если письмо долго не приходит, проверьте папку спам</p>
								</>
							}
						</form>
					}
				</div>
			</div>
		)
	}
}
Login = observer(Login);

class Register extends Component {
	/** API запрос RegisterCheckMerchant для регистрации предприятия чек листы */
	handleSubmit (event) {
		event.preventDefault();
		
		if (!authUserState.validateRegister)
			return false;
		
		authUserState.ButtonLoading = true;

		getRequest("RegisterCheckMerchant", authUserState.getRegisterUser(), (data) => {
			if (data.Success) {
				this.props.changeRegisterUrl();
				if (data.SessionCreated) {
					setTimeout(() => { this.props.checkAuth(); }, 3000);
					// TODO показать окно про письмо - добавить в мерчанта
					// TODO  вызвать чек аус
					// TODO если не создалась сессия оставить как есть
				}
			}
			else authUserState.LoginError = data.ErrorCode;
			
			authUserState.ButtonLoading = false;
		});
	}

	render () {
		if (authUserState.IsCheckAuth && authUserState.IsAuth) return null;

		return(
			<div className="backPage">
				<div id="loginPage" className='register'>
					<img src={logo} alt="ectrum logo" />
					{!this.props.IsRegisterSuccess &&
						<form className="dark" onSubmit={this.handleSubmit.bind(this)} >
							<p>Заполнив форму, вы создаете личный кабинет, который откроет доступ ко всем возможностям нашего сервиса чек листов.</p>
							<TextInput 
								Id="MerchantName" 
								Title="Название вашей организации" 
								Required={true} 
								Value={authUserState.RegisterUser.MerchantName} 
								onChange={(value, id) => authUserState.collectLoginUser(id, value, "register")}
							/>
							<TextInput 
								Id="UserFirstName" 
								Title="Ваше имя" 
								Required={true} 
								Value={authUserState.RegisterUser.UserFirstName} 
								onChange={(value, id) => authUserState.collectLoginUser(id, value, "register")}
							/>
							<TextInput 
								Id="UserPhone" 
								Title="Номер телефона" 
								Required={true} 
								Type={"tel"}
								Mask={uistate.IsMobile ? "" : "7 (999) 999-99-99"} 
								// Placeholder="7 (___) ___-__-__" 
								
								Value={authUserState.RegisterUser.UserPhone} 
								onChange={(value, id) => authUserState.collectLoginUser(id, value, "register")}
							/>
							<TextInput 
								Id="UserEmail" 
								Title="Ваш email"
								Type="email"
								Required={true} 
								Value={authUserState.LoginUser.UserEmail} 
								onChange={(value, id) => authUserState.collectLoginUser(id, value, "register")}
							/>
							<Selectize
								Id="CityId"
								Title="Город"
								Value={authUserState.RegisterUser.CityId} 
								List={toSelectList(authUserState.AllCities)}
								onChange={(value, id) => authUserState.collectLoginUser(id, value, "register")}
							/>
							{authUserState.LoginError !== "" && <p className="error">{getErrorText(authUserState.LoginError)}</p>}
							<SubmitButton Title="Зарегистрироваться" 
								Loading={authUserState.ButtonLoading} 
								Disabled={!authUserState.validateRegister}
							/>
						</form>
					}
					{this.props.IsRegisterSuccess &&
						<>
							<p>Спасибо за регистрацию в «Ectrum — Чек листы»! Сейчас вы будете перенаправлены в ваш личный кабинет.</p>
							<p>На указанную вами почту были отправлены авторизационные данные для использования сервиса.</p>
							<p>Если письмо не пришло, проверьте папку «Спам» или напишите нам в тех поддержку на <a href="mailto:support@ectrum.ru">support@ectrum.ru</a></p>
						</>
					}
				</div>
			</div>
		)
	}
}
Register = observer(Register);


export default App;