import { observable, decorate, computed } from 'mobx';
import _ from 'lodash';
import { authUserState, uistate } from './common';
import { OrderReceivingTitles, POSTypesTitles, PrintFormatesTitles } from '../dictionary';
import { getRubs, getLocalShift, formatDate, intToBitArray, getStatusBySystem, checkRights, 
	clearEmptyParams, getTrafficLight, stringToObject, objectToString } from '../functions';
import moment from 'moment';
import cookie from 'react-cookies';

class OrderState {
	Orders = [];
	IsSendRequest = false;
	OrderInterval = undefined;

	ShouldUpdate = false;
	UserAction = false;

	Filials = [];

	Filter = {};
	AddFilter = {
		FilterTime : [],
		OrderNumber : "",
		CustomerLogin : "",
		CustomerName : "",
		CustomerAddress : "",
		AggregatorId : "",
		CourierId : -1,
	}
	ShowAddFilter = false;

	AvailableCouriers = [];
	AllCouriers = [];

	RemoveOrder = undefined;
	PaymentOrder = undefined;

	Order = undefined;
	Bill = undefined;
	PrintFormat = -1;
	QRCode = undefined;

	ButtonLoading = false;
	ErrorCode = "";
	SuccessSave = false;
	OrderStateActive = "info";

	Products = [];
	SectionProducts = [];
	SectionActive = -1;

	Customer = undefined;
	ShowCustomerCard = false;
	CustomersList = [];

	ReferenceTime = "";
	WorkShopId = -1;

	Filials = [];
	Sections = [];
	Products = [];
	SearchProducts = "";
	ProductTags = [];
	Tags = [];

	ShowTagFilter = false;
	ShowProducts = false;

	OrderDummy = {
		Id: -1,
		CreationDate: "",
		ShiftDate: "",
		UserId: -1,
		MerchantId: -1,
		OrderReceivingType: 3,
		HomeOrder: false,

		MustCompletedTime: "",
		OrderTypeId: -1,
		OrderToTime: false,
		Amount: "",
		TotalAmount: "",
		ChangeFrom: "",
		Discount: 0,
		CustomerId: -1,
		CustomerLogin: "",
		AggregatorId: -1,
		BrandId: -1,
		PaymentMethodId: -1,
		TableNumber: "",
		POSId: -1,
		FilialId: -1,
		DeliveryAreaId : 0,
		PersonsNumber: 1,
		CityId: -1,
		DescriptionAdmin: "",
		DescriptionCook: "",
		Marks: [],
		ByAggregator: false,
		CustomerName: "",
		CustomerInfo: "",
		CustomerAddress: {},
		Paid: false
	}

	ProductDummy = {
		Id: -1,
		ProductId: -1,
		Count: "",
		ProductName: "",
		Recomendation: ""
	}
	AddressDummy = {
		Id: -1,
		City: "",
		Street: "",
		House: "",
		HouseBuilding: "",
		Entrance: "",
		Floor: "",
		Flat: "",
		Intercom: "",
		Main: false,
		Long: "",
		Lat: ""
	}
	CustomerTab = "info";

	orderHeight = 750;
	orderReferenceElement = undefined;

	/** ----------- ORDER LIST ----------- */
	/** Сброс фильтра заказов в исходное состояние */
	setFilter() {
		this.CookieString = "OrdersFilter_" + authUserState.User.Id + "_" + authUserState.Merchant;

		var filterString = cookie.load(this.CookieString),
			params = stringToObject(filterString),
			filter = {
				OrderType : params.OrderType,
				PaymentMethodId : params.PaymentMethodId,
				FilialId : params.FilialId,
				POSId : params.POSId,
				BrandId : params.BrandId,
				Status : params.Status
			};
		filter = _.omitBy(filter, function(v) { return _.isUndefined(v) || v === "undefined"; });
		
		this.Filter = _.extend({
			MerchantId: authUserState.MerchantType === 2 ? -1 : authUserState.Merchant,
			ShiftDate: formatDate(authUserState.Shift.Date, "r"),
			BrandId: -1,
			FilialId: -1,
			POSId: -1,
			PaymentMethodId: -1,
			Status: "",
			OrderType: -1,
			IsHome: false,
		}, filter);

		this.IsSendRequest = false;

		this.Orders = [];
		this.setOrder(undefined);
		this.Sections = [];
		this.SectionProducts = [];

		this.Bill = undefined;
		this.PrintFormats = [];
		this.PrintFormat = -1;
		this.OpenBill = false;

		this.setCustomer(undefined);
		this.CustomerTab = "info";
		this.ShowCustomerCard = false;
	}

	/** Получение ID списанного статуса
	 * @returns {number} ID статуса
	 */
	get WriteOffStatusId () {
		if (_.isUndefined(this.OrderStatuses)) return -1;
		var status = getStatusBySystem(this.OrderStatuses, undefined, undefined, 1);

		return status ? status.Id : -1;
	}

	/** Получение ID отказного статуса
	 * @returns {number} ID статуса
	 */
	get RejectStatusId () {
		if (_.isUndefined(this.OrderStatuses)) return -1;
		var status = getStatusBySystem(this.OrderStatuses, undefined, undefined, 4);

		return status ? status.Id : -1;
	}

	/**
	 * Получение высоты страницы для контейнера виртуализации
	 * @returns {number} высота контейнера
	 */
	get containerHeight () {
		var header = uistate.IsMobile ? 60 : uistate.IsTablet ? 20 : 85,
			width = uistate.WindowDimensions.width,
			height = uistate.WindowDimensions.height,
			padding = uistate.IsMobile || width <= 750 ? 5 : width <= 1030 ? 10 : width <= 1280 ? 15 : 20,
			titleHeight = 36,
			filterHeight = uistate.IsMobile ? 135 : this.ShowAddFilter ? 110 : 55 ;

		return height - header - padding - titleHeight - filterHeight;
	}

	/**
	 * Получение ширины страницы для контейнера виртуализации
	 * @returns {number} ширина контейнера
	 */
	get containerWidth () {
		var width = uistate.WindowDimensions.width,
			padding = uistate.IsMobile || width <= 750 ? 10 : width <= 1030 ? 20 : width <= 1280 ? 30 : 40;

		return width - (uistate.IsMobile ? 0 : uistate.IsSideBarOpen ? 260 : 50) - padding;
	}

	/**
	 * Формирования списка доступных статусов для конкретного заказа
	 * @param {number} merchant ID предприятия, в котором сделан заказ
	 * @param {number} orderType ID типа точка — зал/самовывоз/доставка
	 * @param {number} statusId ID текущего статуса заказа
	 * @returns {[object]} список доступных статусов заказа
	 */
	getOrderStatuses (merchant, orderType, statusId) {
		var allStatuses = orderState.OrderStatuses,
			writeOffStatusId = this.WriteOffStatusId,
			isWriteOff = statusId === this.WriteOffStatusId,
			statuses = _.filter(allStatuses, function(v) {
				if (_.indexOf(v.Merchants, merchant) === -1) return false;
				if (_.indexOf(v.POSType, orderType) === -1) return false;

				if (isWriteOff) return writeOffStatusId === v.Id;
				else return v.StatusType === 0 || writeOffStatusId === -1 || 
					(writeOffStatusId !== -1 && writeOffStatusId !== v.Id);
			})

		return statuses;
	}

	/** Получение списка филиалов и точек продаж для фильтра */
	get filialsFilterList () {
		var merchantId = this.Filter.MerchantId,
			filials = merchantId !== -1 ? _.filter(authUserState.OrderFilials, { MerchantId : merchantId }) :
				authUserState.OrderFilials;

		filials = _.flattenDeep(_.map(filials, function (filial) {
			return [{ Id : "F_" + filial.Id, Name : filial.Name }, _.map(filial.POS, function(pos) {
				return {
					Id : "F_" + filial.Id + "-P_" + pos.Id,
					Name : "-- " + pos.Name + " (" + filial.Name + ")" }
			})]
		}));
		return filials;
	}

	/** Получение списка предприятий для фильтра */
	get merchantsFilterList () {
		return _.filter(authUserState.Merchants, function (v) { return v.MerchantType !== 2; });
	}

	/** Получение списка доступных статусов для фильтра */
	get statusFilterList () {
		if (_.isEmpty(this.OrderStatuses) || _.isUndefined(this.OrderStatuses)) return [];

		var writeOff = this.WriteOffStatusId,
			statuses = _.filter(this.OrderStatuses, function (v) { 
				if (_.isNull(v.Merchants) || _.isEmpty(v.Merchants)) return false;
				return checkRights("CanViewWriteoffOrder") ? true : v.Id !== writeOff; 
			});
		
		return _.map(statuses, function(v) { 
			return { Key : v.Id, Value : v.Name, Color : v.Color };
		});
	}

	/** Получение списка доступных брендов для фильтра */
	get brandsFilterList () {
		if (_.isEmpty(this.Brands) || _.isUndefined(this.Brands)) return [];

		return authUserState.MerchantType === 2 ? this.Brands 
				: _.filter(this.Brands, { MerchantId : authUserState.Merchant });
	}

	/** Получение списка каналов приема заказа для фильтра */
	get receivingFilterList () {
		var receivingList = _.concat([{ Id : "Home_0", Name : "Свои заказы" }], 
				_.map(OrderReceivingTitles, function(v) {
					return { Id : "Home_" + v.Id, Name : "--" + v.Name }
				})),
			aggregators = _.isUndefined(this.Aggregators) ? [] : this.Aggregators,
			aggregatorsList = _.concat([{ Id : "Agg_0", Name : "Агрегаторы" }], 
				_.map(aggregators, function(v) {
					return { Id : "Agg_" + v.Id, Name : "--" + v.Name }
				}));

		return _.concat(receivingList, aggregatorsList);
	}

	/** Получение списка курьеров для фильтра */
	get couriersFilterList () {
		if (_.isEmpty(this.AllCouriers) || _.isEmpty(this.Orders)) return [];

		var couriersIds = _.uniq(_.map(this.Orders, "CourierId")),
			couriers =  _.filter(this.AllCouriers, function(v) {
				return _.indexOf(couriersIds, v.Id) !== -1;
			});

		return _.concat([{ Id : -2, Name : "Без курьера" }], couriers);
	}

	/**
	 * Формирование списка дат для фильтра в зависимости от прав пользователя 
	 * @param {boolean} withDates флаг отображать даты или только названия
	 * @param {boolean} withPeriod флаг отображать ли пункт «период»
	 */
	getDateList(withDates, withPeriod) {
		var date = authUserState.Shift.Date,
			tomorrow = moment(date).add(1, "d"),
			yesterday = moment(date).subtract(1, "d"),
			todayAPI = formatDate(date, "r"),
			pattern = ", DD.MM",
			dateList = [
				{ Id: formatDate(tomorrow, "r"), Name: "Завтра" + (withDates ? formatDate(tomorrow, pattern) : "") },
				{ Id: todayAPI, Name: "Сегодня" + (withDates ? formatDate(date, pattern) : "") },
			];

		if (checkRights("CanViewHistoryOrder")) dateList = _.concat(dateList, [{
			Id: formatDate(yesterday, "r"), Name: "Вчера" + (withDates ? formatDate(yesterday, pattern) : "")
		}, {
			Id: formatDate(moment(date).weekday(0), "r") + "_" + formatDate(moment(date).weekday(6), "r"), 
			Name: "Неделя"
		}, {
			Id: formatDate(moment(date).startOf("month"), "r") + "_" + formatDate(moment(date).endOf("month"), "r"), 
			Name: "Месяц"
		}]);

		if (withPeriod) dateList = _.concat(dateList, [{ Id: "period", Name: "Период" }]);

		return dateList;
	}

	/**
	 * Сбор данных с формы поиска заказов
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectFilter(id, value) {
		if (/IsHome/.test(id)) this.Filter.IsHome = value === 2;
		else this.Filter[id] = value;

		cookie.save(this.CookieString, objectToString(this.Filter));
	}

	/** Форматирование данных для запроса к серверу поиска заказов в интерфейсе администратора */
	getFilterData() {
		var data = _.omitBy(_.clone(this.Filter), function (v, i) {
			return (v === "" || v === false || v === -1 || v === "-1") && i !== "IsHome" && i !== "CourierId";
		});

		data.IsHome = data.IsHome ? 1 : 0;

		if (data.FilialId) {
			if (data.FilialId.indexOf("-") !== -1) {
				var pos = data.FilialId.split("-")[1];
				data.POSId = pos.replace("P_", "");
				data = _.omit(data, "FilialId");
			} else data.FilialId = data.FilialId.replace("F_", "");
		}

		if (data.ShiftDate && data.ShiftDate !== "period") {
			var dates = data.ShiftDate.split("_"),
				pattern = "YYYY-MM-DD 00:00";

			data = _.extend(data, {
				ShiftDateFrom: formatDate(dates[0], pattern),
				ShiftDateTo: dates[1] ? formatDate(dates[1], pattern) : formatDate(dates[0], pattern)
			});
			data = _.omit(data, "ShiftDate");
		}
		if (data.Status) data.Status = _.isArray(data.Status) ? data.Status.join(",") : data.Status;
		return data;
	}

	/**
	 * Разбор данных со списком заказов
	 * @param {object} data ответ от сервера с набором заказов
	 */
	setOrders(data) {
		var store = this,
			orders = data.Success ? _.map(data.Orders, function (item) {
				var times = store.setOrderTimes(item);
				
				return _.extend(item, {
					IsLate: times.LeftMinutes < 0,
					LeftMinutes: times.LeftMinutes,
					Light: times.Light,
					LeftCook: times.LeftCook,
					LightCook: times.LightCook,
					CompleteMinutes: times.CompleteMinutes,
					ToTime: times.ToTime,
					Products: _.map(item.Products, function (product) {
						return _.extend(product, { Count: _.sumBy(product.Prices, "Count") })
					})
				});
			}) : [];

		this.Orders = orders;
		
		this.IsSendRequest = true;
		this.ShouldUpdate = false;
		this.ShowCourier = -1;
		this.SaleContent = undefined;
		this.CheckOrderCustomer = false;
	}

	/** Сбор статистики по заказам
	 * @returns {object} статистика заказов {Count, Amount, Avg}
	 */
	get orderStat () {
		if (_.isEmpty(this.sortedOrders)) return {};

		return {
			Count: this.sortedOrders.length,
			Amount: _.sumBy(this.sortedOrders, "TotalAmount"),
			Avg: (_.sumBy(this.sortedOrders, "TotalAmount") / this.sortedOrders.length).toFixed(0)
		}
	}

	/** Список заказов, отфильтрованный по параметрам, не спользующих API запрос */
	get sortedOrders () {
		if (_.isEmpty(this.Orders)) return [];

		var filter = this.AddFilter,
			isLate = _.indexOf(filter.FilterTime, "IsLate") !== -1,
			toTime = _.indexOf(filter.FilterTime, "ToTime") !== -1,
			aggId = filter.AggregatorId === -1 ? 0 : _.parseInt(filter.AggregatorId.split("_")[1], 10),
			isHome = /Home/.test(filter.AggregatorId),
			receiving = isHome ? aggId : 0,
			isAgg = /Agg/.test(filter.AggregatorId),
			aggregator = isAgg ? aggId : 0,
			noCourier = filter.CourierId === -2,
			courierId = filter.CourierId;

		return _.filter(this.Orders, function(item) {
			var tt = toTime ? item.ToTime : true,
				il = isLate ? item.IsLate && !item.IsFinalStatus : true,
				name = _.toLower(item.CustomerName),
				address = _.isEmpty(item.CustomerAddress) ? "" : _.toLower(item.CustomerAddress.Settlement)
					+ " " + _.toLower(item.CustomerAddress.Street),
				on = filter.OrderNumber === "" ? true : filter.OrderNumber === item.OrderNumber,
				cn = filter.CustomerName === "" ? true : _.startsWith(name, filter.CustomerName),
				cl = filter.CustomerLogin === "" ? true : item.CustomerLogin.indexOf(filter.CustomerLogin) !== -1,
				hasAgg = item.AggregatorId > 0,
				rt = isHome ? receiving === 0 ? !hasAgg : !hasAgg && item.OrderReceivingType === receiving : true,
				ag = isAgg ? aggregator === 0 ? hasAgg : item.AggregatorId === aggregator : true,
				ad = address === "" || filter.CustomerAddress === "" ? true
					: address.indexOf(filter.CustomerAddress) !== -1,
				cu = courierId === -1 ? true : noCourier ? item.CourierId === -1 : item.CourierId === courierId;

			return tt && il && on && cn && cl && rt && ag && ad && cu;
		});
	}

	/** Флаг, что все заказы принадлежат одному филиалу */
	get IsOneFilial() {
		if (_.isEmpty(this.Orders)) return false;

		var filials = _.uniq(_.map(this.Orders, "FilialId"));
		return filials.length === 1;
	}

	/**
	 * Расчет временных параметров заказа
	 * @param {object} order объект заказа
	 * @param {number} reference эталонное время в минутах
	 * @return {object} PastMinutes – сколько прошло, LeftMinutes – сколько осталось, CompleteMinutes — за сколько завершен, ToTime — флаг ко времени, Light — цвет подсветки, 
	 */
	setOrderTimes(order, reference) {
		var cityUTC = getLocalShift(order.CityId),
			nowLocal = moment().add(cityUTC, "minutes"),
			create = order.CreationTime === "" ? "" : moment(order.CreationTime),
			must = order.MustCompletedTime === "" ? "" : moment(order.MustCompletedTime),
			lead = order.LeadTime === "" ? "" : moment(order.LeadTime),
			referenceTime = reference ? reference : order.ReferenceTime,
			releaseId = this.EndReleaseStatusId,
			releaseIndex = _.findIndex(this.OrderStatuses, { Id : releaseId }),
			currentIndex = _.findIndex(this.OrderStatuses, { Id : order.StatusId }),
			hasCooked = currentIndex >= releaseIndex,
			cookReference = this.getReferenceMinutes(order.ReferenceTimes, releaseId),
			mustCook = must !== "" ? (must.clone()).subtract(referenceTime - cookReference, "minutes") : "",
			minTime = cityUTC + (referenceTime === "" ? 0 : referenceTime)

		var complete = lead === "" || create === "" ? null : lead.diff(create, "minutes"),
			past = create === "" ? null : nowLocal.diff(create, "minutes"),
			left = create === "" ? null : must !== "" ? must.diff(nowLocal, "minutes") : referenceTime - past,
			leftCook = create === "" ? null : mustCook !== "" ? mustCook.diff(nowLocal, "minutes") : cookReference - past;

		return {
			PastMinutes: past,
			LeftMinutes: left,
			LeftCook : hasCooked ? null : leftCook,
			CompleteMinutes: complete,
			ToTime: order.MustCompletedTime !== "",
			Light: getTrafficLight(left, referenceTime),
			LightCook: getTrafficLight(leftCook, cookReference),
			ShiftToTime: minTime
		}
	}

	/**
	 * Получение эталонного кол-ва минут, в зависимости от статуса
	 * @param {[object]} reference набор эталонного времени по статусам
	 * @param {number} statusId ID статуса
	 * @returns {number} эталонное кол-во минут
	 */
	getReferenceMinutes (reference, statusId) {
		var minutes = 0;

		_.each(reference, function (status) {
			if (status.StatusId === statusId) return false;
			minutes += status.Time;
		});

		return minutes;
	}

	/**
	 * Фильтрация зон по доступности бренда заказа
	 *  @returns {[object]} список отфильтрованных зон
	 */
	get OrderDeliveryAreas() {
		if (_.isUndefined(this.Order) || this.Order.BrandId < 1) return [];

		var brandId = this.Order.BrandId;
		return _.filter(this.DeliveryAreas, function (v) {
			return _.flatten(_.map(v.Filials, "Brands")).indexOf(brandId) !== -1;
		});
	}

	/** ----------- ORDER HISTORY BLOCK ----------- */
	/**
	 * Разбор данных для истории заказа
	 * @param {object} order заказ
	 * @param {number} statusId ID статуса
	 */
	setOrderHistory(history, bound, user) {
		this.OrderHistory = _.isUndefined(history) ? undefined : {
			History : history, 
			Position : { left : bound.left, top : bound.top + bound.height + 5 },
			User : user
		}

		orderState.UserAction = !_.isUndefined(history);
	}

	/** ----------- QRCODE ORDER IN LIST ----------- */
	setQRCode(order) {
		this.QRCode = _.isUndefined(order) ? undefined : {
			OrderId : order.Id,
			OrderNumber : order.OrderNumber
		}

		this.UserAction = !_.isUndefined(order);
	}

	/** ----------- REMOVE ORDER FROM LIST ----------- */
	/**
	 * Разбор данных для отказа/списания выбранного заказа
	 * @param {object} order заказ
	 * @param {number} statusId ID статуса
	 */
	setRemoveOrder(order, statusId, rejectMode) {
		this.RemoveOrder = _.isUndefined(order) ? undefined : {
			Id : order.Id,
			MerchantId : order.MerchantId,
			OrderNumber : order.OrderNumber,
			CancelReasonId : order.CancelReasonId,
			StatusId : statusId,
			Products : order.Products,
			RejectMode : rejectMode,
			ReleasedProducts : _.map(_.filter(order.Products, function (v) {
				return v.Relealed > 0; }), "ProductId")
		}

		orderState.UserAction = !_.isUndefined(order);
	}
	
	/**
	 * Сбор данных с формы списания заказа
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectRemoveOrder(id, value) {
		if (/ReleasedProducts/.test(id)) {
			var product = id.split("_")[1];

			if (product && value)
				this.RemoveOrder.ReleasedProducts = _.concat(this.RemoveOrder.ReleasedProducts, parseInt(product, 10));
			else if (product && !value)
				this.RemoveOrder.ReleasedProducts = _.without(this.RemoveOrder.ReleasedProducts, parseInt(product, 10));
		} else if (/CancelReasonId/.test(id)) this.RemoveOrder.CancelReasonId = value;
		else this.RemoveOrder[id] = value;
	}

	/** Сбор данных для отправки запроса на списание заказа
	 * @returns {object} данные по отказу/списанию заказа
	 */
	getRemoveData() {
		var data = _.clone(this.RemoveOrder);

		if (!this.RemoveOrder.RejectMode)
			_.each(data.ReleasedProducts, function (v, i) {
				var name = "ReleasedProducts." + i + ".",
					product = _.find(data.Products, { ProductId: v });

				data[name + "ProductId"] = product.ProductId;
				data[name + "Count"] = product.Count;
			});

		data = _.omit(data, ["Products", "ReleasedProducts", "RejectMode"]);

		return data;
	}
	/** ----------- PAY ORDER IN LIST ----------- */
	/**
	 * Разбор данных для оплаты/возврата активного заказа
	 * @param {object} order заказ
	 * @param {number} type тип операции: 1 — оплата, 2 — отмена, 3 — возврат
	 * @param {object} method 
	 */
	setPaymentOrder (order, type, method) {
		this.PaymentOrder = _.isUndefined(order) ? undefined : {
			Type : type,
			Id : order.Id,
			OrderNumber : order.OrderNumber,
			POSId : order.POSId,
			POS : order.POSName,
			FilialId : order.FilialId,
			IsFiscal : order.IsFiscal,
			PaymentMethodId : order.PaymentMethodId,
			IsCassa : method.ShowCassa,
			PaymentMethod : method.Name,
			ButtonLoading : false
		}

		orderState.UserAction = !_.isUndefined(order);
	}

	/** ----------- CHANGE CUSTOMER ----------- */
	/**
	 * Разбор данных заказа для смены клиента в заказе
	 * @param {object} order информация о заказе
	 */
	setChangeOrderCustomer(order) {
		this.ChangeOrderCustomer = _.isUndefined(order) ? undefined : {
			CustomerLogin : "",
			CustomerName : "",
			OrderId : order.Id,
			OldCustomerName : order.CustomerName,
			OrderNumber : order.OrderNumber,
			ButtonLoading: false,
			ErrorCode: ""
		}
	}

	/**
	 * Сбор данных с формы смены клиента
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectChangeOrderCustomer(value, id) { this.ChangeOrderCustomer[id] = value; }

	/**
	 * Проверка валидности введенных в форму данных
	 * @returns {boolean} флаг валидность данных
	 */
	validateChangeOrderCustomer() {
		var isValid = true;

		if (this.ChangeOrderCustomer.CustomerLogin.replace(/\D/gi, "").length < 11) {
			this.ChangeOrderCustomer.ErrorCode = "WRONG_PHONE_LENGTH";
			return false;
		}

		if (this.ChangeOrderCustomer.CustomerName === "") {
			this.ChangeOrderCustomer.ErrorCode = "CUSTOMER_NAME_REQUIRED";
			return false;
		}

		return isValid;
	}

	/**
	 * Подготовка данных для отправки на сервер
	 * @returns {object} данные о смене клиента в заказе
	 */
	getChangeOrderCustomer () {
		return {
			CustomerLogin : this.ChangeOrderCustomer.CustomerLogin.replace(/\D/gi, ""),
			CustomerName : this.ChangeOrderCustomer.CustomerName,
			OrderId : this.ChangeOrderCustomer.OrderId
		}
	}

	/** ----------- ORDER ITEM ----------- */
	/** 
	 * Функция, отдающая доступные типы точек продаж с учетом выбранного бренда
	 * @return {[number]} POSTypes доступные типы точек
	 */
	get POSTypes() {
		if (_.isEmpty(authUserState.OrderFilials)) return [];

		var brandId = this.Order.BrandId,
			canHome = checkRights("CanViewHomeOrders"),
			filials = _.flatten(_.map(authUserState.OrderFilials, function (filial) {
				var poses = _.map(filial.POS, function (pos) {
					var brands = _.map(pos.Brands, "Id"),
						types = _.without(pos.Types, 0);

					return brandId === -1 ? types : _.indexOf(brands, brandId) !== -1 ? types : []
				});

				return _.uniq(_.flatMap(poses))
			})),
			types = canHome ? POSTypesTitles : _.reject(POSTypesTitles, { Id : 4 });

		return _.filter(types, function (v) { return _.indexOf(filials, v.Id) !== -1; });
	}

	/**
	 * Функция, отдающая доступные филиалы с учетом выбранного бренда, города и типа точки продаж
	 * @return {[object]} availableFilials доступные филиалы
	 */
	get availableFilials() {
		if (_.isEmpty(authUserState.OrderFilials) || _.isNull(authUserState.OrderFilials)) return [];

		var merchantId = this.Order.MerchantId,
			brandId = this.Order.BrandId,
			posType = this.Order.OrderTypeId,
			cityId = this.Order.CityId,
			posTypes = _.map(this.POSTypes, "Id");

		return _.compact(_.map(authUserState.OrderFilials, function (filial, i) {
			if (filial.MerchantId !== merchantId) return undefined;
			if (filial.CityId !== cityId) return undefined;
			if (_.isNull(filial.POS || _.isEmpty(filial.POS))) return undefined;

			var pos = filial.POS,
				types = _.uniq(_.flatMap(_.map(_.clone(pos)), "Types")),
				brands = _.map(_.uniqBy(_.flatMap(_.map(_.clone(pos)), "Brands"), "Id"), "Id");

			if (_.indexOf(types, posType) === -1 || _.isEmpty(_.intersection(posTypes, types))
				|| _.indexOf(posTypes, posType) === -1) return undefined;
			if (_.indexOf(brands, brandId) === -1) return undefined;

			return {
				Id: filial.Id,
				Name: filial.Name,
				Address: filial.Address,
				Brands: _.uniqBy(_.flatMap(_.map(_.clone(pos)), "Brands"), "Id"),
				PaymentMethods: _.uniqBy(_.flatMap(_.map(_.clone(pos)), "PaymentMethods"), "Id"),
				Aggregators: _.uniqBy(_.flatMap(_.map(_.clone(pos)), "Aggregators"), "Id"),
				Types: _.uniq(_.flatMap(_.map(_.clone(pos)), "Types")),

				POS: _.map(_.clone(pos), function (posItem) {
					return {
						Id: posItem.Id,
						Name: posItem.Name,
						Main: posItem.Main,
						Types: _.without(posItem.Types, 0),
						Brands: _.map(posItem.Brands, "Id"),
						PaymentMethods: _.map(posItem.PaymentMethods, "Id"),
						Aggregators: _.map(posItem.Aggregators, "Id")
					}
				})
			}
		}))
	}

	/** 
	 * Функция, отдающая информацию по выбранному филиалу
	 * @return {object} currentFilial выбранный филиал
	 */
	get currentFilial() { return _.find(this.availableFilials, { Id: this.Order.FilialId }); }

	/** Выбор активного филиала */
	setFilial(data) {
		this.OrderFilial = data.Success ? { Id: data.Id, WorkDays: data.WorkDays, POS: data.POS } : {};
	}

	/** Функция, отдающая время работы выбранного филиала */
	get workTimes() {
		if (_.isEmpty(this.OrderFilial) || _.isEmpty(this.OrderFilial.POS) || _.isEmpty(this.OrderFilial.WorkDays))
			return [];

		var store = this,
			type = this.Order.OrderTypeId,
			typeShift = _.find(_.flatMap(_.map(this.OrderFilial.POS, "Types")), { Type: type });

		return _.map(this.OrderFilial.WorkDays, function (day) {
			return {
				Day: day.Day,
				MinTime: store.getTime(day.StartTime, typeShift.FirstToTime),
				MaxTime: store.getTime(day.EndTime, typeShift.LastToTime)
			}
		});
	}

	/** 
	 * Функция, высчитывающая время работы филиала с сдвигом от типа точки 
	 * @param {string} timeString время работы филиала в формате «HH:mm»
	 * @param {number} shift сдвиг работы в минутах
	 */
	getTime(timeString, shift) {
		var time = moment(timeString, "HH:mm"),
			timeShift = shift > 0 ? time.add(shift, "minutes") : time.subtract(Math.abs(shift), "minutes");
		return timeShift.format("HH:mm");
	}

	/**
	 * Разбор списка раздел и продукции
	 * @param {[object]} sections массив с разделами продукции
	 * @param {[object]} products массив с продукцией
	 */
	setProducts(sections, products) {
		this.Sections = _.filter(sections, { IsHidden: false, System: false });

		var sec = _.map(this.Sections, "Id");

		this.SectionProducts = _.filter(products, function (v) { return _.indexOf(sec, v.SectionId) !== -1; });
	}

	/** Фильтрация разделов по брендам */
	get filteredSections() {
		if (_.isEmpty(this.Sections)) return [];

		var brand = this.Order.BrandId;
		return _.filter(this.Sections, function (v) {
			return _.indexOf(["", -1, 0], brand) !== -1 ? true : _.indexOf(v.Brands, brand) !== -1;
		});
	}

	/** Фильтруем продукты для выдачи в поисковой строке */
	get productsInSection() {
		if (_.isEmpty(this.SectionProducts)) return [];

		var sectionId = this.SectionActive,
			sections = _.map(this.filteredSections, "Id"),
			cityId = this.Order ? this.Order.CityId : -1,
			filteredProducts = _.filter(this.SectionProducts, function(v) {
				if (v.Modificators === 2) return false;
				if (_.indexOf(sections, v.SectionId) === -1) return false;
				
				return sectionId === -1 ? true : v.SectionId === sectionId;
			});

		return _.map(filteredProducts, function (item) {
			var price = _.find(item.Prices, { CityId : cityId })
			return { Id : item.Id, Name : item.Name + (price ? (", " + getRubs(price.Price, true)) : "") }
		});
	}

	/** Фильтрация продукции по строке поиска и по тэгам продукции */
	get filteredProducts() {
		if (_.isEmpty(this.SectionProducts)) return [];
		
		var sectionId = this.SectionActive,
			sections = _.map(this.filteredSections, "Id"),
			cityId = this.Order ? this.Order.CityId : -1,
			productTags = this.ProductTags,
			search = _.toLower(this.SearchProducts),
			disabledAll = this.IsEndProcess || this.IsFiscalPaidOrder || this.Order.IsFinalStatus,
			orderTypeId = this.Order.OrderTypeId,
			filteredProducts = _.filter(this.SectionProducts, function(v) {
				if (_.indexOf(sections, v.SectionId) === -1) return false;

				var isSection = sectionId === -1 ? true : v.SectionId === sectionId,
					isSearch = search === "" ? true : (_.toLower(v.Name)).indexOf(search) !== -1,
					isTags = _.isEmpty(productTags) ? true
						: _.isEqual(_.intersection(productTags, v.Tags), productTags);

				return isSection && isSearch && isTags;
			});

		return _.sortBy(_.map(filteredProducts, function (item) {
			var price = _.find(item.Prices, { CityId : cityId }),
				disabled = disabledAll || item.Modificators === 2 ||
					_.indexOf(item.POSTypes, orderTypeId) === -1,
				posCount = checkRights("CanViewHomeOrders") ? 4 : 3,
				posTypes = item.POSTypes && item.POSTypes.length !== posCount 
					? "Только для " + _.map(item.POSTypes, function (v) {
					return _.find(POSTypesTitles, { Id : v }).Name; }).join(", ") : "";

			return _.extend(_.clone(item), { 
				Price : price ? price.Price : "",
				Disabled : disabled,
				POSTypesTitle : posTypes
			})
		}), "Name");
	}

	/**
	 * Фильтр продуктов по строке поиска или тэгам
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 * @param {string} type тип операции (input – ввод в текстовое поле, check – выбор элемента из списка)
	 */
	collectSearch(id, value, type) {
		if (/SearchProducts/.test(id)) {
			if (type === "input" && value !== "") this.SearchProducts = _.toLower(value);
			else if (type === "check") this.SearchProducts = "";
		} else if (/ProductTags/.test(id)) {
			if (_.indexOf(this.ProductTags, value) !== -1) this.ProductTags = _.without(this.ProductTags, value);
			else this.ProductTags = _.concat(this.ProductTags, value);
		}
	}

	/**
	 * Разбор данных о заказе
	 * @param {object} order ответ от сервера с информацией о заказе
	 */
	setOrder(order) {
		this.Order = _.isUndefined(order) ? undefined : order.Id === -1 ? _.extend(_.clone(this.OrderDummy), {
			CityId: authUserState.Cities.length === 1 ? authUserState.City : -1,
			MerchantId: authUserState.MerchantType === 2 ? -1 : authUserState.Merchant,
			OrderReceivingType: authUserState.MerchantType === 2 ? 2 : 3
		}, order) : _.extend(_.omit(_.clone(order), ["Success", "ErrorCode", "Products"]), {
			Marks: _.isNull(order.Marks) ? [] : order.Marks,
			ByAggregator: order.AggregatorId !== "" && order.AggregatorId > 0,
			OrderToTime: order.MustCompletedTime !== "",
			Discount: getRubs(order.Discount),
			Id: order.OrderId ? order.OrderId : order.Id,
			ChangeFrom: order.ChangeFrom !== 0 ? getRubs(order.ChangeFrom) : 0
		});

		this.POSId = _.isUndefined(order) ? -1 : this.Order.POSId;
		this.WithCustomer = _.isUndefined(order) ? false : order.Id === -1 ? true :
			order.CustomerLogin !== "";

		if (!_.isUndefined(order) && order.Id === -1)
			this.Order = _.extend(this.Order, { CustomerAddress: _.clone(this.AddressDummy) });

		this.Products = _.isUndefined(order) || _.isUndefined(order.Products) || _.isNull(order.Products) ? [] 
			: _.flatten(_.map(order.Products, function (v) {
				// TODO переделать расчет когда появится бонуска
				
				return _.map(v.Prices, function (item) {
					return {
						ProductId: v.ProductId,
						Count: item.Count,
						Price: item.Price,
						ProductName: v.ProductName,
						Started: v.Started,
						Released: v.Released,
						Modifications : _.isNull(v.Modifications) ? [] : v.Modifications
					}
				})
			}));

		this.ReferenceTime = _.isUndefined(order) || order.Id === -1 ? "" : order.ReferenceTime;

		this.ButtonLoading = false;
		this.ErrorCode = "";
		this.ShowTagFilter = false;
		this.OrderStateActive = "info";
		this.ProductTags = [];
		this.SearchProducts = "";
		this.IsChangeDeliveryZone = false;
	}

	/**
	 * Подстановка данных пользователя в заказ
	 * @param {object} data ответ от сервера данные пользователя
	 */
	setOrderCustomer(data) {
		if (data.Success) {
			if (_.isString(this.Order.Id) || this.Order.Id === -1) this.Order.CustomerName = data.Name;
			this.Order.CustomerInfo = data.CustomerInfo;

			if (_.isString(this.Order.Id) || this.Order.Id === -1)
				this.Order.CustomerAddress = _.isNull(data.Address) || data.Address.length === 0
					? _.clone(this.AddressDummy) : _.find(data.Address, { Main: true })
						? _.find(data.Address, { Main: true }) : data.Address[0];
			else this.Order.CustomerAddress = this.Order.CustomerAddressId > 0 ?
				_.find(data.Address, { Id: this.Order.CustomerAddressId }) : _.clone(this.AddressDummy);
		} else {
			this.Order.CustomerName = "";
			this.Order.CustomerInfo = "";
			this.Order.CustomerAddress = _.clone(this.AddressDummy);
		}
	}

	/**
	 * Сбор данных с формы редактирования заказа
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectOrder(id, value) {
		if (/ByAggregator/.test(id)) {
			this.Order.ByAggregator = value === 2;
			if (value !== 2) this.Order.AggregatorId = -1;
		} else if (/HomeOrder/.test(id)) this.Order.HomeOrder = value;
		else if (/Paid/.test(id)) this.Order.Paid = value;
		else if (/CustomerName/.test(id)) this.Order.CustomerName = _.upperFirst(value);
		else if (/OrderToTime/.test(id)) { 
			if (value !== 2) this.Order.MustCompletedTime = "";
			this.Order.OrderToTime = value === 2;
		} else if (/Marks/.test(id)) {
			if (_.indexOf(this.Order.Marks, value) !== -1) this.Order.Marks = _.without(this.Order.Marks, value);
			else this.Order.Marks = _.concat(this.Order.Marks, value);
		} else this.Order[id] = value;

		if (/BrandId/.test(id) || /MerchantId/.test(id)) this.Products = [];

		if (/BrandId/.test(id) || /CityId/.test(id)) {
			this.Order.FilialId = -1;
			this.Order.OrderTypeId = -1;
			this.Order.DeliveryAreaId = 0;
		}

		if (/DeliveryAreaId/.test(id)) {
			var area = _.find(this.DeliveryAreas, { Id : value });

			if (area) this.Order.FilialId = area.MainFilialId;
		}

		if (/BrandId/.test(id) || /FilialId/.test(id) || /PaymentMethodId/.test(id) || 
			/OrderTypeId/.test(id) || /CityId/.test(id) || /AggregatorId/.test(id) || 
			/DeliveryAreaId/.test(id)) this.POSId = -1;

		if (/OrderTypeId/.test(id)) this.Order.HomeOrder = value === 4;
	}
	/**
	 * Сбор данных с формы ввода адреса
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectAddress(id, value) { this.Order.CustomerAddress[id] = value; }
	/**
	 * Разбор ответа Dadata
	 * @param {object} value ответ от DaData
	 */
	setAddress(value) {
		// TODO ревакторинг - вынести в функции в месте с кастомерс (передавать куда записывать)
		this.Order.CustomerAddress.City = !_.isNull(value.city) ? value.city : 
			!_.isNull(value.area_with_type) ? value.area_with_type : "";
		this.Order.CustomerAddress.Settlement = !_.isNull(value.settlement_with_type) ? value.settlement_with_type : "";
		this.Order.CustomerAddress.Street = !_.isNull(value.street_with_type) ? value.street_with_type : "";
		this.Order.CustomerAddress.House = !_.isNull(value.house) ? value.house : "";
		this.Order.CustomerAddress.HouseBuilding = !_.isNull(value.block) ? value.block_type + " " + value.block : "";
		this.Order.CustomerAddress.Flat = !_.isNull(value.flat) ? value.flat : "";

		this.Order.CustomerAddress.Lat = !_.isNull(value.geo_lat) ? value.geo_lat : "";
		this.Order.CustomerAddress.Long = !_.isNull(value.geo_lon) ? value.geo_lon : "";
	}

	/** Флаг, что у данного предприятия необходимо округлять сумму к оплате до рублей
	 * @returns {boolean} округлять до рублей
	 */
	get RoundOrder () {
		var merchant = _.find(authUserState.Merchants, { Id: this.Order.MerchantId });
		return merchant ? merchant.RoundOrder : false;
	}

	/** Расчет суммы заказа
	 * @returns {number} сумма заказа в копейках
	 */
	get Amount () { 
		if (_.isUndefined(this.Order)) return 0;
		
		return _.sumBy(this.orderProducts, function(v) {
			return v.Amount + _.sumBy(v.Modifications, "Amount");
		});
	}
	/** Расчет суммы к оплате
	 * @returns {number} сумма к оплате в копейках
	 */
	get TotalAmount () { 
		if (_.isUndefined(this.Amount)) return 0;

		var total = this.Amount - this.Order.Discount * 100
		return this.RoundOrder ? (Math.round(total / 100) * 100).toFixed(0) : total; 
	}
	/** Расчет суммы сдачи
	 * @returns {number} сумма сдачи в копейках
	 */
	get Change () { 
		return _.isUndefined(this.Amount) || _.isUndefined(this.TotalAmount) || 
			_.indexOf([0, ""], this.Order.ChangeFrom) !== -1 ? 0 
			: this.Order.ChangeFrom * 100 - this.TotalAmount;
	}

	/** Получение ID статуса начала производства 
	 * @returns {number} ID статуса
	 */
	get StartReleaseStatusId () {
		if (_.isUndefined(this.OrderStatuses)) return -1;
		var status = getStatusBySystem(this.OrderStatuses, this.Order.MerchantId, undefined, 3);

		return status ? status.Id : -1;
	}
	/** Получение ID статуса окончания производства 
	 * @returns {number} ID статуса
	 */
	get EndReleaseStatusId () {
		if (_.isUndefined(this.OrderStatuses)) return -1;
		var status = getStatusBySystem(this.OrderStatuses, this.Order ? this.Order.MerchantId :
			authUserState.Merchant, undefined, 2);

		return status ? status.Id : -1;
	}

	/** Проверка начато ли производство данного заказа
	 * @returns {boolean} производство начато
	 */
	get IsStartProcess () {
		if (this.Order.Id === -1 || _.isString(this.Order.Id)) return false;
		if (_.isUndefined(this.StartReleaseStatusId) || this.StartReleaseStatusId === -1) return false;

		var current = _.findIndex(this.OrderStatuses, { Id : this.Order.StatusId }),
			start = _.findIndex(this.OrderStatuses, { Id : this.StartReleaseStatusId });

		return start === -1 || current === -1 ? false : current >= start;
	}

	/** Проверка завершено ли производство данного заказа
	 * @returns {boolean} производство завершено
	 */
	get IsEndProcess () {
		if (this.Order.Id === -1 || _.isString(this.Order.Id)) return false;
		if (_.isUndefined(this.EndReleaseStatusId) || this.EndReleaseStatusId === -1) return false;

		var current = _.findIndex(this.OrderStatuses, { Id : this.Order.StatusId }),
			end = _.findIndex(this.OrderStatuses, { Id : this.EndReleaseStatusId });

		return end === -1 || current === -1 ? false : current >= end;
	}

	/** Вычисление точки продаж заказа - ручное назначение или автоматически по алгоритму */
	get calculatedPosId() {
		if (_.isEmpty(this.availablePOS)) return -1;

		return this.POSId > 0 ? this.POSId : _.find(this.availablePOS, { Main: true }) ?
			_.find(this.availablePOS, { Main: true }).Id : _.first(this.availablePOS).Id;
	}

	/** Функция, отдающая доступные POS в зависимости от выбранных филиала, бренда, агрегатора и способа оплаты */
	get availablePOS() {
		if (_.isUndefined(this.currentFilial)) return [];

		var order = this.Order,
			poses = _.filter(this.currentFilial.POS, function (v) {
				var type = _.indexOf(v.Types, order.OrderTypeId) !== -1,
					brand = _.indexOf(v.Brands, order.BrandId) !== -1,
					aggregator = order.ByAggregator ? _.indexOf(v.Aggregators, order.AggregatorId) !== -1 : true,
					paymentMethod = _.indexOf(v.PaymentMethods, order.PaymentMethodId) !== -1;

				return type && brand && aggregator && paymentMethod;
			});

		return poses;
	}

	/** Функция, отдающая доступные POS в зависимости от выбранных филиала, бренда, агрегатора и способа оплаты */
	get availableMethods() {
		if (_.isUndefined(this.currentFilial)) return [];

		var order = this.Order,
			paymentMethods = this.PaymentMethods,
			poses = _.filter(this.currentFilial.POS, function (v) {
				var type = _.indexOf(v.Types, order.OrderTypeId) !== -1,
					brand = _.indexOf(v.Brands, order.BrandId) !== -1,
					aggregator = order.ByAggregator ? _.indexOf(v.Aggregators, order.AggregatorId) !== -1 : true;

				return type && brand && aggregator;
			}),
			methods = _.uniq(_.flatten(_.map(poses, "PaymentMethods")));

		methods = _.map(methods, function (v) {
			var method = _.find(paymentMethods, { Id: v });
			return { Id: v, Name: method.Name, Cassa: method.ShowCassa, Fiscal: method.Fiscal }
		});

		return methods;
	}

	/** Функция, определяющая имеет ли заказ фискальный признак
	 * @returns {boolean} флаг фискального заказа
	 */
	get IsFiscalOrder() {
		if (_.isUndefined(this.PaymentMethods) || _.isEmpty(this.PaymentMethods) ||
			_.isUndefined(this.Order) || this.Order.PaymentMethodId === -1) return false;

		var method = _.find(this.PaymentMethods, { Id: this.Order.PaymentMethodId });
		return method ? method.Fiscal : false;
	}

	/** Функция, определяющая наличный способ оплаты
	 * @returns {boolean} флаг метода оплаты «нал»
	 */
	get IsCashOrder() {
		if (_.isUndefined(this.PaymentMethods) || _.isEmpty(this.PaymentMethods) ||
			_.isUndefined(this.Order) || this.Order.PaymentMethodId === -1) return false;

		var method = _.find(this.PaymentMethods, { Id: this.Order.PaymentMethodId });
		return method ? method.ShowCassa : false;
	}

	/** Функция, определяющая что заказ фискализирован
	 * @returns {boolean} флаг фискализированного заказа
	 */
	get IsFiscalPaidOrder() {
		return _.isUndefined(this.Order) ? false : this.IsFiscalOrder && this.Order.Paid;
	}

	/** Определяем, что заказ уже создан и происходит его редактирование
	 * @returns {boolean} флаг существующего заказа
	 */
	get IsOrderExist() {
		return _.isUndefined(this.Order) ? false : this.Order.Id > 0 && _.isNumber(this.Order.Id);
	}

	/** Определяем, что курьеру выдали деньги на руки — сдача или заказ оплачен налом
	 * @returns {boolean} флаг денег на руках у курьера
	 */
	get IsCashInHand () {
		return _.isUndefined(this.Order) ? false : this.Order.CashInHand > 0;
	}

	/** Определяем, что в корзине все товары соответствуют типу подачи заказа
	 * @returns {boolean} флаг в корзине есть товар, не соответствующий типу подачи
	 */
	get HasWrongPOSType () {
		if (_.isUndefined(this.Order) || _.isUndefined(this.orderProducts) || _.isEmpty(this.orderProducts))
			return false;

		var posType = this.Order.OrderTypeId,
			wrongProducts = false;

		_.each(this.orderProducts, function(product) {
			if (_.indexOf(product.POSTypes, posType) === -1) {
				wrongProducts = true;
				return false;
			}
		});

		return wrongProducts
	}

	/**
	 * Функция выбора продуктов из раздела меню
	 * @param {number} id ID выбранного продукта
	 */
	checkProducts(id) {
		var checked = _.find(this.Products, { ProductId: id });

		if (_.isUndefined(checked)) 
			this.Products = _.concat(this.Products, { ProductId: id, Count: 1, Modifications : [] });
		else checked.Count += 1;

		this.SearchProducts = "";
	}

	/**
	 * Сбор данных с формы выбора продуктов
	 * @param {number} count кол-во выбранного продукта
	 * @param {number} index порядковый номер продукта
	 * @param {number} productId ID редактируемого продукта
	 */
	collectProducts(count, index, productId) {
		if (count < 1 && !_.isUndefined(productId))
			this.Products = _.reject(this.Products, { ProductId: productId });
		else this.Products[index].Count = count;
	}

	/**
	 * Добавление модификатора к продукту
	 * @param {number} id ID выбранного модификатора
	 * @param {number} index порядковый номер выбранного продукта
	 */
	checkModificators(id, index) {
		var product = this.Products[index],
			checked = _.find(product.Modifications, { ProductId: id });

		if (_.isUndefined(checked)) 
			this.Products[index].Modifications = _.concat(product.Modifications, { ProductId: id, Count: 1 });
		else checked.Count += 1;

		this.setModificators(undefined);
	}

	/**
	 * Сбор данных с формы выбора модификатора
	 * @param {number} count кол-во выбранного модификатора
	 * @param {number} index порядковый номер модификатора
	 * @param {number} modId ID редактируемого модификатора
	 * @param {number} productIndex порядковый номер продукта, к которму относится модификатор
	 */
	collectModificators(count, index, modId, productIndex) {
		var mods = this.Products[productIndex].Modifications;

		if (count < 1 && !_.isUndefined(modId))
			this.Products[productIndex].Modifications = _.reject(mods, { ProductId: modId });
		else mods[index].Count = count;
	}

	/** Продукты в заказе 
	 * @returns {[object]} Массив продуктов с дополнительными параметрами
	*/
	get orderProducts () {
		if (_.isUndefined(this.Order) || _.isEmpty(this.Products)) return [];

		var allProducts = this.SectionProducts,
			cityId = this.Order.CityId,
			posTypeId = this.Order.OrderTypeId,
			disabledAll = this.IsEndProcess || this.Order.IsFinalStatus || this.IsFiscalPaidOrder;

		return _.map(this.Products, function(item) {
			var product = _.find(allProducts, { Id : item.ProductId });

			if (_.isUndefined(product)) return item;

			var price = _.find(product.Prices, { CityId : cityId }),
				modifications = _.map(item.Modifications, function(v) {
					var mod = _.find(allProducts, { Id : v.ProductId });
					if (_.isUndefined(mod)) return v;

					var modPrice = _.find(mod.Prices, { CityId : cityId });

					return _.extend(_.clone(v), {
						Price : modPrice ? modPrice.Price : 0,
						Amount : modPrice ? modPrice.Price*v.Count : 0,
						ProductName : mod.Name,
						Disabled : disabledAll,
						WrongPosType : _.indexOf(mod.POSTypes, posTypeId) === -1
					})
				})

			return _.extend(_.clone(item), {
				Price : price ? price.Price : 0,
				Amount : price ? price.Price*item.Count : 0,
				ProductName : product.Name,
				Recomendation: product.Recomendation,
				Modificators: product.Modificators,
				SectionId : product.SectionId,
				Disabled : disabledAll,
				POSTypes : product.POSTypes,
				WrongPosType : _.indexOf(product.POSTypes, posTypeId) === -1,
				Modifications : modifications
			});
		});
	}

	/** Массив рекомедация для выбранных продуктов
	 * @returns {[string]} Рекомендации
	 */
	get OrderRecomendations () {
		if (_.isUndefined(this.Order)) return "";
		return _.compact(_.map(this.orderProducts, "Recomendation"));
	}

	/**
	 * Повтор предыдущего заказа клиента
	 * @param {object} order заказ
	 */
	repeatCustomerOrder(order) {
		if (_.isUndefined(this.Order)) {
			this.setOrder({ 
				Id : -1, 
				CustomerLogin : this.Customer.Login,
				MerchantId : order.MerchantId,
				BrandId : order.BrandId,
				OrderTypeId : order.OrderTypeId,
				Products : _.map(order.Products, function(item) {
					return {
						Prices: item.Prices,
						ProductId: item.ProductId,
						ProductName: item.ProductName,
						UnitName: item.UnitName
					}
				})
			});
			this.setOrderCustomer(_.extend(_.clone(this.Customer), { Success : true }));
		} else {
			this.Products = this.getOrderProducts(order.Products); 
			this.Order.BrandId = order.BrandId;
			this.Order.MerchantId = order.MerchantId;
			this.Order.OrderTypeId = order.OrderTypeId;
		}

		this.ShowCustomerCard = false;
		this.OrderStateActive = "order";
	}

	/**
	 * Разбор продуктов для повтора предыдущего заказа
	 * @param {[object]} products массив продуктов из выбранного заказа {Id, Name, Count}
	 * @returns {[object]} массив продуктов для текущего заказа
	 */
	getOrderProducts (products) {
		var allProducts = this.SectionProducts;

		return  _.compact(_.map(products, function (item) {
			var product = _.find(allProducts, { Id: item.ProductId });

			return {
				ProductId: item.ProductId,
				ProductName: item.ProductName,
				Count: _.sumBy(item.Prices, "Count"),
				Prices: _.isUndefined(product) ? null : product.Prices,
				Recomendation: _.isUndefined(product) ? "" : product.Recomendation
			}
		}));
	}

	/** Подготовка данных о заказе для отправки на сервер */
	getData() {
		var data = _.clone(this.Order),
			index = 0;

		_.each(this.Products, function (product) {
			var name = "Products." + index + ".";

			if (product.Count > 0) {
				data[name + "ProductId"] = product.ProductId;
				data[name + "Count"] = product.Count;
				index++;

				if (!_.isEmpty(product.Modifications)) {
					var modIndex = 0;

					_.each(product.Modifications, function (mod) {
						var modName = name + "Modifications." + modIndex + ".";
			
						if (mod.Count > 0) {
							data[modName + "ProductId"] = mod.ProductId;
							data[modName + "Count"] = mod.Count;
							modIndex++;
						}
					});
				}
			}
		});

		data.POSId = this.calculatedPosId;

		data.CustomerLogin = (data.CustomerLogin).replace(/\D/gi, "");

		data = _.omit(data, ["OrderToTime", "ByAggregator", "Discount", "ReferenceTimes", "ReferenceTime"]);
		data = _.omitBy(data, function (v, i) {
			return i !== "Id" && i !== "AggregatorId" &&
				(v === "" || v === -1 || v === 0 || (_.isArray(v) && _.isEmpty(v)));
		});
		
		if (data.ChangeFrom) {
			var change = _.isString(data.ChangeFrom) ? parseFloat(data.ChangeFrom) : data.ChangeFrom;
			data.ChangeFrom = change.toFixed(0) * 100;
		}
		data.Amount = this.Amount;
		data.TotalAmount = this.TotalAmount;

		if (data.CustomerAddress && data.OrderTypeId === 3) {
			var name = "CustomerAddress.";
			_.each(data.CustomerAddress, function (value, field) {
				if (value !== "" && value !== 0) data[name + field] = value;
			});
		}
		data = _.omit(data, "CustomerAddress");

		return data;
	}

	/** 
	 * Проверка валидности заполнения данных о заказе
	 * @return {boolean} флаг валидности данных
	 */
	validateData() {
		var isValid = true;

		this.ErrorCode = "";
		this.SuccessSave = false;

		if (this.Order.OrderTypeId !== 1 && this.Order.CustomerLogin === "") {
			this.ErrorCode = "CUSTOMER_LOGIN_REQUIRED";
			return false;
		}

		if (this.Order.CustomerLogin !== "" && this.Order.CustomerLogin.replace(/\D/gi, "").length < 11) {
			this.ErrorCode = "WRONG_PHONE_LENGTH";
			return false;
		}

		if (this.Order.CustomerLogin !== "" && this.Order.CustomerName.trim().length < 3) {
			this.ErrorCode = "CUSTOMER_NAME_REQUIRED";
			return false;
		}

		if (this.Order.CityId === "" || this.Order.CityId === -1) {
			this.ErrorCode = "STAT_CITY_REQUIRED";
			return false;
		}

		if (this.Order.MerchantId === "" || this.Order.MerchantId === -1) {
			this.ErrorCode = "ORDER_MERCHANT_REQUIRED";
			return false;
		}

		if (this.Order.OrderTypeId === "" || this.Order.OrderTypeId === -1) {
			this.ErrorCode = "ORDER_TYPE_REQUIRED";
			return false;
		}

		if (this.Order.OrderTypeId === 3 && (this.Order.DeliveryAreaId === "" || this.Order.DeliveryAreaId === -1)) {
			this.ErrorCode = "ORDER_DELIVERYAREA_REQUIRED";
			return false;
		}

		if (this.Order.FilialId === "" || this.Order.FilialId === -1) {
			this.ErrorCode = "ORDER_FILIAL_REQUIRED";
			return false;
		}

		if (this.Order.OrderToTime && this.Order.MustCompletedTime === "") {
			this.ErrorCode = "ORDER_TIME_REQUIRED";
			return false;
		}

		if (this.Order.ByAggregator && (this.Order.AggregatorId === "" || this.Order.AggregatorId === -1)) {
			this.ErrorCode = "ORDER_AGGREGATOR_REQUIRED";
			return false;
		}

		if (this.Order.BrandId === "" || this.Order.BrandId === -1) {
			this.ErrorCode = "ORDER_BRAND_REQUIRED";
			return false;
		}

		if (_.isEmpty(this.Products)) {
			this.ErrorCode = "ORDER_PRODUCTS_REQUIRED";
			return false;
		}

		if (this.HasWrongPOSType) {
			this.ErrorCode = "WRONG_ORDER_POS_PRODUCT";
			return false;
		}

		if (this.Order.PaymentMethodId === "" || this.Order.PaymentMethodId === -1) {
			this.ErrorCode = "ORDER_PAYMENT_REQUIRED";
			return false;
		}

		if (this.Order.PersonsNumber === "" || this.Order.PersonsNumber < 1) {
			this.ErrorCode = "ORDER_PERSON_REQUIRED";
			return false;
		}

		if (_.indexOf([0, ""], this.Order.ChangeFrom) === -1 && this.Order.ChangeFrom*100 < this.TotalAmount) {
			this.ErrorCode = "WRONG_ORDER_CHANGEFROM";
			return false;
		}

		return isValid;
	}

	/**
	 * Метод, отдающий доступные способы оплаты при просмотре списка заказов
	 * @param {object} order информация о заказе
	 */
	getMethodsForOrder(order) {
		var poses = this.getPosesForOrder(order),
			methods = _.uniqBy(_.flatMap(_.map(poses), "PaymentMethods"), "Id");

		return methods;
	}

	/**
	 * Метод, отдающий доступные методы оплаты для выбранной точки продаж
	 * @param {number} filialId ID филиала
	 * @param {number} posId ID точки продаж
	 * @returns {[object]} массив методов оплаты
	 */
	getMethodsForPOS (filialId, posId) {
		var filial = _.find(authUserState.OrderFilials, { Id: filialId }),
			pos = _.isUndefined(filial) ? undefined : _.find(filial.POS, { Id : posId });

		return _.isUndefined(pos) ? [] : pos.PaymentMethods
	}

	/**
	 * Метод, отдающий доступные точки продаж в зависимости от бренда, филиала, способа оплаты и т.д.
	 * @param {object} order заказ
	 * @param {number} paymentMethodId новый способ оплаты
	 */
	getPosesForOrder(order, paymentMethodId) {
		var filial = _.find(authUserState.OrderFilials, { Id: order.FilialId }),
			poses = _.isUndefined(filial) ? undefined : _.filter(filial.POS, function (pos) {
				var brand = !_.isUndefined(_.find(pos.Brands, { Id: order.BrandId })),
					type = _.indexOf(pos.Types, order.OrderTypeId) !== -1,
					aggregator = order.AggregatorId < 1 ? true :
						!_.isUndefined(_.find(pos.Aggregators, { Id: order.AggregatorId })),
					method = _.isUndefined(paymentMethodId) ? true :
						!_.isUndefined(_.find(pos.PaymentMethods, { Id: paymentMethodId }));

				return brand && type && aggregator && method;
			});

		return poses;
	}

	/**
	 * Метод, обрабатывающий данные для изменения способа оплаты заказа
	 * @param {object} order заказ
	 * @param {number} paymentMethodId новый способ оплаты
	 */
	getPaymentMethodData(order, paymentMethodId) {
		var poses = this.getPosesForOrder(order, paymentMethodId),
			posId = _.isEmpty(poses) ? -1 : _.find(poses, { Main: true }) ? _.find(poses, { Main: true }).Id
				: _.first(poses).Id

		return {
			Id: order.Id,
			PaymentMethodId: paymentMethodId,
			POSId: posId
		}
	}

	/** 
	 * Расчет высоты окна для страницы создания заказа
	 * @returns {object}
	 */
	get menuHeight() { return { height: _.max([this.orderHeight, uistate.sectionHeight - 5]) }; }

	/** --------- POPUP WINDOWS ---------- */
	/**
	 * Разбор клиента
	 * @param {object} customer ответ от сервера с ифнормацией по клиенту
	 * @param {boolean} openCard Открыть
	 */
	setCustomer(customer, openCard) {
		this.Customer = _.isUndefined(customer) ? undefined
			: _.omit(_.clone(customer), ["Success", "ErrorCode", "Orders", "Address"]);

		this.CheckOrderCustomer = !_.isUndefined(this.Order);

		if (!_.isUndefined(customer)) {
			this.Customer.Stat = {
				Count: this.Customer.OrdersCount,
				Amount: this.Customer.OrdersAmount,
				Avg: this.Customer.OrdersCount === 0 ? 0 : this.Customer.OrdersAmount / this.Customer.OrdersCount,
				Last: this.Customer.LastOrder
			}

			if (!_.isUndefined(this.Order)) {
				var orderBrand = _.find(customer.Brands, { BrandId : this.Order.BrandId});
				this.Customer.BrandsCount = _.isUndefined(orderBrand) ? 0 : orderBrand.Count;
			}

			this.Customer.Orders = _.isNull(customer.Orders) ? [] : customer.Orders;
			var brands = _.groupBy(this.Customer.Orders, "BrandId");
			this.Customer.OrdersStat = _.map(brands, function(brand) {
					return _.map(brand, function(item) {
						return { x : item.Date, y : item.Amount/100, label : brand[0].Brand, width: 30 }
					})
				});

			this.Customer.Address = _.concat(_.isNull(customer.Address) ? [] : customer.Address,
				_.clone(this.AddressDummy));

			this.CustomerTab = "info";

			this.Customer.OrdersHistory = [];

			if (openCard) {
				this.ShowCustomerCard = openCard;
				this.UserAction = openCard;
			}
		}
	}

	/** Подсчет кол-ва заказов в выбранном бренде
	 * @returns {number} кол-во заказов
	 */
	get CustomerBrandsCount () {
		if (_.isUndefined(this.Customer) || _.isUndefined(this.Order)) return -1;

		var orderBrand = _.find(this.Customer.Brands, { BrandId : this.Order.BrandId});
		return _.isUndefined(orderBrand) ? 0 : orderBrand.Count;
	}

	/**
	 * Разбор ответа от сервера с генерацией чека на печать
	 * @param {object} bill ответ от сервера
	 * @value PrintFormats – массив с доступными форматами печати
	 * @value Bill - объект чека
	 */
	setBill(bill) {
		this.Bill = bill.Success ? _.omit(_.clone(bill), ["Success", "ErrorCode", "PrintFormat"])
			: undefined;

		var printFormat = intToBitArray(bill.PrintFormat);

		this.PrintFormats = _.filter(PrintFormatesTitles, function (v) {
			return _.indexOf(printFormat, v.Id) !== -1;
		});
		this.PrintFormat = -1;
	}

	/**
	 * Функция, разбирающая ответ от сервера со всем контентом на странице
	 * @param {[object]} data ответ от сервера
	 */
	setContent (data) {
		var content = _.isUndefined(data) ? undefined : _.filter(data, function (v) {
			return v.Active && _.indexOf(v.Filials, authUserState.Filial.Id) !== -1;
		});
		
		this.SaleContent = _.isUndefined(content) ? undefined : _.isEmpty(content) ? [] :
			_.sortBy(_.map(_.groupBy(content, "Group"), function (group, name) {
				return { Name : name, Group : _.sortBy(group, "Order"), Id : group[0].GroupId, Order : group[0].GroupOrder }
			}), "Order");

		this.OpenContent = 0;
	}

	/**
	 * Формирование списка модификатором для добавления к продукту
	 * @param {object} product продукт, к которому добавляют модификатор
	 * @param {number} productIndex индекс продукта в списке
	 * @param {object} bound координаты продукта, для отображения окна
	 */
	setModificators (product, productIndex, bound) {
		var products = this.SectionProducts,
			cityId = this.Order.CityId,
			mods = _.isUndefined(product) ? [] :
				_.filter(products, { Modificators : 2, SectionId : product.SectionId });

		this.ModificatorsList = _.isUndefined(product) ? undefined : {
			Position : { top : bound.top + 20, left : bound.left - 9 },
			ProductId : product.ProductId,
			ProductIndex : productIndex,
			List : _.map(mods, function(v) {
				var price = _.find(v.Prices, { CityId : cityId });

				return({ ProductId : v.Id, ProductName : v.Name, Price : price ? price.Price : "" });
			})
		}
	}

}
decorate(OrderState, {
	Orders: observable,
	IsSendRequest: observable,
	Filter: observable,
	AddFilter: observable,
	ShowAddFilter: observable,
	OrderInterval: observable,

	Filials: observable,
	Tags: observable,
	Brands: observable,
	Aggregators: observable,
	DeliveryAreas: observable,

	AvailableCouriers: observable,
	AllCouriers : observable,
	OrderStatuses : observable,

	ShouldUpdate: observable,
	UserAction: observable,

	RemoveOrder: observable,
	PaymentOrder: observable,
	OrderHistory: observable,

	Order: observable,
	POSId: observable,
	WithCustomer: observable,
	IsChangeDeliveryZone: observable,

	Bill: observable,
	PrintFormat: observable,
	PrintFormats: observable,
	OpenBill: observable,
	ShowCourier: observable,

	SaleContent: observable,
	OpenContent: observable,

	QRCode: observable,

	ButtonLoading: observable,
	ErrorCode: observable,
	SuccessSave: observable,
	OrderStateActive: observable,
	SectionActive: observable,
	ReferenceTime: observable,

	Customer: observable,
	CustomersList: observable,
	ShowCustomerCard: observable,
	CustomerTab: observable,
	CheckOrderCustomer: observable,

	SearchProducts: observable,
	ProductTags: observable,
	ShowTagFilter: observable,
	ShowProducts: observable,

	Products: observable,

	SectionProducts: observable,
	Sections: observable,
	ModificatorsList: observable,

	orderHeight: observable,
	orderReferenceElement: observable,

	menuHeight: computed,
	POSTypes: computed,
	currentFilial: computed,
	availablePOS: computed,

	ChangeOrderCustomer: observable
});
export const orderState = new OrderState();

class ProcessOrderState {
	IsSendRequest = false;
	Order = [];
	Filter = {};
	ProductCount = 1;
	MenuCrumbs = [];
	OrderInterval = undefined;

	/** Установка дефолтного состояния страницы */
	setDefault() {
		this.Filter = { ShiftDate: formatDate(authUserState.Shift.Date, "r") }

		this.WorkShopId = -1;
		this.IsSendRequest = false;
		this.Orders = [];
		this.OrderId = -1;
		this.Brands = [];
		this.WorkShops = [];
	}

	/**
	 * Сбор данных с формы фильтра заказов
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	collectFilter(id, value) { this.Filter[id] = value; }

	/** Форматирование данных для запроса к серверу поиска заказов в интерфейсе повара */
	getFilterData() { return clearEmptyParams(this.Filter, ["", false]); }

	/** Получение ID статуса начала производства 
	 * @returns {number} ID статуса
	 */
	get StartReleaseStatusId () {
		if (_.isUndefined(this.OrderStatuses)) return -1;
		var status = getStatusBySystem(this.OrderStatuses, authUserState.Merchant, undefined, 3);

		return status ? status.Id : -1;
	}
	/** Получение ID статуса окончания производства 
	 * @returns {number} ID статуса
	 */
	get EndReleaseStatusId () {
		if (_.isUndefined(this.OrderStatuses)) return -1;
		var status = getStatusBySystem(this.OrderStatuses, authUserState.Merchant, undefined, 2);

		return status ? status.Id : -1;
	}

	/**
	 * Разбор данных со списком заказовна приготовление
	 * @param {object} data ответ от сервера с набором заказов
	 */
	setOrders(data) {
		var store = this;

		this.TimeNow = data.TimeNow;

		this.Orders = data.Success ? _.map(data.Orders, function (order) {
			var times = store.getOrderTimes(order.CreationTime, order.MustCompletedTime, order.ReferenceTimes);

			return _.extend(order, { 
				Products: store.flattenProducts(order.Products),
				OrderType : _.find(POSTypesTitles, { Id: order.OrderTypeId }) ?
					_.find(POSTypesTitles, { Id: order.OrderTypeId }).Name : "",
				Left : times.Left,
				Reference : times.Reference,
				Light : times.Light,
				MustCook : times.MustCook
			});
		}) : [];

		this.IsSendRequest = true;
	}

	/**
	 * Функция, разбирающая список продуктов на приготовленные и нет
	 * @param {[object]} products массив продуктов исходный
	 * @returns {[object]} массив продуктов обработанный
	 */
	flattenProducts(products) {
		return _.flatten(_.map(products, function (v) {
			var count = v.Count;

			if (v.Started !== 0 && v.Started < count)
				return [
					_.extend(_.clone(v), {
						Count: v.Started,
						Started: v.Started,
						Released: v.Released
					}),
					_.extend(_.clone(v), {
						Count: count - v.Started,
						Started: 0,
						Released: 0
					})];
			else return [_.extend(v, { Count: count })];
		}));
	}

	/** 
	 * Фильтр заказов по цеху
	 * @return {[object]} Заказы, отфильтрованные по цеху
	 */
	get filteredOrders() {
		var workshop = this.WorkShopId,
			orders = _.filter(this.Orders, function (item) {
				var workshops = _.uniq(_.flatten(_.map(item.Products, "WorkShops")));

				return workshop === -1 ? true : _.indexOf(workshops, workshop) !== -1;
			});

		return _.map(orders, function (v, i) { return _.extend(_.clone(v), { Index: i }); });
	}

	/** Проверка, что заказ может изменить статус на «В производстве» 
	 * @returns {boolean} можно изменить статус
	 */
	get canProcess () {
		if (this.OrderId === -1) return false;

		var order = _.find(this.Orders, { Id : this.OrderId });

		return order ? order.StatusId !== this.StartReleaseStatusId : false;
	}

	/**
	 * Расчет оставшегося и эталонного времени приготовления заказа
	 * @param {number} cityId ID города
	 * @param {string} create время приема заказа в формате «YYYY-MM-DD HH:mm»
	 * @param {string} complete время к которому должен быть готов заказ «YYYY-MM-DD HH:mm»
	 * @param {[object]} reference набор эталонных времен для разных статусов
	 * @returns {object} оставшееся время по заказу { Left, Reference }
	 */
	getOrderTimes (creation, complete, reference) {
		var now = moment(this.TimeNow),
			create = moment(creation),

			past = now.diff(create, "minutes"),
			must = complete === "" ? null : moment(complete),
			referenceTime = _.sumBy(reference, "Time"),
			releaseReference = this.getReferenceMinutes(reference, this.EndReleaseStatusId),
			startReference = this.getReferenceMinutes(reference, this.StartReleaseStatusId),
			mustCook = !_.isNull(must) ? must.subtract(referenceTime - releaseReference, "minutes") : "",
			mustLeft = !_.isNull(must) ? mustCook.diff(now, "minutes") : 0,
			left = _.isNull(must) ? releaseReference - past : mustLeft;
			
		return {
			Left : left,
			Reference : releaseReference - startReference,
			Light : getTrafficLight(left, releaseReference - startReference),
			MustCook : mustCook
		}
	}

	/**
	 * Получение эталонного кол-ва минут, в зависимости от статуса
	 * @param {[object]} reference набор эталонного времени по статусам
	 * @param {number} statusId ID статуса
	 * @returns {number} эталонное кол-во минут
	 */
	getReferenceMinutes (reference, statusId) {
		var minutes = 0;

		_.each(reference, function (status) {
			if (status.StatusId === statusId) return false;
			minutes += status.Time;
		});

		return minutes;
	}

	/**
	 * Сбор данных для отправки на сервер для производства продуктов
	 * @param {[number]} productIds список ID производимых продуктов
	 * @param {boolean} active выполнить (true) или отменить выпуск (false)
	 * @param {boolean} isStart начало выполнения (true) или завершение (false)
	 */
	getReleaseProduct(productIds, active, isStart) {
		var data = { OrderId: this.OrderId },
			order = _.find(this.Orders, { Id: this.OrderId }),
			products = order ? order.Products : [],
			index = 0;

		_.each(productIds, function (productId) {
			var name = "Products." + index + ".",
				product = _.find(products, { ProductId: productId });

			if (_.isUndefined(product)) return true;

			data[(isStart ? "" : name) + "ProductId"] = productId;
			data[(isStart ? "" : name) + "Count"] = active ? product.Count - product.Released
				: isStart ? product.Started : product.Released;
			data[(isStart ? "" : name) + "Active"] = active;
			index++;
		});

		return data;
	}

	/**
	 * Открытие/закрытие карточки с приготовлением продукта
	 * @param {object} product информация о технике приготовления продукта
	 */
	setMenuCard(product) {
		this.MenuCard = _.isUndefined(product) ? undefined : product
		this.ProductCount = 1;

		var index = _.isUndefined(product) ? -2 : _.findIndex(this.MenuCrumbs, { Id : product.Id });
		this.MenuCrumbs = _.isUndefined(product) ? [] : 
			index === -1 ? _.concat(this.MenuCrumbs, { Id : product.Id, Name : product.Name }) :
			_.initial(this.MenuCrumbs, index + 1);
			
	}

	/**
	 * Подсчет кол-ва продукта в составе в зависомсти от необходимо кол-ва на производство
	 * @return {[object]} Состав продукта
	 */
	get menuComposition () {
		if (_.isUndefined(this.MenuCard) || _.isNull(this.MenuCard.Composition) || this.ProductCount === "") 
			return [];

		var count = this.ProductCount;
		
		return _.map(_.clone(this.MenuCard.Composition), function(v) {
			return _.extend(_.clone(v), { Count: _.clone(v.Count)*count, SourceTrash: _.clone(v.SourceTrash)*count });
		});
	}

	/**
	 * Открытие/закрытие изображения в сплывающем окне
	 * @param {string} photoURL адрес изображения
	 */
	setProductPhoto(photoURL) { this.ProductPhoto = photoURL && photoURL !== "" ? photoURL : undefined; }
}
decorate(ProcessOrderState, {
	IsSendRequest: observable,
	Orders: observable,
	Filter: observable,
	TimeNow: observable,
	Brands: observable,
	Workshops: observable,
	OrderInterval: observable,

	OrderStatuses: observable,
	OrderId: observable,
	WorkShopId: observable,

	MenuCard: observable,
	ProductCount: observable,
	ProductPhoto: observable,
	MenuCrumbs: observable
})
export const processOrderState = new ProcessOrderState();

class DeliveryOrderState {
	IsSendRequest = false;
	Orders = [];
	Filter = { Active: "true" };
	Order = undefined;
	OrderStatuses = [];

	/** Установка дефолтных значений страницы */
	setDefault() {
		this.Filter = { IsActiveOrder: "true" }

		this.Orders = [];
		this.Order = undefined;
		this.Loading = false;
		this.IsSendRequest = false;
	}

	/** Получение фильтра по заказам для отправки запроса на сервер */
	getFilter() { return _.clone(this.Filter); }

	/**
	 * Разбор ответа от сервера со списком заказов
	 * @param {object} data ответ от севрера
	 */
	setOrders(data) {
		var store = this;
		
		this.TimeNow = data.TimeNow;
		this.Orders = data.Success ? _.map(data.Orders, function(item) {
			var times = store.getLeftMinutes(item);

			return _.extend(item, { 
				Left : times.Left,
				Light : times.Light
			});
		}) : [];
		
		this.IsSendRequest = true;
	}

	/**
	 * Расчет оставшегося времени до подачи заказа
	 * @param {object} order заказ
	 * @returns {object} объект с расчетом времени {Left, Light}
	 */
	getLeftMinutes (order) {
		var now = moment(this.TimeNow),
			create = moment(order.CreationTime),
			must = order.MustCompletedTime !== "" ? moment(order.MustCompletedTime) : null,
			reference = order.ReferenceTime,
			left = !_.isNull(must) ? must.diff(now, "minutes") : 
				(create.add(reference, "minutes")).diff(now, "minutes")

		return {
			Left : left,
			Light : getTrafficLight(left, reference)
		}
	}

	/**
	 * Назначение/удаление активного заказа
	 * @param {number} id ID заказа
	 */
	setOrder(id) {
		console.log("order id", id)
		this.Order = _.find(this.Orders, { Id: id });

		if (this.Order) {
			this.Order.Change = this.Order.ChangeFrom === "" || this.Order.ChangeFrom === 0 ? 0 :
				this.Order.ChangeFrom - this.Order.TotalAmount;
			this.Order.CanPaid = !_.isUndefined(_.find(this.availableMethods, 
				{ Id : this.Order.PaymentMethodId }));
		}

		this.Loading = false;
	}

	/**
	 * Расчет сдачи
	 * @param {number} value значение сдачи с
	 */
	collectChange(value) {
		this.Order.ChangeFrom = value * 100;
		this.Order.Change = value === 0 || value === "" ? 0 : value * 100 - this.Order.TotalAmount;
	}

	/** Получение ID завершающего статуса для данного заказа
	 * @return {number} ID статуса
	 */
	get finalStatusId() {
		if (_.isUndefined(this.Order) || _.isEmpty(this.OrderStatuses)) return -1;

		var status = getStatusBySystem(this.OrderStatuses, authUserState.Merchant, 3, 0);

		return status ? status.Id : -1;
	}

	/** Получение ID статуса готовности для доставки
	 * @return {number} ID статуса
	 */
	get deliveryStatusId () {
		if (_.isUndefined(this.Order) || _.isEmpty(this.OrderStatuses)) return -1;

		var status = getStatusBySystem(this.OrderStatuses, authUserState.Merchant, 3, 5);
		return status ? status.Id : -1;
	}

	/** Возможность пользователя доставлять заказ исходя из текущего статуса
	 * @return {boolean} dВозможность сменить статус
	 */
	get canDelivery () {
		if (_.isUndefined(this.Order || _.isEmpty(this.OrderStatuses)) || this.deliveryStatusId === -1)
			return true;
		
		var currentIndex = _.findIndex(this.OrderStatuses, { Id : this.Order.StatusId }),
			deliveryIndex = _.findIndex(this.OrderStatuses, { Id : this.deliveryStatusId });
		
		return currentIndex >= deliveryIndex;
	}

	/** Получение доступных фискальных способов оплаты заказа
	 * @return {[object]} массив методов оплаты
	 */
	get availableMethods() {
		if (_.isUndefined(this.Order)) return [];

		var filial = _.find(authUserState.Filials, { Id : this.Order.FilialId }),
			pos = _.find(filial.POS, { Id : this.Order.POSId }),
			methods = pos ? _.filter(pos.PaymentMethods, { ShowCourier : true }) : [],
			methodId = this.Order.PaymentMethodId;

		return _.sortBy(methods, function(v) { return v.Id !== methodId; });
	}

	/** ----------- QRCODE ORDER IN LIST ----------- */
	setQRCode(order) {
		this.QRCode = _.isUndefined(order) ? undefined : {
			OrderId : order.Id,
			OrderNumber : order.OrderNumber
		}

		this.UserAction = !_.isUndefined(order);
	}
}
decorate(DeliveryOrderState, {
	IsSendRequest: observable,
	Orders: observable,
	Filter: observable,
	OrderStatuses: observable,
	TimeNow : observable,

	Order: observable,
	Loading: observable,

	QRCode: observable
})
export const deliveryOrderState = new DeliveryOrderState();