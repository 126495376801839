/**
 * /pages/StopLists.js – Работа со стоп листами на производстве
 * /stores/stopLists.js – бизнес логика
 * 
 * Список позиций в стоп листе доступен всем пользователям по запросу «StopLists» с ID предприятия
 * В «самостоятельном предприятии» пользователь видит стоп лист только своего предприятия,
 * 		В колл-центре пользователь видит позиции на стопе во всех предприятиях
 * 
 * Для управления стоп листом необходимо чтобы была открыта смена и было право «ManageStopList»
 * 
 * Для добавления позиции в стоп лист необходимо вызвать метод «AddProductStopList» указав параметр ID продукта
 * Для удаления позиции из стоп лист необходимо вызвать метод «RemoveProductStopList» указав параметр 
 * 		ID позиции в стоп листе
 * 
 * Дополнительно, один раз при загрузке страницы вызывается метод «Products» и метод «Sections», 
 * 		только товары, для формирования списка позиций для добавления в стоп лист. В списке позиций не должно
 * 		быть товаров из скрытых категорий
 */

import React, { Component } from 'react';
import { observer } from "mobx-react";
import _ from 'lodash';

import { uistate, authUserState, confirmState } from '../stores/common';

import { checkRights, formatDate, getRequest, toSelectList } from '../functions';
import { dashboardsTitles, errors } from '../dictionary';
import { stopListState } from '../stores/stopLists';
import { Selectize, SubmitButton } from '../forms/FormItems';


export class StopListPage extends Component {
	constructor(props) {
		super(props);
		this.state = {}

		stopListState.setDefault();
		this.getData();
		getRequest("Products", { Type: 3, Active: true }, function (data) {
			stopListState.Products = data.Success ? data.Products : [];
		});
		getRequest("Sections", { Type: 3 }, function (data) {
			stopListState.Sections = data.Success ? data.Sections : [];
		});
	}

	/** API запрос «StopLists» на получение позиций из стоп листа */
	getData() {
		var merchantId = authUserState.MerchantType === 2 ? -1 : authUserState.Merchant;

		stopListState.IsSendRequest = false;
		getRequest("StopLists", { MerchantId : merchantId }, function (data) { stopListState.setList(data); });
	}

	/**
	 * API запрос «RemoveProductStopList» на удаление позиции из стоп листа
	 * @param {number} id ID позиции в стоп листе
	 */
	removeItem (id) {
		var model = this,
			item = _.find(stopListState.List, { Id : id }),
			text = <span>Вы действительно хотите удалить позицию <b key="b1">«{item ? item.Product : ""}»</b>
				&nbsp;из филиала <b key="b2">«{item ? item.Filial : ""}»</b>?</span>;

		confirmState.openConfirm(text, function(){
			getRequest("RemoveProductStopList", { Id : id }, function() { model.getData(); });
		});
	}

	/**
	 * API запрос «AddProductStopList» на удаление позиции из стоп листа
	 * @param {number} id ID добавляемого продукта
	 */
	addItem (id) {
		var model = this;
		getRequest("AddProductStopList", { Id : id }, function() { model.getData(); });
	}

	render() {
		var view = this;

		return (
			<div className={"clearfix page " + (uistate.IsTablet ? "tablet" : "")} id="StopListPage">
				<div className="head clearfix"><h2>{dashboardsTitles[this.props.Type]}</h2></div>
				{_.isEmpty(stopListState.stopList) && stopListState.IsSendRequest && 
					<h4>{errors.EMPTY_RESPONSE}</h4>
				}
				{stopListState.IsSendRequest && !_.isEmpty(stopListState.stopList) &&
					_.map(stopListState.stopList, function(item, i) {
						return(<MerchantGroupList 
							Item={item} key={i} 
							addItem={view.addItem.bind(view)}
							removeItem={view.removeItem.bind(view)}
						/>)
					})
				}
			</div>
		)
	}
}
StopListPage = observer(StopListPage);

class MerchantGroupList extends Component {
	render() {
		var view = this,
			isCallCentre = authUserState.MerchantType === 2


		return (
			<div className="clearfix">
				{isCallCentre && <h4>{this.props.Item.Merchant}</h4>}
				{_.map(this.props.Item.Filials, function(item, i) {
					return(<FilialGroupList 
						Item={item} key={i} 
						addItem={view.props.addItem}
						removeItem={view.props.removeItem}
					/>)
				})}
			</div>
		)
	}
}
MerchantGroupList = observer(MerchantGroupList);

class FilialGroupList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			OpenAdd : false,
			ProductId : -1,
		}
	}

	/** Показать/скрыть блок с добавлением продукции в стоплист */
	handleToggle (event) {
		event.preventDefault();
		this.setState({ OpenAdd : !this.state.OpenAdd });
	}

	/** 
	 * Сбор данных с формы добавления позиции в стоп-лист 
	 * @param {number} value значение параметра
	 */
	handleChange(value) { this.setState({ ProductId : value }); }

	/** Добавлени позиции в стоп лист */
	handleAdd(event) {
		event.preventDefault();
		this.props.addItem(this.state.ProductId);
		this.setState({ OpenAdd : false, ProductId : -1 });
	}

	render() {
		var view = this,
			item = this.props.Item,
			isSameFilialShift = authUserState.Shift.Active && authUserState.Shift.FilialId === item.FilialId;
			
		return (
			<div className="clearfix block">
				<h5>{item.Filial}</h5>
				<div className="clearfix">
					{_.map(item.List, function(product, i) {
						return(<StopListItem Item={product} key={i} removeItem={view.props.removeItem} />)
					})}
				</div>
				{isSameFilialShift && checkRights("ManageStopList") &&
					<div className="clearfix addBlock">
						{!this.state.OpenAdd &&
							<button className="icon add" onClick={this.handleToggle.bind(this)}>
								<i></i> Добавить позицию</button>
						}
						{this.state.OpenAdd &&
							<form className="clearfix" onSubmit={this.handleAdd.bind(this)}>
								<h5>Добавление позиции в стоп-лист</h5>
								<Selectize
									Id="ProductId"
									Title="Продукция"
									Value={this.state.ProductId}
									List={toSelectList(stopListState.productsList)}
									RowClass="col col45"
									onChange={this.handleChange.bind(this)}
								/>
								<SubmitButton Title="Добавить" Disabled={this.state.ProductId === -1} />
								<a href="/" className="button gray col col10" onClick={this.handleToggle.bind(this)}>
									Отменить</a>
							</form>
						}
					</div>
				}
			</div>
		)
	}
}
FilialGroupList = observer(FilialGroupList);

class StopListItem extends Component {
	/** Удаление продукта из стоп-листа */
	handleRemove(event) {
		event.preventDefault();
		this.props.removeItem(this.props.Item.Id);
	}

	render() {
		return(
			<div className="clearfix item">
				<span className="col col50 name">{this.props.Item.Product}</span>
				<span className="col col30">
					{this.props.Item.User}, <i>{formatDate(this.props.Item.Date)}</i>
				</span>
				{this.props.Item.CanRemove &&
					<a href="/" className="icon remove col" title="Удалить позицию из стоп-листа"
						onClick={this.handleRemove.bind(this)}><i></i></a>
				}
			</div>
		)
	}
}

