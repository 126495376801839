import { observable, decorate } from 'mobx';
import _ from 'lodash';
import moment from 'moment';
import { checkRights } from '../functions';
import { authUserState } from './common';

class TransferState {
	Transfer = undefined;
	Transfers = [];
	IsSendRequest;
	OperationType = "";
	RequestId = "";
	Filter = {
		Status: "",
		FromStockId: "",
		ToStockId: "",
		ShiftDate: "",
		FromUserId: "",
		ToUserId: "",
		Limit: 100,
		Offset: 0
	}
	Limit = 100;
	HasMore = false;

	TransferDummy = {
		Id: -1,
		TransferNumber: "",
		FromStock: "",
		FromStockId: -1,
		ToStock: "",
		ToStockId: -1,
		FromUser: "",
		ToUser: "",
		FromDate: moment().format("YYYY-MM-DD HH:mm"),
		ToDate: moment().format("YYYY-MM-DD HH:mm"),
		CourierName: "",
		Status: "",
		AcceptUser: "",
		AcceptDate: "",
		Description: ""
	}
	Products = [];
	ProductDummy = {
		ProductId: -1,
		CountOut: "",
		CountIn: "",
		CancelReasonId : -1,
		Description : "",
		Request: "",
		UnitName: "",
		Actual : "",
		LastUpdate : "",
	}
	ActualProducts = [];

	pCounter = 0;
	ErrorCode = "";
	ButtonLoading = false;
	SuccessSave = false;

	setFilter() {
		this.Filter = {
			Status: "",
			FromStockId: "",
			ToStockId: "",
			ShiftDate: "",
			FromUserId: "",
			ToUserId: "",
			Limit: 100,
			Offset: 0
		}
	}

	collectFilter(id, value) {
		this.Filter[id] = value;
	}

	getFilterData() {
		return _.omitBy(_.clone(this.Filter), function (v) { return v === "" || v === false });
	}

	setTransfers(data) {
		var canManage = checkRights("ManageTransfers") || checkRights("AcceptTransfers");

		this.Transfers = data.Success ? _.map(data.Transfers, function (v) {
			return _.extend(v, {
				CanTake : v.Status === 1 && checkRights("ManageTransfers"),
				CanEdit : v.Status !== 3 && canManage,
				CanView : v.Status === 3 && canManage,
				CanRemove : v.Status !== 3 && checkRights("AcceptTransfers")
			});
		}) : [];
		this.HasMore = data.HasMore;
		this.IsSendRequest = true;
	}

	setTransfer(transfer) {
		var store = this;

		this.Transfer = _.isUndefined(transfer) ? undefined : transfer.Id === -1 ? _.clone(this.TransferDummy)
			: _.omit(_.clone(transfer), ["Success", "ErrorCode, Products"]);

		this.Products = _.isUndefined(transfer) || transfer.Id === -1 || _.isNull(transfer.Products)
			? [_.clone(this.ProductDummy)] : _.map(transfer.Products, function (v) {
				return _.extend(v, {
					CountIn: store.OperationType === "Take" ? v.CountOut
						: store.OperationType === "Original" && store.Transfer.Status === 1 ? "" : v.CountIn,
					Request : v.StockCount - v.Request
				});
			});

		this.ErrorCode = "";
		this.ButtonLoading = false;
		this.SuccessSave = false;
	}

	setActual(products) {
		this.ActualProducts = products;

		if (!_.isEmpty(this.Products))
			this.Products = _.map(this.Products, function (v) {
				var product = _.find(products, { ProductId : v.ProductId });

				return _.extend(v, {
					Actual : product ? product.Count : "",
					LastUpdate : product ? moment(product.LastUpdate).format(", DD.MM.YY") : ""
				});
			});
	}

	setRequest (request) {
		this.RequestId = _.isUndefined(request) ? "" : request.Id;
		this.Transfer.ToStockId = _.isUndefined(request) ? -1 : request.StockId;

		if (!_.isUndefined(request))
			this.Products = _.map(request.Products, function (v) {
				return {
					ProductId : v.ProductId,
					Request : v.StockCount - v.Count,
					UnitName : v.UnitName
				}
			});
	}

	collectTransfer(id, value) { this.Transfer[id] = value; }

	collectProducts(id, value, index, product) {
		this.Products[index][id] = value;

		if (/ProductId/.test(id) && product) {
			var prod = _.find(this.ActualProducts, { ProductId : value });

			this.Products[index].UnitName = product.UnitNet;
			this.Products[index].Actual = prod ? prod.Count : "";
			this.Products[index].LastUpdate = prod ? moment(prod.LastUpdate).format("DD.MM.YY") : "";
		}
	}

	getSaveData() {
		var store = this,
			data = { Id: this.Transfer.Id, Description : this.Transfer.Description },
			index = 0;

		if (this.OperationType === "Create") _.extend(data, {
			FromStockId: this.Transfer.FromStockId,
			ToStockId: this.Transfer.ToStockId,
			CourierName: this.Transfer.CourierName,
			FromDate: this.Transfer.FromDate
		});
		
		if (this.OperationType === "Take" && !_.isUndefined(this.Transfer.ToDate) && this.Transfer.ToDate !== "") 
			_.extend(data, { ToDate : this.Transfer.ToDate });
		
		if (this.OperationType === "Original") _.extend(data, { 
				FromDate : moment(this.Transfer.FromDate).format("YYYY-MM-DD HH:mm") 
			}, this.Transfer.Status === 2 ? { 
				ToDate : moment(this.Transfer.ToDate).format("YYYY-MM-DD HH:mm") 
			} : {});
		
		if (this.OperationType === "Create" && this.RequestId !== "") data.RequestId = this.RequestId;

		_.each(this.Products, function (product, i) {
			var name = "Products." + index + ".";

			if (product.ProductId !== "" && product.ProductId !== -1) {
				data[name + "ProductId"] = product.ProductId;
				data[name + "CancelReasonId"] = product.CancelReasonId;
				data[name + "Description"] = product.CancelReasonId !== "" && product.CancelReasonId !== -1
					? store.Transfer.CourierName : "";

				if (store.OperationType === "Create") data[name + "Count"] = product.CountOut;
				else if (store.OperationType === "Take") data[name + "Count"] = product.CountIn;
				else {
					data[name + "CountOut"] = product.CountOut;
					data[name + "CountIn"] = product.CountIn;
				}

				index++;
			}
		});

		return data;
	}

	validateData() {
		var isValid = true;

		if (this.OperationType === "Create" && (this.Transfer.FromStockId === "" || this.Transfer.FromStockId === -1
			|| this.Transfer.ToStockId === -1 || this.Transfer.ToStockId === "")) {
			this.ErrorCode = "STOCK_REQUIRED";
			return false;
		}

		if (this.OperationType === "Create" && this.Transfer.FromStockId === this.Transfer.ToStockId) {
			this.ErrorCode = "DIFFERENT_STOCK_REQUIRED";
			return false;
		}

		return isValid;
	}

	setWidgetTransfers (data) {
		var stocks = _.map(authUserState.Stocks, "Name"),
			transfers = !data.Success ? [] : _.filter(data.Transfers, function (v) {
				return _.indexOf(stocks, v.ToStock) !== -1; 
			});

        this.WidgetTransfers = _.take(transfers, 5);
        this.IsSendRequest = true;
    }
}
decorate(TransferState, {
	Transfer: observable,
	Products: observable,
	RequestId: observable, 

	Transfers: observable,
	IsSendRequest: observable,

	Filter: observable,
	HasMore: observable,

	ButtonLoading: observable,
	ErrorCode: observable,
	SuccessSave: observable,

	WidgetTransfers: observable
});
export const transferState = new TransferState();