import React, { Component } from 'react';
import { observer } from "mobx-react";
import pageLoader from '../images/loader_big.svg';
import _ from 'lodash';

import { Selectize, SubmitButton, ButtonGroup, DatePreset } from '../forms/FormItems';
import { DataTable } from '../tables/Tables';

import { getRequest, toSelectList, formatDate } from '../functions';
import { dashboardsTitles, errors, HomeOrdersTitles } from '../dictionary';
import { orderStatState } from '../stores/orderStat';
import { authUserState } from '../stores/common';

export class EmployeesStat extends Component {
	constructor(props) {
		super(props);
		this.state = {}

		orderStatState.setDefault();

		this.getData();

		var model = this;
		getRequest("Brands", {}, function (data) { model.setData(data, "Brands"); });
	}

	/**
	 * Установка в состоянии модели ответа от сервера
	 * @param {object} data ответ от сервера
	 * @param {string} param название параметра
	 */
	setData(data, param) { this.setState({ [param]: data.Success ? data[param] : [] }); }

	/** API запрос на получение данных с сервера */
	getData() {
		var types = { 
			taking : "TakeOrderStat",
			process : "",
			delivery : "DeliveryOrdersStat"
		};

		orderStatState.IsSendRequest = false;
		getRequest(types[orderStatState.StatType], orderStatState.getFilter(), 
			function (data) { orderStatState.setStat(data.Success? data.Users : []); });
	}

	/** Сабмит формы поиска */
	handleSubmit(event) {
		if (event) event.preventDefault();

		if (!orderStatState.validateFilter()) return false;

		this.getData();
	}

	/**
	 * Сбор данных с формы поиска
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	handleFilterChange(value, id) { 
		if (/StatType/.test(id)) {
			orderStatState.StatType = value;
			this.getData();
		} else orderStatState.collectFilter(id, value); 
	}

	render() {
		var columns = { Name: {} },
			taking = {
				CreateAmount: {}, CreateCount: {}, CreateAvg: {},
				ProcessAmount: {}, ProcessCount: {}, ProcessAvg: {}
			},
			delivery = { Amount: { width: 150 }, Count: {}, Avg : {} };

		if (orderStatState.StatType === "taking") columns = _.extend(columns, taking);
		if (orderStatState.StatType === "delivery") columns = _.extend(columns, delivery);

		return (
			<div className="clearfix page" id="StatPage">
				<div className="head clearfix"><h2>{dashboardsTitles[this.props.Type]}</h2></div>
				<StatForm
					Type={this.props.Type}
					Brands={this.state.Brands}

					onSubmit={this.handleSubmit.bind(this)}
					handleChange={this.handleFilterChange.bind(this)}
				/>
				<StatTable TableColumns={columns} Type={this.props.Type} Data={orderStatState.Stats} />
			</div>
		)
	}
}
EmployeesStat = observer(EmployeesStat);

export class RevenueStat extends Component {
	constructor(props) {
		super(props);
		this.state = {}

		orderStatState.setDefault();

		this.getData();

		var model = this;
		getRequest("Brands", {}, function (data) { model.setData(data, "Brands"); });
	}

	/**
	 * Установка в состоянии модели ответа от сервера
	 * @param {object} data ответ от сервера
	 * @param {string} param название параметра
	 */
	setData(data, param) { this.setState({ [param]: data.Success ? data[param] : [] }); }

	/** API запрос на получение данных с сервера */
	getData() {
		orderStatState.IsSendRequest = false;
		getRequest("StatProceeds", orderStatState.getFilter(this.props.Type), 
			function (data) { orderStatState.setStat(data.Success ? data.Dates : []); });
	}

	/** Сабмит формы поиска */
	handleSubmit(event) {
		if (event) event.preventDefault();

		if (!orderStatState.validateFilter()) return false;

		this.getData();
	}

	/**
	 * Сбор данных с формы поиска
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 */
	handleFilterChange(value, id) { orderStatState.collectFilter(id, value); }

	render() {
		var columns = {
			Date: { Header : "Дата", Cell: row => orderStatState.Filter.Period === "day" ? 
				formatDate(row.original.Date) : _.startCase(formatDate(row.original.Date, "MMMM YYYY")) }, 
			Count : {}, Amount : { width: 200 }, Avg : {}
		};

		return (
			<div className="clearfix page" id="StatPage">
				<div className="head clearfix"><h2>{dashboardsTitles[this.props.Type]}</h2></div>
				<StatForm
					Type={this.props.Type}
					Brands={this.state.Brands}

					onSubmit={this.handleSubmit.bind(this)}
					handleChange={this.handleFilterChange.bind(this)}
				/>
				<StatTable TableColumns={columns} Type={this.props.Type} Data={orderStatState.Stats} />
			</div>
		)
	}
}
RevenueStat = observer(RevenueStat);
class StatForm extends Component {
	/** 
	 * Установка дат фильтра из пресета дат 
	 * @param {object} dates даты из пресета {DateFrom, DateTo}
	 * @param {string} set название активного пресета
	 */
	setPreset(dates, set) {
		orderStatState.Filter = _.extend(orderStatState.Filter, dates);
		orderStatState.DatePreset = set;
	}

	render() {
		var filter = orderStatState.Filter,
			viewHome = this.props.Type !== "EmployeesStat" || 
				(this.props.Type === "EmployeesStat" && orderStatState.StatType === "taking"),
			detalisationList = [{ Id : "day", Name : "По дням"}, { Id : "month", Name : "По месяцам" }];

		return (
			<form className={"clearfix " + this.props.Type} onSubmit={this.props.onSubmit}>
				<div className="col col80 filter orderStat">
					{this.props.Type === "EmployeesStat" && !_.isEmpty(orderStatState.emploeesList) &&
						<div className="clearfix">
							<ButtonGroup
								Id="StatType"
								List={orderStatState.emploeesList}
								Value={orderStatState.StatType}
								onChange={this.props.handleChange}
							/>
						</div>
					}
					<DatePreset
						DateFrom={filter.DateFrom}
						DateTo={filter.DateTo}
						// ActiveSet={orderStatState.DatePreset}
						IsClearable={false}

						// setPreset={this.setPreset.bind(this)}
						onChange={this.props.handleChange}
					/>
					<div className="clearfix">
						<Selectize
							Id="FilialId"
							Title="Филиалы"
							Value={filter.FilialId}
							List={toSelectList(authUserState.Filials)}
							RowClass="col col25"
							onChange={this.props.handleChange}
						/>
						<Selectize
							Id="POSId"
							Title="Точка продаж"
							Value={filter.POSId}
							List={toSelectList(orderStatState.posList)}
							RowClass="col col25"
							onChange={this.props.handleChange}
						/>
						<Selectize
							Id="BrandId"
							Title="Бренд"
							Value={filter.BrandId}
							List={toSelectList(this.props.Brands)}
							RowClass="col col20"
							onChange={this.props.handleChange}
						/>

						{viewHome &&
							<ButtonGroup
								Id="HomeOrder"
								List={HomeOrdersTitles}
								Type="number"
								RowClass="col col30"
								Value={filter.HomeOrder ? 2 : 1}
								onChange={this.props.handleChange}
							/>
						}

						{this.props.Type !== "EmployeesStat" &&
							<Selectize
								Id="Period"
								Title="Детализация"
								Value={filter.Period}
								List={toSelectList(detalisationList)}
								RowClass="col col25"
								isClearable={false}
								onChange={this.props.handleChange}
							/>
						}
					</div>
				</div>
				<div className="col col20 buttons">
					<SubmitButton Title="Найти" Loading={orderStatState.ButtonLoading} />
					{orderStatState.ErrorCode !== "" &&
						<p>{errors[orderStatState.ErrorCode] || errors.DEFAULT}</p>
					}
				</div>
			</form>
		)
	}
}
StatForm = observer(StatForm);

class StatTable extends Component {
	render() {
		return (
			<div className="clearfix table">
				{!orderStatState.IsSendRequest &&
					<div className="loader"><img src={pageLoader} alt="logo" /></div>
				}
				{orderStatState.IsSendRequest && !_.isEmpty(this.props.Data) &&
					<DataTable
						Data={this.props.Data}
						Columns={this.props.TableColumns}
						Type={this.props.Type}

						IsSortable={false}
					/>
				}
				{orderStatState.IsSendRequest && _.isEmpty(this.props.Data) && 
					<p>{errors.EMPTY_RESPONSE}</p>
				}
			</div>
		)
	}
}
StatTable = observer(StatTable);